import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { toNumberWithSmartPrecision } from '../../util/util'

export default class WalletAccounBalanceItem extends Component {
    Row ({ name, value }) {
        return (
            <div className='wallet-account-balance-item--row'>
                <span className='wallet-account-balance-item--row--name'>{name}</span>
                <div className='wallet-account-balance-item--row--value'>{value}</div>
            </div>
        )
    }
    render () {
        const { walletAccountBalance, shouldShowAccountTypeTag, shouldShowAccountName, shouldShowDetail } = this.props
        return (
            <div className='wallet-account-balance-item'>
                <div className='wallet-account-balance-item--header clearfix'>
                    {shouldShowAccountTypeTag && <div className='wallet-account-balance-item--header--info-bar'>
                        <div className='wallet-account-balance-item--header--info-bar--account-type spot'>{'WALLET'}</div>
                        <div className='wallet-account-balance-item--header--info-bar--timestamp'>{moment(walletAccountBalance.ts).format('HH:mm:ss')}</div>
                    </div>}
                    <div className='wallet-account-balance-item--coin'>
                        {walletAccountBalance.coin}
                        {shouldShowAccountName ? ` @ ${walletAccountBalance.acct_name}` : ''}
                    </div>
                    {!shouldShowAccountTypeTag && <div className='wallet-account-balance-item--timestamp'>{moment(walletAccountBalance.ts).format('HH:mm:ss')}</div>}
                </div>
                <div className='wallet-account-balance-item--rows'>
                    {shouldShowDetail && this.Row({
                        name: 'Available',
                        value: toNumberWithSmartPrecision({ number: walletAccountBalance.avaiable, shouldReturnLocalString: true, shouldApplyFloorValue: true })
                    })}
                    {shouldShowDetail && this.Row({
                        name: 'On Hold',
                        value: toNumberWithSmartPrecision({ number: walletAccountBalance.hold, shouldReturnLocalString: true, shouldApplyFloorValue: true })
                    })}
                    {this.Row({
                        name: 'Wallet Balance',
                        value: toNumberWithSmartPrecision({ number: walletAccountBalance.balance, shouldReturnLocalString: true, shouldApplyFloorValue: true })
                    })}
                </div>
            </div>
        )
    }
}

WalletAccounBalanceItem.propTypes = {
    walletAccountBalance: PropTypes.object.isRequired,
    shouldShowAccountTypeTag: PropTypes.bool,
    shouldShowAccountName: PropTypes.bool,
    shouldShowDetail: PropTypes.bool
}