import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment/moment'
import _ from 'lodash'

import Popup from '../common/popup/Popup'
import Checkbox from '../common/checkbox/Checkbox'
import AccountBalanceItem from '../account/AccountBalanceItem'
import AccountAssetItem from '../account/AccountAssetItem'
import PositionItem from '../trading/PositionItem'
import SymbolFundingRates from './SymbolFundingRates'

import { getSymbolAttributeByName } from '../../util/symbolUtil'
import { getProfileTradingAccountNamesBySymbol } from '../../util/profileUtil'
import { getFilteredAccountBalanceBySymbol, getPortfolioNames } from '../../util/accountUtil'
import { getNotional } from '../../util/tradingUtil'
import { toNumberWithSmartPrecision } from '../../util/util'

class SymbolPopup extends Component {
    constructor (props) {
        super(props)
        this.state = {
            order: {
                price: null,
                unit: null
            },
            shouldShowOrder: false,
            shouldShowAccountBalanceDetail: false,
            shouldShowPositionDetail: false,
            shouldShowFundingRateDetail: false,
            selectedPortfolios: this._getDefaultSelectedPortfolios(),
            isPopupOpen: false
        }
    }

    componentDidUpdate (prevProps) {
        const { accountItems: prevAccountItems } = prevProps
        const { accountItems } = this.props
        const { selectedPortfolios } = this.state
        if (_.isEmpty(prevAccountItems) && !_.isEmpty(accountItems) && _.isEmpty(selectedPortfolios)) {
            this._updateDefaultSelectedPortfolios()
        }
    }

    _getDefaultSelectedPortfolios () {
        const { profileItem, symbolName, accountItems } = this.props
        const tradingAccountNames = getProfileTradingAccountNamesBySymbol(profileItem, symbolName)
        const selectedPortfolios = []
        _.forEach(_.concat(tradingAccountNames.BUY, tradingAccountNames.SELL).map(accountName => {
            const accountItem = accountItems[accountName]
            if (_.has(accountItem, 'portfolio_name')) {
                selectedPortfolios.push(accountItem.portfolio_name)
            }
        }))
        return _.uniq(selectedPortfolios)
    }

    _updateDefaultSelectedPortfolios () {
        this.setState({ selectedPortfolios: this._getDefaultSelectedPortfolios() })
    }

    AccountBalances () {
        const { shouldShowAccountBalanceDetail } = this.state
        const { symbolName, symbolItem, profileItem, accountBalance, accountAsset } = this.props
        const { base, quote } = getSymbolAttributeByName(symbolName)

        const symbolTradingAccountNames = getProfileTradingAccountNamesBySymbol(profileItem, symbolName)
        const unionTradingAccountNames = _.uniq([...symbolTradingAccountNames.BUY, ...symbolTradingAccountNames.SELL])
        
        return (
            <div className='symbol-popup--account-balance'>
                <div className='symbol-popup--account-balance--header'>
                    <div className='symbol-popup--account-balance--title'>{'Account Balance'}</div>
                    <button className='symbol-popup--account-balance--toggle-detail-button'
                        onClick={() => { this.setState({ shouldShowAccountBalanceDetail: !shouldShowAccountBalanceDetail }) }}>{shouldShowAccountBalanceDetail ? 'Hide Detail' : 'Show Detail'}</button>
                </div>
                <div className='symbol-popup--account-balance--body'>
                    {unionTradingAccountNames.map((accountName, index) => {
                        const filteredAccountBalance = getFilteredAccountBalanceBySymbol({ 
                            accountBalance,
                            symbolItem,
                            accountName
                        })
                        const accountAssetItem = accountAsset[accountName]
                        
                        return (
                            <div className='symbol-popup--account-balance--item' key={index}>
                                {_.some(Object.values(filteredAccountBalance, accountBalances => !_.isEmpty(accountBalances))) ? <AccountBalanceItem 
                                    filteredAccountBalance={filteredAccountBalance}
                                    accountName={accountName}
                                    shouldShowDetails={shouldShowAccountBalanceDetail}
                                    emptyMessage={'No Balance Data'} 
                                    shouldShowAccountTypeTab />
                                : !_.isEmpty(accountAssetItem) ? <AccountAssetItem 
                                    accountAssetItem={accountAssetItem} 
                                    tokensToFilter={[base, quote]} />
                                : null}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    Positions () {
        const { symbolName, symbolItem, positions, pricings, accountItems } = this.props
        const { shouldShowPositionDetail, selectedPortfolios } = this.state
        const portfolioNames = getPortfolioNames()
        const filteredPositions = _.filter(positions, (position) => {
            const { product_name, account_name } = position
            const accountItem = accountItems[account_name]
            return product_name === symbolName && _.has(accountItem, 'portfolio_name') && selectedPortfolios.includes(accountItem.portfolio_name)
        })

        const { quote } = getSymbolAttributeByName(symbolName) 
        const pricingItem = pricings[symbolName]
        const notionalSum = filteredPositions.reduce((result, positionItem) => {
            const notional = getNotional({
                symbolItem: symbolItem,
                quantity: Number(positionItem.long_position) - Number(positionItem.short_position),
                price: pricingItem ? ( pricingItem.last || (pricingItem.bid + pricingItem.ask)/2 ) : null,
                BTCUSDIndexLastPrice: _.has(pricings, `btc_usd_OKEX_INDEX.last`) ? pricings['btc_usd_OKEX_INDEX'].last : null
            })
            return result + Number(notional)
        }, 0)

        return (
            <div className='symbol-popup--positions'>
                <div className='symbol-popup--positions--header'>
                    <div className='symbol-popup--positions--title'>{'Positions'}</div>
                    <button className='symbol-popup--positions--toggle-detail-button' 
                        onClick={() => { this.setState({ shouldShowPositionDetail: !shouldShowPositionDetail }) }}>{shouldShowPositionDetail ? 'Hide Detail' : 'Show Detail'}</button>
                </div>
                <div className='symbol-popup--positions--sub-title'>{'Porfolios'}</div>
                <div className='symbol-popup--positions--portfolios clearfix' onClick={(e) => { e.stopPropagation() }}>
                    {_.map(portfolioNames, (portfolioName, index) => {
                        return (
                            <div className='symbol-popup--positions--portfolios--item' key={index}>
                                <label>{portfolioName}</label>
                                <Checkbox 
                                    checked={selectedPortfolios.includes(portfolioName)} 
                                    onChange={(newChecked) => { 
                                        this.setState({
                                            selectedPortfolios: newChecked ? _.union(selectedPortfolios, [portfolioName]) : _.without(selectedPortfolios, portfolioName)
                                        })
                                    }} />
                            </div>
                        )
                    })}
                </div>
                {!_.isEmpty(filteredPositions)
                ? <Fragment>
                    <div className='symbol-popup--positions--notional-sum clearfix'>
                        <div className='symbol-popup--positions--notional-sum--name'>{'Notional Sum'}</div>
                        <div className={'symbol-popup--positions--notional-sum--value'}>
                            <span className={notionalSum > 0 ? 'positive' : notionalSum < 0 ? 'negative' : null}>{toNumberWithSmartPrecision({ number: notionalSum, shouldReturnLocalString: true })}</span>
                            <span>{` ${quote}`}</span>
                        </div>
                    </div>
                    <div className='symbol-popup--positions--list'>
                        {filteredPositions.map((position, index) => (
                            <div className='symbol-popup--positions--item' key={index}>
                                <PositionItem 
                                    positionItem={position} 
                                    hideDetails={!shouldShowPositionDetail} />
                            </div>
                        ))}
                    </div>
                </Fragment>
                : <div className='symbol-popup--positions--empty-message'>{'No Position Data'}</div>}
            </div>
        )
    }

    render () {
        const { symbolName, fundingRateLastUpdateTime } = this.props
        const { isPopupOpen } = this.state
        const { base, quote } = getSymbolAttributeByName(symbolName)
        return (
            <Popup className='symbol-popup'
                on={'click'}
                trigger={<div className='symbol-popup--trigger'>{symbolName}</div>}
                onOpen={() => { this.setState({ isPopupOpen: true }) }}
                onClose={() => { this.setState({ isPopupOpen: false }) }}>
                {isPopupOpen && <Fragment>
                    <div className='symbol-popup--title'>
                        {'Symbol: '}<span>{symbolName}</span>
                    </div>
                    <div className='symbol-popup--body' onClick={(e) => { e.stopPropagation() }}>
                        <div className='symbol-popup--row-1'>
                            {this.AccountBalances()}
                            {this.Positions()}
                            <div className='symbol-popup--funding-rates'>
                                <div className='symbol-popup--funding-rates--title'>
                                    {'Funding %'}
                                    {fundingRateLastUpdateTime && <span className='symbol-popup--funding-rates--title--last-update-time'>{`Updated Time: ${fundingRateLastUpdateTime}`}</span>}
                                </div>
                                <SymbolFundingRates 
                                    haveQuoteSelectors
                                    baseToFilter={base} 
                                    quotes={[quote]} />
                            </div>
                        </div>
                    </div>
                </Fragment>}
            </Popup>
        )
    }
}

SymbolPopup.propTypes = {
    symbolName: PropTypes.string.isRequired,

    symbolItem: PropTypes.object.isRequired,
    profileItem: PropTypes.object.isRequired,
    accountItems: PropTypes.object.isRequired,
    accountBalance: PropTypes.object.isRequired,
    accountAsset: PropTypes.object.isRequired,
    positions: PropTypes.array.isRequired,
    pricings: PropTypes.object.isRequired,
    fundingRateLastUpdateTime: PropTypes.string
}

function mapStateToProps (state, ownProps) {
    return {
        symbolItem: state.symbol.items[ownProps.symbolName],
        profileItem: state.profile.items[ownProps.profileId],
        accountItems: state.account.items,
        accountBalance: state.account.balance,
        accountAsset: state.account.asset,
        positions: state.trading.positions,
        pricings: state.symbol.pricings,
        fundingRateLastUpdateTime: !_.isEmpty(state.symbol.fundingRates) ? moment(_.get(Object.values(state.symbol.fundingRates), '0.current')).format('HH:mm:ss') : null
    }
}

export default connect(mapStateToProps)(SymbolPopup)





