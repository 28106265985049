import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import dotProps from 'dot-prop-immutable'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'

import WorkspaceTabs from './WorkspaceTabs'
import WorkspaceLayout from './WorkspaceLayout'
import { fetchWorkspaces, saveWorkspaces } from './workspaceAction'

export const WorkspaceLayoutItem = ({ i='', x=0, y=0, w=0, h=0 }) => {
    return { i, x, y, w, h }
}

export const WorkspaceComponentItem = ({ id='', componentKey='', layout={} }) => {
    return {
        id,
        componentKey,
        layout: layout || WorkspaceLayoutItem({ i: id })
    }   
}

export const Workspace = ({ id='', name='', components={} }) => {
    return {
        id,
        name,
        components
    }
}

let cachedWorkspaceState = null
class WorkspacePage extends Component {
    constructor (props) {
        super(props)
        const initialWrokspaceState = cachedWorkspaceState || {
            workspaces: [],
            selectedWorkspaceId: null
        }
        this.state = {
            isFetching: false,
            isSaving: false,
            message: '',
            ...initialWrokspaceState
        }

        this.handleFocusWindow = this.handleFocusWindow.bind(this)

        this.fetchId = null
        this.saveId = null
        this._mounted = false
    }

    componentDidMount () {
        this._mounted = true
        this._getWorkspaces()
        window.addEventListener('focus', this.handleFocusWindow)
    }

    componentWillUnmount () {
        cachedWorkspaceState = _.cloneDeep(_.pick(this.state, ['workspaces', 'selectedWorkspaceId']))
        this._mounted = false
        window.removeEventListener('focus', this.handleFocusWindow)
    }

    handleFocusWindow () {
        this._getWorkspaces()
    }

    _getWorkspaces () {
        const { dispatch } = this.props
        const fetchId = uuidv4() 
        this.fetchId = fetchId
        this.setState({ isFetching: true })
        dispatch(fetchWorkspaces())
        .then(body => {
            if (this._mounted && _.has(body, 'data') && _.isArray(body.data)) {
                this.setState((prevState) => {
                    return {
                        workspaces: body.data,
                        selectedWorkspaceId: _.isNil(prevState.selectedWorkspaceId) && !_.isEmpty(body.data) ? _.head(body.data).id : prevState.selectedWorkspaceId
                    }
                })
            }
        })
        .finally(() => {
            if (this._mounted && this.fetchId === fetchId) {
                this.setState({ isFetching: false })
            }
        })
    }

    _updateWorkspaces (newWorkspaces=[]) {
        const { dispatch } = this.props
        const { workspaces } = this.state
        this.setState({ workspaces: newWorkspaces })

        if (!_.isEqual(workspaces, newWorkspaces)) {
            const saveId = uuidv4() 
            this.saveId = saveId
            this.setState({ 
                isSaving: true,
                saveSuccess: false,
                saveFailed: false
            })
            dispatch(saveWorkspaces(newWorkspaces))
            .then(response => {
                if (this._mounted && this.saveId === saveId) {
                    const isSuccess = !_.isNil(response) && [200, 202].includes(response.status)
                    this.setState({
                        message: !isSuccess ? 'Save workspace failed' : ''
                    })
                }
            })
            .finally(() => {
                if (this._mounted && this.saveId === saveId) {
                    this.setState({ isSaving: false })
                }
            })
        }
    }

    render () {
        const { workspaces, selectedWorkspaceId, isSaving } = this.state
        const workspaceIndex = _.findIndex(workspaces, { id: selectedWorkspaceId })
        return (
            <div className='workspace-container'>
                <div className='workspace-container--tabs'>
                    <WorkspaceTabs 
                        workspaces={workspaces}
                        selectedWorkspaceId={selectedWorkspaceId} 
                        onSelectWorkspace={(newWorkspaceId) => { this.setState({ selectedWorkspaceId: newWorkspaceId }) }} 
                        onClickAdd={() => {
                            const newWorkspaces = _.cloneDeep(workspaces)
                            const newWorksapceId = uuidv4()
                            newWorkspaces.push(Workspace({
                                id: newWorksapceId,
                                name: `Workspace - ${_.random(100, 999)}`
                            }))
                            this._updateWorkspaces(newWorkspaces)
                            this.setState({ selectedWorkspaceId: newWorksapceId })
                        }}
                        onChangeWorkspaces={(newWorkspaces) => { 
                            this._updateWorkspaces(newWorkspaces)
                        }} />
                </div>
                <div className='workspace-container--layout'>
                    {workspaceIndex > -1 && 
                    <WorkspaceLayout 
                        workspace={workspaces[workspaceIndex]}
                        onChange={(newWorkspace) => {
                            this._updateWorkspaces(dotProps.set(workspaces, workspaceIndex, newWorkspace))
                        }} />}
                </div>
                {isSaving && <div className='workspace-container--saving-message'>
                    <span className='workspace-container--saving-message--dot' />
                    <label>{'Saving'}</label>
                </div>}
            </div>
        )
    }
}

WorkspacePage.propTypes = {
    dispatch: PropTypes.func.isRequired
}

function mapStateToProps () {
    return {}
}

export default connect(mapStateToProps)(WorkspacePage)