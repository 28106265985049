import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { IoMdNotificationsOutline } from 'react-icons/io'

import Popup from '../common/popup/Popup'
import NotificationContainer from '../trading/NotificationContainer'
import { connect } from 'react-redux'

class ProfileNotificationPopup extends Component {
    render () {
        const { profileItems, profileId } = this.props
        const profileItem = profileItems[profileId]
        return (
            <Popup
                className='profile-notification-popup'
                on={'click'}
                trigger={
                    <button className='profile-notification-popup--trigger'>
                        <IoMdNotificationsOutline />
                    </button>}>
                <div className='profile-notification-popup--header'>
                    {`${profileItem ? profileItem.name : profileId} Notifications`}
                </div>
                <div className='profile-notification-popup--main'>
                    <NotificationContainer
                        profileId={profileId} 
                        shouldHideHeader />
                </div>
            </Popup>
        )
    }
}

ProfileNotificationPopup.propTypes = {
    profileItems: PropTypes.object.isRequired,
    profileId: PropTypes.string.isRequired
}

function mapStateToProps (state) {
    return {
        profileItems: state.profile.items
    }
}

export default connect(mapStateToProps)(ProfileNotificationPopup)