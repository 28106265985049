import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import BigNumber from 'bignumber.js'
import greeks from 'greeks'
import moment from 'moment'
import _ from 'lodash'

import Popup from '../common/popup/Popup'
import { getOptionSymbolUnderlying, getSymbolAttributeByName, INSTRUMENT_TYPES } from '../../util/symbolUtil'

class OptionSymbolGreeks extends Component {
    constructor (props) {
        super(props)
        this.state = {
            shouldShowPopup: false
        }
    }

    _getUnderlyingSymbolName () {
        const { symbolItems, optionSymbolName } = this.props
        const { optionExpiryDate, exchangeName: optionExchangeName } = getSymbolAttributeByName(optionSymbolName)
        const underlying = getOptionSymbolUnderlying(optionSymbolName)
        const optionExpiryMoment = moment(optionExpiryDate).endOf('day')
        const filteredSymbolItems = _.filter(symbolItems, symbolItem => {
            const { base, exchangeName, instrumentType } = getSymbolAttributeByName(symbolItem.symbol_name)
            return base === underlying 
                && exchangeName === optionExchangeName 
                && (instrumentType === INSTRUMENT_TYPES.SWAP || moment(symbolItem.expiry).endOf('day').isSame(optionExpiryMoment))
        })
        const pickedSymbolItem = _.maxBy(filteredSymbolItems, symbolItem => moment(symbolItem.expiry).valueOf())
        return _.get(pickedSymbolItem, 'symbol_name')
    }

    _getGreeks (shouldReturnDeltaOnly=false) {
        const { optionSymbolName, symbolItems, pricings, optionImpliedVolatilities } = this.props
        const result = {
            underlyingSymbolName: null,
            underlyingCurrentPrice: null,
            volatilityItem: null,
            volatility: null,
            daysToExpire: null,
            timeToExpirationInYears: null,
            annualRiskFreeInterestRate: 0,
            delta: null,
            gamma: null,
            vega: null,
            theta: null
        }

        const optionSymbolItem = symbolItems[optionSymbolName]
        const { optionStrikePrice } = getSymbolAttributeByName(optionSymbolName)
        const optionExpiry = _.get(optionSymbolItem, 'expiry')
        result.volatilityItem = _.get(optionImpliedVolatilities, optionSymbolName)
        result.underlyingSymbolName = this._getUnderlyingSymbolName()
        result.underlyingCurrentPrice = Number(_.get(pricings, `${result.underlyingSymbolName}.last`))
        result.daysToExpire = moment(optionExpiry).diff(moment(), 'days', true)
        result.timeToExpirationInYears = Number(result.daysToExpire) / 365
        const callPut = _.endsWith(optionSymbolName, 'CALL') ? 'call'
            : _.endsWith(optionSymbolName, 'PUT') ? 'put'
            : null
        if (!_.isNil(result.volatilityItem) && result.underlyingCurrentPrice > 0 && Number(result.timeToExpirationInYears) > 0 && !_.isNil(callPut)) {
            const { bid_implied_volatility: bidIV, ask_implied_volatility: askIV } = result.volatilityItem
            const _bidIV = BigNumber(bidIV || 0)
            const _askIV = BigNumber(askIV || 0)
            result.volatility = (_bidIV.minus(_askIV)).div(_askIV).abs().lt(1)
                ? (_bidIV.plus(_askIV)).div(2).toNumber()
                : _askIV.toNumber()
            result.delta = greeks.getDelta(result.underlyingCurrentPrice, Number(optionStrikePrice), result.timeToExpirationInYears, result.volatility, result.annualRiskFreeInterestRate, callPut)

            
            if (!shouldReturnDeltaOnly) {
                result.gamma = greeks.getGamma(result.underlyingCurrentPrice, Number(optionStrikePrice), result.timeToExpirationInYears, result.volatility, result.annualRiskFreeInterestRate)
                result.vega = greeks.getVega(result.underlyingCurrentPrice, Number(optionStrikePrice), result.timeToExpirationInYears, result.volatility, result.annualRiskFreeInterestRate)
                result.theta = greeks.getTheta(result.underlyingCurrentPrice, Number(optionStrikePrice), result.timeToExpirationInYears, result.volatility, result.annualRiskFreeInterestRate)
            }
        }
        return result
    }

    Main () {
        const greeks = this._getGreeks()
        return (
            <div className='option-symbol-greeks--main'>
                <div>
                    <label>{'Delta'}</label>
                    <span>{_.isNil(greeks.delta) ? 'N/A' : BigNumber(greeks.delta).toFixed(4)}</span>
                </div>
                <div>
                    <label>{'Gamma'}</label>
                    <span>{_.isNil(greeks.gamma) ? 'N/A' : BigNumber(greeks.gamma).toFixed(6)}</span>
                </div>
                <div>
                    <label>{'Vega'}</label>
                    <span>{_.isNil(greeks.vega) ? 'N/A' : BigNumber(greeks.vega).toFixed(4)}</span>
                </div>
                <div>
                    <label>{'Theta'}</label>
                    <span>{_.isNil(greeks.theta) ? 'N/A' : BigNumber(greeks.theta).toFixed(4)}</span>
                </div>
                <hr />
                <div>
                    <label>{'Days to expire'}</label>
                    <span>{_.isNil(greeks.daysToExpire) ? 'N/A' : BigNumber(greeks.daysToExpire).toFixed(2)}</span>
                </div>
                <div>
                    <label>{'Time To Expiration (In Years)'}</label>
                    <span>{_.isNil(greeks.timeToExpirationInYears) ? 'N/A' : BigNumber(greeks.timeToExpirationInYears).toFixed(4)}</span>
                </div>
                <div>
                    <label>{'Volatility'}</label>
                    <span>{_.isNil(greeks.volatility) ? 'N/A' : BigNumber(greeks.volatility).toFixed(4)}</span>
                </div>
                <div>
                    <label>{'Annual Risk-free Interest Rate'}</label>
                    <span>{_.isNil(greeks.annualRiskFreeInterestRate) ? 'N/A' : `${BigNumber(greeks.annualRiskFreeInterestRate).times(100).toFixed(2, 1)}%`}</span>
                </div>
                <div>
                    <label>{'Underlying Symbol'}</label>
                    <span>{greeks.underlyingSymbolName || 'N/A'}</span>
                </div>
                <div>
                    <label>{'Underlying Current PX.'}</label>
                    <span>{_.isNil(greeks.underlyingCurrentPrice) ? 'N/A' : BigNumber(greeks.underlyingCurrentPrice).toFixed(4)}</span>
                </div>
            </div>
        )
    }

    render () {
        const { shouldShowPopup } = this.state
        const greeks = this._getGreeks(true)

        return (
            <Popup className={'option-symbol-greeks--popup'}
                trigger={
                    <div className='option-symbol-greeks'>
                        <label>{'Delta: '}</label>
                        <span>{_.isNil(greeks.delta) ? 'N/A' : BigNumber(greeks.delta).toFixed(4)}</span>
                    </div>
                }
                onOpen={() => { this.setState({ shouldShowPopup: true }) }}
                onClose={() => { this.setState({ shouldShowPopup: false }) }}>
                {shouldShowPopup && this.Main()}
            </Popup>
        )
    }
}

OptionSymbolGreeks.propTypes = {
    symbolItems: PropTypes.object.isRequired,
    optionImpliedVolatilities: PropTypes.object.isRequired,
    optionSymbolName: PropTypes.string.isRequired,
    pricings: PropTypes.object.isRequired
}

function mapStateToProps (state) {
    return {
        symbolItems: state.symbol.items,
        optionImpliedVolatilities: state.symbol.optionImpliedVolatilities,
        pricings: state.symbol.pricings
    }
}

export default connect(mapStateToProps)(OptionSymbolGreeks)