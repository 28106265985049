import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Popup from '../common/popup/Popup'
import Checkbox from '../common/checkbox/Checkbox'

import { isMetSearchStringCriteria } from '../../util/util'

class SymbolMultipleSelector extends Component {
    constructor (props) {
        super(props)
        this.state = {
            searchString: ''
        }
        this.searchInputNode = null
    }

    render () {
        const { className, symbolItems, symbolOptions, selectedSymbolNames, trigger, disabled, popupAlign, onChangeSelectedSymbolNames } = this.props
        const { searchString } = this.state
        const filteredSymbolOptions = _.filter(symbolOptions, accountName => isMetSearchStringCriteria(accountName || '', searchString))
        const filteredSelectedSymbolNames = _.filter(selectedSymbolNames, accountName => isMetSearchStringCriteria(accountName || '', searchString))
        const isEveryFilteredSymbolSelected = _.every(filteredSymbolOptions, accountName => filteredSelectedSymbolNames.includes(accountName))
        return (
            <Popup className='symbol-multiple-selector'
                on={'click'}
                align={popupAlign}
                disabled={disabled}
                trigger={!_.isNil(trigger) ? trigger : <button className={'symbol-multiple-selector--trigger' + (className ? ` ${className}` : '')}>{`${_.size(selectedSymbolNames)}/${_.size(symbolOptions)} Selected`}</button>}
                onOpen={() => { 
                    this.setState({ searchString: '' }) 
                    if (this.searchInputNode) {
                        this.searchInputNode.focus()
                    }
                }}>
                <div className='symbol-multiple-selector--header'>{`Select Symbols (${_.size(selectedSymbolNames)}/${_.size(symbolOptions)})`}</div>
                <div className='symbol-multiple-selector--config-row clearfix'>
                    <button className='symbol-multiple-selector--toggle-all-button'
                        onClick={() => {
                            if (isEveryFilteredSymbolSelected) {
                                onChangeSelectedSymbolNames(_.without(selectedSymbolNames, ...filteredSelectedSymbolNames))
                            } else if (_.size(filteredSymbolOptions) > 0 ) {
                                onChangeSelectedSymbolNames(_.union(selectedSymbolNames, filteredSymbolOptions))
                            }
                        }}>
                        {isEveryFilteredSymbolSelected ? 'Unselect All' : 'Select All'}
                    </button>
                    <input className='symbol-multiple-selector--search-string'
                        ref={(node) => { this.searchInputNode = node }}
                        type={'text'}
                        placeholder={'Search Symbols'}
                        spellCheck={false}
                        value={searchString}
                        onChange={(e) => { this.setState({ searchString: e.target.value }) }} />
                </div>
                <div className='symbol-multiple-selector--main' onClick={(e) => { e.stopPropagation() }}>
                    {_.map(filteredSymbolOptions.sort(), symbolName => {
                        return (
                            <div className='symbol-multiple-selector--item' key={symbolName}
                                title={!_.has(symbolItems, symbolName) ? 'Symbol is invalid' : null}>
                                <span className={!_.has(symbolItems, symbolName) ? 'not-exist-warning' : null}>{symbolName}</span>
                                <Checkbox checked={filteredSelectedSymbolNames.includes(symbolName)}
                                    onChange={(newChecked) => {
                                        if (newChecked) {
                                            onChangeSelectedSymbolNames(_.union(selectedSymbolNames, [symbolName]))
                                        } else {
                                            onChangeSelectedSymbolNames(_.without(selectedSymbolNames, symbolName))
                                        }
                                    }} />
                            </div>
                        )
                    })}
                </div>
            </Popup>
        )
    }
}

SymbolMultipleSelector.propTypes = {
    symbolItems: PropTypes.object.isRequired,

    className: PropTypes.string,
    symbolOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedSymbolNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    trigger: PropTypes.node,
    disabled: PropTypes.bool,
    popupAlign: PropTypes.string,
    onChangeSelectedSymbolNames: PropTypes.func.isRequired
}

SymbolMultipleSelector.defaultProps = {
    symbolOptions: [],
    selectedSymbolNames: [],
    disabled: false,
    popupAlign: 'horizontal',
    onChangeSelectedSymbolNames: () => {}
}

function mapStateToProps (state) {
    return {
        symbolItems: state.symbol.items
    }
}

export default connect(mapStateToProps)(SymbolMultipleSelector)