import React, { Component } from 'react'
import PropTypes from 'prop-types'

import BigNumber from 'bignumber.js'
import _ from 'lodash'

import { toNumberWithSmartPrecision } from '../../util/util'

export default class PureOrderBook extends Component {

    getSeivedOrderBook () {
        const { orderBook } = this.props
        const result = _.cloneDeep(orderBook)
        if (result && _.isArray(result.bids) && _.isArray(result.asks)) {
            _.remove(result.bids, bidItem => Number(bidItem.qty) === 0)
            _.remove(result.asks, askItem => Number(askItem.qty) === 0)
            result.bids = _.take(_.sortBy(result.bids, bidItem => -Number(bidItem.price)), 5)
            result.asks = _.take(_.sortBy(result.asks, askItem => Number(askItem.price)), 5)
            let bidCumulativeQty = 0, askCumulativeQty = 0
            result.bids.forEach(bidItem => {
                bidCumulativeQty += bidItem.qty
                bidItem.cumulativeQty = bidCumulativeQty
            })
            result.asks.forEach(askItem => {
                askCumulativeQty += askItem.qty
                askItem.cumulativeQty = askCumulativeQty
            })
            _.reverse(result.asks)
        }
        return result
    }

    Block (side = 'buy') {
        const { onClickPrice, onClickAmount } = this.props
        const seivedOrderBook = this.getSeivedOrderBook()
        const bidCumulativeQty = seivedOrderBook && !_.isEmpty(seivedOrderBook.bids) ? _.last(seivedOrderBook.bids).cumulativeQty : 0
        const askCumulativeQty = seivedOrderBook && !_.isEmpty(seivedOrderBook.asks) ? _.first(seivedOrderBook.asks).cumulativeQty : 0
        const data = seivedOrderBook ? (seivedOrderBook[side === 'buy'  ? 'bids' : 'asks'] || []) : []
        const maxCumulativeQty = Math.max(bidCumulativeQty, askCumulativeQty)
        return (
            <div className={`order-book--block ${side}`}>
                {data.map((item, index) => {
                    const { price, qty, cumulativeQty } = item
                    const amountNumber = toNumberWithSmartPrecision({ 
                        number: qty, 
                        shouldReturnLocalString: true 
                    })
                    return (
                        <div className='order-book--block--item clearfix' key={index}>
                            <div className={`order-book--block--item--cumulative-amount-bar ${side}`} 
                                style={{
                                    width: (cumulativeQty / maxCumulativeQty) * 100 + '%'
                                }} />
                            <div className={`order-book--block--item--price ${side}`} onClick={() => { onClickPrice(price) }}>{BigNumber(price).toFormat()}</div>
                            <div className='order-book--block--item--amount' onClick={() => { onClickAmount(qty) }}>{amountNumber}</div>
                        </div>
                    )
                })}
            </div>
        )
    }

    render () {
        const { amountLabel } = this.props
        return (
            <div className='order-book'>
                <div className='order-book--body'>
                    <div className='order-book--body--header clearfix'>
                        <div className='order-book--body--header--text price'>{'Price'}</div>
                        <div className='order-book--body--header--text amount'>{amountLabel || 'Amount'}</div>
                    </div>
                    <div className='order-book--body--main'>
                        {this.Block('sell')}
                        {this.Block('buy')}
                    </div>
                </div>
            </div>
        )
    }
}

PureOrderBook.propTypes = {
    orderBook: PropTypes.object.isRequired,
    amountLabel: PropTypes.string,
    onClickPrice: PropTypes.func,
    onClickAmount: PropTypes.func
}

PureOrderBook.defaultProps = {
    amountLabel: 'Amount',
    shouldHideTitle: false,
    onClickPrice: () => {},
    onClickAmount: () => {}
}