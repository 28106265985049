import React, { Fragment, memo, useEffect, useMemo, useState } from 'react'
import useEvent from 'react-use-event-hook'
import { useLatest, useMountedState } from 'react-use'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { ethers, utils } from 'ethers'
import { isAddress } from 'viem'
import _ from 'lodash'

import { FaTimes } from 'react-icons/fa'

import Checkbox from '../common/checkbox/Checkbox'
import VaultExecutePopup from './VaultExecutePopup'

import { areAllValuesNonEmpty, isMetSearchStringCriteria } from '../../util/util'
import { ABI as VAULT_ABI } from './ABIs/Vault'
import { ABI as PERPS_V2_MARKET_CONSLIDATED_ABI} from './ABIs/PerpsV2MarketConsolidated'
import { SYNTHETIX_PERPS_ADDRESSES, VAULT_CHAIN, VAULT_SLOT } from './blockchainConfig'

import { readStorage, sendTransactionWithWallet } from './blockchainThunk'
import { bytes32ToAddr, getEthersJsonRpcBatchProvider, parseABI } from './blockchainUtil'
import { formattedHex } from '../../util/formatUtil'
import { getDefaultChain } from '../../wagmiConfig'


const { FormatTypes, Interface, hexZeroPad } = utils

const PERPS_V2_MARKET_CONSOLIDATED_INTERFACE = new Interface(PERPS_V2_MARKET_CONSLIDATED_ABI)
const CONTRACT_NAME_PER_PROXY_PERPS_V2_ADDRESS = _.invert(SYNTHETIX_PERPS_ADDRESSES)
const PROVIDER = getEthersJsonRpcBatchProvider({ chainId: VAULT_CHAIN.id })

const TAB = {
    ACTIONS_ADDED: {
        key: 'ACTIONS_ADDED',
        name: 'Actions Added'
    },
    ALL_ACTIONS: {
        key: 'ALL_ACTIONS',
        name: 'All Actions'
    }
}

const _getActionId = (action) => {
    const { address, sigHash, payable } = action
    return areAllValuesNonEmpty([address, sigHash, payable]) ? `${address}-${sigHash}${payable === true ? '-payable' : ''}` : null
}

const _getPerpsV2ContractName = (address) => {
    return CONTRACT_NAME_PER_PROXY_PERPS_V2_ADDRESS[address] ?? 'Unkown'
}

export const _getFunctionBySigHash = (sigHash) => {
    let fragment
    try {
        fragment = PERPS_V2_MARKET_CONSOLIDATED_INTERFACE.getFunction(sigHash)
    } catch {
        fragment = null
    }
    return fragment
}

const _getFunctionNameBySigHash = (sigHash, format=FormatTypes.full) => {
    let fragment
    try {
        fragment = PERPS_V2_MARKET_CONSOLIDATED_INTERFACE.getFunction(sigHash)
    } catch {
        fragment = null
    }
    return !_.isNil(fragment) ? fragment.format(format) : 'Unkown'
}

/**
 * @param {string} contract 
 * @param {string} sigHash 
 * @param {boolean} payable 
 * @returns {string}
 */
const _encodeAction = (contract='', sigHash='', payable=false) => {
    const action = ethers.BigNumber.from(contract)
        .or(ethers.BigNumber.from(sigHash).shl(256 - 32))
        .or(ethers.BigNumber.from(payable ? 1 : 0).shl(160))
    return hexZeroPad(action.toHexString(), 32)
}

/**
 * @param {string} action - Vault action
 * @returns {Object}
 */
const _decodeAction = (action) => {
    return {
        address: bytes32ToAddr(action),
        sigHash: action.slice(0, 10),
        payable: !ethers.BigNumber.from(action).and(ethers.BigNumber.from(1).shl(160)).eq(0)
    }
}

const _fetchActions = async (contractAddress) => {
    const actions = {}
    const length = ethers.BigNumber.from(await readStorage({
        provider: PROVIDER,
        contract: contractAddress,
        path: [VAULT_SLOT.ACTION_SET]
    })).toNumber()

    await Promise.all(_.map(_.range(length), async (index) => {
        const action = _decodeAction(await readStorage({
            provider: PROVIDER,
            contract: contractAddress,
            path: [VAULT_SLOT.ACTION_SET, index],
            pathType: 'OA'
        }))
        actions[_getActionId(action)] = action
    }))

    return actions
}

const ALL_ACTIONS = _.reduce(_.values(SYNTHETIX_PERPS_ADDRESSES), (result, address) => {
    _.forEach(PERPS_V2_MARKET_CONSOLIDATED_INTERFACE.functions, (item, functionName) => {
        const { payable, stateMutability } = item
        if (stateMutability !== 'view') {
            const action = {
                address,
                sigHash: PERPS_V2_MARKET_CONSOLIDATED_INTERFACE.getSighash(functionName),
                payable
            }
            result[_getActionId(action)] = action
        }
    })
    return result
}, {})

function VaultActions ({ vaultAddress, owner }) {

    const isMounted = useMountedState()

    const [searchString, setSearchSting] = useState('')
    const [actions, setActions] = useState({}) // actionId -> action
    const [canSelectActions, setCanSelectActions] = useState('')
    const [selectedActions, setSelectedActions] = useState({}) // actionId -> action
    const [tab, setTab] = useState(TAB.ACTIONS_ADDED.key)
    const [message, setMessage] = useState('')
    const [txHash, setTxHash] = useState('')
    const latestVaultAddress = useLatest(vaultAddress)

    const defaultChain = getDefaultChain()

    const updateActions = useEvent(async () => {
        if (isAddress(vaultAddress)) {
            try {
                const newActions = await _fetchActions(vaultAddress)
                if (isMounted() && vaultAddress === latestVaultAddress.current) {
                    setActions(newActions)
                }
            } catch (error) {
                console.error('updateActions error: ', error)
            }
        }

    })

    useEffect(() => {
        if (!_.isEmpty(vaultAddress)) {
            setActions({})
        }
        updateActions()
    }, [vaultAddress])

    const handleClickAddActions = useEvent(async () => {
        if (!_.isEmpty(selectedActions)) {
            sendTransactionWithWallet({
                address: vaultAddress,
                abi: parseABI(VAULT_ABI),
                functionName: 'addActions',
                args: [_.map(selectedActions, action => {
                    const { address, sigHash, payable } = action
                    return _encodeAction(address, sigHash, payable)
                })],
                account: owner
            }).then((receipt) => {
                if (isMounted()) {
                    const { transactionHash } = receipt
                    if (!_.isEmpty(transactionHash)) {
                        setTxHash(transactionHash)
                    }
                    setCanSelectActions(false)
                    setSelectedActions({})
                    updateActions()
                }
            }).catch((error) => {
                if (isMounted())
                setMessage(error.toString())
            })
        }
    })

    const handleClickRemoveActions = useEvent(async () => {
        if (!_.isEmpty(selectedActions)) {
            sendTransactionWithWallet({
                address: vaultAddress,
                abi: parseABI(VAULT_ABI),
                functionName: 'removeActions',
                args: [_.map(selectedActions, action => {
                    const { address, sigHash, payable } = action
                    return _encodeAction(address, sigHash, payable)
                })],
                account: owner
            }).then((receipt) => {
                if (isMounted()) {
                    const { transactionHash } = receipt
                    if (!_.isEmpty(transactionHash)) {
                        setTxHash(transactionHash)
                    }
                    setCanSelectActions(false)
                    setSelectedActions({})
                    updateActions()
                }
            }).catch((error) => {
                if (isMounted()) {
                    setMessage(error.toString())
                }
            })
        }
    })

    const filteredActions = useMemo(() => {
        return _.filter(tab === TAB.ACTIONS_ADDED.key ? actions : ALL_ACTIONS, action => {
            const { address, sigHash } = action
            const contractName = _getPerpsV2ContractName(address)
            const functionName = _getFunctionNameBySigHash(sigHash, FormatTypes.minimal)
            return isMetSearchStringCriteria(`${contractName} ${functionName}`, searchString)
        })
    }, [actions, searchString, tab])

    const actionGroups = _.groupBy(filteredActions, 'address')

    return (
        <div className='vault-actions'>
            <div className='vault-actions--header'>
                <label>{'Actions'}</label>
                <div className='vault-actions--header--tabs'>
                    {_.map(TAB, t => {
                        return (
                            <button key={t.key}
                                className={'vault-actions--tab' + (tab === t.key ? ' selected' : '')}
                                onClick={() => {
                                    setTab(t.key)
                                    setCanSelectActions(false)
                                    setSelectedActions({})
                                }}>
                                {t.name}
                            </button>
                        )
                    })}
                </div>

            </div>
            <div className='vault-actions--search-wrapper'>
                <input className='vault-actions--search-input'
                    placeholder='Search contracts, functions'
                    value={searchString}
                    onChange={(e) => { setSearchSting(e.target.value) }} />
                {!canSelectActions
                    ? <button onClick={() => { setCanSelectActions(true) }}>{`Select Actions to ${tab === TAB.ACTIONS_ADDED.key ? 'Delete' : 'Add'}`}</button>
                    : <button onClick={() => {
                        setSelectedActions({})
                        setCanSelectActions(false)
                    }}>{'Cancel Selection'}</button>}
            </div>
            <div className='vault-actions--table'>
                <table>
                    <thead>
                        <tr>
                            <th>{'Sig Hash'}</th>
                            <th>{'Function'}</th>
                            <th>{'Payable'}</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {_.map(actionGroups, (actionList, address) => {
                            const contractName = _getPerpsV2ContractName(address)
                            return (
                                <Fragment key={address}>
                                    <tr className='vault-actions--contract-row'>
                                        <td colSpan={99}>
                                            <div title={address}>
                                                <Link to={`${VAULT_CHAIN.blockExplorers.default.url}/address/${address}`}
                                                    target='_blank'>
                                                    {`${formattedHex(address)}`}
                                                </Link>
                                                <label>{contractName}</label>
                                            </div>
                                        </td>
                                    </tr>
                                    {_.map(actionList, (action, index) => {
                                        const { sigHash, payable } = action
                                        const actionId = _getActionId(action)
                                        const isSelected = _.has(selectedActions, actionId)
                                        const isAdded = _.has(actions, actionId)
                                        const { name: functionName } = _getFunctionBySigHash(sigHash) || {}
                                        
                                        return (
                                            <tr key={index} className='vault-actions--function-row'>
                                                <td>{sigHash}</td>
                                                <td>
                                                    {functionName}
                                                    {tab === TAB.ALL_ACTIONS.key && isAdded && <span className='vault-actions--added-tag'>{' Added'}</span>}
                                                </td>
                                                <td>{payable === true ? 'TRUE' : 'FALSE'}</td>
                                                <td>
                                                    {canSelectActions 
                                                    ? <>
                                                        {(tab === TAB.ACTIONS_ADDED.key || !isAdded) &&
                                                        <Checkbox
                                                            checked={isSelected}
                                                            onChange={(newChecked) => {
                                                                setSelectedActions(newChecked ? {
                                                                    ...selectedActions,
                                                                    [actionId]: action
                                                                } : _.omit(selectedActions, actionId))
                                                            }} />}
                                                    </>
                                                    : <VaultExecutePopup 
                                                        triggerLabel={'Execute'} 
                                                        targetContract={address}
                                                        vaultAddress={vaultAddress}
                                                        abi={PERPS_V2_MARKET_CONSLIDATED_ABI}
                                                        functionSigHashOrName={sigHash} />}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </Fragment>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className='vault-actions--footer'>
                <div className='vault-actions--messages'>
                    {txHash && <div className='vault-actions--message'>
                        {`Confirmed: `}
                        <Link to={`${defaultChain?.blockExplorers?.etherscan?.url}/tx/${txHash}`} target='_blank'>{formattedHex(txHash)}</Link>
                        <button onClick={() => { setTxHash('') }}><FaTimes /></button>
                    </div>}
                    {message &&
                    <div className='vault-actions--message'>
                        {message}
                        <button onClick={() => { setMessage('') }}><FaTimes /></button>
                    </div>}
                </div>
                {canSelectActions &&
                <>
                    {tab === TAB.ACTIONS_ADDED.key &&
                    <button className='vault-actions--remove-button'
                        disabled={_.isEmpty(selectedActions)}
                        onClick={() => { handleClickRemoveActions() }}>
                        {`Remove Actions (${_.size(selectedActions)})`}
                    </button>}
                    {tab === TAB.ALL_ACTIONS.key &&
                    <button className='vault-actions--add-button'
                        disabled={_.isEmpty(selectedActions)}
                        onClick={() => { handleClickAddActions() }}>{`Add Actions (${_.size(selectedActions)})`}</button>}
                </>}
            </div>
        </div>
    )
}

VaultActions.propTypes = {
    vaultAddress: PropTypes.string.isRequired,
    owner: PropTypes.string.isRequired
}

export default memo(VaultActions)