import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { AiOutlinePoweroff } from 'react-icons/ai'
import Clock from '../common/clock/Clock'

import { authLogout } from '../auth/authAction'

class Header extends Component {
    constructor (props) {
        super(props)
    }

    render () {
        const { dispatch } = this.props
        const { pathname } = window.location
        return (
            <div className='header'>
                <div className='header--title'>{'Antelope Technology'}</div>
                <div className='header--navigators'>
                    <Link to={'/'} className={pathname === '/' ? 'active' : null}>{'Profiles'}</Link>
                    <Link to={'/workspace'} className={pathname === '/workspace' ? 'active' : null}>{'My Workspace'}</Link>
                    <Link to={'/trade'} className={pathname === '/trade' ? 'active' : null}>{'Trade'}</Link>
                    <Link to={'/pairs-trade'} className={pathname === '/pairs-trade' ? 'active' : null}>{'Pairs Trade'}</Link>
                </div>
                <div className='header--right-content'>
                    <div className='header--clock'><Clock /></div>
                    <button className='header--logout-button' onClick={() => {
                        dispatch(authLogout())
                    }}><AiOutlinePoweroff /></button>
                </div>
            </div>
        )
    }
}

Header.propTypes = {
    dispatch: PropTypes.func.isRequired
}

function mapStateToProps () {
    return {}
}

export default connect(mapStateToProps)(Header)