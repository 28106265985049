import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { FaCheck } from 'react-icons/fa'

export default class Checkbox extends Component {
    render () {
        const { disabled, checked, onChange } = this.props
        return (
            <div className={'checkbox' + (checked ? ' checked' : '') + (disabled ? ' disabled' : '')} 
                onClick={(e) => {
                    if (!disabled) {
                        onChange(!checked, e.shiftKey) 
                    }
                }}>
                {checked && <FaCheck className='centered' />}
            </div>
        )
    }
}

Checkbox.propTypes = {
    disabled: PropTypes.bool,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
}

Checkbox.defaultProps = {
    disabled: false,
    checked: false,
    onChange: () => {}
}

