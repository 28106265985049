import React, { Component } from 'react'
import TransactionContainer from './TransactionContainer'
import NotificationContainer from './NotificationContainer'

export default class TradingContainer extends Component {
    constructor (props) {
        super(props)
        this.container = null
        this.draggableLine = null
        this.transactions = null
        this.notifications = null
    }

    handleDragLine (e) {
        let newSplitTop = (e.clientY / this.container.clientHeight) * 100
        if (newSplitTop && this.transactions && this.notifications && this.draggableLine) {
            newSplitTop = Math.max(Math.min(newSplitTop, 80), 20) + '%'
            this.transactions.style.height = newSplitTop
            this.notifications.style.top = newSplitTop
            this.draggableLine.style.top = newSplitTop
        }
    }

    render () {
        return (
            <div className='trading-container' ref={(node) => { this.container = node }}>
                <div className='trading-container--content-block transactions' 
                    ref={(node) => { this.transactions = node }}>
                    <TransactionContainer />
                </div>
                <div className='trading-container--draggable-line' 
                    ref={(node) => { this.draggableLine = node }}
                    draggable
                    onDragOver={(e) => { e.preventDefault() }}
                    onDrag={(e) => { this.handleDragLine(e) }} />
                <div className='trading-container--content-block notifications' 
                    ref={(node) => { this.notifications = node }}>
                    <NotificationContainer />
                </div>
            </div>
        )
    }
}