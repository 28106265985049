import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useLocalStorage } from 'react-use'
import { useDispatch } from 'react-redux'

import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'

import Popup from '../common/popup/Popup'

import { LuChevronDown, LuChevronRight } from 'react-icons/lu'
import { MdDeleteOutline } from 'react-icons/md'

import { deleteSignalProfile, removeSignalProfile, updateSignalProfile } from './profileAction'
import { SignalProfileStruct } from './SignalProfileConfig'
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector'

function Section ({ directory='local', selectedId, onClickProfile=()=>{} }) {

    const dispatch = useDispatch()
    const [isHidden, setIsHidden] = useLocalStorage(`signal-profile-menu--${directory}--is-hidden`, false)
    const signalProfilePerId = useShallowEqualSelector(state => _.get(state, `profile.signalProfile.${directory}`)) || {}

    return (
        <section className='signal-profile-menu--section'>
            <div className='signal-profile-menu--section--header' onClick={() => { setIsHidden(!isHidden) }}>
                {isHidden ? <LuChevronRight /> : <LuChevronDown />}
                <span>{_.capitalize(directory)}</span>
                {directory === 'local' &&
                <button onClick={(e) => {
                    e.stopPropagation()
                    dispatch(updateSignalProfile(directory, SignalProfileStruct({ _id: uuidv4(), name: `signal-profile-${_.random(1000, 9999)}` })))
                    if (isHidden) {
                        setIsHidden(false)
                    }
                }}>{'Create Profile'}</button>}
            </div>
            {!isHidden &&
            <>
                {_.isEmpty(signalProfilePerId)
                    ? <div className='signal-profile-menu--section--empty'>{'Empty Profile'}</div>
                    : <div className='signal-profile-menu--section--list'>
                        {_.map(signalProfilePerId, (signalProfile, id) => {
                            return (
                                <div className={'signal-profile-menu--section--item' + (selectedId === id ? ' selected' : '')} key={id}
                                    onClick={() => { onClickProfile(id) }}>
                                    <div className='signal-profile-menu--section--item--name'>{signalProfile.name}</div>
                                    <Popup className='signal-profile-menu--section--item--delete--popup'
                                        on={'click'}
                                        trigger={<button className='signal-profile-menu--section--item--delete--trigger'><MdDeleteOutline /></button>}>
                                        <div className='signal-profile-menu--section--item--delete'>
                                            <div className='signal-profile-menu--section--item--delete--message'>{'Delete the profile forever?'}</div>
                                            <button className='signal-profile-menu--section--item--delete--confirm-button'
                                                onClick={() => {
                                                    if (directory === 'local') {
                                                        dispatch(removeSignalProfile(directory, id))
                                                    } else if (directory === 'stored') {
                                                        dispatch(deleteSignalProfile(directory, id))
                                                    }
                                                }}>
                                                {'DELETE'}
                                            </button>
                                        </div>
                                    </Popup>
                                </div>
                            )
                        })}
                    </div>}
            </>}
        </section>
    )
}

Section.propTypes = {
    directory: PropTypes.string.isRequired,
    selectedId: PropTypes.string,
    onClickProfile: PropTypes.func.isRequired
}

function SignalProfileMenu ({ selectedProfile={}, onSelectProfile=()=>{} }) {

    return (
        <div className='signal-profile-menu'>
            {_.map(['local', 'stored', 'production'], (_directory) => {
                return (
                    <Section key={_directory}
                        directory={_directory}
                        selectedId={selectedProfile.directory === _directory ? selectedProfile.id : null}
                        onClickProfile={(_signalProfileId) => {
                            onSelectProfile({
                                directory: _directory,
                                id: _signalProfileId
                            })
                        }} />
                )
            })}
        </div>
    )
}

SignalProfileMenu.propTypes = {
    selectedProfile: PropTypes.shape({
        directory: PropTypes.string,
        id: PropTypes.string
    }),
    onSelectProfile: PropTypes.func.isRequired
}

export default memo(SignalProfileMenu)