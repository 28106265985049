import _ from 'lodash'
import { createConfig, configureChains } from 'wagmi'
import { optimism } from 'wagmi/chains'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { publicProvider } from 'wagmi/providers/public'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'

const RPC_URLS_PER_CHAIN = {
    [optimism.id]: [
        'https://quaint-late-crater.optimism.quiknode.pro/703cf95e4c0a99fc2fb4a5359a4d854c1fbf4b44'
    ]
}

export const { chains, publicClient } = configureChains(
    [optimism],
    [   
        jsonRpcProvider({
            rpc: (chain) => ({
                http: RPC_URLS_PER_CHAIN[chain.id][0]
            })
        }),
        publicProvider()
    ],
    { 
        batch: {
            multicall: {
                wait: 1000
            }
        }
    }
)

export const wagmiConfig = createConfig({
    autoConnect: false,
    connectors: [
        new MetaMaskConnector({ chains })
    ],
    publicClient
})

export const getDefaultChain = () => {
    return _.get(chains, '0')
}

export const getDefaultChainId = () => {
    return _.get(chains, '0.id')
}