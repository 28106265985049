import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import _ from 'lodash'

export default class Popup extends Component {
    constructor (props) {
        super(props)
        this.state = {
            open: false
        }
        this.triggerNode = null
        this.popupNode = null
        this.handleClickWindow = this.handleClickWindow.bind(this)
    }

    componentDidMount() {
        const window = this.getWindow()
        if (window) {
            window.addEventListener('click', this.handleClickWindow)
        }
    }

    componentDidUpdate (prevProps, prevState) {
        const { open } = this.state
        const { onOpen, onClose, closeId } = this.props
        if (!_.isEqual(prevState.open, open)) {
            if (open) {
                this._arrangePopup()
                onOpen()
            } else {
                onClose()
            }
        }
        if (!_.isNil(closeId) && !_.isEqual(prevProps.closeId, closeId)) {
            this._closePopup()
        }
    }

    componentWillUnmount () {
        const window = this.getWindow()
        if (window) {
            window.removeEventListener('click', this.handleClickWindow)
        }
    }

    _closePopup () {
        this.setState({ open: false })
    }

    getWindow () {
        return this.triggerNode && this.triggerNode.ownerDocument
            ? (this.triggerNode.ownerDocument.defaultView || this.triggerNode.ownerDocument.parentWindow)
            : null  
    }

    handleClickWindow (e) {
        const { open } = this.state
        const { shouldCloseOnClickingWindow } = this.props
        if (open && shouldCloseOnClickingWindow && (!this.triggerNode || !this.triggerNode.contains(e.target)) && (!this.popupNode || !this.popupNode.contains(e.target))) {
            this.setState({ open: false })
        }
    }

    _arrangePopup () {
        const { align, margin, minDistanceToWindowBorder, shouldForceLeftBiased } = this.props
        const style = {
            position: 'fixed',
            zIndex: 99,
            left: null,
            right: null,
            top: null,
            bottom: null,
            maxWidth: null,
            maxHeight: null
        }

        if (this.triggerNode && this.popupNode) {
            const triggerBoundingClientRect = this.triggerNode.getBoundingClientRect() 
            const triggerCenterX = triggerBoundingClientRect.left + triggerBoundingClientRect.width / 2
            const triggerCenterY = triggerBoundingClientRect.top + triggerBoundingClientRect.height / 2

            const window = this.getWindow()
            const isTriggerLeftBiased = triggerCenterX < window.innerWidth / 2
            const isTriggerTopBiased = triggerCenterY < window.innerHeight / 2

            if (align === 'vertical') {
                if (isTriggerLeftBiased || shouldForceLeftBiased) {
                    const left = triggerBoundingClientRect.left
                    style.left = `${left}px`
                    style.maxWidth = `${window.innerWidth - left - minDistanceToWindowBorder}px`
                } else {
                    const right = window.innerWidth - triggerBoundingClientRect.right
                    style.right = `${right}px`
                    style.maxWidth = `${window.innerWidth - right - minDistanceToWindowBorder}px`
                }
                if (isTriggerTopBiased) {
                    const top = triggerBoundingClientRect.bottom + margin
                    style.top = `${top}px`
                    style.maxHeight = `${window.innerHeight - top - minDistanceToWindowBorder}px`
                } else {
                    const bottom = window.innerHeight - triggerBoundingClientRect.top + margin
                    style.bottom = `${bottom}px`
                    style.maxHeight = `${window.innerHeight - bottom - minDistanceToWindowBorder}px`
                }
            } else {
                if (isTriggerLeftBiased) {
                    const left = triggerBoundingClientRect.right + margin
                    style.left = `${left}px`
                    style.maxWidth = `${window.innerWidth - left - minDistanceToWindowBorder}px`
                } else {
                    const right = window.innerWidth - triggerBoundingClientRect.left + margin
                    style.right = `${right}px`
                    style.maxWidth = `${window.innerWidth - right - minDistanceToWindowBorder}px`
                }
                if (isTriggerTopBiased) {
                    const top = triggerBoundingClientRect.top
                    style.top = `${top}px`
                    style.maxHeight = `${window.innerHeight - top - minDistanceToWindowBorder}px`
                } else {
                    const bottom = window.innerHeight - triggerBoundingClientRect.bottom
                    style.bottom = `${bottom}px`
                    style.maxHeight = `${window.innerHeight - bottom - minDistanceToWindowBorder}px`
                } 
            }
            
            _.forEach(style, (styleValue, styleKey) => {
                this.popupNode.style[styleKey] = styleValue
            })
        }
    }

    renderPopup () {
        const { children, className } = this.props 
        const render = () => {
            return (
                <div className={'popup' + (className ? ` ${className}` : '')} 
                    ref={(node) => { this.popupNode = node }}>
                    {children}
                </div>
            )
        }

        return (
            ReactDOM.createPortal(render(), this.triggerNode.closest('body'))
        )
    }

    render () {
        const { open } = this.state
        const { trigger, on, disabled } = this.props
        const events = {}
        if (on === 'click') {
            events.onClick = () => {
                if (!disabled) {
                    this.setState({ open: !open })
                }
            }
        } else {
            events.onMouseEnter = () => {
                if (!disabled && !open) {
                    this.setState({ open: true })
                }
            }
            events.onMouseLeave = () => {
                if (open) {
                    this.setState({ open: false })
                }
            }
        }
        return (
            <Fragment>
                {React.cloneElement(React.Children.only(trigger), Object.assign({}, {
                    ref: (node) => { this.triggerNode = node }
                }, events))}
                {open && this.renderPopup()}
            </Fragment>
        )
    }
}

Popup.propTypes = {
    trigger: PropTypes.node,
    className: PropTypes.string,
    children: PropTypes.node,
    on: PropTypes.oneOf(['hover', 'click']),
    align: PropTypes.oneOf(['vertical', 'horizontal']),
    margin: PropTypes.number,
    minDistanceToWindowBorder: PropTypes.number,
    disabled: PropTypes.bool,
    shouldCloseOnClickingWindow: PropTypes.bool,
    shouldForceLeftBiased: PropTypes.bool,
    closeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onOpen: PropTypes.func,
    onClose: PropTypes.func
}

Popup.defaultProps = {
    on: 'hover',
    align: 'vertical',
    margin: 5,
    minDistanceToWindowBorder: 50,
    disabled: false,
    shouldCloseOnClickingWindow: true,
    shouldForceLeftBiased: false,
    onOpen: () => {},
    onClose: () => {}
}