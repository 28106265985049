import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { FaXmark } from 'react-icons/fa6'

function ArrayOfStringInput ({ className, placeholder, values, immutableValues, onChange }) {
    const [inputValue, setInputValue] = useState('')
    return (
        <div className={'array-of-string-input' + (!_.isEmpty(className) ? ` ${className}` : '')}>
            {_.map(values, (value, index) => {
                return (
                    <div className='array-of-string-input--value' key={index}>
                        <span>{value}</span>
                        {!immutableValues.includes(value) &&
                        <button
                            onClick={() => {
                                const newValues = [
                                    ...values.slice(0, index),
                                    ...values.slice(index + 1)
                                ]
                                onChange(newValues)
                            }}><FaXmark /></button>}
                    </div>
                )
            })}
            <input
                value={inputValue}
                placeholder={placeholder}
                spellCheck={false}
                onChange={(e) => { setInputValue(e.target.value) }}
                onKeyDown={(e) => {
                    const _newValue = e.target.value.trim()
                    if (e.key === 'Enter' && !_.isEmpty(_newValue) && !values.includes(_newValue)) {
                        onChange([...values, _newValue])
                        setInputValue('')
                    }
                }} />
        </div>
    )
}

ArrayOfStringInput.propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.string).isRequired,
    immutableValues: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired
}

ArrayOfStringInput.defaultProps = {
    className: PropTypes.string,
    placeholder: 'Add new value',
    values: [],
    immutableValues: [],
    onChange: () => {}
}

export default memo(ArrayOfStringInput)