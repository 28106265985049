import _ from 'lodash'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import dotProp from 'dot-prop-immutable'

import { SERVERS, getApiBaseUrlByHostname } from '../../configs/config'
import { secureFetch } from '../../util/util'

export const UPDATE_TIMER_ITEMS = 'UPDATE_TIMER_ITEMS'
export const REMOVE_TIMER_ITEMS = 'REMOVE_TIMER_ITEMS'

const fetchingTimerIds = {}

function partitionTimerByHostname (timerItem) {
    const partitionedTimers = {}
    _.filter(SERVERS, { enabled: true }).map(server => server.hostname).forEach((hostname) => {
        const profilesInCurrentHostname = timerItem.profiles.filter((timerProfile) => {
            return timerProfile.profileId && _.last(timerProfile.profileId.split('_')) === hostname
        })
        partitionedTimers[hostname] = dotProp.set(timerItem, 'profiles', profilesInCurrentHostname)
    })

    return partitionedTimers
}

function mergeTimers (timerItems = []) {
    const mergedTimerItems = {}
    timerItems.forEach((timerItem) => {
        const { id, profiles } = timerItem
        if (_.isEmpty(mergedTimerItems[id])) {
            mergedTimerItems[id] = timerItem
        } else if (!_.isEmpty(profiles)) {
            mergedTimerItems[id].profiles.push(...profiles)
        }
    })
    return mergedTimerItems
}

export function createTimer (timer) {
    return (dispatch) => {
        timer.id = uuidv4()
        timer.createdTime = moment().toISOString()
        const partitionedTimers = partitionTimerByHostname(timer)
        const requests = []
        _.forEach(partitionedTimers, (timerItem, hostname) => {
            if (!_.isEmpty(timerItem.profiles)) {
                const request = dispatch(secureFetch(`${getApiBaseUrlByHostname(hostname)}/timer`, {
                    method: 'POST',
                    body: JSON.stringify(timerItem)
                }))
                requests.push(request)
            }
        })
        const promises = Promise.all(requests)
        promises.then((responses) => {
            if (responses.every(response => response.status === 200)) {
                dispatch({
                    type: UPDATE_TIMER_ITEMS,
                    timers: {
                        [timer.id]: timer
                    }
                })
            }
        })
        return promises
    }
}

export function fetchTimerById (timerId) {
    return (dispatch) => {
        if (_.isNil(fetchingTimerIds[timerId])) {
            fetchingTimerIds[timerId] = true
            const requests = []
            _.filter(SERVERS, { enabled: true, profileDisabled: false }).map(server => server.hostname).forEach((hostname) => {
                const request = dispatch(secureFetch(`${getApiBaseUrlByHostname(hostname)}/timer?id=${timerId}`, {
                    method: 'GET'
                })).catch(() => {
                    delete fetchingTimerIds[timerId]
                })
                requests.push(request)
            })
            const promises = Promise.all(requests)
            promises.then((responses) => {
                delete fetchingTimerIds[timerId]
                const jsons = []
                const timerItems = []
                responses.forEach((response) => {
                    if (response.status === 200) {
                        jsons.push(response.json())
                    }
                })
                
                Promise.all(jsons).then((bodies) => {
                    bodies.forEach((body) => {
                        if (!_.isEmpty(body)) {
                            timerItems.push(body)
                        }
                    })
                    const mergedTimers = mergeTimers(timerItems)
                    dispatch({
                        type: UPDATE_TIMER_ITEMS,
                        timers: mergedTimers
                    })
                })
            })
            return promises
        }
    }
}

export function fetchTimers () {
    return (dispatch, getState) => {
        const requests = []
        _.filter(SERVERS, { enabled: true, profileDisabled: false }).map(server => server.hostname).forEach((hostname) => {
            const request = dispatch(secureFetch(`${getApiBaseUrlByHostname(hostname)}/timer`, {
                method: 'GET'
            }))
            requests.push(request)
        })
        const promises = Promise.all(requests)
        promises.then((responses) => {
            const jsons = []
            const timerItems = []
            const allFecthed = responses.every(response => response.status === 200)
            
            responses.forEach((response) => {
                if (response.status === 200) {
                    jsons.push(response.json())
                }
            })

            Promise.all(jsons).then((bodies) => {
                bodies.forEach((body) => {
                    if (!_.isEmpty(body)) {
                        timerItems.push(...Object.values(body))
                    }
                })
                const mergedTimers = mergeTimers(timerItems)
                dispatch({
                    type: UPDATE_TIMER_ITEMS,
                    timers: mergedTimers
                })
                if (allFecthed) {
                    const deletedTimers = _.omit(getState().timer.items, Object.keys(mergedTimers))
                    if (!_.isEmpty(deletedTimers)) {
                        dispatch({
                            type: REMOVE_TIMER_ITEMS,
                            timerIds: Object.keys(deletedTimers)
                        })
                    }
                }
            })
        })
        return promises
    }
}

export function updateTimer (newTimer) {
    return (dispatch, getState) => {
        const { timer } = getState()
        const prevPartitionedTimers = partitionTimerByHostname(timer.items[newTimer.id])
        const newPartitionedTimers = partitionTimerByHostname(newTimer)
        const requests = []
        _.forEach(newPartitionedTimers, (newTimerItem, hostname) => {
            let request
            if (!_.isEmpty(newTimerItem.profiles)) {
                request = dispatch(secureFetch(`${getApiBaseUrlByHostname(hostname)}/timer`, {
                    method: 'PUT',
                    body: JSON.stringify(newTimerItem)
                }))
            } else if (!_.isEmpty(prevPartitionedTimers[hostname].profiles)) {
                request = dispatch(secureFetch(`${getApiBaseUrlByHostname(hostname)}/timer`, {
                    method: 'DELETE',
                    body: JSON.stringify({id: newTimerItem.id})
                }))
            }
            if (request) {
                requests.push(request)
            }
        })
        const promises = Promise.all(requests)
        promises.then((responses) => {
            if (responses.every(response => response.status === 200)) {
                dispatch({
                    type: UPDATE_TIMER_ITEMS,
                    timers: {
                        [newTimer.id]: newTimer
                    }
                })
            }
        })
        return promises
    }
}

export function toggleTimer (timer, profileId) {
    return (dispatch, getState) => {
        const newTimer = _.cloneDeep(timer)
        const timerProfile = _.find(newTimer.profiles, { profileId })
        const profileItem = getState().profile.items[profileId]
        if (timerProfile && profileItem) {
            const newEnabled = !timerProfile.enabled
            const request = dispatch(secureFetch(`${getApiBaseUrlByHostname(profileItem.hostname)}/timer/switch`, {
                method: 'PUT',
                body: JSON.stringify({
                    id: timer.id,
                    profileId: profileId,
                    enabled: newEnabled
                })
            })).then((response) => {
                if (response.status === 200) {
                    timerProfile.enabled = newEnabled
                    dispatch({
                        type: UPDATE_TIMER_ITEMS,
                        timers: {
                            [timer.id]: newTimer
                        }
                    })
                }
            })
            return request
        }
    }
}

export function removeTimerItem (timerId) {
    return (dispatch) => {
        dispatch({
            type: REMOVE_TIMER_ITEMS,
            timerIds: [timerId]
        })
    }
}

export function deleteTimer (timerId) {
    return (dispatch, getState) => {
        const { timer } = getState()
        const partitionedTimers = partitionTimerByHostname(timer.items[timerId])
        const requests = []
        _.forEach(partitionedTimers, (timerItem, hostname) => {
            if (!_.isEmpty(timerItem.profiles)) {
                const request = dispatch(secureFetch(`${getApiBaseUrlByHostname(hostname)}/timer`, {
                    method: 'DELETE',
                    body: JSON.stringify({ id: timerItem.id })
                }))
                requests.push(request)
            }
        })
        const promise = Promise.all(requests)
        promise.then((responses) => {
            if (responses.every(response => response.status === 200)) {
                dispatch(removeTimerItem(timerId))
            }
        })
        return promise
    }
}