import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import dotProp from 'dot-prop-immutable'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import _ from 'lodash'

import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer'
import VirtualizedDynamicHeightList from '../components/common/VirtualizedList/VirtualizedDynamicHeightList'
import OrderEditor, { MULTIPLE_ACCOUNTS } from '../components/trading/OrderEditor'
import AccountBalanceItem from '../components/account/AccountBalanceItem'
import AccountAssetItem from '../components/account/AccountAssetItem'
import ManualOrderContainer from '../components/trading/ManualOrderContainer'
import SocketConnection from '../components/webSocket/SocketConnection'
import OrderBook from '../components/symbol/OrderBook'
import PositionItem from '../components/trading/PositionItem'

import { getFilteredAccountBalanceBySymbol } from '../util/accountUtil'
import { MANUAL_ORDER_PROFILE } from '../configs/tradingConfig'
import { getSymbolAttributeByName } from '../util/symbolUtil'
import { webSocketSendData } from '../components/webSocket/webSocketAction'
import { removeSymbolOrderBook } from '../components/symbol/symbolAction'
import { setPageTitleAndDescription } from '../util/util'

class TradePage extends Component {
    constructor (props) {
        super(props)
        this.state = {
            orderEditorConfig: {
                symbolName: null,
                accountName: null,
                accountListString: '',
                price: null,
                unit: null
            },
            shouldShowThreeColumns: false
        }    
        this.symbolNameUpdateTimeValue = null
        this.tradeWindowNode = null
        this.minWindowWidthShouldShowThreeColumns = 1919
        
        this.accountInfoNode = null
        this.accountBalanceListNode = null
        this.positionListNode = null
        this.draggableLineNode = null

        this.handleResizeWindow = this.handleResizeWindow.bind(this)
        this.pageId = uuidv4()
    }

    componentDidMount () {
        setPageTitleAndDescription({
            title: 'Trade - Antelope Technology'
        })
        setTimeout(() => {
            const window = this.getWindow()
            if (window) {
                this.updateShouldShowThreeColumns()
                window.addEventListener('resize', this.handleResizeWindow)
                if (window.document && window.document.body) {
                    window.document.body.style.minWidth = '0px'
                }
            }
        })
    }

    componentWillUnmount () {
        const { orderEditorConfig } = this.state
        const window = this.getWindow()
        if (window) {
            window.removeEventListener('resize', this.handleResizeWindow)
        }
        if (!_.isNil(orderEditorConfig.symbolName)) {
            this.unsubscribeSymbolOrderBook(orderEditorConfig.symbolName)
        }
    }

    handleResizeWindow () {
        this.updateShouldShowThreeColumns()
    }

    handleDragLine (e) {
        let newSplitTop = ((e.clientY - this.accountInfoNode.offsetTop) / (this.accountInfoNode.clientHeight)) * 100
        if (newSplitTop && newSplitTop > 0 && this.accountBalanceListNode && this.positionListNode && this.draggableLineNode) {
            newSplitTop = Math.max(Math.min(newSplitTop, 80), 20) + '%'
            this.accountBalanceListNode.style.height = newSplitTop
            this.positionListNode.style.top = newSplitTop
            this.draggableLineNode.style.top = newSplitTop
        }
    }

    subscribeSymbolOrderBook (symbolName) {
        if (symbolName) {
            webSocketSendData(process.env.REACT_APP_MANUAL_ORDER_PROFILE_HOSTNAME, {
                type: 'SUBSCRIBE_ORDER_BOOK',
                symbols: [symbolName]
            })
        }
    }

    unsubscribeSymbolOrderBook (symbolName) {
        const { dispatch } = this.props
        if (!_.isEmpty(symbolName)) {
            webSocketSendData(process.env.REACT_APP_MANUAL_ORDER_PROFILE_HOSTNAME, {
                type: 'UNSUBSCRIBE_ORDER_BOOK',
                symbols: [symbolName]
            })
            dispatch(removeSymbolOrderBook(symbolName))
        }
    }

    updateShouldShowThreeColumns () {
        const window = this.getWindow()
        if (window) {
            this.setState({ shouldShowThreeColumns: window.innerWidth > this.minWindowWidthShouldShowThreeColumns })
        }
    }

    getWindow () {
        return this.tradeWindowNode && this.tradeWindowNode.ownerDocument
            ? (this.tradeWindowNode.ownerDocument.defaultView || this.tradeWindowNode.ownerDocument.parentWindow)
            : null  
    }

    EmptyData () {
        return (
            <div className='trade-page--empty-data'>{'Empty Data'}</div>
        )
    }

    AccountBalance () {
        const { symbolName, accountName, accountListString } = this.state.orderEditorConfig
        const { symbolItems, accountBalance, accountAsset } = this.props
        const accountNames = _.isNil(accountName) ? []
            : accountName === MULTIPLE_ACCOUNTS ? (!_.isEmpty(accountListString) ? accountListString.split(',') : [])
            : [accountName]

        if (!_.isNil(symbolName) && !_.isEmpty(accountNames)) {
            const { base, quote } = getSymbolAttributeByName(symbolName)
            const symbolItem = symbolItems[symbolName]

            const seivedItems = _.compact(_.map(_.compact(accountNames), name => {
                const filteredAccountBalance = getFilteredAccountBalanceBySymbol({
                    accountBalance,
                    symbolItem,
                    accountName: name
                })
                const accountAssetItem = accountAsset[name]
                return _.some(Object.values(filteredAccountBalance), accountBalances => !_.isEmpty(accountBalances)) ? {
                        type: 'account-balance',
                        accountName: name,
                        filteredAccountBalance
                    } : !_.isEmpty(accountAssetItem) ? {
                        type: 'account-asset',
                        accountAssetItem
                    } : null
            }))
            
            return _.size(seivedItems) > 0 
                ? <VirtualizedDynamicHeightList 
                    items={seivedItems}
                    renderItem={(item) => {
                        if (item.type === 'account-balance') {
                            return (
                                <AccountBalanceItem 
                                    accountName={item.accountName}
                                    filteredAccountBalance={item.filteredAccountBalance}
                                    emptyMessage={'No Balance Data'}
                                    shouldShowDetails
                                    shouldShowAccountTypeTab 
                                    shouldHideAccountName
                                    shouldHideAccountTypeTitle />
                            )
                        } else if (item.type === 'account-asset') {
                            return (
                                <AccountAssetItem 
                                    accountAssetItem={item.accountAssetItem} 
                                    tokensToFilter={[base, quote]} />
                            )
                        }
                    }}  /> 
                : this.EmptyData()
        } else {
            return this.EmptyData()
        }
    }

    Position () {
        const { positions } = this.props
        const { symbolName, accountName, accountListString } = this.state.orderEditorConfig
        const accountNames = _.isNil(accountName) ? []
            : accountName === MULTIPLE_ACCOUNTS ? (!_.isEmpty(accountListString) ? accountListString.split(',') : [])
            : [accountName]

        if (!_.isEmpty(symbolName) && !_.isEmpty(accountNames)) {
            const positionsFilteredBySymbol  = _.filter(positions, { product_name: symbolName })
            const seivedPositions = _.compact(_.map(accountNames, name => {
                return _.find(positionsFilteredBySymbol, { account_name: name })
            }))
            return _.size(seivedPositions) > 0 
                ? <VirtualizedDynamicHeightList 
                    items={seivedPositions}
                    renderItem={(positionItem) => {
                        return (
                            <PositionItem
                            shouldShowTag    
                            positionItem={positionItem} /> 
                        )
                    }} />
                : this.EmptyData()
        } else {
            return this.EmptyData()
        }
    }

    AccountInfo () {
        return (
            <div className='trade-page--account-info' ref={(node) => { this.accountInfoNode = node }}>
                <div className='trade-page--account-balance' ref={(node) => { this.accountBalanceListNode = node }}>
                    <div className='trade-page--account-balance--title'>{'Account Balance'}</div>
                    <div className='trade-page--account-balance--main'>{this.AccountBalance()}</div>
                </div>
                <div className='trade-page--account-info--draggable-line' 
                    ref={(node) => { this.draggableLineNode = node }}
                    draggable
                    onDragOver={(e) => { e.preventDefault() }}
                    onDrag={(e) => { this.handleDragLine(e) }} />
                <div className='trade-page--position' ref={(node) => { this.positionListNode = node }}>
                    <div className='trade-page--position--title'>{'Position'}</div>
                    <div className='trade-page--position--main'>{this.Position()}</div>
                </div>
            </div>
        )
    }

    render () {
        const { orderEditorConfig, shouldShowThreeColumns } = this.state
        const { symbolItems } = this.props
        const symbolItem = symbolItems[orderEditorConfig.symbolName]

        return (
            <div className='trade-page page' ref={(node) => { this.tradeWindowNode = node }}>
                <Header />
                <div className='trade-page--body page--body'>
                    {shouldShowThreeColumns && <div className='trade-page--left'>{this.AccountInfo()}</div>}
                    <div className='trade-page--center'>
                        <div className='trade-page--order-editor'>
                            <div className='trade-page--order-editor--title'>
                                <span>{'New Order'}</span>
                                <div className='trade-page--order-editor--title--connection'>
                                    <SocketConnection hostname={MANUAL_ORDER_PROFILE.hostname} shouldHideReconnectInfo />
                                </div>
                            </div>
                            <div className='trade-page--order-editor--main'>
                                <OrderEditor 
                                    shouldHideTitle
                                    config={orderEditorConfig}
                                    onChangeConfig={(newConfig) => { 
                                        if (!_.isEmpty(newConfig.symbolName) && !_.isEqual(orderEditorConfig.symbolName, newConfig.symbolName)) {
                                            if (!_.isNil(orderEditorConfig.symbolName)) {
                                                this.unsubscribeSymbolOrderBook(orderEditorConfig.symbolName)
                                            }
                                            this.subscribeSymbolOrderBook(newConfig.symbolName)
                                            this.symbolNameUpdateTimeValue = moment().valueOf()
                                        }
                                        this.setState(dotProp.merge(this.state, 'orderEditorConfig', newConfig)) 
                                    }} />
                            </div>
                        </div>
                        <div className='trade-page--order-book'>
                            <div className='trade-page--order-book--title'>
                                <span>{'Order Book'}</span>
                                <div className='trade-page--order-book--title--connection'>
                                    <SocketConnection hostname={MANUAL_ORDER_PROFILE.hostname} shouldHideReconnectInfo />
                                </div>
                            </div>
                            <div className='trade-page--order-book--main'>
                                {symbolItem 
                                ? <OrderBook 
                                    shouldHideTitle
                                    symbolItem={symbolItem} 
                                    onClickPrice={(price) => { 
                                        this.setState({
                                            orderEditorConfig: dotProp.set(orderEditorConfig, 'price', price)
                                        })
                                    }} 
                                    onClickAmount={(amount) => {
                                        this.setState({
                                            orderEditorConfig: dotProp.set(orderEditorConfig, 'unit', amount)
                                        })
                                    }} 
                                    onClickReSubscribeButton={() => {
                                        if (!_.isNil(orderEditorConfig.symbolName)) {
                                            this.subscribeSymbolOrderBook(orderEditorConfig.symbolName)
                                        }
                                    }} />
                                : this.EmptyData()}
                            </div>
                        </div>
                        {!shouldShowThreeColumns && this.AccountInfo()}
                    </div>
                    <div className='trade-page--right'>
                        <ManualOrderContainer 
                            symbolToFilter={{
                                symbolName: orderEditorConfig.symbolName,
                                id: this.symbolNameUpdateTimeValue
                            }} 
                            onChangeSymbol={(newSymbolName) => {
                                if (!_.isEmpty(newSymbolName) && !_.isEqual(orderEditorConfig.symbolName, newSymbolName)) {
                                    if (!_.isNil(orderEditorConfig.symbolName)) {
                                        this.unsubscribeSymbolOrderBook(orderEditorConfig.symbolName)
                                    }
                                    this.subscribeSymbolOrderBook(newSymbolName)
                                    this.setState({ orderEditorConfig: dotProp.set(orderEditorConfig, 'symbolName', newSymbolName) })
                                }
                            }} />
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

TradePage.propTypes = {
    dispatch: PropTypes.func.isRequired,
    accountBalance: PropTypes.object.isRequired,
    accountAsset: PropTypes.object.isRequired,
    positions: PropTypes.array.isRequired,
    symbolItems: PropTypes.object.isRequired
}

TradePage.defaultProps = {
}

function getStateFromProps (state) {
    return {
        accountBalance: state.account.balance,
        accountAsset: state.account.asset,
        positions: state.trading.positions,
        symbolItems: state.symbol.items
    }
}

export default connect(getStateFromProps)(TradePage)