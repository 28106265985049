import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import BigNumber from 'bignumber.js'
import moment from 'moment'
import _ from 'lodash'

import Popup from '../common/popup/Popup'
import { getOptionSymbolUnderlying, getSymbolAttributeByName, getTokenPriceInUSD } from '../../util/symbolUtil'
import { getNotional } from '../../util/tradingUtil'

const PORTFOLIOS = ['allblack']

const _getOptionSymbolPositionGroupId = ({ underlying='', expiryDate='', strikePrice=0 }) => {
    return `${underlying}--${expiryDate}--${strikePrice}`
}

const PositionGroupStruct = ({ underlying='', expiryDate='', strikePrice=0, callPositions=[], putPositions=[], callMarkIVPerExchange={}, putMarkIVPerExchange={} }) => {
    return {
        id: _getOptionSymbolPositionGroupId({ underlying, expiryDate, strikePrice }),
        underlying,
        expiryDate,
        strikePrice,
        callPositions,
        putPositions,
        callMarkIVPerExchange,
        putMarkIVPerExchange
    }
}

class AggregatedOptionPositions extends Component {
    constructor (props) {
        super(props)
        this.state = {
            underlying: null,
            expiryDate: null,
            exchanges: null // []
        }
    }

    _formattedValue (value) {
        const _value = BigNumber(value || 0)
        return _value.abs().gte(1000) ? _value.toFormat(0) : _value.toFormat(2)
    }

    _getExchangeNames () {
        const { positions } = this.props
        const _exchangeNames = _.reduce(positions, (_result, _position) => {
            const { originalType, optionExpiryDate, optionStrikePrice, exchangeName } = getSymbolAttributeByName(_position.product_name)
            if (['CALL', 'PUT'].includes(originalType) && !_.isEmpty(optionExpiryDate) && !_.isNil(optionStrikePrice)) {
                _result.push(exchangeName)
            }
            return _result
        }, [])
        return _.compact(_.uniq(_exchangeNames))
    }

    _getPositionGroups () {
        const { exchanges } = this.state
        const { positions, optionSymbolAdditionalInfo, accountItems } = this.props
        const positionGroups = {}
        _.forEach(positions, position => {
            const { product_name: symbolName, account_name: accountName } = position
            const { originalType, optionExpiryDate, optionStrikePrice, exchangeName } = getSymbolAttributeByName(symbolName)
            const portfolioName = _.get(accountItems, `${accountName}.portfolio_name`)

            if (['CALL', 'PUT'].includes(originalType) && !_.isEmpty(optionExpiryDate) && !_.isNil(optionStrikePrice) && PORTFOLIOS.includes(portfolioName)) {
                const underlying = getOptionSymbolUnderlying(symbolName)
                const groupId = _getOptionSymbolPositionGroupId({
                    underlying,
                    expiryDate: optionExpiryDate,
                    strikePrice: optionStrikePrice
                })
                if (!_.has(positionGroups, groupId)) {
                    positionGroups[groupId] = PositionGroupStruct({
                        underlying,
                        expiryDate: optionExpiryDate,
                        strikePrice: optionStrikePrice
                    })
                }
                if (_.isNil(exchanges) || exchanges.includes(exchangeName)) {
                    if (originalType === 'CALL') {
                        positionGroups[groupId].callPositions.push(position)
                    } else {
                        positionGroups[groupId].putPositions.push(position)
                    }
                }
            }
        })

        _.forEach(optionSymbolAdditionalInfo, (info, symbolName) => {
            const { originalType, optionExpiryDate, optionStrikePrice, exchangeName } = getSymbolAttributeByName(symbolName)
            if (['CALL', 'PUT'].includes(originalType) && !_.isEmpty(optionExpiryDate) && !_.isNil(optionStrikePrice)) {
                const underlying = getOptionSymbolUnderlying(symbolName)
                const groupId = _getOptionSymbolPositionGroupId({
                    underlying,
                    expiryDate: optionExpiryDate,
                    strikePrice: optionStrikePrice
                })
                if (!_.has(positionGroups, groupId)) {
                    positionGroups[groupId] = PositionGroupStruct({
                        underlying,
                        expiryDate: optionExpiryDate,
                        strikePrice: optionStrikePrice
                    })
                }
                if (originalType === 'CALL') {
                    positionGroups[groupId].callMarkIVPerExchange[exchangeName] = _.get(info, 'mark_iv')
                } else {
                    positionGroups[groupId].putMarkIVPerExchange[exchangeName] = _.get(info, 'mark_iv')
                }
            }
        })

        return positionGroups
    }

    MarkIV (markIVPerExchange=[]) {
        const sortedPairs = _.sortBy(_.toPairs(markIVPerExchange), pair => {
            const exchangeName = pair[0]
            return exchangeName === 'DERIBIT' ? 0
                : exchangeName === 'BNBFUTA' ? 1
                : exchangeName === 'OKEX' ? 2
                : 3
        })
        return (
            <div className='aggregated-option-positions--mark-iv'>
                {_.map(sortedPairs, pair => {
                    const exchangeName = pair[0]
                    const markIV = pair[1]
                    return (
                        <div className='aggregated-option-positions--mark-iv--item' key={exchangeName}>
                            <label>{exchangeName}</label>
                            <div className='aggregated-option-positions--mark-iv--item--value'>{!_.isNil(markIV) ? `${BigNumber(markIV).times(100).toFixed(2)}%` : 'N/A'}</div>
                        </div>
                    )
                })}
            </div>
        )
    }

    PositionSum (positions=[], className) {
        const { symbolItems, pricings } = this.props
        if (_.isEmpty(positions)) {
            return <td className={className || null} />
        } else {
            const formattedPosition = _.map(positions, position => {
                const { product_name: symbolName, long_position, short_position } = position
                const { quote } = getSymbolAttributeByName(symbolName)
                const currentPrice = _.get(pricings, `${symbolName}.last`)
                const notionalValue = getNotional({
                    symbolItem: _.get(symbolItems, symbolName),
                    quantity: Number(long_position) - Number(short_position),
                    price: currentPrice,
                    BTCUSDIndexLastPrice: _.get(pricings, 'btc_usdc_BINANCE_SPT.last')
                })
                return {
                    ...position,
                    currentPrice,
                    symbolQuote: quote,
                    notionalValue
                }
            })
            const positionValueSum = _.sumBy(formattedPosition, 'notionalValue')

            return (
                <Popup className='aggregated-option-positions--position-sum--popup'
                    trigger={
                        <td className={className || null}>
                            <span className={'aggregated-option-positions--position-sum--value' + (positionValueSum > 0 ? ' positive' : ' negative')}>{`${this._formattedValue(positionValueSum)}`}</span>
                        </td>}>
                        {_.map(formattedPosition, (position, index) => {
                            const { product_name: symbolName, account_name: accountName, long_position, short_position, notionalValue, symbolQuote, timestamp } = position
                            return (
                                <div className='aggregated-option-positions--position-sum--item' key={index}>
                                    <label>{symbolName}</label>
                                    <div className='aggregated-option-positions--position-sum--data'>
                                        <div className='aggregated-option-positions--position-sum--data--row'>
                                            <label>{'Time'}</label>
                                            <div>{!_.isEmpty(timestamp) ? moment(timestamp).format('HH:mm:ss') : 'N/A'}</div>
                                        </div>
                                        <div className='aggregated-option-positions--position-sum--data--row'>
                                            <label>{'Account'}</label>
                                            <div>{accountName}</div>
                                        </div>
                                        <div className='aggregated-option-positions--position-sum--data--row'>
                                            <label>{'Long'}</label>
                                            <div>{long_position}</div>
                                        </div>
                                        <div className='aggregated-option-positions--position-sum--data--row'>
                                            <label>{'Short'}</label>
                                            <div>{short_position}</div>
                                        </div>
                                        <div className='aggregated-option-positions--position-sum--data--row'>
                                            <label>{'Notional Value'}</label>
                                            <div>
                                                <span className={Number(notionalValue) > 0 ? 'positive' : 'negative'}>{this._formattedValue(notionalValue)}</span>
                                                <span>{` ${symbolQuote}`}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}  
                </Popup>
            )
        }
    }

    render () {
        const { pricings } = this.props  
        const { underlying, expiryDate, exchanges } = this.state
        const positionGroups = this._getPositionGroups()
        const underlyings = _.map(_.uniqBy(Object.values(positionGroups), 'underlying'), positionGroup => positionGroup.underlying)
        const selectedUnderlying = underlying || underlyings[0]
        const selectedUnderlyingPrice = getTokenPriceInUSD(selectedUnderlying, pricings)

        let filteredPositionGroups = _.filter(positionGroups, positionGroup => positionGroup.underlying === selectedUnderlying)
        const expiryDates = _.map(_.uniqBy(filteredPositionGroups, 'expiryDate'), positionGroup => positionGroup.expiryDate)
        expiryDates.sort()

        const exchangeNames = this._getExchangeNames()

        const selectedExpiryDate = expiryDates.includes(expiryDate) ? expiryDate : expiryDates[0]
        filteredPositionGroups = _.filter(filteredPositionGroups, positionGroup => positionGroup.expiryDate === selectedExpiryDate)
        const seivedPositionGroups = _.sortBy(filteredPositionGroups, positionGroup => Number(positionGroup.strikePrice))
        
        return (
            <div className='aggregated-option-positions'>
                <div className='aggregated-option-positions--header'>
                    <div className='aggregated-option-positions--header--row-1'>
                        <div className='aggregated-option-positions--underlyings'>
                            {_.map(underlyings, value => {
                                return (
                                    <button className={'aggregated-option-positions--underlying' + (value === selectedUnderlying ? ' selected' : '')}
                                        key={value}
                                        onClick={() => { this.setState({ underlying: value }) }}>{value}</button>
                                )
                            })}
                        </div>
                        <div className='aggregated-option-positions--portfolios'>
                            <label>{'Portfolio: '}</label>
                            <span>{PORTFOLIOS.join(', ')}</span>
                        </div>
                    </div>
                    <div className='aggregated-option-positions--expiry-dates'>
                        <label>{'Expiry Date'}</label>
                        <div className='aggregated-option-positions--expiry-dates--list'>
                            {_.map(expiryDates, value => {
                                return (
                                    <button className={'aggregated-option-positions--expiry-date' + (value === selectedExpiryDate ? ' selected' : '')}
                                        key={value}
                                        onClick={() => { this.setState({ expiryDate: value }) }}>{value}</button>
                                )
                            })}
                        </div>
                    </div>
                    <div className='aggregated-option-positions--exchanges'>
                        <label>{'Exchanges'}</label>
                        <div className='aggregated-option-positions--exchanges--list'>
                            {_.map(exchangeNames, _exchangeName => {
                                const _selected = _.isNil(exchanges) || exchanges.includes(_exchangeName)
                                return (
                                    <button className={'aggregated-option-positions--exchange' + (_selected ? ' selected' : '')}
                                        key={_exchangeName}
                                        onClick={() => {
                                            const newExchanges = _selected ? _.without(exchanges ?? exchangeNames, _exchangeName) : _.concat(exchanges ?? exchangeNames, [_exchangeName])
                                            this.setState({ exchanges: newExchanges })
                                        }}>
                                        {_exchangeName}
                                    </button>
                                )
                            })}
                            <button className='aggregated-option-positions--exchanges--select-all-button'
                                onClick={() => { this.setState({ exchanges: exchangeNames }) }}>
                                {'Select All'}
                            </button>
                        </div>
                    </div>
                </div>
                <div className='aggregated-option-positions--main'>
                    <table>
                        <thead>
                            <tr>
                                <th colSpan={2}>{'Calls'}</th>
                                <th />
                                <th colSpan={2}>{'Puts'}</th>
                            </tr>
                            <tr>
                                <td>{'Mark IV'}</td>
                                <td className='right-align'>{`Position SUM (${selectedUnderlying || ''})`}</td>
                                <td>{'Strike'}</td>
                                <td className='left-align'>{`Position SUM (${selectedUnderlying || ''})`}</td>
                                <td>{'Mark IV'}</td>
                            </tr>
                        </thead>
                        <tbody>
                            {_.map(seivedPositionGroups, positionGroup => {
                                const { id, strikePrice, callPositions, putPositions, callMarkIVPerExchange, putMarkIVPerExchange } = positionGroup
                                const shouldHighlightCall = Number(selectedUnderlyingPrice) > 0 && Number(strikePrice) < selectedUnderlyingPrice
                                const shouldHighlightPut = Number(selectedUnderlyingPrice) > 0 && Number(strikePrice) > selectedUnderlyingPrice
                                return (
                                    <tr key={id}>
                                        <td className={shouldHighlightCall ? 'highlight' : null}>{this.MarkIV(callMarkIVPerExchange)}</td>
                                        {this.PositionSum(callPositions, 'right-align' + (shouldHighlightCall ? ` highlight` : ''))}
                                        <th>{strikePrice}</th>
                                        {this.PositionSum(putPositions, 'left-align' + (shouldHighlightPut ? ` highlight` : ''))}
                                        <td className={shouldHighlightPut ? 'highlight' : null}>{this.MarkIV(putMarkIVPerExchange)}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

AggregatedOptionPositions.propTypes = {
    positions: PropTypes.array.isRequired,
    symbolItems: PropTypes.object.isRequired,
    accountItems: PropTypes.object.isRequired,
    optionSymbolAdditionalInfo: PropTypes.object.isRequired,
    pricings: PropTypes.object.isRequired
}

function mapStateToProps (state) {
    return {
        positions: state.trading.positions,
        symbolItems: state.symbol.items,
        accountItems: state.account.items,
        optionSymbolAdditionalInfo: state.symbol.optionSymbolAdditionalInfo,
        pricings: state.symbol.pricings
    }
}

export default connect(mapStateToProps)(AggregatedOptionPositions)