import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { HiViewBoards } from 'react-icons/hi'
import Popup from '../popup/Popup'
import Checkbox from '../checkbox/Checkbox'

export const TableColumnStruct = ({ key='', name='' }) => {
    return {
        key,
        name
    }
}
export default class TableColumnSelector extends Component {
    constructor (props) {
        super(props)
    }

    render () {
        const { columns, selectedKeys, canHaveEmptySelectedKeys, onChangeSelectedKeys } = this.props
        const colomnKeys = _.map(columns, column => column.key)
        const filteredSelectedKeys = _.intersection(colomnKeys, selectedKeys)

        return (
            <Popup className={'table-column-selector'} 
                on={'click'}
                trigger={<button className='table-column-selector--trigger'><HiViewBoards /></button>}>
                <div className='table-column-selector--main' onClick={(e) => { e.stopPropagation() }}>
                    <div className='table-column-selector--header'>
                        <label>{`Select Table Columns`}</label>
                        <div className='table-column-selector--header--buttons'>
                            <button className='table-column-selector--header--select-all' 
                                onClick={() => {
                                    onChangeSelectedKeys(colomnKeys)
                                }}>{'Select All'}</button>
                            <button className='table-column-selector--header--select-all' 
                                onClick={() => {
                                    onChangeSelectedKeys([])
                                }}>{'Unselect All'}</button>
                        </div>
                    </div>
                    <div className='table-column-selector--options'>
                        {_.map(columns, column => {
                            const { key, name } = column
                            const selected = filteredSelectedKeys.includes(key)
                            return (
                                <div className='table-column-selector--option' 
                                    key={key}
                                    onClick={() => {
                                        const newSelectedKeys = selected && (canHaveEmptySelectedKeys || _.size(filteredSelectedKeys) > 1) ? _.without(filteredSelectedKeys, key) 
                                            : !selected ? _.concat(filteredSelectedKeys, key)
                                            : newSelectedKeys
                                        onChangeSelectedKeys(newSelectedKeys)
                                    }}>
                                    <Checkbox checked={selected} />
                                    <label>{name}</label>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </Popup>
        )
    }
}

TableColumnSelector.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    })).isRequired,
    selectedKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
    canHaveEmptySelectedKeys: PropTypes.bool,
    onChangeSelectedKeys: PropTypes.func.isRequired
}

TableColumnSelector.defaultProps = {
    columns: [],
    selectedKeys: [],
    onChangeSelectedKeys: () => {} 
}