import React, { memo } from 'react'
import PropTypes from 'prop-types'

import BigNumber from 'bignumber.js'
import _ from 'lodash'

import { ResponsiveBar } from '@nivo/bar'
import { COIN_COLORS, TRANSFERABLE_COINS } from './PortfolioGuard'
import { toAbbreviateNumber } from '../../util/util'
import { formattedTokenAmount } from '../../util/formatUtil'

const REQUIRED = 'REQUIRED'

const CHART_GRID_Y_SIZE = 5
function MarginOverviewChart ({ collectionOverviewPerMainAccount={}, requirementOverviewPerMainAccount={}, transferableCoins=TRANSFERABLE_COINS,
    onClickCollectMargin=()=>{}, onClickFulfillRequiredMargin=()=>{} }) {

    let chartMaxValue=0, chartMinValue=0
    const data = {}

    const _sortedMainAccounts = _.union(_.keys(collectionOverviewPerMainAccount), _.keys(requirementOverviewPerMainAccount)).toSorted()
    _.forEach(_sortedMainAccounts, _mainAccount => {
        const _collectionOverview = collectionOverviewPerMainAccount[_mainAccount]
        if (!_.isEmpty(_collectionOverview)) {
            data[_mainAccount] = {
                key: _mainAccount,
                ..._.reduce(_collectionOverview.marginInfoPerCoin, (_result, _coinMarginInfo, _coin) => {
                    _result[`${_coin}_amount`] = Number(_coinMarginInfo.totalCollectibleAmount)
                    _result[`${_coin}`] = Number(_coinMarginInfo.totalCollectibleMargin)
                    return _result
                }, {})
            }
    
            chartMaxValue = BigNumber.max(
                chartMaxValue,
                ..._.map(_collectionOverview.marginInfoPerCoin, _coinMarginInfo => _coinMarginInfo.totalCollectibleMargin)
            ).toNumber()
        }

        const _requirementOverview = requirementOverviewPerMainAccount[_mainAccount]
        if (!_.isEmpty(_requirementOverview)) {
            const required = -Number(_requirementOverview.totalRequiredMargin)
            data[_mainAccount] = Object.assign({}, data[_mainAccount], {
                key: _mainAccount,
                [REQUIRED]: required
            })
            chartMinValue = Math.min(chartMinValue, required)
        }
    })

    const chartGridYDiff = (chartMaxValue * 0.95 - chartMinValue) / (CHART_GRID_Y_SIZE - 1)
    const gridYValues = []
    for (let i=0; i< CHART_GRID_Y_SIZE; i++) {
        gridYValues.push(_.toInteger(chartMinValue * 1.05 + chartGridYDiff * i))
    }

    return chartMaxValue !== chartMinValue && (
        <div className='margin-overview-chart'>
            <ResponsiveBar
                data={
                    _.filter(data, _d => {
                        return _.some([REQUIRED, ...transferableCoins], _key => !BigNumber(_d[_key] || 0).eq(0))
                    })
                }
                keys={[REQUIRED, ...transferableCoins]}
                maxValue={chartMaxValue}
                indexBy='key'
                margin={{ top: 20, right: 120, bottom: 40, left: 50 }}
                padding={0.4}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                valueFormat={(v) => `$${toAbbreviateNumber(v, 0)}`}
                colors={({ id }) => {
                    return id === REQUIRED ? '#fd818a' 
                        : (COIN_COLORS[id] || 'white')
                }}
                groupMode='grouped'
                borderRadius={2}
                borderWidth={1.5}
                innerPadding={4}
                animate={false}
                theme={{
                    text: {
                        fontFamily: 'Source Sans Pro',
                        fontSize: 13,
                        fontWeight: 600
                    },
                    axis: {
                        ticks: {
                            text: {
                                fill: '#ddd',
                                fillOpacity: 0.4,
                                fontSize: 12
                            },
                            line: {
                                strokeOpacity: 0.6
                            }
                        }
                    },
                    grid: {
                        line: {
                            stroke: '#082438'
                        }
                    },
                    legends: {
                        text: {
                            fontSize: 12,
                            fill: '#ddd',
                            fillOpacity: 0.6
                        }
                    }
                }}
                defs={[
                    {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'inherit',
                        color: '#e4626c',
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10
                    }
                ]}
                fill={[
                    {
                        match: {
                            id: REQUIRED
                        },
                        id: 'lines'
                    }
                ]}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 3,
                    tickPadding: 8,
                    tickRotation: -12,
                    legend: '',
                    legendPosition: 'middle',
                    truncateTickAt: 0
                }}
                axisLeft={{
                    tickValues: gridYValues,
                    tickSize: 0,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendPosition: 'middle',
                    legendOffset: -40,
                    truncateTickAt: 0,
                    format: (v) => `$${toAbbreviateNumber(v, 1)}`
                }}
                gridYValues={gridYValues}
                labelSkipWidth={0}
                labelSkipHeight={0}
                labelTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.1
                        ]
                    ]
                }}
                enableLabel={false}
                labelPosition='end'
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 6,
                        itemWidth: 100,
                        itemHeight: 12,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 12,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
                role='application'
                ariaLabel='Margin Overview Chart'
                tooltip={({ id, indexValue: mainAccountName, data, value, color }) => {
                    const requirementPerCoins = _.get(requirementOverviewPerMainAccount, `${mainAccountName}.requirementPerCoins`) || {}

                    return (
                        <div className='margin-overview-chart--tooltip'>
                            <div className='margin-overview-chart--tooltip--main-account-name'>{`Main Acct: ${mainAccountName}` }</div>
                            <div className='margin-overview-chart--tooltip--value'>
                                <label style={{ color }}>{id === REQUIRED ? 'Margin Required' : `Collectible ${id}`}</label>
                                <span>
                                    {id !== REQUIRED
                                        ? `${formattedTokenAmount({ amount: _.get(data, `${id}_amount`) })} ($${BigNumber(value).toFormat(0, 1)})`
                                        : `$${BigNumber(value).abs().toFormat(0, 1)}`}
                                </span>
                            </div>
                            {id === REQUIRED &&
                            <div className='margin-overview-chart--tooltip--coin-required-margins'>
                                {_.map(requirementPerCoins, (_requirement, _coinString) => {
                                    const _coins = (_coinString).split(',')
                                    const { totalRequiredMargin } = _requirement
                                    return (
                                        <div className='margin-overview-chart--tooltip--coin-required-margin'>
                                            <div>
                                                {_.map(_coins, _coin => (<span style={{ background: COIN_COLORS[_coin] }}>{_coin}</span>))}
                                            </div>
                                            <span>{`$${BigNumber(totalRequiredMargin).toFormat(0, 1)}`}</span>
                                        </div>
                                    )
                                     
                                })}
                            </div>}
                            <div className='margin-overview-chart--tooltip--remark'>
                                {id === REQUIRED ? 'Click to generate transfers for the required margin' : 'Click to generate transfers for collecting margin'}
                            </div>
                        </div>
                    )
                }}
                // barAriaLabel={e => e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue}
                markers={[
                    {
                        axis: 'y',
                        value: 0,
                        lineStyle: { stroke: '#ddd', strokeOpacity: 0.6 },
                        legendOrientation: 'vertical'
                    }
                ]}
                onMouseEnter={(_d, event) => { event.currentTarget.style.cursor = 'pointer' }}
                onClick={({ id, indexValue: mainAccountName }) => {
                    if (id === REQUIRED) {
                        onClickFulfillRequiredMargin({ mainAccountName })
                    } else {
                        onClickCollectMargin({
                            mainAccountName,
                            coin: id
                        })
                    }
                }} />
        </div>
    )
}

MarginOverviewChart.propTypes = {
    collectionOverviewPerMainAccount: PropTypes.object.isRequired,
    requirementOverviewPerMainAccount: PropTypes.object.isRequired,
    transferableCoins: PropTypes.array.isRequired,
    onClickCollectMargin: PropTypes.func.isRequired,
    onClickFulfillRequiredMargin: PropTypes.func.isRequired
}

export default memo(MarginOverviewChart)