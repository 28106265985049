import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import _ from 'lodash'

const sockets = {}

const READY_STATE_NAMES = {
    [WebSocket.CONNECTING]: 'CONNECTING',
    [WebSocket.OPEN]: 'OPEN',
    [WebSocket.CLOSING]: 'CLOSING',
    [WebSocket.CLOSED]: 'CLOSED'
}

const webSocketSendData = (socketId, data={}) => {
    const socket = sockets[socketId]
    if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify(data))
    } else {
        console.error(`Send Data failed: WebSocket ${socketId} is not open.`)   
    }
}

export default class WebSocketClient extends Component {
    constructor (props) {
        super(props)
        this.state = {
            socketId: null,
            readyState: WebSocket.CLOSED,
            reconnectCountdownInSecond: null,
            delayInMillisecond: null
        }
        this._mounted = false
        this.reconnectCountdownInterval = null
        this.getReadyStateInterval = null
        this.pingPong = null
        this.lastPingTimestamp = null
    }

    componentDidMount () {
        this._mounted = true
        this._connect()
    }

    componentDidUpdate (prevProps) {
        const { url: prevUrl, disabled: prevDisabled } = prevProps
        const { url, disabled, shouldFetchUrl } = this.props

        if (!_.isEqual(prevDisabled, disabled)) {
            if (disabled) {
                this._disconnect()
            } else {
                this._connect()
            }
        } else if (!_.isEqual(prevUrl, url) && !_.isEmpty(url) && !shouldFetchUrl) {
            this._connect()
        }
    }

    componentWillUnmount () {
        this._mounted = false
        this._disconnect()
        if (this.getReadyStateInterval) {
            window.clearInterval(this.getReadyStateInterval)
        }
        if (this.pingPong) {
            window.clearInterval(this.pingPong)
        }
    }

    _isConnected () {
        const { socketId } = this.state
        const socket = sockets[socketId]
        return !_.isNil(socket) && socket.readyState === WebSocket.OPEN
    }

    _disconnect () {
        const { socketId } = this.state
        const socket = sockets[socketId]
        if (socket) {
            socket.close()
            delete sockets[socketId]
        }
    }

    async _connect () {
        this._registerGetReadyStateIntervalInterval()
        try {
            const { url, getUrl, shouldFetchUrl, disabled, onSocketOpen, onReceiveMessage, onSocketClose } = this.props
            const webSocketUrl = shouldFetchUrl ? await getUrl() : url
            if (_.isString(webSocketUrl) && !_.isEmpty(webSocketUrl)  && !disabled) {
                if (this._isConnected()) {
                    this._disconnect()
                }
                const socketId = uuidv4()

                this.setState({ 
                    socketId,
                    readyState: WebSocket.CONNECTING
                })
                            
                sockets[socketId] = new WebSocket(webSocketUrl)

                sockets[socketId].onopen = () => {
                    console.log(`WebSocket ${socketId} connected`) 
                    if (this._mounted) {
                        this.setState({ readyState: WebSocket.OPEN })
                        this._registerPingPong()
                    }
                    onSocketOpen({ socket: sockets[socketId], id: socketId })
                }

                sockets[socketId].onmessage = (message) => {
                    if (message.data === 'pong') {
                        const delayInMillisecond = !_.isNil(this.lastPingTimestamp) ? moment().diff(this.lastPingTimestamp, 'milliseconds') / 2 : 9999
                        this.lastPingTimestamp = null
                        this.setState({ delayInMillisecond })
                    } else {
                        onReceiveMessage(message)
                    }
                }

                sockets[socketId].onclose = () => {
                    console.log(`WebSocket ${socketId} closed`) 
                    if (this._mounted) {
                        this.setState({
                            socketId: null,
                            readyState: WebSocket.CLOSED
                        })
                    }
                    if (_.has(sockets, socketId)) {
                        delete sockets[socketId]
                    }
                    onSocketClose()
                    this._startReconnectCountdown()
                }

                sockets[socketId].onerror = (error) => { 
                    console.log(`WebSocket ${socketId} error: `, error) 
                }
            }
        } catch (error) {
            console.error(`WebSocketClient._connect error: `, error)
        }
    }

    _startReconnectCountdown () {
        const { disabled, reconnectIntervalInSecond } = this.props
        const { reconnectCountdownInSecond } = this.state
        if (this._mounted && !disabled && !this._isConnected() && _.isNil(reconnectCountdownInSecond) && _.isNil(this.reconnectCountdownInterval)) {
            this.setState({ reconnectCountdownInSecond: parseInt(reconnectIntervalInSecond) }, () => {
                this.reconnectCountdownInterval = setInterval(() => {
                    if (this._mounted && !this._isConnected() && !this.props.disabled) {
                        if (Number(this.state.reconnectCountdownInSecond) > 0) {
                            this.setState((prevState) => {
                                return { reconnectCountdownInSecond: prevState.reconnectCountdownInSecond - 1 }
                            })
                        } else {
                            window.clearInterval(this.reconnectCountdownInterval)
                            this.reconnectCountdownInterval = null
                            this.setState({ reconnectCountdownInSecond: null })
                            this._connect()
                        }
                    } else {
                        window.clearInterval(this.reconnectCountdownInterval)
                        this.reconnectCountdownInterval = null
                        if (!_.isNil(this.state.reconnectCountdownInSecond)) {
                            this.setState({ reconnectCountdownInSecond: null })
                        }
                    }
                }, 1000)
            })
        }
    }

    _registerGetReadyStateIntervalInterval () {
        const { onSocketClose } = this.props
        if (this.getReadyStateInterval) {
            window.clearInterval(this.getReadyStateInterval)
        }
        this.getReadyStateInterval = setInterval(() => {
            const { socketId, readyState }  = this.state
            const socket = sockets[socketId]
            if (this._mounted) {
                if (_.isNil(socket)) {
                    if (readyState !== WebSocket.CLOSED) {
                        this.setState({ readyState: WebSocket.CLOSED })
                        onSocketClose()
                    }
                    this._startReconnectCountdown()
                } else if (!navigator.onLine && ![WebSocket.CLOSING, WebSocket.CLOSED].includes(socket.readyState)) {
                    this._disconnect()
                }  else if (socket.readyState !== readyState){
                    this.setState({ readyState: socket.readyState })
                }
            }
        }, 1000)
    }

    _registerPingPong () {
        const { pingPongEnabled } = this.props

        const ping = () => {
            const { socketId } = this.state
            const socket = sockets[socketId]
            if (socket && this._isConnected()) {
                this.lastPingTimestamp = moment().toISOString()
                socket.send('ping')
                setTimeout(() => {
                    this.lastPingTimestamp = null
                }, 12000)
            }
        }

        if (pingPongEnabled) {
            ping()
            if (this.pingPong) {
                window.clearInterval(this.pingPong)
            }
            this.pingPong = setInterval(() => {
                ping()
            }, 15000)
        }
    }

    render () {
        const { name, shouldRender } = this.props
        const { socketId, readyState, reconnectCountdownInSecond, delayInMillisecond } = this.state
        return shouldRender ? (
            <div className='web-socket-client'>
                {readyState !== WebSocket.OPEN && Number(reconnectCountdownInSecond) > 0 && <div className='web-socket-client--reconnect-countdown'>{`Reconnect in ${Number(reconnectCountdownInSecond)}s`}</div>}
                {readyState === WebSocket.OPEN && !_.isNil(delayInMillisecond) && <div className='web-socket-client--delay'>{`${parseInt(delayInMillisecond)} ms`}</div>}
                <label title={`Socket ID: ${socketId}`}>{name}</label>
                <div className={`web-socket-client--mark ${READY_STATE_NAMES[readyState]}`} title={READY_STATE_NAMES[readyState]} />
            </div>
        ) : null
    }
}

WebSocketClient.propTypes = {
    name: PropTypes.string,
    url: PropTypes.string,
    getUrl: PropTypes.func,
    shouldFetchUrl: PropTypes.bool,
    disabled: PropTypes.bool,
    reconnectIntervalInSecond: PropTypes.number,
    pingPongEnabled: PropTypes.bool,
    shouldRender: PropTypes.bool,
    onSocketOpen: PropTypes.func,
    onReceiveMessage: PropTypes.func,
    onSocketClose: PropTypes.func
}

WebSocketClient.defaultProps = {
    getUrl: () => '',
    shouldFetchUrl: false,
    disabled: false,
    reconnectIntervalInSecond: 8,
    pingPongEnabled: false,
    shouldRender: true,
    onSocketOpen: () => { 
        return {
            socket: null,
            id: null
        }
    },
    onReceiveMessage: () => {},
    onSocketClose: () => {}
}

export { webSocketSendData }