/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import $ from 'jquery'
import dotProp from 'dot-prop-immutable'

import { MdClose } from 'react-icons/md'
import Toggle from '../common/toggle/Toggle'
import SearchSelect from '../common/searchSelect/SearchSelect'
import ProfileParam from './ProfileParam'
import { parameterTypes } from '../../configs/profileConfig'
import { toNumberInputValue } from '../../util/util'
import ArrayOfStringInput from '../common/input/ArrayOfStringInput'

class ProfileBulkUploadParamRow extends Component {

    constructor (props) {
        super(props)
        this.state = {
            bulkEditingParams: [], // [{profileIndex: 1, symbolIndex: 0}, ...]
            bulkEditorValue: null,
            symbolFilterString: ''
        }
        this.handleClickWindow = this.handleClickWindow.bind(this)
        this.paramRowNode = null
        this.paramNameNode = null
        this.forceUpdateTimeout = null
    }

    componentDidMount () {
        window.addEventListener('click', this.handleClickWindow)
    }

    componentDidUpdate (prevProps, prevState) {
        const { bulkEditingParams } = this.state
        if (_.isEmpty(prevState.bulkEditingParams) && !_.isEmpty(bulkEditingParams)) {
            this._addScrollTabelEventListener()
        } else if (!_.isEmpty(prevState.bulkEditingParams) && _.isEmpty(bulkEditingParams)) {
            this._removeScrollTableEventListener()
        }
    }

    componentWillUnmount () {
        window.removeEventListener('click', this.handleClickWindow)
        this._removeScrollTableEventListener()
    }
    
    _isArrayTypeParameter () {
        const { paramConfig } = this.props
        return [parameterTypes.BUY_SELL_BOOLEAN_ARRAY, 
            parameterTypes.BUY_SELL_NUMBER_ARRAY, 
            parameterTypes.MIN_MAX_NUMBER_ARRAY].includes(paramConfig.type)
    }

    _addBulkEditingParam ({ profileIndex, symbolIndex }) {
        const { bulkEditingParams, bulkEditorValue } = this.state
        const { profileParams } = this.props
        this.setState({
            bulkEditingParams: bulkEditingParams.concat([{
                profileIndex: profileIndex,
                symbolIndex: !_.isNil(symbolIndex) ? symbolIndex : null
            }]),
            bulkEditorValue: _.isEmpty(bulkEditingParams) 
            ? (_.isNil(symbolIndex) ? profileParams[profileIndex].value : profileParams[profileIndex].symbolValues[symbolIndex])
            : bulkEditorValue 
        })
    }

    _isBulkEditingParam ({ profileIndex, symbolIndex }) {
        const { bulkEditingParams } = this.state
        return  _.findIndex(bulkEditingParams, { 
            profileIndex: profileIndex,
            symbolIndex: !_.isNil(symbolIndex) ? symbolIndex : null
        }) > -1
    }

    _removeBulkEditingParam ({ profileIndex, symbolIndex }) {
        const { bulkEditingParams, bulkEditorValue } = this.state
        const bulkEditingParamIndex = _.findIndex(bulkEditingParams, { 
            profileIndex: profileIndex, 
            symbolIndex: !_.isNil(symbolIndex) ? symbolIndex : null 
        })
        this.setState({
            bulkEditingParams: dotProp.delete(bulkEditingParams, bulkEditingParamIndex),
            bulkEditorValue: bulkEditingParams.length === 1 ? null : bulkEditorValue
        })
    }

    _clearBulkEditingParams () {
        this.setState({ 
            bulkEditingParams: [],
            bulkEditorValue: null,
            symbolFilterString: ''
        })
    }

    _addScrollTabelEventListener () {
        const $tableWrapper = $(this.paramRowNode).closest('.profile-bulk-upload-editor--table-wrapper')
        if ($tableWrapper) {
            $tableWrapper[0].addEventListener('scroll', () => {
                if (!_.isEmpty(this.state.bulkEditingParams) && !this.forceUpdateTimeout) {
                    this.forceUpdateTimeout = setTimeout(() => {
                        this.forceUpdate()
                        this.forceUpdateTimeout = null
                    }, 250)
                }
            })   
        }
    }

    _removeScrollTableEventListener () {
        const $tableWrapper = $(this.paramRowNode).closest('.profile-bulk-upload-editor--table-wrapper')
        if ($tableWrapper) {
            $tableWrapper[0].removeEventListener('scroll', () => {
                if (!_.isEmpty(this.state.bulkEditingParams)) {
                    this.forceUpdate()
                }
            })   
        }
    }

    handleClickWindow (e) {
        const { bulkEditingParams, symbolFilterString } = this.state
        if (this.paramRowNode && !this.paramRowNode.contains(e.target) && (!_.isEmpty(bulkEditingParams) || symbolFilterString.length > 0)) {
            this._clearBulkEditingParams()
        } 
    }

    handleClickParamName () {
        const { profileParams, disableBulkEdit } = this.props
        if (!disableBulkEdit) {
            const newBulkEditingParams = []
            let newBulkEditorValue
            profileParams.forEach((profileParam, profileIndex) => {
                if (!profileParam.disabled) {
                    if (_.isArray(profileParam.symbolValues)) {
                        profileParam.symbolValues.forEach((symbolValue, symbolIndex) => {
                            if (_.isNil(newBulkEditorValue) && !_.isNil(symbolValue)) {
                                newBulkEditorValue = symbolValue
                            }
                            newBulkEditingParams.push({
                                profileIndex,
                                symbolIndex
                            })
                        })
                    } else {
                        if (_.isNil(newBulkEditorValue) && !_.isNil(profileParam.value)) {
                            newBulkEditorValue = profileParam.value
                        }
                        newBulkEditingParams.push({
                            profileIndex: profileIndex,
                            symbolIndex: null
                        })
                    }
                }
            })
            this.setState({ 
                bulkEditingParams: newBulkEditingParams,
                bulkEditorValue: newBulkEditorValue
            })
        }
    }

    renderParam ({value, disabled, bulkEditable, onChange = () => {}, onClick = () => {} }) {
        const { paramConfig, symbolItems } = this.props

        const ToggleParam = ({checked, onChangeToggle}) => {
            return (
                !_.isNil(checked)
                ? <Toggle className='profile-bulk-upload-param-row--param--toggle' 
                    checked={checked}
                    disabled={disabled}
                    trueText={'True'}
                    falseText={'False'}
                    onChange={(newChecked) => { onChangeToggle(newChecked) }} />
                : null
            )
        }

        const NumberParam = ({number, onChangeNumber}) => {
            return (
                !_.isNil(number)
                ? <input className='profile-bulk-upload-param-row--param--number-input' 
                    type={'number'}
                    disabled={disabled} 
                    value={number}
                    onChange={(e) => { onChangeNumber(toNumberInputValue(e.target.value)) }} />
                : null
            )
        }

        const SelectParam = ({value, options, onChangeOption}) => {
            return (
                !_.isNil(value)
                ? <SearchSelect className='profile-bulk-upload-param-row--param--select' 
                    value={value}
                    placeholder={'Select Type'}
                    hideSearchBar
                    options={options.map((optionValue) => {
                        return { value: optionValue, name: optionValue}
                    })} 
                    onChange={(newOption) => { onChangeOption(newOption.value) }} />
                : null
            )
        }

        const SymbolParam = ({ symbolName, onChangeSymbol }) => {
            return (
                !_.isNil(symbolName)
                ? <SearchSelect className='profile-bulk-upload-param-row--param--symbol-select' 
                        value={symbolName} 
                        placeholder={'Search Symbol'}
                        hasClearButton
                        disabled={disabled}
                        options={_.map(symbolItems, symbolItem => { 
                            return {
                                value: symbolItem.symbol_name,
                                name: symbolItem.symbol_name
                            }
                        })} 
                        onClickClearButton={() => { onChangeSymbol('INVALID') }} 
                        onChange={(newOption) => { onChangeSymbol(newOption.value) }} />
                : null
            )
        }

        const ArrayOfStringParam = ({ values, onChangeValues }) => {
            return (
                _.isArray(values)
                ? <ArrayOfStringInput
                    className='profile-bulk-upload-param-row--param--array-of-string-input'
                    values={values}
                    immutableValues={['INVALID']}
                    onChange={(newValues) => { onChangeValues(newValues) }} />
                : null
            )
        }

        const ArrayTypeParam = () => {
            const isToggles = paramConfig.type === parameterTypes.BUY_SELL_BOOLEAN_ARRAY
            const isNumbers = [parameterTypes.BUY_SELL_NUMBER_ARRAY, parameterTypes.MIN_MAX_NUMBER_ARRAY].includes(paramConfig.type)
            return (
                _.isArray(value) 
                ? <div className='profile-bulk-upload-param-row--param--array'>
                    <div className='profile-bulk-upload-param-row--param--array--section first-section'>
                        {isToggles && ToggleParam({
                            checked: value[0],
                            onChangeToggle: (newChecked) => { onChange(dotProp.set(value, '0', newChecked)) }
                        })}
                        {isNumbers && NumberParam({
                            number: value[0],
                            onChangeNumber: (newValue) => { onChange(dotProp.set(value, '0', newValue)) } 
                        })}
                    </div>
                    <div className='profile-bulk-upload-param-row--param--array--section second-section'>
                        {isToggles && ToggleParam({
                            checked: value[1],
                            onChangeToggle: (newChecked) => { onChange(dotProp.set(value, '1', newChecked)) }
                        })}
                        {isNumbers && NumberParam({
                            number: value[1],
                            onChangeNumber: (newValue) => { onChange(dotProp.set(value, '1', newValue )) } 
                        })}
                    </div>
                </div>
                : null
            )
        }

        return (
            <div className={'profile-bulk-upload-param-row--param' + (bulkEditable ? ' bulk-editable' : '')}
                onClick={(e) => { onClick(e) }}>
                {paramConfig.type === parameterTypes.BOOLEAN && 
                ToggleParam({
                    checked: value,
                    onChangeToggle: (newChecked) => { onChange(newChecked) }
                })}
                {paramConfig.type === parameterTypes.NUMBER && 
                NumberParam({
                    number: value,
                    onChangeNumber: (newValue) => { onChange(newValue) }
                })}
                {paramConfig.type === parameterTypes.STRING && _.isArray(paramConfig.options) &&
                SelectParam({
                    value: value,
                    options: paramConfig.options,
                    onChangeOption: (newOption) => { onChange(newOption) }
                })}
                {paramConfig.type === parameterTypes.SYMBOL && 
                SymbolParam({
                    symbolName: value,
                    onChangeSymbol: (newSymbolName) => { onChange(newSymbolName) }
                })}
                {paramConfig.type === parameterTypes.STRING_ARRAY &&
                ArrayOfStringParam({
                    values: value,
                    onChangeValues: (newValues) => {
                        onChange( _.isEmpty(newValues) ? ['INVALID'] : _.without(newValues, 'INVALID'))
                    }
                })}
                {this._isArrayTypeParameter() && ArrayTypeParam()}
                {paramConfig.type === parameterTypes.NUMBER_ARRAY_WITH_LENGTH_MUTLIPLIER && 
                <ProfileParam 
                    paramConfig={paramConfig} 
                    value={value} 
                    onChange={(newValue) => { onChange(newValue) }} />}
            </div>
        )
    }

    renderProfileSymbolParams (profileIndex) {
        const { onChangeParams, profileParams, disableBulkEdit } = this.props
        const profileParam = profileParams[profileIndex]
        return (
            <div className='profile-bulk-upload-param-row--symbol-params'>
                {(profileParam.symbolValues || []).map((symbolValue, symbolIndex) => {
                    return (
                        <div className='profile-bulk-upload-param-row--symbol-param' key={symbolIndex}>
                            {this.renderParam({
                                value: symbolValue,
                                disabled: profileParam.disabled,
                                bulkEditable: !profileParam.disabled && this._isBulkEditingParam({ profileIndex, symbolIndex }),
                                onChange: (newValue) => { 
                                    onChangeParams([{ 
                                        profileIndex, 
                                        symbolIndex, 
                                        newValue 
                                    }])
                                },
                                onClick: (e) => {
                                    if (!profileParam.disabled && (e.metaKey || e.ctrlKey || e.shiftKey)) {
                                        if (this._isBulkEditingParam({ profileIndex, symbolIndex })) {
                                            this._removeBulkEditingParam({ profileIndex, symbolIndex })
                                        } else if (!disableBulkEdit) {
                                            this._addBulkEditingParam({ profileIndex, symbolIndex })
                                        }
                                    }
                                }
                            })}
                        </div>
                    )
                })}
            </div>
        )
    }

    SymbolFilter () {
        const { symbolFilterString } = this.state
        const { profileParams } = this.props
        return (
            <div className='profile-bulk-upload-param-row--symbol-filter'>
                <input className='profile-bulk-upload-param-row--symbol-filter--input' 
                    spellCheck={false}
                    placeholder={'Filter Symbols'}
                    value={symbolFilterString}
                    onChange={(e) => { 
                        const trimmedFilterString = e.target.value.trim().toLowerCase()
                        const newBulkEditingParams = profileParams.reduce((result, profileParamItem, profileIndex) => {
                            profileParamItem.symbolNames.forEach((symbolName, symbolIndex) => {
                                const { disabled, hidden } = profileParams[profileIndex]
                                if (symbolName.toLowerCase().includes(trimmedFilterString) && !disabled && !hidden) {
                                    result.push({
                                        profileIndex,
                                        symbolIndex
                                    })
                                }
                            })
                            return result
                        }, [])  
                        this.setState({
                            bulkEditingParams: newBulkEditingParams, 
                            symbolFilterString: e.target.value 
                        }) 
                    }} />
            </div>
        )
    }

    renderBulkEditor () {
        const { bulkEditingParams, bulkEditorValue } = this.state
        const { profileParams, onChangeParams } = this.props
        const paramNameNodeClientRect = this.paramNameNode.getBoundingClientRect()
        const bulkEditorTop = paramNameNodeClientRect.top + (paramNameNodeClientRect.top < document.body.clientHeight / 2 ? paramNameNodeClientRect.height : -75) + 'px'
        const bulkEditorLeft = paramNameNodeClientRect.width - 210 + 'px'

        return ReactDOM.createPortal(
            <div className='profile-bulk-upload-param-row--bulk-editor' 
                style={{
                    top: bulkEditorTop,
                    left: bulkEditorLeft
                }} 
                onClick={(e) => { e.stopPropagation() }}>
                <div className='profile-bulk-upload-param-row--bulk-editor--header clearfix'>
                    <div className='profile-bulk-upload-param-row--bulk-editor--title'>{'Edit Selected Params'}</div>
                    <button className='profile-bulk-upload-param-row--bulk-editor--close-button'
                        onClick={(e) => { 
                            e.stopPropagation()
                            this._clearBulkEditingParams() 
                        }}><MdClose /></button>
                    {_.has(profileParams, '0.symbolNames') && _.isArray(profileParams[0].symbolNames) && this.SymbolFilter()}
                </div>
                <div className='profile-bulk-upload-param-row--bulk-editor--main'>
                    {this.renderParam({
                        value: bulkEditorValue,
                        onClick: (e) => { e.stopPropagation() },
                        onChange: (newValue) => {
                            const newProfileParams = []
                            const isArrayTypeParameter = this._isArrayTypeParameter()
                            let updatedIndex
                            if (isArrayTypeParameter) {
                                updatedIndex = newValue.findIndex((v, index) => {
                                    return v !== bulkEditorValue[index]
                                })
                            }
                            bulkEditingParams.forEach((bulkEditableParam) => {
                                const profileParam = profileParams[bulkEditableParam.profileIndex]
                                const oldParamValue = _.isNil(bulkEditableParam.symbolIndex) 
                                    ? profileParam.value
                                    : profileParam.symbolValues[bulkEditableParam.symbolIndex]
                                if (!profileParam.disabled && !_.isNil(oldParamValue)) {
                                    if (isArrayTypeParameter && updatedIndex > -1) {
                                        newProfileParams.push({
                                            profileIndex: bulkEditableParam.profileIndex,
                                            symbolIndex: bulkEditableParam.symbolIndex,
                                            newValue: dotProp.set(oldParamValue, updatedIndex, newValue[updatedIndex])
                                        })
                                    } else if (!isArrayTypeParameter) {
                                        newProfileParams.push({
                                            profileIndex: bulkEditableParam.profileIndex,
                                            symbolIndex: bulkEditableParam.symbolIndex,
                                            newValue: newValue
                                        })
                                    }
                                } 
                                onChangeParams(newProfileParams)
                            })
                            this.setState({
                                bulkEditorValue: newValue
                            })
                        }
                    })}
                </div>
            </div>
        , window.document.body)
    }

    render () {
        const { bulkEditingParams, symbolFilterString } = this.state
        const { paramConfig, profileParams, disableBulkEdit, onChangeParams } = this.props 
        return (
            <tr className='profile-bulk-upload-param-row' ref={(node) => { this.paramRowNode = node }}>
                <th className={'profile-bulk-upload-param-row--param-name' + (disableBulkEdit ? ' not-clickable' : '')} 
                    ref={(node) => { this.paramNameNode = node }}
                    onClick={() => { this.handleClickParamName() }}>
                    <span>{paramConfig.name}</span>
                    {(!_.isEmpty(bulkEditingParams) || symbolFilterString.length > 0) && this.renderBulkEditor()}
                </th>
                {profileParams.map((profileParam, profileIndex) => {
                    return (
                        <td className={'profile-bulk-upload-param-row--data-block' + (paramConfig.type === parameterTypes.NUMBER_ARRAY_WITH_LENGTH_MUTLIPLIER ? ' vertical-align-top' : '')} key={profileIndex}>
                            {_.isArray(profileParam.symbolValues) 
                            ? this.renderProfileSymbolParams(profileIndex)
                            : this.renderParam({
                                value: profileParam.value,
                                disabled: profileParam.disabled,
                                bulkEditable: !profileParam.disabled && this._isBulkEditingParam({ profileIndex }),
                                onChange: (newValue) => { onChangeParams([{ profileIndex, newValue }]) },
                                onClick: (e) => {
                                    if (e.metaKey || e.ctrlKey || e.shiftKey) {
                                        if (this._isBulkEditingParam({ profileIndex })) {
                                            this._removeBulkEditingParam({ profileIndex })
                                        } else if (!disableBulkEdit) {
                                            this._addBulkEditingParam({ profileIndex })
                                        }
                                    }
                                }
                            })}
                        </td>
                    )
                })}
            </tr>
        )
    }
}

const paramValuePropType = PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number, 
    PropTypes.string,
    PropTypes.array
])

ProfileBulkUploadParamRow.propTypes = {
    symbolItems: PropTypes.object.isRequired,
    paramConfig: PropTypes.object.isRequired,
    profileParams: PropTypes.arrayOf(PropTypes.shape({
        value: paramValuePropType,
        symbolValues: PropTypes.arrayOf(paramValuePropType),
        symbolNames: PropTypes.array, // Its index must be consistent with symbolValues
        disabled: PropTypes.bool,
        hidden: PropTypes.bool
    })),
    disableBulkEdit: PropTypes.bool,
    onChangeParams: PropTypes.func
}

ProfileBulkUploadParamRow.defaultProps = {
    profileParams: [],
    onChangeParams: () => {}
}

function mapStateToProps (state) {
    return {
        symbolItems: state.symbol.items
    }
}

export default connect(mapStateToProps)(ProfileBulkUploadParamRow)

