import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import dotProp from 'dot-prop-immutable'

import { MdClear } from 'react-icons/md'
import { FiX } from 'react-icons/fi'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { arrayMoveImmutable } from 'array-move'

import ProfileParam from './ProfileParam'
import SingleLimit from '../trading/SingleLimit'
import Toggle from '../common/toggle/Toggle'
import SearchSelect from '../common/searchSelect/SearchSelect'
import Popup from '../common/popup/Popup'

import { parameterTypes, legParameters, symbolParameters, refSymbolParameters, strategies, legStrategyTypes, 
    createTmpParameterConfig, createLegSymbol, createRefSymbolDefaultParameters, createStrategyDefaultSharedParameters, 
    createStrategyDefaultProductParameters, getStrategyParamConfig } from '../../configs/profileConfig'
import { fetchProfileParams } from './profileAction'
import { toNumberInputValue } from '../../util/util'

const SortableLegSymbolItem = SortableElement(({symbolOptions, symbolName, optionsMaxHeight, disableModification, showRemoveButton, hasWarning, onChangeSymbol, onClickRemoveButton}) => {
    return (
        <td className={'leg-editor--symbol-row-data' + (!disableModification ? ' resizable' : '')} style={{zIndex: 1000}}>
            <Popup
                className='leg-editor--symbol-selector--disabled-popup'
                disabled={!disableModification}
                trigger={
                    <div className={'leg-editor--symbol-selector' + (hasWarning ? ` warning` : '')}>
                        <SearchSelect
                            options={symbolOptions}
                            value={symbolName} 
                            disabled={disableModification}
                            optionsMaxHeight={optionsMaxHeight} 
                            onChange={(newOption) => { onChangeSymbol(newOption.name) }} />
                    </div>
                }>
                <span>{'You are allowed to edit Symbols only in the '}<strong>{'Profile Editor'}</strong>{' and only when the profile is '}<strong>{'STOPPED'}</strong>{'.'}</span>
            </Popup>
            {showRemoveButton && <button className='leg-editor--remove-symbol-button' onClick={() => { onClickRemoveButton() }}><MdClear /></button>}
            <SingleLimit symbolName={symbolName} />
        </td>
    )
})

const SortableLegSymbolList = SortableContainer(({ legSymbols=[], symbolOptions, optionsMaxHeight, disableModification, disableRemoveButton, onChange }) => {
    const legSymbolNames = _.compact(_.map(legSymbols, legSymbol => legSymbol.name))
    return (
        <tr className='leg-editor--symbol-row'>
            <th>{'Symbol'}</th>
            {legSymbols.map((legSymbolItem, index) => {
                const isDuplicate = _.size(_.filter(legSymbolNames, symbolName => symbolName === legSymbolItem.name)) > 1
                return (
                    <SortableLegSymbolItem 
                        key={index}
                        index={index}
                        symbolName={legSymbolItem.name}
                        symbolOptions={symbolOptions} 
                        optionsMaxHeight={optionsMaxHeight}
                        disableModification={disableModification}
                        disabled={disableModification}
                        showRemoveButton={!disableRemoveButton}
                        hasWarning={isDuplicate || legSymbolItem.name === 'INVALID'}
                        onChangeSymbol={(newSymbolName) => { 
                            if (!disableModification) {
                                const newLegSymbols = dotProp.set(legSymbols, `${index}.name`, newSymbolName)
                                onChange(newLegSymbols)
                            }
                        }} 
                        onClickRemoveButton={() => {
                            if (!disableRemoveButton) {
                                const newLegSymbols = dotProp.delete(legSymbols, index)
                                onChange(newLegSymbols || [])
                            }
                        }} />
                )
            })}
        </tr>
    )
})

class LegEditor extends Component {

    constructor (props) {
        super (props)
        this.state = {
            isChangingStrategy: false
        }

        this.nonModifiableParamKeysOnProfileStart = ['LEG1_PROD_INITIAL_LONG_POS', 'LEG1_PROD_INITIAL_SHORT_POS', 'LEG2_PROD_INITIAL_LONG_POS', 'LEG2_PROD_INITIAL_SHORT_POS',
            'LEG1_SPT_SOLDOUT_SWITCH', 'LEG2_SPT_SOLDOUT_SWITCH',
            'LEG1_FX_PROD', 'LEG1_USE_INHOUSE_FUNDING_RATE', 'LEG2_USE_INHOUSE_FUNDING_RATE', 'HEDGE_REQUIRED_QUANTITY_RATIO_TO_QUOTE_QUANTITY']
    }

    _isHedgeBIGEnabled () {
        const { legItem, legNumber } = this.props
        return legNumber === 2 && legItem.symbols.length > 1
    }

    _getSymbolSelectOption () {
        const { symbol } = this.props
        const symbolSelectOptions = _.map(_.filter(symbol.items, symbolItem => symbolItem.trading === '1'), symbolItem => {
            return {
                value: symbolItem.symbol_name,
                name: symbolItem.symbol_name
            }
        })
        return symbolSelectOptions
    }

    renderParameter ({ key, name, description, type, value, options, disabled, onChangeParam }) {
        const renderArrayValue = (index) => {
            const label = type === parameterTypes.MIN_MAX_NUMBER_ARRAY ? (index === 0 ? 'FROM' : 'TO') : (index === 0 ? 'B' : 'S')
            const isBuySellArray = [parameterTypes.BUY_SELL_BOOLEAN_ARRAY, parameterTypes.BUY_SELL_NUMBER_ARRAY].includes(type)
            return (
                <div className={'leg-editor--parameter--array-section clearfix' + 
                    (isBuySellArray ? (index === 0 ? ' BUY' : ' SELL') : ' min-max')}>
                    <label>{label}</label>
                    {type === parameterTypes.BUY_SELL_BOOLEAN_ARRAY && 
                    <Toggle checked={_.isArray(value) ? value[index] : null} 
                        disabled={disabled || false}
                        onChange={(checked) => {
                            let newValues
                            if (_.isArray(value)) {
                                newValues = dotProp.set(value, index, checked)
                            } else {
                                newValues = [false, false]
                                newValues[index] = checked
                            }
                            onChangeParam(newValues)
                        }} />}
                    {[parameterTypes.BUY_SELL_NUMBER_ARRAY, parameterTypes.MIN_MAX_NUMBER_ARRAY].includes(type) &&
                    <input
                        type={'number'}
                        disabled={disabled || false}
                        value={_.isArray(value) ? value[index] : ''}
                        onWheel={(e) => { e.target.blur() }}
                        onChange={(e) => {
                            let newValues
                            if (_.isArray(value)) {
                                newValues = dotProp.set(value, index, toNumberInputValue(e.target.value))
                            } else {
                                newValues = [null, null]
                                newValues[index] = toNumberInputValue(e.target.value)
                            }
                            onChangeParam(newValues)
                        }} />}
                </div>
            )
        }

        return (
            <div className={`leg-editor--parameter ${type}`} key={key}>
                {name && <div className='leg-editor--parameter--name'>
                    <Popup className='leg-editor--parameter--name-popup' 
                        disabled={_.isEmpty(description)}
                        trigger={<span className={!_.isEmpty(description) ? 'has-description' : ''}>{name}</span>}>
                            <span dangerouslySetInnerHTML={{__html: description}} />
                    </Popup>
                </div>}
                <div className='leg-editor--parameter--value'>
                    {type === parameterTypes.BOOLEAN && 
                    <Toggle 
                        disabled={disabled || false}
                        checked={value} 
                        onChange={(checked) => {
                            onChangeParam(checked)
                        }} />}
                    {type === parameterTypes.NUMBER && <input 
                        type={'number'}
                        disabled={disabled || false}
                        value={value} 
                        onWheel={(e) => { e.target.blur() }}
                        onChange={(e) => { onChangeParam(toNumberInputValue(e.target.value)) }} />}
                    {type === parameterTypes.STRING && _.isArray(options) && 
                    <SearchSelect className='leg-editor--parameter--search-select' 
                        value={value}
                        placeholder={'Select Type'}
                        hideSearchBar
                        options={options.map((optionValue) => {
                            return { value: optionValue, name: optionValue}
                        })} 
                        onChange={(newOption) => { onChangeParam(newOption.value) }}/>}
                    {type === parameterTypes.SYMBOL && 
                    <Popup className='leg-editor--parameter--symbol-select--popup'
                        disabled={!disabled}
                        trigger={
                            <SearchSelect className='leg-editor--parameter--symbol-select' 
                                value={value} 
                                placeholder={'Search Symbol'} 
                                disabled={disabled}
                                hasClearButton 
                                options={this._getSymbolSelectOption()} 
                                onClickClearButton={() => { onChangeParam('INVALID') }}
                                onChange={(newOption) => { onChangeParam(newOption.value) }} />
                        }>
                        <span>{'You are allowed to edit Symbol fields only when the profile is '}<strong>{'STOPPED'}</strong>{'.'}</span>
                    </Popup>}
                    {[parameterTypes.BUY_SELL_BOOLEAN_ARRAY, parameterTypes.BUY_SELL_NUMBER_ARRAY, parameterTypes.MIN_MAX_NUMBER_ARRAY].includes(type) && 
                    <div className='leg-editor--parameter--array'>
                        {renderArrayValue(0)}
                        {renderArrayValue(1)}
                    </div>}
                </div>
            </div>
        )
    }

    renderLegParams () {
        const { nonModifiableParamKeysOnProfileStart } = this
        const { legItem, legNumber, isProfileStarted, onChangeLeg } = this.props
        const parameterConfigs = legParameters[`leg${legNumber}`]
        const sortedLegParams = {}
        _.forEach(_.keys(parameterConfigs), paramKey => {
            if (_.has(legItem.params, paramKey)) {
                sortedLegParams[paramKey] = legItem.params[paramKey]
            }
        })
        _.forEach(legItem.params, (paramValue, paramKey) => {
            if (!_.has(sortedLegParams, paramKey)) {
                sortedLegParams[paramKey] = paramValue
            }
        })

        return (
            <div className='leg-editor--leg-params'>
                {_.map(sortedLegParams, (paramValue, paramKey) => {
                    const parameterConfig = parameterConfigs[paramKey] || createTmpParameterConfig(paramKey, paramValue)
                    const _disabled = isProfileStarted && nonModifiableParamKeysOnProfileStart.includes(paramKey)
                    return (
                        <Popup
                            className='leg-editor--leg-params--disabled-popup'
                            disabled={!_disabled}
                            trigger={
                                this.renderParameter({
                                    key: paramKey,
                                    name: parameterConfig.name,
                                    description: parameterConfig.description,
                                    type: parameterConfig.type,
                                    value: paramValue,
                                    disabled: _disabled,
                                    onChangeParam: (newValue) => {
                                        const newLegItem = dotProp.set(legItem, `params.${paramKey}`, newValue)
                                        onChangeLeg(newLegItem)
                                    }
                                })
                            }>
                            {`You are allowed to modify this field only when the profile is STOPPED.`}
                        </Popup>
                    )
                })}
            </div>
        )
    }

    renderSymbolTable () {
        const { nonModifiableParamKeysOnProfileStart } = this
        const { isChangingStrategy } = this.state
        const { dispatch, symbol, profileId, legItem, legNumber, shouldHideStrategy, shouldHideRefSymbols,
            disableSymbolModification, shouldAllowEmptySymbol, symbolParamKeyFilter, 
            isProfileStarted, onChangeLeg } = this.props

        const strategyType = legItem.strategy.type
        const symbolOptions = _.map(_.filter(symbol.items, item => item.trading === '1'), (symbolItem) => {
            return {
                value: symbolItem.symbol_name,
                name: symbolItem.symbol_name
            }
        })
        const symbolParamKeys = _.isObject(legItem.symbols[0].params) ? Object.keys(legItem.symbols[0].params) : []
        const symbolStrategyParamKeys = _.isObject(legItem.symbols[0].strategyParams) ? Object.keys(legItem.symbols[0].strategyParams) : []
        const symbolParamConfigs = _.has(symbolParameters, `leg${legNumber}`) ? symbolParameters[`leg${legNumber}`] : []
        const strategyParamConfigs = _.has(strategies, `${strategyType}.parameters`) ? strategies[strategyType].parameters : []
        const sortedSymbolParamKeys = _.union(Object.keys(symbolParamConfigs), symbolParamKeys).filter(paramKey => symbolParamKeys.includes(paramKey)) 
        const sortedSymbolStrategyParamKeys = _.union(Object.keys(strategyParamConfigs), symbolStrategyParamKeys).filter(paramKey => symbolStrategyParamKeys.includes(paramKey))

        const renderSymbolRow = () => {
            return (
                <SortableLegSymbolList 
                    legSymbols={legItem.symbols || []}
                    symbolOptions={symbolOptions}
                    axis={'x'}
                    distance={10}
                    optionsMaxHeight={legNumber === 2 && !this._isHedgeBIGEnabled() ? 124 : null} 
                    disableModification={disableSymbolModification} 
                    disableRemoveButton={disableSymbolModification || (_.size(legItem.symbols || []) < 2 && !shouldAllowEmptySymbol)}
                    onChange={(newLegSymbols) => {
                        const newLegItem = dotProp.set(legItem, 'symbols', newLegSymbols)
                        onChangeLeg(newLegItem)
                    }} 
                    onSortEnd={({ oldIndex, newIndex }) => {
                        const newLegItem = dotProp.set(legItem, 'symbols', arrayMoveImmutable(legItem.symbols, oldIndex, newIndex))
                        onChangeLeg(newLegItem)
                    }} />
            )
        }

        const renderRefSymbolsRow = () => {
            return (
                <tr className='leg-editor--ref-symbols-row'>
                    <th>{'Ref Symbols'}</th>
                    {(legItem.symbols || []).map((legSymbolItem, legSymbolIndex) => {
                        const { refSymbols } = legSymbolItem
                        return (
                            <td className='leg-editor--ref-symbols-row--data' key={legSymbolIndex}>
                                <div className='leg-editor--ref-symbols-row--items'>
                                    {(refSymbols || []).map((refSymbolItem, refSymbolIndex) => {
                                        return (
                                            <div className='leg-editor--ref-symbols-row--item' key={refSymbolIndex}>
                                                <Popup
                                                    className='leg-editor--ref-symbols-row--disable-edit-popup'
                                                    disabled={!isProfileStarted}
                                                    trigger={
                                                        <div className='leg-editor--ref-symbols-row--selector' onClick={(e) => { e.stopPropagation() }}>
                                                            <SearchSelect 
                                                                options={symbolOptions} 
                                                                value={refSymbolItem.name} 
                                                                disabled={isProfileStarted}
                                                                onChange={(newOption) => {
                                                                    const newLegItem = dotProp.set(legItem, `symbols.${legSymbolIndex}.refSymbols.${refSymbolIndex}.name`, newOption.value)
                                                                    onChangeLeg(newLegItem)
                                                                }} />
                                                            <button className='leg-editor--ref-symbols-row--remove-symbol-button'
                                                                disabled={(refSymbols.length === 1 && refSymbols[0].name === 'INVALID') || isProfileStarted} 
                                                                onClick={() => {
                                                                    let newLegItem
                                                                    if (refSymbols.length === 1) {
                                                                        newLegItem = dotProp.set(legItem, `symbols.${legSymbolIndex}.refSymbols.0.name`, 'INVALID')
                                                                    } else {
                                                                        newLegItem = dotProp.delete(legItem, `symbols.${legSymbolIndex}.refSymbols.${refSymbolIndex}`)
                                                                    }
                                                                    onChangeLeg(newLegItem)
                                                                }}><FiX /></button>
                                                        </div>
                                                    }> 
                                                    <span>{'You are allowed to modify Ref Symbols only when the profile is STOPPED.'}</span>
                                                </Popup>
                                                {refSymbolItem.name !=='INVALID' && <div className='leg-editor--ref-symbols-row--parameters'>
                                                    {_.map(refSymbolItem.params, (refSymbolParamValue, refSymbolParamKey) => {
                                                        const refSymbolParamConfig = refSymbolParameters[refSymbolParamKey] || createTmpParameterConfig(refSymbolParamKey, refSymbolParamValue)
                                                        return (
                                                            <div className='leg-editor--ref-symbols-row--parameter-item' key={refSymbolParamKey}>
                                                                <span className='leg-editor--ref-symbols-row--parameter-item--name'>{refSymbolParamConfig.name}</span>
                                                                <ProfileParam 
                                                                    paramConfig={refSymbolParamConfig}
                                                                    value={refSymbolParamValue} 
                                                                    onChange={(newValue) => {
                                                                        const newLegItem = dotProp.set(legItem, `symbols.${legSymbolIndex}.refSymbols.${refSymbolIndex}.params.${refSymbolParamKey}`, newValue)
                                                                        onChangeLeg(newLegItem)
                                                                    }} />
                                                            </div>
                                                        )
                                                    })}
                                                </div>}
                                            </div>
                                        )
                                    })}
                                </div>
                                {!_.isNil(refSymbols) && 
                                <Popup
                                    className='leg-editor--ref-symbols-row--disable-add-popup'
                                    disabled={!isProfileStarted}
                                    trigger={
                                        <button className={'leg-editor--ref-symbols-row--add-symbol-button' + (isProfileStarted ? ' is-disabled' : '')} 
                                            onClick={() => {
                                                if (!isProfileStarted) {
                                                    const newRefSymbolItem = {
                                                        name: 'INVALID',
                                                        params: createRefSymbolDefaultParameters()
                                                    }
                                                    const newLegItem = dotProp.merge(legItem, `symbols.${legSymbolIndex}.refSymbols`, newRefSymbolItem)
                                                    onChangeLeg(newLegItem)
                                                }
                                            }}>
                                            {'Add'}
                                        </button>}>
                                    <span>{'You are allowed to add Ref Symbol only when the profile is STOPPED.'}</span>
                                </Popup>}
                            </td>
                        )
                    })}
                </tr>
            )
        }

        const renderParamRow = (paramKey, isStrategyParam) => {
            const disabled = isProfileStarted && nonModifiableParamKeysOnProfileStart.includes(paramKey)
            let paramConfig = !isStrategyParam ? symbolParameters[`leg${legNumber}`][paramKey] : getStrategyParamConfig(strategyType, paramKey)
            const hasDescription = _.has(paramConfig, 'description') && !_.isEmpty(paramConfig.description)
            return (
                <Popup
                    key={paramKey}
                    className='leg-editor--symbol-param-row--disabled-popup'
                    disabled={!disabled}
                    trigger={
                        <tr className={'leg-editor--symbol-param-row' + (isStrategyParam ? ' is-strategy-param' : '')}>
                            <th className='leg-editor--symbol-param-name'>
                                <Popup className='leg-editor--symbol-param-name-popup'
                                    disabled={!hasDescription}
                                    trigger={
                                        <div>
                                            {isStrategyParam && _.get(paramConfig, 'strategyType') !== strategyType && <label className='leg-editor--symbol-param-row--external-strategy-label'>{_.get(paramConfig, 'strategyType')}</label>}
                                            <span className={hasDescription ? 'has-description' : ''}>{_.has(paramConfig, 'name') ? paramConfig.name : paramKey}</span>
                                        </div>
                                    }>
                                    <span dangerouslySetInnerHTML={{__html: hasDescription ? paramConfig.description : ''}} />
                                </Popup>
                            </th>
                            {(legItem.symbols || []).map((symbolItem, symbolIndex) => {
                                const paramValue = symbolItem[!isStrategyParam ? 'params' : 'strategyParams'][paramKey]
                                paramConfig = paramConfig || createTmpParameterConfig(paramKey, paramValue)
                                return (
                                    <td key={symbolIndex}>
                                        {paramConfig.type === parameterTypes.NUMBER_ARRAY_WITH_LENGTH_MUTLIPLIER 
                                        ? <ProfileParam 
                                            paramConfig={paramConfig}
                                            value={paramValue}
                                            onChange={(newValue) => { 
                                                const newLegItem = dotProp.set(legItem, `symbols.${symbolIndex}.${!isStrategyParam ? 'params' : 'strategyParams'}.${paramKey}`, newValue)
                                                onChangeLeg(newLegItem)
                                            }} /> 
                                        : this.renderParameter({
                                            key: paramKey,
                                            type: paramConfig.type,
                                            value: paramValue,
                                            disabled: disabled,
                                            options: paramConfig.options || null,
                                            onChangeParam: (newValue) => {
                                                const newLegItem = dotProp.set(legItem, `symbols.${symbolIndex}.${!isStrategyParam ? 'params' : 'strategyParams'}.${paramKey}`, newValue)
                                                onChangeLeg(newLegItem)
                                            }
                                        })}
                                    </td>
                                )
                            })}
                        </tr>
                    }>
                    <span>{'You are allowed to edit this field only when the profile is '}<strong>{'STOPPED'}</strong>{'.'}</span>
                </Popup>
            )
        }

        const renderStrategyTypeRow = () => {
            const strategyOptions = legStrategyTypes[`leg${legNumber}`].map((type) => {
                return {
                    value: type,
                    name: type
                }
            })
            
            return (
                <tr className='leg-editor--strategy-type-row'>
                    <th>{'Strategy'}</th>
                    {isChangingStrategy && <td className='leg-editor--strategy-type-row--changing-strategy-message'>{'Changing Strategy...'}</td>}
                    {!isChangingStrategy && <td colSpan={legItem.symbols.length}>
                        {!isProfileStarted 
                        ? <div className='leg-editor--strategy-type-selector' onClick={(e) => { e.stopPropagation() }}>
                            <SearchSelect
                                hideSearchBar
                                options={strategyOptions}
                                value={strategyType}
                                optionPosition={'top'}
                                onChange={(option) => { 
                                    const newStrategyType = option.value
                                    if (newStrategyType !== strategyType) {
                                        if (!_.isEmpty(profileId)) {
                                            this.setState({ isChangingStrategy: true })
                                            dispatch(fetchProfileParams({
                                                profileId,
                                                quoteAlgo: legNumber === 1 ? newStrategyType : null,
                                                hedgeAlgo: legNumber === 2 ? newStrategyType: null
                                            })).then((body) => {
                                                this.setState({ isChangingStrategy: false })
                                                if (body && !_.isEmpty(body)) {
                                                    const profileParams = Object.values(body)[0]
                                                    const newLegItem = _.cloneDeep(legItem)
                                                    newLegItem.strategy = profileParams.legs[legNumber].strategy
                                                    newLegItem.symbols.forEach((symbolItem, index) => {
                                                        symbolItem.strategyParams = _.has(profileParams.legs[legNumber].symbols, `${index}.strategyParams`) 
                                                        ? profileParams.legs[legNumber].symbols[index].strategyParams
                                                        : profileParams.legs[legNumber].symbols[0].strategyParams
                                                        _.forEach(symbolItem.strategyParams, (paramValue, paramKey) => {
                                                            if (_.isNull(paramValue)) {
                                                                const strategyParamConfig = getStrategyParamConfig(newStrategyType, paramKey)
                                                                if (strategyParamConfig) {
                                                                    symbolItem.strategyParams[paramKey] = strategyParamConfig.defaultValue
                                                                } else {
                                                                    symbolItem.strategyParams[paramKey] = 0
                                                                    console.error(`Strategy Parameter ${paramKey} is not valid`)
                                                                }
                                                            }
                                                        })
                                                    })
                                                    onChangeLeg(newLegItem)
                                                }
                                            })
                                        } else {
                                            const newLegItem = dotProp.set(legItem, 'strategy.type', newStrategyType)
                                            newLegItem.strategy.params = createStrategyDefaultSharedParameters(newStrategyType)
                                            newLegItem.symbols.forEach(symbolItem => symbolItem.strategyParams = createStrategyDefaultProductParameters(newStrategyType))
                                            onChangeLeg(newLegItem)
                                        }
                                    }
                                }} />
                            </div>
                        : <Popup
                            className='leg-editor--strategy-type-name--disabled-popup'
                            trigger={<span className={'leg-editor--strategy-type-name ' + strategyType}>{strategyType}</span>}>
                            <span>{'You are allowed to modify the strategy only when the profile is '}<strong>{'STOPPED'}</strong></span>
                        </Popup>}
                        {!_.isEmpty(legItem.strategy.params) && <div className='leg-editor--strategy-shared-params'>
                            {_.map(legItem.strategy.params, (paramValue, paramKey) => {
                                const paramConfig = getStrategyParamConfig(strategyType, paramKey) || createTmpParameterConfig(paramKey, paramValue)
                                return (
                                    <div className='leg-editor--strategy-shared-param' key={paramKey}>
                                        {this.renderParameter({
                                            key: paramKey,
                                            name: paramConfig.name,
                                            type: paramConfig.type,
                                            value: paramValue,
                                            description: paramConfig.description,
                                            options: paramConfig.options || null,
                                            onChangeParam: (newValue) => {
                                                const newLegItem = dotProp.set(legItem, `strategy.params.${paramKey}`, newValue)
                                                onChangeLeg(newLegItem)
                                            }
                                        })}
                                    </div>
                                )
                            })}
                        </div>}
                    </td>}
                </tr>
            )
        }

        return (
            <table className='leg-editor--symbols-table'>
                <tbody>
                    {renderSymbolRow()}
                    {legNumber === 1 && !shouldHideRefSymbols && renderRefSymbolsRow()}
                    {sortedSymbolParamKeys.map(paramKey => {
                        return _.startsWith(paramKey, 'HEDGE_BIG_') && !this._isHedgeBIGEnabled() ? null 
                            : _.isFunction(symbolParamKeyFilter) && symbolParamKeyFilter(paramKey) === false ? null
                            : renderParamRow(paramKey, false)
                    })}
                    {!shouldHideStrategy && 
                    <Fragment>
                        {renderStrategyTypeRow()}
                        {sortedSymbolStrategyParamKeys.map(paramKey => renderParamRow(paramKey, true))}
                    </Fragment>}
                </tbody>
            </table>
        )
    }

    render () {
        const { legNumber, legItem, shouldHideLegParams, disableSymbolModification, onChangeLeg } = this.props
        return (
            <div className='leg-editor'>
                {!shouldHideLegParams && this.renderLegParams()}
                {!disableSymbolModification && <button className='leg-editor--add-symbol-button' onClick={() => {
                    const lastSymbolItem = _.last(legItem.symbols)
                    let newSymbol, newLegItem
                    if (lastSymbolItem) {
                        newSymbol = _.cloneDeep(lastSymbolItem)
                        newSymbol.name = 'INVALID'
                        if (!_.isNil(newSymbol.refSymbol)) {
                            newSymbol.refSymbol = 'INVALID'
                        }
                        if (_.isArray(newSymbol.refSymbols) && newSymbol.refSymbols.length > 0) {
                            newSymbol.refSymbols = _.take(newSymbol.refSymbols)
                            newSymbol.refSymbols[0].name= 'INVALID'
                        }
                        newLegItem = dotProp.merge(legItem, 'symbols', newSymbol)
                    } else {
                        newSymbol = createLegSymbol({
                            legNumber: legNumber,
                            strategyType: legItem.strategy.type
                        })
                        newLegItem = dotProp.set(legItem, 'symbols', [newSymbol])
                    }
                    onChangeLeg(newLegItem)
                }}>{'Add Symbol'}</button>}
                {!_.isEmpty(legItem.symbols) && <div className='leg-editor--symbols-table-wrapper'>{this.renderSymbolTable()}</div>}
            </div>
        )
    }
}

LegEditor.propTypes = {
    dispatch: PropTypes.func.isRequired,
    symbol: PropTypes.object.isRequired,
    profileId: PropTypes.string,
    legNumber: PropTypes.oneOf([1, 2]).isRequired,
    legItem: PropTypes.shape({
        params: PropTypes.object,
        strategy: PropTypes.shape({
            type: PropTypes.string,
            params: PropTypes.object
        }),
        symbols: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            refSymbol: PropTypes.string,
            params: PropTypes.object,
            strategyParams: PropTypes.object 
        }))
    }).isRequired,
    shouldHideRefSymbols: PropTypes.bool,
    shouldHideLegParams: PropTypes.bool,
    shouldHideStrategy: PropTypes.bool,
    disableSymbolModification: PropTypes.bool,
    isProfileStarted: PropTypes.bool,
    shouldAllowEmptySymbol: PropTypes.bool,
    symbolParamKeyFilter: PropTypes.func,
    onChangeLeg: PropTypes.func.isRequired
}

LegEditor.defaultProps = {
    shouldHideRefSymbols: false,
    shouldHideLegParams: false,
    disableSymbolModification: false,
    isProfileStarted: false,
    symbolParamKeyFilter: null,
    onChangeLeg: () => {}
}

function mapStateToProps (state) {
    return {
        symbol: state.symbol,
        singleLimits: state.trading.singleLimits
    }
}

export default connect(mapStateToProps)(LegEditor)