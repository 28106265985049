export const ABI = [
    'function addActions(bytes32[] actions)',
    'function approve(address token, address spender, uint256 amount)',
    'function clearAndSetTraders(address[] addrs)',
    'function exec(address target, uint256 value, bytes data)',
    'function pause()',
    'function removeActions(bytes32[] actions)',
    'function setOwner(address newOwner)',
    'function setPauser(address newPauser)',
    'function withdraw(address token, uint256 amount)',
    'function withdrawEverything(address[] tokens)'
]