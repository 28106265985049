import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useLatest, useMountedState, useSessionStorage } from 'react-use'
import { useDispatch } from 'react-redux'

import useEvent from 'react-use-event-hook'
import BigNumber from 'bignumber.js'
import PropTypes from 'prop-types'
import dotProp from 'dot-prop-immutable'
import moment from 'moment'
import _ from 'lodash'

import SearchSelect from '../common/searchSelect/SearchSelect'
import PositiveNumberInput from '../common/input/PositiveNumberInput'
import ProfileItem from './ProfileItem'

import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector'
import { areAllValuesNonEmpty } from '../../util/util'
import { clearProfileUpdaterLogs, createProfileUpdater, fetchProfileUpdaterLogs, pauseProfileUpdater, startProfileUpdater } from './profileAction'

export const ACTION_TYPES = {
    CREATE: 'CREATE',
    EDIT: 'EDIT'
}

const SIDES = {
    BUY: 'BUY',
    SELL: 'SELL'
}

function TWAPUpdater ({ actionType=ACTION_TYPES.CREATE, updaterId, onCreateUpdater=()=>{} }) {

    const dispatch = useDispatch()
    const isMounted = useMountedState()

    const profileItems = useShallowEqualSelector(state => _.get(state, 'profile.items'))
    const profileUpdater = useShallowEqualSelector(state => _.get(state, `profile.updaters.${updaterId}`, {}))
    const updaterLogs = useShallowEqualSelector(state => _.get(state, `profile.updaterLogs.${updaterId}`, []))

    const [profileInput, setProfileInput] = useState({})
    // {
    //     hostname: '',
    //     profileName: '',
    //     user: ''
    // }

    const [paramsInput, setParamsInput] = useState({})
    // {
    //     side: SIDES.BUY,
    //     initialSkew: 0,
    //     totalValue: 0,
    //     duration: 0,
    //     interval: 0
    // }

    let _profileId
    if (actionType === ACTION_TYPES.CREATE) {
        _profileId = areAllValuesNonEmpty([profileInput?.profileName, profileInput?.hostname]) ? `${profileInput?.profileName}_${profileInput?.hostname}` : null
    } else if (actionType === ACTION_TYPES.EDIT) {
        _profileId = areAllValuesNonEmpty([profileUpdater?.profileName, profileUpdater?.hostname]) ? `${profileUpdater.profileName}_${profileUpdater.hostname}` : null
    }
    const profileItem = useShallowEqualSelector(state => _.get(state, `profile.items.${_profileId}`, {}))

    const [isCreating, setIsCreating] = useState(false)
    const [updaterParamsMessage, setUpdaterParamsMessage] = useState('')
    const [isFetchingLogs, setIsFetchingLogs] = useState(false)
    const [isProfileExpanded, setIsProfileExpanded] = useSessionStorage('twap-updater--is-profile-expanded', true)
    const [isStarting, setIsStarting] = useState(false)
    const [isPausing, setIsPausing] = useState(false)

    const latestUpdaterId = useLatest(updaterId)
    const logsRef = useRef(null)
    const isLogsAtBottom = useRef(true)

    useEffect(() => {
        if (!_.isEmpty(profileInput)) {
            setProfileInput({})
        }
        
        if (actionType === ACTION_TYPES.CREATE) {
            setParamsInput({ side: SIDES.BUY })
        } else if (!_.isEmpty(paramsInput)) {
            setParamsInput({})
        }

        if (isCreating) {
            setIsCreating(false)
        }
        if (isStarting) {
            setIsStarting(false)
        }
        if (isPausing) {
            setIsPausing(false)
        }

        isLogsAtBottom.current = true

        if (actionType === ACTION_TYPES.EDIT && !_.isEmpty(updaterId)) {
            if (!isFetchingLogs) {
                setIsFetchingLogs(true)
            }
            dispatch(fetchProfileUpdaterLogs(updaterId))
            .finally(() => {
                if (isMounted() && latestUpdaterId.current === updaterId) {
                    setIsFetchingLogs(false)
                }
            })
        }
    }, [actionType, updaterId])

    useEffect(() => {    
        // Only perform the scroll if the user is already at the bottom
        if (logsRef.current && isLogsAtBottom.current) {
            logsRef.current.scrollTop = 999999
        }
    }, [_.size(updaterLogs)])

    const profileOptions = useMemo(() =>{
        return _.map(profileItems, _item => {
            const { id, hostname, name, user } = _item
            return {
                value: id,
                name: `${name} - ${hostname} - ${user}`,
                hostname,
                profileName: name,
                user
            }
        })
    }, [_.size(profileItems)])

    const _profileInput = Object.assign({}, _.pick(profileUpdater, ['hostname', 'profileName', 'user']), profileInput)
    const _params = Object.assign({}, profileUpdater?.params, paramsInput)

    const canEditParams = !isStarting && profileUpdater?.isPaused !== false

    const canCreate = !isCreating && !isStarting && profileUpdater?.isPaused !== false
        && areAllValuesNonEmpty([_profileInput.hostname, _profileInput.profileName, _profileInput.user])
        && areAllValuesNonEmpty([_params.side, _params.totalValue, _params.duration, _params.interval])
        && (!_.isEmpty(profileInput) || !_.isEmpty(paramsInput) || profileUpdater.isPaused === true)

    const canStart = !isCreating && !isStarting && actionType === ACTION_TYPES.EDIT && !_.isEmpty(updaterId) && profileUpdater?.isPaused !== false && _.isEmpty(profileInput) && _.isEmpty(paramsInput)
    const canPause = !isCreating && !isPausing && actionType === ACTION_TYPES.EDIT && !_.isEmpty(updaterId) && profileUpdater?.isPaused === false

    const handleClickCreate = useEvent(() => {
        if (canCreate) {
            setIsCreating(true)
            setUpdaterParamsMessage('')
            
            if (!_.isEmpty(updaterLogs)) {
                dispatch(clearProfileUpdaterLogs(updaterId))
            }

            dispatch(createProfileUpdater({
                ..._profileInput,
                params: _params
            }))
            .then((_createdUpdater) => {
                if (isMounted()) {
                    setProfileInput({})
                    setParamsInput({})
                }
                onCreateUpdater(_createdUpdater)
            })
            .catch(error => {
                if (isMounted()) {
                    setUpdaterParamsMessage(_.toString(error))
                }
            })
            .finally(() => {
                if (isMounted()) {
                    setIsCreating(false)
                }
            })
        }
    })

    const handleClickStart = useEvent(() => {
        if (canStart) {
            setIsStarting(true)
            dispatch(startProfileUpdater(updaterId))
            .finally(() => {
                if (isMounted() && latestUpdaterId.current === updaterId) {
                    setIsStarting(false)
                }
            })
        }
    })

    const handleClickPause = useEvent(() => {
        if (canPause) {
            setIsPausing(true)
            dispatch(pauseProfileUpdater(updaterId))
            .finally(() => {
                if (isMounted() && latestUpdaterId.current === updaterId) {
                    setIsPausing(false)
                }
            })
        }
    })

    const handleClickPauseAndReset = useEvent(async () => {
        try {
            await dispatch(pauseProfileUpdater(updaterId))
            
            if (!_.isEmpty(updaterLogs)) {
                dispatch(clearProfileUpdaterLogs(updaterId))
            }

            await dispatch(createProfileUpdater({
                ..._profileInput,
                params: _params
            }))
        } catch (error) {
            if (isMounted()) {
                setUpdaterParamsMessage(_.toString(error))
            }
        }
    })

    // eslint-disable-next-line react/prop-types
    function UpdaterParam ({ label, component }) {
        return (
            <div className='twap-updater--updater-param'>
                <label>{label}</label>
                <div className='twap-updater--updater-param--main'>{component}</div>
            </div>
        )
    }

    const _updaterProgress = profileUpdater?.isPaused !== null && areAllValuesNonEmpty([profileUpdater?.currentSkew, profileUpdater?.params?.totalValue]) && BigNumber(profileUpdater.params.totalValue).gt(0)
        ? BigNumber(profileUpdater.currentSkew).abs().div(profileUpdater.params.totalValue)
        : null

    return (
        <div className='twap-updater'>
            <div className='twap-updater--main'>
                <div className='twap-updater--updater-params'>
                    <div className='twap-updater--updater-params--title title'>{'Updater Params'}</div>
                    <div className='twap-updater--updater-params--main'>
                        {actionType === ACTION_TYPES.CREATE && UpdaterParam({
                            label: 'Profile',
                            component: (
                                <SearchSelect className='twap-updater--profile-selector'
                                    placeholder='Select Profile'
                                    options={profileOptions}
                                    value={areAllValuesNonEmpty([profileInput.hostname, profileInput.profileName]) ? `${profileInput.profileName}_${profileInput.hostname}` : null}
                                    onChange={(_newOption) => {
                                        setProfileInput({
                                            hostname: _newOption.hostname,
                                            profileName: _newOption.profileName,
                                            user: _newOption.user
                                        })
                                    }} />
                            )
                        })}
                        {UpdaterParam({
                            label: 'Side',
                            component: (
                                <div className='twap-updater--sides'>
                                    {_.map(SIDES, _side => {
                                        return (
                                            <button key={_side}
                                                className={`twap-updater--side ${_side}${_side === _params.side ? ' selected' : ''}`}
                                                disabled={!canEditParams}
                                                onClick={() => { setParamsInput(dotProp.set(paramsInput, 'side', _side)) }}>
                                                {_side}
                                            </button>
                                        )
                                    })}
                                </div>
                            )
                        })}
                        {/* {UpdaterParam({
                            label: 'Initial Skew',
                            component: (
                                <PositiveNumberInput
                                    autoFocus={false}
                                    value={_params.initialSkew}
                                    disabled={!canEditParams}
                                    onChange={(newValue) => {
                                        setParamsInput(dotProp.set(paramsInput, 'initialSkew', _.isEmpty(newValue) ? '' : Number(newValue)))
                                    }} />
                            )
                        })} */}
                        {UpdaterParam({
                            label: 'Total Value',
                            component: (
                                <PositiveNumberInput
                                    autoFocus={false}
                                    value={_params.totalValue ?? ''}
                                    disabled={!canEditParams}
                                    onChange={(newValue) => {
                                        setParamsInput(dotProp.set(paramsInput, 'totalValue', _.isEmpty(newValue) ? '' : Number(newValue)))
                                    }} />
                            )
                        })}
                        {UpdaterParam({
                            label: 'Duration (s)',
                            component: (
                                <PositiveNumberInput
                                    autoFocus={false}
                                    value={_params.duration ?? ''}
                                    disabled={!canEditParams}
                                    onChange={(newValue) => {
                                        setParamsInput(dotProp.set(paramsInput, 'duration', _.isEmpty(newValue) ? '' : Number(newValue)))
                                    }} />
                            )
                        })}
                        {UpdaterParam({
                            label: 'Interval (s)',
                            component: (
                                <PositiveNumberInput
                                    autoFocus={false}
                                    value={_params.interval ?? ''}
                                    disabled={!canEditParams}
                                    onChange={(newValue) => {
                                        setParamsInput(dotProp.set(paramsInput, 'interval', _.isEmpty(newValue) ? '' : Number(newValue)))
                                    }} />
                            )
                        })}
                    </div>
                    <div className='twap-updater--updater-params--footer'>
                        {updaterParamsMessage && <div className='twap-updater--updater-params--message'>{updaterParamsMessage}</div>}
                        <div className='twap-updater--updater-params--footer--buttons'>
                            <button className='twap-updater--updater-params--reset-button'
                                disabled={isCreating
                                    || (actionType === ACTION_TYPES.CREATE && _.isEqual(_.keys(paramsInput), ['side']))
                                    || (actionType === ACTION_TYPES.EDIT && _.isEmpty(paramsInput))}
                                onClick={() => {
                                    if (actionType === ACTION_TYPES.CREATE) {
                                        setParamsInput({ side: 'BUY' })
                                    } else if (actionType === ACTION_TYPES.EDIT) {
                                        setParamsInput({})
                                    }
                                }}>
                                {'Reset Params'}
                            </button>
                            <button
                                className='twap-updater--updater-params--create-button'
                                disabled={!canCreate}
                                onClick={() => { handleClickCreate() }}>
                                {actionType === ACTION_TYPES.EDIT ? (isCreating ? 'Updating' : (_.isEmpty(profileInput) && _.isEmpty(paramsInput) && profileUpdater?.isPaused === true ? 'Reset State' :'Update'))
                                : (isCreating ? 'Creating' : 'Create')}
                            </button>
                        </div>
                    </div>
                </div>
                <div className='twap-updater--state-overview'>
                    <div className='twap-updater--action-buttons'>
                        <button
                            className='twap-updater--start-button'
                            disabled={!canStart}
                            onClick={() => { handleClickStart() }}>
                            {_.isNil(profileUpdater?.isPaused)
                            ? (isStarting ? 'Starting' : 'Start')
                            : (isStarting ? 'Resuming' : 'Resume')}
                        </button>
                        {!_.isNil(_updaterProgress) && _updaterProgress.gte(1)
                        ? <button className='twap-updater--pause-and-reset-button' onClick={() => {  handleClickPauseAndReset() }}>{'Pause & Reset'}</button>
                        : <button
                            className='twap-updater--pause-button'
                            disabled={!canPause}
                            onClick={() => { handleClickPause() }}>{isPausing ? 'Pausing' : 'Pause'}</button>}
                    </div>
                    <div className='twap-updater--updater-state'>
                        <div className='twap-updater--updater-state--title title'>{'Updater State'}</div>
                        <div className='twap-updater--updater-state--main'>
                            <div>
                                <label>{'Current Skew'}</label>
                                <div>{_.get(profileUpdater, 'currentSkew', 'N/A')}</div>
                            </div>
                            <div>
                                <label>{'Paused'}</label>
                                <div>{_.toString(_.get(profileUpdater, 'isPaused', 'N/A'))}</div>
                            </div>
                            <div>
                                <label>{'Last Updated'}</label>
                                <div>{_.isEmpty(profileUpdater?.createdTimestamp) ? 'N/A' : moment(profileUpdater.createdTimestamp).format('YYYY-MM-DD HH:mm:ss')}</div>
                            </div>
                        </div>
                    </div>
                    <div className='twap-updater--profile-state'>
                        <div className='twap-updater--profile-state--title title'>{'Profile State'}</div>
                        <div className='twap-updater--profile-state--main'>
                            <div>
                                <label>{'Skew'}</label>
                                <div>{_.get(profileItem, 'params.SKEW', 'N/A')}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='twap-updater--updater-logs'>
                    <div className='twap-updater--updater-logs--title title'>{'Logs'}</div>
                    <div className='twap-updater--updater-logs--main' ref={logsRef}
                        onScroll={(e) => {
                            const { scrollTop, scrollHeight, clientHeight } = e.target
                            isLogsAtBottom.current = scrollTop + clientHeight + 1 >= scrollHeight
                        }}>
                        {_.map(updaterLogs, (_log, _index) => {
                            return (
                                <div className='twap-updater--updater-logs--item' key={_index}>{_log}</div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className='twap-updater--profile'>
                <div className='twap-updater--profile--title title'>{'Profile'}</div>
                {!_.isEmpty(profileItem) &&
                <ProfileItem
                    profile={profileItem}
                    expanded={isProfileExpanded}
                    onClickToggleExpand={() => { setIsProfileExpanded(!isProfileExpanded) }} />}
            </div>
        </div>
    )
}

TWAPUpdater.propTypes = {
    actionType: PropTypes.oneOf(_.values(ACTION_TYPES)),
    updaterId: PropTypes.string,
    onCreateUpdater: PropTypes.func
}

export default memo(TWAPUpdater)