import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Modal from 'react-modal'
import { FiX } from 'react-icons/fi'
import { AutoSizer, CellMeasurer, CellMeasurerCache, Table, Column } from 'react-virtualized'

import ProfileSwitchItem from '../profile/ProfileSwitchItem'
import { getProfileSymbolNames, getProfileTradingAccountNamesBySymbol } from '../../util/profileUtil'
import { switchOnProfileSymbol, switchOffProfileSymbol, switchProfileSymbolReduceOnly } from '../profile/profileAction'
import { getSymbolAttributeByName, getSymbolSwitchType, INSTRUMENT_TYPES, SYMBOL_SWITCH_TYPES } from '../../util/symbolUtil'

import SearchSelect from '../common/searchSelect/SearchSelect'
import Popup from '../common/popup/Popup'
import { EXCHANGES_CAN_USE_REDUCE_ONLY } from '../../configs/tradingConfig'
import ProfileReduceOnlySwitchItem from '../profile/ProfileReduceOnlySwitchItem'

const ACTION_TYPES = {
    TRADING: 'Trading',
    REDUCE_ONLY: 'Reduce-Only'
}

const getSessionStorageActionType = () => {
    const actionType = sessionStorage.switchControllerActionType
    return _.keys(ACTION_TYPES).includes(actionType) ? actionType : _.keys(ACTION_TYPES)[0]
}

const updateSessionStorageActionType = (actionType) => {
    sessionStorage.switchControllerActionType = actionType
}

class SwitchController extends Component {
    constructor (props) {
        super(props)
        this.sortBys = {
            ACCOUNT: 'ACCOUNT',
            SYMBOL: 'SYMBOL'
        }
        this.symbolTypes = {
            ALL: 'ALL',
            QUOTE: 'QUOTE',
            HEDGE: 'HEDGE'
        }
        this.cellMeasurerCache = new CellMeasurerCache({
            fixedWidth: true,
            fixedHeight: false
        })
        this.state = {
            accountSearchString: sessionStorage.switchControllerAccountSearchString || '',
            symbolSearchString: sessionStorage.switchControllerSymbolSearchString || '',
            profileSearchString: sessionStorage.switchControllerProfileSearchString || '',
            sortBy: this.sortBys.ACCOUNT,
            symbolType: this.symbolTypes.ALL,
            actionType: getSessionStorageActionType()
        }
        this.prevSymbolAccountProfileIds = null
    }

    updateAccountSearchString (searchString) {
        sessionStorage.switchControllerAccountSearchString = searchString
        this.setState({ accountSearchString: sessionStorage.switchControllerAccountSearchString })
        this.scrollToTop()
    }

    updateSymbolSearchString (searchString) {
        sessionStorage.switchControllerSymbolSearchString = searchString
        this.setState({ symbolSearchString: sessionStorage.switchControllerSymbolSearchString })
        this.scrollToTop()
    }

    updateProfileSearchString (searchString) {
        sessionStorage.switchControllerProfileSearchString = searchString
        this.setState({ profileSearchString: sessionStorage.switchControllerProfileSearchString })
        this.scrollToTop()
    }

    getSymbolAccountProfileIds () {
        const { profileItems, profileIdsToPick } = this.props
        const { accountSearchString, symbolSearchString, profileSearchString, symbolType, sortBy, actionType } = this.state
        const trimmedAccountSearchString = accountSearchString.toLowerCase().trim()
        const trimmedSymbolSearchString = symbolSearchString.toLowerCase().trim()
        const trimmedProfileSearchString = profileSearchString.toLowerCase().trim()
        const symbolAccountProfileIds = _.reduce(_.isEmpty(profileIdsToPick) ? profileItems : _.pick(profileItems, profileIdsToPick), (result, profileItem) => {
            if (profileItem.started && !profileItem.isPausing && !profileItem.isStarting && _.has(profileItem, 'legs')) {
                const profileSymbolNames = [this.symbolTypes.QUOTE, this.symbolTypes.HEDGE].includes(symbolType)
                    ? (profileItem.legs[symbolType === this.symbolTypes.QUOTE ? '1' : '2'].symbols || []).map(legSymbol => legSymbol.name)
                    : getProfileSymbolNames(profileItem)
                const filteredProfileSymbolNames = actionType === 'REDUCE_ONLY'
                    ? _.filter(profileSymbolNames, symbolName => {
                        const { exchangeName, instrumentType } = getSymbolAttributeByName(symbolName)
                        return EXCHANGES_CAN_USE_REDUCE_ONLY.includes(exchangeName) && instrumentType !== INSTRUMENT_TYPES.SPOT
                    })
                    : profileSymbolNames

                _.uniq(filteredProfileSymbolNames).forEach(symbolName => {
                    const symbolTradingAccountNames = getProfileTradingAccountNamesBySymbol(profileItem, symbolName)
                    const unionTradingAccountNames = _.uniq([...symbolTradingAccountNames.BUY, ...symbolTradingAccountNames.SELL])

                    unionTradingAccountNames.forEach(tradingAccountName => {
                        const id = `${tradingAccountName}--${symbolName}`
                        if ((trimmedAccountSearchString.length === 0 || _.some(trimmedAccountSearchString.split(/\|{2}|\s+/), v => v.trim().length > 0 && (tradingAccountName || '').toLowerCase().includes(v.trim())))
                            && (trimmedSymbolSearchString.length === 0 || _.some(trimmedSymbolSearchString.split(/\|{2}|\s+/), v => v.trim().length > 0 && (symbolName || '').toLowerCase().includes(v.trim())))
                            && (trimmedProfileSearchString.length === 0 || _.some(trimmedProfileSearchString.split(/\|{2}|\s+/), v => v.trim().length > 0 && profileItem.name.toLowerCase().includes(v.trim())))) {
                            if (!_.has(result, id)) {
                                result[id] = {
                                    accountName: tradingAccountName,
                                    symbolName,
                                    profileIds: []
                                }
                            }
                            result[id].profileIds.push(profileItem.id)
                        }
                    })
                })
            }
            return result
        }, {})
        return _.sortBy(symbolAccountProfileIds, item => {
            if (sortBy === this.sortBys.ACCOUNT) {
                return item.accountName
            } else {
                return item.symbolName
            }
        })
    }

    scrollToTop () {
        if (this.tableNode) {
            this.tableNode.scrollToPosition(0)
            setTimeout(() => {
                this.tableNode.scrollToPosition(0)
            })
        }
    }

    handleClickBatchSwitchButton ({ profileIds=[], accountName, symbolName, type='ON', side, actionType }) {
        const { dispatch } = this.props
        if (actionType === 'REDUCE_ONLY') {
            profileIds.forEach(profileId => dispatch(switchProfileSymbolReduceOnly({
                profileId,
                symbolName,
                accountName,
                side,
                action: type
            })))
        } else {
            const switchAction = type === 'ON' ? switchOnProfileSymbol : switchOffProfileSymbol
            profileIds.forEach(profileId => dispatch(switchAction({
                profileId,
                symbolName,
                accountName,
                side
            })))
        }
    }

    ProfileSideSwitch ({ profileItem, accountName, symbolName, direction='BUY' }) {     
        const { actionType } = this.state
        const switchType = getSymbolSwitchType(symbolName, accountName)
        return (
            <div className='switch-controller--profile-side-switch'>
                <div className={`switch-controller--profile-side-switch--direction ${direction}${actionType === 'TRADING' && switchType === SYMBOL_SWITCH_TYPES.BUY_SELL_TO_OPEN_CLOSE ? ' has-open-close' : ''}`}>{direction}</div>
                <div className='switch-controller--profile-side-switch--main'>
                    {actionType === 'REDUCE_ONLY' 
                    ? <ProfileReduceOnlySwitchItem 
                        profileItem={profileItem}
                        accountName={accountName}
                        symbolName={symbolName}
                        direction={direction} />
                    : <ProfileSwitchItem 
                        profileItem={profileItem}
                        accountName={accountName}
                        symbolName={symbolName}
                        direction={direction} />}
                </div>
            </div>
        )
    }

    BatchSwitch ({ type='ON', hasBuySellType, hasBuySellToOpenCloseType, onClickButton=() => {} }) {
        return (
            <div className='switch-controller--batch-switch'>
                <div className={`switch-controller--batch-switch--type ${type}`}>{`Switch ${type}`}</div>
                <div className={'switch-controller--batch-switch--buttons BUY'}>
                    {hasBuySellType && <Fragment>
                        <button onClick={() => { onClickButton('BUY') }}>{'BUY'}</button>
                    </Fragment>}
                    {hasBuySellToOpenCloseType && <Fragment>
                        <button onClick={() => { onClickButton('BUY_OPEN') }}>{'BUY OPEN'}</button>
                        <button onClick={() => { onClickButton('BUY_CLOSE') }}>{'BUY CLOSE'}</button>
                    </Fragment>}
                </div>
                <div className={'switch-controller--batch-switch--buttons SELL'}>
                    {hasBuySellType && <Fragment>
                        <button onClick={() => { onClickButton('SELL') }}>{'SELL'}</button>
                    </Fragment>}
                    {hasBuySellToOpenCloseType && <Fragment>
                        <button onClick={() => { onClickButton('SELL_OPEN') }}>{'SELL OPEN'}</button>
                        <button onClick={() => { onClickButton('SELL_CLOSE') }}>{'SELL CLOSE'}</button>
                    </Fragment>}
                </div>
            </div>
        )
    }

    BatchOperationPopup () {
        const { dispatch } = this.props
        const symbolAccountProfileIds = this.getSymbolAccountProfileIds()
        const symbolSwitchTypes = _.uniq(symbolAccountProfileIds.map(item => {
            const { symbolName, accountName } = item
            return getSymbolSwitchType(symbolName, accountName)
        }))
        
        const handleClickButton = (type='ON', side) => {
            symbolAccountProfileIds.forEach(item => {
                const { accountName, symbolName, profileIds } = item
                const switchType = getSymbolSwitchType(symbolName, accountName)
                if ((switchType === SYMBOL_SWITCH_TYPES.BUY_SELL && ['BUY', 'SELL'].includes(side))
                || (switchType === SYMBOL_SWITCH_TYPES.BUY_SELL_TO_OPEN_CLOSE && ['BUY_OPEN', 'BUY_CLOSE', 'SELL_OPEN', 'SELL_CLOSE'].includes(side))) {
                    const switchAction = type === 'ON' ? switchOnProfileSymbol : switchOffProfileSymbol
                    profileIds.forEach(profileId => dispatch(switchAction({
                        profileId,
                        symbolName,
                        accountName,
                        side
                    })))
                }
            })
        }

        return !_.isEmpty(symbolAccountProfileIds) ? (
            <Popup className='switch-controller--batch-operation-popup'
                on={'click'}
                shouldForceLeftBiased
                trigger={<button className='switch-controller--batch-operation-popup--trigger'>{'Batch Operation'}</button>}>
                <div className='switch-controller--batch-operation-popup--title'>{'Trading Switch'}</div>
                {this.BatchSwitch({
                    type: 'ON',
                    hasBuySellType: symbolSwitchTypes.includes(SYMBOL_SWITCH_TYPES.BUY_SELL),
                    hasBuySellToOpenCloseType: symbolSwitchTypes.includes(SYMBOL_SWITCH_TYPES.BUY_SELL_TO_OPEN_CLOSE),
                    onClickButton: (side) => { handleClickButton('ON', side) }
                })}
                {this.BatchSwitch({
                    type: 'OFF',
                    hasBuySellType: symbolSwitchTypes.includes(SYMBOL_SWITCH_TYPES.BUY_SELL),
                    hasBuySellToOpenCloseType: symbolSwitchTypes.includes(SYMBOL_SWITCH_TYPES.BUY_SELL_TO_OPEN_CLOSE),
                    onClickButton: (side) => { handleClickButton('OFF', side) }
                })}
            </Popup>
        ) : <span>{'Batch Operation'}</span>
    }

    ReduceOnlyBatchOperationPopup () {
        const { dispatch } = this.props
        const symbolAccountProfileIds = this.getSymbolAccountProfileIds()
        const handleClickButton = (type='ON', side) => {
            symbolAccountProfileIds.forEach(item => {
                const { accountName, symbolName, profileIds } = item
                profileIds.forEach(profileId => dispatch(switchProfileSymbolReduceOnly({
                    profileId,
                    symbolName,
                    accountName,
                    side,
                    action: type
                })))
            })
        }

        return !_.isEmpty(symbolAccountProfileIds) ? (
            <Popup className='switch-controller--batch-operation-popup'
                on={'click'}
                shouldForceLeftBiased
                trigger={<button className='switch-controller--batch-operation-popup--trigger'>{'Batch Operation'}</button>}>
                <div className='switch-controller--batch-operation-popup--title'>{'Reduce-Only Switch'}</div>
                {this.BatchSwitch({
                    type: 'ON',
                    hasBuySellType: true,
                    hasBuySellToOpenCloseType: false,
                    onClickButton: (side) => { handleClickButton('ON', side) }
                })}
                {this.BatchSwitch({
                    type: 'OFF',
                    hasBuySellType: true,
                    hasBuySellToOpenCloseType: false,
                    onClickButton: (side) => { handleClickButton('OFF', side) }
                })}
            </Popup>
        ) : <span>{'Batch Operation'}</span>
    }

    Table () {
        const { profileItems } = this.props
        const { accountSearchString, symbolSearchString, profileSearchString, sortBy, symbolType, actionType } = this.state
        const symbolAccountProfileIds = this.getSymbolAccountProfileIds()

        if (!_.isEqual(this.prevSymbolAccountProfileIds, symbolAccountProfileIds)) {
            this.cellMeasurerCache.clearAll()
            this.prevSymbolAccountProfileIds = symbolAccountProfileIds
        }

        return (
            <AutoSizer disableWidth>
                {({height}) => (
                    <Table
                        ref={(node) => { this.tableNode = node }}
                        className='switch-controller--table' 
                        headerHeight={27}
                        headerClassName={'switch-controller--table--header'}
                        rowClassName={'switch-controller--table--row'}
                        height={height}
                        width={1000}
                        rowCount={symbolAccountProfileIds.length}
                        rowGetter={({ index }) => symbolAccountProfileIds[index]}
                        rowHeight={this.cellMeasurerCache.rowHeight}
                        overscanRowCount={5}
                        deferredMeasurementCache={this.cellMeasurerCache}
                        noRowsRenderer={() => (
                            <div className='switch-controller--table--no-content'>{'There is no matched profiles.'}</div>
                        )}>
                        <Column 
                            dataKey={'accountName'}
                            width={160}
                            className='switch-controller--table--account-name'
                            headerRenderer={() => (
                                <Fragment>
                                    <span className={'sortable' + (sortBy === this.sortBys.ACCOUNT ? ' sorted' : '')}
                                        onClick={() => { this.setState({ sortBy: this.sortBys.ACCOUNT }) }}>{'Account'}</span>
                                    <input value={accountSearchString} 
                                        placeholder={'Search'}
                                        spellCheck={false}
                                        onChange={(e) => { this.updateAccountSearchString(e.target.value) }} />
                                </Fragment>
                            )} 
                            cellRenderer={({ rowData }) => {
                                const { symbolName, accountName } = rowData
                                const switchType = getSymbolSwitchType(symbolName, accountName)
                                return (
                                    <div className={'switch-controller--table--account-name' + (switchType === SYMBOL_SWITCH_TYPES.BUY_SELL_TO_OPEN_CLOSE ? ' has-open-close' : '')}>{accountName}</div>
                                )
                            }} />
                        <Column 
                            dataKey={'symbolName'}
                            width={200}
                            headerRenderer={() => (
                                <Fragment>
                                    <span className={'sortable' + (sortBy === this.sortBys.SYMBOL ? ' sorted' : '')}
                                        onClick={() => { this.setState({ sortBy: this.sortBys.SYMBOL }) }}>{'Symbol'}</span>
                                    <div className={`switch-controller--symbol-type-select ${symbolType}`}>
                                        <SearchSelect
                                            hideSearchBar
                                            options={_.map(this.symbolTypes, (name, value) => {
                                                return {
                                                    name,
                                                    value
                                                }
                                            })} 
                                            value={symbolType} 
                                            onChange={(newOption) => {
                                                this.setState({ symbolType: newOption.value })
                                                this.scrollToTop()
                                            }} />
                                    </div>
                                    <input value={symbolSearchString} 
                                        placeholder={'Search'}
                                        spellCheck={false}
                                        onChange={(e) => { this.updateSymbolSearchString(e.target.value) }} />
                                </Fragment>
                            )} 
                            cellRenderer={({ rowData }) => {
                                const { symbolName, accountName } = rowData
                                const switchType = getSymbolSwitchType(symbolName, accountName)
                                return (
                                    <div className={'switch-controller--table--symbol-name' + (switchType === SYMBOL_SWITCH_TYPES.BUY_SELL_TO_OPEN_CLOSE ? ' has-open-close' : '')}>{symbolName}</div>
                                )
                            }} />
                        <Column
                            dataKey={'profileIds'}
                            width={100}
                            flexGrow={1}
                            headerRenderer={() => (
                                <Fragment>
                                    <span>{'Profiles'}</span>
                                    <input value={profileSearchString}
                                        placeholder={'Search'}
                                        spellCheck={false}
                                        onChange={(e) => { this.updateProfileSearchString(e.target.value) }} />
                                </Fragment>
                            )}
                            cellRenderer={({ rowData, parent, rowIndex }) => {
                                const { accountName, symbolName, profileIds } = rowData
                                return (
                                    <CellMeasurer
                                        cache={this.cellMeasurerCache}
                                        columnIndex={0}
                                        parent={parent}
                                        rowIndex={rowIndex}>
                                        <div className='switch-controller--table--profiles'>
                                            {_.map(profileIds, profileId => {
                                                const profileItem = profileItems[profileId]
                                                return (
                                                    <Fragment key={profileId}>
                                                        <div className='switch-controller--table--profile'>
                                                            <div className='switch-controller--table--profile--name'>{profileItem.name}</div>
                                                            <div className='switch-controller--table--profile--sides'>
                                                                {this.ProfileSideSwitch({
                                                                    profileItem,
                                                                    accountName,
                                                                    symbolName,
                                                                    direction: 'BUY'
                                                                })}
                                                                {this.ProfileSideSwitch({
                                                                    profileItem,
                                                                    accountName,
                                                                    symbolName,
                                                                    direction: 'SELL'
                                                                })}
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                )
                                            })}
                                        </div>
                                    </CellMeasurer>
                                )
                            }} />
                        <Column
                            dataKey={'batchOperation'}
                            width={230}
                            headerRenderer={() => actionType === 'REDUCE_ONLY' ? this.ReduceOnlyBatchOperationPopup() : this.BatchOperationPopup()}
                            cellRenderer={({ rowData }) => {
                                const { accountName, symbolName, profileIds } = rowData
                                const switchType = getSymbolSwitchType(symbolName, accountName)
                                return (
                                    <div className='switch-controller--table--batch-operation'>
                                        {actionType === 'REDUCE_ONLY'
                                        ? <Fragment>
                                            {this.BatchSwitch({
                                                type: 'ON',
                                                hasBuySellType: true,
                                                hasBuySellToOpenCloseType: false,
                                                onClickButton: (side) => { 
                                                    this.handleClickBatchSwitchButton({
                                                        profileIds,
                                                        accountName,
                                                        symbolName,
                                                        type: 'ON',
                                                        side,
                                                        actionType
                                                    }) 
                                                }
                                            })}
                                            {this.BatchSwitch({
                                                type: 'OFF',
                                                hasBuySellType: true,
                                                hasBuySellToOpenCloseType: false,
                                                onClickButton: (side) => {
                                                    this.handleClickBatchSwitchButton({
                                                        profileIds,
                                                        accountName,
                                                        symbolName,
                                                        type: 'OFF',
                                                        side,
                                                        actionType
                                                    })
                                                }
                                            })}
                                        </Fragment>
                                        : <Fragment>
                                            {this.BatchSwitch({
                                                type: 'ON',
                                                hasBuySellType: switchType === SYMBOL_SWITCH_TYPES.BUY_SELL,
                                                hasBuySellToOpenCloseType: switchType === SYMBOL_SWITCH_TYPES.BUY_SELL_TO_OPEN_CLOSE,
                                                onClickButton: (side) => { 
                                                    this.handleClickBatchSwitchButton({
                                                        profileIds,
                                                        accountName,
                                                        symbolName,
                                                        type: 'ON',
                                                        side,
                                                        actionType
                                                    }) 
                                                }
                                            })}
                                            {this.BatchSwitch({
                                                type: 'OFF',
                                                hasBuySellType: switchType === SYMBOL_SWITCH_TYPES.BUY_SELL,
                                                hasBuySellToOpenCloseType: switchType === SYMBOL_SWITCH_TYPES.BUY_SELL_TO_OPEN_CLOSE,
                                                onClickButton: (side) => {
                                                    this.handleClickBatchSwitchButton({
                                                        profileIds,
                                                        accountName,
                                                        symbolName,
                                                        type: 'OFF',
                                                        side,
                                                        actionType
                                                    })
                                                }
                                            })}
                                        </Fragment>}
                                    </div>
                                )
                            }} />
                    </Table>
                )}
            </AutoSizer>
        )
    }

    render () {
        const { subTitle, onClickClose } = this.props
        const { actionType } = this.state
        return (
            <Modal
                overlayClassName='switch-controller--modal-overlay modal-overlay'
                className='switch-controller'
                isOpen>
                <div className='switch-controller--body horizontal-centered'>
                    <div className='switch-controller--header clearfix'>
                        <div className='switch-controller--header--title'>{'Switch Controller' + (subTitle ? `: ${subTitle}` : '')}</div>
                        <div className='switch-controller--header--action-types'>
                            {_.map(ACTION_TYPES, (name, key) => {
                                return (
                                    <button className={'switch-controller--header--action-type' + (actionType === key ? ' selected' : '')} 
                                        key={key}
                                        onClick={() => {
                                            updateSessionStorageActionType(key)
                                            this.setState({ actionType: getSessionStorageActionType() })
                                            this.scrollToTop() 
                                        }}>{name}</button>
                                )
                            })}
                        </div>
                        <button className='switch-controller--header--close-button' onClick={() => { onClickClose() }}><FiX /></button>
                    </div>
                    <div className='switch-controller--main'>
                        {this.Table()}
                    </div>
                </div>
            </Modal>
        )
    }
}

SwitchController.propTypes = {
    dispatch: PropTypes.func.isRequired,
    subTitle: PropTypes.string,
    profileItems: PropTypes.object.isRequired,
    profileIdsToPick: PropTypes.array,
    onClickClose: PropTypes.func.isRequired
}

SwitchController.defaultProps = {
    profileIdsToPick: [],
    onClickClose: () => {}
}

function mapStateToProps (state) {
    return {
        profileItems: state.profile.items,
        runningState: state.profile.runningState
    }
}

export default connect(mapStateToProps)(SwitchController)