import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Popup from '../common/popup/Popup'
import PositionItem from '../trading/PositionItem'
import AccountBalanceItem from '../account/AccountBalanceItem'
import { updateProfileSearchString } from '../profile/profileAction'
import { getProfileExchangeNames } from '../../util/profileUtil'

class AccountPopup extends Component {
    constructor (props) {
        super(props)
        this.tabs = {
            balance: {
                name: 'Balances'
            },
            position: {
                name: 'Positions'
            },
            profile: {
                name: 'Profiles'
            }
        }
        this.state = {
            activeTab: Object.keys(this.tabs)[0],
            showDetails: false
        }
        this.body = null
    }

    renderPositions () {
        const { showDetails } = this.state
        const { accountItem, positions } = this.props
        const filteredPositions = _.filter(positions, { account_name: accountItem.account_name })
        return (
            <div className='account-popup--positions'>
                {!_.isEmpty(filteredPositions) ? filteredPositions.map((position, index) => (
                    <div className='account-popup--positions--item' key={index}>
                        <PositionItem 
                            positionItem={position} 
                            hideDetails={!showDetails} />
                    </div>
                )) : <div className='account-popup--positions--empty-message'>{'Empty Position'}</div>}
            </div>
        )
    }

    renderBalance () {
        const { showDetails } = this.state
        const { accountItem } = this.props
        return (
            <div className='account-popup--balance'>
                <AccountBalanceItem 
                    accountName={accountItem.account_name} 
                    shouldShowDetails={showDetails}
                    emptyMessage={'No Balance Data'} />
            </div>
        )
    }

    renderProfiles () {
        const { dispatch, accountItem, profileItems } = this.props
        const filteredProfileItems = Object.values(profileItems).filter(profileItem => !_.isEmpty(profileItem.accounts) 
            && Object.values(_.pick(profileItem.accounts, getProfileExchangeNames(profileItem))).includes(accountItem.account_name))
        return (
            <div className='account-popup--profiles'>
                {!_.isEmpty(filteredProfileItems) ? filteredProfileItems.map(profileItem => {
                    return (
                        <div className='account-popup--profiles--item' key={profileItem.id} 
                            onClick={() => { dispatch(updateProfileSearchString(profileItem.name)) }}>
                            <span className='account-popup--profiles--item--name'>{profileItem.name}</span>
                            <span className='account-popup--profiles--item--hostname'>{profileItem.hostname}</span>
                        </div>
                    )
                }) : <div className='account-popup--profiles--empty-message'>{'No Related Profiles'}</div>}
            </div>
        )
    }

    render () {
        const { activeTab, showDetails } = this.state
        const { className, accountItem } = this.props
        return (
            <Popup className={'account-popup' + (className ? ` ${className}` : '')}
                on={'click'}
                trigger={<span className='account-popup--trigger-name'>{accountItem.account_name}</span>}>
                <div className='account-popup--main' onClick={(e) => { e.stopPropagation() }}>
                    <div className='account-popup--header clearfix'>
                        <div className='account-popup--name'>{accountItem.account_name}</div>
                        <span className='account-popup--host-list'>{'host: ' + accountItem.host_list}</span>
                        {['position', 'balance'].includes(activeTab) && <button className='account-popup--toggle-detail-button' onClick={() => { this.setState({ showDetails: !showDetails }) }}>{showDetails ? 'Hide Details' : 'Show Details'}</button>}
                    </div>
                    <div className='account-popup--tabs'>
                        {_.map(this.tabs, (tab, tabKey) => (
                            <button className={'account-popup--tab-button' + (tabKey === activeTab ? ' active' : '')} key={tabKey}
                                onClick={() => { 
                                    this.setState({ activeTab: tabKey }) 
                                    if (this.body) {
                                        this.body.scrollTop = 0
                                    }
                                }}>{tab.name}</button>  
                        ))}
                    </div>
                    <div className='account-popup--body' ref={(node) => { this.body = node }}>
                        {activeTab === 'balance' && this.renderBalance()}
                        {activeTab === 'position' && this.renderPositions()}
                        {activeTab === 'profile' && this.renderProfiles()}
                    </div>
                </div>
            </Popup>
        )
    }
}

AccountPopup.propTypes = {
    dispatch: PropTypes.func.isRequired,
    className: PropTypes.string,
    accountItem: PropTypes.object.isRequired,
    positions: PropTypes.array.isRequired,
    profileItems: PropTypes.object.isRequired
}

function mapStateToProps (state) {
    return {
        positions: state.trading.positions,
        profileItems: state.profile.items
    }
}

export default connect(mapStateToProps)(AccountPopup)
