import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FaCheck} from 'react-icons/fa'

export default class SaveButton extends Component {
    render () {
        const {className, isSaving, isSaved, text, isSavingText, isSavedText, hideSavedIcon, disabled, onClick} = this.props
        return (
            <button className={'save-button' + (isSaving ? ' is-saving' : isSaved ? ' is-saved' : '') + (className ? ` ${className}` : '')} 
                disabled={isSaving || isSaved || disabled}
                onClick={() => { onClick() }}>
                {isSaved && !hideSavedIcon && <FaCheck />}
                {isSaving ? isSavingText : isSaved ? isSavedText : text}
            </button>
        )
    } 
}

SaveButton.propTypes = {
    className: PropTypes.string,
    isSaving: PropTypes.bool.isRequired,
    isSaved: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,
    isSavingText: PropTypes.string.isRequired,
    isSavedText: PropTypes.string.isRequired,
    hideSavedIcon: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired
}

SaveButton.defaultProps = {
    isSaving: false,
    isSaved: false,
    text: 'Save',
    isSavingText: 'Saving...',
    isSavedText: 'Saved',
    hideSavedIcon: false,
    disabled: false,
    onClick: () => {}
}