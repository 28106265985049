import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import BigNumber from 'bignumber.js'
import moment from 'moment'
import _ from 'lodash'

import { AutoSizer, Table, Column } from 'react-virtualized'
import SearchSelect from '../common/searchSelect/SearchSelect'

import { isMetSearchStringCriteria, toNumberWithSmartPrecision } from '../../util/util'
import { fetchAccountRiskInfo } from './accountAction'
import { getPortfolioNames } from '../../util/accountUtil'
import { FiChevronsDown, FiChevronsUp } from 'react-icons/fi'

let componentSize = 0
let polling = null

const GROUP_BYS = {
    CROSS_MARGIN_GROUP: 'Cross Margin Group',
    ACCOUNT_NAME: 'Account',
    MARGIN_MARK_TOKEN: 'Token'
}

const SORT_ORDERS = {
    ASC: 'ASC',
    DESC: 'DESC'
}

const TABLE_ROW_TYPES = {
    GROUP: 'GROUP',
    ITEM: 'ITEM'
}

const TableRow = ({ type='', data={} }) => {
    return {
        type,
        data
    }
}

const ALL = 'ALL'

const cachedWorkspaceComponentStates = {}

class AccountRiskInfo extends Component {
    constructor (props) {
        super(props)
        this.state = _.get(cachedWorkspaceComponentStates, props.workspaceComponentId, {
            searchString: '',
            groupBy: _.keys(GROUP_BYS)[0],
            portfolio: ALL,
            sortBy: null,
            sortOrder: SORT_ORDERS.ASC,
            groupNamesShouldShowItems: []
        })

        this.isScrollingTable = null
        this.tableWrapperNode = null
        this.tableNode = null
    }

    componentDidMount () {
        componentSize++
        if (_.isNil(polling)) {
            this._getData()
            polling = setInterval(() => {
                this._getData()
            }, 30000)
        }
    }

    componentDidUpdate () {
        if (this.tableNode) {
            this.tableNode.recomputeRowHeights()
        }
    }

    componentWillUnmount () {
        const { workspaceComponentId } = this.props
        componentSize--
        if (componentSize === 0 && polling) {
            window.clearInterval(polling)
            polling = null
        }
        if (!_.isEmpty(workspaceComponentId)) {
            cachedWorkspaceComponentStates[workspaceComponentId] = _.cloneDeep(this.state)
        }
    }

    _removeInnerScrollContainerPointerEvents () {
        if (this.tableWrapperNode && this.tableWrapperNode.ownerDocument) {
            const containers = this.tableWrapperNode.ownerDocument.getElementsByClassName('ReactVirtualized__Grid__innerScrollContainer')
            if (!_.isEmpty(containers)) {
                containers[0].style.pointerEvents = null
            }
        }
    }

    handleScrollTableMain () {
        if (window.document.hidden) {
            if (this.isScrollingTable) {
                window.clearTimeout(this.isScrollingTable)
            }
            this.isScrollingTable = setTimeout(() => {
                this._removeInnerScrollContainerPointerEvents()
            }, 66)
        }
    }

    _getData () {
        const { dispatch } = this.props
        dispatch(fetchAccountRiskInfo())
    }

    _getTableRows () {
        const { accountRiskInfo, accountItems } = this.props
        const { groupBy, portfolio, searchString, groupNamesShouldShowItems, sortBy, sortOrder } = this.state

        const filteredAccountRiskInfo = _.filter(accountRiskInfo, riskInfoItem => {
            const { account_name: accountName, cross_margin_group: crossMarginGroup, margin_mark_token: marginMarkToken } = riskInfoItem
            return (portfolio === ALL || _.get(accountItems, `${accountName}.portfolio_name`) === portfolio)
                && (_.isEmpty(searchString) || isMetSearchStringCriteria(`${accountName} ${crossMarginGroup} ${marginMarkToken}`, searchString))
        })

        const groups = _.groupBy(filteredAccountRiskInfo, groupBy === 'ACCOUNT_NAME' ? 'account_name'
            : groupBy === 'MARGIN_MARK_TOKEN' ? 'margin_mark_token'
            : 'cross_margin_group')

        const tableRows = []
        _.forEach(groups, (groupItems, groupName) => {
            tableRows.push(TableRow({
                type: TABLE_ROW_TYPES.GROUP,
                data: {
                    name: groupName
                }
            }))
            if (groupNamesShouldShowItems.includes(groupName)) {
                const sortedGroupItems = !_.isEmpty(sortBy) ? _.sortBy(groupItems, sortBy) : groupItems
                if (!_.isEmpty(sortBy) && sortOrder === SORT_ORDERS.DESC) {
                    _.reverse(sortedGroupItems)
                }
                _.forEach(sortedGroupItems, item => {
                    tableRows.push(TableRow({
                        type: TABLE_ROW_TYPES.ITEM,
                        data: item
                    }))
                })
            }
        })
        return tableRows
    }

    Header () {
        const { groupBy, portfolio, searchString, groupNamesShouldShowItems } = this.state
        const portfolioNames = getPortfolioNames()
        const portfolioOptions = _.concat([{ value: ALL, name: 'All' }], _.map(portfolioNames, name => {
            return {
                value: name,
                name
            }
        })) 
        const groupByOptions = _.map(GROUP_BYS, (value, key) => {
            return {
                value: key,
                name: value
            }
        })

        return (
            <div className='account-risk-info--header'>
                <div className='account-risk-info--header--selector'>
                    <label>{'Portofolio'}</label>
                    <SearchSelect 
                        hideSearchBar
                        value={portfolio}
                        options={portfolioOptions}
                        onChange={(newOption) => { this.setState({ portfolio: newOption.value }) }} />
                </div>
                <div className='account-risk-info--header--selector'>
                    <label>{'Group By'}</label>
                    <SearchSelect 
                        hideSearchBar
                        value={groupBy}
                        options={groupByOptions}
                        onChange={(newOption) => { this.setState({ groupBy: newOption.value }) }} />
                </div>
                <button className='account-risk-info--header--toggle-group-button' onClick={() => {
                    if (!_.isEmpty(groupNamesShouldShowItems)) {
                        this.setState({ groupNamesShouldShowItems: [] })
                    } else {
                        const tableRows = this._getTableRows()
                        const allGroupNames = _.filter(tableRows, { type: TABLE_ROW_TYPES.GROUP }).map(tableRow => tableRow.data.name)
                        this.setState({ groupNamesShouldShowItems: allGroupNames })
                    }
                }}>
                    {!_.isEmpty(groupNamesShouldShowItems) ? 'Collapse All' : 'Expand All'}
                    {!_.isEmpty(groupNamesShouldShowItems) ? <FiChevronsUp /> : <FiChevronsDown />}
                </button>
                <button className='account-risk-info--header--reset-button' onClick={() => {
                    this.setState({
                        groupBy: _.keys(GROUP_BYS)[0],
                        portfolio: ALL,
                        searchString: '',
                        groupNamesShouldShowItems: []
                    })
                }}>{'RESET'}</button>
                <input className='account-risk-info--header--search-input'
                    type={'text'}
                    spellCheck={false}
                    placeholder={'Search Account, Margin Group, Token'} 
                    value={searchString}
                    onChange={(e) => { this.setState({ searchString: e.target.value }) }} />
            </div>
        )
    }

    GroupRow (groupData={}) {
        const { name } = groupData
        return (
            <div className='account-risk-info--group-row'>
                {name}
            </div>
        )
    }

    renderNumber (number) {
        return _.isNil(number) ? 'N/A'
            : Math.abs(number || 0) >= 1000 ? BigNumber(number).toFormat(0)
            : toNumberWithSmartPrecision({ number })
    }

    render () {
        const { groupNamesShouldShowItems, sortBy, sortOrder } = this.state
        const tableRows = this._getTableRows()
        
        return (
            <div className='account-risk-info'>
                {this.Header()}
                <div className='account-risk-info--table-wrapper' ref={(node) => { this.tableWrapperNode = node }}>
                    <AutoSizer>
                        {({ width, height }) => (
                            <Table
                                ref={(node) => { this.tableNode = node }}
                                className='account-risk-info--table'
                                headerClassName={'account-risk-info--table--header'}
                                headerHeight={52}
                                width={Math.max(width, 1400)}
                                height={height}
                                rowCount={_.size(tableRows)}
                                rowGetter={({ index }) => tableRows[index]}
                                rowClassName={({ index }) => { 
                                    let className = 'account-risk-info--table--row'
                                    const tableRow = tableRows[index]
                                    if (tableRow) {
                                        className += (tableRow.type === TABLE_ROW_TYPES.GROUP ? ' group-row' : ' item-row')
                                        className += (index % 2 === 1 ? ' odd-row' : ' even-row')
                                    } 
                                    return className
                                }}
                                rowHeight={({ index }) => { 
                                    const tableRow = tableRows[index]
                                    return tableRow.type === TABLE_ROW_TYPES.GROUP ? 50 : 37
                                }}
                                overscanRowCount={5}
                                noRowsRenderer={() => (<div className='account-risk-info--table--no-content'>{'No matched records.'}</div> )}
                                sort={({ sortBy: newTableSortBy }) => {
                                    this.setState({
                                        sortBy: newTableSortBy,
                                        sortOrder: newTableSortBy === sortBy ? (sortOrder === SORT_ORDERS.ASC ? SORT_ORDERS.DESC : SORT_ORDERS.ASC) : sortOrder
                                    })
                                }}
                                onScroll={() => { this.handleScrollTableMain() }}
                                onRowClick={({ rowData }) => {
                                    if (rowData.type === TABLE_ROW_TYPES.GROUP) {
                                        const { name } = rowData.data
                                        const newGroupsNamesShouldShowItems = groupNamesShouldShowItems.includes(name)
                                            ? _.without(groupNamesShouldShowItems, name)
                                            : _.concat(groupNamesShouldShowItems, name)
                                        this.setState({ groupNamesShouldShowItems: newGroupsNamesShouldShowItems })
                                    }
                                }}>
                                <Column dataKey={'account_name'}
                                    label={'ACCOUNT'}
                                    headerClassName={'sortable' + (sortBy === 'account_name' ? ' sorted' : '')}
                                    width={140}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.GROUP
                                            ? this.GroupRow(data)
                                            : data.account_name
                                    }} />
                                <Column dataKey={'cross_margin_group'}
                                    label={'CROSS MGN GROUP'}
                                    headerClassName={'sortable' + (sortBy === 'cross_margin_group' ? ' sorted' : '')}
                                    width={200}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.ITEM
                                            ? data.cross_margin_group
                                            : null
                                    }} />
                                <Column dataKey={'margin_mark_token'}
                                    label={'MGN MARK TOKEN'}
                                    headerClassName={'sortable' + (sortBy === 'margin_mark_token' ? ' sorted' : '')}
                                    width={50}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.ITEM
                                            ? data.margin_mark_token
                                            : null
                                    }} />
                                <Column dataKey={'pos_notional_sum_usdt'}
                                    label={'POS NOTIONAL SUM (USDT)'}
                                    headerClassName={'sortable' + (sortBy === 'pos_notional_sum_usdt' ? ' sorted' : '')}
                                    width={80}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.ITEM
                                            ? <span className={data.pos_notional_sum_usdt > 0 ? 'positive' 
                                                : data.pos_notional_sum_usdt < 0 ? 'negative' 
                                                : null}>{this.renderNumber(data.pos_notional_sum_usdt)}</span>
                                            : null
                                    }} />
                                <Column dataKey={'abs_pos_notional_sum'}
                                    label={'ABS POS NOTIONAL SUM'}
                                    headerClassName={'sortable' + (sortBy === 'abs_pos_notional_sum' ? ' sorted' : '')}
                                    width={80}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.ITEM
                                            ? this.renderNumber(data.abs_pos_notional_sum)
                                            : null
                                    }} />
                                <Column dataKey={'var_usdt'}
                                    label={'VaR (USDT)'}
                                    headerClassName={'sortable' + (sortBy === 'var_usdt' ? ' sorted' : '')}
                                    width={80}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.ITEM
                                            ? this.renderNumber(data.var_usdt)
                                            : null
                                    }} />
                                <Column dataKey={'available_notional'}
                                    label={'AVAIL. NOTIONAL'}
                                    headerClassName={'sortable' + (sortBy === 'available_notional' ? ' sorted' : '')}
                                    width={80}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.ITEM
                                            ? this.renderNumber(data.available_notional)
                                            : null
                                    }} />
                                <Column dataKey={'effective_margin_notional'}
                                    label={'EFF MARGIN NOTIONAL'}
                                    headerClassName={'sortable' + (sortBy === 'effective_margin_notional' ? ' sorted' : '')}
                                    width={80}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.ITEM
                                            ? this.renderNumber(data.effective_margin_notional)
                                            : null
                                    }} />
                                <Column dataKey={'margin_buffer_to_liq_notional'}
                                    label={'MGN BUF TO LIQ. NOTIONAL'}
                                    headerClassName={'sortable' + (sortBy === 'margin_buffer_to_liq_notional' ? ' sorted' : '')}
                                    width={80}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.ITEM
                                            ? this.renderNumber(data.margin_buffer_to_liq_notional)
                                            : null
                                    }} />
                                <Column dataKey={'avail_ratio_sum'}
                                    label={'AVAIL. RATIO SUM'}
                                    headerClassName={'sortable' + (sortBy === 'avail_ratio_sum' ? ' sorted' : '')}
                                    width={50}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.ITEM
                                            ? this.renderNumber(data.avail_ratio_sum)
                                            : null
                                    }} />
                                <Column dataKey={'buffer_ratio_sum'}
                                    label={'BUF RATIO SUM'}
                                    headerClassName={'sortable' + (sortBy === 'buffer_ratio_sum' ? ' sorted' : '')}
                                    width={50}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.ITEM
                                            ? this.renderNumber(data.buffer_ratio_sum)
                                            : null
                                    }} />
                                <Column dataKey={'buffer_ratio_abs'}
                                    label={'BUF RATIO ABS'}
                                    headerClassName={'sortable' + (sortBy === 'buffer_ratio_abs' ? ' sorted' : '')}
                                    width={50}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.ITEM
                                            ? this.renderNumber(data.buffer_ratio_abs)
                                            : null
                                    }} />
                                <Column dataKey={'margin_ratio_sum'}
                                    label={'MGN RATIO SUM'}
                                    headerClassName={'sortable' + (sortBy === 'margin_ratio_sum' ? ' sorted' : '')}
                                    width={50}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.ITEM
                                            ? this.renderNumber(data.margin_ratio_sum)
                                            : null
                                    }} />

                                <Column dataKey={'margin_ratio_abs'}
                                    label={'MGN RATIO ABS'}
                                    headerClassName={'sortable' + (sortBy === 'margin_ratio_abs' ? ' sorted' : '')}
                                    width={50}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.ITEM
                                            ? this.renderNumber(data.margin_ratio_abs)
                                            : null
                                    }} />
                                <Column dataKey={'var_last_updated'}
                                    label={'VAR UPDATED'}
                                    headerClassName={'sortable' + (sortBy === 'var_last_updated' ? ' sorted' : '')}
                                    width={80}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.ITEM && !_.isNil(data.var_last_updated)
                                            ? moment(data.var_last_updated).format('HH:mm:ss')
                                            : null
                                    }} />
                                <Column dataKey={'avail_last_updated'}
                                    label={'AVAIL UPDATED'}
                                    headerClassName={'sortable' + (sortBy === 'avail_last_updated' ? ' sorted' : '')}
                                    width={80}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.ITEM && !_.isNil(data.avail_last_updated)
                                            ? moment(data.avail_last_updated).format('HH:mm:ss')
                                            : null
                                    }} />
                                <Column dataKey={'margin_last_updated'}
                                    label={'MAGN UPDATED'}
                                    headerClassName={'sortable' + (sortBy === 'margin_last_updated' ? ' sorted' : '')}
                                    width={80}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.ITEM && !_.isNil(data.margin_last_updated)
                                            ? moment(data.margin_last_updated).format('HH:mm:ss')
                                            : null
                                    }} />
                            </Table>            
                        )}
                    </AutoSizer>
                </div>
            </div>
        )
    }
}

AccountRiskInfo.propTypes = {
    dispatch: PropTypes.func.isRequired,
    accountRiskInfo: PropTypes.array.isRequired,
    accountItems: PropTypes.object.isRequired,

    workspaceComponentId: PropTypes.string
}

function mapStateToProps (state) {
    return {
        accountRiskInfo: state.account.riskInfo,
        accountItems: state.account.items
    }
}

export default connect(mapStateToProps)(AccountRiskInfo)