import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'

import { MdGridOff } from 'react-icons/md'
import { FiPlus, FiChevronsUp, FiChevronsDown, FiRefreshCw } from 'react-icons/fi'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { arrayMoveImmutable } from 'array-move'

import SearchSelect from '../common/searchSelect/SearchSelect'
import ProfileGroupEditor from './ProfileGroupEditor'
import ProfileEditor from './ProfileEditor'
import ProfileBulkUploadEditor from './ProfileBulkUploadEditor'
import ProfileList from './ProfileList'
import SwitchController from '../trading/SwitchController'
import ProfileAccountsManager from './ProfileAccountsManager'
import HostnameProfileState from './HostnameProfileState'
import ProfileSymbolsToBeHandled from './ProfileSymbolsToBeHandled'

import { updateProfileGroupItem, updateProfileGroupIds, updateProfileGroupFocusedId, saveProfileGroups, updateProfileSearchString } from './profileAction'
import { getProfileSymbolNames, getProfileTradingAccountNamesBySymbol, getProfileTradingAccountPortfolios, profileHasNilSmartPosAccount, profileHasQuantityCappedSymbol } from '../../util/profileUtil'
import { getPositionLiquidationRatio, getMarginTradingLiquidationRatio } from '../../util/tradingUtil'
import { INSTRUMENT_TYPES, getSymbolAttributeByName, getTokenPriceInUSD } from '../../util/symbolUtil'
import { isMetSearchStringCriteria } from '../../util/util'
import { SERVERS } from '../../configs/config'
import { getPortfolioNames } from '../../util/accountUtil'

const ALL = 'ALL'

const SortableGroupItem = SortableElement(({groupItem, isFocused, onClickItem}) => {
    return (
        <div className={'profile-container--group-tab' + (isFocused ? ` is-focused` : '')} 
            onClick={() => { onClickItem(groupItem.id) }}><span>{groupItem.name}</span></div>
    )
})

const SortableGroupList = SortableContainer(({groupItems, profileGroupId, onClickItem, onClickAddButton}) => {
    return (
        <div className='profile-container--sortable-group-tabs'>
            {groupItems.map((groupItem, index) => {
                return groupItem ? (
                    <SortableGroupItem
                        key={groupItem.id}
                        index={index + 1}
                        groupItem={groupItem} 
                        isFocused={profileGroupId === groupItem.id} 
                        onClickItem={(groupId) => { onClickItem(groupId) }} />
                ) : null
            })}
            <button className='profile-container--add-group-button' title={'New Group'}
                onClick={() => { onClickAddButton() }}>
                <FiPlus />
            </button>
        </div>
    )
})

class ProfileContainer extends Component {
    
    constructor (props) {
        super(props)
        this.statusFilters = [{
            value: ALL,
            name: ALL
        }, {
            value: 'STARTED',
            name: 'Started Only'
        }, {
            value: 'RESUMED',
            name: 'Started & Resumed'
        }, {
            value: 'PAUSED',
            name: 'Started & Paused'
        }, {
            value: 'STOPPED',
            name: 'Stopped Only'
        }, {
            value: 'SWITCH_OFF_ALL',
            name: 'Switched Off | Qty Capped'
        }, {
            value: 'SWITCH_OFF_PASSIVE',
            name: 'Switched Off | Qty Capped\n(Exclude NAC Switch-Off)'
        }, {
            value: 'HAS_NIL_SMART_POS_ACCOUNT',
            name: 'Has Nil Smart Pos Account'
        }]

        this.sortBys = [{
            value: 'CUSTOMIZED',
            name: 'Customized'
        }, {
            value: 'SPREAD',
            name: 'Spread'
        }, {
            value: 'LIQUIDATION',
            name: 'Liquidation (Abs)'
        }, {
            value: 'NET_POSITION',
            name: 'Net Position (Abs)'
        }, {
            value: 'FUNDING_RATE',
            name: 'Funding Rate'
        }, {
            value: 'EXPOSURE',
            name: 'Exposure (Abs) in Asset'
        }, {
            value: 'EXPOSURE_IN_USD',
            name: 'Exposure (Abs) in USD'
        }, {
            value: 'PROFILE_PAUSE_TIME',
            name: 'Pause Time'
        }]

        this.sortOrders = [{
            value: 'ASC',
            name: 'ASC'
        }, {
            value: 'DESC',
            name: 'DESC'
        }]

        const sortBy = this._getSortBy()
        const sortOrder = this._getSortOrder()
        const portfolioFilter = this._getPortlioFilter()
        const statusFilter = this._getStatusFilter()
        this.state = {
            portfolioFilter,
            statusFilter,
            sortBy,
            sortOrder,
            seivedProfileIds: this.getSeivedProfileIds({
                profileGroupId: props.profile.group.focusedId,
                portfolioFilter,
                statusFilter,
                sortBy,
                sortOrder
            }),
            lastSeivingProfilesTimestamp: moment().valueOf(),
            showNewProfile: false,
            showNewProfileGroup: false,
            editingProfileGroupId: null,
            bulkUploadEnabled: false,
            expandedProfiles: {},
            profileListScrollToTopVersion: null,
            shouldShowSwitchController: false,
            shouldShowProfileAccountsManager: false
        }
        
        this.defaultNewProfileGroup = undefined
    }

    componentDidUpdate (prevProps) {
        const { portfolioFilter, statusFilter, sortBy, sortOrder } = this.state
        const { profile } = this.props
        const isProfileItemsLengthChanged = !_.isEqual(Object.keys(prevProps.profile.items).length, Object.keys(profile.items).length)
        const groupProfileIdsChanged = !_.isEqual(prevProps.profile.group.items[prevProps.profile.group.focusedId], profile.group.items[profile.group.focusedId])
        if (isProfileItemsLengthChanged || groupProfileIdsChanged) {
            // Re-seive the profile when new profile items are received
            const newSeivedProfileIds = this.getSeivedProfileIds({
                profileGroupId: profile.group.focusedId,
                portfolioFilter,
                statusFilter,
                sortBy,
                sortOrder
            })
            this.updateSeivedProfileIds(newSeivedProfileIds)
        }
    }

    updateSeivedProfileIds (newSeivedProfileIds) {
        this.setState({
            seivedProfileIds: newSeivedProfileIds,
            lastSeivingProfilesTimestamp: moment().valueOf()
        })
    }

    _getPortlioFilter () {
        const sessionValue = sessionStorage.profileContainerPortfolioFilter
        const portolioNames = getPortfolioNames()
        return sessionValue && portolioNames.includes(sessionValue) ? sessionValue : ALL
    }

    _updatePortfolioFilter (newPortfolioFilter) {
        const { profile } = this.props
        const { statusFilter, sortBy, sortOrder } = this.state
        sessionStorage.profileContainerPortfolioFilter = newPortfolioFilter
        newPortfolioFilter = this._getPortlioFilter()
        const momentValue = moment().valueOf()
        const newSeivedProfileIds = this.getSeivedProfileIds({
            profileGroupId: profile.group.focusedId,
            portfolioFilter: newPortfolioFilter,
            statusFilter,
            sortBy,
            sortOrder
        })
        this.setState({ 
            profileListScrollToTopVersion: momentValue,
            portfolioFilter: newPortfolioFilter,
            seivedProfileIds: newSeivedProfileIds,
            lastSeivingProfilesTimestamp: momentValue
        })
    }

    _getStatusFilter () {
        const sessionValue = sessionStorage.profileContainerStatusFilter
        return sessionValue && this.statusFilters.map(f => f.value).includes(sessionValue)
            ? sessionValue
            : this.statusFilters[0].value
    }

    _updateStatusFilter (newStatusFilter) {
        const { profile } = this.props
        const { portfolioFilter, sortBy, sortOrder } = this.state
        if (this.statusFilters.map(f => f.value).includes(newStatusFilter)) {
            const momentValue = moment().valueOf()
            const newSeivedProfileIds = this.getSeivedProfileIds({
                profileGroupId: profile.group.focusedId,
                portfolioFilter,
                statusFilter: newStatusFilter,
                sortBy,
                sortOrder
            })
            sessionStorage.profileContainerStatusFilter = newStatusFilter
            this.setState({ 
                profileListScrollToTopVersion: momentValue,
                statusFilter: newStatusFilter,
                seivedProfileIds: newSeivedProfileIds,
                lastSeivingProfilesTimestamp: momentValue
            })
        }
    }

    _getSortBy () {
        const sessionValue = sessionStorage.profileContainerSortBy
        return sessionValue && this.sortBys.map(s => s.value).includes(sessionValue)
            ? sessionValue
            : this.sortBys[0].value
    }

    _updateSortBy (newSortBy, shouldUpdateProfileListScrolltoTopVersion) {
        const { portfolioFilter, statusFilter, sortOrder, profileListScrollToTopVersion } = this.state
        const { profile } = this.props
        if (this.sortBys.map(s => s.value).includes(newSortBy)) {
            const momentValue = moment().valueOf()
            const newSeivedProfileIds = this.getSeivedProfileIds({
                profileGroupId: profile.group.focusedId,
                portfolioFilter,
                statusFilter,
                sortBy: newSortBy,
                sortOrder
            })
            sessionStorage.profileContainerSortBy = newSortBy
            this.setState({ 
                profileListScrollToTopVersion: shouldUpdateProfileListScrolltoTopVersion ? momentValue : profileListScrollToTopVersion,
                sortBy: newSortBy,
                seivedProfileIds: newSeivedProfileIds,
                lastSeivingProfilesTimestamp: momentValue
            })
        }
    }

    _getSortOrder () {
        const sessionValue = sessionStorage.profileContainerSortOrder
        return sessionValue && this.sortOrders.map(s => s.value).includes(sessionValue)
            ? sessionValue
            : this.sortOrders[1].value
    }

    _updateSortOrder (newSortOrder) {
        const { portfolioFilter, statusFilter, sortBy } = this.state
        const { profile } = this.props
        if (this.sortOrders.map(s => s.value).includes(newSortOrder)) {
            const momentValue = moment().valueOf()
            const newSeivedProfileIds = this.getSeivedProfileIds({
                profileGroupId: profile.group.focusedId,
                portfolioFilter,
                statusFilter,
                sortBy,
                sortOrder: newSortOrder
            })
            sessionStorage.profileContainerSortOrder = newSortOrder
            this.setState({ 
                profileListScrollToTopVersion: momentValue,
                sortOrder: newSortOrder,
                seivedProfileIds: newSeivedProfileIds,
                lastSeivingProfilesTimestamp: momentValue
            })
        }
    }

    _isSwitchedOffProfile (profileItem, runningState, filterPassiveSwitchOff=false) {
        const symbolNames = getProfileSymbolNames(profileItem)
        return _.has(runningState, 'switchOffs') && _.some((runningState.switchOffs || []), switchOff => { 
            const { symbol: symbolName, account: accountName } = switchOff
            const symbolTradingAccountNames = getProfileTradingAccountNamesBySymbol(profileItem, symbolName)
            const unionSymbolTradingAccountNames = _.compact([...symbolTradingAccountNames.BUY, ...symbolTradingAccountNames.SELL])

            return symbolNames.includes(symbolName) 
                && unionSymbolTradingAccountNames.includes(accountName) 
                && (!filterPassiveSwitchOff || switchOff.reason !== 'NAC switched off')
        })
    }

    _isQuantityCappedProfile (profileItem) {
        const { profile, symbolItems, pricings } = this.props
        const profileRunningState = profile.runningState[profileItem.id]
        return profileHasQuantityCappedSymbol({
            profileItem,
            profileRunningState,
            symbolItems,
            symbolPricings: pricings
        })
    }

    getSeivedProfileIds ({ profileGroupId, portfolioFilter, statusFilter, sortBy, sortOrder }) {
        const { profile, positions, pricings, fundingRates, account } = this.props
        const profileGroup = profile.group.items[profileGroupId]
        const filteredProfileIds = _.has(profileGroup, 'profileIds')
            ? profileGroup.profileIds.filter(profileId => {
                const profileItem = profile.items[profileId]
                const profileRunningState = profile.runningState[profileId]
                const profilePortfolios = getProfileTradingAccountPortfolios(profileItem)

                return !_.isEmpty(profileItem) 
                    && !_.isEmpty(profileItem.legs) 
                    && (portfolioFilter === ALL || profilePortfolios.includes(portfolioFilter))
                    && (statusFilter === ALL
                        || (statusFilter === 'STARTED' && profileItem.started)
                        || (statusFilter === 'RESUMED' && profileItem.started && (profileItem.resumed || profileItem.isResuming || profileItem.isPausing))
                        || (statusFilter === 'PAUSED' && profileItem.started && (!profileItem.resumed || profileItem.isResuming || profileItem.isPausing))
                        || (statusFilter === 'STOPPED' && !profileItem.started)
                        || (statusFilter === 'SWITCH_OFF_ALL' && (this._isSwitchedOffProfile(profileItem, profileRunningState, false) || this._isQuantityCappedProfile(profileItem)))
                        || (statusFilter === 'SWITCH_OFF_PASSIVE' && (this._isSwitchedOffProfile(profileItem, profileRunningState, true) || this._isQuantityCappedProfile(profileItem)))
                        || (statusFilter === 'HAS_NIL_SMART_POS_ACCOUNT' && profileItem.started && profileHasNilSmartPosAccount(profileItem, profileRunningState))
                    )
            })
            : []
        const seivedProfileIds = sortBy && sortBy !== 'CUSTOMIZED'
            ? _.sortBy(filteredProfileIds, profileId => {
                const profileItem = profile.items[profileId]
                const profileRunningState = profile.runningState[profileId]
                const profileSymbolNames = getProfileSymbolNames(profileItem)
                if (sortBy === 'SPREAD') {
                    if (sortOrder === 'ASC') {
                        const minSpread = _.has(profileRunningState, 'strategyInfo.symbols') && _.isObject(profileRunningState.strategyInfo.symbols)
                            ? _.min(Object.values(profileRunningState.strategyInfo.symbols).map(symbol => _.isArray(symbol.spread) ? symbol.spread[1] : Infinity))
                            : Infinity
                        return minSpread
                    } else {
                        const maxSpread = _.has(profileRunningState, 'strategyInfo.symbols') && _.isObject(profileRunningState.strategyInfo.symbols)
                            ? _.max(Object.values(profileRunningState.strategyInfo.symbols).map(symbol => _.isArray(symbol.spread) ? symbol.spread[0] : -Infinity))
                            : -Infinity
                        return -maxSpread
                    }
                } else if (sortBy === 'FUNDING_RATE') {
                    if (sortOrder === 'ASC') {
                        const minFundingRate = _.min(profileSymbolNames.map(symbolName => _.has(fundingRates, `${symbolName}.current_funding_rate`) ? Number(fundingRates[symbolName].current_funding_rate) : Infinity))
                        return minFundingRate
                    } else {
                        const maxFundingRate = _.max(profileSymbolNames.map(symbolName => _.has(fundingRates, `${symbolName}.current_funding_rate`) ? Number(fundingRates[symbolName].current_funding_rate) : -Infinity))
                        return -maxFundingRate
                    }
                } else if (sortBy === 'LIQUIDATION') {
                    const minLiquidation = _.min(profileSymbolNames.map(symbolName => {
                        const symbolTradingAccountNames = getProfileTradingAccountNamesBySymbol(profileItem, symbolName)
                        const unionTradingAccountNames = _.uniq([...symbolTradingAccountNames.BUY, ...symbolTradingAccountNames.SELL])
                        const { instrumentType } = getSymbolAttributeByName(symbolName)
                        const pricingItem = pricings[symbolName]
                        let minRatio = Infinity
                        if ([INSTRUMENT_TYPES.FUTURE, INSTRUMENT_TYPES.SWAP].includes(instrumentType)) {
                            const ratios = unionTradingAccountNames.map(accountName => {
                                const positionItem = _.find(positions, { product_name: symbolName, account_name: accountName })
                                return getPositionLiquidationRatio(positionItem, pricingItem) || Infinity
                            })
                            minRatio = _.min(ratios)
                        } else if (instrumentType === INSTRUMENT_TYPES.SPOT) {
                            const symbolNameTokens = symbolName.split('_')
                            const ratios = unionTradingAccountNames.map(accountName => {
                                const marginAccountBalance = _.find(account.balance.margin, {
                                    pair: `${symbolNameTokens[0]}-${symbolNameTokens[1]}`,
                                    acct_name: accountName
                                })
                                return getMarginTradingLiquidationRatio(marginAccountBalance, pricingItem) || Infinity
                            })
                            minRatio = _.min(ratios)
                        }
                        return minRatio ? Math.abs(minRatio) : Infinity
                    }))
                    return minLiquidation
                } else if (sortBy === 'NET_POSITION') {
                    const symbolNetPositions = _.has(profileRunningState, 'position')
                        ? _.map(Object.values(profileRunningState.position || {}), symbolPositionData => {
                            const symbolNetPosition = _.reduce(symbolPositionData, (result, positionData) => {
                                const { long, open_long, short, open_short } = positionData
                                result += (Number(long) + Number(open_long) - Number(short) - Number(open_short))
                                return result
                            }, 0)
                            return symbolNetPosition
                        })
                        : 0
                    const maxNetPosition = _.max(symbolNetPositions)
                    return -maxNetPosition
                } else if (sortBy === 'EXPOSURE') {
                    const exposure = _.has(profileRunningState, 'strategyInfo.expo') ? Math.abs(profileRunningState.strategyInfo.expo) : -Infinity
                    return -exposure
                } else if (sortBy === 'EXPOSURE_IN_USD') {
                    const profileAsset = profileSymbolNames.length > 0 ? getSymbolAttributeByName(profileSymbolNames[0]).base : null
                    const assetPrice = getTokenPriceInUSD(profileAsset, pricings)
                    const exposure = _.has(profileRunningState, 'strategyInfo.expo') && Number(assetPrice) > 0
                        ? Math.abs(profileRunningState.strategyInfo.expo * assetPrice) 
                        : -Infinity
                    return -exposure
                } else if (sortBy === 'PROFILE_PAUSE_TIME') {
                    return !profileItem.resumed && _.has(profileItem, 'pauseTimestamp') && moment(profileItem.pauseTimestamp).isValid() 
                        ? -moment(profileItem.pauseTimestamp).valueOf()
                        : !profileItem.resumed ? moment(0)
                        : Infinity
                } else {
                    return profileId
                }
            })
            : filteredProfileIds

        return seivedProfileIds
    }

    handleClickGroupTab (groupId) {
        const { dispatch } = this.props
        dispatch(updateProfileGroupFocusedId(groupId))
        this.setState({
            profileListScrollToTopVersion: moment().valueOf()
        })
    }

    renderEmptyProfile () {
        return (
            <div className='profile-container--empty-wrapper'>
                <div className='profile-container--empty-icon'><MdGridOff /></div>
                <div className='profile-container--empty-title'>{'It Is Void Here'}</div>
                <div className='profile-container--empty-description'>{'There is no trading profiles.'}</div>
            </div>
        )
    }

    renderPortfolioFilter () {
        const { portfolioFilter } = this.state
        const portfolioNames = getPortfolioNames()
        const portfolioOptions = _.map(_.concat(ALL, portfolioNames), portfolio => {
            return {
                value: portfolio,
                name: portfolio
            }
        })
        return (
            <div className='profile-container--portfolio-filter'>
                <span>{'Portfolio'}</span>
                <SearchSelect 
                    value={portfolioFilter}
                    options={portfolioOptions} 
                    hideSearchBar
                    onChange={(newOption) => { this._updatePortfolioFilter(newOption.value) }} />
            </div>
        )
    }

    renderStatusFilter () {
        const { statusFilter } = this.state
        return (
            <div className='profile-container--status-filter'>
                <span>{'Status'}</span>
                <SearchSelect 
                    value={statusFilter}
                    options={this.statusFilters} 
                    hideSearchBar
                    onChange={(newOption) => { this._updateStatusFilter(newOption.value) }} />
            </div>
        )
    }

    renderSortBy () {
        const { sortBy } = this.state
        return (
            <div className='profile-container--sort-by-wrapper'>
                <span>{'Sort By'}</span>
                <SearchSelect
                    value={sortBy}
                    options={this.sortBys}
                    hideSearchBar
                    onChange={(newOption) => { this._updateSortBy(newOption.value, true) }} />
            </div>
        )
    }

    renderSortOrder () {
        const { sortOrder } = this.state
        return (
            <div className='profile-container--sort-order'>
                {this.sortOrders.map((item) => {
                    const { value, name } = item
                    return (
                        <button className={'profile-container--sort-order--button' + (sortOrder === value ? ' active' : '')} 
                            key={value}
                            onClick={() => { this._updateSortOrder(value) }}>{name}</button>
                    )
                })}
            </div>
        )
    }

    render () {
        const { showNewProfile, showNewProfileGroup, editingProfileGroupId, 
            bulkUploadEnabled, portfolioFilter, statusFilter, sortBy, sortOrder, seivedProfileIds, 
            lastSeivingProfilesTimestamp, expandedProfiles, profileListScrollToTopVersion,
            shouldShowSwitchController, shouldShowProfileAccountsManager } = this.state
        const { dispatch, profile } = this.props
        const { searchString } = profile

        const trimmedSearchString = searchString.trim().toLowerCase()
        const allProfileGroup = profile.group.items[profile.group.ids[0]]
        const focusedGroup = profile.group.items[profile.group.focusedId]
        const allProfileSize = _.filter(allProfileGroup.profileIds, profileId => _.has(profile.items, `${profileId}.legs`) && !_.isEmpty(profile.items[profileId].legs)).length 

        const filteredProfileIds = seivedProfileIds.filter((profileId) => {
            const profileItem = profile.items[profileId]
            const profileSymbolNames = getProfileSymbolNames(profileItem)
            const profileRefSymbolNames = _.has(profileItem, `legs.1.symbols`) ? _.reduce(profileItem.legs['1'].symbols, (result, profileSymbolItem) => {
                if (!_.isEmpty(profileSymbolItem.refSymbols)) {
                    result.push(...profileSymbolItem.refSymbols.map(refSymbolItem => refSymbolItem.name))
                }
                return result
            }, []) : []
            const profileQuoteLegFxSymbols = _.map(_.get(profileItem, `legs.1.symbols`) || [], _symbol => _.get(_symbol, 'params.LEG1_FX_PROD'))
            const profileAccountNames = _.uniq(_.reduce(profileSymbolNames, (result, symbolName) => {
                const symbolAccountNames = getProfileTradingAccountNamesBySymbol(profileItem, symbolName)
                result.push(...symbolAccountNames.BUY, ...symbolAccountNames.SELL)
                return result
            }, []))
            const profileManagers = _.has(profile, 'userProfiles.items') ? _.filter(profile.userProfiles.items, userProfileItem => {
                const { username, profileIds } = userProfileItem
                return !_.isEmpty(username) && (profileIds || []).includes(profileId)
            }).map(userProfileItem => userProfileItem.username) : []
            const _leg1Strategy = _.get(profileItem, `legs.1.strategy.type`) || ''
            const _leg2Strategy = _.get(profileItem, `legs.2.strategy.type`) || ''

            const targetString = `${profileId} ${profileItem.user} ${profileItem.hostname} ${profileSymbolNames.join(' ')} ${profileAccountNames.join(' ')} ${profileRefSymbolNames.join(' ')} ${profileQuoteLegFxSymbols.join(' ')} ${profileManagers.join(' ')} ${_.get(profileItem, `params.RUNNING_MODE`, '')} ${_leg1Strategy} ${_leg2Strategy}`
            return _.isEmpty(trimmedSearchString) || isMetSearchStringCriteria(targetString, trimmedSearchString)
        })
        const hasExpandedProfile = !_.isEmpty(filteredProfileIds) && _.some(filteredProfileIds, profileId => expandedProfiles[profileId])

        return (
            <div className='profile-container'>
                <div className='profile-container--groups clearfix'>
                    <div className={'profile-container--group-tab all-profile-group' + (focusedGroup.id === allProfileGroup.id ? ' is-focused' : '')}
                        onClick={() => { this.handleClickGroupTab(allProfileGroup.id) }}><span>{`All Profiles (${allProfileSize})`}</span></div>
                    <SortableGroupList 
                        axis={'x'}
                        pressDelay={200}
                        groupItems={_.tail(profile.group.ids).map(groupId => profile.group.items[groupId])}  
                        profileGroupId={focusedGroup.id}
                        onClickAddButton={() => { this.setState({ showNewProfileGroup: true }) }}
                        onClickItem={(groupId) => { this.handleClickGroupTab(groupId) }}
                        onSortEnd={({oldIndex, newIndex}) => {
                            dispatch(updateProfileGroupIds(arrayMoveImmutable(profile.group.ids, oldIndex, newIndex)))
                        }} />
                    <button className='profile-container--groups--save-button hidden'
                    onClick={() => { dispatch(saveProfileGroups()) }}>{'Save Profile Groups'}</button>
                </div>
                <div className='profile-container--main'>
                    <div className='profile-container--header'>
                        <div className='profile-container--header--row-1 clearfix'>
                            <input className='profile-container--header--search-input' placeholder='Search Profile, Symbol, Account' 
                                type={'text'}
                                spellCheck={false} 
                                value={searchString}
                                onChange={(e) => { 
                                    this.setState({ profileListScrollToTopVersion: moment().valueOf() })
                                    dispatch(updateProfileSearchString(e.target.value, false)) 
                                }} />
                            {focusedGroup.id === allProfileGroup.id && 
                            <button className='profile-container--header--new-profile-button hidden'
                                disabled
                                onClick={() => { this.setState({ showNewProfile: true }) }}>
                                {'New Profile'}
                            </button>}
                            {focusedGroup.editable && 
                            <button className='profile-container--header--edit-group-button'
                                onClick={() => { this.setState({ editingProfileGroupId: focusedGroup.id }) }}>
                                {'Manage Group'}
                            </button>}
                            {focusedGroup.id !== allProfileGroup.id && !_.isEmpty(filteredProfileIds) && 
                            <button className='profile-container--header--bulk-upload-button' 
                                onClick={() => { this.setState({ bulkUploadEnabled: true }) }}>
                                {'Bulk Upload'}
                            </button>}
                            {_.some(filteredProfileIds, profileId => profile.items[profileId].started) && <button className='profile-container--header--switch-control-button'
                                onClick={() => { this.setState({ shouldShowSwitchController: true }) }}>
                                {'Switch Control'}
                            </button>}
                            <button className='profile-container--header--accounts-manager-button'
                                onClick={() => { this.setState({ shouldShowProfileAccountsManager: true }) }}>
                                {'Acct. Manager'}
                            </button>
                            {(trimmedSearchString.length > 0 || portfolioFilter !== ALL || statusFilter !== ALL) && !_.isEmpty(filteredProfileIds) &&
                            <button className='profile-container--header--create-tmp-group-button'
                                onClick={() => {
                                    this.defaultNewProfileGroup = {
                                        id: null,
                                        name: 'Tmp Group',
                                        profileIds: filteredProfileIds,
                                        editable: true
                                    }
                                    this.setState({ showNewProfileGroup: true })
                                }}>
                                {`Create Group (${filteredProfileIds.length})`}
                            </button>}
                        </div>
                        <div className='profile-container--header--row-2 clearfix'>
                            {this.renderPortfolioFilter()}
                            {this.renderStatusFilter()}
                            {this.renderSortBy()}
                            {['SPREAD', 'FUNDING_RATE'].includes(sortBy) && this.renderSortOrder()}
                            {(portfolioFilter !== ALL || statusFilter !== ALL || sortBy !== 'CUSTOMIZED') && <div className='profile-container--snapshot'>
                                <span>{'SNAPSHOT @ '}</span>
                                {moment(lastSeivingProfilesTimestamp).format('HH:mm:ss')}
                                <button className='profile-container--snapshot--refresh-button' onClick={() => {
                                    const newSeivedProfileIds = this.getSeivedProfileIds({
                                        profileGroupId: profile.group.focusedId,
                                        portfolioFilter,
                                        statusFilter,
                                        sortBy,
                                        sortOrder
                                    })
                                    this.updateSeivedProfileIds(newSeivedProfileIds)
                                }}>{<FiRefreshCw />}</button>
                            </div>}
                            {!_.isEmpty(filteredProfileIds) && hasExpandedProfile && 
                            <button className='profile-container--header--collapse-all-button' 
                                onClick={() => { this.setState({ expandedProfiles: {} }) }}>
                                <span>{'Collapse All'}</span>
                                <FiChevronsUp />
                            </button>}
                            {!_.isEmpty(filteredProfileIds) && !hasExpandedProfile && 
                            <button className='profile-container--header--expand-all-button' 
                                onClick={() => { 
                                    this.setState({ 
                                        expandedProfiles: filteredProfileIds.reduce((result, profileId) => {
                                            result[profileId] = true
                                            return result
                                        }, {})
                                    }) 
                                }}> 
                                <span>{'Expand All'}</span>
                                <FiChevronsDown />
                            </button>}
                        </div>
                    </div>
                    <ProfileList 
                        profileItems={filteredProfileIds.map(profileId => profile.items[profileId])} 
                        expandedProfiles={expandedProfiles} 
                        scrollToTopVersion={profileListScrollToTopVersion}
                        onChangeExpandedProfiles={(newExpandedProfiles) => { this.setState({ expandedProfiles: newExpandedProfiles }) }} 
                        onSortEnd={({oldIndex, newIndex}) => {
                            dispatch(updateProfileGroupItem(focusedGroup.id, {
                                profileIds: _.union(arrayMoveImmutable(filteredProfileIds, oldIndex, newIndex), focusedGroup.profileIds)
                            }))
                            this._updateSortBy('CUSTOMIZED', false)
                        }}/>
                    {_.isEmpty(filteredProfileIds) && this.renderEmptyProfile()}
                </div> 

                <HostnameProfileState />
                {_.every(profile.hostnameProfileState, (profileStateItem, hostname) => profileStateItem.isFetched || (_.has(SERVERS, `${hostname}.profileDisabled`) && SERVERS[hostname].profileDisabled)) 
                && <ProfileSymbolsToBeHandled />}
                
                {showNewProfile && <ProfileEditor 
                    mode={'CREATE'} 
                    onClickClose={() => { this.setState({ showNewProfile: false }) }} 
                    onSaveSuccess={() => { this.setState({ showNewProfile: false }) }} />}
                {showNewProfileGroup && <ProfileGroupEditor 
                    mode={'CREATE'}
                    group={this.defaultNewProfileGroup}
                    onClickClose={() => { 
                        this.defaultNewProfileGroup = undefined
                        this.setState({ showNewProfileGroup: false }) 
                    }} 
                    onCreateSuccess={() => { 
                        this.setState({ 
                            showNewProfileGroup: false
                        }) 
                    }} />}
                {editingProfileGroupId && <ProfileGroupEditor 
                    mode={'EDIT'}
                    group={focusedGroup}
                    onClickClose={() => { this.setState({ editingProfileGroupId: null }) }} 
                    onDeleteSuccess={() => { this.setState({ editingProfileGroupId: null }) }}/>}
                {bulkUploadEnabled && <ProfileBulkUploadEditor 
                    profileItems={filteredProfileIds.map(profileId => profile.items[profileId])} 
                    onClickClose={() => {
                        this.setState({ bulkUploadEnabled: false })
                    }} />}
                {shouldShowSwitchController && <SwitchController 
                    profileIdsToPick={filteredProfileIds}
                    subTitle={_.has(focusedGroup, 'name') && focusedGroup.id !== allProfileGroup.id ? focusedGroup.name : null}
                    onClickClose={() => { this.setState({ shouldShowSwitchController: false }) }} />}
                {shouldShowProfileAccountsManager && 
                <ProfileAccountsManager 
                    profileIdsToPick={filteredProfileIds} 
                    subTitle={_.has(focusedGroup, 'name') && focusedGroup.id !== allProfileGroup.id ? focusedGroup.name : null} 
                    onClickClose={() => { this.setState({ shouldShowProfileAccountsManager: false }) }} />}
            </div>
        )
    }
}

ProfileContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    fundingRates: PropTypes.object.isRequired,
    symbolItems: PropTypes.object.isRequired,
    pricings: PropTypes.object.isRequired,
    positions: PropTypes.array.isRequired,
    account: PropTypes.object.isRequired
}

function mapStateToProps (state) {
    return {
        profile: state.profile,
        fundingRates: state.symbol.fundingRates,
        symbolItems: state.symbol.items,
        pricings: state.symbol.pricings,
        positions: state.trading.positions,
        account: state.account
    }
}
export default connect(mapStateToProps)(ProfileContainer)