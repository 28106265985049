import { optimism } from 'viem/chains'

export const RPC_URLS_PER_CHAIN = {
    [optimism.id]: [
        'https://quaint-late-crater.optimism.quiknode.pro/703cf95e4c0a99fc2fb4a5359a4d854c1fbf4b44'
    ]
}

export const VAULT_CHAIN = optimism
export const VAULT_SLOT = {
    GIT_VERSION: 0,
    OWNER: 1,
    PAUSER: 2,
    TRADERS: 3,
    ACTION_SET: 4
}

export const SYNTHETIX_PERPS_ADDRESSES = {
    BTC_PERP: '0x59b007E9ea8F89b069c43F8f45834d30853e3699',
    ETH_PERP: '0x2B3bb4c683BFc5239B029131EEf3B1d214478d93',
    OP_PERP: '0x442b69937a0daf9D46439a71567fABE6Cb69FBaf',
    EUR_PERP: '0x87AE62c5720DAB812BDacba66cc24839440048d1',
    SOL_PERP: '0x0EA09D97b4084d859328ec4bF8eBCF9ecCA26F1D'
}

export const sUSD_ADDRESS = '0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9'