import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import _ from 'lodash'

import Popup from '../common/popup/Popup'
import Checkbox from '../common/checkbox/Checkbox'

import { sendData as nodeWebSocketSendData } from '../webSocket/NodeWebSocket'
import { TRADERS } from '../../configs/config'
import { UserProfileItem } from './UserProfiles'

class ProfileManagers extends Component {
    constructor (props) {
        super(props)
        this.state = {
            userProfiles: props.userProfiles,
            webSocketLastUpdateId: null
        }
    }

    componentDidUpdate () {
        const { userProfiles: propsUserProfiles } = this.props
        const { userProfiles: stateUserProfiles, webSocketLastUpdateId } = this.state
        if (!_.isEmpty(propsUserProfiles.lastUpdateTime)
        && (_.isEmpty(stateUserProfiles.lastUpdateTime) || moment(stateUserProfiles.lastUpdateTime).isBefore(propsUserProfiles.lastUpdateTime))
        && (_.isNil(webSocketLastUpdateId) || webSocketLastUpdateId === propsUserProfiles.lastUpdateId)) {
            this._updateUserProfiles(propsUserProfiles)
        }
    }

    _updateUserProfiles (userProfiles) {
        this.setState({
            userProfiles,
            webSocketLastUpdateId: null
        })
    }

    handleToggleCheckbox (newChecked=false, username='') {
        const { auth, profileId } = this.props
        const { userProfiles } = this.state
        const newUserProfiles = _.cloneDeep(userProfiles)
        const newWebSocketLastUpdateId = uuidv4()
        if (newChecked) {
            if (!_.has(newUserProfiles, `items.${username}`)) {
                newUserProfiles.items[username] = UserProfileItem({
                    username,
                    profileIds: []
                })
            }
            if (!newUserProfiles.items[username].profileIds.includes(profileId)) {
                newUserProfiles.items[username].profileIds.unshift(profileId)
                this.setState({ 
                    userProfiles: newUserProfiles,
                    webSocketLastUpdateId: newWebSocketLastUpdateId
                }, () => {
                    nodeWebSocketSendData({
                        type: 'MOVE_USER_PROFILE',
                        payload: {
                            sourceUsername: null,
                            destinationUsername: username,
                            profileId,
                            profileIndex: 0,
                            updateBy: auth.username,
                            updateId: newWebSocketLastUpdateId 
                        }   
                    })
                })
            }
        } else if (_.has(newUserProfiles, `items.${username}.profileIds`) && newUserProfiles.items[username].profileIds.includes(profileId)) {
            _.pull(newUserProfiles.items[username].profileIds, profileId)
            this.setState({ 
                userProfiles: newUserProfiles,
                webSocketLastUpdateId: newWebSocketLastUpdateId
            }, () => {
                nodeWebSocketSendData({
                    type: 'REMOVE_USER_PROFILE',
                    payload: {
                        username,
                        profileId,
                        updateBy: auth.username,
                        updateId: newWebSocketLastUpdateId
                    }   
                })
            })
        }
    }

    render () {
        const { profileId } = this.props
        const { userProfiles, webSocketLastUpdateId } = this.state
        const managers = _.reduce(userProfiles.items || {}, (result, userProfileItem) => {
            const { username, profileIds }= userProfileItem
            if (_.isArray(profileIds) && profileIds.includes(profileId)) {
                result.push(username)
            }
            return result
        }, [])

        return (
            <Popup className='profile-managers--popup' 
                on={'click'}
                trigger={<div className={'profile-managers--popup--trigger' + (_.isEmpty(managers) ? ' empty' : '')}>{_.isEmpty(managers) ? 'Assign to Traders' : managers.join(', ')}</div>}>
                <div className='profile-managers--main'>
                    <div className='profile-managers--header'>
                        <label className='profile-managers--title'>{'Profile Traders'}</label>
                        <div className='profile-managers--is-updating'>{!_.isNil(webSocketLastUpdateId) ? 'Saving...' : ''}</div>
                    </div>
                    <div className='profile-managers--body clearfix'>
                        {_.map(TRADERS, trader => {
                            return (
                                <div className='profile-managers--trader' key={trader}
                                    onClick={(e) => { e.stopPropagation() }}>
                                    <label>{trader}</label>
                                    <Checkbox 
                                        checked={managers.includes(trader)} 
                                        onChange={(newChecked) => {
                                            this.handleToggleCheckbox(newChecked, trader)
                                        }} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </Popup>
        )
    }
}

ProfileManagers.propTypes = {
    auth: PropTypes.object.isRequired,
    userProfiles: PropTypes.object.isRequired,

    profileId: PropTypes.string.isRequired
}

function mapStateToProps (state) {
    return {
        auth: state.auth,
        userProfiles: state.profile.userProfiles
    }
}

export default connect(mapStateToProps)(ProfileManagers)