import React, { Component } from 'react'
import { connect } from 'react-redux'

import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer'
import OptionStrategyContainer from '../components/optionStrategy/OptionStrategyContainer'

import { setPageTitleAndDescription } from '../util/util'

class OptionStrategiesPage extends Component {
    constructor (props) {
        super(props)
    }

    componentDidMount () {
        setPageTitleAndDescription({
            title: 'Option Trade - Antelope Technology'
        })
    }

    render () {
        return (
            <div className='option-srategies-page page'>
                <Header />
                <div className='option-srategies-page page--body'>
                    <OptionStrategyContainer />
                </div>
                <Footer />
            </div>
        )
    }
}

function mapStateToProps () {
    return {}
}

export default connect(mapStateToProps)(OptionStrategiesPage)