import React, { Component } from 'react'
import moment from 'moment'

export default class Clock extends Component {
    constructor (props) {
        super(props)
        this.state = {
            timeString: moment().format('HH:mm:ss')
        }
        this.interval = null
    }

    componentDidMount () {
        this.interval = setInterval(() => {
            this._updateTimeString()
        }, 1000)
    }
    
    componentWillUnmount () {
        if (this.interval) {
            window.clearInterval(this.interval)
        }
    }

    _updateTimeString () {
        this.setState({
            timeString: moment().format('HH:mm:ss')
        })
    }

    render () {
        const { timeString } = this.state
        return (
            <div className='clock'>{timeString}</div>
        )
    }
}