import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import dotProp from 'dot-prop-immutable'
import _ from 'lodash'

import Modal from 'react-modal'
import { IoMdSwitch } from 'react-icons/io'
import { FiAlertCircle, FiCheck, FiX } from 'react-icons/fi'
import { AutoSizer, CellMeasurer, CellMeasurerCache, Table, Column } from 'react-virtualized'

import rollingImage from '../../images/rolling.svg'

import Popup from '../common/popup/Popup'
import ProfileActionPopup from './ProfileActionPopup'
import SaveButton from '../common/saveButton/SaveButton'
import ProfileActionButtons from './ProfileActionButtons'
import SearchSelect from '../common/searchSelect/SearchSelect'
import SymbolMultipleSelector from '../symbol/SymbolMultipleSelector'
import ProfileExchangeAccountEditor from './ProfileExchangeAccountEditor'

import { getExchangeDisplayName, getProfileExchangeNames, getProfileExchangeNameBySymbol, getProfileSmartPosAccountParamKeys, getProfileSymbolNames } from '../../util/profileUtil'
import { cancelAndPauseProfile, fetchProfileParams, pauseProfile, restartProfile, resumeProfile, stopProfile, updateProfileParams } from './profileAction'
import { EXCHANGES_CAN_SUPPORT_SMART_POSITIONS } from '../../configs/tradingConfig'


const INVALID = 'INVALID'
const SMART_POS_ACCOUNT = 'smart_pos_acct'

const ProfileItemToSave = ({ profileId, profileItem, isSaving=false, isSavedSuccess=false, failed=false, errorMessage }) => {
    return {
        profileId,
        profileItem,
        isSaving,
        isSavedSuccess,
        failed,
        errorMessage
    }
}

const ProfileItemToSync = ({ profileId, profileName, isSyncing=false, isSyncedSuccess=false, failed=false, errorMessage }) => {
    return {
        profileId,
        profileName,
        isSyncing,
        isSyncedSuccess,
        failed,
        errorMessage
    }
}

class ProfileAccountsManager extends Component {
    constructor (props) {
        super(props)
        this.state = {
            batchEditor: {
                exchangeName: null,
                symbolNamesToFilter: [],
                virtualProfileItem: null
            },
            editingProfileItems: [],
            profileItemsToSave: [],
            profileItemsToSync: [],
            shouldShowSavingProfileWindow: false,
            shouldShowSyncingProfileWindow: false,
            shouldShowSmartAcounts: true
        }
        this.tableNode = null
        this.cellMeasurerCache = new CellMeasurerCache({
            fixedWidth: true,
            fixedHeight: false
        })
    }

    scrollToTop () {
        if (this.tableNode) {
            this.tableNode.scrollToPosition(0)
            setTimeout(() => {
                this.tableNode.scrollToPosition(0)
            })
        }
    }

    getValidExchangeNames () {
        const { profileIdsToPick, profileItems } = this.props
        const profileItemsToPick = _.compact(profileIdsToPick.map(profileId => profileItems[profileId]))
        const validExchangeNames = _.reduce(profileItemsToPick, (result, profileItem) => {
            const profileExchangeNames = getProfileExchangeNames(profileItem)
            result.push(...profileExchangeNames)
            return result
        }, [])
        return _.uniq(validExchangeNames)
    }

    getValidSymbolNames (exchangeName) {
        const { profileIdsToPick, profileItems } = this.props
        const profileItemsToPick = _.compact(profileIdsToPick.map(profileId => profileItems[profileId]))
        const validSymbolNames = _.reduce(profileItemsToPick, (result, profileItem) => {
            const profileSymbolNames = getProfileSymbolNames(profileItem)
            profileSymbolNames.forEach(symbolName => {
                const symbolExchangeName = getProfileExchangeNameBySymbol(profileItem, symbolName)
                if ([symbolExchangeName.BUY, symbolExchangeName.SELL].includes(exchangeName)) {
                    result.push(symbolName)
                }
            })
            return result
        }, [])
        return _.uniq(validSymbolNames)
    }

    canNewAccountParamApplyToEditingProfileItem ({ editingProfileItem, exchangeName, newAccountName  }) {
        const { profileItems } = this.props
        const profileItem = profileItems[editingProfileItem.id]
        return (_.has(profileItem, 'started') && profileItem.started === false && !profileItem.isStarting)
            || (newAccountName === SMART_POS_ACCOUNT && _.has(profileItem, `accounts.${exchangeName}`) && profileItem.accounts[exchangeName] === newAccountName)
    }

    isEditingProfileAccountModified (editingProfileItem) {
        const { batchEditor } = this.state
        const { profileItems } = this.props
        const { id: profileId } = editingProfileItem
        const { exchangeName } = batchEditor
        const profileItem = profileItems[profileId]

        return _.has(profileItem, 'accounts') && _.has(editingProfileItem, 'accounts')
            && _.has(profileItem, 'params') && _.has(editingProfileItem, 'params')
            && (!_.isEqual(profileItem.accounts[exchangeName], editingProfileItem.accounts[exchangeName])
                || (editingProfileItem.accounts[exchangeName] === SMART_POS_ACCOUNT 
                    && _.some(getProfileSmartPosAccountParamKeys(exchangeName), paramKey => !_.isEqual(profileItem.params[paramKey], editingProfileItem.params[paramKey]))
                )
            )
    }

    saveEditingProfileItems (editingProfileItems=[]) {
        const { profileItemsToSave, batchEditor } = this.state
        const { dispatch, profileItems } = this.props
        const { exchangeName } = batchEditor

        const editingProfileItemsToSave = _.filter(editingProfileItems, editingProfileItem => {
            return this.isEditingProfileAccountModified(editingProfileItem) 
                && !_.some(profileItemsToSave, { profileId: editingProfileItem.id, isSaving: true })
        }).map(editingProfileItem => {
            const { id: profileId } = editingProfileItem
            const newProfileItem = _.cloneDeep(profileItems[profileId])

            newProfileItem.accounts[exchangeName] = editingProfileItem.accounts[exchangeName]
            if (newProfileItem.accounts[exchangeName] === SMART_POS_ACCOUNT) {
                getProfileSmartPosAccountParamKeys(exchangeName).forEach(paramKey => {
                    if (_.has(newProfileItem, `params.${paramKey}`)) {
                        newProfileItem.params[paramKey] = editingProfileItem.params[paramKey]
                    }
                })
            }
            
            return ProfileItemToSave({ 
                profileId, 
                profileItem: newProfileItem, 
                isSaving: true 
            })
        })

        const newProfileItemsToSave = _.unionBy(editingProfileItemsToSave, _.filter(profileItemsToSave, { isSaving: true }), 'profileId')

        this.setState({
            shouldShowSavingProfileWindow: true,
            shouldShowSyncingProfileWindow: false,
            profileItemsToSave: newProfileItemsToSave,
            profileItemsToSync: []
        })

        newProfileItemsToSave.forEach(profileItemToSave => {
            const { profileId, profileItem } = profileItemToSave
            dispatch(updateProfileParams(profileId, profileItem))
            .then(response => {
                if (response && !_.isError(response) && response.status === 200) {
                    this.setState(prevState => {
                        const profileItemToSaveIndex = _.findIndex(prevState.profileItemsToSave, { profileId, isSaving: true })
                        if (profileItemToSaveIndex >= 0) {
                            const newProfileItemsToSave = dotProp.merge(prevState.profileItemsToSave, `${profileItemToSaveIndex}`, {
                                isSaving: false,
                                isSavedSuccess: true
                            })
                            return {
                                profileItemsToSave: newProfileItemsToSave
                            }
                        }
                    })
                } else {
                    this.setState(prevState => {
                        const profileItemToSaveIndex = _.findIndex(prevState.profileItemsToSave, { profileId, isSaving: true })
                        if (profileItemToSaveIndex >= 0) {
                            const newProfileItemsToSave = dotProp.merge(prevState.profileItemsToSave, `${profileItemToSaveIndex}`, {
                                isSaving: false,
                                failed: true,
                                errorMessage: _.isError(response) ? response.toString() : 'Unkown Network Error'
                            })
                            return {
                                profileItemsToSave: newProfileItemsToSave
                            }
                        }
                    })
                }
            })
        })
    }

    handleClickSyncButton () {
        const { editingProfileItems, profileItemsToSave } = this.state
        const { dispatch, profileItems } = this.props
        const isSavingAnyProfileItem = _.some(profileItemsToSave, { isSaving: true })
        if (!isSavingAnyProfileItem) {
            const newProfileItemsToSync = _.filter(editingProfileItems, editingProfileItem => profileItems[editingProfileItem.id].started === false)
                .map(editingProfileItem => ProfileItemToSync({
                    profileId: editingProfileItem.id,
                    profileName: editingProfileItem.name,
                    isSyncing: true
                }))
            this.setState({
                profileItemsToSync: newProfileItemsToSync,
                profileItemsToSave: [],
                shouldShowSyncingProfileWindow: true,
                shouldShowSavingProfileWindow: false
            })

            newProfileItemsToSync.forEach(profileItemToSync => {
                const { profileId, profileName } = profileItemToSync
                dispatch(fetchProfileParams({
                    profileId,
                    updateStore: true
                })).then(body => {
                    if (_.has(body, profileName)) {
                        const newProfileParam = body[profileName]
                        this.setState(prevState => {
                            const profileItemToSyncIndex = _.findIndex(prevState.profileItemsToSync, { profileId, isSyncing: true })
                            const editingProfileItemIndex = _.findIndex(prevState.editingProfileItems, { id: profileId })
                            const newState = {}
                            if (profileItemToSyncIndex >= 0) {
                                newState.profileItemsToSync = dotProp.merge(prevState.profileItemsToSync, profileItemToSyncIndex, {
                                    isSyncing: false,
                                    isSyncedSuccess: true
                                })
                            }
                            if (editingProfileItemIndex >= 0) {
                                newState.editingProfileItems = dotProp.merge(prevState.editingProfileItems, editingProfileItemIndex, newProfileParam)
                            }
                            this.cellMeasurerCache.clearAll()
                            return newState
                        })
                    } else {
                        this.setState(prevState => {
                            const profileItemToSyncIndex = _.findIndex(prevState.profileItemsToSync, { profileId, isSyncing: true })
                            if (profileItemToSyncIndex >= 0) {
                                return {
                                    profileItemsToSync: dotProp.merge(prevState.profileItemsToSync, profileItemToSyncIndex, {
                                        isSyncing: false,
                                        failed: true,
                                        errorMessage: 'Failed to Sync'
                                    })
                                }
                            }
                        })
                    }
                })
            })
        }
    }

    handleChangeBatchEditorSymbolNamesToFilter (newSymbolNamesToFilter=[]) {
        const { batchEditor } = this.state
        const { profileIdsToPick, profileItems } = this.props
        const profileItemsToPick = _.compact(profileIdsToPick.map(profileId => profileItems[profileId]))

        const newEditingProfileItems = profileItemsToPick
            .filter(profileItem => getProfileExchangeNames(profileItem).includes(batchEditor.exchangeName))
            .filter(profileItem => _.intersection(getProfileSymbolNames(profileItem), newSymbolNamesToFilter).length > 0)
        const newVirtualProfileItem = _.head(Object.values(newEditingProfileItems))

        this.cellMeasurerCache.clearAll()
        this.setState({
            batchEditor: Object.assign({}, batchEditor, {
                symbolNamesToFilter: newSymbolNamesToFilter,
                virtualProfileItem: newVirtualProfileItem || null
            }),
            editingProfileItems: newEditingProfileItems
        })
        this.scrollToTop()
    }

    handleClickApplyButton () {
        const { batchEditor, editingProfileItems } = this.state

        const virtualProfileItemActiveAccountName = _.has(batchEditor, `virtualProfileItem.accounts.${batchEditor.exchangeName}`)
            && batchEditor.virtualProfileItem.accounts[batchEditor.exchangeName] !== INVALID
            ? batchEditor.virtualProfileItem.accounts[batchEditor.exchangeName] 
            : null

        const newEditingProfileItems = editingProfileItems.map(profileItem => {
            const shouldApply = this.canNewAccountParamApplyToEditingProfileItem({ 
                editingProfileItem: profileItem, 
                exchangeName: batchEditor.exchangeName, 
                newAccountName: virtualProfileItemActiveAccountName })
            if (shouldApply) {
                let newEditingProfileItem
                if (virtualProfileItemActiveAccountName === SMART_POS_ACCOUNT) {
                    const newSmartPosAccountParams = _.reduce(getProfileSmartPosAccountParamKeys(batchEditor.exchangeName), (result, paramKey) => {
                        if (_.has(profileItem, `params.${paramKey}`)) {
                            result[paramKey] = batchEditor.virtualProfileItem.params[paramKey]
                        }
                        return result
                    }, {})
                    newEditingProfileItem = dotProp.merge(profileItem, 'params', newSmartPosAccountParams)
                    newEditingProfileItem = dotProp.set(newEditingProfileItem, `accounts.${batchEditor.exchangeName}`, SMART_POS_ACCOUNT)
                } else {
                    newEditingProfileItem = dotProp.set(profileItem, `accounts.${batchEditor.exchangeName}`, virtualProfileItemActiveAccountName)
                }
                return newEditingProfileItem
            } else {
                return profileItem
            }
        })

        this.cellMeasurerCache.clearAll()
        this.setState({ editingProfileItems: newEditingProfileItems })
    }

    SavingProfileWindow () {
        const { profileItemsToSave } = this.state
        const canClose = _.every(profileItemsToSave, { isSaving: false })
        return !_.isEmpty(profileItemsToSave) ? (
            <div className='profile-accounts-manager--saving-profile-window'>
                <div className='profile-accounts-manager--saving-profile-window--body'>
                    {_.map(profileItemsToSave, profileItemToSave => {
                        const { profileId, profileItem, isSaving, isSavedSuccess, errorMessage } = profileItemToSave
                        return (
                            <div className='profile-accounts-manager--saving-profile-window--item clearfix' key={profileId}>
                                <div className='profile-accounts-manager--saving-profile-window--item--name'>{profileItem.name}</div>
                                <div className='profile-accounts-manager--saving-profile-window--item--icon'>
                                    {isSaving ? <img alt={'saving'} src={rollingImage} />
                                    : isSavedSuccess ? <FiCheck className='profile-accounts-manager--saving-profile-window--item--icon--success' />
                                    : <FiAlertCircle className='profile-accounts-manager--saving-profile-window--item--icon--failed'/>}
                                </div>
                                {errorMessage && <div className='profile-accounts-manager--saving-profile-window--item--error-message'>{errorMessage}</div>}
                            </div>
                        )
                    })}
                </div>
                <div className='profile-accounts-manager--saving-profile-window--footer'>
                    <span>{'Saving Profile'}</span>
                    {canClose && <button 
                        className='profile-accounts-manager--saving-profile-window--close-button'
                        onClick={() => {
                            this.setState({ 
                                shouldShowSavingProfileWindow: false,
                                profileItemsToSave: []
                            })
                        }}>
                        {'CLOSE'}
                    </button>}
                </div>
            </div>
        ) : null
    }

    SyncingProfileWindow () {
        const { profileItemsToSync } = this.state
        const canClose = _.every(profileItemsToSync, { isSyncing: false })
        return !_.isEmpty(profileItemsToSync) ? (
            <div className='profile-accounts-manager--syncing-profile-window'>
                <div className='profile-accounts-manager--syncing-profile-window--body'>
                    {_.map(profileItemsToSync, profileItemsToSync => {
                        const { profileId, profileName, isSyncing, isSyncedSuccess, errorMessage } = profileItemsToSync
                        return (
                            <div className='profile-accounts-manager--syncing-profile-window--item clearfix' key={profileId}>
                                <div className='profile-accounts-manager--syncing-profile-window--item--name'>{profileName}</div>
                                <div className='profile-accounts-manager--syncing-profile-window--item--icon'>
                                    {isSyncing ? <img alt={'syncing'} src={rollingImage} />
                                    : isSyncedSuccess ? <FiCheck className='profile-accounts-manager--syncing-profile-window--item--icon--success' />
                                    : <FiAlertCircle className='profile-accounts-manager--syncing-profile-window--item--icon--failed' />}
                                </div>
                                {errorMessage && <div className='profile-accounts-manager--syncing-profile-window--item--error-message'>{errorMessage}</div>}
                            </div>
                        )
                    })}
                </div>
                <div className='profile-accounts-manager--syncing-profile-window--footer'>
                    <span>{'Syncing Profile'}</span>
                    {canClose && <button 
                        className='profile-accounts-manager--syncing-profile-window--close-button'
                        onClick={() => {
                            this.setState({ 
                                shouldShowSyncingProfileWindow: false,
                                profileItemsToSync: []
                            })
                        }}>
                        {'CLOSE'}
                    </button>}
                </div>
            </div>
        ) : null
    }

    StateItem ({ className, name='', hasMark=true, iconComponent }) {
        return (
            <div className={'profile-accounts-manager--state-item' + (className ? ` ${className}` : '')}>
                {iconComponent}
                {hasMark && <span className='profile-accounts-manager--state-item--mark vertical-centered' />}
                <span className='profile-accounts-manager--state-item--name'>{name}</span>
            </div>
        )
    }

    ProfileState (profileItem) {
        const profileStartStopState = profileItem.isStarting ? 'STARTING' : profileItem.isStopping ? 'STOPPING' : profileItem.started ? 'STARTED' : 'STOPPED'
        const profileResumePauseState = profileItem.isResuming ? 'RESUMING' : profileItem.isPausing ? 'PAUSING' : profileItem.resumed ? 'RESUMED' : 'PAUSED'
        return (
            <div className='profile-accounts-manager--state'>
                {profileItem.crashed && this.StateItem({
                    className: 'CRASHED',
                    name: 'CRASHED',
                    hasMark: false,
                    iconComponent: <FiAlertCircle />
                })}
                {this.StateItem({
                    className: profileStartStopState,
                    name: profileStartStopState,
                    hasMark: true
                })}
                {profileStartStopState === 'STARTED' && this.StateItem({
                    className: profileResumePauseState,
                    name: profileResumePauseState,
                    hasMark: true
                })}
            </div>
        )
    }

    BatchEditor () {
        const { batchEditor, editingProfileItems } = this.state
        const { profileIdsToPick, profileItems } = this.props
        const profileItemsToPick = _.compact(profileIdsToPick.map(profileId => profileItems[profileId]))
        const validExchangeNames = this.getValidExchangeNames()
        const validSymbolNames = this.getValidSymbolNames(batchEditor.exchangeName)
        const exchangeNameOptions = validExchangeNames.sort().map(exchangeName => {
            return {
                value: exchangeName,
                name: getExchangeDisplayName(exchangeName)
            }
        })

        const virtualProfileItemActiveAccountName = _.has(batchEditor, `virtualProfileItem.accounts.${batchEditor.exchangeName}`)
            && batchEditor.virtualProfileItem.accounts[batchEditor.exchangeName] !== INVALID
            ? batchEditor.virtualProfileItem.accounts[batchEditor.exchangeName] 
            : null

        const canNewAccountParamApplyToEditingProfileItemSize = _.filter(editingProfileItems, profileItem => 
            this.canNewAccountParamApplyToEditingProfileItem({ 
                editingProfileItem: profileItem, 
                exchangeName: batchEditor.exchangeName,
                newAccountName: virtualProfileItemActiveAccountName
            })
        ).length

        return (
            <div className='profile-accounts-manager--batch-editor'>
                <div className='profile-accounts-manager--batch-editor--title'>
                    {'Batch Editor'}
                </div>
                <div className='profile-accounts-manager--batch-editor--body'>
                    <div className='profile-accounts-manager--batch-editor--exchange'>
                        <div className='profile-accounts-manager--batch-editor--exchange--label'>{'Exchange'}</div>
                        <div className='profile-accounts-manager--batch-editor--exchange--body'>
                            <SearchSelect
                                value={batchEditor.exchangeName}
                                options={exchangeNameOptions}
                                onChange={(newOption) => {
                                    const newExchangeName = newOption.value
                                    const newEditingProfileItems = _.filter(profileItemsToPick, profileItem => getProfileExchangeNames(profileItem).includes(newExchangeName))
                                    const newVirtualProfileItem = _.head(Object.values(newEditingProfileItems))
                                    const newValidSymbolNames = this.getValidSymbolNames(newExchangeName)
                                    this.cellMeasurerCache.clearAll()
                                    this.setState({
                                        batchEditor: Object.assign({}, batchEditor, {
                                            exchangeName: newExchangeName,
                                            symbolNamesToFilter: newValidSymbolNames,
                                            virtualProfileItem: newVirtualProfileItem
                                        }),
                                        editingProfileItems: newEditingProfileItems
                                    })
                                    this.scrollToTop()
                                }} />
                        </div>
                    </div>
                    {!_.isEmpty(batchEditor.exchangeName) && <div className='profile-accounts-manager--batch-editor--symbols-to-filter'>
                        <div className='profile-accounts-manager--batch-editor--symbols-to-filter--label'>{'Symbols'}</div>
                        <SymbolMultipleSelector 
                            className='profile-accounts-manager--batch-editor--symbols-to-filter--symbol-selector' 
                            symbolOptions={validSymbolNames}
                            selectedSymbolNames={batchEditor.symbolNamesToFilter}
                            onChangeSelectedSymbolNames={(newSymbolNames) => {
                                this.handleChangeBatchEditorSymbolNamesToFilter(newSymbolNames)
                            }} />
                    </div>}
                    {batchEditor.virtualProfileItem && <div className='profile-accounts-manager--batch-editor--accounts'>
                        <div className='profile-accounts-manager--batch-editor--accounts--label'>{'Account'}</div>
                        <div className='profile-accounts-manager--batch-editor--accounts--body'>
                            <ProfileExchangeAccountEditor 
                                editingProfileItem={batchEditor.virtualProfileItem}
                                exchangeName={batchEditor.exchangeName}
                                editable
                                onChangeProfileAccount={(exchangeName, accountName) => {
                                    this.setState({ 
                                        batchEditor: dotProp.set(batchEditor, `virtualProfileItem.accounts.${exchangeName}`, accountName)
                                    })
                                }} 
                                onChangeProfileParam={(paramKey, newValue) => {
                                    this.setState({
                                        batchEditor: dotProp.set(batchEditor, `virtualProfileItem.params.${paramKey}`, newValue)
                                    })
                                }} />
                        </div>
                    </div>}
                </div>
                <div className='profile-accounts-manager--batch-editor--buttons'>
                    <button className='profile-accounts-manager--batch-editor--apply-button' 
                        disabled={canNewAccountParamApplyToEditingProfileItemSize === 0}
                        onClick={() => { this.handleClickApplyButton() }}>
                        {canNewAccountParamApplyToEditingProfileItemSize > 0 
                        ? `Apply to ${canNewAccountParamApplyToEditingProfileItemSize} Profile${canNewAccountParamApplyToEditingProfileItemSize > 1 ? 's' : ''}`
                        : 'No Available Profile'}
                    </button>
                </div>
            </div>
        )
    }

    Table () {
        const { editingProfileItems, batchEditor, profileItemsToSave, shouldShowSmartAcounts } = this.state
        const { dispatch, profileItems, profileIdsToPick } = this.props
        return (
            <AutoSizer>
                {({ width, height }) => (
                    <Table 
                        className='profile-accounts-manager--table'
                        ref={(node) => { this.tableNode = node }}
                        headerHeight={27}
                        headerClassName={'profile-accounts-manager--table--header'}
                        rowClassName={'profile-accounts-manager--table--row'}
                        height={height}
                        width={width}
                        rowCount={editingProfileItems.length}
                        rowGetter={({ index }) => editingProfileItems[index]}
                        rowHeight={this.cellMeasurerCache.rowHeight}
                        overscanRowCount={5}
                        deferredMeasurementCache={this.cellMeasurerCache}
                        noRowsRenderer={() => (
                            <div className='profile-accounts-manager--table--no-content'>{'There is no matched profiles.'}</div>
                        )}>
                        <Column 
                            dataKey={'name'}
                            width={220}
                            flexGrow={1}
                            label={`Profile (${editingProfileItems.length}/${profileIdsToPick.length})`} 
                            cellRenderer={({ rowData, parent, rowIndex }) => {
                                const profileSymbolNames = getProfileSymbolNames(rowData)
                                const symbolNamesTradedBySelectedExchange = profileSymbolNames.filter(symbolName => {
                                    const symbolExchangeName = getProfileExchangeNameBySymbol(rowData, symbolName)
                                    return [symbolExchangeName.BUY, symbolExchangeName.SELL].includes(batchEditor.exchangeName)
                                }) 
                                return (
                                    <CellMeasurer
                                        cache={this.cellMeasurerCache}
                                        columnIndex={0}
                                        parent={parent}
                                        rowIndex={rowIndex}>
                                        <div className='profile-accounts-manager--table--profile-name'>
                                            <span>{rowData.name}</span>
                                            <div className='profile-accounts-manager--table--profile-name--symbols'>
                                                {`${symbolNamesTradedBySelectedExchange.join(', ')}`}
                                            </div>
                                        </div>
                                    </CellMeasurer>
                                )
                            }}/>
                        <Column 
                            dataKey={'hostname'}
                            width={100}
                            className='profile-accounts-manager--table--hostname'
                            label={'Hostname'} />
                        <Column 
                            dataKey={'state'}
                            width={200}
                            className='profile-accounts-manager--table--state'
                            headerRenderer={() => {
                                return (
                                    <Fragment>
                                        <span>{'State'}</span>
                                        <Popup className='profile-accounts-manager--table--batch-control-popup'
                                            on={'click'}
                                            trigger={<button className='profile-accounts-manager--table--batch-control-popup--trigger'><IoMdSwitch /></button>}>
                                            <div className='profile-accounts-manager--table--batch-control-popup--header'>{'Batch Control'}</div>
                                            <div className='profile-accounts-manager--table--batch-control-popup--body'>
                                                <ProfileActionButtons 
                                                    cancelOrderButtonText={'Cancel Orders & Pause'}
                                                    onClickRestart={() => {
                                                        editingProfileItems.forEach(editingProfileItem => {
                                                            const profileItem = profileItems[editingProfileItem.id]
                                                            if (profileItem && !profileItem.isStarting) {
                                                                dispatch(restartProfile(profileItem.id, false))
                                                            }
                                                        })
                                                    }} 
                                                    onClickCleanRestart={() => {
                                                        editingProfileItems.forEach(editingProfileItem => {
                                                            const profileItem = profileItems[editingProfileItem.id]
                                                            if (profileItem && !profileItem.isStarting) {
                                                                dispatch(restartProfile(profileItem.id, true))
                                                            }
                                                        })
                                                    }} 
                                                    onClickStop={() => {
                                                        editingProfileItems.forEach(editingProfileItem => {
                                                            const profileItem = profileItems[editingProfileItem.id]
                                                            if (profileItem && profileItem.started && !profileItem.isStopping) {
                                                                dispatch(stopProfile(profileItem.id))
                                                            }
                                                        })
                                                    }} 
                                                    onClickResume={() => {
                                                        editingProfileItems.forEach(editingProfileItem => {
                                                            const profileItem = profileItems[editingProfileItem.id]
                                                            if (profileItem && profileItem.started && !profileItem.resumed && !profileItem.isResuming) {
                                                                dispatch(resumeProfile(profileItem.id))
                                                            }
                                                        })
                                                    }} 
                                                    onClickPause={() => {
                                                        editingProfileItems.forEach(editingProfileItem => {
                                                            const profileItem = profileItems[editingProfileItem.id]
                                                            if (profileItem && profileItem.started && profileItem.resumed && !profileItem.isPausing) {
                                                                dispatch(pauseProfile(profileItem.id))
                                                            }
                                                        })
                                                    }} 
                                                    onClickCancelOrder={() => {
                                                        editingProfileItems.forEach(editingProfileItem => {
                                                            const profileItem = profileItems[editingProfileItem.id]
                                                            if (profileItem && profileItem.started) {
                                                                dispatch(cancelAndPauseProfile(profileItem.id))
                                                            }
                                                        })
                                                    }} />
                                            </div>
                                        </Popup>
                                    </Fragment>
                                )
                            }} 
                            cellRenderer={({ rowData }) => {
                                const { id: profileId } = rowData
                                const profileItem = profileItems[profileId]
                                return (
                                    <Fragment>
                                        {this.ProfileState(profileItem)}
                                        <ProfileActionPopup 
                                            triggerClassName='profile-accounts-manager--table--profile-action-trigger'
                                            profileItem={profileItem} />
                                    </Fragment>
                                )
                            }} />
                        <Column
                            dataKey={'exchangeAccount'}
                            width={260}
                            headerRenderer={() => {
                                return (
                                    <Fragment>
                                        <span>{'Exch' + (!_.isEmpty(batchEditor.exchangeName) ? `: ${getExchangeDisplayName(batchEditor.exchangeName)}` : '')}</span>
                                        {EXCHANGES_CAN_SUPPORT_SMART_POSITIONS.includes(batchEditor.exchangeName) && 
                                        <button className='profile-accounts-manager--table--toggle-smart-accounts-button' 
                                            onClick={() => { 
                                                this.cellMeasurerCache.clearAll()
                                                this.setState({ shouldShowSmartAcounts: !shouldShowSmartAcounts }) 
                                            }}>
                                            {(shouldShowSmartAcounts ? 'Hide' : 'Show') + ' Smart Acct.'}
                                        </button>}
                                    </Fragment>
                                )
                            }}
                            cellRenderer={({ rowData, parent, rowIndex }) => {
                                const { id: profileId } = rowData
                                const profileItem = profileItems[profileId]
                                const isSaving = _.some(profileItemsToSave, { profileId, isSaving: true })
                                return (
                                    <CellMeasurer
                                        cache={this.cellMeasurerCache}
                                        columnIndex={2}
                                        parent={parent}
                                        rowIndex={rowIndex}>
                                        <ProfileExchangeAccountEditor
                                            className='profile-accounts-manager--table--exchange-accounts'
                                            editingProfileItem={rowData}
                                            exchangeName={batchEditor.exchangeName}
                                            editable={!profileItem.started && !profileItem.isStarting && !isSaving} 
                                            shouldHideSmartAccounts={!shouldShowSmartAcounts}
                                            onChangeProfileItem={(newProfileItem) => {
                                                this.cellMeasurerCache.clearAll()
                                                this.setState({
                                                    editingProfileItems: dotProp.set(editingProfileItems, rowIndex, newProfileItem)
                                                })
                                            }} />
                                    </CellMeasurer>
                                )
                            }} />
                        <Column 
                            dataKey={'action'}
                            width={120}
                            className='profile-accounts-manager--table--buttons'
                            label={''}
                            cellRenderer={({ rowData, rowIndex }) => {
                                const { id: profileId } = rowData
                                const isSaving = _.some(profileItemsToSave, { profileId, isSaving: true })
                                return this.isEditingProfileAccountModified(rowData) && !isSaving ? (
                                    <Fragment>
                                        <button className='profile-accounts-manager--table--button reset' 
                                            onClick={() => { 
                                                this.cellMeasurerCache.clearAll()
                                                const newEditingProfileItems = dotProp.set(editingProfileItems, rowIndex, profileItems[profileId])
                                                this.setState({ editingProfileItems: newEditingProfileItems })
                                            }}>{'Reset'}</button>
                                        <SaveButton 
                                            className='profile-accounts-manager--table--button save' 
                                            onClick={() => { this.saveEditingProfileItems([rowData]) }} />
                                    </Fragment>
                                ) 
                                : isSaving ? (<span className='profile-accounts-manager--table--saving-text'>{'Saving...'}</span>)
                                : null
                            }} />
                    </Table>
                )}
            </AutoSizer>
        )
    }

    render () {
        const { editingProfileItems, profileItemsToSave, profileItemsToSync, shouldShowSavingProfileWindow, shouldShowSyncingProfileWindow } = this.state
        const { subTitle, profileItems, onClickClose } = this.props

        const modifiedEditingProfileItemSize = _.filter(editingProfileItems, profileItem => this.isEditingProfileAccountModified(profileItem)).length
        const stoppedEditingProfileItemSize = _.filter(editingProfileItems, profileItem => _.has(profileItems, `${profileItem.id}.started`) && profileItems[profileItem.id].started === false).length
        const isSavingAnyProfileItem = _.some(profileItemsToSave, { isSaving: true })

        return (
            <Modal className='profile-accounts-manager'
                overlayClassName='profile-accounts-manager--modal-overlay modal-overlay'
                isOpen>
                <div className='profile-accounts-manager--body'>
                    <div className='profile-accounts-manager--header clearfix'>
                        <div className='profile-accounts-manager--title'>{'Profile Accounts Manager' + (!_.isEmpty(subTitle) ? `: ${subTitle}` : '')}</div>
                        <button className='profile-accounts-manager--close-button' onClick={() => { onClickClose() }}><FiX /></button>
                    </div>
                    <div className='profile-accounts-manager--main'>
                        <div className='profile-accounts-manager--main--left'>
                            {this.Table()}
                            {shouldShowSavingProfileWindow && !_.isEmpty(profileItemsToSave) && this.SavingProfileWindow()}
                            {shouldShowSyncingProfileWindow && !_.isEmpty(profileItemsToSync) && this.SyncingProfileWindow()}
                        </div>
                        <div className='profile-accounts-manager--main--right'>
                            <div className='profile-accounts-manager--batch-editor-wrapper'>
                                {this.BatchEditor()}
                            </div>
                            <div className='profile-accounts-manager--buttons'>
                                <button className='profile-accounts-manager--reset-button'
                                    onClick={() => {
                                        this.cellMeasurerCache.clearAll()
                                        const newEditingProfileItems = editingProfileItems.map(profileItem => {
                                            const isSaving = _.some(profileItemsToSave, { profileId: profileItem.id, isSaving: true })
                                            return isSaving ? profileItem : profileItems[profileItem.id]
                                        })
                                        this.setState({ editingProfileItems: newEditingProfileItems })
                                        this.scrollToTop()
                                    }}>{'RESET ALL'}</button>
                                <button className='profile-accounts-manager--sync-button' 
                                    disabled={isSavingAnyProfileItem || stoppedEditingProfileItemSize === 0}
                                    onClick={() => { this.handleClickSyncButton() }}>
                                    {'SYNC' + (stoppedEditingProfileItemSize > 0 ? ` (${stoppedEditingProfileItemSize})` : '')}
                                </button>
                                <button className='profile-accounts-manager--save-button' 
                                    disabled={modifiedEditingProfileItemSize === 0}
                                    onClick={() => { this.saveEditingProfileItems(editingProfileItems) }}>
                                    {'SAVE' + (modifiedEditingProfileItemSize > 0 ? ` (${modifiedEditingProfileItemSize})`: '')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

ProfileAccountsManager.propTypes = {
    dispatch: PropTypes.func.isRequired,
    subTitle: PropTypes.string,
    profileIdsToPick: PropTypes.array.isRequired,

    profileItems: PropTypes.object.isRequired,

    onClickClose: PropTypes.func.isRequired
}

ProfileAccountsManager.defaultProps = {
    onClickClose: () => {}
}

function mapStateToProps (state) {
    return {
        profileItems: state.profile.items
    }
}

export default connect(mapStateToProps)(ProfileAccountsManager)