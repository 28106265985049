import React, { memo, useState } from 'react'
import { useMountedState, useUpdateEffect } from 'react-use'
import { useDispatch } from 'react-redux'
import useEvent from 'react-use-event-hook'

import { AiOutlineNotification } from 'react-icons/ai'
import { postAlertUser } from './supportAction'
import { DEVELOPERS } from '../../configs/config'

import Popup from '../common/popup/Popup'
import ReactLoading from 'react-loading'
import { FaCircleCheck } from 'react-icons/fa6'


function NotifyITButton () {

    const dispatch = useDispatch()
    const isMounted = useMountedState()

    const [isSendingRequest, setIsSendingRequest] = useState(false)
    const [isRequestSent, setIsRequestSent] = useState(false)

    const handleClickButton = useEvent(() => {
        setIsSendingRequest(true)
        dispatch(postAlertUser(DEVELOPERS.ZhimingWang, ''))
        .then(() => {
            if (isMounted()) {
                setIsRequestSent(true)
            }
        })
        .finally(() => {
            if (isMounted()) {
                setIsSendingRequest(false)
            }
        })
    })

    useUpdateEffect(() => {
        if (isRequestSent) {
            setTimeout(() => {
                if (isRequestSent && isMounted()) {
                    setIsRequestSent(false)
                }
            }, 5000)
        }
    }, [isRequestSent])

    return (
        <Popup
            className='notify-it-button--popup'
            trigger={
                <button
                    className='notify-it-button'
                    disabled={isSendingRequest || isRequestSent}
                    onClick={() => { handleClickButton() }}>
                    {isRequestSent ? <>
                        <FaCircleCheck />
                        {'Notified'}
                    </> : isSendingRequest ? <>
                        <ReactLoading className='notify-it-button--loading'
                            type={'spin'}
                            color='#000' />
                        {'Notifying'}
                    </> : <>
                        <AiOutlineNotification />
                        {`Require urgent IT assit`}
                    </>}
                </button>
            }>
            {`"IT assistance is always one click away" - Zhiming`}
        </Popup>
    )
}

export default memo(NotifyITButton)