import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import TimerEditor from './TimerEditor'
import TimerItem from './TimerItem'

class TimerContainer extends Component {
    
    constructor (props) {
        super(props)
        this.state = {
            creatingTimer: false,
            editingTimer: null,
            hideDetail: true,
            searchString: ''
        }
    }

    render () {
        const { creatingTimer, editingTimer, hideDetail, searchString } = this.state
        const { timerItems } = this.props
        const trimmedSearchString = searchString.trim().toLowerCase()

        return (
            <div className='timer-container'>
                <div className='timer-container--header'>
                    <div className='timer-container--header--buttons'>
                        <button className='timer-container--new-button' 
                            onClick={() => { this.setState({ creatingTimer: true }) }}>
                            {'Create New Timer'}
                        </button>
                        <button className='timer-container--toggle-detail-button' 
                            onClick={() => { this.setState({ hideDetail: !hideDetail }) }}>
                            {(hideDetail ? 'Show' : 'Hide') + ' Details'}
                        </button>
                    </div>
                    <input className='timer-container--search-input' value={searchString}
                        placeholder={'Search Profiles'}
                        autoFocus
                        spellCheck={false}
                        onChange={(e) => { this.setState({ searchString: e.target.value }) }} />
                </div>
                <div className='timer-container--main'>
                    {_.isEmpty(timerItems) 
                    ? <div className='timer-container--empty-message'>{'Empty pending timers'}</div>
                    : _.sortBy(Object.values(timerItems), ['createdTime'])
                    .reverse().map((timer, index) => {
                        const showItem = trimmedSearchString.length === 0 
                        || _.findIndex(timer.profiles || [], profile => profile.profileId.toLowerCase().includes(trimmedSearchString)) > -1
                        return showItem ? (
                            <div className='timer-container--item' key={index}
                                onClick={() => {
                                    this.setState({ editingTimer: timer })
                                }}>
                                <TimerItem 
                                    timerItem={timer}
                                    searchString={searchString} 
                                    hideDetail={hideDetail} />
                            </div>
                        ) : null
                    })}
                </div>
                {creatingTimer && <TimerEditor 
                    mode={'CREATE'} 
                    closeOnSaveSuccess
                    onClickClose={() => { this.setState({ creatingTimer: false }) }} />}
                {editingTimer && <TimerEditor 
                    mode={'EDIT'}
                    defaultTimer={editingTimer} 
                    closeOnSaveSuccess={false}
                    onClickClose={() => { this.setState({ editingTimer: null }) }} />}
            </div>
        )
    }
}

TimerContainer.propTypes = {
    timerItems: PropTypes.object.isRequired
}

function mapStateToProps (state) {
    return {
        timerItems: state.timer.items
    }
}

export default connect(mapStateToProps)(TimerContainer)