import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { MdClose } from 'react-icons/md'
import { FiAlertCircle, FiLayers } from 'react-icons/fi'
import ProfileActionButtons from './ProfileActionButtons'
import ProfileActionPopup from './ProfileActionPopup'
import {restartProfile, stopProfile, resumeProfile, pauseProfile, cancelAndPauseProfile} from './profileAction'

class ProfileBulkUploadStatusRow extends Component {
    constructor (props) {
        super(props)
        this.state = {
            selectedProfileIds: []
        }
        this.statusRowNode = null
        this.nameNode = null
        this.handleClickWindow = this.handleClickWindow.bind(this)
    }

    componentDidMount () {
        window.addEventListener('click', this.handleClickWindow)
    }

    componentWillUnmount () {
        window.removeEventListener('click', this.handleClickWindow)
    }

    handleClickWindow (e) {
        const { selectedProfileIds } = this.state
        if (this.statusRowNode && !this.statusRowNode.contains(e.target) && !_.isEmpty(selectedProfileIds)) {
            this.setState({ selectedProfileIds: [] })
        } 
    }

    renderStatus ({bulbClassName, label}) {
        return (
            <div className='profile-bulk-upload-status-row--status'>
                <div className={'profile-bulk-upload-status-row--status--bulb vertical-centered' + (bulbClassName ? ` ${bulbClassName}` : '')} />
                <div className='profile-bulk-upload-status-row--status--label'>{label}</div>
            </div>
        )
    }

    renderBulkEditor () {
        const { selectedProfileIds } = this.state
        const { dispatch, profileItems } = this.props
        const nameNodeClientRect = this.nameNode.getBoundingClientRect()
        const bulkEditorTop = nameNodeClientRect.top + (nameNodeClientRect.top < document.body.clientHeight / 2 ? nameNodeClientRect.height : -75) + 'px'
        const bulkEditorLeft = nameNodeClientRect.width - 187 + 'px'
        return ReactDOM.createPortal(
            <div className='profile-bulk-upload-status-row--bulk-editor'
                style={{
                    top: bulkEditorTop,
                    left: bulkEditorLeft
                }}
                onClick={(e) => { e.stopPropagation() }}>
                <div className='profile-bulk-upload-status-row--bulk-editor--header clearfix'>
                    <div className='profile-bulk-upload-status-row--bulk-editor--title'>{'Control Selected Profiles'}</div>
                    <button className='profile-bulk-upload-status-row--bulk-editor--close-button'
                        onClick={(e) => { 
                            e.stopPropagation()
                            this.setState({ selectedProfileIds: [] })
                        }}><MdClose /></button>
                </div>
                <div className='profile-bulk-upload-status-row--bulk-editor--main'>
                    <ProfileActionButtons 
                        cancelOrderButtonText={'Cancel Orders & Pause'}
                        onClickRestart={() => { 
                            selectedProfileIds.map(profileId => profileItems[profileId]).forEach(profileItem => {
                                if (!profileItem.isStarting) {
                                    dispatch(restartProfile(profileItem.id, false))
                                }
                            })
                        }} 
                        onClickCleanRestart={() => { 
                            selectedProfileIds.map(profileId => profileItems[profileId]).forEach(profileItem => {
                                if (!profileItem.isStarting) {
                                    dispatch(restartProfile(profileItem.id, true))
                                }
                            })
                        }}
                        onClickStop={() => { 
                            selectedProfileIds.map(profileId => profileItems[profileId]).forEach(profileItem => {
                                if (profileItem.started && !profileItem.isStopping) {
                                    dispatch(stopProfile(profileItem.id))
                                }
                            })
                        }}
                        onClickResume={() => { 
                            selectedProfileIds.map(profileId => profileItems[profileId]).forEach(profileItem => {
                                if (profileItem.started && !profileItem.resumed && !profileItem.isResuming) {
                                    dispatch(resumeProfile(profileItem.id))
                                }
                            })
                        }}
                        onClickPause={() => { 
                            selectedProfileIds.map(profileId => profileItems[profileId]).forEach(profileItem => {
                                if (profileItem.started && profileItem.resumed && !profileItem.isPausing) {
                                    dispatch(pauseProfile(profileItem.id))
                                }
                            })
                        }} 
                        onClickCancelOrder={() => {
                            selectedProfileIds.map(profileId => profileItems[profileId]).forEach(profileItem => {
                                if (profileItem.started) {
                                    dispatch(cancelAndPauseProfile(profileItem.id))
                                }
                            })
                        }} />
                </div>
            </div>
        , window.document.body)
    }

    render () {
        const { selectedProfileIds } = this.state
        const { profileIds, profileItems, onClickSortButton } = this.props
        return (
            <tr className='profile-bulk-upload-status-row' ref={(node) => { this.statusRowNode = node }}>
                <th ref={(node) => { this.nameNode = node}} onClick={() => {
                    this.setState({ selectedProfileIds: profileIds })
                }}>
                    <button className='profile-bulk-upload-status-row--sort-button' 
                        onClick={(e) => {
                            e.stopPropagation()
                            const sortedProfileIds = profileIds.sort((a, b) => {
                                const profileItemA = profileItems[a]
                                const profileItemB = profileItems[b]
                                if (profileItemA.started && !profileItemB.started) {
                                    return -1
                                } else if (profileItemA.started && profileItemB.started && profileItemA.resumed && !profileItemB.resumed) {
                                    return -1
                                } else {
                                    return 0
                                }
                            })
                            onClickSortButton(sortedProfileIds)
                        }}>
                        <FiLayers />
                        {'Sort'}
                    </button>
                    {'Status'}
                    {!_.isEmpty(selectedProfileIds) && this.renderBulkEditor()}
                </th>
                {profileIds.map((profileId, index) => {
                    const profileItem = profileItems[profileId]
                    const profileStartStopStatus = profileItem.isStarting ? 'starting' : profileItem.isStopping ? 'stopping' : profileItem.started ? 'started' : 'stopped'
                    const profileResumePauseStatus = profileItem.isResuming ? 'resuming' : profileItem.isPausing ? 'pausing' : profileItem.resumed ? 'resumed' : 'paused'
                    const selected = selectedProfileIds.includes(profileId)
                    return (
                        <td className={'profile-bulk-upload-status-row--data' + (selected ? ' selected' : '')} key={index}>
                            <div className='profile-bulk-upload-status-row--status-wrapper' onClick={(e) => {
                                if (e.metaKey || e.ctrlKey || e.shiftKey) {
                                    this.setState({
                                        selectedProfileIds: selected ? _.without(selectedProfileIds, profileId) : _.concat(selectedProfileIds, profileId)
                                    })
                                }
                            }}>
                                {profileItem.crashed && <div className='profile-bulk-upload-status-row--status crashed-status'>
                                    <FiAlertCircle />
                                    <div className='profile-bulk-upload-status-row--status--label'>{'CRASHED'}</div>
                                </div>}
                                {this.renderStatus({
                                    bulbClassName: profileStartStopStatus,
                                    label: profileStartStopStatus.toUpperCase()
                                })}
                                {profileStartStopStatus === 'started' && this.renderStatus({
                                    bulbClassName: profileResumePauseStatus,
                                    label: profileResumePauseStatus.toUpperCase()
                                })}
                            </div>
                            <ProfileActionPopup 
                                popupClassName={'profile-bulk-upload-status-row--action-popup'}
                                triggerClassName={'profile-bulk-upload-status-row--action-trigger vertical-centered'} 
                                profileItem={profileItem} />
                        </td>
                    )
                })}
            </tr>
        )
    }
}

ProfileBulkUploadStatusRow.propTypes = {
    dispatch: PropTypes.func.isRequired,
    profileIds: PropTypes.array.isRequired,
    profileItems: PropTypes.object.isRequired,
    onClickSortButton: PropTypes.func
}

ProfileBulkUploadStatusRow.defaultProps = {
    profileIds: [],
    onClickSortButton: () => {}
}

function mapStateToProps (state) {
    return {
        profileItems: state.profile.items
    }
}

export default connect(mapStateToProps)(ProfileBulkUploadStatusRow)