import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'

import Checkbox from '../common/checkbox/Checkbox'

import { FiChevronUp, FiChevronDown } from 'react-icons/fi'
import { getProfileSymbolNames, getProfileTradingAccountNamesBySymbol } from '../../util/profileUtil'
import { postSignedSwitchOffProfileSymbols, updateProfileSearchString } from './profileAction'
import { getSymbolAttributeByName, INSTRUMENT_TYPES } from '../../util/symbolUtil'
import { isMetSearchStringCriteria } from '../../util/util'

const getStorageShouldPriceHaltIncludeOptionSymbols = () => {
    const shouldPriceHaltIncludeOptionSymbols = localStorage.profileSymbolsToBeHandledShouldPriceHaltIncludeOptionSymbols === '1' || false
    return shouldPriceHaltIncludeOptionSymbols
}

const updateStorageShouldPriceHaltIncludeOptionSymbols = (shouldInclude=false) => {
    localStorage.profileSymbolsToBeHandledShouldPriceHaltIncludeOptionSymbols = shouldInclude ? '1' : '0'
}

const TYPES = {
    PRICE_STOPPED_UPDATING: 'Price Halt',
    ALL_SWITCHED_OFF: 'All Acct. Switch-off'
}

const PROFILE_SYMBOL_SWITCH_OFF_GROUPS = {
    LATEST: 'Latest',
    SIGNED: 'Signed'
}

const ProfileSymbolAllSwitchOff = ({ profileId, symbolName, side, lastSwitchOffTimestamp }) => {
    return {
        profileId,
        symbolName,
        side,
        lastSwitchOffTimestamp
    }
}

class ProfileSymbolsToBeHandled extends Component {
    constructor (props) {
        super(props)
        this.state = {
            type: _.keys(TYPES)[0],
            shouldPriceHaltIncludeOptionSymbols: getStorageShouldPriceHaltIncludeOptionSymbols(),
            switchedOffProfileSymbolItemsToSign: [], // Array of ProfileSymbolAllSwitchOff
            profileSymbolSwitchOffGroup: _.keys(PROFILE_SYMBOL_SWITCH_OFF_GROUPS)[0],
            isMinimized: false,
            isSigningSwitchedOffItems: false,
            isSignSwitchedOffItemsSuccess: false,
            isSignSwitchedOffItemsFailed: false,
            searchString: ''
        }
    }

    _getProfileSymbolsAllSwitchedOff () {
        const { profileItems, profileRunningState, signedSwitchedOffProfileSymbols } = this.props
        const result = {
            latestItems: [],
            signedItems: []
        }
        const switchedOffItems = []
        _.forEach(profileItems, (profileItem, profileId) => {
            const runningState = profileRunningState[profileId]
            if (profileItem.started && _.has(runningState, 'switchOffs') && !_.isEmpty(runningState.switchOffs)) {
                const symbolNames = getProfileSymbolNames(profileItem)
                symbolNames.forEach(symbolName => {
                    const tradingAccountNames = getProfileTradingAccountNamesBySymbol(profileItem, symbolName)
                    const { BUY: buyTradingAccountNames, SELL: sellTradingAccountNames } = tradingAccountNames
                    const symbolBuySideSwitchedOffItems = _.filter(runningState.switchOffs, switchOffItem => {
                        return switchOffItem.side.includes('BUY') && switchOffItem.symbol === symbolName
                    })
                    const symbolSellSideSwitchedOffItems = _.filter(runningState.switchOffs, switchOffItem => {
                        return switchOffItem.side.includes('SELL') && switchOffItem.symbol === symbolName
                    })
                    const symbolBuySideSwitchedOffAccountNames = symbolBuySideSwitchedOffItems.map(switchOffItem => switchOffItem.account)
                    const symbolSellSideSwitchedOffAccountNames = symbolSellSideSwitchedOffItems.map(switchOffItem => switchOffItem.account)
                    const isAllBuyTradingAccountsSwitchedOff = _.every(buyTradingAccountNames, accountName => symbolBuySideSwitchedOffAccountNames.includes(accountName))
                    const isAllSellTradingAccountsSwitchedOf = _.every(sellTradingAccountNames, accountName => symbolSellSideSwitchedOffAccountNames.includes(accountName))
                    if (isAllBuyTradingAccountsSwitchedOff) {
                        const lastSwitchOffItem = _.maxBy(symbolBuySideSwitchedOffItems, 'timestamp')
                        if (lastSwitchOffItem) {
                            switchedOffItems.push(ProfileSymbolAllSwitchOff({
                                profileId,
                                symbolName,
                                side: 'BUY',
                                lastSwitchOffTimestamp: moment(lastSwitchOffItem.timestamp).toISOString()
                            }))
                        }
                    }
                    if (isAllSellTradingAccountsSwitchedOf) {
                        const lastSwitchOffItem = _.maxBy(symbolSellSideSwitchedOffItems, 'timestamp')
                        if (lastSwitchOffItem) {
                            switchedOffItems.push(ProfileSymbolAllSwitchOff({
                                profileId,
                                symbolName,
                                side: 'SELL',
                                lastSwitchOffTimestamp: moment(lastSwitchOffItem.timestamp).toISOString()
                            }))
                        }
                    }
                })
            }
        })
        if (!_.isEmpty(signedSwitchedOffProfileSymbols.items) && !_.isEmpty(signedSwitchedOffProfileSymbols.lastUpdateTime)) {
            _.forEach(switchedOffItems, item => {
                const { profileId, symbolName, side, lastSwitchOffTimestamp } = item
                const signedItem = _.find(signedSwitchedOffProfileSymbols.items, { profileId, symbolName, side })
                if (signedItem && !_.isEmpty(signedItem.lastSwitchOffTimestamp) && moment(lastSwitchOffTimestamp).isSame(signedItem.lastSwitchOffTimestamp)) {
                    result.signedItems.push(signedItem)
                } else {
                    result.latestItems.push(item)
                }
            })
        } else {
            result.latestItems = switchedOffItems
        }
        return result
    }

    handleClickSignSwitchedOffProfileSymbols () {
        const { dispatch } = this.props
        const { switchedOffProfileSymbolItemsToSign, isSigningSwitchedOffItems } = this.state
        if (!isSigningSwitchedOffItems && !_.isEmpty(switchedOffProfileSymbolItemsToSign)) {
            this.setState({ isSigningSwitchedOffItems: true })
            dispatch(postSignedSwitchOffProfileSymbols(switchedOffProfileSymbolItemsToSign))
            .then(() => {
                this.setState({ 
                    isSigningSwitchedOffItems: false,
                    isSignSwitchedOffItemsSuccess: true
                })
            })
            .catch(error => {
                console.error('ProfileSymbolsToBeHandled handleClickSignSwitchedOffProfileSymbols error: ', error)
                this.setState({ 
                    isSigningSwitchedOffItems: false,
                    isSignSwitchedOffItemsFailed: true
                })
            })
            .finally(() => {
                this.setState({ switchedOffProfileSymbolItemsToSign: [] })
            })
        }
    }

    _getProfileManagers (profileId) {
        const { userProfileItems } = this.props
        const profileManagers = _.filter(userProfileItems, userProfileItem => {
            const { username, profileIds } = userProfileItem
            return !_.isEmpty(username) && (profileIds || []).includes(profileId)
        }).map(userProfileItem => userProfileItem.username)
        return profileManagers
    }

    SymbolsStoppedUpdatingPrice (stoppedUpdatingSymbolPricings=[]) {
        const { dispatch } = this.props
        const { shouldPriceHaltIncludeOptionSymbols, searchString } = this.state
        const filteredItems = _.isEmpty(searchString)
            ? stoppedUpdatingSymbolPricings
            : _.filter(stoppedUpdatingSymbolPricings, item => {
                const profileManagers = this._getProfileManagers(item.profileId)
                return isMetSearchStringCriteria(`${item.profileId} ${item.symbolName} ${profileManagers.join(' ')}`, searchString)
            })
        const seivedItems = _.sortBy(filteredItems, item => {
            return item.lastUpdateTime ? moment(item.lastUpdateTime).valueOf() : 0
        })
        return (
            <div className='profile-symbols-to-be-handled--symbols-stopped-updating-price'>
                <div className='profile-symbols-to-be-handled--symbols-stopped-updating-price--criterias'>
                    <div className='profile-symbols-to-be-handled--symbols-stopped-updating-price--should-include-option-toggle'>
                        <label>{'Include Options'}</label>
                        <Checkbox checked={shouldPriceHaltIncludeOptionSymbols} 
                            onChange={(newChecked) => { 
                                updateStorageShouldPriceHaltIncludeOptionSymbols(newChecked)
                                this.setState({
                                    shouldPriceHaltIncludeOptionSymbols: getStorageShouldPriceHaltIncludeOptionSymbols()
                                })
                            }} />
                    </div>
                </div>
                {_.size(seivedItems) > 0 ? (
                    <table>
                        <thead>
                            <tr>
                                <th>{'Profile ID'}</th>
                                <th>{'Symbol'}</th>
                                <th>{'Trader'}</th>
                                <th>{'Last Update'}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {_.map(seivedItems, (item, index) => {
                                const { profileId, symbolName, lastUpdateTime } = item
                                const profileManagers = this._getProfileManagers(profileId)
                                return (
                                    <tr key={index}>
                                        <td><span onClick={() => { dispatch(updateProfileSearchString(profileId)) }}>{profileId}</span></td>
                                        <td>{symbolName}</td>
                                        <td>{profileManagers.join(', ')}</td>
                                        <td>{lastUpdateTime ? moment(lastUpdateTime).format('HH:mm:ss') : 'Unkown'}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                ) : <span className='profile-symbols-to-be-handled--empty-message'>{'Empty Data'}</span>}
            </div>
        )
    }

    SymbolsAllSwitchOff () {
        const { dispatch } = this.props
        const { switchedOffProfileSymbolItemsToSign, profileSymbolSwitchOffGroup, isSigningSwitchedOffItems, isSignSwitchedOffItemsSuccess, isSignSwitchedOffItemsFailed, searchString } = this.state
        const profileSymbolsAllSwitchedOff = this._getProfileSymbolsAllSwitchedOff()
        const itemsToRender = profileSymbolSwitchOffGroup === 'LATEST' ? profileSymbolsAllSwitchedOff.latestItems
            : profileSymbolSwitchOffGroup === 'SIGNED' ? profileSymbolsAllSwitchedOff.signedItems
            : []
        const filteredItems = _.isEmpty(searchString) ? itemsToRender : _.filter(itemsToRender, item => {
            const profileManagers = this._getProfileManagers(item.profileId)
            return isMetSearchStringCriteria(`${item.profileId} ${item.symbolName} ${item.side} ${profileManagers.join(' ')}`, searchString)
        })
        const seivedItems = _.sortBy(filteredItems, item => -moment(item.lastSwitchOffTimestamp).valueOf())

        return (
            <div className='profile-symbols-to-be-handled--switch-off'>
                <div className='profile-symbols-to-be-handled--switch-off--groups'>
                    {_.map(PROFILE_SYMBOL_SWITCH_OFF_GROUPS, (name, key) => {
                        const size = key === 'LATEST' ? _.size(profileSymbolsAllSwitchedOff.latestItems)
                            : key === 'SIGNED' ? _.size(profileSymbolsAllSwitchedOff.signedItems)
                            : 0
                        return (
                            <button className={'profile-symbols-to-be-handled--switch-off--groups--button' + (key === profileSymbolSwitchOffGroup? ' selected' : '')} 
                                key={key} onClick={() => {
                                this.setState({
                                    profileSymbolSwitchOffGroup: key
                                })
                            }}>{name + (size > 0 ? ` (${size})` : '')}</button>
                        )
                    })}
                </div>
                {_.size(seivedItems) > 0 ? (
                    <table>
                        <thead>
                            <tr>
                                <th>{'Profile ID'}</th>
                                <th>{'Symbol'}</th>
                                <th>{'Side'}</th>
                                <th>{'Trader'}</th>
                                <th>{'Last Switch-off'}</th>
                                {profileSymbolSwitchOffGroup === 'LATEST' && <th>
                                    <button
                                        className={'profile-symbols-to-be-handled--switch-off--sign-button' + (isSignSwitchedOffItemsSuccess? ' success' : isSignSwitchedOffItemsFailed ? ' failed' : '')}
                                        disabled={isSigningSwitchedOffItems || _.size(switchedOffProfileSymbolItemsToSign) === 0}
                                        onClick={() => {
                                            this.handleClickSignSwitchedOffProfileSymbols()
                                        }}>
                                        {isSigningSwitchedOffItems ? 'Signing'
                                        : isSignSwitchedOffItemsSuccess ? 'Success'
                                        : isSignSwitchedOffItemsFailed ? 'Failed'
                                        : 'Sign Off' + (_.size(switchedOffProfileSymbolItemsToSign) > 0 ? ` (${_.size(switchedOffProfileSymbolItemsToSign)})` : '')}
                                    </button>
                                </th>}
                                {profileSymbolSwitchOffGroup === 'SIGNED' && <Fragment>
                                    <th>{'Signed By'}</th>
                                    <th>{'Signed At'}</th>
                                </Fragment>}
                            </tr>
                        </thead>
                        <tbody>
                            {_.map(seivedItems, (item, index) => {
                                const { profileId, symbolName, side, lastSwitchOffTimestamp, createdBy, createdTimestamp } = item
                                const profileManagers = this._getProfileManagers(profileId)
                                const itemIndex = _.findIndex(switchedOffProfileSymbolItemsToSign, { profileId, symbolName, side })
                                return (
                                    <tr key={index}>
                                        <td><span onClick={() => { dispatch(updateProfileSearchString(profileId)) }}>{profileId}</span></td>
                                        <td>{symbolName}</td>
                                        <td className={side}>{side}</td>
                                        <td>{profileManagers.join(', ')}</td>
                                        <td>{moment(lastSwitchOffTimestamp).format('MM-DD HH:mm:ss')}</td>
                                        {profileSymbolSwitchOffGroup === 'LATEST' && <td>
                                            <Checkbox 
                                                checked={itemIndex >= 0}
                                                disabled={isSigningSwitchedOffItems}
                                                onChange={() => {
                                                    const newItemsToSign = _.cloneDeep(switchedOffProfileSymbolItemsToSign)
                                                    if (itemIndex >= 0) {
                                                        _.pullAt(newItemsToSign, itemIndex)
                                                    } else {
                                                        newItemsToSign.push(item)
                                                    }
                                                    this.setState({ 
                                                        switchedOffProfileSymbolItemsToSign: newItemsToSign,
                                                        isSignSwitchedOffItemsSuccess: false,
                                                        isSignSwitchedOffItemsFailed: false
                                                    })
                                                }} />
                                        </td>}
                                        {profileSymbolSwitchOffGroup === 'SIGNED' && <Fragment>
                                            <td>{createdBy || ''}</td>
                                            <td>{moment(createdTimestamp).format('MM-DD HH:mm:ss')}</td>
                                        </Fragment>}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                ) : <span className='profile-symbols-to-be-handled--empty-message'>{'Empty Data'}</span>}
            </div>
        ) 
    }

    render () {
        const { stoppedUpdatingSymbolPricings } = this.props
        const { type, isMinimized, shouldPriceHaltIncludeOptionSymbols, searchString } = this.state
        const filteredStoppedUpdatingSymbolPricings = shouldPriceHaltIncludeOptionSymbols 
            ? stoppedUpdatingSymbolPricings
            : _.filter(stoppedUpdatingSymbolPricings, item => {
                const { instrumentType, isCompositInstrument, compositSymbols } = getSymbolAttributeByName(item.symbolName)
                return instrumentType !== INSTRUMENT_TYPES.OPTION
                    && !(isCompositInstrument && _.some(compositSymbols, compositSymbol => getSymbolAttributeByName(compositSymbol).instrumentType === INSTRUMENT_TYPES.OPTION))
            })
        const profileSymbolsAllSwitchedOff = this._getProfileSymbolsAllSwitchedOff()
        const totalSize = _.size(filteredStoppedUpdatingSymbolPricings) + _.size(profileSymbolsAllSwitchedOff.latestItems)
        return totalSize > 0 || !_.isEmpty(profileSymbolsAllSwitchedOff.signedItems) ? (
            <div className='profile-symbols-to-be-handled'>
                <div className='profile-symbols-to-be-handled--header clearfix'>
                    <div className='profile-symbols-to-be-handled--title'>{`Symbols to Inspect (${totalSize})`}</div>
                    <button className='profile-symbols-to-be-handled--toggle-button' 
                        onClick={() => { this.setState({ isMinimized: !isMinimized }) }}>
                        {isMinimized ? <FiChevronDown /> : <FiChevronUp />}
                    </button>
                </div>
                {!isMinimized && <Fragment>
                    <div className='profile-symbols-to-be-handled--types'>
                        {_.map(TYPES, (name, t) => {
                            const size = t === 'PRICE_STOPPED_UPDATING' ? _.size(filteredStoppedUpdatingSymbolPricings)
                                : t === 'ALL_SWITCHED_OFF' ? _.size(profileSymbolsAllSwitchedOff.latestItems)
                                : 0
                            return (
                                <button className={type === t ? 'selected' : null} 
                                    key={t} 
                                    onClick={() => { this.setState({ type: t }) }}>{`${name} (${size})`}</button>
                            )
                        })}
                    </div>
                    <input className='profile-symbols-to-be-handled--search-input' 
                        type={'text'}
                        spellCheck={false}
                        placeholder='Search Profile, Symbol, Side'
                        value={searchString} 
                        onChange={(e) => { this.setState({ searchString: e.target.value }) }} />
                    <div className='profile-symbols-to-be-handled--main'>
                        {type === 'PRICE_STOPPED_UPDATING' && this.SymbolsStoppedUpdatingPrice(filteredStoppedUpdatingSymbolPricings)}
                        {type === 'ALL_SWITCHED_OFF' && this.SymbolsAllSwitchOff()}
                    </div>
                </Fragment>}
            </div>
        ) : null
    }
}

ProfileSymbolsToBeHandled.propTypes = {
    dispatch: PropTypes.func.isRequired,
    stoppedUpdatingSymbolPricings: PropTypes.array.isRequired,
    profileItems: PropTypes.object.isRequired,
    profileRunningState: PropTypes.object.isRequired,
    signedSwitchedOffProfileSymbols: PropTypes.object.isRequired,
    userProfileItems: PropTypes.object.isRequired
}

function mapStateToProps (state) {
    return {
        stoppedUpdatingSymbolPricings: state.profile.stoppedUpdatingSymbolPricings,
        profileItems: state.profile.items,
        profileRunningState: state.profile.runningState,
        signedSwitchedOffProfileSymbols: state.profile.signedSwitchedOffProfileSymbols,
        userProfileItems: state.profile.userProfiles.items
    }   
}

export default connect(mapStateToProps)(ProfileSymbolsToBeHandled)