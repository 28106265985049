import React, { Component } from 'react'
import PropTypes from 'prop-types'

import dotProp from 'dot-prop-immutable'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'

import { AiOutlineAppstoreAdd } from 'react-icons/ai'
import GridLayout from 'react-grid-layout'

import { WorkspaceComponentItem, WorkspaceLayoutItem } from './WorkspaceContainer'
import WorkspaceComponent from './WorkspaceComponent'

const COLUMN_WIDTH = 100
const ROW_HEIGHT = 30
const GRID_MARGIN = 15

export const WORKSPACE_LAYOUT_DRAGGABLE_HANDLE_CLASSNAME = 'workspace-layout--draggalbe-handle'
export default class WorkspaceLayout extends Component {
    constructor (props) {
        super(props)
        this.state = {
            wrapperWidth: null
        }
        this.gridWrapperNode = null
    }

    componentDidMount () {
        this._updateWrapperWidth()
    }

    _getWorkspaceLayouts (workspace) {
        const layout = _.map(_.get(workspace, 'components', []), workspaceComponent => workspaceComponent.layout)
        return _.compact(layout)
    }

    _updateWrapperWidth () {
        const { wrapperWidth } = this.state
        if (this.gridWrapperNode) {
            const newWrapperWdith = this.gridWrapperNode.clientWidth
            if (!_.isEqual(wrapperWidth, newWrapperWdith)) {
                this.setState({ wrapperWidth: newWrapperWdith })
            }
        }
    }

    render () {
        const { workspace, onChange } = this.props
        const { wrapperWidth } = this.state
        const workspaceLayouts = this._getWorkspaceLayouts(workspace)
        let gridMinWidth = 0, gridMinHeight = 0
        
        if (!_.isEmpty(workspaceLayouts)) {
            _.forEach(workspaceLayouts, layout => {
                const { x, y, w, h } = layout
                gridMinWidth = Math.max(gridMinWidth, COLUMN_WIDTH * (x + w) + Math.floor(Number(wrapperWidth) / 2))
                gridMinHeight = Math.max(gridMinHeight, (ROW_HEIGHT + GRID_MARGIN) * (y + h + 5))
            })
        }
        const width = !_.isNil(wrapperWidth) ? Math.max(wrapperWidth, gridMinWidth) : null
        return (
            <div className='workspace-layout'>
                <button className='workspace-layout--add-component-button' 
                    title={'Add Component'}
                    onClick={() => {
                        const newComponentId = uuidv4()
                        const newWorkspace = dotProp.set(workspace, `components.${newComponentId}`, WorkspaceComponentItem({
                            id: newComponentId,
                            componentKey: '',
                            layout: WorkspaceLayoutItem({
                                i: newComponentId,
                                x: 1,
                                y: 0,
                                w: 3,
                                h: 9
                            })
                        }))
                        onChange(newWorkspace)
                    }}><AiOutlineAppstoreAdd /></button>
                <div className='workspace-layout--grid-wrapper' ref={node => this.gridWrapperNode = node}>
                    {width > 0 && <GridLayout
                        className='workspace-layout--grid'
                        style={{ 
                            minWidth: `${width}px`,
                            minHeight: `${gridMinHeight}px`
                        }}
                        draggableHandle={`.${WORKSPACE_LAYOUT_DRAGGABLE_HANDLE_CLASSNAME}`}
                        layout={workspaceLayouts}
                        width={width}
                        margin={[GRID_MARGIN, GRID_MARGIN]}
                        cols={Math.floor(width / COLUMN_WIDTH)}
                        rowHeight={ROW_HEIGHT}
                        compactType={null}
                        preventCollision
                        resizeHandles={['nw', 'se']}
                        onLayoutChange={(newLayouts) => {
                            const newWorkspace = _.cloneDeep(workspace)
                            _.forEach(newLayouts, newLayout => {
                                if (_.has(newWorkspace, `components.${newLayout.i}`)) {
                                    newWorkspace.components[newLayout.i].layout = newLayout
                                }
                            })
                            onChange(newWorkspace)
                        }}>
                        {_.map(workspace.components, (component, componentId) => {
                            return (
                                <div className='workspace-layout--grid--item' key={componentId}>
                                    <WorkspaceComponent 
                                        componentId={componentId}
                                        componentKey={component.componentKey} 
                                        onChangeComponentKey={(newComponentKey) => {
                                            const newWorkspace = dotProp.set(workspace, `components.${componentId}.componentKey`, newComponentKey)
                                            onChange(newWorkspace)
                                        }} 
                                        onClickRemove={() => {
                                            const newWorkspace = dotProp.delete(workspace, `components.${componentId}`)
                                            onChange(newWorkspace)
                                        }} />
                                </div>
                            )
                        })}
                    </GridLayout>}
                </div>
            </div>
        )
    }
}

WorkspaceLayout.propTypes = {
    workspace: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
}

WorkspaceLayout.defaultProps = {
    onChange: () => {}
}