import React, { Component } from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'
import { toNumberWithSmartPrecision } from '../../util/util'

export default class SpotLoanAccountBalanceItem extends Component {
    InlineData ({ name, value, shouldHighlight }) {
        return (
            <div className='spot-loan-account-balance-item--inline-data'>
                <span className='spot-loan-account-balance-item--inline-data--name'>{name}</span>
                <div className={'spot-loan-account-balance-item--inline-data--value' + (shouldHighlight ? ' highlight' : '')}>{value}</div>
            </div>
        )
    }

    render () {
        const { spotLoanAccountBalance, shouldShowAccountTypeTag, shouldShowAccountName } = this.props
        return (
            <div className='spot-loan-account-balance-item'>
                <div className='spot-loan-account-balance-item--header clearfix'>
                    {shouldShowAccountTypeTag && <div className='spot-loan-account-balance-item--header--info-bar'>
                        <div className='spot-loan-account-balance-item--header--info-bar--account-type cross-margin'>{'SPOT LOAN'}</div>
                        <div className='spot-loan-account-balance-item--header--info-bar--timestamp'>{moment(spotLoanAccountBalance.update_time).format('HH:mm:ss')}</div>
                    </div>}
                    <div className='spot-loan-account-balance-item--coin'>
                        {spotLoanAccountBalance.coin}
                        {shouldShowAccountName ? ` @ ${spotLoanAccountBalance.acct_name}` : ''}
                    </div>
                    {!shouldShowAccountTypeTag && <div className='spot-loan-account-balance-item--timestamp'>{moment(spotLoanAccountBalance.update_time).format('HH:mm:ss')}</div>}
                </div>
                <div className='spot-loan-account-balance-item--summary'>
                    {this.InlineData({
                        name: 'Notional Bal',
                        value: `$${toNumberWithSmartPrecision({ number: spotLoanAccountBalance.balance_in_notional, shouldReturnLocalString: true, shouldApplyFloorValue: true, defaultPrecision: Math.abs(Number(spotLoanAccountBalance.balance_in_notional)) >= 1000 ? 0 : 2 })}`
                    })}
                    {this.InlineData({
                        name: 'Borrowed',
                        value: `$${toNumberWithSmartPrecision({ number: spotLoanAccountBalance.borrowed_in_notional, shouldReturnLocalString: true, shouldApplyFloorValue: true, defaultPrecision: Math.abs(Number(spotLoanAccountBalance.borrowed_in_notional)) >= 1000 ? 0 : 2  })}`
                    })}
                    {this.InlineData({
                        name: 'Risk Rate',
                        value: toNumberWithSmartPrecision({ number: spotLoanAccountBalance.risk_rate, shouldReturnLocalString: true, defaultPrecision: 4, shouldApplyFloorValue: true }),
                        shouldHighlight: true
                    })}
                </div>
                {/* {tokenLendingEnabled && shouldShowDetail && <div className='spot-loan-account-balance-item--lending' onClick={(e) => { e.stopPropagation() }}>
                    <div className='spot-loan-account-balance-item--lending--title'>{'LENDING'}</div>
                    <TokenLendingEditor 
                        defaultAccountName={spotLoanAccountBalance.acct_name} 
                        defaultCoin={_.toUpper(spotLoanAccountBalance.coin)} 
                        onChangeComponentHeight={() => { onChangeComponentHeight() }}/>
                </div>} */}
            </div>
        )
    } 
}

SpotLoanAccountBalanceItem.propTypes = {
    spotLoanAccountBalance: PropTypes.object.isRequired,
    shouldShowAccountTypeTag: PropTypes.bool,
    shouldShowAccountName: PropTypes.bool
    // shouldShowDetail: PropTypes.bool,
    // tokenLendingEnabled: PropTypes.bool,
    // onChangeComponentHeight: PropTypes.func
}

SpotLoanAccountBalanceItem.defaultProps = {
    tokenLendingEnabled: true,
    onChangeComponentHeight: () => {}
}