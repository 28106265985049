import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

const FrequentAccountStruct = ({ name, type, typeDisplayName }) => {
    return {
        name, type, typeDisplayName
    }
}

const FREQUENT_ACCOUNTS = [
    FrequentAccountStruct({
        name: 'binance_mining001',
        type: 'SPOT',
        typeDisplayName: 'Spot'
    }),
    FrequentAccountStruct({
        name: 'binance_vanda1',
        type: 'SPOT',
        typeDisplayName: 'Spot'
    }),
    FrequentAccountStruct({
        name: 'binance_mining',
        type: 'SPOT',
        typeDisplayName: 'Spot'
    }),
    FrequentAccountStruct({
        name: 'okex_cross',
        type: 'OKEX_FUNDING_ACCOUNT',
        typeDisplayName: 'Funding'
    }),
    FrequentAccountStruct({
        name: 'okex_vanda',
        type: 'OKEX_FUNDING_ACCOUNT',
        typeDisplayName: 'Funding'
    }),
    FrequentAccountStruct({
        name: 'bybit_prop2',
        type: 'BYBIT_FUNDING_ACCOUNT',
        typeDisplayName: 'Funding'
    }),
    FrequentAccountStruct({
        name: 'bybit_vanda1',
        type: 'BYBIT_FUNDING_ACCOUNT',
        typeDisplayName: 'Funding'
    })
]

function TransferFrequentAccounts ({ exchangeNames, accountItems, onClickAccount }) {

    const filteredFrequentAccounts = useMemo(() => {
        return _.isEmpty(exchangeNames) ? FREQUENT_ACCOUNTS : _.filter(FREQUENT_ACCOUNTS, frequentAccount => {
            const accountExchangeName = _.get(accountItems, `${frequentAccount?.name}.exchange_name`)
            return exchangeNames.includes(accountExchangeName)
        })
    }, [exchangeNames, accountItems])

    return !_.isEmpty(filteredFrequentAccounts) 
        ? (
            <div className='transfer-frequent-accounts'>
                <div className='transfer-frequent-accounts--title'>{'Frequently Used Accounts'}</div>
                <div className='transfer-frequent-accounts--list'>
                    {_.map(filteredFrequentAccounts, (frequentAccount, index) => {
                        const { name: accountName, type, typeDisplayName } = frequentAccount
                        return (
                            <div className='transfer-frequent-accounts--item' key={index}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onClickAccount(frequentAccount)
                                }}>
                                <label>{accountName}</label>
                                {!_.isEmpty(type) && <span>{` - ${typeDisplayName}`}</span>}
                            </div>
                        )
                    })}
                </div>
            </div>
        ) : null
}

TransferFrequentAccounts.propTypes = {
    exchangeNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    accountItems: PropTypes.object.isRequired,
    onClickAccount: PropTypes.func.isRequired
}

TransferFrequentAccounts.defaultProps = {
    exchangeNames: [],
    onSelectAccount: () => {}
}

function mapStateToProps (state) {
    return {
        accountItems: state.account.items
    }
}

export default connect(mapStateToProps)(TransferFrequentAccounts)
