import React, { Fragment, useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useMountedState } from 'react-use'
import useEvent from 'react-use-event-hook'

import PropTypes from 'prop-types'
import _ from 'lodash'

import { AutoSizer, Table, Column } from 'react-virtualized'

import SearchSelect from '../common/searchSelect/SearchSelect'
import Popup from '../common/popup/Popup'
import MultipleAccountSelector from '../account/MultipleAccountSelector'

import { INSTRUMENT_TYPES, getSymbolAttributeByName } from '../../util/symbolUtil'
import { fetchSymbolLeverage, updateBybitRiskId, updateSymbolLeverage } from './symbolAction'
import { areAllValuesNonEmpty } from '../../util/util'

const EXCHANGES = {
    BNBFUTA: 'BNBFUTA',
    BYBIT: 'BYBIT'
}

function LeverageManagement ({ symbolItems }) {

    const dispatch = useDispatch()
    const isMounted = useMountedState()

    const [symbol, setSymbol] = useState()
    const [accounts, setAccounts] = useState([])
    const [leverage, setLeverage] = useState('')
    const [riskId, setRiskId] = useState(null)
    const [updateLeverageComment, setUpdateLeverageComment] = useState('')
    const [updateBybitRiskIdComment, setUpdateBybitRiskIdComment] = useState('')
    const [dataPerSymbolAccountPair, setDataPerSymbolAccountPair] = useState({})
    const [leverageUpdatePopupId, setLeverageUpdatePopupId] = useState(0)
    const [bybitRiskIdUpdatePopupId, setBybitRiskIdUpdatePopupId] = useState(0)

    const filteredSymbolItems = useMemo(() => {
        return _.filter(symbolItems, _symbolItem => {
            const { exchangeName, instrumentType } = getSymbolAttributeByName(_symbolItem.symbol_name)
            return _.values(EXCHANGES).includes(exchangeName)
                && (exchangeName !== EXCHANGES.BYBIT || [INSTRUMENT_TYPES.FUTURE, INSTRUMENT_TYPES.SWAP].includes(instrumentType))
        })
    }, [symbolItems])

    const symbolOptions = _.map(filteredSymbolItems, _symbolItem => ({
        value: _symbolItem.symbol_name,
        name: _symbolItem.symbol_name
    }))

    const { exchangeName } = getSymbolAttributeByName(symbol)

    const tableRows = _.map(accounts, _account => ({
        symbol,
        account: _account,
        ..._.get(dataPerSymbolAccountPair, `${symbol}-${_account}`, {})
    }))

    const _updateSymbolAccountPairMessage = ({ symbol, account, message }) => {
        const pair = `${symbol}-${account}`
        setDataPerSymbolAccountPair(prevData => ({
            ...prevData,
            [pair]: {
                ..._.get(prevData, pair, {}),
                message
            }
        }))
    }

    const fetchData = useEvent(() => {
        if (!_.isEmpty(symbol) && !_.isEmpty(accounts)) {
            _.forEach(accounts, _account => {
                const pair = `${symbol}-${_account}`
                _updateSymbolAccountPairMessage({
                    symbol,
                    account: _account,
                    message: 'Fetching...'
                })
                dispatch(fetchSymbolLeverage({
                    accountName: _account,
                    symbol,
                    comment: updateLeverageComment
                })).then(async (response) => {
                        const status = response?.status
                        if (status === 200) {
                            const data = await response.json()
                            if (isMounted()) {
                                setDataPerSymbolAccountPair(prevData => ({
                                    ...prevData,
                                    [pair]: data
                                }))
                            }
                        } else {
                            const message = await response.text()
                            _updateSymbolAccountPairMessage({
                                symbol,
                                account: _account,
                                message: message
                            })
                        }
                    })
                    .catch((error) => {
                        if (isMounted()) {
                            _updateSymbolAccountPairMessage({
                                symbol,
                                account: _account,
                                message: error.toString()
                            })
                        }
                    })
            })
        }
    })

    const handleClickBatchUpdateLeverage = useEvent(async () => {
        if (areAllValuesNonEmpty([symbol, leverage]) && !_.isEmpty(accounts)) {
            for await (const accountName of accounts) {
                if (!isMounted()) {
                    break
                }

                const pair = `${symbol}-${accountName}`
                _updateSymbolAccountPairMessage({
                    symbol,
                    account: accountName,
                    message: `Updating leverage...`
                })
    
                dispatch(updateSymbolLeverage({
                    accountName,
                    symbol,
                    leverage,
                    comment: updateLeverageComment
                })).then(async (response) => {
                    if (isMounted()) {
                        if (response?.status === 200) {
                            const data = await response.json()
                            setDataPerSymbolAccountPair(prevData => ({
                                ...prevData,
                                [pair]: {
                                    ...data,
                                    message: 'Leverage updated'
                                }
                            }))
                        } else {
                            const errorMessage = await response.text()
                            throw new Error(errorMessage)
                        }
                    }
                })
                .catch((error) => {
                    if (isMounted()) {
                        _updateSymbolAccountPairMessage({
                            symbol,
                            account: accountName,
                            message: error.toString()
                        })
                    }
                })

                await new Promise(resolve => setTimeout(resolve, 100))
            }
        }
    })

    const handleClickBatchUpdateBybitRiskId = useEvent(async () => {
        if (areAllValuesNonEmpty([symbol, riskId]) && !_.isEmpty(accounts)) {
            for await (const accountName of accounts) {
                if (!isMounted()) {
                    break
                }

                const pair = `${symbol}-${accountName}`
                _updateSymbolAccountPairMessage({
                    symbol,
                    account: accountName,
                    message: `Updating Risk ID...`
                })
    
                dispatch(updateBybitRiskId({
                    accountName,
                    symbol,
                    riskId,
                    comment: updateBybitRiskIdComment
                })).then(async (response) => {
                    if (isMounted()) {
                        if (response?.status === 200) {
                            const data = await response.json()
                            setDataPerSymbolAccountPair(prevData => ({
                                ...prevData,
                                [pair]: {
                                    ...data,
                                    message: 'Risk ID updated'
                                }
                            }))
                        } else {
                            const errorMessage = await response.text()
                            throw new Error(errorMessage)
                        }
                    }
                }).catch((error) => {
                    if (isMounted()) {
                        _updateSymbolAccountPairMessage({
                            symbol,
                            account: accountName,
                            message: error.toString()
                        })
                    }
                })

                await new Promise(resolve => setTimeout(resolve, 100))
            }
        }
    })

    function Criteria () {
        return (
            <div className='leverage-management--criteria'>
                <div>
                    <label>{'Symbol'}</label>
                    <div onClick={(e) => { e.stopPropagation() }}>
                        <SearchSelect
                            options={symbolOptions}
                            value={symbol}
                            onChange={(_newOption) => {
                                const { exchangeName: _newExchangeName } = getSymbolAttributeByName(_newOption.value)
                                if (_newExchangeName !== exchangeName) {
                                    setAccounts([])   
                                }
                                setSymbol(_newOption.value)
                            }} />
                    </div>
                </div>
                <div>
                    <label>{'Accounts'}</label>
                    <Popup
                        className='leverage-management--criteria--accounts--popup'
                        on={'click'}
                        trigger={
                            <div className='leverage-management--criteria--accounts--trigger'>
                                <div>{_.isEmpty(accounts) ? 'None' : accounts.join(', ')}</div>
                                <span>{`(${_.size(accounts)})`}</span>
                            </div>
                        }>
                        <div className='leverage-management--criteria--accounts'
                            onClick={(e) => { e.stopPropagation() }}>
                            <MultipleAccountSelector
                                filteredExchangeNames={[exchangeName]}
                                accountNames={accounts}
                                onChangeAccountNames={(_newAccounts) => { setAccounts(_newAccounts) }} />
                        </div>
                    </Popup>
                </div>
            </div>
        )
    }

    function BatchLeverageUpdate () {
        return (
            <Popup
                key={leverageUpdatePopupId}
                className='leverage-management--batch-leverage-update--popup'
                on={'click'}
                disabled={_.size(accounts) === 0}
                trigger={
                    <button
                        disabled={_.size(accounts) === 0}
                        className='leverage-management--batch-leverage-update--trigger'>
                        {'Batch Leverage Update'}
                    </button>
                }
                onOpen={() => {
                    setLeverage('')
                    setUpdateLeverageComment('')
                }}>
                <div className='leverage-management--batch-leverage-update'>
                    <div className='leverage-management--batch-leverage-update--inputs'>
                        <div>
                            <label>{'Leverage'}</label>
                            <input
                                type={'number'}
                                min={0}
                                autoFocus
                                value={leverage ?? ''}
                                onChange={(e) => {
                                    const _newValue = e.target.value
                                    setLeverage(!_.isEmpty(_newValue) ? (exchangeName === EXCHANGES.BNBFUTA ? parseInt(_newValue) : _newValue) : '')
                                }} />
                        </div>
                        <div>
                            <label>{'Comment'}</label>
                            <textarea
                                spellCheck={false}
                                value={updateLeverageComment}
                                onChange={(e) => { setUpdateLeverageComment(_.replace(e.target.value, /[^a-zA-Z0-9.,\s]/, '')) }} />
                        </div>
                    </div>
                    <button
                        disabled={_.size(accounts) === 0 || leverage === ''}
                        onClick={() => {
                            setLeverageUpdatePopupId(leverageUpdatePopupId + 1)
                            handleClickBatchUpdateLeverage()
                        }}>
                        {`Update ${_.size(accounts)} Leverage`}
                    </button>
                </div>
            </Popup>
        )
    }

    function BatchBybitRiskIdUpdate () {
        return (
            <Popup
                key={bybitRiskIdUpdatePopupId}
                className='leverage-management--batch-bybit-risk-id-update--popup'
                on={'click'}
                disabled={_.size(accounts) === 0}
                trigger={
                    <button className='leverage-management--batch-bybit-risk-id-update--trigger'>{'Batch Risk ID Update'}</button>
                }
                onOpen={() => {
                    setRiskId(null)
                    setUpdateBybitRiskIdComment('')
                }}>
                <div className='leverage-management--batch-bybit-risk-id-update'>
                    <div className='leverage-management--batch-bybit-risk-id-update--inputs'>
                        <div>
                            <label>{'Risk ID'}</label>
                            <input
                                type={'number'}
                                min={1}
                                max={2000}
                                autoFocus
                                value={riskId ?? ''}
                                onChange={(e) => {
                                    const _newValue = e.target.value
                                    setRiskId(!_.isEmpty(_newValue) ? _.clamp(_newValue, 1, 2000) : '')
                                }} />
                        </div>
                        <div>
                            <label>{'Comment'}</label>
                            <textarea
                                spellCheck={false}
                                value={updateBybitRiskIdComment}
                                onChange={(e) => { setUpdateBybitRiskIdComment(e.target.value) }} />
                        </div>
                    </div>
                    <button
                        disabled={_.size(accounts) === 0 || !_.isNumber(riskId)}
                        onClick={() => {
                            setBybitRiskIdUpdatePopupId(bybitRiskIdUpdatePopupId + 1)
                            handleClickBatchUpdateBybitRiskId()
                        }}>
                        {`Update ${_.size(accounts)} Risk ID`}
                    </button>
                </div>
            </Popup>
        )
    }

    function SymbolTable () {
        return (
            <div className='leverage-management--table--wrapper'>
                <AutoSizer>
                    {({ width, height }) => (
                        <Table
                            className='leverage-management--table'
                            headerClassName={'leverage-management--table--header'}
                            headerHeight={27}
                            width={Math.max(width, 1120)}
                            height={height}
                            rowCount={tableRows.length}
                            rowGetter={({ index }) => tableRows[index]}
                            rowClassName={({ index }) => { 
                                return 'leverage-management--table--row' + (index % 2 === 1 ? ' odd-row' : ' even-row')
                            }}
                            rowHeight={45}
                            overscanRowCount={10}
                            noRowsRenderer={() => (<div className='leverage-management--table--no-content'>{'No contents.'}</div> )}>
                            <Column dataKey={'symbol'}
                                label={`Symbol`}
                                width={320}
                                flexGrow={1}
                                flexShrink={1} />
                            <Column dataKey={'account'}
                                label={`Account`}
                                width={200}
                                flexGrow={1}
                                flexShrink={1} />
                            <Column
                                dataKey={'leverage'}
                                width={220}
                                flexGrow={0}
                                flexShrink={0}
                                headerRenderer={() => {
                                    return (
                                        <>
                                            <span>{'Leverage'}</span>
                                            {BatchLeverageUpdate()}
                                        </>
                                    )
                                }} />
                            {exchangeName === EXCHANGES.BYBIT &&
                            <Column
                                dataKey={'riskId'}
                                width={180}
                                flexGrow={0}
                                flexShrink={0}
                                headerRenderer={() => {
                                    return (
                                        <>
                                            <span>{'Risk ID'}</span>
                                            {BatchBybitRiskIdUpdate()}
                                        </>
                                    )
                                }} />}
                            {exchangeName === EXCHANGES.BYBIT &&
                            <Column
                                dataKey={'riskLimitValue'}
                                label={'RISK LIMIT VALUE'}
                                width={200}
                                flexGrow={1}
                                flexShrink={1} />}
                            <Column
                                dataKey={'message'}
                                label={'MESSAGE'}
                                width={600}
                                flexGrow={1}
                                flexShrink={1} />
                        </Table>
                    )}
                </AutoSizer>
            </div>
        )
    }

    return (
        <div className='leverage-management'>
            <div className='leverage-management--header'>
                {Criteria()}
                <div className='leverage-management--header--buttons'>
                    <button
                        disabled={_.isEmpty(symbol) || _.isEmpty(accounts)}
                        onClick={() => { fetchData() }}>
                        {'Fetch Exch Data'}
                    </button>
                </div>
            </div>
            {SymbolTable()}
        </div>
    )
}

LeverageManagement.propTypes = {
    symbolItems: PropTypes.object.isRequired
}

function mapStateToProps(state) {
    return {
        symbolItems: state.symbol.items
    }
}

export default connect(mapStateToProps)(LeverageManagement)