import { store } from '../store'
import _ from 'lodash'

import { INSTRUMENT_TYPES, getSymbolAttributeByName } from './symbolUtil'

export const getFilteredAccountBalanceBySymbol = ({ accountBalance, symbolItem={}, accountName='' }) => {
    const result = {
        spot: null,
        crossMargin: null,
        cross: null,
        futrue: null,
        swap: null,
        option: null
    }

    if (!_.isEmpty(accountBalance) && !_.isEmpty(symbolItem) && !_.isEmpty(accountName)) {
        const { base, quote, instrumentType, exchangeName } = getSymbolAttributeByName(symbolItem.symbol_name)
        if (exchangeName === 'OKEX') {
            result.cross = _.pickBy(accountBalance.cross, crossAccountBalance => {
                return crossAccountBalance.acct_name === accountName
                    && [base, quote].includes(_.toUpper(crossAccountBalance.coin || ''))
            })
        } else if (instrumentType === INSTRUMENT_TYPES.SPOT) {
            const spotAccountBalances = _.pickBy(accountBalance.spot, spotAccountBalance => {
                return spotAccountBalance.acct_name === accountName
                    && [base, quote].includes(_.toUpper(spotAccountBalance.coin || ''))
            })
            const crossMarginAccountBalances = _.pickBy(accountBalance.crossMargin, crossMarginAccountBalance => {
                return crossMarginAccountBalance.acct_name === accountName
                    && [base, quote].includes(_.toUpper(crossMarginAccountBalance.coin || ''))
            })
            if (!_.isEmpty(spotAccountBalances)) {
                result.spot = spotAccountBalances
            }  
            if (!_.isEmpty(crossMarginAccountBalances)) {
                result.crossMargin = crossMarginAccountBalances
            }
        } else if ([INSTRUMENT_TYPES.FUTURE, INSTRUMENT_TYPES.SWAP].includes(instrumentType)) {
            const futureAccountBalances = _.pickBy(accountBalance.future, futureAccountBalance => {
                return futureAccountBalance.acct_name === accountName
                    && ((['USDT', 'BUSD'].includes(quote) && _.toUpper(futureAccountBalance.coin || '') === quote) 
                        || (quote === 'USD' && _.toUpper(futureAccountBalance.coin || '') === base)
                    )
            })
            const swapAccountBalances = _.pickBy(accountBalance.swap, swapAccountBalance => {
                const coinsToMatch = []
                if (symbolItem.trading_in_notional === '1') {
                    coinsToMatch.push(base)
                } else {
                    coinsToMatch.push(quote)
                }
                return swapAccountBalance.acct_name === accountName && coinsToMatch.includes(_.toUpper(swapAccountBalance.coin || '')) 
            })
            if (!_.isEmpty(futureAccountBalances)) {
                result.future = futureAccountBalances
            }
            if (!_.isEmpty(swapAccountBalances)) {
                result.swap = swapAccountBalances
            }   
        } else if (instrumentType === INSTRUMENT_TYPES.OPTION) {
            const optionAccountBalances = _.pickBy(accountBalance.option, optionAccountBalance => {
                return optionAccountBalance.acct_name === accountName
            })
            if (!_.isEmpty(optionAccountBalances)) {
                result.option = optionAccountBalances
            }
        }
    }
    return result
}

export const getPortfolioNames = () => {
    const { items: accountItems } = store.getState().account
    const portfolioNames = _.map(accountItems, accountItem => accountItem.portfolio_name)
    return _.uniq(portfolioNames)
}

export const getBinanceEquivalentAccountNames = (accountName='') => {
    const accountBinanceExchangeFields = ['binance', 'bnbfuta', 'binancepm']
    const accountNames = [accountName]
    const accountBinanceExchangeField = _.find(accountBinanceExchangeFields, exchangeField => (accountName || '').includes(`${exchangeField}_`))
    if (!_.isNil(accountBinanceExchangeField)) {
        const remainingBinanceExchangeFields = _.without(accountBinanceExchangeFields, accountBinanceExchangeField)
        _.forEach(remainingBinanceExchangeFields, exchangeField => {
            accountNames.push(accountName.replace(`${accountBinanceExchangeField}_`, `${exchangeField}_`))
        })
    }
    return accountNames    
} 