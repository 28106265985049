import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

export default class Toggle extends Component {
    render () {
        const { className, checked, trueText, falseText, disabled, isChanging, changingText, onChange } = this.props
        return (
            <div className={'toggle clearfix ' + (checked ? 'on' : 'off') + (className ? ` ${className}` : '') + (disabled || isChanging ? ' disabled' : '')} onClick={(e) => { 
                if (_.isFunction(onChange) && !disabled && !isChanging && !(e.metaKey || e.ctrlKey || e.shiftKey)) { 
                    onChange(!checked) 
                } 
            }}>
                <div className='toggle--track'>
                    <div className='toggle--button' />
                </div>
                <span className={'toggle--label' + (!_.isBoolean(checked) ? ' is-empty-value' : '')}>
                    {isChanging ? changingText 
                        : checked ? trueText 
                        : _.isBoolean(checked) ? falseText 
                        : 'Invalid'}</span>
            </div>
        )
    }
}

Toggle.propTypes = {
    className: PropTypes.string,
    checked: PropTypes.bool.isRequired,
    trueText: PropTypes.string.isRequired,
    falseText: PropTypes.string.isRequired,
    changingText: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    isChanging: PropTypes.bool,
    onChange: PropTypes.func.isRequired
}

Toggle.defaultProps = {
    trueText: 'TRUE',
    falseText: 'FALSE',
    changingText: 'Waiting',
    isChanging: false,
    disabled: false
}