import _ from 'lodash'
import { secureFetch } from '../../util/util'
import { DEFAULT_SERVER } from '../../configs/config'

export const UPDATE_EXCHANGE_ITEMS = 'UPDATE_EXCHANGE_ITEMS'

export function fetchExchanges () {
    return (dispatch) => {
        dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/exchanges`)).then((response) => {
            if (response.status === 200) {
                response.json().then((body) => {
                    if (body && _.isArray(body.info)) {
                        dispatch({
                            type: UPDATE_EXCHANGE_ITEMS,
                            exchanges: body.info
                        })
                    }
                })
            }
        })
    }
}