import React, { memo, useState } from 'react'
import { useMountedState } from 'react-use'
import useEvent from 'react-use-event-hook'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import moment from 'moment'

import Popup from '../common/popup/Popup'
import ProfileSnapshotEditor, { MODE } from './ProfileSnapshotEditor'
import { deleteProfileSnapshot } from './profileAction'

const ProfileSnapshot = ({ profileItem, snapshot, onClickApply, onUpdated }) => {

    const dispatch = useDispatch()
    const isMounted = useMountedState()

    const [updatePopupId, setUpdatePopupId] = useState(0)
    const [isDeleting, setIsDeleting] = useState(false)

    const { _id, title, comment, lastUpdateTime, lastUpdateBy } = snapshot || {}

    const handleClickDelete = useEvent(() => {
        if (!isDeleting) {
            setIsDeleting(true)
            dispatch(deleteProfileSnapshot(_id))
            .then(() => {
                onUpdated()
            })
            .finally(() => {
                if (isMounted()) {
                    setIsDeleting(false)
                }
            })
        }
    })

    return (
        <div className='profile-snapshot'>
            <div className='profile-snapshot--header'>
                <div className='profile-snapshot--header--main'>
                    <div className='profile-snapshot--id'>{`ID: ${_id}`}</div>
                    <div className='profile-snapshot--title'>{title}</div>
                </div>
                <div className='profile-snapshot--meta-data'>
                    <div className='profile-snapshot--update-time'>{moment(lastUpdateTime).format('YYYY-MM-DD HH:mm')}</div>
                    <div className='profile-snapshot--update-by'>{lastUpdateBy}</div>
                </div>
            </div>
            <div className='profile-snapshot--comment'>{comment}</div>
            <div className='profile-snapshot--buttons'>
                <button className='profile-snapshot--button apply' onClick={() => { onClickApply() }}>{'Apply'}</button>
                <Popup className='profile-snapshot--update-popup'
                    key={updatePopupId}
                    on={'click'}
                    trigger={<button className='profile-snapshot--button update'>{'Update'}</button>}>
                    <div onClick={(e) => { e.stopPropagation() }}>
                        <div className='profile-snapshot--update-popup--message'>
                            {`Save the current configuration to this snapshot`}
                        </div>
                        <ProfileSnapshotEditor
                            profileItem={profileItem}
                            defaultTile={title}
                            defaultComment={comment}
                            mode={MODE.UPDATE_SNAPSHOT}
                            snapshotId={_id}
                            onSaved={() => {
                                setUpdatePopupId(updatePopupId+1)
                                onUpdated()
                            }} />
                    </div>
                </Popup>
                <Popup className={'profile-snapshot--delete-popup'}
                    on={'click'}
                    trigger={<button className='profile-snapshot--button delete'>{'Delete'}</button>}>
                    <div onClick={(e) => { e.stopPropagation() }}>
                        <div className='profile-snapshot--delete-popup--message'>
                            {`Confirm to remove this snapshot`}
                        </div>
                        <button onClick={handleClickDelete}>{'Confirm'}</button>
                    </div>
                </Popup>
            </div>
        </div>
    )
}

ProfileSnapshot.propTypes = {
    profileItem: PropTypes.object.isRequired,
    snapshot: PropTypes.object.isRequired,
    onClickApply: PropTypes.func.isRequired,
    onUpdated: PropTypes.func.isRequired
}

ProfileSnapshot.propTypes = {
    onClickApply: () => {},
    onUpdated: () => {}
}

export default memo(ProfileSnapshot)