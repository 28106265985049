import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types' 
import _ from 'lodash'

import SpotAccountBalanceItem from './SpotAccountBalanceItem'
import MarginAccountBalanceItem from './MarginAccountBalanceItem'
import CrossMarginAccountBalanceItem from './CrossMarginAccountBalanceItem'
import FutureAccountBalanceItem from './FutureAccountBalanceItem'
import SwapAccountBalanceItem from './SwapAccountBalanceItem'
import AccountAssetItem from './AccountAssetItem'
import CrossAccountBalanceItem from './CrossAccountBalanceItem'
import OptionAccountBalanceItem from './OptionAccountBalanceItem'

class AccountBalanceItem extends Component {
    
    renderSpotBalances (spotBalances, shouldShowAccountName=false) {
        const { shouldShowDetails, shouldShowAccountTypeTab, shouldHideAccountTypeTitle } = this.props
        return (
            <div className='account-balance--spot'>
                {!shouldHideAccountTypeTitle && <div className='account-balance--spot--title'>{'Spot'}</div>}
                <div className='account-balance--spot--items'>
                    {_.map(spotBalances, (spotBalance, index) => (
                        <div className='account-balance--spot--item' key={index}>
                            <SpotAccountBalanceItem 
                                spotAccountBalance={spotBalance}
                                shouldShowAccountTypeTag={shouldShowAccountTypeTab} 
                                shouldShowAccountName={shouldShowAccountName}
                                shouldShowDetail={shouldShowDetails} />
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    renderMarginBalances (marginBalances, shouldShowAccountName=false) {
        const { shouldShowDetails, shouldShowAccountTypeTab, shouldHideAccountTypeTitle } = this.props
        return (
            <div className='account-balance--margin'>
                {!shouldHideAccountTypeTitle && <div className='account-balance--margin--title'>{'Margin'}</div>}
                <div className='account-balance--margin--items'>
                    {_.map(marginBalances, (marginAccountBalance, index) => {
                        return (
                            <div className='account-balance--margin--item' key={index}>
                                <MarginAccountBalanceItem 
                                    marginAccountBalance={marginAccountBalance}
                                    shouldShowAccountTypeTag={shouldShowAccountTypeTab} 
                                    shouldShowAccountName={shouldShowAccountName}
                                    shouldShowDetail={shouldShowDetails} />
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    renderCrossMarginBalances (crossMarginBalances, shouldShowAccountName=false) {
        const { shouldShowDetails, shouldShowAccountTypeTab, shouldHideAccountTypeTitle } = this.props
        return (
            <div className='account-balance--cross-margin'>
                {!shouldHideAccountTypeTitle && <div className='account-balance--cross-margin--title'>{'Cross Margin'}</div>}
                <div className='account-balance--cross-margin--items'>
                    {_.map(crossMarginBalances, (crossMarginAccountBalance, index) => {
                        return (
                            <div className='account-balance--cross-margin--item' key={index}>
                                <CrossMarginAccountBalanceItem 
                                    crossMarginAccountBalance={crossMarginAccountBalance}
                                    shouldShowAccountTypeTag={shouldShowAccountTypeTab} 
                                    shouldShowAccountName={shouldShowAccountName}
                                    shouldShowDetail={shouldShowDetails} />
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    renderCrossBalances (crossBalances, shouldShowAccountName=false) {
        const { shouldShowDetails, shouldShowAccountTypeTab, shouldHideAccountTypeTitle } = this.props
        return (
            <div className='account-balance--cross'>
                {!shouldHideAccountTypeTitle && <div className='account-balance--cross--title'>{'Cross'}</div>}
                <div className='account-balance--cross--items'>
                    {_.map(crossBalances, (crossAccountBalance, index) => {
                        return (
                            <div className='account-balance--cross--item' key={index}>
                                <CrossAccountBalanceItem 
                                    crossAccountBalance={crossAccountBalance}
                                    shouldShowAccountTypeTag={shouldShowAccountTypeTab} 
                                    shouldShowAccountName={shouldShowAccountName}
                                    shouldShowDetail={shouldShowDetails} />
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    renderFutureBalances (futureBalances, shouldShowAccountName=false) {
        const { shouldShowDetails, shouldShowAccountTypeTab, shouldHideAccountTypeTitle } = this.props
        return (
            <div className='account-balance--future'>
                {!shouldHideAccountTypeTitle && <div className='account-balance--future--title'>{'Future'}</div>}
                <div className='account-balance--future--items'>
                    {_.map(futureBalances, (futureBalance, index) => {
                        return (
                            <div className='account-balance--future--item' key={index}>
                                <FutureAccountBalanceItem 
                                    futureAccountBalance={futureBalance}
                                    shouldShowAccountTypeTag={shouldShowAccountTypeTab}
                                    shouldShowAccountName={shouldShowAccountName} 
                                    shouldShowDetail={shouldShowDetails}/>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    renderSwapBalances (swapBalances, shouldShowAccountName=false) {
        const { shouldShowDetails, shouldShowAccountTypeTab, shouldHideAccountTypeTitle } = this.props
        return (
            <div className='account-balance--swap'>
                {!shouldHideAccountTypeTitle && <div className='account-balance--swap--title'>{'Swap'}</div>}
                <div className='account-balance--swap--items'>
                    {_.map(swapBalances, (swapBalance, index) => {
                        return (
                            <div className='account-balance--swap--item' key={index}>
                                <SwapAccountBalanceItem 
                                    swapAccountBalance={swapBalance}
                                    shouldShowAccountTypeTag={shouldShowAccountTypeTab}
                                    shouldShowAccountName={shouldShowAccountName} 
                                    shouldShowDetail={shouldShowDetails} />
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    renderOptionBalances (optionBalances, shouldShowAccountName=false) {
        const { shouldShowDetails, shouldShowAccountTypeTab, shouldHideAccountTypeTitle } = this.props
        return (
            <div className='account-balance--option'>
                {!shouldHideAccountTypeTitle && <div className='account-balance--option--title'>{'Option'}</div>}
                <div className='account-balance--option--items'>
                    {_.map(optionBalances, (optionBalance, index) => {
                        return (
                            <div className='account-balance--option--item' key={index}>
                                <OptionAccountBalanceItem 
                                    optionAccountBalance={optionBalance}
                                    shouldShowAccountTypeTag={shouldShowAccountTypeTab}
                                    shouldShowAccountName={shouldShowAccountName} 
                                    shouldShowDetail={shouldShowDetails} />
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    render () {
        const { accountBalance, accountAsset, accountName, filteredAccountBalance,
            shouldHideSpot, shouldHideCrossMargin, shouldHideCross, shouldHideFuture, shouldHideSwap, shouldHideOption, emptyMessage, shouldHideAccountName } = this.props
        const accountAssetItem = accountAsset[accountName]

        let spotBalances, crossMarginBalances, crossBalances, futureBalances, swapBalances, optionBalances
        if (!_.isNil(filteredAccountBalance)) {
            spotBalances = filteredAccountBalance.spot || {}
            crossMarginBalances = filteredAccountBalance.crossMargin || {}
            crossBalances = filteredAccountBalance.cross || {}
            futureBalances = filteredAccountBalance.future || {}
            swapBalances = filteredAccountBalance.swap || {}
            optionBalances = filteredAccountBalance.option || {}
        } else {
            spotBalances = _.filter(accountBalance.spot, { acct_name: accountName })
            crossMarginBalances = _.filter(accountBalance.crossMargin, { acct_name: accountName })
            crossBalances = _.filter(accountBalance.cross, { acct_name: accountName })
            futureBalances = _.filter(accountBalance.future, { acct_name: accountName })
            swapBalances = _.filter(accountBalance.swap, { acct_name: accountName })
            optionBalances = _.filter(accountBalance.option, { acct_name: accountName })
        }

        const hasContent = (!shouldHideSpot && !_.isEmpty(spotBalances))
            || (!shouldHideCrossMargin && !_.isEmpty(crossMarginBalances))
            || (!shouldHideCross && !_.isEmpty(crossBalances))
            || (!shouldHideFuture && !_.isEmpty(futureBalances))
            || (!shouldHideSwap && !_.isEmpty(swapBalances))
            || (!shouldHideOption && !_.isEmpty(optionBalances))
    
        return hasContent ? (
            <div className='account-balance'>
                {!shouldHideAccountName && <div className='account-balance--name'>{accountName}</div>}
                {!shouldHideSpot && !_.isEmpty(spotBalances) && this.renderSpotBalances(spotBalances, !_.isNil(filteredAccountBalance))}
                {!shouldHideCrossMargin && !_.isEmpty(crossMarginBalances) && this.renderCrossMarginBalances(crossMarginBalances, !_.isNil(filteredAccountBalance))}
                {!shouldHideCross && !_.isEmpty(crossBalances) && this.renderCrossBalances(crossBalances, !_.isNil(filteredAccountBalance))}
                {!shouldHideFuture && !_.isEmpty(futureBalances) && this.renderFutureBalances(futureBalances, !_.isNil(filteredAccountBalance))}
                {!shouldHideSwap && !_.isEmpty(swapBalances) && this.renderSwapBalances(swapBalances, !_.isNil(filteredAccountBalance))}
                {!shouldHideOption && !_.isEmpty(optionBalances) && this.renderOptionBalances(optionBalances, !_.isNil(filteredAccountBalance))}
            </div>
        ) 
        : !_.isNil(accountAssetItem) ? <AccountAssetItem 
            accountAssetItem={accountAssetItem} />
        : (emptyMessage ? <div className='account-balance--empty-message'>{emptyMessage}</div> : null)
    }
}

AccountBalanceItem.propTypes = {
    accountName: PropTypes.string.isRequired,
    filteredAccountBalance: PropTypes.shape({
        spot: PropTypes.object,
        crossMargin: PropTypes.object,
        cross: PropTypes.object,
        future: PropTypes.object,
        swap: PropTypes.object,
        option: PropTypes.object
    }),
    shouldHideSpot: PropTypes.bool,
    shouldHideCrossMargin: PropTypes.bool,
    shouldHideCross: PropTypes.bool,
    shouldHideFuture: PropTypes.bool,
    shouldHideSwap: PropTypes.bool,
    shouldHideOption: PropTypes.bool,
    shouldShowDetails: PropTypes.bool,
    emptyMessage: PropTypes.string,
    shouldShowAccountTypeTab: PropTypes.bool,
    shouldHideAccountName: PropTypes.bool,
    shouldHideAccountTypeTitle: PropTypes.bool,
    
    accountBalance: PropTypes.object.isRequired,
    accountAsset: PropTypes.object.isRequired
}

AccountBalanceItem.defaultProps = {
    filteredAccountBalance: null
}

function mapStateToProps (state) {
    return {
        accountBalance: state.account.balance,
        accountAsset: state.account.asset
    }
}

export default connect(mapStateToProps)(AccountBalanceItem)


