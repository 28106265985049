import React, { memo, useEffect, useMemo, useState } from 'react'
import useEvent from 'react-use-event-hook'
import { useDispatch } from 'react-redux'
import { useLocalStorage, useMountedState } from 'react-use'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { parseUnits, isAddress } from 'viem'
import BigNumber from 'bignumber.js'
import _ from 'lodash'

import { FaTimes } from 'react-icons/fa'
import Popup from '../common/popup/Popup'

import { getDefaultChain } from '../../wagmiConfig'
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector'
import { selectBlockchainTokenBalances, selectBlockchainTokens, selectWallet } from './blockchainReducer'
import { ABI as VAULT_ABI } from './ABIs/Vault'
import { fetchTokenBalance, fetchTokensIfNeed } from './blockchainAction'
import { sUSD_ADDRESS } from './blockchainConfig'
import { formattedHex, formattedTokenAmount } from '../../util/formatUtil'
import { sendTransactionWithWallet } from './blockchainThunk'
import { parseABI } from './blockchainUtil'

const _countDecimalPlaces = (value) => {
    const fractions = _.toString(value).split('.')
    return fractions.length === 2 ? fractions[1].length : 0
}

function VaultTokens ({ vaultAddress }) {

    const dispatch = useDispatch()
    const isMounted = useMountedState()

    const { address: walletAddress } = useShallowEqualSelector(state => selectWallet(state)) || {}
    const tokens = useShallowEqualSelector(state => selectBlockchainTokens(state))
    const tokenBalances = useShallowEqualSelector(state => selectBlockchainTokenBalances(state))

    const [vaultTokens, setVaultTokens] = useLocalStorage('vault-tokens', [])
    const [tokenAddress, setTokenAddress] = useState('')
    const [amount, setAmount] = useState('')
    const [errorMessagePerToken, setErrorMessagePerToken] = useState({})
    const [errorMessage, setErrorMessage] = useState('')
    const [isSendingWithdrawTokenTx, setIsSendingWithdrawTokenTx] = useState(false)
    const [isSendingWithdrawEverythingTx, setIsSendingWithdrawEverythingTx] = useState(false)

    const tokenAddresses = useMemo(() => {
        return _.compact(_.uniq(_.concat([sUSD_ADDRESS], vaultTokens)))
    }, [vaultTokens])

    const defaultChain = getDefaultChain()
    const _amount = BigNumber(amount || 0)

    const tokensWithPositveVaultBalance = useMemo(() => {
        return _.filter(tokenAddresses, tokenAddress => BigNumber(_.get(tokenBalances, `${tokenAddress}.${vaultAddress}`) || 0).gt(0))
    }, [tokenAddresses, tokenBalances])

    const fetchTokenBalances = useEvent(() => {
        if (!_.isEmpty(tokenAddresses) && isAddress(vaultAddress)) {
            _.forEach(tokenAddresses, tokenAddress => {
                dispatch(fetchTokenBalance(tokenAddress, vaultAddress))
                if (!_.isEmpty(walletAddress)) {
                    dispatch(fetchTokenBalance(tokenAddress, walletAddress))
                }
            })
        }
    })

    useEffect(() => {
        if (!_.isEmpty(tokenAddresses)) {
            dispatch(fetchTokensIfNeed(tokenAddresses))
            .then(() => {
                fetchTokenBalances()
            })
        }
    }, [tokenAddresses])

    useEffect(() => {
        if (!_.isEmpty(walletAddress)) {
            fetchTokenBalances()
        }
        const polling = setInterval(() => {
            fetchTokenBalances()
        }, 10000)
        return () => { clearInterval(polling) }
    }, [walletAddress, vaultAddress])

    const handleClickAddToken = useEvent(() => {
        if (!_.isEmpty(tokenAddress)) {
            setVaultTokens(_.concat(tokenAddresses, tokenAddress))
        }
    })

    const handleClickWithdraw = useEvent((tokenAddress) => {
        const { decimals } = _.get(tokens, tokenAddress) || {}
        if (Number(decimals) > 0 && _amount.gt(0) && !isSendingWithdrawTokenTx && isAddress(vaultAddress)) {
            setIsSendingWithdrawTokenTx(true)
            dispatch(sendTransactionWithWallet({
                address: vaultAddress,
                abi: parseABI(VAULT_ABI),
                functionName: 'withdraw',
                args: [tokenAddress, parseUnits(amount, decimals)]
            })).catch(error => {
                console.error(`VaultTokens handleClickWithdraw error: `, error)
                if (isMounted()) {
                    setErrorMessagePerToken({
                        ...errorMessagePerToken,
                        [tokenAddress]: error.toString()
                    })
                }
            }).finally(() => {
                if (isMounted()) {
                    setIsSendingWithdrawTokenTx(false)
                }
            })
        }
    })

    const handleClickWithdrawEverything = useEvent(() => {
        if (!_.isEmpty(tokensWithPositveVaultBalance) && !isSendingWithdrawEverythingTx && isAddress(vaultAddress)) {
            setIsSendingWithdrawEverythingTx(true)
            dispatch(sendTransactionWithWallet({
                address: vaultAddress,
                abi: parseABI(VAULT_ABI),
                functionName: 'withdrawEverything',
                args: [tokensWithPositveVaultBalance]
            })).catch(error => {
                console.error(`VaultTokens handleClickWithdrawEverything error: `, error)
                if (isMounted()) {
                    setErrorMessage(error.toString())
                }
            }).finally(() => {
                if (isMounted()) {
                    setIsSendingWithdrawEverythingTx(false)
                }
            })
        }
    })

    return (
        <div className='vault-tokens'>
            <div className='vault-tokens--header'>
                <label>{'Token Balances'}</label>
                <div className='vault-tokens--header--buttons'>
                    <button
                        className='vault-tokens--withdraw-everything-button'
                        disabled={_.isEmpty(tokensWithPositveVaultBalance) || isSendingWithdrawEverythingTx}
                        onClick={() => { handleClickWithdrawEverything() }}>
                        {isSendingWithdrawEverythingTx ? 'Waiting' : 'Withdraw Everything'}
                    </button>
                    <Popup
                        className='vault-tokens--add-popup'
                        on={'click'}
                        trigger={<button className='vault-tokens--add-token-button'>{'Add Token'}</button>}
                        onOpen={() => { setTokenAddress('') }}>
                        <input
                            type={'text'} 
                            placeholder='Token address'
                            autoFocus
                            spellCheck={false}
                            value={tokenAddress}
                            onChange={(e) => { setTokenAddress(e.target.value) }} />
                        <button
                            disabled={_.isEmpty(tokenAddress) || !isAddress(tokenAddress)}
                            onClick={() => { handleClickAddToken() }}>
                            {'Add'}
                        </button>
                    </Popup>
                </div>
            </div>
            <div className='vault-tokens--table'>
                <table>
                    <thead>
                        <tr>
                            <th>{'Address'}</th>
                            <th>{'Symbol'}</th>
                            <th>{'Decimals'}</th>
                            <th>{'Vault Balance'}</th>
                            <th>{'Wallet Balance'}</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {_.map(tokenAddresses, tokenAddress => {
                            const deicmals = _.get(tokens, `${tokenAddress}.decimals`, 18)
                            const vaultBalance = _.get(tokenBalances, `${tokenAddress}.${vaultAddress}`)
                            const walletBalance = _.get(tokenBalances, `${tokenAddress}.${walletAddress}`)
                            const errorMessage = _.get(errorMessagePerToken, tokenAddress)
                            return (
                                <tr key={tokenAddress}>
                                    <td>
                                        <Link to={`${defaultChain?.blockExplorers?.etherscan?.url}/token/${tokenAddress}`}
                                            target='_blank'
                                            title={tokenAddress}>
                                            {formattedHex(tokenAddress)}
                                        </Link>
                                    </td>
                                    <td>{_.get(tokens, `${tokenAddress}.symbol`) || ''}</td>
                                    <td>{_.get(tokens, `${tokenAddress}.decimals`) || ''}</td>
                                    <td>{formattedTokenAmount({ amount: vaultBalance })}</td>
                                    <td>{formattedTokenAmount({ amount: walletBalance })}</td>
                                    <td>
                                        <Popup 
                                            className='vault-tokens--withdraw-popup'
                                            on={'click'}
                                            trigger={<button className='vault-tokens--withdraw-popup--trigger'>{'Withdraw'}</button>}
                                            onOpen={() => {
                                                if (!_.isEmpty(errorMessage)) {
                                                    setErrorMessagePerToken(_.omit(errorMessagePerToken, tokenAddress))
                                                }
                                                setAmount('')
                                            }}>
                                            <div className='vault-tokens--withdraw-popup--input-wrapper'>
                                                <input type={'number'}
                                                    placeholder='Amount'
                                                    autoFocus
                                                    min={0} 
                                                    value={amount}
                                                    onKeyDown={(e) => { ['E', 'e', '+', '-'].includes(e.key) && e.preventDefault() }}
                                                    onChange={(e) => { 
                                                        const newValue = e.target.value
                                                        if (!_.isEmpty(errorMessage)) {
                                                            setErrorMessagePerToken(_.omit(errorMessagePerToken, tokenAddress))
                                                        }
                                                        if (_countDecimalPlaces(newValue) <= deicmals) {
                                                            setAmount(newValue)
                                                        }
                                                    }} />
                                                <button className='vault-tokens--withdraw-popup--input-wrapper--max-button'onClick={() => { setAmount(vaultBalance) }}>{'Max'}</button>
                                            </div>
                                            {errorMessage && <div className='vault-tokens--withdraw-popup--error-message'>{errorMessage}</div>}
                                            <button
                                                disabled={_amount.lte(0) || isSendingWithdrawTokenTx}
                                                onClick={() => { handleClickWithdraw(tokenAddress) }}>{isSendingWithdrawTokenTx ? 'Waiting' : 'Confirm'}</button>
                                        </Popup>
                                        {tokenAddress !== sUSD_ADDRESS &&
                                        <button className='vault-tokens--withdraw-popup--remove-button'
                                            onClick={() => { setVaultTokens(_.without(tokenAddress)) }}><FaTimes /></button>}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            {errorMessage &&
            <div className='vault-tokens--error-message'>
                <p>{errorMessage}</p>
                <button onClick={() => { setErrorMessage('') }}><FaTimes /></button>
            </div>}
        </div>
    )
}

VaultTokens.propTypes = {
    vaultAddress: PropTypes.string.isRequired
}

export default memo(VaultTokens)