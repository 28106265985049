import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { arrayMoveImmutable } from 'array-move'
import dotProp from 'dot-prop-immutable'
import _ from 'lodash'

import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { FiPlus } from 'react-icons/fi'

import WorkspaceTab from './WorkspaceTab'

const SortableItem = SortableElement(({ workspace, shouldHighlight, onClick=()=>{}, onChangeName=()=>{}, onRemove=()=>{} }) => {
    return (
        <WorkspaceTab 
            workspace={workspace}
            shouldHighlight={shouldHighlight}
            onClick={() => { onClick() }}
            onChangeName={(newName) => { onChangeName(newName) }} 
            onRemove={() => { onRemove() }} />
    )
})

const SortableList = SortableContainer(({ workspaces, selectedWorkspaceId, onClickTab=()=>{}, onChangeTabName=()=>{}, onRemoveTab=()=>{} }) => {
    return (
        <div className='workspace-tabs--list'>
            {workspaces.map((workspace, index) => {
                return (
                    <SortableItem
                        key={workspace.id}
                        index={index}
                        workspace={workspace} 
                        shouldHighlight={selectedWorkspaceId === workspace.id} 
                        onClick={() => { onClickTab(workspace.id) }} 
                        onChangeName={(newName) => { onChangeTabName(workspace.id, newName) }}
                        onRemove={() => { onRemoveTab(workspace.id) }} />
                )
            })}
        </div>
    )
})

export default class WorkspaceTabs extends Component {

    render () {
        const { workspaces, selectedWorkspaceId, onChangeWorkspaces, onSelectWorkspace, onClickAdd } = this.props
        return (
            <div className='workspace-tabs'>
                <SortableList 
                    axis={'x'}
                    pressDelay={200}
                    workspaces={workspaces}  
                    selectedWorkspaceId={selectedWorkspaceId}
                    onClickTab={(workSpaceId) => { onSelectWorkspace(workSpaceId) }}
                    onChangeTabName={(workspaceId, newName) => {
                        const workspaceIndex = _.findIndex(workspaces, { id: workspaceId })
                        if (workspaceIndex > -1 && !_.isEmpty(newName)) {
                            const newWorkspaces = dotProp.set(workspaces, `${workspaceIndex}.name`, newName)
                            onChangeWorkspaces(newWorkspaces)
                        }
                    }}
                    onRemoveTab={(workspaceId) => {
                        const workspaceIndex = _.findIndex(workspaces, { id: workspaceId })
                        if (workspaceIndex > -1) {
                            const newWorkspaces = dotProp.delete(workspaces, workspaceIndex)
                            onChangeWorkspaces(newWorkspaces)
                        }
                    }}
                    onClickAddButton={() => { onClickAdd() }}
                    onSortEnd={({ oldIndex, newIndex }) => {
                        onChangeWorkspaces(arrayMoveImmutable(workspaces, oldIndex, newIndex))
                    }} />
                <button className='workspace-tabs--add-button' title={'New Workspace'}
                    onClick={() => { onClickAdd() }}>
                    <FiPlus />
                </button>
            </div>
        )
    }
}

WorkspaceTabs.propTypes = {
    workspaces: PropTypes.array.isRequired,
    selectedWorkspaceId: PropTypes.string,
    onSelectWorkspace: PropTypes.func,
    onClickAdd: PropTypes.func,
    onChangeWorkspaces: PropTypes.func
}

WorkspaceTabs.defaultProps = {
    workspaces: [],
    selectedWorkspaceId: 0,
    onSelectWorkspace: () => {},
    onClickAdd: () => {},
    onChangeWorkspaces: () => {}
}
