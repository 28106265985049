import React, { memo, useEffect, useMemo, useState } from 'react'
import useEvent from 'react-use-event-hook'
import { useDispatch } from 'react-redux'
import { useMountedState } from 'react-use'
import _ from 'lodash'

import { FaAngleDown, FaAngleUp, FaArrowRight } from 'react-icons/fa6'
import SearchSelect from '../common/searchSelect/SearchSelect'

import { getPortfolioNames } from '../../util/accountUtil'
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector'
import { getTokens } from '../../util/symbolUtil'
import { createInternalInvestmentFlow, fetchInternalInvestmentFlows } from './tradingAction'

const MESSAGE_TYPE = {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    WARNING: 'WARNING'
}

function TransferStruct ({ originPortfolio, destinationPortfolio, currency='BTC', quantity, comment='Added via front end', flowOutId, flowInId, date }) {
    return {
        originPortfolio,
        destinationPortfolio,
        currency,
        quantity,
        comment,
        flowOutId,
        flowInId,
        date
    }
}

function PortfolioAssetTransfer () {

    const dispatch = useDispatch()
    const isMounted = useMountedState()
    const internalInvestmentFlows = useShallowEqualSelector(state => _.get(state, 'trading.internalInvestmentFlows'))
    const [shouldShowNewTransfer, setShouldShowNewTransfer] = useState(true)
    const [transfer, setTransfer] = useState(TransferStruct({}))
    const [message, setMessage] = useState({
        type: MESSAGE_TYPE.SUCCESS,
        text: ''
    })
    const [isFetching, setIsFetching] = useState(false)
    const [isSending, setIsSending] = useState(false)
    const symbolItems = useShallowEqualSelector(state => state.symbol?.items)
    const portfolioNames = getPortfolioNames()
    const tokens = getTokens(symbolItems)

    useEffect(() => {
        dispatch(fetchInternalInvestmentFlows())
        const polling = setInterval(() => {
            dispatch(fetchInternalInvestmentFlows())
        }, 60000)
        return () => { clearInterval(polling) }
    }, [])

    const handleClickAddButton = useEvent(() => {
        if (_.isEmpty(transfer.originPortfolio)) {
            setMessage({
                type: MESSAGE_TYPE.ERROR,
                text: 'The origin portfolio is required'
            })
        } else if (_.isEmpty(transfer.destinationPortfolio)) {
            setMessage({
                type: MESSAGE_TYPE.ERROR,
                text: 'The destination portfolio is required'
            })
        } else if (_.isEmpty(transfer.currency)) {
            setMessage({
                type: MESSAGE_TYPE.ERROR,
                text: 'The currency is required'
            })
        } else if (_.isEmpty(transfer.quantity)) {
            setMessage({
                type: MESSAGE_TYPE.ERROR,
                text: 'The amount is required'
            })
        } else if (!isSending) {
            setIsSending(true)
            dispatch(createInternalInvestmentFlow({
                originPortfolio: transfer.originPortfolio,
                destinationPortfolio: transfer.destinationPortfolio,
                currency: transfer.currency,
                quantity: transfer.quantity,
                comment: transfer.comment
            }))
            .then(response => {
                if (isMounted() && response?.status === 200) {
                    setMessage({
                        type: MESSAGE_TYPE.SUCCESS,
                        text: 'Succesfully added record.'
                    })
                }
            })
            .catch(error => {
                console.error(`PortfolioAssetTransfer handleClickAddButton error: `, error)
                if (isMounted()) {
                    setMessage({
                        type: MESSAGE_TYPE.ERROR,
                        text: 'Failed to add record.'
                    })
                }
            })
            .finally(() => {
                if (isMounted()) {
                    setIsSending(false)
                }
            })
        }
    })

    const handleClickFetchButton = useEvent(() => {
        if (!isFetching) {
            setIsFetching(true)
            dispatch(fetchInternalInvestmentFlows())
            .finally(() => {
                if (isMounted()) {
                    setIsFetching(false)
                }
            })
        }
    })

    const transfers = useMemo(() => {
        const _transfers = []
        const _flowIns = _.filter(internalInvestmentFlows, _flow => _flow.direction === 'in')
        const _sortedFlowIns = _.sortBy(_flowIns, _flow => -Number(_flow.id))

        _.forEach(_sortedFlowIns, _flow => {
            const _flowOut = internalInvestmentFlows[Number(_flow.id) - 1]
            if (!_.isEmpty(_flowOut)
                && _flowOut.direction === 'out'
                && _flowOut.quantity === _flow.quantity
                && _flowOut.currency === _flow.currency
                && _flowOut.product_name !== _flow.product_name) {
                _transfers.push(TransferStruct({
                    originPortfolio: _flowOut.product_name,
                    destinationPortfolio: _flow.product_name,
                    currency: _flowOut.currency,
                    quantity: _flowOut.quantity,
                    comment: _flowOut.comment,
                    flowOutId: _flowOut.id,
                    flowInId: _flow.id,
                    date: _flowOut.date
                }))
            }
        })

        return _transfers

    }, [internalInvestmentFlows])

    function Form () {
        const originPortfolioOptions = _.map(portfolioNames, _portfolioName => ({
            value: _portfolioName,
            name: _portfolioName
        }))
        const destinationPortfolioOptions = _.filter(originPortfolioOptions, _option => _option.value !== transfer.originPortfolio)
        const tokenOptions = _.map(tokens, _token => ({
            value: _token,
            name: _token
        }))

        return (
            <div className='portfolio-asset-transfer--form'>
                <div className='portfolio-asset-transfer--form--input'>
                    <label>{'From'}</label>
                    <SearchSelect
                        options={originPortfolioOptions}
                        value={transfer.originPortfolio}
                        placeholder='Origin Portfolio'
                        onChange={(newOption) => {
                            setTransfer(Object.assign({}, transfer, {
                                originPortfolio: newOption.value,
                                destinationPortfolio: newOption.value === transfer.destinationPortfolio ? null : transfer.destinationPortfolio
                            }))
                            setMessage({
                                type: MESSAGE_TYPE.SUCCESS,
                                text: ''
                            })
                        }} />
                </div>
                <div className='portfolio-asset-transfer--form--input'>
                    <label>{'To'}</label>
                    <SearchSelect
                        options={destinationPortfolioOptions}
                        value={transfer.destinationPortfolio}
                        placeholder='Destination Portfolio'
                        onChange={(newOption) => {
                            setTransfer(Object.assign({}, transfer, {
                                originPortfolio: newOption.value === transfer.originPortfolio ? null : transfer.originPortfolio,
                                destinationPortfolio: newOption.value
                            }))
                            setMessage({
                                type: MESSAGE_TYPE.SUCCESS,
                                text: ''
                            })
                        }} />
                </div>
                <div className='portfolio-asset-transfer--form--input'>
                    <label>{'Currency'}</label>
                    <SearchSelect
                        options={tokenOptions}
                        value={transfer.currency}
                        placeholder='Token'
                        onChange={(newOption) => {
                            setTransfer(Object.assign({}, transfer, { currency: newOption.value }))
                            setMessage({
                                type: MESSAGE_TYPE.SUCCESS,
                                text: ''
                            })
                        }} />
                </div>
                <div className='portfolio-asset-transfer--form--input'>
                    <label>{'Amount'}</label>
                    <input
                        type='number'
                        min={0}
                        spellCheck={false}
                        value={transfer.quantity}
                        placeholder='0.0'
                        onChange={(e) => {
                            setTransfer(Object.assign({}, transfer, { quantity: e.target.value }))
                            setMessage({
                                type: MESSAGE_TYPE.SUCCESS,
                                text: ''
                            })
                        }} />
                </div>
                <div className='portfolio-asset-transfer--form--input'>
                    <label>{'Comment'}</label>
                    <textarea
                        type='text'
                        spellCheck={false}
                        value={transfer.comment}
                        placeholder='Add remarks ...'
                        onChange={(e) => {
                            setTransfer(Object.assign({}, transfer, { comment: e.target.value }))
                            setMessage({
                                type: MESSAGE_TYPE.SUCCESS,
                                text: ''
                            })
                        }} />
                </div>
                <div className='portfolio-asset-transfer--form--footer'>
                    {!_.isEmpty(message.text) && <div className={`portfolio-asset-transfer--form--message ${message.type}`}>{message.text}</div>}
                    <button className='portfolio-asset-transfer--form--confirm-button'
                        disabled={isSending}
                        onClick={() => { handleClickAddButton() }}>
                        {isSending ? 'Adding...' : 'Add Transfer Record'}
                    </button>
                </div>
            </div>
        )
    }

    function Transfers () {
        return (
            <div className='portfolio-asset-transfer--transfers'>
                {_.map(transfers, (_transfer, index) => {
                    return (
                        <div className='portfolio-asset-transfer--transfers--item' key={index}>
                            <div className='portfolio-asset-transfer--transfers--item--date'>{_transfer.date}</div>
                            <div className='portfolio-asset-transfer--transfers--item--diagram'>
                                <div className='portfolio-asset-transfer--transfers--item--diagram--item'>
                                    <div className='portfolio-asset-transfer--transfers--item--diagram--item--header'>
                                        <label>{'Origin'}</label>
                                        <span>{`(Flow ID: ${_transfer.flowOutId})`}</span>
                                    </div>
                                    <span>{_transfer.originPortfolio}</span>
                                </div>
                                <FaArrowRight />
                                <div className='portfolio-asset-transfer--transfers--item--diagram--item'>
                                    <div className='portfolio-asset-transfer--transfers--item--diagram--item--header'>
                                        <label>{'Destination'}</label>
                                        <span>{`(Flow ID: ${_transfer.flowInId})`}</span>
                                    </div>
                                    <span>{_transfer.destinationPortfolio}</span>
                                </div>
                            </div>
                            <div className='portfolio-asset-transfer--transfers--item--quantity'>
                                <span>{_transfer.quantity}</span>
                                <label>{_transfer.currency}</label>
                            </div>
                            {!_.isEmpty(_transfer.comment) && <div className='portfolio-asset-transfer--transfers--item--comment'>{_transfer.comment}</div>}
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <div className='portfolio-asset-transfer'>
            <section className='portfolio-asset-transfer--section'>
                <div className='portfolio-asset-transfer--section--header' onClick={() => { setShouldShowNewTransfer(!shouldShowNewTransfer) }}>
                    <div className='portfolio-asset-transfer--section--title'>{'New Transfer Record'}</div>
                    {shouldShowNewTransfer ? <FaAngleUp /> : <FaAngleDown />}
                </div>
                {shouldShowNewTransfer && <div className='portfolio-asset-transfer--section--main'>
                    <div className='portfolio-asset-transfer--section--description'>
                        {`This allows you to manually add records of asset transfers within the system. It creates two separate entries in the database for incoming and outgoing transactions, serving the purpose of maintaining accurate financial records. `}
                    </div>
                    {Form()}
                </div>}
            </section>
            <section className='portfolio-asset-transfer--section records'>
                <div className='portfolio-asset-transfer--section--header'>
                    <div className='portfolio-asset-transfer--section--title'>{'Records'}</div>
                    <button className='portfolio-asset-transfer--fetch-button'
                        disabled={isFetching}
                        onClick={() => { handleClickFetchButton() }}>
                        {isFetching ? `Fetching...` : `Fetch Latest`}
                    </button>
                </div>
                <div className='portfolio-asset-transfer--section--main'>{Transfers()}</div>
            </section>
        </div>
    )
}

export default memo(PortfolioAssetTransfer)