import _ from 'lodash'

export const SIDES = {
    BID: 'BID',
    ASK: 'ASK'
}

export const TRANSFORMS = {
    sm: 'sm',
    orig: 'orig'
}

export const PARAM_TYPES = {
    BOOLEAN: 'BOOLEAN',
    NUMBER: 'NUMBER',
    SYMBOL: 'SYMBOL',
    PRICING_MODEL: 'PRICING_MODEL',
    SAMPLER: 'SAMPLER',
    ARRAY_OF_SAMPLERS: 'ARRAY_OF_SAMPLERS',
    SIDE: 'SIDE',
    VARIABLE: 'VARIABLE',
    ARRAY_OF_VARIABLES: 'ARRAY_OF_VARIABLES',
    ARRAY_OF_WEIGHTED_TRANS_VARIABLES: 'ARRAY_OF_WEIGHTED_TRANS_VARIABLES',
    SIGNED_VOLUME_VARIABLE: 'SIGNED_VOLUME_VARIABLE',
    MODEL: 'MODEL',
    TRANSFORM: 'TRANSFORM'
}

function ParamStruct ({ type=PARAM_TYPES.NUMBER, description='', defaultValue, optional=false }) {
    return {
        type,
        description,
        defaultValue,
        optional
    }
}

function ConfigurationStruct ({ description='', params={} }) {
    return {
        description,
        params
    }
}

export function WeightedTransVariableStruct ({ norm_coef=0, variable, weight=0, transform=TRANSFORMS.orig, sm_std=0 }) {
    return {
        norm_coef,
        variable,
        weight,
        transform,
        sm_std
    }
}

export const INSTANCE = ConfigurationStruct({
    description: ``,
    params: {
        aggregateTrade: ParamStruct({ type: PARAM_TYPES.BOOLEAN, description: ``, defaultValue: false }),
        isLive: ParamStruct({ type: PARAM_TYPES.BOOLEAN, description: ``, defaultValue: false }),
        isNewData: ParamStruct({ type: PARAM_TYPES.BOOLEAN, description: ``, defaultValue: false })
    }
})

export const SAMPLERS = {
    TimeSampler: ConfigurationStruct({
        description: `Periodically sample by the pre-defined time interval with a decaying value.`,
        params: {
            msecs: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `This is the time interval which controls how often the sampling occurs` }),
            halflife: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `This is the time interval which controls how often the sampling occurs` })
        }
    }),
    BurstTradeSampler: ConfigurationStruct({
        description: `For a given reset time parameter, total notional is calculated in the given time period i.e. current time - last traded time < reset time. If the total notional is greater than a minimum notional threshold, the sampler is fired`,
        params: {
            symbol: ParamStruct({ type: PARAM_TYPES.SYMBOL, description: `The symbol to sample trades for` }), 
            min_notional: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `the threshold notional for sampling` }),
            reset_msecs: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `the reset time window duration, default value is 10`, defaultValue: 10, optional: true })
        }
    }),
    LargeTradeSampler: ConfigurationStruct({
        description: `This sampler is fired on arrival of a large trade, where large trade is defined if the trade quantity is larger than a given threshold`,
        params: {
            symbol: ParamStruct({ type: PARAM_TYPES.SYMBOL, description: `The symbol to sample trades for` }), 
            min_notional: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `the threshold notional for sampling` })
        }
    }),
    SigPxcSampler: ConfigurationStruct({
        description: `This sampler is fired if the price change exceeds a threshold.`,
        params: {
            pm: ParamStruct({ type: PARAM_TYPES.PRICING_MODEL, description: `The name of the pricing model to sample, passed as a string` }),
            bps: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `The price change threshold in basis points to trigger sampling` }),
            msecs: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `The overall time window duration for the sampler` })
        }
    }),
    BBOPxEventSampler: ConfigurationStruct({
        description: ``,
        params: {
            symbol: ParamStruct({ type: PARAM_TYPES.SYMBOL, description: `The symbol name to track` }),
            halflife: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `This is the decay rate which controls how many times the sampled value decays to half value` })
        }
    }),
    NotionalSampler: ConfigurationStruct({
        description: `This sampler tracks the total traded notional for a symbol and fires sampling events when the notional exceeds a threshold.`,
        params: {
            symbol: ParamStruct({ type: PARAM_TYPES.SYMBOL, description: `The symbol name to track` }),
            notional: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `The notional threshold to trigger sampling` }),
            halflife: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `This is the decay rate which controls how many times the sampled value decays to half value` }),
            multi_events_per_trade: ParamStruct({ type: PARAM_TYPES.BOOLEAN, description: `Boolean to control firing multiple events per trade, default value is false`, defaultValue: false, optional: true })
        }
    }),
    MultiSampler: ConfigurationStruct({
        description: `This sampler allows the combination of multiple samplers to fire.`,
        params: {
            samplers: ParamStruct({ type: PARAM_TYPES.ARRAY_OF_SAMPLERS, description: `a list of sampler names` })
        }
    })
}

export const PRICING_MODELS = {
    TradePx: ConfigurationStruct({
        description: `a regular pricing model referring to the last traded price for a symbol`,
        params: {
            symbol: ParamStruct({ type: PARAM_TYPES.SYMBOL, description: `The symbol name to track` })
        }
    }),
    MidPx: ConfigurationStruct({
        description: `a regular pricing model referring to mid price on the top of the order book i.e. 0.5 * (best_bid_px + best_ask_px)`,
        params: {
            symbol: ParamStruct({ type: PARAM_TYPES.SYMBOL, description: `The symbol name to track` })
        }
    }),
    MktWPx: ConfigurationStruct({
        description: `a regular pricing model referring to mid price adjusted for the order book imbalance`,
        params: {
            symbol: ParamStruct({ type: PARAM_TYPES.SYMBOL, description: `The symbol name to track` })
        }
    }),
    Vwap: ConfigurationStruct({
        description: `a regular pricing model referring to volume weighted average price`,
        params: {
            symbol: ParamStruct({ type: PARAM_TYPES.SYMBOL, description: `The symbol name to track` }),
            sampler: ParamStruct({ type: PARAM_TYPES.SAMPLER, description: `the name of sampler, providing the periodic decay and update events` })
        }
    }),
    BurstTradePx: ConfigurationStruct({
        description: `an advanced pricing model that calculates a combined burst/momentum price for trades occuring within a time window`,
        params: {
            symbol: ParamStruct({ type: PARAM_TYPES.SYMBOL, description: `The symbol name to track` }),
            burst_reset_msecs: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `the duration of the burst/momentum window in milliseconds. Trades within this window are accumulated, default value is 100.`, defaultValue: 100, optional: true })
        }
    }),
    MktSwitchMid: ConfigurationStruct({
        description: `an advanced pricing model that switches between MidPx and MktWpx depending on the size of the bid-ask spread`,
        params: {
            symbol: ParamStruct({ type: PARAM_TYPES.SYMBOL, description: `The symbol name to track` }),
            normal_spread: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `the spread threshold that determines when to switch to the imbalance adjusted mid price, default value is 0.01`, defaultValue: 0.01, optional: true })
        }
    }),
    LevelSupportPx: ConfigurationStruct({
        description: `an advanced pricing model that calculate a support price based on a subset of top orders`,
        params: {
            symbol: ParamStruct({ type: PARAM_TYPES.SYMBOL, description: `The symbol name to track` }),
            side: ParamStruct({ type: PARAM_TYPES.SIDE, description: `"ASK" or "BID", represents the order book side used to calculate support price` }),
            support_notional: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `adjusts final level size to match support notional, default value is 100.`, defaultValue: 100 }),
            level_cap: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `represents a cap or limit on the number of levels in the order book, default value is 10.`, defaultValue: 10 })
        }
    })
}

export const VARIABLES = {
    /***  Math Variables  ***/
    Sum: ConfigurationStruct({
        description: `sum of multiple variables`,
        params: {
            variables: ParamStruct({ type: PARAM_TYPES.ARRAY_OF_VARIABLES, description: `a list of pre-defined variable names` })
        }
    }),
    Sub: ConfigurationStruct({
        description: `subtraction of two variables, i.e. v1 - v2`,
        params: {
            v1: ParamStruct({ type: PARAM_TYPES.VARIABLE, description: `pre-defined variable 1 name` }),
            v2: ParamStruct({ type: PARAM_TYPES.VARIABLE, description: `pre-defined variable 2 name` })
        }
    }),
    Add: ConfigurationStruct({
        description: `addition of two variables`,
        params: {
            v1: ParamStruct({ type: PARAM_TYPES.VARIABLE, description: `pre-defined variable 1 name` }),
            v2: ParamStruct({ type: PARAM_TYPES.VARIABLE, description: `pre-defined variable 2 name` })
        }
    }),
    Ratio: ConfigurationStruct({
        description: `ratio of two variables`,
        params: {
            v1: ParamStruct({ type: PARAM_TYPES.VARIABLE, description: `pre-defined variable 1 name` }),
            v2: ParamStruct({ type: PARAM_TYPES.VARIABLE, description: `pre-defined variable 2 name` })
        }
    }),
    Scale: ConfigurationStruct({
        description: `apply a scaling coefficient to the value of variable`,
        params: {
            coef: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `constant coeffient` }),
            variable: ParamStruct({ type: PARAM_TYPES.VARIABLE, description: `pre-defined variable name` })
        }
    }),
    /***  Support Variables  ***/
    VarEma: ConfigurationStruct({
        description: `expoential moving average (EMA) on a variable`,
        params: {
            variable: ParamStruct({ type: PARAM_TYPES.VARIABLE, description: `pre-defined variable name` }),
            sampler: ParamStruct({ type: PARAM_TYPES.SAMPLER, description: `provides the periodic decay and update events` })
        }
    }),
    PriceVar: ConfigurationStruct({
        description: `wrap a pricing model and expose it as a variable`,
        params: {
            pm: ParamStruct({ type: PARAM_TYPES.PRICING_MODEL, description: `pre-defined pricing model name` })
        }
    }),
    Bollinger: ConfigurationStruct({
        description: `Bollinger Bands indicator that can monitor a trend and adapt the bands based on recent variability`,
        params: {
            trend: ParamStruct({ type: PARAM_TYPES.VARIABLE, description: `The input price/trend variable that the Bollinger Bands will track and normalize. This could be something like a 20-period moving average of price.` }),
            stdev_sampler: ParamStruct({ type: PARAM_TYPES.SAMPLER, description: `Used to sample the trend variable periodically and update/decay the standard deviation EMA. This controls the smoothness of the stdev value over time. A higher sampling frequency and lower decay rate would make it more responsive.` }),
            default_stdev: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `Gives an initial starting point for the standard deviation before enough samples are collected. Can set it based on typical volatility.` }),
            cutoff: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `Used to clip the normalized trend value between positive and negative cutoff thresholds. This prevents extreme values when stdev gets small, which can happen with low volatility, default value is 1.0.`, defaultValue: 1, optional: true })
        }
    }),
    Volatility: ConfigurationStruct({
        description: `track the standard deviation of a trend variable over time using an EMA.`,
        params: {
            trend: ParamStruct({ type: PARAM_TYPES.VARIABLE, description: `the input price/trend variable that Volatility will track.` }),
            sampler: ParamStruct({ type: PARAM_TYPES.SAMPLER, description: `the name of Sampler, providing the periodic decay and update events` }),
            init_vol: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `provides the first point on EMA calculation, default value is 0.0005`, defaultValue: 0.0005, optional: true })
        }
    }),
    ReturnBetweenPrices: ConfigurationStruct({
        description: `just return between two pre-defined pricing models`,
        params: {
            base_pm: ParamStruct({ type: PARAM_TYPES.PRICING_MODEL, description: `a pre-defined pricing model name used as denominator` }),
            pm: ParamStruct({ type: PARAM_TYPES.PRICING_MODEL, description: `a pre-defined pricing model name used as nominator` })
        }
    }),
    TransformedLinearSum: ConfigurationStruct({
        description: `calculate the weighted sum of multiple variables with optional transformations applied to each variable. It is used to load the coefficients of alpha variables with linear model generated from Python and calculate the final result/signal`,
        params: {
            variables: ParamStruct({ type: PARAM_TYPES.ARRAY_OF_WEIGHTED_TRANS_VARIABLES, description: `` })
        }
    }),
    /***  Alpha Variables  ***/
    BookDelta: ConfigurationStruct({
        description: `tracks changes in the top level bid and ask sizes for a market depth feed over a given period.`,
        params: {
            symbol: ParamStruct({ type: PARAM_TYPES.SYMBOL, description: `The symbol name to track` }),
            sampler: ParamStruct({ type: PARAM_TYPES.SAMPLER, description: `provides the periodic decay and update events` }),
            cancel_only: ParamStruct({ type: PARAM_TYPES.BOOLEAN, description: `if true, only bid/ask cancellations will decrement the deltas. New order at that level won't increment, default is false.`, defaultValue: false, optional: true }),
            addback_trades: ParamStruct({ type: PARAM_TYPES.BOOLEAN, description: `if true, executed trades will add their quantities back into the bid/ask deltas, default is true. `, defaultValue: true, optional: true }),
            use_notional: ParamStruct({ type: PARAM_TYPES.BOOLEAN, description: `if true, the bid/ask deltas are calculated using price * quantity rather than quantity, default is false.`, defaultValue: false, optional: true })
        }
    }),
    BookDeltaAll: ConfigurationStruct({
        description: `the difference with BookDelta is that BookDeltaAll will consider all levels instead of top level.`,
        params: {
            symbol: ParamStruct({ type: PARAM_TYPES.SYMBOL, description: `The symbol name to track` }),
            sampler: ParamStruct({ type: PARAM_TYPES.SAMPLER, description: `provides the periodic decay and update events` }),
            cancel_only: ParamStruct({ type: PARAM_TYPES.BOOLEAN, description: `if true, only bid/ask cancellations will decrement the deltas. New order at that level won't increment, default is false.`, defaultValue: false, optional: true }),
            addback_trades: ParamStruct({ type: PARAM_TYPES.BOOLEAN, description: `if true, executed trades will add their quantities back into the bid/ask deltas, default is true. `, defaultValue: true, optional: true }),
            use_notional: ParamStruct({ type: PARAM_TYPES.BOOLEAN, description: `if true, the bid/ask deltas are calculated using price * quantity rather than quantity, default is false.`, defaultValue: false, optional: true })
        }
    }),
    BookVar: ConfigurationStruct({
        description: `measure the imbalance/bias between the top bid/ask sizes.`,
        params: {
            symbol: ParamStruct({ type: PARAM_TYPES.SYMBOL, description: `The symbol name to track` })
        }
    }),
    SVol: ConfigurationStruct({
        description: `measure the sum of the signed trading volume over a given period.`,
        params: {
            symbol: ParamStruct({ type: PARAM_TYPES.SYMBOL, description: `The symbol name to track` }),
            msecs: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `the overall time window duration for the sampler` })
        }
    }),
    SVol2: ConfigurationStruct({
        description: `measure the exponential moving sum (EMS) of the signed trading volume over a given period.`,
        params: {
            symbol: ParamStruct({ type: PARAM_TYPES.SYMBOL, description: `The symbol name to track` }),
            sampler: ParamStruct({ type: PARAM_TYPES.SAMPLER, description: `provides the periodic decay and update events` }),
            use_notional: ParamStruct({ type: PARAM_TYPES.BOOLEAN, description: `if true, the value will be converted as USD (price * quantity) rather than quantity, default is false`, defaultValue: false, optional: true })
        }
    }),
    SVol3: ConfigurationStruct({
        description: `measure the exponential moving sum (EMS) of the signed trading volume over a given period.`,
        params: {
            symbol: ParamStruct({ type: PARAM_TYPES.SYMBOL, description: `The symbol name to track` }),
            sampler: ParamStruct({ type: PARAM_TYPES.SAMPLER, description: `provides the periodic decay and update events` }),
            use_notional: ParamStruct({ type: PARAM_TYPES.BOOLEAN, description: `if true, the value will be converted as USD (price * quantity) rather than quantity, default is false`, defaultValue: false, optional: true })
        }
    }),
    TVol: ConfigurationStruct({
        description: `measure the overall trading volume over a given period, ignoring the trading side.`,
        params: {
            symbol: ParamStruct({ type: PARAM_TYPES.SYMBOL, description: `The symbol name to track` }),
            msecs: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `the overall time window duration for the sampler` })
        }
    }),
    TVol2: ConfigurationStruct({
        description: `measure the exponential moving sum (EMS) of trading volume over a sample period.`,
        params: {
            symbol: ParamStruct({ type: PARAM_TYPES.SYMBOL, description: `The symbol name to track` }),
            msecs: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `the overall time window duration for the sampler` })
        }
    }),
    BookImbalance: ConfigurationStruct({
        description: `measure the midpoint of size weighted bid/ask prices vs a reference price.`,
        params: {
            symbol: ParamStruct({ type: PARAM_TYPES.SYMBOL, description: `The symbol name to track` }),
            ref_pm: ParamStruct({ type: PARAM_TYPES.PRICING_MODEL, description: `a pricing model used as a reference price to compare midpoint price` }),
            level_cap: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `the maximum depth level to sum sizes up to, default is 5`, defaultValue: 5, optional: true })
        }
    }),
    BookImbalanceAbs: ConfigurationStruct({
        description: `measure the absolute imbalance between the bid and ask side of an order book.`,
        params: {
            symbol: ParamStruct({ type: PARAM_TYPES.SYMBOL, description: `The symbol name to track` }),
            ref_pm: ParamStruct({ type: PARAM_TYPES.PRICING_MODEL, description: `a pricing model used as a reference price to compare midpoint price` }),
            level_cap: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `the maximum depth level to sum sizes up to, default is 5`, defaultValue: 5, optional: true }),
            valid_qty_cap: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `the value will be calculated only if either bidQty or askQty exceeeds valid_qty_cap, default is 5`, defaultValue: 5, optional: true })
        }
    }),
    TradeVar: ConfigurationStruct({
        description: `similar to SVol2, measure the exponential moving sum (EMS) of the signed trading volume over a given period.`,
        params: {
            symbol: ParamStruct({ type: PARAM_TYPES.SYMBOL, description: `The symbol name to track` }),
            sampler: ParamStruct({ type: PARAM_TYPES.SAMPLER, description: `provides the periodic decay and update events` }),
            use_notional: ParamStruct({ type: PARAM_TYPES.BOOLEAN, description: `if true, the value will be converted as USD (price * quantity) rather than quantity, default is false`, defaultValue: false, optional: true })
        }
    }),
    TradePxDiff: ConfigurationStruct({
        description: `calculate the exponential moving average of the difference between the actual trade price and the price adjusted by a pricing model`,
        params: {
            symbol: ParamStruct({ type: PARAM_TYPES.SYMBOL, description: `The symbol name to track` }),
            sampler: ParamStruct({ type: PARAM_TYPES.SAMPLER, description: `provides the periodic decay and update events` }),
            fee: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `trading commission fee used to adjust trade price, default is 0.0`, defaultValue: 0, optional: true }),
            pm: ParamStruct({ type: PARAM_TYPES.PRICING_MODEL, description: `a pricing model used to compare with trade price` })
        }
    }),
    SweepVol: ConfigurationStruct({
        description: `calculate the volume swept over a time window.`,
        params: {
            symbol: ParamStruct({ type: PARAM_TYPES.SYMBOL, description: `The symbol name to track` }),
            msecs: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `the overall time window duration for the sampler` })
        }
    }),
    SentimentVar: ConfigurationStruct({
        description: `measure a smoothed estimate of buying vs selling pressure based on classifying trades as buyer or seller.`,
        params: {
            symbol: ParamStruct({ type: PARAM_TYPES.SYMBOL, description: `symbol name to track` }),
            sampler: ParamStruct({ type: PARAM_TYPES.SAMPLER, description: `provides the periodic decay and update events` })
        }
    }),
    AggrTrend: ConfigurationStruct({
        description: `aggressive trend aims to estimate price trend/momentum by tracking increasing asks or decreasing bids`,
        params: {
            symbol: ParamStruct({ type: PARAM_TYPES.SYMBOL, description: `symbol name to track` }),
            sampler: ParamStruct({ type: PARAM_TYPES.SAMPLER, description: `provides the periodic decay and update events` })
        }
    }),
    PassTrend: ConfigurationStruct({
        description: `passive trend aims to estimate price trend/momentum by tracking decreasing asks or increasing bids`,
        params: {
            symbol: ParamStruct({ type: PARAM_TYPES.SYMBOL, description: `symbol name to track` }),
            sampler: ParamStruct({ type: PARAM_TYPES.SAMPLER, description: `provides the periodic decay and update events` })
        }
    }),
    DynVar: ConfigurationStruct({
        description: `conditional variable based on svol and threshold.`,
        params: {
            var: ParamStruct({ type: PARAM_TYPES.VARIABLE, description: `the main variable name` }),
            svol: ParamStruct({ type: PARAM_TYPES.SIGNED_VOLUME_VARIABLE, description: `svol variable as described above` }),
            threshold: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `control the activation level` })
        }
    }),
    CCBookDelta: ConfigurationStruct({
        description: `similar to BookDeltaAll`,
        params: {
            symbol: ParamStruct({ type: PARAM_TYPES.SYMBOL, description: `symbol name to track` }),
            sampler: ParamStruct({ type: PARAM_TYPES.SAMPLER, description: `provides the periodic decay and update events` }),
            top_depth_bps: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `the levels within top_depth_bps threshold will be used to compute the bid/ask deltas, default is 1.0.`, defaultValue: 1, optional: true }),
            cancel_only: ParamStruct({ type: PARAM_TYPES.BOOLEAN, description: `if true, the bid/ask deltas are calculated using price * quantity rather than quantity, default is false.`, defaultValue: false })
        }
    }),
    Trend: ConfigurationStruct({
        description: `represents the percentage change between the current price and a smoothed baseline EMA price.`,
        params: {
            pm: ParamStruct({ type: PARAM_TYPES.PRICING_MODEL, description: `provides the current price` }),
            base_pm: ParamStruct({ type: PARAM_TYPES.PRICING_MODEL, description: `provides the baseline EMA price` }),
            sampler: ParamStruct({ type: PARAM_TYPES.SAMPLER, description: `controls the decay rate` })
        }        
    }),
    Basis: ConfigurationStruct({
        description: `computes the spread between two existing trends with logic: value = beta * srcTrend - depTrend.`,
        params: {
            beta: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `caling factor applied to the source trend, normally the correlation of two trends from historical data, default is 1.0`, defaultValue: 1, optional: true }),
            src_trend: ParamStruct({ type: PARAM_TYPES.VARIABLE, description: `The source price/trend variable` }),
            dep_trend: ParamStruct({ type: PARAM_TYPES.VARIABLE, description: `The dependent price/trend variable` })
        }  
    }),
    BasisFTCS: ConfigurationStruct({
        description: `advanced Basis variable that incorporates fast price-based trend and slow external trend. The goal is to distinguish the trend direction from fast and slow components.`,
        params: {
            srcPM: ParamStruct({ type: PARAM_TYPES.PRICING_MODEL, description: `the source pricing model, used to calculate the fast source trend` }),
            depPM: ParamStruct({ type: PARAM_TYPES.PRICING_MODEL, description: `the dependent pricing model, used to calculate the fast dependent trend` }),
            srcTrend: ParamStruct({ type: PARAM_TYPES.VARIABLE, description: `the source slow trend variable` }),
            depTrend: ParamStruct({ type: PARAM_TYPES.VARIABLE, description: `the dependent fast trend variable` }),
            beta: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `scaling factor applied to the source trend, default is 1.0`, defaultValue: 1, optional: true }),
            sampler: ParamStruct({ type: PARAM_TYPES.SAMPLER, description: `the sampler used to decay trends` })
        }
    })
}

export const WEIGHTED_TRANS_VARIABLE = ConfigurationStruct({
    description: `measure the overall trading volume over a given period, ignoring the trading side.`,
    params: {
        norm_coef: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `the normalized coefficient of variable` }),
        variable: ParamStruct({ type: PARAM_TYPES.VARIABLE, description: `pre-defined variable name` }),
        weight: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `the weight of variable` }),
        transform: ParamStruct({ type: PARAM_TYPES.TRANSFORM, description: `"sm" represents this is a sigmoid variable, default value is "orig"`, optional: true }),
        sm_std: ParamStruct({ type: PARAM_TYPES.NUMBER, description: `sigmoid standard deviation, default value is 0.0`, defaultValue: 0, optional: true })
    }
})

export const MODELS = {
    LinearModel: ConfigurationStruct({
        description: `generalized linear model which is used to predict the future return based on the transformed linear sum of variables`,
        params: {
            variable: ParamStruct({ type: PARAM_TYPES.VARIABLE, description: `pre-defined model variable` })
        }
    })
}

export const STRATEGIES = {
    SimpleArbMMStrategy3: ConfigurationStruct({
        description: ``,
        params: {
            symbol: ParamStruct({ type: PARAM_TYPES.SYMBOL, description: `The symbol name to track` }),
            model: ParamStruct({ type: PARAM_TYPES.MODEL, description: `pre-defined model variable name or INVALID`, optional: true }),
            quote_spread_bps: ParamStruct({ type: PARAM_TYPES.NUMBER, defaultValue: 5, optional: true }),
            quote_spread_adj_bps: ParamStruct({ type: PARAM_TYPES.NUMBER, defaultValue: 1, optional: true }),
            max_quote_error_bps: ParamStruct({ type: PARAM_TYPES.NUMBER, defaultValue: 1, optional: true }),
            skew_base_notional: ParamStruct({ type: PARAM_TYPES.NUMBER, defaultValue: 10000, optional: true }),
            max_order_nums: ParamStruct({ type: PARAM_TYPES.NUMBER, defaultValue: 1, optional: true }),
            vol_threshold: ParamStruct({ type: PARAM_TYPES.NUMBER, defaultValue: 1, optional: true }),
            vol_multiplier_bps: ParamStruct({ type: PARAM_TYPES.NUMBER, defaultValue: 1, optional: true }),
            skew_multiplier_bps: ParamStruct({ type: PARAM_TYPES.NUMBER, defaultValue: 1, optional: true }),
            funding_weight: ParamStruct({ type: PARAM_TYPES.NUMBER, defaultValue: 0, optional: true }),
            fair_px: ParamStruct({ type: PARAM_TYPES.VARIABLE }),
            vol_ratio: ParamStruct({ type: PARAM_TYPES.VARIABLE }),
            base_spread_ema: ParamStruct({ type: PARAM_TYPES.VARIABLE }),
            support_bid_px: ParamStruct({ type: PARAM_TYPES.VARIABLE }),
            support_ask_px: ParamStruct({ type: PARAM_TYPES.VARIABLE }),
            enable_md_delay_check: ParamStruct({ type: PARAM_TYPES.BOOLEAN, description: `if true, strategy will check the latency of market data`, defaultValue: false }),
            delay_threshold: ParamStruct({ type: PARAM_TYPES.NUMBER, defaultValue: 99999, optional: true }),
            delay_reset_threshold: ParamStruct({ type: PARAM_TYPES.NUMBER, defaultValue: 99999, optional: true })
        }
    }),
    SimpleArbMMStrategy4: ConfigurationStruct({
        description: ``,
        params: {
            symbol: ParamStruct({ type: PARAM_TYPES.SYMBOL, description: `The symbol name to track` }),
            model: ParamStruct({ type: PARAM_TYPES.MODEL, description: `pre-defined model variable name or INVALID`, optional: true }),
            is_quote: ParamStruct({ type: PARAM_TYPES.BOOLEAN, defaultValue: true, optional: true }),
            quote_spread_bps: ParamStruct({ type: PARAM_TYPES.NUMBER, defaultValue: 5, optional: true }),
            hedge_spread_bps: ParamStruct({ type: PARAM_TYPES.NUMBER, defaultValue: 1, optional: true }),
            quote_spread_adj_bps: ParamStruct({ type: PARAM_TYPES.NUMBER, defaultValue: 1, optional: true }),
            max_quote_error_bps: ParamStruct({ type: PARAM_TYPES.NUMBER, defaultValue: 1, optional: true }),
            max_hedge_error_bps: ParamStruct({ type: PARAM_TYPES.NUMBER, defaultValue: 1, optional: true }),
            skew_base_notional: ParamStruct({ type: PARAM_TYPES.NUMBER, defaultValue: 10000, optional: true }),
            max_order_nums: ParamStruct({ type: PARAM_TYPES.NUMBER, defaultValue: 1, optional: true }),
            vol_threshold: ParamStruct({ type: PARAM_TYPES.NUMBER, defaultValue: 1, optional: true }),
            vol_multiplier_bps: ParamStruct({ type: PARAM_TYPES.NUMBER, defaultValue: 1, optional: true }),
            skew_multiplier_bps: ParamStruct({ type: PARAM_TYPES.NUMBER, defaultValue: 1, optional: true }),
            expand_threshold: ParamStruct({ type: PARAM_TYPES.NUMBER, defaultValue: 1, optional: 0.35 }),
            funding_weight: ParamStruct({ type: PARAM_TYPES.NUMBER, defaultValue: 0, optional: true }),
            fair_px: ParamStruct({ type: PARAM_TYPES.VARIABLE }),
            vol_ratio: ParamStruct({ type: PARAM_TYPES.VARIABLE }),
            base_spread_ema: ParamStruct({ type: PARAM_TYPES.VARIABLE }),
            support_bid_px: ParamStruct({ type: PARAM_TYPES.VARIABLE }),
            support_ask_px: ParamStruct({ type: PARAM_TYPES.VARIABLE }),
            enable_md_delay_check: ParamStruct({ type: PARAM_TYPES.BOOLEAN, description: `if true, strategy will check the latency of market data`, defaultValue: false }),
            delay_threshold: ParamStruct({ type: PARAM_TYPES.NUMBER, defaultValue: 99999, optional: true }),
            delay_reset_threshold: ParamStruct({ type: PARAM_TYPES.NUMBER, defaultValue: 99999, optional: true })
        }
    })
}

export function InstanceStruct (instance={}) {
    const defaultInstance = _.reduce(INSTANCE.params, (_result, _paramConfig, _paramKey) => {
        _result[_paramKey] = _paramConfig.defaultValue ?? null
        return _result
    }, {})
    return Object.assign({}, defaultInstance, instance)
}

export function SignalProfileStruct ({ _id, name='', description='', includes=[], instance=InstanceStruct({}), symbols=[], samplers=[], pricing_models=[], variables=[], models=[], strategies=[] }) {
    return {
        _id,
        name,
        description,
        includes,
        instance,
        symbols,
        samplers,
        pricing_models,
        variables,
        models,
        strategies
    }
}

export function serializeData ({ name='', type='', params={} }) {
    return [name, [type, params]]
}

export function deserializeData (data=[]) {
    return {
        name: _.get(data, '0'),
        type: _.get(data, '1.0'),
        params: _.get(data, '1.1')
    }
}

