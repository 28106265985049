import React, { Fragment, memo, useState } from 'react'
import { useDispatch } from 'react-redux'
import useEvent from 'react-use-event-hook'
import { useMountedState, useSessionStorage } from 'react-use'
import PropTypes from 'prop-types'

import dotProp from 'dot-prop-immutable'
import BigNumber from 'bignumber.js'
import moment from 'moment'
import _ from 'lodash'

import { VscDiscard } from 'react-icons/vsc'
import { TbAdjustmentsCog } from 'react-icons/tb'
import { GrSave } from 'react-icons/gr'

import Popup from '../common/popup/Popup'
import Toggle from '../common/toggle/Toggle'

import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector'
import { updateExposureMonitorConfig } from './tradingAction'
import { areAllValuesNonEmpty, isMetSearchStringCriteria, toNumberWithSmartPrecision } from '../../util/util'

const SORT_BY = {
    COIN: 'COIN',
    BENCHMARK_NET_EXPOSURE: 'BENCHMARK_NET_EXPOSURE',
    CURRENT_NET_EXPOSURE: 'CURRENT_NET_EXPOSURE',
    EXPOSURE_DEVIATION_VALUE: 'EXPOSURE_DEVIATION_VALUE',
    EXPOSURE_DEVIATION_RATIO: 'EXPOSURE_DEVIATION_RATIO'
}

const MonitorItemStruct = ({ deviationRatioThreshold=null, absoluteDeviationThreshold=null, disabled=false, disableUntilTimestamp='' }) => {
    return {
        deviationRatioThreshold,
        absoluteDeviationThreshold,
        disabled,
        disableUntilTimestamp
    }
}

function DisableUntilTimestamp ({ originalValue, value, onChange=()=>{} }) {
    const [popupId, setPopupId] = useState(0)
    const MINUTES = [15, 30, 60, 0]
    const _isDisabled = !_.isEmpty(value) && moment().isBefore(value)

    return (
        <Popup key={popupId}
            className='exposure-monitor--disable-until-timestamp'
            on={'click'}
            trigger={<button className={'exposure-monitor--disable-until-timestamp--trigger' + (_isDisabled ? ' highlight' : '') + (originalValue !== value ? ' edited' : '')}>{_isDisabled ? moment(value).format('HH:mm:ss') : 'Set Duration'}</button>}>
            {_.map(MINUTES, minute => {
                return (
                    <button onClick={() => {
                        if (minute > 0) {
                            onChange(moment().add(minute, 'minutes').toISOString())
                        } else {
                            onChange('')
                        }
                        setPopupId(popupId + 1)
                    }}>{minute > 0 ? `${minute} Minutes` : 'Remove'}</button>
                )
            })}
        </Popup>
    )
}

DisableUntilTimestamp.propTypes = {
    originalValue: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired
}

function ExposureMonitor () {

    const dispatch = useDispatch()
    const isMounted = useMountedState()
    const { lastUpdateTime, lastUpdateBy, configPerPortfolio, monitorPerPortfolio, personsOnDuty } = useShallowEqualSelector(state => _.get(state, 'trading.exposureMonitor')) || {}
    const username = useShallowEqualSelector(state => _.get(state, 'auth.username'))
    const [searchString, setSearchString] = useState('')
    const [editingConfigPerPortfolio, setEditingConfigPerPortfolio] = useState({})
    const [sortBy, setSortBy] = useState(SORT_BY.EXPOSURE_DEVIATION_VALUE)
    const [isSaving, setIsSavging] = useState(false)
    const [portfoliosShouldHide, setPortfoliosShouldHide] = useSessionStorage('exposure-monitor--portfolio-should-hide', [])

    const _configPerPortfolio = !_.isEmpty(editingConfigPerPortfolio) ? _.reduce(editingConfigPerPortfolio, (_result, _config, _portfolio) => {
        _result[_portfolio] = {
            ..._config,
            subscribedUsers: _.get(configPerPortfolio, `${_portfolio}.subscribedUsers`, [])
        }
        return _result
    }, {}) : configPerPortfolio

    const _handleClickSaveButton = useEvent(() => {
        if (!isSaving && !_.isEmpty(_configPerPortfolio)) {
            setIsSavging(true)
            dispatch(updateExposureMonitorConfig({ configPerPortfolio: _configPerPortfolio }))
            .then(() => { setEditingConfigPerPortfolio({}) })
            .finally(() => {
                if (isMounted()) {
                    setIsSavging(false)
                }
            })
        }
    })

    // eslint-disable-next-line react/prop-types
    function NumberInputWithDirtyCheck ({ originalValue, value, onChange=()=>{} }) {
        return (
            <input
                className={originalValue !== value ? 'edited' : null}
                type='number'
                placeholder='0.0'
                value={value ?? ''} 
                onChange={(e) => { onChange(e.target.value) }} />
        )
    }

    // eslint-disable-next-line react/prop-types
    function ToggleWithDirtyCheck ({ originalValue, value, onChange=()=>{} }) {
        return (
            <Toggle className={originalValue !== value ? 'edited' : null}
                trueText={'ON'}
                falseText={'OFF'}
                checked={value}
                onChange={(newChecked) => { onChange(newChecked) }} />
        )

    }

    function PortfolioConfig (portfolio) {
        const { subscribedUsers, alertPersonOnDutyEnabled, defaultDeviationThreshold, shouldAlertOnNewCurrentExposure, shouldAlertOnMissingCurrentExposure } = _.get(_configPerPortfolio, portfolio) || {}
        return (
            <Popup className='exposure-monitor--config'
                on={'click'}
                trigger={
                    <button className='exposure-monitor--config--trigger'>
                        <TbAdjustmentsCog />
                        {'More Configs'}
                    </button>}>
                <div className='exposure-monitor--config--header'>{'Portfolio Configuration'}</div>
                <div className='exposure-monitor--config--main'>
                    <section className='exposure-monitor--config--subscribed-users'>
                        <div className='exposure-monitor--config--subscribed-users--title'>{'Pushover Subscribed Users'}</div>
                        <div className='exposure-monitor--config--subscribed-users--list'>{_.isEmpty(subscribedUsers) ? 'No Subscribers' : (subscribedUsers).join(', ')}</div>
                        <div className='exposure-monitor--config--alert-persons-on-duty-toggle'>
                            <label>{`Alert Person On Duty - ${personsOnDuty.join(', ')}`}</label>
                            {ToggleWithDirtyCheck({
                                originalValue: _.get(configPerPortfolio, `${portfolio}.alertPersonOnDutyEnabled`) ?? false,
                                value: alertPersonOnDutyEnabled ?? false,
                                onChange: (newChecked) => { setEditingConfigPerPortfolio(dotProp.set(_configPerPortfolio, `${portfolio}.alertPersonOnDutyEnabled`, newChecked)) }
                            })}
                        </div>
                    </section>
                    <section className='exposure-monitor--config--default-deviation-ratio-threshold'>
                        <div className='exposure-monitor--config--default-deviation-ratio-threshold--title'>{`Default Deviation Threshold`}</div>
                        <div className='exposure-monitor--config--default-deviation-ratio-threshold--description'>
                            {`When this threshold is enabled, if a coin lacks its own threshold, an alert triggers if its deviation ratio exceeds the set ratio and its USD deviation exceeds the set value (if no value is set, this condition is ignored).`}
                        </div>
                        <div className='exposure-monitor--config--default-deviation-ratio-threshold--main'>
                            <div>
                                <label>{'Switch'}</label>
                                {ToggleWithDirtyCheck({
                                    originalValue: _.get(configPerPortfolio, `${portfolio}.defaultDeviationThreshold.enabled`) ?? false,
                                    value: defaultDeviationThreshold?.enabled ?? false,
                                    onChange: (newChecked) => { setEditingConfigPerPortfolio(dotProp.set(_configPerPortfolio, `${portfolio}.defaultDeviationThreshold.enabled`, newChecked)) }
                                })}
                            </div>
                            <div>
                                <label>{'Ratio'}</label>
                                {NumberInputWithDirtyCheck({
                                    originalValue: _.get(configPerPortfolio, `${portfolio}.defaultDeviationThreshold.ratio`),
                                    value: defaultDeviationThreshold?.ratio,
                                    onChange: (newValue) => {
                                        if (newValue >= 0) {
                                            setEditingConfigPerPortfolio(dotProp.set(_configPerPortfolio, `${portfolio}.defaultDeviationThreshold.ratio`, newValue))
                                        }
                                    }
                                })}
                            </div>
                            <div>
                                <label>{'USD Value'}</label>
                                {NumberInputWithDirtyCheck({
                                    originalValue: _.get(configPerPortfolio, `${portfolio}.defaultDeviationThreshold.valueUSD`),
                                    value: defaultDeviationThreshold?.valueUSD,
                                    onChange: (newValue) => {
                                        if (newValue >= 0) {
                                            setEditingConfigPerPortfolio(dotProp.set(_configPerPortfolio, `${portfolio}.defaultDeviationThreshold.valueUSD`, newValue))
                                        }
                                    }
                                })}
                            </div>
                        </div>
                    </section>
                    <section className='exposure-monitor--config--hould-alert-on-new-current-exposure'>
                        <div className='exposure-monitor--config--should-alert-on-new-current-exposure--title'>{'Alert On New Exposure'}</div>
                        <div className='exposure-monitor--config--should-alert-on-new-current-exposure--description'>
                            {`If new token exposure occurs compared to the benchmark, trigger an alert.`}
                        </div>
                        <div className='exposure-monitor--config--should-alert-on-new-current-exposure--main'>
                            <div>
                                <label>{'Switch'}</label>
                                {ToggleWithDirtyCheck({
                                    originalValue: _.get(configPerPortfolio, `${portfolio}.shouldAlertOnNewCurrentExposure`) ?? false,
                                    value: shouldAlertOnNewCurrentExposure ?? false,
                                    onChange: (newChecked) => { setEditingConfigPerPortfolio(dotProp.set(_configPerPortfolio, `${portfolio}.shouldAlertOnNewCurrentExposure`, newChecked)) }
                                })}
                            </div>
                        </div>
                    </section>
                    <section className='exposure-monitor--config--should-alert-on-missing-current-exposure'>
                        <div className='exposure-monitor--config--should-alert-on-missing-current-exposure--title'>{'Alert On Missing Exposure'}</div>
                        <div className='exposure-monitor--config--should-alert-on-missing-current-exposure--description'>
                            {`If the latest token exposure is unavailable compared to the benchmark — meaning the benchmark shows exposure but the current exposure is N/A — trigger an alert.`}
                        </div>
                        <div className='exposure-monitor--config--should-alert-on-missing-current-exposure--main'>
                            <div>
                                <label>{'Switch'}</label>
                                {ToggleWithDirtyCheck({
                                    originalValue: _.get(configPerPortfolio, `${portfolio}.shouldAlertOnMissingCurrentExposure`) ?? false,
                                    value: shouldAlertOnMissingCurrentExposure ?? false,
                                    onChange: (newChecked) => { setEditingConfigPerPortfolio(dotProp.set(_configPerPortfolio, `${portfolio}.shouldAlertOnMissingCurrentExposure`, newChecked)) }
                                })}
                            </div>
                        </div>
                    </section>
                </div>
            </Popup>
        )
    }

    function SubscriptionToggle (portfolio) {
        const { subscribedUsers } = _.get(_configPerPortfolio, portfolio) || {}
        const subscribed = (subscribedUsers ?? []).includes(username)

        return !_.isEmpty(username) && (
            <div className='exposure-monitor--subscription-toggle'>
                <label>{'Pushover'}</label>
                <Toggle
                    trueText={'Subscribed'}
                    falseText={'Unsubscribed'}
                    checked={subscribed}
                    onChange={() => {
                        const newSubscribedUsers = subscribed ? _.without(subscribedUsers, username) : _.concat(subscribedUsers, username)
                        dispatch(updateExposureMonitorConfig({
                            configPerPortfolio: dotProp.set(configPerPortfolio, `${portfolio}.subscribedUsers`, newSubscribedUsers)
                        }))
                    }} />
            </div>
        )
    }

    function Table () {
        const pickedMonitorPerPortfolio = _.reduce(monitorPerPortfolio, (_pickedMonitors, _monitor, _portfolio) => {
            const _pickedItemsPerCoin = _.pickBy(_monitor?.itemPerCoin, (_item, _coin) => isMetSearchStringCriteria(`${_portfolio} ${_coin}`, searchString))
            if (!_.isEmpty(_pickedItemsPerCoin)) {
                _pickedMonitors[_portfolio] = {
                    ..._monitor,
                    itemPerCoin: _pickedItemsPerCoin
                }
            }
            return _pickedMonitors
        }, {})

        return (
            <table className='exposure-monitor--table'>
                <thead>
                    <tr>
                        <th className={'sortable' + (sortBy === SORT_BY.COIN ? ' sorted' : '')} onClick={() => { setSortBy(SORT_BY.COIN) }}>{'Coin'}</th>
                        <th className={'sortable' + (sortBy === SORT_BY.BENCHMARK_NET_EXPOSURE ? ' sorted' : '')} onClick={() => { setSortBy(SORT_BY.BENCHMARK_NET_EXPOSURE) }}>{'Benchmark Net Exp'}</th>
                        <th className={'sortable' + (sortBy === SORT_BY.CURRENT_NET_EXPOSURE ? ' sorted' : '')} onClick={() => { setSortBy(SORT_BY.CURRENT_NET_EXPOSURE) }}>{'Current Net Exp'}</th>
                        <th className={'sortable' + (sortBy === SORT_BY.EXPOSURE_DEVIATION_RATIO ? ' sorted' : '')} onClick={() => { setSortBy(SORT_BY.EXPOSURE_DEVIATION_RATIO) }}>{'Deviation %'}</th>
                        <th className={'sortable' + (sortBy === SORT_BY.EXPOSURE_DEVIATION_VALUE ? ' sorted' : '')} onClick={() => { setSortBy(SORT_BY.EXPOSURE_DEVIATION_VALUE) }}>{'Deviation'}</th>
                        <th>
                            <Popup className='exposure-monitor--table--tooltip'
                                trigger={<span className='has-tooltip'>{'Deviation Ratio Thresh'}</span>}>
                                {`If the change in exposure, calculated by ratio, exceeds this value, an alarm will be triggered. When it is set to an empty value, this alert will be disabled.`}
                            </Popup>
                        </th>
                        <th>
                            <Popup className='exposure-monitor--table--tooltip'
                                trigger={<span className='has-tooltip'>{'Deviation Thresh'}</span>}>
                                {`If the change in exposure, calculated by absolute value, exceeds this amount, an alarm will be triggered. When it is set to an empty value, this alert will be disabled.`}
                            </Popup>
                        </th>
                        <th>
                            <Popup className='exposure-monitor--table--tooltip'
                                trigger={<span className='has-tooltip'>{'Alert Switch'}</span>}>
                                {`When the alert for the token is turned off, all alerts for it will be ignored.`}
                            </Popup>
                        </th>
                        <th>{'Disable Until'}</th>
                    </tr>
                </thead>
                <tbody>
                    {_.map(pickedMonitorPerPortfolio, (_monitor, _portfolio) => {
                        const { benchmarkTimestamp: _benchmarkTimestamp, benchmarkType: _benchmarkType, itemPerCoin: _itemPerCoin } = _monitor || {}

                        const _sortedCoinItemPairs = _.sortBy(_.toPairs(_itemPerCoin), _coinItemPair => {
                            return sortBy === SORT_BY.COIN ? _coinItemPair[0]
                                : sortBy === SORT_BY.BENCHMARK_NET_EXPOSURE ? - Math.abs(Number(_coinItemPair[1].benchmarkNetExposure))
                                : sortBy === SORT_BY.CURRENT_NET_EXPOSURE ? - Math.abs(Number(_coinItemPair[1].currentNetExposure))
                                : sortBy === SORT_BY.EXPOSURE_DEVIATION_VALUE ? - Math.abs(Number(_coinItemPair[1].deviationInUSD))
                                : - Math.abs(Number(_coinItemPair[1].deviationRatio))
                        }) 

                        const _shouldHide = portfoliosShouldHide.includes(_portfolio)
                        return (
                            <Fragment key={_portfolio}>
                                <tr className='exposure-monitor--table--portfolio-row'
                                    onClick={() => { setPortfoliosShouldHide(_shouldHide ? _.without(portfoliosShouldHide, _portfolio) : _.concat(portfoliosShouldHide, [_portfolio])) }}>
                                    <td colSpan={99}>
                                        <div className='exposure-monitor--table--portfolio-row--main'>
                                            <div className='exposure-monitor--table--portfolio-row--name'>{_portfolio}</div>
                                            <div className='exposure-monitor--table--portfolio-row--benchmark'>
                                                <label>{'Benchmark'}</label>
                                                <div>{!_.isEmpty(_benchmarkTimestamp) ? moment(_benchmarkTimestamp).format('YYYY-MM-DD HH:mm:ss') : 'N/A'}</div>
                                                {!_.isEmpty(_benchmarkType) && _benchmarkType !== 'DEFAULT' && <span>{_benchmarkType}</span>}
                                            </div>
                                            <div className='exposure-monitor--table--portfolio-row--main--right' onClick={(e) => { e.stopPropagation() }}>
                                                {PortfolioConfig(_portfolio)}
                                                {SubscriptionToggle(_portfolio)}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                {!_shouldHide && _.map(_sortedCoinItemPairs, _coinItemPair => {
                                    const [_coin, _item] = _coinItemPair 
                                    const { benchmarkNetExposure, currentNetExposure, deviation, deviationInUSD, deviationRatio, deviationRatioThreshold, absoluteDeviationThreshold, disabled, disableUntilTimestamp } = _item || {}
                                    const isDeviationPositive = Number(deviation) > 0
                                    const _configItem = MonitorItemStruct(_.get(_configPerPortfolio, `${_portfolio}.itemPerCoin.${_coin}`, {}))

                                    const { deviationRatioThreshold: _editingDeviationRatioThreshold, absoluteDeviationThreshold: _editingAbsoluteDeviationThreshold, disabled: _editingDisabled, disableUntilTimestamp: _editingDisableUntilTimestamp } = _configItem

                                    const shouldHighlightRatio = areAllValuesNonEmpty([deviationRatio, _editingDeviationRatioThreshold]) && BigNumber(deviationRatio).abs().gt(_editingDeviationRatioThreshold)
                                    const shouldHighlightValue = areAllValuesNonEmpty([deviation, _editingAbsoluteDeviationThreshold]) && BigNumber(deviation).abs().gt(_editingAbsoluteDeviationThreshold)

                                    return (
                                        <tr className='exposure-monitor--table--data-row' key={_coin}>
                                            <td>{_coin}</td>
                                            <td>{_.isNil(benchmarkNetExposure) ? 'N/A' : toNumberWithSmartPrecision({ number: benchmarkNetExposure, shouldApplyFloorValue: true, shouldReturnLocalString: true })}</td>
                                            <td>{_.isNil(currentNetExposure) ? 'N/A' : toNumberWithSmartPrecision({ number: currentNetExposure, shouldApplyFloorValue: true, shouldReturnLocalString: true })}</td>
                                            <td>
                                                <div className='exposure-monitor--table--exposure-change'>
                                                    <span className={'exposure-monitor--table--exposure-change--ratio' + (shouldHighlightRatio ? ' highlight' : '')}>{_.isNil(deviationRatio) ? 'N/A' : `${isDeviationPositive ? '+' : ''}${BigNumber(deviationRatio).times(100).toFixed(2, 1)}%`}</span>                                                </div>
                                            </td>
                                            <td>
                                                <div className='exposure-monitor--table--exposure-change'>
                                                    <span className={'exposure-monitor--table--exposure-change--value' + (shouldHighlightValue ? ' highlight' : '')}>{_.isNil(deviation) ? 'N/A' : `${isDeviationPositive ? '+' : ''}${toNumberWithSmartPrecision({ number: deviation, shouldApplyFloorValue: true, shouldReturnLocalString: true })}`}</span>
                                                    <span className={'exposure-monitor--table--exposure-change--value-usd'}>{_.isNil(deviationInUSD) ? 'N/A' : `$${BigNumber(deviationInUSD).toFormat(0, 1)}`}</span>
                                                </div>
                                            </td>
                                            <td>
                                                {NumberInputWithDirtyCheck({
                                                    originalValue: deviationRatioThreshold,
                                                    value: _editingDeviationRatioThreshold,
                                                    onChange: (newValue) => {
                                                        if (Number(newValue) >= 0) {
                                                            const newConfigItem = Object.assign({}, _configItem, { deviationRatioThreshold: newValue })
                                                            setEditingConfigPerPortfolio(dotProp.set(_configPerPortfolio, `${_portfolio}.itemPerCoin.${_coin}`, newConfigItem))
                                                        }
                                                    }
                                                })}
                                            </td>
                                            <td>
                                                {NumberInputWithDirtyCheck({
                                                    originalValue: absoluteDeviationThreshold,
                                                    value: _editingAbsoluteDeviationThreshold,
                                                    onChange: (newValue) => {
                                                        if (Number(newValue) >= 0) {
                                                            const newConfigItem = Object.assign({}, _configItem, { absoluteDeviationThreshold: newValue })
                                                            setEditingConfigPerPortfolio(dotProp.set(_configPerPortfolio, `${_portfolio}.itemPerCoin.${_coin}`, newConfigItem))
                                                        }
                                                    }
                                                })}
                                            </td>
                                            <td>
                                                {ToggleWithDirtyCheck({
                                                    originalValue: !disabled,
                                                    value: !_editingDisabled,
                                                    onChange: (newChecked) => {
                                                        const newConfigItem = Object.assign({}, _configItem, { disabled: !newChecked })
                                                        setEditingConfigPerPortfolio(dotProp.set(_configPerPortfolio, `${_portfolio}.itemPerCoin.${_coin}`, newConfigItem))
                                                    }
                                                })}
                                            </td>
                                            <td>
                                                <DisableUntilTimestamp
                                                    originalValue={disableUntilTimestamp ?? ''}
                                                    value={_editingDisableUntilTimestamp ?? ''}
                                                    onChange={(newTimestamp) => {
                                                        const newConfigItem = Object.assign({}, _configItem, { disableUntilTimestamp: newTimestamp })
                                                        setEditingConfigPerPortfolio(dotProp.set(_configPerPortfolio, `${_portfolio}.itemPerCoin.${_coin}`, newConfigItem))
                                                    }} />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </Fragment>
                        )
                    })}
                </tbody>
            </table>
        )
    }

    return (
        <div className='exposure-monitor'>
            <div className='exposure-monitor--header'>
                <div className='exposure-monitor--last-update'>
                    <label>{'Last Update'}</label>
                    <div>{`${!_.isEmpty(lastUpdateTime) ? moment(lastUpdateTime).format('YYYY-MM-DD HH:mm:ss') : 'N/A'} by ${lastUpdateBy}`}</div>
                </div>
            </div>
            <div className='exposure-monitor--actions'>
                <input className='exposure-monitor--search-input'
                    spellCheck={false}
                    placeholder='Search portfolios, coins'
                    value={searchString}
                    onChange={(e) => { setSearchString(e.target.value) }} />
                <div className='exposure-monitor--actions--right'>
                    <button className='exposure-monitor--discard-button'
                        disabled={_.isEmpty(editingConfigPerPortfolio) || isSaving}
                        onClick={() => { setEditingConfigPerPortfolio({}) }}>
                        <VscDiscard />
                        {'Discard Changes'}
                    </button>
                    <button className='exposure-monitor--save-button'
                        disabled={_.isEmpty(editingConfigPerPortfolio) || isSaving}
                        onClick={() => { _handleClickSaveButton() }}>
                        <GrSave />
                        {isSaving? 'Saving...' : 'Save Changes'}
                    </button>
                </div>
            </div>
            <div className='exposure-monitor--table-wrapper'>{Table()}</div>
        </div>
    )
}

export default memo(ExposureMonitor)