import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Checkbox from '../common/checkbox/Checkbox'
import Popup from '../common/popup/Popup'
import CrossMarginAccountBalanceItem from './CrossMarginAccountBalanceItem'
import SearchSelect from '../common/searchSelect/SearchSelect'

import { getSymbolAttributeByName } from '../../util/symbolUtil'
import { accountBorrowFund, accountRepayFund } from './accountAction'
import { toNumberWithSmartPrecision } from '../../util/util'

class TokenLendingEditor extends Component {
    constructor (props) {
        super(props)
        this.state = {
            accountName: props.defaultAccountName || null,
            coin: !_.isEmpty(props.defaultCoin) ? _.toUpper(props.defaultCoin) : null,
            amountInput: '',
            shouldShowAccountBalanceHavePositiveBorrowedValue: true,
            isFetching: false,
            message: null
        }
        this._mounted = false
    }

    componentDidMount () {
        this._mounted = true
    }

    componentWillUnmount () {
        this._mounted = false
    }

    handleClickActionButton (action='borrow') {
        const { dispatch, onChangeComponentHeight } = this.props
        const { accountName, coin, amountInput, isFetching } = this.state
        const request = action === 'borrow' ? accountBorrowFund 
            : action === 'repay' ? accountRepayFund
            : null
        if (!_.isEmpty(accountName) && !_.isEmpty(coin) && !isFetching && Number(amountInput) > 0 && !_.isNil(request)) {

            this.setState({
                isFetching: true,
                message: action === 'borrow' ? 'Borrowing...' : 'Repaying...'
            }, () => {
                onChangeComponentHeight()
            })
            dispatch(request({ 
                account_name:  accountName,
                currency: coin,
                pair: '',
                amount: Number(amountInput)
            }))
            .then(response => {
                if (this._mounted && response && response.status) {
                    response.text()
                    .then(text => {
                        this.setState(prevState => {
                            return {
                                isFetching: false,
                                message: (response.status === 200 ? 'Success: ' : 'Fail: ') + text,
                                amountInput: response.status === 200 ? '' : prevState.amountInput
                            }
                        })
                    })
                    .catch(error => {
                        if (this._mounted) {
                            this.setState({
                                isFetching: false,
                                message: error.toString()
                            })
                        }
                    })
                    .finally(() => {
                        onChangeComponentHeight()
                    })
                }
            })
        }
    }

    AccountCoinInfo (accountName) {
        const { crossMarginAccountBalances } = this.props
        const { shouldShowAccountBalanceHavePositiveBorrowedValue } = this.state
        const filteredCrossMarginAccountBalances = _.filter(crossMarginAccountBalances, balance => {
            return balance.acct_name === accountName 
                && (!shouldShowAccountBalanceHavePositiveBorrowedValue || Number(balance.borrowed) > 0)
        })
        const seivedCrossMarginAccountBalances = _.sortBy(filteredCrossMarginAccountBalances, 'coin')
        
        return (
            <div className='token-lending-editor--account-coin-info'>
                <div className='token-lending-editor--account-coin-info--header'>
                    <div className='token-lending-editor--account-coin-info--title'>{'Account Details'}</div>
                    <div className='token-lending-editor--account-coin-info--checkbox'>
                        <label>{'Borrowed'}</label>
                        <Checkbox checked={shouldShowAccountBalanceHavePositiveBorrowedValue} 
                            onChange={(newChecked) => { this.setState({ shouldShowAccountBalanceHavePositiveBorrowedValue: newChecked }) }}/>
                    </div>
                </div>
                <div className='token-lending-editor--account-coin-info--table-wrapper'>
                    <table>
                        <thead>
                            <tr>
                                <th>{'Coin'}</th>
                                <th className='right-align'>{'Borrowed'}</th>
                                <th className='right-align'>{'Borrowable'}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {_.map(seivedCrossMarginAccountBalances, balance => {
                                const { coin, borrowed, max_borrowable } = balance
                                return (
                                    <Fragment key={coin}>
                                        <Popup 
                                            className='token-lending-editor--account-coin-info--balance-popup'
                                            trigger={
                                                <tr key={coin}>
                                                    <td>{_.toUpper(coin)}</td>
                                                    <td className='right-align'>{toNumberWithSmartPrecision({ number: borrowed, shouldApplyFloorValue: true, shouldReturnLocalString: true, defaultPrecision: 0 })}</td>
                                                    <td className='right-align'>{toNumberWithSmartPrecision({ number: max_borrowable, shouldApplyFloorValue: true, shouldReturnLocalString: true, defaultPrecision: 0 })}</td>
                                                </tr>
                                            }>
                                            <CrossMarginAccountBalanceItem 
                                                crossMarginAccountBalance={balance} 
                                                shouldShowAccountTypeTag
                                                shouldShowAccountName
                                                shouldShowDetail 
                                                tokenLendingEnabled={false} /> 
                                        </Popup>
                                    </Fragment>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    render () {
        const { crossMarginAccountBalances, shouldEnableAccountSelector, shouldShowAccountCoinInfo, symbolItems, onChangeComponentHeight } = this.props
        const { accountName, coin, amountInput, isFetching, message } = this.state
        const uniqAccountNames = _.uniq(_.map(crossMarginAccountBalances, crossMarginAccountBalance => crossMarginAccountBalance.acct_name))
        const accountOptions = _.map(uniqAccountNames, accountName => {
            return {
                value: accountName,
                name: accountName
            }
        })
        const coinOptions = _.uniq(_.map(symbolItems, symbolItem => getSymbolAttributeByName(symbolItem.symbol_name).base)).sort().map(coinName => {
            return {
                value: coinName,
                name: coinName
            }
        })

        const selectedCrossMaginBalance = _.find(crossMarginAccountBalances, balance => {
            return balance.acct_name === accountName && balance.coin === _.toLower(coin || '')
        })

        return (
            <div className='token-lending-editor'>
                <div className='token-lending-editor--selectors'>
                    {shouldEnableAccountSelector && 
                    <SearchSelect className='token-lending-editor--selector account-selector'
                        placeholder={'Select Account'}
                        options={accountOptions}
                        value={accountName}
                        onChange={(newOption) => {
                            this.setState({ 
                                accountName: newOption.value,
                                message: null
                            }, () => { onChangeComponentHeight() })
                        }} />}
                    <SearchSelect className='token-lending-editor--selector coin-selector'
                        placeholder={'Select Coin'}
                        options={coinOptions}
                        value={coin}
                        onChange={(newOption) => {
                            this.setState({ 
                                coin: newOption.value,
                                message: null
                            }, () => { onChangeComponentHeight() })
                        }} />
                </div>
                <div className='token-lending-editor--account-detail'>
                    <div><label>{'Balance'}</label><span>{selectedCrossMaginBalance ? toNumberWithSmartPrecision({ number: selectedCrossMaginBalance.balance, shouldApplyFloorValue: true, shouldReturnLocalString: true }) : 'N/A'}</span></div>
                    <div><label>{'Borrowed'}</label><span>{selectedCrossMaginBalance ? toNumberWithSmartPrecision({ number: selectedCrossMaginBalance.borrowed, shouldApplyFloorValue: true, shouldReturnLocalString: true }) : 'N/A'}</span></div>
                    <div><label>{'Max Borrowable'}</label><span>{selectedCrossMaginBalance ? toNumberWithSmartPrecision({ number: selectedCrossMaginBalance.max_borrowable, shouldApplyFloorValue: true, shouldReturnLocalString: true }) : 'N/A'}</span></div>
                </div>
                <div className='token-lending-editor--inputs'>
                    <input type={'number'}
                        placeholder={'Amount'}
                        min={0}
                        value={amountInput}
                        onChange={(e) => { 
                            this.setState({ 
                                amountInput: e.target.value,
                                message: null
                            }, () => { onChangeComponentHeight() }) 
                        }} />
                    <div className='token-lending-editor--buttons'>
                        <button className='token-lending-editor--button borrow' 
                            disabled={_.isEmpty(accountName) || _.isEmpty(coin) || Number(amountInput) <= 0 || isFetching}
                            onClick={() => {
                                this.handleClickActionButton('borrow')
                            }}>{'BORROW'}</button>
                        <button className='token-lending-editor--button repay'
                            disabled={_.isEmpty(accountName) || _.isEmpty(coin) || Number(amountInput) <= 0 || isFetching}
                            onClick={() => {
                                this.handleClickActionButton('repay')
                            }}>{'REPAY'}</button>
                    </div>
                    {message && <div className='token-lending-editor--message'>{message}</div>}
                </div>
                {shouldShowAccountCoinInfo && !_.isNil(accountName) && this.AccountCoinInfo(accountName)}
            </div>
        )
    }
}

TokenLendingEditor.propTypes = {
    dispatch: PropTypes.func.isRequired,
    crossMarginAccountBalances: PropTypes.object.isRequired,
    symbolItems: PropTypes.object.isRequired,

    defaultAccountName: PropTypes.string,
    defaultCoin: PropTypes.string,
    shouldEnableAccountSelector: PropTypes.bool,
    shouldShowAccountCoinInfo: PropTypes.bool,
    onChangeComponentHeight: PropTypes.func
}

TokenLendingEditor.defaultProps = {
    onChangeComponentHeight: () => {}
}

function mapStateToProps (state) {
    return {
        crossMarginAccountBalances: state.account.balance.crossMargin,
        symbolItems: state.symbol.items
    }
}

export default connect(mapStateToProps)(TokenLendingEditor)