import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import _ from 'lodash'

import VaultExecutePopup from './VaultExecutePopup'
import { SYNTHETIX_PERPS_ADDRESSES } from './blockchainConfig'
import { ABI as PERPS_V2_MARKET_CONSOLIDATED_ABI } from './ABIs/PerpsV2MarketConsolidated'
import { formattedHex, formattedTokenAmount } from '../../util/formatUtil'
import { getDefaultChain } from '../../wagmiConfig'
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector'
import { selectBlockchainVaultSynthetixPerps } from './blockchainReducer'

const CONTRACT_NAME_PER_PROXY_PERPS_V2_ADDRESS = _.invert(SYNTHETIX_PERPS_ADDRESSES)

function VaultSynthetixPerps ({ vaultAddress }) {
    
    const vaultSynthetixPerps = useShallowEqualSelector(state => selectBlockchainVaultSynthetixPerps(state, vaultAddress))
    const defaultChain = getDefaultChain()
    
    return (
        <div className='vault-synthetix-perps'>
            <div className='vault-synthetix-perps--header'>
                <label>{'Synthetix Perps'}</label>
            </div>
            <div className='vault-synthetix-perps--table'>
                <table>
                    <thead>
                        <tr>
                            <th>{'Contract'}</th>
                            <th>{'Remaining Margin'}</th>
                            <th>{'Accesible Margin'}</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {_.map(CONTRACT_NAME_PER_PROXY_PERPS_V2_ADDRESS, (contractName, contractAddress) => {
                            return (
                                <tr key={contractAddress}>
                                    <td>
                                        <div className='vault-synthetix-perps--contract'>
                                            <Link to={`${defaultChain?.blockExplorers?.etherscan?.url}/address/${contractAddress}`} target='_blank'>{formattedHex(contractAddress)}</Link>
                                            <span>{contractName}</span>
                                        </div>
                                    </td>
                                    <td>{formattedTokenAmount({ amount: _.get(vaultSynthetixPerps, `${contractAddress}.remainingMargin`) })}</td>
                                    <td>{formattedTokenAmount({ amount: _.get(vaultSynthetixPerps, `${contractAddress}.accessibleMargin`) })}</td>
                                    <td>
                                        <div className='vault-synthetix-perps--row-action'>
                                            <VaultExecutePopup
                                                triggerClassName={'vault-synthetix-perps--button transfer-margin'}
                                                triggerLabel={'Transfer Margin'}
                                                targetContract={contractAddress}
                                                vaultAddress={vaultAddress}
                                                abi={PERPS_V2_MARKET_CONSOLIDATED_ABI}
                                                functionSigHashOrName={'transferMargin'} />
                                            <VaultExecutePopup
                                                triggerClassName={'vault-synthetix-perps--button'}
                                                triggerLabel={'Withdraw All Margin'}
                                                targetContract={contractAddress}
                                                vaultAddress={vaultAddress}
                                                abi={PERPS_V2_MARKET_CONSOLIDATED_ABI}
                                                functionSigHashOrName={'withdrawAllMargin'} />
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

VaultSynthetixPerps.propTypes = {
    vaultAddress: PropTypes.string.isRequired
}

export default memo(VaultSynthetixPerps)