import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import { arrayMoveImmutable } from 'array-move'

import { IoIosClose } from 'react-icons/io'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { getPricePrecisionBySymbolItem } from '../../util/symbolUtil'


const SortablePricingItem = SortableElement(({ pricingItem, symbolItem, onClickRemoveButton }) => {
    const pricePrecision = getPricePrecisionBySymbolItem(symbolItem)
    function renderBidAskPrice (type) {
        const displayPrice = type === 'BID' && Number(pricingItem.bid) > 0 ? Number(pricingItem.bid).toFixed(pricePrecision)
            : type === 'ASK' && Number(pricingItem.ask) > 0 ? Number(pricingItem.ask).toFixed(pricePrecision)
            : 'N/A'
        return (
            <div className={'pricing-container--item--bid-ask-section ' + type}>
                <span className='pricing-container--item--bid-ask-section--label'>{type === 'BID' ? 'Bid' : 'Ask'}</span>
                <div className='pricing-container--item--bid-ask-section--pricing'>{displayPrice}</div>
            </div>
        )
    } 
    return (
        <div className='pricing-container--item' key={pricingItem.symbolName}>
            <button className='pricing-container--item--remove-button' onClick={() => { onClickRemoveButton(pricingItem) }}><IoIosClose /></button>
            <div className='pricing-container--item--header clearfix'>
                <div className='pricing-container--item--timestamp'>{pricingItem.timestamp ? moment(pricingItem.timestamp).format('HH:mm:ss') : ''}</div>
                <div className='pricing-container--item--symbol-name'>{pricingItem.symbolName}</div>
            </div>
            <div className='pricing-container--item--price-wrapper'>
                {renderBidAskPrice('BID')}
                {renderBidAskPrice('ASK')}
                <div className='pricing-container--item--last-price'>
                    <span className='pricing-container--item--last-price--label'>{'Last Price'}</span>
                    <span>{Number(pricingItem.last) > 0 ? Number(pricingItem.last).toFixed(pricePrecision) : 'N/A'}</span>
                </div>
            </div>
        </div>
    )
})

const SortablePricingList = SortableContainer(({ pricingItems, symbolItems, onClickRemoveButton }) => {
    return (
        <div className='pricing-container--items'>
            {pricingItems.map((pricingItem, index) => {
                return (
                    <SortablePricingItem
                        key={pricingItem.symbolName}
                        index={index}
                        pricingItem={pricingItem} 
                        symbolItem={symbolItems[pricingItem.symbolName]}
                        onClickRemoveButton={onClickRemoveButton}/>
                )
            })}
        </div>
    )
})

// eslint-disable-next-line react/no-multi-comp
class PricingContainer extends Component {
    constructor (props) {
        super(props)

        this.state = {
            symbolNames: !_.isEmpty(localStorage.symbols) ?  localStorage.symbols.split(',') : ['btc_usdt_OKEX_SPT', 'eth_usdt_OKEX_SPT', 'XBTUSD_BITMEX_SWAP'],
            searchString: '',
            showSearchResult: false
        }

        this.searchNode = null
        this.handleClickWindow = this.handleClickWindow.bind(this)
    }

    componentDidMount () {
        window.addEventListener('click', this.handleClickWindow)
    }

    componentWillUnmount () {
        window.removeEventListener('click', this.handleClickWindow)
    }

    _updateSymbolNames (newSymbolNames) {
        localStorage.symbols = newSymbolNames.join(',')
        this.setState({
            symbolNames: newSymbolNames
        })
    }

    handleClickWindow (e) {
        const { showSearchResult } = this.state
        if (showSearchResult && this.searchNode && !this.searchNode.contains(e.target)) {
            this.setState({
                showSearchResult: false
            })
        }
    }

    handleClickSearchResultItem (pricingItem) {
        const { symbolNames } = this.state
        const newSymbolNames = _.union([pricingItem.symbolName], symbolNames)
        this._updateSymbolNames(newSymbolNames)
        this.setState({
            searchString: ''
        })
    }

    handleClickRemoveItem (symbolName) {
        const { symbolNames } = this.state
        const newSymbolNames = _.without(symbolNames, symbolName)
        this._updateSymbolNames(newSymbolNames)
    }

    renderSymbolSearch () {
        const { searchString, showSearchResult } = this.state
        const { symbol } = this.props
        const trimmedSearchString = searchString.toLowerCase().trim()
        const searchResultSymbols = _.filter(symbol.pricings, pricingItem => trimmedSearchString.length > 0 && pricingItem.symbolName.toLowerCase().includes(trimmedSearchString))
        return (
            <div className='pricing-container--search' ref={(node) => { this.searchNode = node }}>
                <input className='pricing-container--search--input' value={searchString}
                    placeholder={'Add Symbol'}
                    spellCheck={false}
                    onFocus={() => { 
                        this.setState({ showSearchResult: true }) 
                    }}
                    onChange={(e) => { this.setState({ searchString: e.target.value }) }} />
                {showSearchResult && !_.isEmpty(searchResultSymbols) && <div className='pricing-container--search--result'>
                    {searchResultSymbols.map((pricingItem) => {
                        return (
                            <div className='pricing-container--search--result-item' key={pricingItem.symbolName}
                                onClick={() => { this.handleClickSearchResultItem(pricingItem) }}>{pricingItem.symbolName}</div>
                        )
                    })}
                </div>}
            </div>
        )
    }

    render () {
        const { symbolNames } = this.state
        const { symbol } = this.props
        return (
            <div className='pricing-container'>
                {this.renderSymbolSearch()}
                <div className='pricing-container--body'>
                    <SortablePricingList 
                        pressDelay={200}
                        pricingItems={_.filter(symbolNames, symbolName => !_.isEmpty(symbol.pricings[symbolName])).map(symbolName => symbol.pricings[symbolName])}
                        symbolItems={symbol.items}
                        onClickRemoveButton={(pricingItem) => { this.handleClickRemoveItem(pricingItem.symbolName) }}
                        onSortEnd={({oldIndex, newIndex}) => {
                            const newSymbolNames = arrayMoveImmutable(symbolNames, oldIndex, newIndex)
                            this._updateSymbolNames(newSymbolNames)
                        }} />
                </div>
            </div>
        )
    }
}

PricingContainer.propTypes = {
    symbol: PropTypes.object.isRequired
}

function mapStateToProps (state) {
    return {
        symbol: state.symbol
    }
}

export default connect(mapStateToProps)(PricingContainer)