import { ELF_API_BASE_URL } from '../../configs/config'
import { secureFetch } from '../../util/util'

export const fetchWorkspaces = () => {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        const { username } = getState().auth
        dispatch(secureFetch(`${ELF_API_BASE_URL}/workspaces?username=${username}`))
        .then(response => response.json())
        .then(body => { resolve(body) })
        .catch(error => {
            console.error('fetchWorkspaces error: ', error)
            reject(error)
        })
    })
}

export const saveWorkspaces = (workspaces=[]) => {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        const { username } = getState().auth

        dispatch(secureFetch(`${ELF_API_BASE_URL}/workspaces?username=${username}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(workspaces)
        }))
        .then(response => resolve(response))
        .catch(error => {
            console.error('saveWorkspaces error: ', error)
            reject(error)
        })
    })
}