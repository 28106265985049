import _ from 'lodash'
import dotProp from 'dot-prop-immutable'

import { UPDATE_TIMER_ITEMS, REMOVE_TIMER_ITEMS } from './timerAction'
import { AUTH_LOGOUT } from '../auth/authAction'

const initialState = {
    items: {}    
}

export function timerReducer (state = initialState, action) {
    switch (action.type) {
        
        case UPDATE_TIMER_ITEMS:
            return dotProp.merge(state, 'items', action.timers)

        case REMOVE_TIMER_ITEMS:
            return dotProp.set(state, 'items', _.omit(state.items, action.timerIds))

        case AUTH_LOGOUT:
            return initialState

        default: 
            return state
    }
}