import React, { Component } from 'react'
import { connect } from 'react-redux'

import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer'
import WorkspaceContainer from '../components/workspace/WorkspaceContainer'
import { setPageTitleAndDescription } from '../util/util'

class WorkspacePage extends Component {
    constructor (props) {
        super(props)
    }

    componentDidMount () {
        setPageTitleAndDescription({
            title: 'Workspace - Antelope Technology'
        })
    }

    render () {
        return (
            <div className='workspace-page page'>
                <Header />
                <div className='workspace-page--body page--body'>
                    <WorkspaceContainer />
                </div>
                <Footer />
            </div>
        )
    }
}

WorkspacePage.propTypes = {
}

function mapStateToProps () {
    return {}
}

export default connect(mapStateToProps)(WorkspacePage)