import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import memoizeOne from 'memoize-one'
import _ from 'lodash'
import Modal from 'react-modal'
import dotProp from 'dot-prop-immutable'
import { MdClose } from 'react-icons/md'
import { FaAngleDown, FaAngleUp, FaCheck } from 'react-icons/fa'
import { FiAlertCircle, FiDownloadCloud, FiX } from 'react-icons/fi'

import LegEditor from './LegEditor'
import SearchSelect from '../common/searchSelect/SearchSelect'
import Popup from '../common/popup/Popup'
import Toggle from '../common/toggle/Toggle'
import ProfileActionPopup from './ProfileActionPopup'
import PortfolioAccountSelector from '../account/PortfolioAccountSelector'
import SymbolMultipleSelector from '../symbol/SymbolMultipleSelector'
import ProfileSnapshotContainer from './ProfileSnapshotContainer'

import { profileShape } from './ProfileItem'
import { createProfileItem, updateProfileParams, fetchProfileParams, updateProfileItem } from './profileAction'

import { getProfileExchangeNames, getExchangeDisplayName, getProfileSymbolNames } from '../../util/profileUtil'
import { createDefaultProfile, profileParameters, parameterTypes, createTmpParameterConfig } from '../../configs/profileConfig'
import { EXCHANGES_CAN_SUPPORT_SMART_POSITIONS } from '../../configs/tradingConfig'
import { toNumberInputValue } from '../../util/util'
import { filterAccountItemsByExchangeName } from '../../util/tradingUtil'
import { AiOutlineEdit } from 'react-icons/ai'
import { SERVERS } from '../../configs/config'
import { getSymbolAttributeByName } from '../../util/symbolUtil'
import ArrayOfStringInput from '../common/input/ArrayOfStringInput'

Modal.setAppElement('#root')
const ORCHID_PARAM_KEYS = ['ENABLE_SKEW', 'SKEW', 'SKEW_BASE_POS', 'SKEW_MULTI', 'SKEW_THRES_1', 'SKEW_THRES_2', 'ENABLE_EXPOSURE', 'ENABLE_VOL',
    'VOL_SKEW_UPDATE_1', 'VOL_THRESHOLD_1', 'VOL_SKEW_UPDATE_2', 'VOL_THRESHOLD_2', 'VOL_SKEW_UPDATE_3', 'VOL_THRESHOLD_3', 'VOL_SKEW_UPDATE_4', 'VOL_THRESHOLD_4', 'SINGLE_SIDE_VOL_ADJUST_THRESHOLD']
const OPTION_PARAM_KEYS = ['ENABLE_OPTION', 'VOL_SKEW', 'THEORY_VOL', 'VOL_INTEREST_RATE']
const ENET_PARAM_KEYS = ['ENABLE_ENET']
const CHAIN_DATA_KEYS = ['CHAIN_DATA_SUB', 'CHAIN_DATA_TYPE', 'CHAIN_DATA_CHAIN', 'CHAIN_CEX_FLOW_EXCH', 'CHAIN_CEX_FLOW_TOKEN', 'CHAIN_DATA_LOOP']

const _getStorageShouldShowSetting = () => {
    return localStorage.shouldShowProfileEditorSetting !== '0'
}

const _updateStorageShouldShowSetting = (shouldShow=false) => {
    localStorage.shouldShowProfileEditorSetting = shouldShow ? '1' : '0'
}

const _getStorageShouldShowQuote = () => {
    return localStorage.shouldShowProfileEditorQuote !== '0'
}

const _updateStorageShouldShowQuote = (shouldShow=false) => {
    localStorage.shouldShowProfileEditorQuote = shouldShow ? '1' : '0'
}

const _getStorageShouldShowHedge = () => {
    return localStorage.shouldShowProfileEditorHedge !== '0'
}

const _updateStorageShouldShowHedge = (shouldShow=false) => {
    localStorage.shouldShowProfileEditorHedge = shouldShow ? '1' : '0'
}

const _getStorageShouldShowOrchid = () => {
    return localStorage.shouldShowProfileEditorOrchid !== '0'
}

const _updateStorageShouldShowOrchid = (shouldShow=false) => {
    localStorage.shouldShowProfileEditorOrchid = shouldShow ? '1' : '0'
}

const _getStorageShouldShowOption = () => {
    return localStorage.shouldShowProfileEditorOption !== '0'
}

const _updateStorageShouldShowOption = (shouldShow=false) => {
    localStorage.shouldShowProfileEditorOption = shouldShow ? '1' : '0'
}

const _getStorageShouldShowEnet = () => {
    return localStorage.shouldShowProfileEditorEnet !== '0'
}

const _updateStorageShouldShowEnet = (shouldShow=false) => {
    localStorage.shouldShowProfileEditorEnet = shouldShow ? '1' : '0'
}

const _getStorageShouldShowChainData = () => {
    return localStorage.shouldShowProfileEditorChainData !== '0'
}

const _updateStorageShouldShowChainData = (shouldShow=false) => {
    localStorage.shouldShowProfileEditorChainData = shouldShow ? '1' : '0'
}

const _getStorageShouldShowTradingAccount = () => {
    return localStorage.shouldShowProfileEditorTraddingAccount !== '0'
}

const _updateStorageShouldShowTradingAccount = (shouldShow=false) => {
    localStorage.shouldShowProfileEditorTraddingAccount = shouldShow ? '1' : '0'
}

const _memoizedLspHedgeSymbolOptions = memoizeOne((symbolItems={}) => {
    const filteredSymbolItems = _.filter(symbolItems, symbolItem => {
        const { exchangeName } = getSymbolAttributeByName(symbolItem.symbol_name)
        return exchangeName === 'COINBASE'
    })
    return _.map(filteredSymbolItems, symbolItem => symbolItem.symbol_name)
})

class ProfileEditor extends Component {
    constructor (props) {
        super(props)
        this.state = {
            profile: props.profile,
            isSaving: false,
            isSaved: false,
            isSyncing: false,
            isSynced: false,
            syncFailed: false,
            message: null,
            lastParaLoadFailNotificationId: null,
            shouldShowSetting: _getStorageShouldShowSetting(),
            shouldShowQuote: _getStorageShouldShowQuote(),
            shouldShowHedge: _getStorageShouldShowHedge(),
            shouldShowOrchid: _getStorageShouldShowOrchid(),
            shouldShowOption: _getStorageShouldShowOption(),
            shouldShowEnet: _getStorageShouldShowEnet(),
            shouldShowChainData: _getStorageShouldShowChainData(),
            shouldShowTradingAccount: _getStorageShouldShowTradingAccount(),
            snapshotPopupId: 0
        }

        this.nonModifiableParamKeysOnProfileStart = ['ENABLE_SKEW', 'ENABLE_EXPOSURE', 'COG_COUNT_MODE']
    }

    static getDerivedStateFromProps (props, state) {
        const { notifications } = props
        const firstNotification = _.head(notifications) 
        if (!_.isNil(firstNotification) 
            && ['PARA_LOAD_FAIL', 'PARA_UPDATED'].includes(firstNotification.type)
            && firstNotification.profileId === state.profile.id) {
            if (firstNotification.type === 'PARA_LOAD_FAIL' 
                && !_.isEqual(firstNotification.id, state.lastParaLoadFailNotificationId)
                && state.isSaved) {
                return {
                    message: `PARA_LOAD_FAIL: ${firstNotification.message}`,
                    lastParaLoadFailNotificationId: firstNotification.id
                }
            } else if (firstNotification.type === 'PARA_UPDATED' 
                && !_.isNil(state.lastParaLoadFailNotificationId)
                && (state.message || '').includes('PARA_LOAD_FAIL')) {
                return {
                    message: null,
                    lastParaLoadFailNotificationId: null
                }
            }
        } else {
            return null
        }
    }

    componentDidUpdate (prevProps) {
        if (!_.isEqual(prevProps.profile, this.props.profile)) {
            this._updateProfile(this.props.profile)
        }
    }

    _updateProfile (profile) {
        this.setState({
            profile: profile
        })
    }

    handleClickSaveButton () {
        const { profile } = this.state
        const { dispatch, onSaveSuccess, mode } = this.props
        const action = mode === 'EDIT' ? updateProfileParams(profile.id, _.pick(profile, ['accounts', 'legs', 'params'])) : createProfileItem(profile)
        this.setState({ isSaving: true })
        dispatch(action).then((response) => {
            this.setState({
                isSaving: false,
                isSaved: response && response.status === 200,
                message: _.isError(response) ? response.toString() 
                    : (response && response.status === 403) ? `You are forbidden to update this profile's params`
                    : (response && response.status === 503) ? 'The server is under maintenance'
                    : null
            })
            if (response && response.status === 200) {
                onSaveSuccess()
            }
        })
    }

    handleClickResetButton () {
        const { profile } = this.props
        this.setState({
            profile: profile
        })
    }

    handleClickSyncButton () {
        const { profile } = this.state
        const { dispatch } = this.props
        this.setState({ 
            isSyncing: true,
            isSynced: false,
            syncFailed: false
        })
        dispatch(fetchProfileParams({ profileId: profile.id })).then((body) => {
            const success = body && !_.isEmpty(body[profile.name]) && !profile.started
            const syncedProfile = success ? Object.assign({}, profile, body[profile.name]) : null
            this.setState({
                isSyncing: false,
                isSynced: success,
                syncFailed: !success,
                profile: success ? syncedProfile : profile 
            })
            if (success) {
                dispatch(updateProfileItem(profile.id, syncedProfile))
            }
        })
    }

    ProfileSelector ({ selectedProfileName='', selectedProfileServer='', selectedProfileUser='', disabled, onChange=()=>{} }) {
        const { profileItems } = this.props
        const { profile } = this.state
        const currentHostname = _.get(profile, 'hostname')
        const options = _.map(
            _.filter(profileItems, profileItem => _.get(profileItem, 'hostname') !== currentHostname), 
            profileItem => {
                return {
                    value: profileItem.id,
                    name: `${profileItem.name} @ ${profileItem.hostname} - ${profileItem.user}`
                }
            })

        const enabledServers = _.filter(SERVERS, server => server.enabled)
        const serverOptions = _.map(enabledServers, server => {
            return {
                value: server.hostname,
                name: server.hostname
            }
        })

        const hasValidProfileItem = !_.isNil(_.find(profileItems, { name: selectedProfileName, hostname: selectedProfileServer, user: selectedProfileUser }))

        return (
            <div className='profile-editor--profile-selector'>
                <SearchSelect 
                    value={hasValidProfileItem ? `${selectedProfileName}_${selectedProfileServer}` : `Not Found: ${selectedProfileName} @ ${selectedProfileServer} - ${selectedProfileUser}`}
                    placeholder={'Select Profile'}
                    options={options} 
                    disabled={disabled}
                    onChange={(newOption) => {
                        const newProfileItem = _.get(profileItems, newOption.value)
                        if (newProfileItem) {
                            onChange(newProfileItem)
                        }
                    }} />
                <Popup className='profile-editor--profile-selector--popup'
                    on={'click'}
                    disabled={disabled}
                    trigger={<button className='profile-editor--profile-selector--popup--trigger' disabled={disabled}><AiOutlineEdit /></button>}>
                    <div className='profile-editor--profile-selector--popup--main'>
                        <div>
                            <label>{'Peer Profile Name'}</label>
                            <input type={'text'}
                                value={_.get(profile, 'params.PEER_PROFILE_NAME')}
                                spellCheck={false}
                                onChange={(e) => {
                                    this.setState({  
                                        profile: dotProp.set(profile, 'params.PEER_PROFILE_NAME', e.target.value),
                                        isSaved: false,
                                        message: null
                                    })
                                }} />
                        </div>
                        <div onClick={(e) => { e.stopPropagation() }}>
                            <label>{'Peer Profile Server'}</label>
                            <SearchSelect className='profile-editor--profile-selector--server-selector' 
                                options={serverOptions}
                                value={_.get(profile, 'params.PEER_PROFILE_SERVER')} 
                                hideSearchBar
                                onChange={(newOption) => {
                                    this.setState({
                                        profile: dotProp.set(profile, 'params.PEER_PROFILE_SERVER', newOption.value),
                                        isSaved: false,
                                        message: null
                                    })
                                }} />
                        </div>
                        <div>
                            <label>{'Peer Profile User'}</label>
                            <input type={'text'}
                                value={_.get(profile, 'params.PEER_PROFILE_USER')}
                                spellCheck={false}
                                onChange={(e) => {
                                    this.setState({  
                                        profile: dotProp.set(profile, 'params.PEER_PROFILE_USER', e.target.value),
                                        isSaved: false,
                                        message: null
                                    })
                                }} />
                        </div>
                    </div>
                </Popup>
            
            </div>
        )
    }

    renderName () {
        const { profile } = this.state
        const { mode } = this.props
        return (
            <div className='profile-editor--name'>
                <span>{'Name'}</span>
                <input className='profile-editor--name-input' type='text'
                    value={profile.name}
                    placeholder={'Type the name'} 
                    spellCheck={false} 
                    autoFocus 
                    disabled={mode !== 'CREATE'}
                    onChange={(e) => {
                        this.setState({
                            profile: dotProp.set(profile, 'name', e.target.value),
                            isSaved: false,
                            message: null
                        })
                    }} />
            </div>
        )
    }

    renderSettings ({ title, pickedKeys=[], omitKeys=[], shouldShowParameters=false, others, onClickTitle=()=>{} }) {
        const { nonModifiableParamKeysOnProfileStart } = this
        const { profile } = this.state
        const { symbolItems } = this.props
        let filteredParams = _.cloneDeep(profile.params)
        if (!_.isEmpty(pickedKeys)) {
            filteredParams = _.pick(filteredParams, pickedKeys)
        }
        if (!_.isEmpty(omitKeys)) {
            filteredParams = _.omit(filteredParams, omitKeys)
        }        

        const shouldRenderRunningMode = _.has(filteredParams, 'RUNNING_MODE') && _.has(profileParameters, `RUNNING_MODE.options`)
        const runningMode = _.get(filteredParams, 'RUNNING_MODE')
        const lspHedgeSymbolOptions = _memoizedLspHedgeSymbolOptions(symbolItems)

        return (
            <div className='profile-editor--settings'>
                <div className='profile-editor--settings--title'
                    onClick={() => { onClickTitle() }}>
                    {title || 'Setting'}
                    {shouldShowParameters ? <FaAngleUp /> : <FaAngleDown />}
                </div>
                {shouldShowParameters &&
                <div className='profile-editor--settings--parameters'>
                    {shouldRenderRunningMode && 
                    <Fragment>
                        <div className='profile-editor--settings--parameter-row'>
                            <div className='profile-editor--settings--parameter-name'>{'Running Mode'}</div>
                            <Popup
                                disabled={!profile.started}
                                className='profile-editor--settings--disabled-popup'
                                shouldForceLeftBiased
                                trigger={
                                    <div className='profile-editor--settings--parameter-value'>
                                        <SearchSelect className={'leg-editor--parameter--search-select leg-editor--parameter--running-mode'} 
                                            value={runningMode}
                                            placeholder={'Select Type'}
                                            disabled={profile.started}
                                            hideSearchBar
                                            options={profileParameters['RUNNING_MODE'].options.map((optionValue) => {
                                                return { value: optionValue, name: optionValue}
                                            })} 
                                            onChange={(newOption) => { 
                                                this.setState({
                                                    profile: dotProp.set(profile, `params.RUNNING_MODE`, newOption.value),
                                                    isSaved: false,
                                                    message: null
                                                })
                                            }} />
                                    </div>
                                }>
                                {'You can update Running Mode only when the profile is STOPPED.'}
                            </Popup>
                        </div>
                        {['QUOTE', 'HEDGE'].includes(runningMode) &&
                        <div className='profile-editor--settings--parameter-row'>
                            <div className='profile-editor--settings--parameter-name'>{'Peer Profile'}</div>
                            <Popup
                                disabled={!profile.started}
                                className='profile-editor--settings--disabled-popup'
                                shouldForceLeftBiased
                                trigger={
                                    <div className='profile-editor--settings--parameter-value'>
                                        {this.ProfileSelector({
                                            selectedProfileName: _.get(profile, 'params.PEER_PROFILE_NAME'),
                                            selectedProfileServer: _.get(profile, 'params.PEER_PROFILE_SERVER'),
                                            selectedProfileUser: _.get(profile, 'params.PEER_PROFILE_USER'),
                                            disabled: profile.started,
                                            onChange: (newProfileItem) => {
                                                const newProfile = dotProp.merge(profile, 'params', {
                                                    PEER_PROFILE_NAME: newProfileItem.name,
                                                    PEER_PROFILE_SERVER: newProfileItem.hostname,
                                                    PEER_PROFILE_USER: newProfileItem.user
                                                })
                                                this.setState({
                                                    profile: newProfile,
                                                    isSaved: false,
                                                    message: null
                                                })
                                            }
                                        })}
                                    </div>
                                }>
                                {'You can update peer profile only when the profile is STOPPED.'}
                            </Popup>
                        </div>}
                        {runningMode === 'LSP_HEDGE' && 
                        <div className='profile-editor--settings--parameter-row'>
                            <div className='profile-editor--settings--parameter-name'>{'LSP Hedge Symbols'}</div>
                            <Popup
                                disabled={!profile.started}
                                className='profile-editor--settings--disabled-popup'
                                shouldForceLeftBiased
                                trigger={
                                    <div className='profile-editor--settings--parameter-value'>
                                        <SymbolMultipleSelector symbolOptions={lspHedgeSymbolOptions} 
                                            selectedSymbolNames={_.filter(_.get(profile, 'params.LSP_HEDGE_SYMBOLS'), name => name !== 'INVALID')} 
                                            popupAlign={'vertical'}
                                            trigger={<div className={'profile-editor--settings--symbol-multiple-selector-trigger' + (profile.started ? ' disabled' : '')}>
                                                {(_.get(profile, 'params.LSP_HEDGE_SYMBOLS') || []).join(', ')}
                                            </div>}
                                            disabled={profile.started}
                                            onChangeSelectedSymbolNames={(newSymbolNames) => {
                                                const formattedSymbolNames = _.isEmpty(newSymbolNames) ? ['INVALID'] : _.filter(newSymbolNames, name => name !== 'INVALID')
                                                const newProfile = dotProp.set(profile, `params.LSP_HEDGE_SYMBOLS`, formattedSymbolNames)
                                                this.setState({
                                                    profile: newProfile,
                                                    isSaved: false,
                                                    message: null
                                                })
                                            }} />
                                    </div>
                                }>
                                {'You can update the LSP Hedge Symbols only when the profile is STOPPED. '}
                            </Popup>
                        </div>}
                    </Fragment>}
                    {_.map(filteredParams, (value, key) => {
                        const keysWithSubStringToOmit = ['_SMART_POS_ASK_ACCOUNTS', '_SMART_POS_BID_ACCOUNTS', '_SMART_POS_SWITCH_INTERVAL_IN_SEC', '_SMART_POS_BACKUP_ACCOUNT',
                            '_SMART_POS_ASK_MARGINRATIO_THRESHOLD', '_SMART_POS_BID_MARGINRATIO_THRESHOLD', '_SMART_ACCTS_ROUND_ROBIN_SWITCH', 'VENUE_ACCOUNT_NAME']
                        const parameterConfig = profileParameters[key] || createTmpParameterConfig(key, value)
                        const _disabled = profile.started && nonModifiableParamKeysOnProfileStart.includes(key)
                        return parameterConfig && !_.some(keysWithSubStringToOmit, substring => key.includes(substring)) && key !== 'RUNNING_MODE' ? (
                            <div className='profile-editor--settings--parameter-row' key={key}>
                                <div className='profile-editor--settings--parameter-name'>
                                    {!_.isEmpty(parameterConfig.description) ? <Popup 
                                        className='profile-editor--settings--parameter-description'
                                        trigger={<span className='has-description'>{parameterConfig.name}</span>}>
                                        <span dangerouslySetInnerHTML={{ __html: parameterConfig.description }} />
                                    </Popup> : <span>{parameterConfig.name}</span>}
                                </div>
                                <Popup
                                    disabled={!_disabled}
                                    className='profile-editor--settings--disabled-popup'
                                    shouldForceLeftBiased
                                    trigger={
                                        <div className='profile-editor--settings--parameter-value'>
                                        {parameterConfig.type === parameterTypes.BOOLEAN && 
                                        <Toggle
                                            checked={value}
                                            disabled={_disabled}
                                            onChange={(checked) => {
                                            this.setState({
                                                profile: dotProp.set(profile, `params.${key}`, checked),
                                                isSaved: false,
                                                message: null
                                            })
                                        }} />}
                                        {parameterConfig.type === parameterTypes.NUMBER && 
                                        <input type={'number'} 
                                            value={value}
                                            disabled={_disabled}
                                            onWheel={(e) => { e.target.blur() }} 
                                            onChange={(e) => {
                                                this.setState({
                                                    profile: dotProp.set(profile, `params.${key}`, toNumberInputValue(e.target.value)),
                                                    isSaved: false,
                                                    message: null
                                                })
                                            }} />}
                                        {parameterConfig.type === parameterTypes.STRING && !_.isEmpty(parameterConfig.options) &&
                                        <SearchSelect
                                            className={'profile-editor--settings--parameter-value--search-select'}
                                            value={value}
                                            placeholder={'Select Type'}
                                            disabled={_disabled}
                                            hideSearchBar
                                            options={parameterConfig.options.map((optionValue) => {
                                                return { value: optionValue, name: optionValue}
                                            })} 
                                            onChange={(newOption) => { 
                                                this.setState({
                                                    profile: dotProp.set(profile, `params.${key}`, newOption.value),
                                                    isSaved: false,
                                                    message: null
                                                })
                                            }} />}
                                        {parameterConfig.type === parameterTypes.STRING && _.isEmpty(parameterConfig.options) &&
                                        <input
                                            className='profile-editor--settings--parameter-value--string-input'
                                            type={'text'}
                                            value={value}
                                            placeholder='Input value'
                                            disabled={_disabled}
                                            onWheel={(e) => { e.target.blur() }} 
                                            onChange={(e) => {
                                                this.setState({
                                                    profile: dotProp.set(profile, `params.${key}`, e.target.value),
                                                    isSaved: false,
                                                    message: null
                                                })
                                            }} />}
                                        {parameterConfig.type === parameterTypes.STRING_ARRAY &&
                                        <ArrayOfStringInput
                                            className={'profile-editor--settings--parameter-value--array-of-string-input'}
                                            values={value}
                                            immutableValues={['INVALID']}
                                            onChange={(_newValue) => {
                                                this.setState({
                                                    profile: dotProp.set(profile, `params.${key}`, _.isEmpty(_newValue) ? ['INVALID'] : _.without(_newValue, 'INVALID')),
                                                    isSaved: false,
                                                    message: null
                                                })
                                            }} />}
                                    </div>}>
                                {'This field is modifiable only when the profile is STOPPED.'}
                            </Popup>

                            </div>
                        ) : null
                    })}
                </div>}
                {shouldShowParameters && others && <div className='profile-editor--settings--others'>{others}</div>}
            </div>
        )
    }

    renderLeg ({ legNumber, shouldShowParameters=false, onClickHeader=()=>{} }) {
        const { profile } = this.state
        const { mode } = this.props
        const legItem = profile.legs[legNumber]
        return (
            <div className={`profile-editor--leg leg-${legNumber}`}>
                <div className='profile-editor--leg--header' onClick={() => { onClickHeader() }}>
                    <div className='profile-editor--leg--title'>
                        {legNumber === 1 ? 'Quote' : 'Hedge'}
                    </div>
                    {shouldShowParameters ? <FaAngleUp /> : <FaAngleDown />}
                </div>
                {shouldShowParameters && <div className='profile-editor--leg--editor'>
                    <LegEditor
                        profileId={mode === 'EDIT' ? profile.id : null}
                        legNumber={legNumber} 
                        legItem={legItem}
                        disableSymbolModification={profile.started}
                        isProfileStarted={profile.started}
                        shouldAllowEmptySymbol={['HEDGE', 'LSP_HEDGE'].includes(_.get(profile, 'params.RUNNING_MODE')) && legNumber === 1}
                        symbolParamKeyFilter={(paramKey) => !_.startsWith(paramKey, 'QUOTE_ENET')}
                        onChangeLeg={(newLeg) => {
                            const newProfile = dotProp.set(profile, `legs.${legNumber}`, newLeg)
                            const exchangeNames = getProfileExchangeNames(newProfile)
                            exchangeNames.forEach(exchangeName => {
                                newProfile.accounts[exchangeName] = profile.accounts[exchangeName] || 'INVALID'
                            })
                            this.setState({
                                profile: newProfile,
                                isSaved: false,
                                message: null
                            })
                        }} />
                </div>}
            </div>
        )
    }

    renderSmartPosAccounts (exchangeName, direction='BID') {
        const { profile } = this.state
        const { account } = this.props
        const directionName = direction === 'BID' ? 'BUY' : 'SELL'
        const paramKey = `${exchangeName}_SMART_POS_${direction}_ACCOUNTS`
        const accountNames = _.has(profile, `params.${paramKey}`) && _.isArray(profile.params[paramKey])
            ? (profile.params[paramKey].filter(accountName => ![undefined, null, 'INVALID'].includes(accountName)) || []) 
            : []
        const accountItemOptions = filterAccountItemsByExchangeName(account.items, exchangeName)
        return (
            <div className='profile-editor--smart-pos-accounts'>
                <div className={`profile-editor--smart-pos-accounts--direction ${directionName}`}>{directionName}</div>
                <div className='profile-editor--smart-pos-accounts--list clearfix'>
                    <PortfolioAccountSelector
                        className={'profile-editor--smart-pos-accounts--selector'}
                        accountItemOptions={accountItemOptions} 
                        selectedAccountNames={accountNames}
                        onChangeSelectedAccountNames={(newAccountNames) => {
                            let newProfile = dotProp.set(profile, `params.${paramKey}`, !_.isEmpty(newAccountNames) ? newAccountNames : ['INVALID'])
                            if (_.has(newProfile, `params.${exchangeName}_SMART_POS_BACKUP_ACCOUNT`) 
                                && newProfile.params[`${exchangeName}_SMART_POS_BACKUP_ACCOUNT`] !== 'INVALID' 
                                && newAccountNames.includes(newProfile.params[`${exchangeName}_SMART_POS_BACKUP_ACCOUNT`])) {
                                newProfile = dotProp.set(newProfile, `params.${exchangeName}_SMART_POS_BACKUP_ACCOUNT`, 'INVALID')
                            }
                            this.setState({
                                profile: newProfile,
                                isSaved: false,
                                message: null
                            })
                        }} />
                    <button className={`profile-editor--smart-pos-accounts--copy-button ${direction}`}
                        onClick={() => { 
                            const newSmartPosAccounts = profile.params[`${exchangeName}_SMART_POS_${direction==='BID' ? 'ASK' : 'BID'}_ACCOUNTS`]
                            this.setState({
                                profile: dotProp.set(profile, `params.${paramKey}`, newSmartPosAccounts),
                                isSaved: false,
                                message: null
                            })
                        }}>
                        {`Copy From ${direction === 'BID' ? 'SELL' : 'BUY'}`}
                    </button>
                </div>
            </div>
        )
    }

    renderAccounts () {
        const { account } = this.props
        const { profile, shouldShowTradingAccount } = this.state
        const exchangesToBeConfigured = getProfileExchangeNames(profile)
        const exchangeToAccountMap = exchangesToBeConfigured.reduce((map, exchangeName) => {
            map[exchangeName] = profile.accounts[exchangeName] || 'INVALID'
            return map
        }, {})

        const VenueAccountName = () => {
            const symbolNames = getProfileSymbolNames(profile, false)
            let validExchangeNames = []
            _.forEach(symbolNames, symbolName => {
                const { exchangeName, compositSymbols } = getSymbolAttributeByName(symbolName)
                if (exchangeName === 'PARADIGM') {
                    _.forEach(compositSymbols, compositSymbol => {
                        validExchangeNames.push(getSymbolAttributeByName(compositSymbol).exchangeName)
                    })
                }
            })
            validExchangeNames = _.uniq(validExchangeNames)
            const _accountOptions = _.map(
                _.filter(account.items, accountItem => validExchangeNames.includes(_.get(accountItem, 'exchange_name'))), 
                accountItem => {
                    return {
                        value: accountItem.account_name,
                        name: accountItem.account_name
                    }
                }
            )
            return (
                <div className='profile-editor--accounts--venue-account venue-account'>
                    <label>{`Venue Account`}</label>
                    <div className='profile-editor--accounts--venue-account--main'>
                        <Popup
                            disabled={!profile.started}
                            className='profile-editor--accounts--disabled-popup'
                            trigger={
                                <div className='profile-editor--accounts--account-name'>
                                    <SearchSelect
                                        className='account-name-search-select' 
                                        options={_accountOptions} 
                                        value={_.get(profile, 'params.VENUE_ACCOUNT_NAME')}
                                        optionPosition={'top'}
                                        placeholder={'Select Venue Account'}
                                        disabled={profile.started}
                                        shouldApplyOptionValueToClassName
                                        onChange={(option) => {
                                            this.setState({
                                                profile: dotProp.set(profile, `params.VENUE_ACCOUNT_NAME`, option.value),
                                                isSaved: false,
                                                message: null
                                            })
                                        }} />
                                </div>
                            }>
                            <span>{'You are allowed to update accounts only when the profile is '}<strong>{'STOPPED'}</strong>{'.'}</span>
                        </Popup>
                    </div>
                </div>
            )
        }

        return (
            <div className='profile-editor--accounts'>
                <div className='profile-editor--accounts--title' 
                    onClick={() => {
                        _updateStorageShouldShowTradingAccount(!shouldShowTradingAccount)
                        this.setState({ shouldShowTradingAccount: _getStorageShouldShowTradingAccount() })
                    }}>
                    {'Trading Account'}
                    {shouldShowTradingAccount ? <FaAngleUp /> : <FaAngleDown />}
                </div>
                {shouldShowTradingAccount && <div className='profile-editor--accounts--list'>
                    {_.isEmpty(profile.accounts)
                    ? <div className='profile-editor--accounts--empty-message'>{'Accounts can be configured after you select any symbols'}</div>
                    : (_.map(exchangeToAccountMap, (accountName, exchangeName) => {
                        const filteredAccountItems = filterAccountItemsByExchangeName(account.items, exchangeName)
                        const accountOptions = filteredAccountItems.map(accountItem => {
                            return {
                                value: accountItem['account_name'],
                                name: accountItem['account_name']
                            }
                        })
                        if (EXCHANGES_CAN_SUPPORT_SMART_POSITIONS.includes(exchangeName)) {
                            accountOptions.unshift({
                                value: 'smart_pos_acct',
                                name: 'Smart Position Accounts'
                            })
                        }
                        const selectedSmartAccountNames = _.has(profile, 'params') ? _.union(profile.params[`${exchangeName}_SMART_POS_BID_ACCOUNTS`] || [], profile.params[`${exchangeName}_SMART_POS_ASK_ACCOUNTS`] || []) : []
                        return (
                            <Fragment key={exchangeName}>
                                <div className='profile-editor--accounts--item'>
                                    <div className='profile-editor--accounts--exchange-name'>{getExchangeDisplayName(exchangeName)}</div>
                                    <div className='profile-editor--accounts--exchange-accounts'>
                                        <div className='profile-editor--accounts--exchange-account-selector-row'>
                                            <Popup
                                                disabled={!profile.started}
                                                className='profile-editor--accounts--disabled-popup'
                                                trigger={
                                                    <div className='profile-editor--accounts--account-name'>
                                                        <SearchSelect
                                                            className='account-name-search-select' 
                                                            options={accountOptions} 
                                                            value={accountName}
                                                            optionPosition={'top'}
                                                            placeholder={'Select Trading Account'}
                                                            hasClearButton={exchangeName.includes('OKEX')}
                                                            disabled={profile.started}
                                                            shouldApplyOptionValueToClassName
                                                            onClickClearButton={() => {
                                                                this.setState({
                                                                    profile: dotProp.set(profile, `accounts.${exchangeName}`, 'INVALID'),
                                                                    isSaved: false,
                                                                    message: null
                                                                })
                                                            }}
                                                            onChange={(option) => {
                                                                this.setState({
                                                                    profile: dotProp.set(profile, `accounts.${exchangeName}`, option.value),
                                                                    isSaved: false,
                                                                    message: null
                                                                })
                                                            }} />
                                                    </div>
                                                }>
                                                <span>{'You are allowed to update accounts only when the profile is '}<strong>{'STOPPED'}</strong>{'.'}</span>
                                            </Popup>
                                            {accountName === 'smart_pos_acct' && 
                                            <div className='profile-editor--accounts--smart-pos-switch-interval'>
                                                <div className='profile-editor--accounts--smart-pos-switch-interval--name'>{'Switch Interval(Seconds)'}</div>
                                                <input className='profile-editor--accounts--smart-pos-switch-interval--value' 
                                                    value={profile.params[`${exchangeName}_SMART_POS_SWITCH_INTERVAL_IN_SEC`]} 
                                                    onChange={(e) => {
                                                        this.setState({
                                                            profile: dotProp.set(profile, `params.${exchangeName}_SMART_POS_SWITCH_INTERVAL_IN_SEC`, toNumberInputValue(e.target.value)),
                                                            isSaved: false,
                                                            message: null
                                                        })
                                                    }} 
                                                    onWheel={(e) => { e.target.blur() }}
                                                    onBlur={(e) => { 
                                                        if (e.target.value.trim().length === 0) {
                                                            this.setState({
                                                                profile: dotProp.set(profile, `params.${exchangeName}_SMART_POS_SWITCH_INTERVAL_IN_SEC`, profileParameters[`${exchangeName}_SMART_POS_SWITCH_INTERVAL_IN_SEC`].defaultValue)
                                                            })
                                                        }
                                                    }} />
                                            </div>}
                                            {exchangeName === 'PARADIGM' && _.has(profile, 'params.VENUE_ACCOUNT_NAME') && VenueAccountName()}
                                        </div>
                                        {accountName === 'smart_pos_acct' && 
                                        <div className='profile-editor--accounts--smart-accounts-wrapper'>
                                            {this.renderSmartPosAccounts(exchangeName, 'BID')}
                                            {this.renderSmartPosAccounts(exchangeName, 'ASK')}
                                            {_.has(profile, `params.${exchangeName}_SMART_POS_BACKUP_ACCOUNT`) &&
                                            <div className='profile-editor--accounts--smart-pos-backup-account'>
                                                <label>{'Backup'}</label>
                                                <SearchSelect className='profile-editor--accounts--smart-pos-backup-account--search-select'
                                                    options={_.filter(accountOptions, option => !selectedSmartAccountNames.includes(option.value) && option.value !== 'smart_pos_acct')}
                                                    value={profile.params[`${exchangeName}_SMART_POS_BACKUP_ACCOUNT`]}
                                                    placeholder={'Select Account'}
                                                    optionPosition={'top'}
                                                    hasClearButton
                                                    onClickClearButton={() => {
                                                        this.setState({
                                                            profile: dotProp.set(profile, `params.${exchangeName}_SMART_POS_BACKUP_ACCOUNT`, 'INVALID'),
                                                            isSaved: false,
                                                            message: null
                                                        })
                                                    }}
                                                    onChange={(option) => {
                                                        this.setState({
                                                            profile: dotProp.set(profile, `params.${exchangeName}_SMART_POS_BACKUP_ACCOUNT`, option.value),
                                                            isSaved: false,
                                                            message: null
                                                        })
                                                    }} />
                                            </div>}
                                            {_.has(profile, `params.${exchangeName}_SMART_ACCTS_ROUND_ROBIN_SWITCH`) &&
                                            <div className='profile-editor--accounts--smart-accts-round-robin-switch'>
                                                <label>{'Round Robin Switch'}</label>
                                                <Toggle
                                                    checked={profile.params[`${exchangeName}_SMART_ACCTS_ROUND_ROBIN_SWITCH`]}
                                                    onChange={(checked) => {
                                                    this.setState({
                                                        profile: dotProp.set(profile, `params.${exchangeName}_SMART_ACCTS_ROUND_ROBIN_SWITCH`, checked),
                                                        isSaved: false,
                                                        message: null
                                                    })
                                                }} />
                                            </div>}
                                        </div>}
                                    </div>
                                </div>
                            </Fragment>
                        )
                    }))}
                </div>}
            </div>
        )
    }  

    renderHeader () {
        const { profile, isSyncing, isSynced, syncFailed, snapshotPopupId } = this.state
        const { mode, onClickClose } = this.props
        const profileStartStopStatus = profile.isStarting ? 'starting' : profile.isStopping ? 'stopping' : profile.started ? 'started' : 'stopped'
        const profileResumePauseStatus = profile.isResuming ? 'resuming' : profile.isPausing ? 'pausing' : profile.resumed ? 'resumed' : 'paused'

        return (
            <div className='profile-editor--header'>
                <div className='profile-editor--title'>{mode === 'CREATE' ? 'New Profile' : 'Edit Profile: ' + profile.name}</div>
                <button className='profile-editor--close-button' 
                    onClick={() => {
                        if (_.isFunction(onClickClose)) {
                            onClickClose()
                        }
                    }}><MdClose /></button>
                <div className='profile-editor--status-wrapper clearfix'>
                    <div className='profile-editor--status-wrapper--indicators'>
                        {profile.crashed && <div className='profile-editor--status crashed-status'>
                            <FiAlertCircle />
                            <div className='profile-editor--status--label'>{'CRASHED'}</div>
                        </div>}
                        <div className='profile-editor--status start-stop-status'>
                            <div className={`profile-editor--status--bulb ${profileStartStopStatus}`} />
                            <div className='profile-editor--status--label'>{profileStartStopStatus.toUpperCase()}</div>
                        </div>
                        {profile.started && !profile.isStarting && !profile.isStopping &&
                        <div className='profile-editor--status resume-pause-status'>
                            <div className={`profile-editor--status--bulb ${profileResumePauseStatus}`} />
                            <div className='profile-editor--status--label'>{profileResumePauseStatus.toUpperCase()}</div>
                        </div>}
                    </div>
                    <div className='profile-editor--status-wrapper--right-section'>
                        <div className='profile-editor--status-wrapper--sync'>
                            <div className={'profile-editor--status-wrapper--sync--label' + 
                                (isSyncing ? ' syncing' : isSynced ? ' synced' : syncFailed ? ' sync-failed' : '')}>
                                {isSyncing ? 'Syncing...' : isSynced ? 'Synced' : syncFailed ? 'Sync Failed' : ''}
                            </div>
                            <Popup
                                className='profile-editor--status-wrapper--sync--popup'
                                disabled={profileStartStopStatus === 'stopped'}
                                trigger={
                                <button className='profile-editor--status-wrapper--sync--button'
                                    disabled={profileStartStopStatus !== 'stopped' || isSyncing}
                                    onClick={() => { this.handleClickSyncButton() }}>
                                    <FiDownloadCloud />
                                    <span>{'Sync'}</span>
                                </button>}>
                                <span>{'Sync the profile with the remote one.' + 
                                    (profileStartStopStatus !== 'stopped' ? ' You are allowed to sync it only when the profile is STOPPED.' : '')}
                                </span>
                            </Popup>
                        </div>
                        <Popup
                            key={snapshotPopupId}
                            className='profile-editor--status-wrapper--snapshots--popup'
                            on={'click'}
                            trigger={<button className='profile-editor--status-wrapper--snapshots--trigger'>{'Configuraiton Snapshots'}</button>}>
                            <ProfileSnapshotContainer profileItem={profile}
                                onApplySnapshot={(_snapshot) => {
                                    if (_snapshot?.profileId === profile?.id) {
                                        let newProfile = _.cloneDeep(profile)
                                        newProfile = Object.assign({}, _.cloneDeep(profile), _.pick(_snapshot?.data, ['accounts', 'legs', 'params']))
                                        this.setState({
                                            profile: newProfile,
                                            snapshotPopupId: snapshotPopupId + 1
                                        })
                                    }
                                }} />
                        </Popup>
                        <ProfileActionPopup 
                            triggerClassName={'profile-editor--action-trigger-button'}
                            profileItem={profile} />
                    </div>
                </div>
            </div>
        )
    }

    renderFooter () {
        const { isSaving, isSaved, message } = this.state
        const { mode, onClickClose } = this.props
        return (
            <div className='profile-editor--footer'>
                {!_.isEmpty(message) && <div className='profile-editor--message'>
                    <span dangerouslySetInnerHTML={{ __html: message }} />
                    <button className='profile-editor--message--close-button' onClick={() => {
                        this.setState({ message: null })
                    }}><FiX /></button>
                </div>}
                <div className='profile-editor--buttons'>
                    {mode === 'EDIT' && !isSaved && <button className='profile-editor--reset-button' 
                        onClick={() => { this.handleClickResetButton() }}>{'RESET'}</button>}
                    {isSaved && <button className='profile-editor--bottom-close-button' onClick={() => { onClickClose() }}>{'Close'}</button>}
                    <button className={'profile-editor--save-button' + (isSaved ? ' is-saved' : '')} 
                        disabled={isSaving || isSaved}
                        onClick={() => { this.handleClickSaveButton() }}>
                        {isSaving ? 'Saving...' 
                        : isSaved ? <span><FaCheck className='profile-editor--saved-icon'/>{'Saved'}</span> : 'Save'}
                    </button>
                </div>
            </div>
        )
    }

    render () {
        const { mode } = this.props
        const { profile, shouldShowSetting, shouldShowQuote, shouldShowHedge, shouldShowOrchid, shouldShowOption, shouldShowEnet, shouldShowChainData } = this.state
        const profileHasOrchidParams = _.some(ORCHID_PARAM_KEYS, key => _.has(profile.params, key))
        const profileHasOptionParams = _.some(OPTION_PARAM_KEYS, key => _.has(profile.params, key))
        const profileHasEnetParams = _.some(ENET_PARAM_KEYS, key => _.has(profile.params, key))
        const profileHasChainDataParams = _.some(CHAIN_DATA_KEYS, key => _.has(profile.params, key))

        return (
            <Modal
                overlayClassName='modal-overlay'
                className='profile-editor horizontal-centered'
                isOpen>
                {this.renderHeader()}
                <div className='profile-editor--main'>
                    {/* {this.renderName()} */}
                    {this.renderSettings({ 
                        omitKeys: _.concat(ORCHID_PARAM_KEYS, OPTION_PARAM_KEYS, ENET_PARAM_KEYS, CHAIN_DATA_KEYS, 'PEER_PROFILE_NAME', 'PEER_PROFILE_SERVER', 'PEER_PROFILE_USER', 'LSP_HEDGE_SYMBOLS'),
                        shouldShowParameters: shouldShowSetting,
                        onClickTitle: () => {
                            _updateStorageShouldShowSetting(!shouldShowSetting)
                            this.setState({ shouldShowSetting: _getStorageShouldShowSetting() })
                        }
                    })}
                    {this.renderLeg({ 
                        legNumber: 1,
                        shouldShowParameters: shouldShowQuote,
                        onClickHeader: () => {
                            _updateStorageShouldShowQuote(!shouldShowQuote)
                            this.setState({ shouldShowQuote: _getStorageShouldShowQuote() })
                        }
                    })}
                    {this.renderLeg({
                        legNumber: 2,
                        shouldShowParameters: shouldShowHedge,
                        onClickHeader: () => {
                            _updateStorageShouldShowHedge(!shouldShowHedge)
                            this.setState({ shouldShowHedge: _getStorageShouldShowHedge() })
                        }
                    })}
                    {profileHasOrchidParams && this.renderSettings({ 
                        title: 'Orchid', 
                        pickedKeys: ORCHID_PARAM_KEYS,
                        shouldShowParameters: shouldShowOrchid,
                        onClickTitle: () => {
                            _updateStorageShouldShowOrchid(!shouldShowOrchid)
                            this.setState({ shouldShowOrchid: _getStorageShouldShowOrchid() })
                        }
                    })}
                    {profileHasOptionParams && this.renderSettings({ 
                        title: 'Option', 
                        pickedKeys: OPTION_PARAM_KEYS,
                        shouldShowParameters: shouldShowOption,
                        onClickTitle: () => {
                            _updateStorageShouldShowOption(!shouldShowOption)
                            this.setState({ shouldShowOption: _getStorageShouldShowOption() })
                        }
                    })}
                    {profileHasEnetParams && this.renderSettings({ 
                        title: 'Enet', 
                        pickedKeys: ENET_PARAM_KEYS,
                        shouldShowParameters: shouldShowEnet,
                        others: (
                            <LegEditor
                                profileId={mode === 'EDIT' ? profile.id : null}
                                legNumber={1} 
                                legItem={_.get(profile, `legs.1`)}
                                shouldHideLegParams
                                shouldHideStrategy
                                shouldHideRefSymbols
                                disableSymbolModification
                                isProfileStarted={profile.started}
                                shouldAllowEmptySymbol={['HEDGE', 'LSP_HEDGE'].includes(_.get(profile, 'params.RUNNING_MODE'))}
                                symbolParamKeyFilter={(paramKey) => _.startsWith(paramKey, 'QUOTE_ENET')}
                                onChangeLeg={(newLeg) => {
                                    const newProfile = dotProp.set(profile, `legs.1`, newLeg)
                                    const exchangeNames = getProfileExchangeNames(newProfile)
                                    exchangeNames.forEach(exchangeName => {
                                        newProfile.accounts[exchangeName] = profile.accounts[exchangeName] || 'INVALID'
                                    })
                                    this.setState({
                                        profile: newProfile,
                                        isSaved: false,
                                        message: null
                                    })
                                }} />
                        ),
                        onClickTitle: () => {
                            _updateStorageShouldShowEnet(!shouldShowEnet)
                            this.setState({ shouldShowEnet: _getStorageShouldShowEnet() })
                        }
                    })}
                    {profileHasChainDataParams && this.renderSettings({ 
                        title: 'Chain Data', 
                        pickedKeys: CHAIN_DATA_KEYS,
                        shouldShowParameters: shouldShowChainData,
                        onClickTitle: () => {
                            _updateStorageShouldShowChainData(!shouldShowChainData)
                            this.setState({ shouldShowChainData: _getStorageShouldShowChainData() })
                        }
                    })}
                    {this.renderAccounts()}
                </div>
                {this.renderFooter()}
            </Modal>
        )
    }
}

ProfileEditor.propTypes = {
    dispatch: PropTypes.func.isRequired,
    account: PropTypes.object.isRequired,
    profileItems: PropTypes.object.isRequired,
    notifications: PropTypes.array.isRequired,
    symbolItems: PropTypes.object.isRequired,

    mode: PropTypes.oneOf(['CREATE', 'EDIT']).isRequired,
    profile: profileShape,
    onClickClose: PropTypes.func.isRequired,
    onSaveSuccess: PropTypes.func
}

ProfileEditor.defaultProps = {
    mode: 'CREATE',
    profile: createDefaultProfile({}),
    onSaveSuccess: () => {}
}

function mapStateToProps (state) {
    return {
        account: state.account,
        profileItems: state.profile.items,
        notifications: state.trading.notifications,
        symbolItems: state.symbol.items
    }
}

export default connect(mapStateToProps)(ProfileEditor)

