import _ from 'lodash'
import moment from 'moment' 
import { store } from '../../store'
import { getProfileSymbolNames } from '../../util/profileUtil'

const profileSymbolPricingUpdateTimes = {
    // pa_dev: {
    //     btc_usdt_OKEX_SPT: {
    //         price: [0, 0],
    //         lastUpdateTime: moment().toISOString()
    //     }
    // }
}

const getProfileStoppedUpdatingSymbolPricings = (toleranceInSecond=60) => {
    const { items: profileItems } = store.getState().profile
    const toleranceMoment = moment().add(-toleranceInSecond, 'seconds')
    let result = [] 
    _.forEach(profileItems, profileItem => {
        const { id: profileId, started } = profileItem
        if (started) {
            const profileSymbolNames = getProfileSymbolNames(profileItem, true)
            _.forEach(profileSymbolNames, symbolName => {
                const lastUpdateTime = _.has(profileSymbolPricingUpdateTimes, `${profileId}.${symbolName}`)
                    ? profileSymbolPricingUpdateTimes[profileId][symbolName].lastUpdateTime
                    : null
                if (_.isNil(lastUpdateTime) || toleranceMoment.isAfter(lastUpdateTime)) {
                    result.push({
                        profileId,
                        symbolName,
                        lastUpdateTime
                    })
                }
            })
        }
    })
    return result
}

const updateProfileSymbolPricingUpdateTime = ({ profileId='', symbolName='', price=[0, 0] }) => {
    const now = moment().toISOString()
    if (!_.has(profileSymbolPricingUpdateTimes, profileId)) {
        profileSymbolPricingUpdateTimes[profileId] = {}
    }
    if (!_.isNil(price) && 
        (!_.has(profileSymbolPricingUpdateTimes[profileId], symbolName) 
        || !_.isEqual(price, profileSymbolPricingUpdateTimes[profileId][symbolName].price))) {
        profileSymbolPricingUpdateTimes[profileId][symbolName] = {
            price,
            lastUpdateTime: now
        }
    }
}

export { profileSymbolPricingUpdateTimes, getProfileStoppedUpdatingSymbolPricings, updateProfileSymbolPricingUpdateTime }