import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { toNumberWithSmartPrecision } from '../../util/util'

export default class FundAccountBalanceItem extends Component {

    InlineData ({ name, value, shouldHighlight }) {
        return (
            <div className='fund-account-balance-item--inline-data'>
                <span className='fund-account-balance-item--inline-data--name'>{name}</span>
                <div className={'fund-account-balance-item--inline-data--value' + (shouldHighlight ? ' highlight' : '')}>{value}</div>
            </div>
        )
    }

    render () {
        const { fundAccountBalance, shouldShowAccountTypeTag, shouldShowAccountName } = this.props
        return (
            <div className='fund-account-balance-item'>
                <div className='fund-account-balance-item--header clearfix'>
                    {shouldShowAccountTypeTag && <div className='fund-account-balance-item--header--info-bar'>
                        <div className='fund-account-balance-item--header--info-bar--account-type swap'>{'FUND'}</div>
                        <div className='fund-account-balance-item--header--info-bar--timestamp'>{moment(fundAccountBalance.timestamp).format('HH:mm:ss')}</div>
                    </div>}
                    <div className='fund-account-balance-item--coin'>
                        {fundAccountBalance.coin}
                        {shouldShowAccountName ? ` @ ${fundAccountBalance.acct_name}` : ''}
                    </div>
                    {!shouldShowAccountTypeTag && <div className='fund-account-balance-item--timestamp'>{moment(fundAccountBalance.timestamp).format('HH:mm:ss')}</div>}
                </div>
                <div className='fund-account-balance-item--summary'>
                    {this.InlineData({
                        name: 'Balance',
                        value: toNumberWithSmartPrecision({ number: fundAccountBalance.balance, shouldReturnLocalString: true, shouldApplyFloorValue: true })
                    })}
                    {this.InlineData({
                        name: 'Transferable',
                        value: toNumberWithSmartPrecision({ number: fundAccountBalance.transferable, shouldReturnLocalString: true})
                    })}
                </div>
            </div>
        )
    } 
}

FundAccountBalanceItem.propTypes = {
    fundAccountBalance: PropTypes.object.isRequired,
    shouldShowAccountTypeTag: PropTypes.bool,
    shouldShowAccountName: PropTypes.bool
}