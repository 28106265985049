import React, { Component } from 'react'
import { connect } from 'react-redux'

import dotProp from 'dot-prop-immutable'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { FiMaximize2 } from 'react-icons/fi'

import ProfileItem from './ProfileItem'

import { AutoSizer, List, CellMeasurer, CellMeasurerCache } from 'react-virtualized'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'

const DragHandle = SortableHandle(() => <button className='profile-list--item--sort-handler'>{<FiMaximize2 />}</button>)
const SortableProfileList = SortableContainer(List, { withRef: true })
const SortableProfileItem = SortableElement(props => {
    const { style, ...rest } = props
    return (
        <div className='profile-list--item' style={style}>
            <ProfileItem 
                dragHandle={<DragHandle />}
                {...rest} />
        </div>
    )
})

class ProfileList extends Component {
    constructor (props) {
        super(props)
        this.cellMeasurerCache = new CellMeasurerCache({
            fixedWidth: true,
            fixedHeight: false
        })
        this.listNode = null
    }

    componentDidUpdate (prevProps) {
        const prevProfileIds = prevProps.profileItems.map(profileItem => profileItem.id)
        const profileIds = this.props.profileItems.map(profileItem => profileItem.id)
        if (!_.isEqual(prevProfileIds, profileIds) 
            || !_.isEqual(prevProps.expandedProfiles, this.props.expandedProfiles)) {
            this.updateVirtualizedList()
        }
        if (!_.isEqual(prevProps.scrollToTopVersion, this.props.scrollToTopVersion)) {
            this.scrollToTop()
        }
    }

    scrollToTop () {
        if (this.listNode) {
            this.listNode.scrollToPosition(0)
        }
    }

    scrollToProfile (profileId) {
        const { profileItems } = this.props
        if (_.has(this.listNode, `refs.wrappedInstance`)) {
            const List = this.listNode.refs.wrappedInstance
            const profileIndex = _.findIndex(profileItems, { id: profileId })
            if (profileIndex >= 0) {
                const rowOffset = List.getOffsetForRow({ index: profileIndex })
                List.scrollToPosition(rowOffset)
                setTimeout(() => {
                    const newRowOffset = List.getOffsetForRow({ index: profileIndex })
                    if (newRowOffset !== rowOffset) {
                        this.scrollToProfile(profileId)
                    }
                })
            }
        }
    }

    updateVirtualizedList () {
        if (this.listNode) {
            this.cellMeasurerCache.clearAll()
            this.listNode.forceUpdateGrid()
        }
    }

    renderRow (params) {
        const { expandedProfiles, onChangeExpandedProfiles } = this.props
        const { index, key, parent, style } = params
        const { profileItems } = this.props
        const profileItem = profileItems[index]
        return (
            <CellMeasurer 
                key={key} 
                rowIndex={index}
                columnIndex={0}
                parent={parent} 
                cache={this.cellMeasurerCache}>
                <SortableProfileItem 
                    style={style}
                    index={index}
                    profile={profileItem} 
                    expanded={expandedProfiles[profileItem.id]}
                    onClickToggleExpand={(expanded) => {
                        const newExpandedProfiles = dotProp.set(expandedProfiles, profileItem.id, expanded) 
                        onChangeExpandedProfiles(newExpandedProfiles)
                    }}
                    onChangeItemHeight={() => { this.updateVirtualizedList() }} />
            </CellMeasurer>
        )
    }

    render () {
        const { profileItems, listScrollTop, onSortEnd } = this.props

        return (
            <div className='profile-list' 
                style={{
                    position: 'absolute',
                    top: '89px',
                    bottom: '0px',
                    left: '0px',
                    right: '0px'
                }}>
                <AutoSizer>
                    {({ width, height }) => (
                        <SortableProfileList 
                            ref={(node) => { 
                                if (node) {
                                    this.listNode = node.getWrappedInstance()
                                }
                            }}
                            useDragHandle
                            width={width}
                            height={height} 
                            rowCount={profileItems.length} 
                            rowRenderer={params => this.renderRow(params)} 
                            rowHeight={this.cellMeasurerCache.rowHeight} 
                            overscanRowCount={5} 
                            scrollToAlignment={'start'}
                            scrollTop={listScrollTop ? null : null}
                            deferredMeasurementCache={this.cellMeasurerCache} 
                            onSortEnd={params => { onSortEnd(params) }} />
                    )}
                </AutoSizer>
            </div>
        )
    }
} 

ProfileList.propTypes = {
    profileItems: PropTypes.array.isRequired,
    expandedProfiles: PropTypes.object,
    listScrollTop: PropTypes.number,
    scrollToTopVersion: PropTypes.number, // The list will scroll to top position once this value is updated
    onChangeExpandedProfiles: PropTypes.func.isRequired,
    onSortEnd: PropTypes.func
}

ProfileList.defaultProps = {
    expandedProfiles: {},
    onChangeExpandedProfiles: () => {},
    onSortEnd: () => {}
}

function mapStateToProps () {
    return {}
}

export default connect(mapStateToProps)(ProfileList)
