import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

const _countDecimalPlaces = (value) => {
    const fractions = _.toString(value).split('.')
    return fractions.length === 2 ? fractions[1].length : 0
}

function PositiveNumberInput ({ className, placeholder='', value='', maxDecimalPlaces, autoFocus=true, disabled=false, trailingContent, onChange=()=>{}}) {

    const [isFocused, setIsFocused] = useState(false)
    const disabledKeys = ['E', 'e', '+', '-']
    if (Number(maxDecimalPlaces) === 0) {
        disabledKeys.push('.')
    }

    return (
        <div className={'positive-number-input' + (!_.isEmpty(className) ? ` ${className}` : '') + (disabled ? ' disabled' : '') + (isFocused ? ' focused' : '')}>
            <input
                placeholder={placeholder || '0'}
                type='number'
                inputMode='decimal'
                value={value}
                disabled={disabled}
                autoFocus={autoFocus}
                min={0}
                onKeyDown={(e) => { disabledKeys.includes(e.key) && e.preventDefault() }}
                onFocus={() => { setIsFocused(true) }}
                onBlur={() => { setIsFocused(false) }}
                onChange={(e) => {
                    const newValue = e.target.value
                    if (_.isNil(maxDecimalPlaces) || _countDecimalPlaces(newValue) <= maxDecimalPlaces) {
                        onChange(newValue)
                    }
                }} />
            {!_.isNil(trailingContent) && <div className='positive-number-input--trailing-content'>{trailingContent}</div>}
        </div>

    )
}

PositiveNumberInput.propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    maxDecimalPlaces: PropTypes.number,
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    trailingContent: PropTypes.any,
    onChange: PropTypes.func.isRequired
}

export default memo(PositiveNumberInput)