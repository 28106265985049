import React, { memo, useState } from 'react'
import useEvent from 'react-use-event-hook'
import { useDispatch } from 'react-redux'
import { useMount, useMountedState } from 'react-use'

import BigNumber from 'bignumber.js'
import moment from 'moment'
import _ from 'lodash'

import { FaXmark } from 'react-icons/fa6'

import Popup from '../common/popup/Popup'
import { CircularProgressbar } from 'react-circular-progressbar'
import TWAPUpdater, { ACTION_TYPES } from './TWAPUpdater'

import { deleteProfileUpdater, fetchProfileUpdaters } from './profileAction'
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector'
import { areAllValuesNonEmpty } from '../../util/util'

function TWAPUpdaterContainer () {

    const dispatch = useDispatch()
    const isMounted = useMountedState()
    const updaters = useShallowEqualSelector(state => _.get(state, 'profile.updaters'))

    const [isCreatingNew, setIsCreatingNew] = useState(false)
    const [selectedUpdaterId, setSelectedUpdaterId] = useState(null)
    const [isFetching, setIsFetching] = useState(false)

    const sortedUpdaters = _.sortBy(updaters, _updater => -moment(_updater.createdTimestamp).unix())

    const _fetchProfileUpdaters = useEvent(() => {
        if (!isFetching) {
            setIsFetching(true)
            dispatch(fetchProfileUpdaters())
            .then(() => {
                if (isMounted()) {
                    setIsFetching(false)
                }
            })
        }
    })

    useMount(() => {
        _fetchProfileUpdaters()
    })

    const handleClickDelete = useEvent((_id) => {
        dispatch(deleteProfileUpdater(_id))
    })

    return (
        <div className='twap-updater-container'>
            <div className='twap-updater-container--side'>
                <div className='twap-updater-container--side--head'>
                    <button className='twap-updater-container--side--fetch-latest-button'
                        disabled={isFetching}
                        onClick={() => { _fetchProfileUpdaters() }}>{isFetching ? 'Fetching...' : 'Fetch Latest'}</button>
                    <button
                        className='twap-updater-container--side--create-new-button'
                        onClick={() => { setIsCreatingNew(true) }}>{'New Updater'}</button>
                </div>
                <div className='twap-updater-container--updater-list'>
                    {_.map(sortedUpdaters, _updater => {
                        const { id, hostname, user, profileName, isPaused, currentSkew, params } = _updater
                        const _updaterProgress = isPaused !== null && areAllValuesNonEmpty([currentSkew, params?.totalValue]) && BigNumber(params.totalValue).gt(0)
                            ? BigNumber(currentSkew).abs().div(params.totalValue)
                            : null
                        return (
                            <div key={id}
                                className={'twap-updater-container--updater' + (id === selectedUpdaterId ? ' selected' : '')}
                                onClick={() => {
                                    setIsCreatingNew(false)
                                    setSelectedUpdaterId(id)
                                }}>
                                <div className='twap-updater-container--updater--header'>
                                    <span className='hostname'>{hostname}</span>
                                    <span className='user'>{user}</span>
                                    {isPaused !== false &&
                                    <Popup
                                        className='twap-updater-container--remove-updater'
                                        on={'click'}
                                        trigger={
                                            <button className='twap-updater-container--remove-updater--trigger'
                                                onClick={(e) => { e.stopPropagation() }}>
                                                <FaXmark />
                                            </button>
                                        }>
                                        <div className='twap-updater-container--remove-updater--main' onClick={(e) => { e.stopPropagation() }}>
                                            <div className=''>{'Delete the updater?'}</div>
                                            <button onClick={() => { handleClickDelete(id) }}>{'DELETE'}</button>
                                        </div>
                                    </Popup>}
                                </div>
                                <div className='twap-updater-container--updater--main'>
                                    <div className='twap-updater-container--updater--profile-name'>{profileName}</div>
                                    {!_.isNil(_updaterProgress) &&
                                    <CircularProgressbar
                                        className='twap-updater-container--updater--progress'
                                        value={_updaterProgress.toNumber()}
                                        maxValue={1}
                                        text={`${_updaterProgress.times(100).toFixed(0, 1)}%`}
                                        styles={{
                                            path: {
                                                strokeWidth: 4,
                                                stroke: _updaterProgress.lt(1) ? '#eeda85' : '#90eaea'
                                            },
                                            trail: {
                                                strokeWidth: 4,
                                                stroke: isPaused ? 'rgb(253 129 138 / 60%)' : '#7080909c'
                                            },
                                            text: {
                                                fontSize: '26px',
                                                fill: isPaused ? 'rgb(253 129 138)' : 'white'
                                            }
                                        }} />}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className='twap-updater-container--main'>
                {isCreatingNew
                ? <TWAPUpdater
                    actionType={ACTION_TYPES.CREATE}
                    onCreateUpdater={(createdUpdater) => {
                        setIsCreatingNew(false)
                        setSelectedUpdaterId(createdUpdater?.id)
                    }} />
                : !_.isEmpty(selectedUpdaterId) && _.has(updaters, selectedUpdaterId) ? <TWAPUpdater
                    actionType={ACTION_TYPES.EDIT}
                    updaterId={selectedUpdaterId} />
                : null}
            </div>
        </div>
    )
}

export default memo(TWAPUpdaterContainer)