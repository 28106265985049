import { providers, utils } from 'ethers'
import _ from 'lodash'

const { getAddress, FormatTypes, Interface } = utils

import { areAllValuesNonEmpty } from '../../util/util'
import { RPC_URLS_PER_CHAIN } from './blockchainConfig'

export const getEthersJsonRpcBatchProvider = ({ rpcUrl, chainId }) => {
    rpcUrl = rpcUrl ?? _.get(RPC_URLS_PER_CHAIN, `${chainId}.0`)
    return areAllValuesNonEmpty([rpcUrl, chainId]) ? new providers.JsonRpcBatchProvider(rpcUrl, chainId) : null
}

export const getEthersJsonRpcProvider = ({ rpcUrl, chainId }) => {
    rpcUrl = rpcUrl ?? _.get(RPC_URLS_PER_CHAIN, `${chainId}.0`)
    return areAllValuesNonEmpty([rpcUrl, chainId]) ? new providers.JsonRpcProvider(rpcUrl, chainId) : null
}

/**
 * @param {string} bytes32 
 * @returns {string}
 */
export const bytes32ToAddr = (bytes32) => {
    return getAddress(bytes32.slice(-40))
}

export const parseABI = (humanRedableABI) => {
    return JSON.parse((new Interface(humanRedableABI)).format(FormatTypes.json))
}