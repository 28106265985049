import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Popup from '../common/popup/Popup'
import ProfileActionButtons from './ProfileActionButtons'
import { IoMdSwitch } from 'react-icons/io'
import { restartProfile, stopProfile, resumeProfile, pauseProfile, cancelAndPauseProfile } from './profileAction'

class ProfileActionPopup extends Component {
    render () {
        const { dispatch, popupClassName, triggerClassName, profileItem } = this.props
        const isHedgePeerProfilePaused = profileItem.resumed === false && _.get(profileItem, 'params.RUNNING_MODE') === 'QUOTE' && _.get(profileItem, 'pauseReason') === 'HedgeSidePeerProfilePaused'
        return (
            <Popup className={'profile-action-popup' + (popupClassName ? ` ${popupClassName}` : '')}
                on={'click'}
                margin={0}
                trigger={
                    <button className={'profile-action-popup--trigger' + (triggerClassName ? ` ${triggerClassName}` : '')} 
                        onDoubleClick={(e) => { e.stopPropagation() }}><IoMdSwitch /></button>
                }>
                    <ProfileActionButtons 
                        disableRestart={profileItem.isStarting || profileItem.isStopping || profileItem.resumed} 
                        disableStop={!profileItem.started || profileItem.isStarting || profileItem.isStopping || profileItem.resumed} 
                        disableResume={!profileItem.started || profileItem.isResuming || isHedgePeerProfilePaused}
                        disablePause={!profileItem.started || profileItem.isPausing} 
                        disableCancelOrder={!profileItem.started} 
                        hideResume={profileItem.resumed} 
                        hidePause={!profileItem.resumed} 
                        cancelOrderButtonText={profileItem.resumed ? 'Cancel Orders & Pause' : 'Cancel Orders'} 
                        resumeButtonTitle={isHedgePeerProfilePaused ? `The resume of the peer profile on the hedge side will automatically resume the current one.` : null}
                        onClickRestart={() => { dispatch(restartProfile(profileItem.id, false)) }}
                        onClickCleanRestart={() => { dispatch(restartProfile(profileItem.id, true)) }} 
                        onClickStop={() => { dispatch(stopProfile(profileItem.id)) }} 
                        onClickResume={() => { dispatch(resumeProfile(profileItem.id)) }}
                        onClickPause={() => { dispatch(pauseProfile(profileItem.id)) }} 
                        onClickCancelOrder={() => { dispatch(cancelAndPauseProfile(profileItem.id)) }} />
            </Popup>
        )
    }
}

ProfileActionPopup.propTypes = {
    dispatch: PropTypes.func.isRequired,
    popupClassName: PropTypes.string,
    triggerClassName: PropTypes.string,
    profileItem: PropTypes.object.isRequired
}

export default connect()(ProfileActionPopup)



