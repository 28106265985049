import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import BigNumber from 'bignumber.js'
import moment from 'moment'
import _ from 'lodash'

import { FiChevronDown, FiChevronsDown, FiChevronsUp } from 'react-icons/fi'
import { AutoSizer, Table, Column } from 'react-virtualized'

import Popup from '../common/popup/Popup'
import Checkbox from '../common/checkbox/Checkbox'

import { getOptionSymbolUnderlying, getPricePrecisionBySymbolItem, getSymbolAttributeByName, getTokenPriceInUSD, INSTRUMENT_TYPES } from '../../util/symbolUtil'
import { areAllValuesNonEmpty, isMetSearchStringCriteria, toNumberWithSmartPrecision } from '../../util/util'
import { getNotional } from '../../util/tradingUtil'

const SORT_ORDERS = {
    ASC: 'ASC',
    DESC: 'DESC'
}

const TABLE_ROW_TYPES = {
    OPTION_GROUP: 'OPTION_GROUP',
    HEDGED_GROUP: 'HEDGED_GROUP',
    OPTION_POSITION: 'OPTION_POSITION',
    HEDGED_POSITION: 'HEDGED_POSITION',
    HEDGED_ACCOUNT_BALANCE: 'HEDGED_ACCOUNT_BALANCE'
}

const Strategy = ({ key, name, portfolioName, shouldIgnoreHedge=false, shouldHedgeUseInitialBalance=false, shouldHedgeIgnoreAccountBalance=false }) => {
    return {
        key,
        name,
        portfolioName,
        shouldIgnoreHedge,
        shouldHedgeUseInitialBalance,
        shouldHedgeIgnoreAccountBalance
    }
}

const STRATEGY = {
    allblack: Strategy({
        key: 'allblack',
        name: 'All Black',
        portfolioName: 'allblack',
        shouldIgnoreHedge: false,
        shouldHedgeUseInitialBalance: true,
        shouldHedgeIgnoreAccountBalance: false
    }),
    naked: Strategy({
        key: 'naked',
        name: 'Naked',
        portfolioName: 'prop',
        shouldIgnoreHedge: false,
        shouldHedgeUseInitialBalance: false,
        shouldHedgeIgnoreAccountBalance: false
    })
}

const _getStrategyKeyByPosition = (positionItem, accountItems) => {
    const { account_name, product_name } = positionItem || {}
    const { instrumentType } = getSymbolAttributeByName(product_name)
    let strategyKey = null
    const portfolioName = _.get(accountItems, `${account_name}.portfolio_name`)
    if (portfolioName === 'allblack') {
        strategyKey = STRATEGY.allblack.key
    } else if (portfolioName === 'prop' && (instrumentType === INSTRUMENT_TYPES.OPTION || account_name === 'deribit_otc1')) {
        strategyKey = STRATEGY.naked.key
    }
    return strategyKey
}

const _getStrategyKeyByAccountBalance = (accountBalanceItem={}, accountItems) => {
    const { acct_name } = accountBalanceItem
    const portfolioName = _.get(accountItems, `${acct_name}.portfolio_name`)
    let strategyKey = null
    if (portfolioName === 'allblack') {
        strategyKey = STRATEGY.allblack.key
    } else if (portfolioName === 'prop' && acct_name === 'deribit_otc1') {
        strategyKey = STRATEGY.naked.key
    }
    return strategyKey
}

const TableRow = ({ type='', data={}, nonClickable=false }) => {
    return {
        type,
        data,
        nonClickable
    }
}
const cachedWorkspaceComponentStates = {}

class OptionPositions extends Component {
    constructor (props) {
        super(props)
        this.state = _.get(cachedWorkspaceComponentStates, props.workspaceComponentId, {
            searchString: '',
            groupNamesShouldShowItems: [],
            sortBy: null,
            sortOrder: SORT_ORDERS.ASC,
            strategies: [STRATEGY.allblack.key]
        })
        this.tableWrapperNode = null
        this.tableNode = null
        this.isScrollingTable = null
    }

    componentDidUpdate () {
        if (this.tableNode) {
            this.tableNode.recomputeRowHeights()
        }
    }

    componentWillUnmount () {
        const { workspaceComponentId } = this.props
        if (!_.isEmpty(workspaceComponentId)) {
            cachedWorkspaceComponentStates[workspaceComponentId] = _.cloneDeep(this.state)
        }
    }

    _removeInnerScrollContainerPointerEvents () {
        if (this.tableWrapperNode && this.tableWrapperNode.ownerDocument) {
            const containers = this.tableWrapperNode.ownerDocument.getElementsByClassName('ReactVirtualized__Grid__innerScrollContainer')
            if (!_.isEmpty(containers)) {
                containers[0].style.pointerEvents = null
            }
        }
    }

    handleScrollTableMain () {
        if (window.document.hidden) {
            if (this.isScrollingTable) {
                window.clearTimeout(this.isScrollingTable)
            }
            this.isScrollingTable = setTimeout(() => {
                this._removeInnerScrollContainerPointerEvents()
            }, 66)
        }
    }

    _getSymbolLastPrice (symbol='') {
        const { pricings } = this.props
        const { bid, ask, last } = _.get(pricings, symbol) || {}
        return last || ((Number(bid) + Number(ask)) / 2) || null
    }

    _getSortedPositionTableRows (tableRows=[]) {
        const { sortBy, sortOrder } = this.state
        let sortedTableRows = []
        if (['bid_implied_volatility', 'ask_implied_volatility', 'unrealized_pnl'].includes(sortBy)) {
            sortedTableRows = _.sortBy(tableRows, tableRow => Number(tableRow.data[sortBy]))
        } else if (sortBy === 'avg_cost') {
            sortedTableRows = _.sortBy(tableRows, tableRow => Number(tableRow.data.long_avg_cost || tableRow.data.short_avg_cost))
        } else {
            sortedTableRows = _.sortBy(tableRows, tableRow => tableRow.data[sortBy])
        }

        if (sortOrder === SORT_ORDERS.DESC) {
            _.reverse(sortedTableRows)
        }
        return sortedTableRows
    }

    _getOptionSymbolUnderlying (symbolName='') {
        const symbolTokens = symbolName.split('_')
        const token = symbolTokens[0].substring(0, symbolTokens[0].length - 6)
        return _.toUpper(token) || 'UNKOWN'
    }

    _getTableRows () {
        const { positions, accountItems, optionGreek, nakedOptionGreek, optionImpliedVolatilities, symbolItems, pricings, swapAccountBalances, crossAccountBalances, crossMarginAccountBalances } = this.props
        const { searchString, groupNamesShouldShowItems, strategies } = this.state
        
        const cachedTokenPricesInUSD = {}
        const _getTokenPriceInUSD = (token) => {
            if (_.isNil(_.get(cachedTokenPricesInUSD, token))) {
                cachedTokenPricesInUSD[token] = getTokenPriceInUSD(token, pricings)
            }
            return Number(cachedTokenPricesInUSD[token])
        }

        const cachedSymbolPricePrecision = {}
        const _getSymbolPricePrceision = (symbol) => {
            if (_.isNil(_.get(cachedSymbolPricePrecision, symbol))) {
                cachedSymbolPricePrecision[symbol] = getPricePrecisionBySymbolItem(symbolItems[symbol])
            }
            return !_.isNil(cachedSymbolPricePrecision[symbol]) ?  Number(cachedSymbolPricePrecision[symbol]) : 5
        }

        const _getNetPositionInUSD = (position) => {
            const { product_name: symbolName, long_position: longPosition, short_position: shortPosition } = position
            const { quote } = getSymbolAttributeByName(symbolName)
            const symbolItem = symbolItems[symbolName]
            const currentPrice = this._getSymbolLastPrice(symbolName)
            const netPositionNotionalValue = getNotional({ 
                symbolItem, 
                quantity: BigNumber(longPosition || 0).minus(shortPosition || 0).toString(),
                price: currentPrice,
                BTCUSDIndexLastPrice: _.get(pricings, 'btc_usdc_BINANCE_SPT.last')
            })
            return netPositionNotionalValue * _getTokenPriceInUSD(quote)
        }

        const initialTokenToPositionsMaps = {}
        const filteredPortfolioPositions = []

        _.forEach(positions, position => {
            const { account_name, product_name } = position
            const { instrumentType } = getSymbolAttributeByName(product_name)
            const strategyKey = _getStrategyKeyByPosition(position, accountItems)
            if (!_.isNil(strategyKey) && strategies.includes(strategyKey)) {
                if (instrumentType === INSTRUMENT_TYPES.OPTION) {
                    const underlying = getOptionSymbolUnderlying(product_name)
                    if (!_.has(initialTokenToPositionsMaps, underlying)) {
                        initialTokenToPositionsMaps[underlying] = []
                    }
                }
                if (_.isEmpty(searchString) || isMetSearchStringCriteria(`${account_name} ${product_name}`, searchString)) {
                    filteredPortfolioPositions.push(position)
                }
            }
        })

        const [filteredOptionPositions, filteredHedgedPositions] = _.partition(filteredPortfolioPositions, position => {
            return getSymbolAttributeByName(position.product_name).instrumentType === INSTRUMENT_TYPES.OPTION
        })

        const tokenToOptionPositionsMaps = Object.assign({}, initialTokenToPositionsMaps,
            _.groupBy(filteredOptionPositions, optionPosition => {
                const { product_name } = optionPosition
                return getOptionSymbolUnderlying(product_name)
            })
        )

        const tokenToHedgedPositionsMaps = Object.assign({}, initialTokenToPositionsMaps, 
            _.groupBy(filteredHedgedPositions, hedgePosition => {
                return getSymbolAttributeByName(hedgePosition.product_name).base
            })
        )

        const tableRows = []
        _.forEach(tokenToOptionPositionsMaps, (optionPositions, token) => {
            const optionGroupName = `Total ${token} Options` 
            const hedgedGroupName = `Total ${token} Hedged`
            const shouldShowOptionItems = groupNamesShouldShowItems.includes(optionGroupName)
            const shouldShowHedgedItems = groupNamesShouldShowItems.includes(hedgedGroupName)
            const hedgedPositions = tokenToHedgedPositionsMaps[token] || []

            tableRows.push(TableRow({
                type: TABLE_ROW_TYPES.OPTION_GROUP,
                data: {
                    name: optionGroupName,
                    token,
                    netPositionInUSD: '0',
                    underlyingPrice: _getTokenPriceInUSD(token),
                    delta: '0',
                    gamma: '0',
                    theta: '0',
                    vega: '0',
                    rho: '0'
                }
            }))

            const optionGroupRow = _.last(tableRows)
            const optionPositoinRows = []
            _.forEach(optionPositions, optionPosition => {
                const { product_name: symbolName } = optionPosition
                const positionStrategyKey = _getStrategyKeyByPosition(optionPosition, accountItems)
                const { delta, gamma, theta, vega, rho } = (positionStrategyKey === STRATEGY.allblack.key ? optionGreek[symbolName] 
                    : positionStrategyKey === STRATEGY.naked.key ? nakedOptionGreek[symbolName]
                    : null) || {}
                const netPositionInUSD = _getNetPositionInUSD(optionPosition)
                const currentPrice = this._getSymbolLastPrice(symbolName)
                const symbolPricePrecision = _getSymbolPricePrceision(symbolName)

                optionGroupRow.data.netPositionInUSD = BigNumber(optionGroupRow.data.netPositionInUSD).plus(netPositionInUSD || 0).toString()
                optionGroupRow.data.delta = BigNumber(optionGroupRow.data.delta).plus(delta || 0).toString()
                optionGroupRow.data.gamma = BigNumber(optionGroupRow.data.gamma).plus(gamma || 0).toString()
                optionGroupRow.data.theta = BigNumber(optionGroupRow.data.theta).plus(theta || 0).toString()
                optionGroupRow.data.vega = BigNumber(optionGroupRow.data.vega).plus(vega || 0).toString()
                optionGroupRow.data.rho = BigNumber(optionGroupRow.data.rho).plus(rho || 0).toString()

                if (shouldShowOptionItems) {
                    const { bid_implied_volatility, ask_implied_volatility } = optionImpliedVolatilities[symbolName] || {}

                    optionPositoinRows.push(TableRow({
                        type: TABLE_ROW_TYPES.OPTION_POSITION,
                        data: {
                            ...optionPosition,
                            netPositionInUSD,
                            currentPrice: !_.isNil(currentPrice) ? BigNumber(currentPrice).toFormat(symbolPricePrecision) : 'N/A',
                            token,
                            underlyingPrice: _getTokenPriceInUSD(token),
                            delta,
                            gamma,
                            theta,
                            vega,
                            rho,
                            bid_implied_volatility,
                            ask_implied_volatility
                        }
                    }))
                }
            })
            const sortedOptionPositionTableRows = this._getSortedPositionTableRows(optionPositoinRows)
            tableRows.push(...sortedOptionPositionTableRows)

            const shouldHaveHedge = !_.isEmpty(strategies)
                && _.some(strategies, strategyKey => {
                    const strategyItem = STRATEGY[strategyKey]
                    return _.get(strategyItem, 'shouldIgnoreHedge') === false
                })

            if (shouldHaveHedge) {                
                tableRows.push(TableRow({
                    type: TABLE_ROW_TYPES.HEDGED_GROUP,
                    data: {
                        name: hedgedGroupName,
                        token,
                        netPositionInUSD: '0',
                        underlyingPrice: _getTokenPriceInUSD(token)
                    }
                }))

                const hedgedGroupRow = _.last(tableRows)
                const hedgedPositionRows = []
                _.forEach(hedgedPositions, hedgedPosition => {
                    const { product_name: symbolName } = hedgedPosition
                    const netPositionInUSD = _getNetPositionInUSD(hedgedPosition)
                    const currentPrice = this._getSymbolLastPrice(symbolName)
                    const symbolPricePrecision = _getSymbolPricePrceision(symbolName)
    
                    hedgedGroupRow.data.netPositionInUSD = BigNumber(hedgedGroupRow.data.netPositionInUSD).plus(netPositionInUSD || 0).toString()
    
                    if (shouldShowHedgedItems) {
                        hedgedPositionRows.push(TableRow({
                            type: TABLE_ROW_TYPES.HEDGED_POSITION,
                            data: {
                                ...hedgedPosition,
                                netPositionInUSD,
                                currentPrice: !_.isNil(currentPrice) ? BigNumber(currentPrice).toFormat(symbolPricePrecision) : 'N/A'
                            }
                        }))
                    }
                })
    
                const shouldHaveAccountBalances = !_.isEmpty(strategies)
                    && _.some(strategies, strategyKey => {
                        const strategyItem = STRATEGY[strategyKey]
                        return _.get(strategyItem, 'shouldIgnoreHedge') === false && _.get(strategyItem, 'shouldHedgeIgnoreAccountBalance') === false
                    })
                let filteredSwapAccountBalances = [], filteredCrossAccountBalances = [], filteredCrossMarginAccountBalances = []

                if (shouldHaveAccountBalances) {
                    filteredSwapAccountBalances = _.filter(swapAccountBalances, swapAccountBalance => {
                        const strategyKey = _getStrategyKeyByAccountBalance(swapAccountBalance, accountItems)
                        return !_.isNil(strategyKey) && strategies.includes(strategyKey) && _.toUpper(swapAccountBalance.coin || '') === token
                    })
                    filteredCrossAccountBalances = _.filter(crossAccountBalances, _crossAccountBalance => {
                        const strategyKey = _getStrategyKeyByAccountBalance(_crossAccountBalance, accountItems)
                        return !_.isNil(strategyKey) && strategies.includes(strategyKey) && _.toUpper(_crossAccountBalance.coin || '') === token
                    })
                    filteredCrossMarginAccountBalances = _.filter(crossMarginAccountBalances, _crossMarginAccountBalance => {
                        const strategyKey = _getStrategyKeyByAccountBalance(_crossMarginAccountBalance, accountItems)
                        return !_.isNil(strategyKey) && strategies.includes(strategyKey) && _.toUpper(_crossMarginAccountBalance.coin || '') === token
                    })
                }

                _.forEach(filteredSwapAccountBalances, swapAccountBalance => {
                    const { equity, option_value, coin } = swapAccountBalance
                    const netPositionInUSD = (Number(equity) - Number(option_value)) * _getTokenPriceInUSD(coin)
                    hedgedGroupRow.data.netPositionInUSD = BigNumber(hedgedGroupRow.data.netPositionInUSD).plus(netPositionInUSD || 0).toString()
                    if (shouldShowHedgedItems) {
                        hedgedPositionRows.push(TableRow({
                            type: TABLE_ROW_TYPES.HEDGED_ACCOUNT_BALANCE,
                            data: {
                                ...swapAccountBalance,
                                source: 'Swap Account Balance',
                                netPositionInUSD
                            }
                        }))
                    }
                })

                _.forEach(filteredCrossAccountBalances, crossAccountBalance => {
                    const { eq, option_value, coin } = crossAccountBalance
                    const netPositionInUSD = (Number(eq) - Number(option_value)) * _getTokenPriceInUSD(coin)
                    hedgedGroupRow.data.netPositionInUSD = BigNumber(hedgedGroupRow.data.netPositionInUSD).plus(netPositionInUSD || 0).toString()
                    if (shouldShowHedgedItems) {
                        hedgedPositionRows.push(TableRow({
                            type: TABLE_ROW_TYPES.HEDGED_ACCOUNT_BALANCE,
                            data: {
                                ...crossAccountBalance,
                                source: 'Cross Account Balance',
                                equity: eq,
                                netPositionInUSD
                            }
                        }))
                    }
                })

                _.forEach(filteredCrossMarginAccountBalances, crossMarginAccountBalance => {
                    const { balance, coin } = crossMarginAccountBalance
                    const netPositionInUSD = Number(balance) * _getTokenPriceInUSD(coin)
                    hedgedGroupRow.data.netPositionInUSD = BigNumber(hedgedGroupRow.data.netPositionInUSD).plus(netPositionInUSD || 0).toString()
                    if (shouldShowHedgedItems) {
                        hedgedPositionRows.push(TableRow({
                            type: TABLE_ROW_TYPES.HEDGED_ACCOUNT_BALANCE,
                            data: {
                                ...crossMarginAccountBalance,
                                source: 'Cross Margin Account Balance',
                                equity: balance,
                                netPositionInUSD
                            }
                        }))
                    }
                })
    
                const sortedHedgedPositionTableRows = this._getSortedPositionTableRows(hedgedPositionRows)
                tableRows.push(...sortedHedgedPositionTableRows)
            }
        })

        return tableRows


    }

    StatsItem ({ label, component }) {
        return (
            <div className='option-positions--stats-item'>
                <label>{label}</label>
                <div className='option-positions--stats-item--component'>{component}</div>
            </div>
        )
    }

    renderNumber (number, defaultPrecision=2) {
        return (_.isNil(number) || number === '') ? ''
            : Math.abs(number || 0) >= 1000 ? BigNumber(number).toFormat(0) 
            : toNumberWithSmartPrecision({ number, defaultPrecision })
    }

    OptionGroupRow (groupData={}, shouldHideUnderlyingPrice=false) {
        const { name, netPositionInUSD, nakedPosition, delta, gamma, theta, vega, rho, underlyingPrice, token } = groupData
        const _nakedPosition = BigNumber(nakedPosition || 0)
        const _delta = BigNumber(delta || 0) 
        const nakedPositionClassName = _nakedPosition.gt(0) ? 'positive' : _nakedPosition.lt(0) ? 'negative' : null
        const deltaValueClassName = _delta.gt(0) ? 'positive' : _delta.lt(0) ? 'negative' : null
        return (
            <div className={'option-positions--group-row option-group'}>
                <div className='option-positions--group-row--name'>{name}</div>
                {this.StatsItem({
                    label: 'Net Position (USD)',
                    component: (
                        <span className={BigNumber(netPositionInUSD).gt(0) ? 'positive' : BigNumber(netPositionInUSD).lt(0) ? 'negative' : ''}>
                            {this.renderNumber(netPositionInUSD)}
                        </span>
                    )
                })}
                {!BigNumber(nakedPosition || 0).eq(0) && 
                this.StatsItem({
                    label: 'Naked Position',
                    component: (
                        <Fragment>
                            <div className='option-positions--group-row--naked-position'>
                                <span className={nakedPositionClassName}>{this.renderNumber(_nakedPosition.times(underlyingPrice).toString())}</span>
                                <label>{'USD'}</label>
                            </div>
                            <div className='option-positions--group-row--naked-position remark'>
                                {'('}
                                <span className={nakedPositionClassName}>{this.renderNumber(nakedPosition)}</span>
                                <label>{token}</label>
                                {')'}
                            </div>
                        </Fragment>
                    )
                })}
                {this.StatsItem({
                    label: 'Delta SUM',
                    component: (
                        <Fragment>
                            <div className='option-positions--group-row--delta-item'>
                                <span className={deltaValueClassName}>{this.renderNumber(_delta.times(underlyingPrice).toString())}</span>
                                <label>{'USD'}</label>
                            </div>
                            <div className='option-positions--group-row--delta-item remark'>
                                {'('}
                                <span className={deltaValueClassName}>{this.renderNumber(delta)}</span>
                                <label>{token}</label>
                                {')'}
                            </div>
                        </Fragment>
                    )
                })}
                {this.StatsItem({
                    label: 'Gamma SUM',
                    component: this.renderNumber(gamma)
                })}
                {this.StatsItem({
                    label: 'Theta SUM',
                    component: `$${this.renderNumber(theta)}`
                })}
                {this.StatsItem({
                    label: 'Vega SUM',
                    component: `$${this.renderNumber(vega)}`
                })}
                {this.StatsItem({
                    label: 'Rho SUM',
                    component: `$${this.renderNumber(rho)}`
                })}
                {!shouldHideUnderlyingPrice && this.StatsItem({
                    label: 'Underlying Price',
                    component: `$${this.renderNumber(underlyingPrice)}`
                })}
            </div>
        )
    } 

    HedgedGroupRow (groupData={}) {
        const { initialBalance } = this.props
        const { strategies } = this.state
        const { token, name, netPositionInUSD, underlyingPrice } = groupData
        const portfolioNamesShouldUseInitialBalance = _.uniq(
            _.map(
                _.filter(strategies, strategyKey => {
                    const strategyItem = STRATEGY[strategyKey]
                    return _.get(strategyItem, 'shouldIgnoreHedge') === false 
                        && _.get(strategyItem, `shouldHedgeUseInitialBalance`)
                }),
                strategyKey => _.get(STRATEGY, `${strategyKey}.portfolioName`)
            )
        )
        
        const tokenInitialBalance = _.reduce(portfolioNamesShouldUseInitialBalance, (result, portfolioName) => {
            return result + Number(_.get(initialBalance, `initial_balance_${portfolioName}.${_.toLower(token || 0)}`, 0))
        }, 0)
        const initialBalanceInUSD = BigNumber(tokenInitialBalance).times(underlyingPrice).toString()
        const netExposure = BigNumber(netPositionInUSD).minus(initialBalanceInUSD).toString()
        return (
            <div className='option-positions--group-row hedged-group'>
                <div className='option-positions--group-row--name'>{name}</div>
                {this.StatsItem({
                    label: 'Net Position (USD)',
                    component: (
                        <span className={BigNumber(netPositionInUSD).gt(0) ? 'positive' : BigNumber(netPositionInUSD).lt(0) ? 'negative' : ''}>
                            {this.renderNumber(netPositionInUSD)}
                        </span>
                    )
                })}
                {this.StatsItem({
                    label: `Initial Balance`,
                    component: (
                        <Fragment>
                            <div className='option-positions--group-row--initial-balance-item'>
                                <span>{this.renderNumber(initialBalanceInUSD)}</span>
                                <label>{'USD'}</label>
                            </div>
                            <div className='option-positions--group-row--initial-balance-item remark'>
                                {'('}
                                <span>{this.renderNumber(tokenInitialBalance)}</span>
                                <label>{token}</label>
                                {')'}
                            </div>
                        </Fragment>
                    )
                })}
                {this.StatsItem({
                    label: 'Net Exposure (USD)',
                    component: (
                        <span className={BigNumber(netExposure).gt(0) ? 'positive' : BigNumber(netExposure).lt(0) ? 'negative' : ''}>
                            {this.renderNumber(netExposure)}
                        </span>
                    )
                })}
            </div>
        )
    } 

    Header  () {
        const { searchString, groupNamesShouldShowItems, strategies } = this.state
        const strategyNames = _.map(strategies, strategyKey => _.get(STRATEGY, `${strategyKey}.name`))
        return (
            <div className='option-positions--header'>
                <div className='option-positions--header--portfolio'>
                    <label>{'Strategy'}</label>
                    <Popup
                        className={'option-positions--header--strategy-popup'}
                        on={'click'}
                        trigger={
                            <button>
                                {_.isEmpty(strategyNames) ? 'EMPTY' : strategyNames.join(', ')}
                                <FiChevronDown />
                            </button>}>
                        {_.map(STRATEGY, strategyItem => {
                            const { key, name } = strategyItem
                            const checked = strategies.includes(key)
                            return (
                                <div className='option-positions--header--strategy-popup--option' 
                                    key={key}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        this.setState({
                                            strategies: checked ? _.without(strategies, key) : _.concat(strategies, key)
                                        })
                                    }}>
                                    <Checkbox checked={checked} />
                                    <label>{name}</label>
                                </div>
                            )
                        })} 
                    </Popup>
                </div>
                <button className='option-positions--header--toggle-group-button' onClick={() => {
                    if (!_.isEmpty(groupNamesShouldShowItems)) {
                        this.setState({ groupNamesShouldShowItems: [] })
                    } else {
                        const tableRows = this._getTableRows()
                        const allGroupNames = _.filter(tableRows, tableRow => [TABLE_ROW_TYPES.OPTION_GROUP, TABLE_ROW_TYPES.HEDGED_GROUP].includes(tableRow.type)).map(tableRow => tableRow.data.name)
                        this.setState({ groupNamesShouldShowItems: allGroupNames })
                    }
                }}>
                    {!_.isEmpty(groupNamesShouldShowItems) ? 'Collapse All' : 'Expand All'}
                    {!_.isEmpty(groupNamesShouldShowItems) ? <FiChevronsUp /> : <FiChevronsDown />}
                </button>
                <button className='option-positions--header--reset-button' onClick={() => {
                    this.setState({
                        searchString: '',
                        groupNamesShouldShowItems: []
                    })
                }}>{'RESET'}</button>
            <input className='option-positions--header--search-input'
                    type={'text'}
                    spellCheck={false}
                    placeholder={'Search Symbol, Account'} 
                    value={searchString}
                    onChange={(e) => { this.setState({ searchString: e.target.value }) }} />
            </div>
        )
    }

    render () {
        const { symbolItems } = this.props
        const { sortBy, sortOrder, groupNamesShouldShowItems } = this.state
        const tableRows = this._getTableRows()
        return (
            <div className='option-positions'>
                {this.Header()}
                <div className='option-positions--table-wrapper' ref={(node) => { this.tableWrapperNode = node }}>
                    <AutoSizer>
                        {({ width, height }) => (
                            <Table
                                ref={(node) => { this.tableNode = node }}
                                className='option-positions--table'
                                headerClassName={'option-positions--table--header'}
                                headerHeight={38}
                                width={Math.max(width, 1180)}
                                height={height}
                                rowCount={_.size(tableRows)}
                                rowGetter={({ index }) => tableRows[index]}
                                rowClassName={({ index }) => { 
                                    const tableRow = tableRows[index]
                                    let className = 'option-positions--table--row'
                                    if (tableRow) {
                                        className += ` ${tableRow.type}${index % 2 === 1 ? ' odd-row' : ' even-row'}${tableRow.nonClickable ? ' non-clickable' : ''}`
                                    } 
                                    return className
                                }}
                                rowHeight={({ index }) => { 
                                    const tableRow = tableRows[index]
                                    return [TABLE_ROW_TYPES.OPTION_GROUP, TABLE_ROW_TYPES.HEDGED_GROUP].includes(tableRow.type) ? 60 
                                        : TABLE_ROW_TYPES.OPTION_POSITION ? 50
                                        : 32
                                }}
                                overscanRowCount={5}
                                noRowsRenderer={() => (<div className='option-positions--table--no-content'>{'No matched records.'}</div> )}
                                sort={({ sortBy: newTableSortBy }) => {
                                    this.setState({
                                        sortBy: newTableSortBy,
                                        sortOrder: newTableSortBy === sortBy ? (sortOrder === SORT_ORDERS.ASC ? SORT_ORDERS.DESC : SORT_ORDERS.ASC) : sortOrder
                                    })
                                }}
                                onScroll={() => { this.handleScrollTableMain() }}
                                onRowClick={({ rowData }) => {
                                    if ([TABLE_ROW_TYPES.OPTION_GROUP, TABLE_ROW_TYPES.HEDGED_GROUP].includes(rowData.type) && rowData.nonClickable !== true) {
                                        const { name } = rowData.data
                                        const newGroupsNamesShouldShowItems = groupNamesShouldShowItems.includes(name)
                                            ? _.without(groupNamesShouldShowItems, name)
                                            : _.concat(groupNamesShouldShowItems, name)
                                        this.setState({ groupNamesShouldShowItems: newGroupsNamesShouldShowItems })
                                    }
                                }}>
                                <Column dataKey={'product_name'}
                                    label={'SYMBOL/ACCOUNT'}
                                    headerClassName={'sortable' + (sortBy === 'product_name' ? ' sorted' : '')}
                                    width={220}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data, nonClickable } = rowData
                                        return type === TABLE_ROW_TYPES.OPTION_GROUP ? this.OptionGroupRow(data, nonClickable)
                                            : type === TABLE_ROW_TYPES.HEDGED_GROUP ? this.HedgedGroupRow(data)
                                            : type === TABLE_ROW_TYPES.HEDGED_ACCOUNT_BALANCE ? `Acct. Bal: ${data.acct_name}`
                                            : data.product_name
                                    }} />
                                {/* <Column dataKey={'account_name'}
                                    label={'ACCOUNT'}
                                    headerClassName={'sortable' + (sortBy === 'account_name' ? ' sorted' : '')}
                                    width={120}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return [TABLE_ROW_TYPES.OPTION_POSITION, TABLE_ROW_TYPES.HEDGED_POSITION].includes(type) ? data.account_name : null
                                    }} /> */}
                                <Column dataKey={'timestamp'}
                                    label={'TIMESTAMP'}
                                    headerClassName={'sortable' + (sortBy === 'timestamp' ? ' sorted' : '')}
                                    width={80}
                                    flexGrow={0}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return [TABLE_ROW_TYPES.OPTION_POSITION, TABLE_ROW_TYPES.HEDGED_POSITION, TABLE_ROW_TYPES.HEDGED_ACCOUNT_BALANCE].includes(type) ? moment(data.timestamp).format('HH:mm:ss') : null
                                    }} />
                                <Column dataKey={'netPositionInUSD'}
                                    label={'Net Position (USD)'}
                                    headerClassName={'sortable' + (sortBy === 'netPositionInUSD' ? ' sorted' : '')}
                                    width={80}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        const symbolPricePrecision = getPricePrecisionBySymbolItem(symbolItems[data.product_name])
                                        return [TABLE_ROW_TYPES.OPTION_POSITION, TABLE_ROW_TYPES.HEDGED_POSITION].includes(type) ? <Popup className='option-positions--net-position-popup'
                                                trigger={
                                                    <span className={'option-positions--net-position-popup--trigger' + (BigNumber(data.netPositionInUSD || 0).gt(0) ? ' positive' : BigNumber(data.netPositionInUSD || 0).lt(0) ? ' negative' : '')}>
                                                        {this.renderNumber(data.netPositionInUSD)}
                                                    </span>}>
                                                <Fragment>
                                                    <div className='option-positions--net-position-popup--data-item'>
                                                        <label>{'Account'}</label>
                                                        <span>{data.account_name}</span>
                                                    </div>
                                                    <div className='option-positions--net-position-popup--data-item'>
                                                        <label>{'Long Position'}</label>
                                                        <span>{this.renderNumber(data.long_position)}</span>
                                                    </div>
                                                    <div className='option-positions--net-position-popup--data-item'>
                                                        <label>{'Long Avg Cost'}</label>
                                                        <span>{_.isEmpty(data.long_avg_cost) ? 'N/A' : this.renderNumber(data.long_avg_cost, symbolPricePrecision)}</span>
                                                    </div>
                                                    <div className='option-positions--net-position-popup--data-item'>
                                                        <label>{'Short Position'}</label>
                                                        <span>{this.renderNumber(data.short_position)}</span>
                                                    </div>
                                                    <div className='option-positions--net-position-popup--data-item'>
                                                        <label>{'Short Avg Cost'}</label>
                                                        <span>{_.isEmpty(data.short_avg_cost) ? 'N/A' : this.renderNumber(data.short_avg_cost, symbolPricePrecision)}</span>
                                                    </div>
                                                    <div className='option-positions--net-position-popup--data-item'>
                                                        <label>{'Current Price'}</label>
                                                        <span>{data.currentPrice}</span>
                                                    </div>
                                                </Fragment>
                                            </Popup> 
                                            : type === TABLE_ROW_TYPES.HEDGED_ACCOUNT_BALANCE ? <Popup className='option-positions--net-position-popup'
                                                trigger={
                                                    <span className={'option-positions--net-position-popup--trigger' + (BigNumber(data.netPositionInUSD || 0).gt(0) ? ' positive' : BigNumber(data.netPositionInUSD || 0).lt(0) ? ' negative' : '')}>
                                                        {this.renderNumber(data.netPositionInUSD)}
                                                    </span>}>
                                                <Fragment>
                                                    <div className='option-positions--net-position-popup--data-item'>
                                                        <label>{'Coin'}</label>
                                                        <span>{_.toUpper(data.coin || '')}</span>
                                                    </div>
                                                    {areAllValuesNonEmpty([data.option_value, data.equity]) && <>
                                                        <div className='option-positions--net-position-popup--data-item'>
                                                            <label>{'Equity'}</label>
                                                            <span>{this.renderNumber(data.equity)}</span>
                                                        </div>
                                                        <div className='option-positions--net-position-popup--data-item'>
                                                            <label>{'Option Value'}</label>
                                                            <span>{this.renderNumber(data.option_value)}</span>
                                                        </div>
                                                        <div className='option-positions--net-position-popup--data-item'>
                                                            <label>{'Exposure'}</label>
                                                            <span>{this.renderNumber(data.equity - data.option_value)}</span>
                                                        </div>
                                                    </>}
                                                    {!_.isEmpty(data.balance) &&
                                                    <div className='option-positions--net-position-popup--data-item'>
                                                        <label>{'Balance'}</label>
                                                        <span>{this.renderNumber(data.balance)}</span>
                                                    </div>}
                                                    <div className='option-positions--net-position-popup--data-item'>
                                                        <label>{'Source'}</label>
                                                        <span>{_.toUpper(data.source || '')}</span>
                                                    </div>
                                                </Fragment>
                                            </Popup>
                                            : null
                                    }} />
                                <Column dataKey={'delta'}
                                    label={'DELTA'}
                                    headerClassName={'sortable' + (sortBy === 'delta' ? ' sorted' : '')}
                                    width={110}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        const valueClassName = BigNumber(data.delta || 0).gt(0) ? 'positive' : BigNumber(data.delta || 0).lt(0) ? 'negative' : null
                                        return type === TABLE_ROW_TYPES.OPTION_POSITION
                                            ? (
                                                <div className='option-positions--delta'>
                                                    <div className='option-positions--delta--item'>
                                                        <span className={valueClassName}>{this.renderNumber(BigNumber(data.delta || 0).times(data.underlyingPrice || 0).toString())}</span>
                                                        <label>{'USD'}</label>
                                                    </div>
                                                    <div className='option-positions--delta--item remark'>
                                                        <span className={valueClassName}>{this.renderNumber(data.delta)}</span>
                                                        <label>{data.token}</label>
                                                    </div>

                                                </div>
                                            )
                                            : null
                                    }} />
                                <Column dataKey={'gamma'}
                                    label={'GAMMA'}
                                    headerClassName={'sortable' + (sortBy === 'gamma' ? ' sorted' : '')}
                                    width={70}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.OPTION_POSITION ? this.renderNumber(data.gamma, 3) : null
                                    }} />
                                <Column dataKey={'theta'}
                                    label={'THETA'}
                                    headerClassName={'sortable' + (sortBy === 'theta' ? ' sorted' : '')}
                                    width={70}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.OPTION_POSITION ? `$${this.renderNumber(data.theta)}` : null
                                    }} />
                                <Column dataKey={'vega'}
                                    label={'VEGA'}
                                    headerClassName={'sortable' + (sortBy === 'vega' ? ' sorted' : '')}
                                    width={70}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.OPTION_POSITION ? `$${this.renderNumber(data.vega)}` : null
                                    }} />
                                <Column dataKey={'rho'}
                                    label={'RHO'}
                                    headerClassName={'sortable' + (sortBy === 'rho' ? ' sorted' : '')}
                                    width={70}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.OPTION_POSITION ? `$${this.renderNumber(data.rho)}` : null
                                    }} />
                                <Column dataKey={'currentPrice'}
                                    label={'Current Price'}
                                    headerClassName={'sortable' + (sortBy === 'currentPrice' ? ' sorted' : '')}
                                    width={100}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return [TABLE_ROW_TYPES.OPTION_POSITION, TABLE_ROW_TYPES.HEDGED_POSITION].includes(type)
                                            ? data.currentPrice
                                            : null
                                    }} />
                                <Column dataKey={'bid_implied_volatility'}
                                    label={'BID IV'}
                                    headerClassName={'sortable' + (sortBy === 'bid_implied_volatility' ? ' sorted' : '')}
                                    width={70}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.OPTION_POSITION ? this.renderNumber(data.bid_implied_volatility, 4) : null
                                    }} />
                                <Column dataKey={'ask_implied_volatility'}
                                    label={'ASK IV'}
                                    headerClassName={'sortable' + (sortBy === 'ask_implied_volatility' ? ' sorted' : '')}
                                    width={70}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.OPTION_POSITION ? this.renderNumber(data.ask_implied_volatility, 4) : null
                                    }} />
                                <Column dataKey={'avg_cost'}
                                    label={'AVG PRICE'}
                                    headerClassName={'sortable' + (sortBy === 'avg_cost' ? ' sorted' : '')}
                                    width={70}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        if ([TABLE_ROW_TYPES.OPTION_POSITION, TABLE_ROW_TYPES.HEDGED_POSITION].includes(type)) {
                                            const { product_name: symbolName, long_avg_cost, short_avg_cost } = data
                                            const symbolPricePrecision = getPricePrecisionBySymbolItem(symbolItems[symbolName])
                                            if (!_.isEmpty(long_avg_cost) && !_.isEmpty(short_avg_cost)) {
                                                return `Long ${this.renderNumber(long_avg_cost, symbolPricePrecision)}, Short ${this.renderNumber(short_avg_cost, symbolPricePrecision)}`
                                            } else {
                                                return !_.isEmpty(long_avg_cost) ? this.renderNumber(long_avg_cost, symbolPricePrecision)
                                                    : !_.isEmpty(short_avg_cost) ? this.renderNumber(short_avg_cost, symbolPricePrecision)
                                                    : 'N/A'
                                            }
                                        }
                                    }} />
                                <Column dataKey={'unrealized_pnl'}
                                    label={'UPL'}
                                    headerClassName={'sortable' + (sortBy === 'unrealized_pnl' ? ' sorted' : '')}
                                    width={120}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return [TABLE_ROW_TYPES.OPTION_POSITION, TABLE_ROW_TYPES.HEDGED_POSITION, TABLE_ROW_TYPES.HEDGED_ACCOUNT_BALANCE].includes(type)
                                            ? <span className={BigNumber(data.unrealized_pnl || 0).gt(0) ? 'positive' : BigNumber(data.unrealized_pnl || 0).lt(0) ? 'negative' : null}>{this.renderNumber(data.unrealized_pnl)}</span>
                                            : null
                                    }} />
                            </Table>            
                        )}
                    </AutoSizer>
                </div>
            </div>
        )
    }

}

OptionPositions.propTypes = {
    optionImpliedVolatilities: PropTypes.object.isRequired,
    optionGreek: PropTypes.object.isRequired,
    nakedOptionGreek: PropTypes.object.isRequired,
    positions: PropTypes.array.isRequired,
    accountItems: PropTypes.object.isRequired,
    pricings: PropTypes.object.isRequired,
    symbolItems: PropTypes.object.isRequired,
    swapAccountBalances: PropTypes.object.isRequired,
    crossAccountBalances: PropTypes.object.isRequired,
    crossMarginAccountBalances: PropTypes.object.isRequired,
    initialBalance: PropTypes.object.isRequired,

    workspaceComponentId: PropTypes.string

}

function mapStateToProps (state) {
    return {
        optionImpliedVolatilities: state.symbol.optionImpliedVolatilities,
        optionGreek: state.trading.optionGreek,
        nakedOptionGreek: state.trading.nakedOptionGreek,
        positions: state.trading.positions,
        accountItems: state.account.items,
        pricings: state.symbol.pricings,
        symbolItems: state.symbol.items,
        swapAccountBalances: state.account.balance.swap,
        crossAccountBalances: state.account.balance.cross,
        crossMarginAccountBalances: state.account.balance.crossMargin,
        initialBalance: state.trading.initialBalance
    }
}

export default connect(mapStateToProps)(OptionPositions)