import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { useMountedState } from 'react-use'
import useEvent from 'react-use-event-hook'
import PropTypes from 'prop-types'
import Popup from '../common/popup/Popup'
import moment from 'moment'
import _ from 'lodash'

import { FaPlus } from 'react-icons/fa6'
import SearchSelect from '../common/searchSelect/SearchSelect'

import { deleteSymbolPriceMonitor, fetchSymbolPriceMonitors, postSymbolPriceMonitor } from './symbolAction'
import { isMetSearchStringCriteria } from '../../util/util'
import { MdDeleteOutline, MdEdit } from 'react-icons/md'

const ALERT_TYPES = {
    LAST_PRICE: {
        key: 'LAST_PRICE',
        name: 'Last Price'
    },
    PREM_INDEX_AVG: {
        key: 'PREM_INDEX_AVG',
        name: 'Premium Index Average'
    }
}

const OWNER_TAGS = {
    MINE: 'MINE',
    ALL: 'ALL'
}

function PriceMonitorConfigStruct ({ symbol='', alert_type=ALERT_TYPES.LAST_PRICE.key, low_threshold, high_threshold, setup_user }) {
    return {
        symbol,
        alert_type,
        low_threshold,
        high_threshold,
        setup_user
    }
}
  
function PriceAlertMonitor ({ dispatch, authUsername, symbolItems, priceAlertMonitors }) {

    const isMounted = useMountedState()
    const [searchString, setSearchString] = useState('')
    const [config, setConfig] = useState(PriceMonitorConfigStruct({}))
    const [formMessage, setFormMessage] = useState('')
    const [ownerTag, setOnwerTag] = useState(OWNER_TAGS.MINE)
    const [isPosting, setIsPosting] = useState(false)
    const [isFetching, setIsFetching] = useState(false)

    const seivedPriceMonitors = useMemo(() => {
        const _filteredMonitors = _.filter(priceAlertMonitors, _item => {
            return (ownerTag !== OWNER_TAGS.MINE || _item.setup_user === authUsername)
                && (_.isEmpty(searchString) || isMetSearchStringCriteria(`${_item.symbol} ${_item.alert_type} ${_item.setup_user}`, searchString))
        })
        return _filteredMonitors
    }, [searchString, ownerTag, authUsername, priceAlertMonitors])

    const _fetchPriceMonitors = useEvent(() => {
        if (!isFetching) {
            setIsFetching(true)
            dispatch(fetchSymbolPriceMonitors())
            .finally(() => {
                if (isMounted()) {
                    setIsFetching(false)
                }
            })
        }
    })

    useEffect(() => {
        _fetchPriceMonitors()
        const polling = setInterval(() => {
            _fetchPriceMonitors()
        }, 30000)
        return () => { window.clearInterval(polling) }
    }, [])

    const _setConfig = useEvent((params={}) => {
        const _newConfig = Object.assign({}, config, params)
        setConfig(_newConfig)
        setFormMessage('')
    })

    const _handleClickCreateButton = useEvent((isUpdating=false) => {
        const { symbol, alert_type, low_threshold, high_threshold } = config
        if (!isPosting) {
            if (_.isEmpty(symbol)) {
                setFormMessage('Symbol is required.')
            } else if (_.isEmpty(alert_type)) {
                setFormMessage('Type is required.')
            } else if (!(Number(low_threshold) < Number(high_threshold))) {
                setFormMessage('Lower price must be smaller than upper price')
            } else {
                setIsPosting(true)
                dispatch(postSymbolPriceMonitor(config))
                .then(response => {
                    if (response?.status === 200) {
                        setFormMessage(isUpdating ? 'Successfully updated!' : 'Successfully created!')
                    }
                })
                .finally(() => {
                    if (isMounted()) {
                        setIsPosting(false)
                    }
                })
            }
        }
    })

    function Form (isUpdating=false) {

        const symbolOptions = _.map(symbolItems, _item => ({
            value: _item.symbol_name,
            name: _item.symbol_name
        }))

        const isDuplicated = !isUpdating && _.some(priceAlertMonitors, _monitor => {
            return _monitor.symbol === config.symbol
                && _monitor.alert_type === config.alert_type
                && _monitor.setup_user === authUsername
        })
    
        return (
            <div className='price-alert-monitor--form' onClick={(e) => { e.stopPropagation() }}>
                <div className='price-alert-monitor--form--inputs'>
                    <div>
                        <label>{'Symbol'}</label>
                        <div className='price-alert-monitor--form--symbol'>
                            <SearchSelect
                                disabled={isUpdating}
                                value={config.symbol}
                                options={symbolOptions}
                                onChange={(_newOption) => { _setConfig({ symbol: _newOption.value }) }} />
                        </div>
                    </div>
                    <div>
                        <label>{'Type'}</label>
                        <div className='price-alert-monitor--form--alert-types'>
                            {_.map(ALERT_TYPES, _type => {
                                return (
                                    <button key={_type.key}
                                        className={_type.key === config.alert_type ? 'selected' : null}
                                        disabled={isUpdating}
                                        onClick={() => { _setConfig({ alert_type: _type.key }) }}>
                                        {_type.name}
                                    </button>
                                )
                            })}
                        </div>
                    </div>
                    <div>
                        <label>{'Lower Price'}</label>
                        <input
                            placeholder='0.0'
                            type='number' 
                            value={config.low_threshold}
                            onChange={(e) => { _setConfig({ low_threshold: e.target.value }) }} />
                    </div>
                    <div>
                        <label>{'Upper Price'}</label>
                        <input
                            placeholder='0.0'
                            type='number' 
                            value={config.high_threshold}
                            onChange={(e) => { _setConfig({ high_threshold: e.target.value }) }} />
                    </div>
                </div>
                <div className='price-alert-monitor--form--footer'>
                    {isDuplicated &&
                    <div className='price-alert-monitor--form--message duplicated-warning'>
                        {`You already have a monitor with this symbol-type combination. Creating a new one will overwrite the existing one.`}
                    </div>}
                    {!_.isEmpty(formMessage) && <div className='price-alert-monitor--form--message'>{formMessage}</div>}
                    <button className='price-alert-monitor--form--create-button'
                        disabled={isPosting}
                        onClick={() => { _handleClickCreateButton(isUpdating) }}>
                        {isPosting ? (isUpdating ? 'Updating' : 'Creating') : (isUpdating ? 'Update' : 'Create Alert Monitor')}
                    </button>
                </div>
            </div>
        )
    }

    return (
        <div className='price-alert-monitor'>
            <div className='price-alert-monitor--header'>
                <div className='price-alert-monitor--header--main'>
                    <input className='price-alert-monitor--search-input'
                        spellCheck={false}
                        placeholder='Search symbols, types, users'
                        value={searchString}
                        onChange={(e) =>{ setSearchString(e.target.value) }} />
                    <button className='price-alert-monitor--fetch-button'
                        disabled={isFetching}
                        onClick={() => { _fetchPriceMonitors() }}>{isFetching ? 'Fetching...' : 'Fetch Latest'}</button>
                    <Popup
                        className='price-alert-monitor--form-popup'
                        on={'click'}
                        trigger={<button className='price-alert-monitor--form-popup--trigger'><FaPlus /></button>}
                        onOpen={() => {
                            setConfig(PriceMonitorConfigStruct({
                                symbol: '',
                                alert_type: ALERT_TYPES.LAST_PRICE.key,
                                low_threshold: '',
                                high_threshold: '',
                                setup_user: authUsername
                            }))
                            setFormMessage('')
                        }}>
                        <div className='price-alert-monitor--form-popup--header'>{'New Alert Monitor'}</div>
                        <div className='price-alert-monitor--form-popup--main'>{Form()}</div>
                    </Popup>
                </div>
                <div className='price-alert-monitor--header--tags'>
                    {_.map(OWNER_TAGS, _tag => {
                        return (
                            <button key={_tag}
                                className={_tag === ownerTag ? 'selected' : null}
                                onClick={() => { setOnwerTag(_tag) }}>
                                {_.capitalize(_tag)}
                            </button>
                        )
                    })}
                </div>
            </div>
            {_.isEmpty(seivedPriceMonitors) && <div className='price-alert-monitor--empty-data'>{'Empty Data'}</div>}
            <div className='price-alert-monitor--list'>
                {_.map(seivedPriceMonitors, _monitor => {
                    return (
                        <div className='price-alert-monitor--item' key={`${_monitor.symbol}--${_monitor.alert_type}`}>
                            <div className='price-alert-monitor--item--row-1'>
                                <div className='price-alert-monitor--item--symbol'>{_monitor.symbol}</div>
                                <div className={`price-alert-monitor--item--alert-type ${_monitor.alert_type}`}>{_.get(ALERT_TYPES, `${_monitor.alert_type}.name`) ?? _monitor.alert_type}</div>
                                <div className='price-alert-monitor--item--info'>{`${_monitor.setup_user} @ ${moment(_monitor.update_time).format('YYYY-DD-MM HH:mm:ss')}`}</div>
                            </div>
                            <div className='price-alert-monitor--item--row-2'>
                                <div className='price-alert-monitor--item--price lower'>
                                    <label>{'Lower'}</label>
                                    <span>{_monitor.low_threshold}</span>
                                </div>
                                <div className='price-alert-monitor--item--price upper'>
                                    <label>{'Upper'}</label>
                                    <span>{_monitor.high_threshold}</span>
                                </div>
                                {_monitor.setup_user === authUsername &&
                                <div className='price-alert-monitor--item--buttons'>
                                    <Popup
                                        className='price-alert-monitor--edit-popup'
                                        on={'click'}
                                        trigger={<button><MdEdit /></button>}
                                        onOpen={() => {
                                            setConfig(PriceMonitorConfigStruct(_monitor))
                                            setFormMessage('')
                                        }}>
                                        <div className='price-alert-monitor--edit-popup--header'>{'Update Price Monitor'}</div>
                                        <div className='price-alert-monitor--edit-popup--main'>{Form(true)}</div>
                                    </Popup>
                                    <Popup
                                        className='price-alert-monitor--item--delete-popup'
                                        on={'click'}
                                        trigger={<button><MdDeleteOutline /></button>}>
                                        <div className='price-alert-monitor--item--delete-popup--message'>{'Delete the price monitor?'}</div>
                                        <button
                                            className='price-alert-monitor--item--delete-popup--button'
                                            onClick={() => {
                                                dispatch(deleteSymbolPriceMonitor({ symbol: _monitor.symbol, alert_type: _monitor.alert_type }))
                                            }}>{'Confirm'}</button>
                                    </Popup>
                                </div>}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

PriceAlertMonitor.propTypes = {
    dispatch: PropTypes.func.isRequired,
    authUsername: PropTypes.string.isRequired,
    symbolItems: PropTypes.object.isRequired,
    priceAlertMonitors: PropTypes.arrayOf(PropTypes.shape({
        symbol: PropTypes.string.isRequired,
        alert_type: PropTypes.oneOf(_.keys(ALERT_TYPES)).isRequired,
        low_threshold: PropTypes.number,
        high_threshold: PropTypes.number,
        setup_user: PropTypes.string
    }))
}

function mapStateToProps (state) {
    return {
        authUsername: state.auth.username,
        symbolItems: state.symbol.items,
        priceAlertMonitors: state.symbol.priceAlertMonitors
    }
}

export default connect(mapStateToProps)(PriceAlertMonitor)
