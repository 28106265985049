import dotProp from 'dot-prop-immutable'
import _ from 'lodash'

import { UPDATE_ACCOUNT_ITEMS, UPDATE_ACCOUNT_SPOT_BALANCE, 
    UPDATE_ACCOUNT_MARGIN_BALANCE, UPDATE_ACCOUNT_FUTURE_BALANCE, 
    UPDATE_ACCOUNT_SWAP_BALANCE, UPDATE_ACCOUNT_WALLET_BALANCE,
    UPDATE_ACCOUNT_ASSET, UPDATE_ACCOUNT_BALANCES, UPDATE_ACCOUNT_CROSS_BALANCE, UPDATE_ACCOUNT_CROSS_MARGIN_BALANCE, UPDATE_ACCOUNT_OPTION_BALANCE,
    UPDATE_ACCOUNT_CONTRACT_BALANCE, UPDATE_ACCOUNT_FUND_BALANCE, UPDATE_ACCOUNT_FUNDING_BALANCE, UPDATE_BNBFUTA_PM2DOT0_UNIFIED_BALANCE,
    UPDATE_ACCOUNT_AVAILABLE_BALANCE, UPDATE_ACCOUNT_RISK_INFO, UPDATE_PORTFOLIO_MARGIN_ACCOUNT_INFO, UPDATE_DERIBIT_PORTFOLIO_MARGIN_ACCOUNT_INFO,
    UPDATE_PORTFOLIO_MARGIN_VIRTUAL_ACCOUNT_INFO, UPDATE_ACCOUNT_SPOT_LOAN_BALANCE, UPDATE_DERIBIT_PORTFOLIO_MARGIN_ACCOUNT_IM_INFO, 
    UPDATE_PORTFOLIO_EFF_RATIO_VIRTUAL_ACCOUNT_INFO} from './accountAction'

const initialState = {
    items: {},
    balance: {
        spot: {},
        margin: {},
        crossMargin: {},
        future: {},
        swap: {},
        cross: {},
        wallet: {},
        option: {},
        spotLoan: {},
        contract: {},
        fund: {},
        funding: {},
        bnbfutaPM2Unified: {}
    },
    availableBalance: [],
    asset: {},
    riskInfo: [],
    portfolioMarginAccountInfo: {},
    deribitPortfolioMarginAccountInfo: {},
    deribitPortfolioMarginAccountIMInfo: {},
    portfolioMarginVirtualAccountInfo: {},
    portfolioMarginEffRatioVirtualAccountInfo: {}
}

export function accountReducer (state = initialState, action) {
    let newBalance 
    switch(action.type) {
        case UPDATE_ACCOUNT_ITEMS:
            return dotProp.merge(state, 'items', action.accounts)

        case UPDATE_ACCOUNT_SPOT_BALANCE:
            return dotProp[action.shouldMerge ? 'merge' : 'set'](state, 'balance.spot', action.spotBalance)

        case UPDATE_ACCOUNT_MARGIN_BALANCE:
            return dotProp[action.shouldMerge ? 'merge' : 'set'](state, 'balance.margin', action.marginBalance)

        case UPDATE_ACCOUNT_CROSS_MARGIN_BALANCE:
            return dotProp[action.shouldMerge ? 'merge' : 'set'](state, 'balance.crossMargin', action.crossMarginBalance)

        case UPDATE_ACCOUNT_OPTION_BALANCE:
            return dotProp[action.shouldMerge ? 'merge' : 'set'](state, 'balance.option', action.optionBalance)

        case UPDATE_ACCOUNT_CONTRACT_BALANCE:
            return dotProp[action.shouldMerge ? 'merge' : 'set'](state, 'balance.contract', action.contractBalance)

        case UPDATE_ACCOUNT_FUND_BALANCE:
            return dotProp[action.shouldMerge ? 'merge' : 'set'](state, 'balance.fund', action.fundBalance)

        case UPDATE_ACCOUNT_FUNDING_BALANCE:
            return dotProp[action.shouldMerge ? 'merge' : 'set'](state, 'balance.funding', action.fundingBalance)

        case UPDATE_BNBFUTA_PM2DOT0_UNIFIED_BALANCE:
            return dotProp[action.shouldMerge ? 'merge' : 'set'](state, 'balance.bnbfutaPM2Unified', action.balance)

        case UPDATE_ACCOUNT_FUTURE_BALANCE:
            return dotProp[action.shouldMerge ? 'merge' : 'set'](state, 'balance.future', action.futureBalance)

        case UPDATE_ACCOUNT_SWAP_BALANCE:
            return dotProp[action.shouldMerge ? 'merge' : 'set'](state, 'balance.swap', action.swapBalance)

        case UPDATE_ACCOUNT_WALLET_BALANCE:
            return dotProp[action.shouldMerge ? 'merge' : 'set'](state, 'balance.wallet', action.walletBalance)

        case UPDATE_ACCOUNT_CROSS_BALANCE:
            return dotProp[action.shouldMerge ? 'merge' : 'set'](state, 'balance.cross', action.crossBalance)

        case UPDATE_ACCOUNT_SPOT_LOAN_BALANCE:
            return dotProp.set(state, 'balance.spotLoan', action.spotLoanBalance)

        case UPDATE_ACCOUNT_ASSET:
            return dotProp[action.shouldMerge ? 'merge' : 'set'](state, 'asset', action.asset)

        case UPDATE_ACCOUNT_BALANCES: 
            newBalance = _.cloneDeep(state.balance)
            if (!_.isNil(action.spotBalance)) {
                newBalance.spot = action.shouldMerge ? Object.assign({}, state.balance.spot, action.spotBalance) : action.spotBalance
            }
            if (!_.isNil(action.marginBalance)) {
                newBalance.margin = action.shouldMerge ? Object.assign({}, state.balance.margin, action.marginBalance) : action.marginBalance
            }
            if (!_.isNil(action.futureBalance)) {
                newBalance.future = action.shouldMerge ? Object.assign({}, state.balance.future, action.futureBalance) : action.futureBalance
            }
            if (!_.isNil(action.swapBalance)) {
                newBalance.swap = action.swapBalance ? Object.assign({}, state.balance.swap, action.swapBalance) : action.swapBalance
            }
            return dotProp.set(state, 'balance', newBalance)

        case UPDATE_ACCOUNT_AVAILABLE_BALANCE:
            return dotProp.set(state, 'availableBalance', action.availableBalance)

        case UPDATE_ACCOUNT_RISK_INFO:
            return dotProp.set(state, 'riskInfo', action.accountRiskInfo)

        case UPDATE_PORTFOLIO_MARGIN_ACCOUNT_INFO:
            return dotProp.set(state, 'portfolioMarginAccountInfo', action.portfolioMarginAccountInfo)

        case UPDATE_DERIBIT_PORTFOLIO_MARGIN_ACCOUNT_INFO:
            return dotProp.set(state, 'deribitPortfolioMarginAccountInfo', action.deribitPortfolioMarginAccountInfo)

        case UPDATE_DERIBIT_PORTFOLIO_MARGIN_ACCOUNT_IM_INFO:
            return dotProp.set(state, 'deribitPortfolioMarginAccountIMInfo', action.deribitPortfolioMarginAccountIMInfo)

        case UPDATE_PORTFOLIO_MARGIN_VIRTUAL_ACCOUNT_INFO:
            return dotProp.set(state, 'portfolioMarginVirtualAccountInfo', action.portfolioMarginVirtualAccountInfo)

        case UPDATE_PORTFOLIO_EFF_RATIO_VIRTUAL_ACCOUNT_INFO:
            return dotProp.set(state, 'portfolioMarginEffRatioVirtualAccountInfo', action.portfolioMarginEffRatioVirtualAccountInfo)
            
        default:
            return state
    }
}