import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import useEvent from 'react-use-event-hook'
import { useMountedState } from 'react-use'
import PropTypes from 'prop-types'

import { nanoid } from 'nanoid'
import _ from 'lodash'

import ReactLoading from 'react-loading'

import ProfileSnapshotEditor, { MODE } from './ProfileSnapshotEditor'
import ProfileSnapshot from './ProfileSnapshot'
import { fetchProfileSnapshots } from './profileAction'

const ProfileSnapshotContainer = ({ profileItem, onApplySnapshot }) => {

    const isMounted = useMountedState()
    const dispatch = useDispatch()
    const [snapshots, setSnapshots] = useState([])
    const [isFetchingSnapshots, setIsFetchingSnapshots] = useState(false)
    const fetchId = useRef(null)

    const sortedSnapshots = useMemo(() => {
        return _.sortBy(snapshots, 'lastUpdateTime').reverse()
    }, [snapshots])

    const profileId = _.get(profileItem, 'id')

    const _fetchSnapshots = useEvent(() => {
        const _fetchId = nanoid()
        fetchId.current = _fetchId
        setIsFetchingSnapshots(true)
        dispatch(fetchProfileSnapshots(profileId))
        .then((_snapshots) => {
            if (isMounted()) {
                setSnapshots(_snapshots)
            }
        })
        .finally(() => {
            if (isMounted() && _fetchId === fetchId.current) {
                setIsFetchingSnapshots(false)
            }
        })
    })

    useEffect(() => {
        if (!_.isEmpty(snapshots)) {
            setSnapshots([])
        }
        _fetchSnapshots()
    }, [profileId])

    return (
        <div className='profile-snapshot-container'>
            <div className='profile-snapshot-container--creation'>
                <div className='profile-snapshot-container--creation--title'>{'New Snapshot'}</div>
                <div className='profile-snapshot-container--creation--main'>
                    <ProfileSnapshotEditor
                        profileItem={profileItem}
                        mode={MODE.CREATE_SNAPSHOT}
                        onSaved={() => { _fetchSnapshots() }} />
                </div>
            </div>
            <hr />
            <div className='profile-snapshot-container--list'>
                <div className='profile-snapshot-container--list--title'>
                    {'Profile Snapshots'}
                    {isFetchingSnapshots &&
                    <ReactLoading className='profile-snapshot-container--loading'
                        type={'spin'}
                        color='#fff' />}
                </div>
                {!isFetchingSnapshots && _.isEmpty(sortedSnapshots)
                ? <div className='profile-snapshot-container--list--empty'>{'Empty Saved Data'}</div>
                : <div className='profile-snapshot-container--list--main'>
                    {_.map(sortedSnapshots, snapshot => {
                        const { _id } = snapshot
                        return (
                            <div className='profile-snapshot-container--list--item' key={_id}>
                                <ProfileSnapshot
                                    profileItem={profileItem}
                                    snapshot={snapshot}
                                    onClickApply={() => { onApplySnapshot(snapshot) }}
                                    onUpdated={() => { _fetchSnapshots() }} />
                            </div>
                        )
                    })}
                </div>}
            </div>
        </div>
    )
}

ProfileSnapshotContainer.propTypes = {
    profileItem: PropTypes.object.isRequired,
    onApplySnapshot: PropTypes.func.isRequired
}

ProfileSnapshotContainer.defaultProps = {
    onApplySnapshot: () => {}
}

export default memo(ProfileSnapshotContainer)