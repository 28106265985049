/* eslint-disable react/prop-types */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import randomColor from 'randomcolor'

import { ChartCanvas, Chart } from 'react-stockcharts'
import { discontinuousTimeScaleProvider } from 'react-stockcharts/lib/scale'
import { XAxis, YAxis } from 'react-stockcharts/lib/axes'
import { LineSeries } from 'react-stockcharts/lib/series'
import { CrossHairCursor } from 'react-stockcharts/lib/coordinates'
import { ClickCallback } from 'react-stockcharts/lib/interactive'
import { FiX } from 'react-icons/fi'
import SearchSelect from '../common/searchSelect/SearchSelect'
import { fetchFundingRateHistory } from './symbolAction'
import { toNumberWithSmartPrecision } from '../../util/util'
import { getSymbolAttributeByName } from '../../util/symbolUtil'

const TimeRange = ({ name, fromMomentValue, fromMomentUnit }) => {
    return {
        name,
        fromMomentOption: {
            value: fromMomentValue,
            unit: fromMomentUnit
        }
    }
}

const cachedWorkspaceComponentStates = {}

class SymbolFundingRateHistory extends Component {
    constructor (props) {
        super(props)
        this.timeRanges = {
            D1: TimeRange({ name: '1 Day', fromMomentValue: -1, fromMomentUnit: 'days' }),
            D3: TimeRange({ name: '3 Days', fromMomentValue: -3, fromMomentUnit: 'days' }),
            D7: TimeRange({ name: '7 Days', fromMomentValue: -7, fromMomentUnit: 'days' }),
            D30: TimeRange({ name: '30 Days', fromMomentValue: -30, fromMomentUnit: 'days' }),
            D90: TimeRange({ name: '90 Days', fromMomentValue: -90, fromMomentUnit: 'days' })
        }
        const initialSymbolNames = this._getSymbolNamesFromStorage()
        this.state = _.get(cachedWorkspaceComponentStates, props.workspaceComponentId, {
            symbolNames: !_.isEmpty(initialSymbolNames) ? initialSymbolNames : ['btc_usd_OKEX_SWAP'],
            timeRangeKey: this._getTimeRangeKeyFromStorage(),
            isFetching: false,
            chartCanvasWidth: 0,
            chartCanvasHeight: 0,
            currentItem: null,
            chartData: []
        })

        this.chartCanvasMargin = { left: 50, right: 15, top: 12, bottom: 28 }
        this.chartCanvasWrapper = null
        this.strokeColors = ['#8AE1FC', 'orange', '#FFF3B0', '#dc7479', '#bf80ff', 'lightseagreen', '#979ACF', '#a4f542', '#f2f2f2']
        this.symbolColors = {}
        this.symbolExchangesShouldMergeFundingRateHistory = ['FTX']

        this.updateCanvasWidthInterval = null
    }

    componentDidMount () {
        const { symbolNames } = this.state
        this.getFundingRateHistory(symbolNames)
        this.updateChartCanvasSize()
        this.updateChartData()
        this.updateCanvasWidthInterval = setInterval(() => {
            this.updateChartCanvasSize()
        }, 1000)
    }

    componentDidUpdate (prevProps, prevState) {
        const { timeRangeKey, symbolNames } = this.state
        if (!_.isEqual(prevState.timeRangeKey, timeRangeKey)
            || !_.isEqual(prevState.symbolNames, symbolNames)) {
            this.updateChartData()
        }
    }

    componentWillUnmount () {
        const { workspaceComponentId } = this.props
        if (this.updateCanvasWidthInterval) {
            window.clearInterval(this.updateCanvasWidthInterval)
        }
        if (!_.isEmpty(workspaceComponentId)) {
            cachedWorkspaceComponentStates[workspaceComponentId] = _.cloneDeep(this.state)
        }
    }

    _getTimeRangeKeyFromStorage () {
        const storageTimeRangeKey = _.get(localStorage, 'fundingRateHistoryTimeRangeKey')
        const timeRangeKeys = Object.keys(this.timeRanges)
        return timeRangeKeys.includes(storageTimeRangeKey) ? storageTimeRangeKey : timeRangeKeys[0]
    }

    _updateTimeRangeKey (timeRangeKey) {
        localStorage.fundingRateHistoryTimeRangeKey = timeRangeKey
        this.setState({ timeRangeKey: this._getTimeRangeKeyFromStorage() })
    }

    _getSymbolNamesFromStorage () {
        const { workspaceComponentId } = this.props
        let result = []
        const storageSymbolNames = _.has(localStorage, `fundingRateHistorySymbols--${workspaceComponentId}`) 
            ? _.get(localStorage, `fundingRateHistorySymbols--${workspaceComponentId}`) 
            : _.get(sessionStorage, 'fundingRateHistorySymbols')
        
        if (_.isString(storageSymbolNames) && storageSymbolNames.trim().length > 0) {
            try {
                result = storageSymbolNames.split(',')
            } catch (error) {
                console.error(`SymbolFundingRateHistory getSymbolNamesFromStorage error: `, error)
            }
        }
        return result
    }

    _updateSymbolNames (symbolNames=[]) {
        const { workspaceComponentId } = this.props
        localStorage[`fundingRateHistorySymbols--${workspaceComponentId}`] = !_.isEmpty(symbolNames) ? symbolNames.join() : ''
        this.setState({ symbolNames: this._getSymbolNamesFromStorage() })
    }

    getSymbolColor (symbolName) {
        if (!_.has(this.symbolColors, symbolName)) {
            const occupiedColors = Object.values(this.symbolColors)
            const strokeColor = _.find(this.strokeColors, color => !occupiedColors.includes(color)) || randomColor()
            this.symbolColors[symbolName] = strokeColor
        }
        return this.symbolColors[symbolName]
    }

    updateChartCanvasSize () {
        const { chartCanvasWidth,  chartCanvasHeight } = this.state
        if (this.chartCanvasWrapper) {
            const { clientWidth, clientHeight } = this.chartCanvasWrapper
            if (!_.isEqual(chartCanvasWidth, clientWidth) || !_.isEqual(chartCanvasHeight, clientHeight)) {
                this.setState({
                    chartCanvasWidth: clientWidth,
                    chartCanvasHeight: clientHeight
                })
            }   

        }
    }

    getFundingRateHistory (symbolNames=[]) {
        const { dispatch, fundingRateHistory } = this.props
        const getFrom = () => {
            let fromMoment = moment()
            symbolNames.forEach(symbolName => {
                const symbolFundingRateHistory = fundingRateHistory[symbolName]
                if (_.isEmpty(symbolFundingRateHistory)) {
                    fromMoment = moment().add(-90, 'days')
                } else {
                    const lastFundingRateTime = _.last(symbolFundingRateHistory).time
                    if (fromMoment.isAfter(lastFundingRateTime)) {
                        fromMoment = moment(lastFundingRateTime)
                    }
                }
            })
            return fromMoment.toISOString()
        }
        this.setState({ isFetching: true })
        dispatch(fetchFundingRateHistory(symbolNames, getFrom()))
        .then(() => {
            this.setState({ isFetching: false })
            this.updateChartData()
        })
        .catch(() => {
            this.setState({ isFetching: false })
        })
    }

    getChartData () {
        const { symbolNames, timeRangeKey } = this.state
        const { fundingRateHistory } = this.props
        const timeRange = this.timeRanges[timeRangeKey]
        const fromMoment = moment().add(timeRange.fromMomentOption.value, timeRange.fromMomentOption.unit)
        const pickedFundingRateHistory = _.pick(fundingRateHistory, symbolNames)
        const cumulativeFundingRates = _.reduce(Object.keys(pickedFundingRateHistory), (result, symbolName) => {
            result[symbolName] = 0
            return result
        }, {})
        const filteredFundingRateHistoryArray = _.reduce(pickedFundingRateHistory, (result, symbolFundingRateHistory) => {
            result.push(...symbolFundingRateHistory.filter(item => fromMoment.isSameOrBefore(item.time)))
            return result
        } ,[])
        const fundingRateHistoryGroupedByTime = _.groupBy(filteredFundingRateHistoryArray, symbolFundingRateHistory => {
            const { exchangeName } = getSymbolAttributeByName(symbolFundingRateHistory.symbol)
            if (this.symbolExchangesShouldMergeFundingRateHistory.includes(exchangeName)) {
                const historyMoment = moment(symbolFundingRateHistory.time)
                const hour = historyMoment.utcOffset(0).hour()
                if (hour <= 4) {
                    return historyMoment.add(4 - hour, 'hours').toISOString()
                } else if (hour <= 12) {
                    return historyMoment.add(12 - hour, 'hours').toISOString()
                } else if (hour <= 20) {
                    return historyMoment.add(20 - hour, 'hours').toISOString()
                } else {
                    return historyMoment.add(28 - hour, 'hours').toISOString()
                }
            } else {
                return symbolFundingRateHistory.time
            }
        })
        const chartData = Object.keys(fundingRateHistoryGroupedByTime).sort().reduce((result, time) => {
            const fundingRateHistoryItems = fundingRateHistoryGroupedByTime[time]
            fundingRateHistoryItems.forEach(fundingRateHistoryItem => {
                const { symbol, real_funding_rate: realFundingRate } = fundingRateHistoryItem
                cumulativeFundingRates[symbol] += realFundingRate
            })
            result.push({
                time: new Date(time),
                ...cumulativeFundingRates
            })
            return result
        }, [])

        return chartData
    }

    updateChartData () {
        this.setState({
            chartData: this.getChartData()
        })
    }

    Tooltip () {
        const { currentItem, chartData } = this.state

        const Row = ({key, name, value, hasMarker, markerColor, shouldApplySmartPrecision, valuePostfix}) => (
            <div className='symbol-funding-rate-history--tooltip--row' key={key}>
                <div className='symbol-funding-rate-history--tooltip--row--marker-wrapper'>
                    <span className='symbol-funding-rate-history--tooltip--row--marker'  
                        style={{
                        background: markerColor,
                        visibility: hasMarker ? 'visible' : 'hidden'
                    }} /> 
                </div>
                <div className='symbol-funding-rate-history--tooltip--row--name'>{name}</div>
                {!_.isNil(value) && <div className='symbol-funding-rate-history--tooltip--row--value'>
                    {shouldApplySmartPrecision ? toNumberWithSmartPrecision({ number: value, defaultPrecision: 3 }) : value }
                    {!_.isNil(valuePostfix) ? ` ${valuePostfix}` : ''}
                </div>}
            </div>
        )

        return currentItem || !_.isEmpty(chartData) ? (
            <div className='symbol-funding-rate-history--tooltip'>
                {Row({
                    key: 'time',
                    name: 'Time',
                    value: moment(currentItem ? currentItem.time : _.last(chartData).time).format('MM-DD HH:mm:ss'),
                    hasMarker: false
                })}
                {_.map(_.sortBy(_.toPairs(_.omit(currentItem || _.last(chartData), ['time', 'idx'])), pair => -pair[1]), (pair) => {
                    const [symbolName, value] = pair
                    return Row({
                        key: symbolName,
                        name: symbolName,
                        value: value * 10000,
                        valuePostfix: '%%',
                        hasMarker: true,
                        markerColor: this.getSymbolColor(symbolName),
                        shouldApplySmartPrecision: true
                    })
                })}
            </div>
        ) : null
    }

    Header () {
        const { timeRanges } = this
        const { timeRangeKey, symbolNames } = this.state
        const { fundingRateHistorySymbols, fundingRateHistory } = this.props
        const pairs = Object.values(fundingRateHistorySymbols.reduce((result, symbolName) => {
            const { base, quote } = getSymbolAttributeByName(symbolName)
            const pair = `${base}/${quote}`
            result[pair] = pair
            return result
        }, {}))
        const Block = ({name, component}) => {
            return (
                <div className='symbol-funding-rate-history--header--block'>
                    <div className='symbol-funding-rate-history--header--block--name'>{name}</div>
                    <div className='symbol-funding-rate-history--header--block--body'>{component}</div>
                </div>
            )
        }

        return (
            <div className='symbol-funding-rate-history--header'>
                {Block({
                    name: 'Time Range',
                    component: (
                        <div className='symbol-funding-rate-history--time-ranges'>
                            {_.map(timeRanges, (timeRangeItem, key) => {
                                return (
                                    <button className={'symbol-funding-rate-history--time-range' + (timeRangeKey === key ? ' active' : '')} key={key} 
                                        onClick={() => { this._updateTimeRangeKey(key) }}>
                                        {timeRangeItem.name}
                                    </button>
                                )
                            })}
                        </div>
                    )
                })}
                {Block({
                    name: 'Symbols',
                    component: (
                        <div className='symbol-funding-rate-history--symbols clearfix'>
                            {_.map(symbolNames, symbolName => {
                                const hasNilFundingRateHistory = _.isEmpty(fundingRateHistory[symbolName])
                                return (
                                    <div className={'symbol-funding-rate-history--symbols--item' + (hasNilFundingRateHistory ? ' has-nil-history' : '')} 
                                        key={symbolName} 
                                        title={hasNilFundingRateHistory ? 'Has Nil History Data' : symbolName}>
                                        <span>{symbolName}</span>
                                        <button className='symbol-funding-rate-history--symbols--item--remove-button' 
                                            onClick={() => { 
                                                delete this.symbolColors[symbolName]
                                                this._updateSymbolNames(_.without(symbolNames, symbolName))
                                            }}><FiX /></button>
                                    </div>
                                )
                            })}
                            <SearchSelect className='symbol-funding-rate-history--symbols--symbol-select'
                                options={_.without(fundingRateHistorySymbols, ...symbolNames).map(symbolName => {
                                    return {
                                        value: symbolName,
                                        name: symbolName
                                    }
                                })}
                                value={null} 
                                placeholder={'Add Symbol'}
                                onChange={(newOption) => {
                                    if (!symbolNames.includes(newOption.value)) {
                                        const newSymbolNames = symbolNames.concat([newOption.value])
                                        this.getFundingRateHistory([newOption.value])
                                        this._updateSymbolNames(newSymbolNames)
                                    }    
                                }} />

                            <SearchSelect className='symbol-funding-rate-history--symbols--pair-select'
                                options={pairs.map(pair => {
                                    return {
                                        value: pair,
                                        name: pair
                                    }
                                })} 
                                value={null}
                                placeholder={'Add Pair'} 
                                onChange={(newOption) => {
                                    const tokens = newOption.value.split('/')
                                    const newSymbolNames = _.union(symbolNames, fundingRateHistorySymbols.filter(symbolName => {
                                        const { base, quote } = getSymbolAttributeByName(symbolName)
                                        return base === tokens[0] && quote === tokens[1]
                                    }))
                                    this.getFundingRateHistory(_.difference(newSymbolNames, symbolNames))
                                    this._updateSymbolNames(newSymbolNames)
                                }} />
                            {!_.isEmpty(symbolNames) && <button className='symbol-funding-rate-history--symbols--clear-button' onClick={() => {
                                this.symbolColors = {}
                                this._updateSymbolNames([])
                            }}>{'Clear'}</button>}
                        </div>
                    )
                })}
            </div>
        )
    }

    render () {
        const { isFetching, chartCanvasWidth, chartCanvasHeight, symbolNames, chartData } = this.state
        const xScaleProvider = discontinuousTimeScaleProvider.inputDateAccessor(d => d.time)
        const { data, xScale, xAccessor, displayXAccessor } = xScaleProvider(chartData)
        const xExtents = [xAccessor(_.head(data)), xAccessor(_.last(data))]

        const grid = {
            innerTickSize: -1 * (chartCanvasWidth - this.chartCanvasMargin.left - this.chartCanvasMargin.right),
            tickStrokeDasharray: 'ShortDash',
            tickStrokeOpacity: 0.2,
            tickStrokeWidth: 1
        }

        return (
            <div className='symbol-funding-rate-history' onClick={e => { e.stopPropagation() }}>
                {this.Header()}
                <div className='symbol-funding-rate-history--body'>
                    {isFetching && <div className='symbol-funding-rate-history--fetching-tag'>{'Fetching ...'}</div>}
                    <div className='symbol-funding-rate-history--chart-canvas-wrapper' 
                        ref={(node) => { this.chartCanvasWrapper = node }}
                        onMouseLeave={() => { this.setState({ currentItem: null }) }}>
                        {chartCanvasWidth > 0 && chartCanvasHeight > 0 && !_.isEmpty(chartData) && 
                        <ChartCanvas 
                            className='symbol-funding-rate-history--chart-canvas' 
                            seriesName='Cumulative Funding Rate'
                            data={data}
                            ratio={2}
                            width={chartCanvasWidth}
                            height={chartCanvasHeight}
                            margin={this.chartCanvasMargin} 
                            type='hybrid'
                            xScale={xScale}
                            clamp
                            xAccessor={xAccessor}
                            displayXAccessor={displayXAccessor} 
                            xExtents={xExtents}
                            pointsPerPxThreshold={1}>
                            <Chart id={1}
                                yExtents={d => Object.values(_.omit(d, 'time'))}
                                padding={{top: 20, bottom: 20, left: 10, right: 10}}>
                                <XAxis 
                                    axisAt='bottom' 
                                    orient='bottom'
                                    fontSize={13}
                                    tickPadding={5}
                                    stroke={'#aaaaaa'}
                                    tickStroke={'#eeeeee'}
                                    fontFamily={'Source Sans Pro'} 
                                    fontWeight={600} />
                                <YAxis
                                    axisAt='left' 
                                    orient='left' 
                                    fontSize={13}
                                    ticks={7} 
                                    stroke={'#aaaaaa'}
                                    tickStroke={'#eeeeee'}
                                    fontFamily={'Source Sans Pro'} 
                                    fontWeight={600} 
                                    tickFormat={(v) => `${Number(v * 10000).toFixed(0)}%%`}
                                    {...grid} />
                                {symbolNames.map(symbolName => {
                                    const stroke = this.getSymbolColor(symbolName)
                                    return (
                                        <Fragment key={symbolName}>
                                            <LineSeries
                                                yAccessor={d => d[symbolName]}
                                                stroke={stroke}
                                                strokeDasharray={'Solid'}
                                                strokeWidth={1.3}
                                                connectNulls />
                                        </Fragment>
                                    )
                                })}
                                <ClickCallback
                                    onMouseMove={(moreProps) => {
                                        const { currentItem } = moreProps
                                        this.setState({ currentItem: currentItem })
                                    }} />
                            </Chart>
                            <CrossHairCursor 
                                stroke={'lightslategray'} 
                                opacity={1} />
                        </ChartCanvas>}
                        {this.Tooltip()}
                    </div>
                </div>
            </div>
        )
    }
}

SymbolFundingRateHistory.propTypes = {
    dispatch: PropTypes.func.isRequired,
    fundingRateHistorySymbols: PropTypes.array.isRequired,
    fundingRateHistory: PropTypes.object.isRequired,

    workspaceComponentId: PropTypes.string
}

function mapStateToProps (state) {
    return {
        fundingRateHistorySymbols: state.symbol.fundingRateHistorySymbols,
        fundingRateHistory: state.symbol.fundingRateHistory
    }
}

export default connect(mapStateToProps)(SymbolFundingRateHistory)