const mockedBasis = {
    usdQuoted: {
        BTC: {
            timestamp: '2019-06-04 21:05:36',
            okex: {
                spot: 7999.5,
                index: 7965.25,
                weekly: {
                    price: 7978.08,
                    basis: [0.03, 0.04]
                },
                biWeekly: {
                    price: 7998.05,
                    basis: [0.3, 0.31]
                },
                quaterly: {
                    price: 8011.41,
                    basis: [0.47, 0.49]
                }
            },
            bitmex: {
                index: 7974.37,
                perpetual: {
                    price: 7968.0,
                    basis: [-0.08, -0.07]
                },
                currentQuater: {
                    price: 7970.0,
                    basis: [-0.05, -0.05]
                },
                nextQuater: {
                    price: 7958.5,
                    basis: [-0.21, -0.2]
                }
            }
        },
        ETH: {
            timestamp: '2019-06-04 13:05:36',
            okex: {
                spot: 248.09,
                index: 247.518,
                weekly: {
                    price: 248.146,
                    basis: [0.22, 0.25]
                },
                biWeekly: {
                    price: 249.011,
                    basis: [0.6, 0.63]
                },
                quaterly: {
                    price: 251.081,
                    basis: [1.14, 1.15]
                }
            },
            bitmex: {
                index: 247.56,
                perpetual: {
                    price: 247.45,
                    basis: [-0.04, -0.02]
                }
            }
        },
        BCH: {
            timestamp: '2019-06-04 20:12:32',
            okex: {
                spot: 398.18,
                index: 396.798,
                weekly: {
                    price: 397.97,
                    basis: [0.3, 0.35]
                },
                biWeekly: {
                    price: 398.99,
                    basis: [0.56, 0.63]
                },
                quaterly: {
                    price: 400.4,
                    basis: [0.88, 0.91]
                }
            }
        }
    },
    btcQuoted: {
        'ETH/BTC': {
            timestamp: '2019-06-04 13:05:36',
            okex: {
                spot: 0.03116
            },
            bitmex: {
                currentQuater: {
                    price: 0.03154,
                    basis: [1.21, 1.25]
                }
            }
        },
        'BCH/BTC': {
            timestamp: '2019-06-04 20:12:32',
            okex: {
                spot: 0.04964
            },
            bitmex: {
                currentQuater: {
                    price: 0.05001,
                    basis: [0.72, 0.74]
                }
            }
        }
    }
}

const mockedOkexHuobiFutureSpread = {
    name: 'OKEX vs Huobi',
    timestamp: '2019-06-05 13:42:20',
    data: {
        weekly: {
            BTC: {
                percent: [0.017, 0.045],
                value: [1.33, 3.51]
            },
            ETH: {
                percent: [0.0119, 0.045],
                value: [1.33, 3.51]
            },
            EOS: {
                percent: [0.0159, 0.0794],
                value: [0.001, 0.005]
            },
            LTC: {
                percent: [-0.1869, -0.0594],
                value: [-0.192, -0.061]
            },
            XRP: {
                percent: [-0.0499, 0.0499],
                value: [-0.0002, 0.0002]
            },
            BCH: {
                percent: [-0.0134, 0.01246],
                value: [-0.052, 0.483]
            },
            TRX: {
                percent: [-0.2581, 0.2016],
                value: [-0.00009, 0.00007]
            }
        },
        biWeekly: {
            BTC: {
                percent: [0.0393, 0.1626],
                value: [3.07, 12.71]
            },
            ETH: {
                percent: [-0.0295, 0.0513],
                value: [-0.072, 0.125]
            },
            EOS: {
                percent: [-0.0791, 0.0792],
                value: [-0.005, 0.005]
            },
            LTC: {
                percent: [-0.2069, 0.1080],
                value: [-0.213, 0.111]
            },
            XRP: {
                percent: [-0.0747, 0.0498],
                value: [-0.0003, 0.0002]
            },
            BCH: {
                percent: [-0.1913, 0.0821],
                value: [-0.744, 0.319]
            },
            TRX: {
                percent: [-0.6560, -0.0573],
                value: [-0.00023, -0.00002]
            }
        },
        quaterly: {
            BTC: {
                percent: [0.2170, 0.2234],
                value: [16.99, 17.49]
            },
            ETH: {
                percent: [0.0892, 0.0945],
                value: [0.219, 0.232]
            },
            EOS: {
                percent: [0.1101, 0.1415],
                value: [0.007, 0.009]
            },
            LTC: {
                percent: [0.1084, 0.2178],
                value: [0.112, 0.225]
            },
            XRP: {
                percent: [0.0, 0.0744],
                value: [0.0, 0.0003]
            },
            BCH: {
                percent: [-0.0433, 0.0634],
                value: [-0.169, 0.247]
            },
            TRX: {
                percent: [-0.1137, 0],
                value: [-0.00004, 0.0]
            }
        }
    }
}

const mockedOkexSwap = {
    name: 'OKEX Swap',
    timestamp: '2019-06-05 22:23:11',
    data: {
        BSV: {
            lastPrice: 210.77,
            basis: -0.31,
            fundingRate: -0.036
        },
        BCH: {
            lastPrice: 392.01,
            basis: -0.24,
            fundingRate: 0.034
        },
        TRX: {
            lastPrice: 0.0339,
            basis: -0.21,
            fundingRate: 0.045
        },
        ETC: {
            lastPrice: 7.973,
            basis: -0.1,
            fundingRate: 0.032
        },
        EOS: {
            lastPrice: 6.252,
            basis: -0.1,
            fundingRate: 0.026
        },
        XRP: {
            lastPrice: 0.3941,
            basis: -0.08,
            fundingRate: 0.057
        },
        LTC: {
            lastPrice: 102.14,
            basis: 0.06,
            fundingRate: 0.031
        },
        BTC: {
            lastPrice: 7742,
            basis: -0.04,
            fundingRate: -0.027
        },
        ETH: {
            lastPrice: 7742,
            basis: -0.04,
            fundingRate: -0.027
        }
    }
}

const initialState = {
    basis: mockedBasis || {},
    futureSpread: {
        okexhuobi: mockedOkexHuobiFutureSpread
    },
    swaps: {
        okex: mockedOkexSwap
    }
}

export function marketReducer (state=initialState, aciton) {
    switch(aciton.type) {
        default:
            return state
    }
}