import thunk from 'redux-thunk'
import promise from 'redux-promise'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'

import { authReducer } from './components/auth/authReducer'
import { accountReducer } from './components/account/accountReducer'
import { profileReducer } from './components/profile/profileReducer'
import { tradingReducer } from './components/trading/tradingReducer'
import { marketReducer } from './components/market/marketReducer'
import { symbolReducer } from './components/symbol/symbolReducer'
import { exchangeReducer } from './components/exchange/exchangeReducer'
import { webSocketReducer } from './components/webSocket/webSocketReducer'
import { timerReducer } from './components/timer/timerReducer'
import { settingReducer } from './components/setting/settingReducer'
import { layoutReducer } from './components/layout/layoutReducer'
import { loanReducer } from './components/loan/loanReducer'

import { getProfileStoppedUpdatingSymbolPricings } from './components/webSocket/ProfileSymbolPricingMonitor'
import { updateProfileStoppedUpdatingSymbolPricings } from './components/profile/profileAction'
import { SLICE_NAME as BLOCKCHAIN_SLICE_NAME, blockchainReducer } from './components/blockchain/blockchainReducer'

const middlewares = [thunk, promise]
if (process.env.REACT_APP_REDUX_LOGGER_ENABLED === 'TRUE') {
   middlewares.push(createLogger())
}

export const store = createStore(combineReducers({
    account: accountReducer,
    auth: authReducer,
    [BLOCKCHAIN_SLICE_NAME]: blockchainReducer,
    exchange: exchangeReducer,
    market: marketReducer,
    layout: layoutReducer,
    loan: loanReducer,
    profile: profileReducer,
    setting: settingReducer,
    symbol: symbolReducer,
    timer: timerReducer,
    trading: tradingReducer,
    webSocket: webSocketReducer
}), applyMiddleware(...middlewares))

setInterval(() => {
    const stoppedUpdatingSymbolPricings = getProfileStoppedUpdatingSymbolPricings()
    store.dispatch(updateProfileStoppedUpdatingSymbolPricings(stoppedUpdatingSymbolPricings))
}, 10000)