import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import dotProp from 'dot-prop-immutable'
import _ from 'lodash'

import Popup from '../common/popup/Popup'
import SearchSelect from '../common/searchSelect/SearchSelect'
import { getOptionSymbolUnderlying, getSymbolAttributeByName } from '../../util/symbolUtil'
import OptionImpliedVolatilityMatrix from './OptionImpliedVolatilityMatrix'
import OptionImpliedVolatilitySurface from './OptionImpliedVolatilitySurface'
import Checkbox from '../common/checkbox/Checkbox'
import moment from 'moment'

const cachedWorkspaceComponentStates = {}

const getAvailableCoinsByOptions = (options) => {
    const coins = {}
    _.forEach(options, option => {
        const underlying = getOptionSymbolUnderlying(option.symbol)
        coins[underlying] = underlying
    })
    return Object.keys(coins)
}

class OptionImpliedVolatilityContainer extends Component {
    constructor (props) {
        super(props)
        this.state = _.get(cachedWorkspaceComponentStates, props.workspaceComponentId, {
            coin: null,
            withoutExpiryDates: [],
            strikePriceRange: [0, ''],
            volatilityRange: [0, 10],
            pastHours: 72
        })
    }

    static getDerivedStateFromProps (props, state) {
        let newState = null
        if (_.isNil(state.coin)) {
            const availableCoins = getAvailableCoinsByOptions(props.optionSymbolAdditionalInfo)
            if (!_.isEmpty(availableCoins)) {
                newState = {
                    coin: _.head(availableCoins)
                }
            } 
        }
        return newState
    }

    componentWillUnmount () {
        const { workspaceComponentId } = this.props
        if (!_.isEmpty(workspaceComponentId)) {
            cachedWorkspaceComponentStates[workspaceComponentId] = _.cloneDeep(this.state)
        }
    }

    Header () {
        const { coin, withoutExpiryDates, strikePriceRange, volatilityRange, pastHours } = this.state
        const { optionSymbolAdditionalInfo } = this.props
        const now = moment()
        const availableCoins = getAvailableCoinsByOptions(optionSymbolAdditionalInfo)
        const allExpiryDates = _.filter(
            _.keys(_.groupBy(optionSymbolAdditionalInfo, option => getSymbolAttributeByName(option.symbol).optionExpiryDate)),
            expiryDate => moment(expiryDate).endOf('day').isAfter(now)
        )
        return (
            <div className='option-implied-volatility-container--header'>
                <div className='option-implied-volatility-container--header--coin-select'>
                    <span>{'COIN'}</span>
                    <SearchSelect 
                        hideSearchBar
                        value={coin} 
                        options={availableCoins.map(coin => { 
                            return { 
                                value: coin, 
                                name: coin 
                            }
                        })}
                        onChange={(newOption) => { 
                            this.setState({ 
                                coin: newOption.value,
                                strikePriceRange: [0, ''],
                                volatilityRange: [0, 10]
                            }) 
                        }} />
                </div>
                <div className='option-implied-volatility-container--header--filter'>
                    <label>{'Expiration'}</label>
                    <Popup className='option-implied-volatility-container--expiration-popup' 
                        on={'click'}
                        trigger={<button className='option-implied-volatility-container--expiration-popup--trigger'>{_.isEmpty(withoutExpiryDates) ? 'All' : `${_.size(allExpiryDates) - _.size(withoutExpiryDates)}/${_.size(allExpiryDates)} Selected`}</button>}>
                        {_.map(allExpiryDates.sort(), expiryDate => {
                            const isSelected = !withoutExpiryDates.includes(expiryDate)
                            return (
                                <div className='option-implied-volatility-container--expiration-popup--item' key={expiryDate}
                                    onClick={(e) => { e.stopPropagation() }}>
                                    <label>{expiryDate}</label>
                                    <Checkbox 
                                        checked={isSelected}
                                        onChange={(newChecked) => {
                                            this.setState({
                                                withoutExpiryDates: newChecked ? _.without(withoutExpiryDates, expiryDate) : _.union(withoutExpiryDates, [expiryDate])
                                            })
                                        }} />
                                </div>
                            )
                        })}
                    </Popup>
                </div>
                <div className='option-implied-volatility-container--header--filter'>
                    <label>{'Strike Price Range'}</label>
                    <input
                        type={'number'} 
                        placeholder={'Min'}
                        value={strikePriceRange[0]}
                        min={0}
                        onChange={(e) => { this.setState({ strikePriceRange: dotProp.set(strikePriceRange, '0', e.target.value) }) }} />
                    <span>{'-'}</span>
                    <input
                        type={'number'} 
                        placeholder={'Max'}
                        value={strikePriceRange[1]}
                        min={0}
                        onChange={(e) => { this.setState({ strikePriceRange: dotProp.set(strikePriceRange, '1', e.target.value) }) }} />
                </div>
                <div className='option-implied-volatility-container--header--filter'>
                    <label>{'Volatility Range'}</label>
                    <input
                        type={'number'} 
                        placeholder={'Min'}
                        value={volatilityRange[0]}
                        min={0}
                        onChange={(e) => { this.setState({ volatilityRange: dotProp.set(volatilityRange, '0', e.target.value) }) }} />
                    <span>{'-'}</span>
                    <input
                        type={'number'} 
                        placeholder={'Max'}
                        value={volatilityRange[1]}
                        min={0}
                        onChange={(e) => { this.setState({ volatilityRange: dotProp.set(volatilityRange, '1', e.target.value) }) }} />
                </div>
                <div className='option-implied-volatility-container--header--filter'>
                    <label>{'Past Hours'}</label>
                    <input
                        type={'number'} 
                        placeholder={'72'}
                        value={pastHours}
                        min={0}
                        onChange={(e) => { this.setState({ pastHours: e.target.value }) }} />
                </div>
            </div>
        )
    }

    render () {
        const { coin, withoutExpiryDates, strikePriceRange, volatilityRange, pastHours } = this.state
        return (
            <div className='option-implied-volatility-container'>
                {this.Header()}
                <div className='option-implied-volatility-container--body'>
                    <div className='option-implied-volatility-container--body--left'>
                        <OptionImpliedVolatilitySurface 
                            coin={coin}
                            withoutExpiryDates={withoutExpiryDates}
                            strikePriceRange={strikePriceRange} 
                            volatilityRange={volatilityRange} 
                            pastHours={pastHours} />
                    </div>
                    <div className='option-implied-volatility-container--body--right'>
                        <OptionImpliedVolatilityMatrix 
                            coin={coin} 
                            withoutExpiryDates={withoutExpiryDates}
                            strikePriceRange={strikePriceRange} 
                            volatilityRange={volatilityRange} 
                            pastHours={pastHours} />
                    </div>
                </div>
            </div>
        )
    }
}

OptionImpliedVolatilityContainer.propTypes = {
    optionSymbolAdditionalInfo: PropTypes.object.isRequired,
    workspaceComponentId: PropTypes.string
}

function mapStateToProps (state) {
    return {
        optionSymbolAdditionalInfo: state.symbol.optionSymbolAdditionalInfo
    }
}

export default connect(mapStateToProps)(OptionImpliedVolatilityContainer)