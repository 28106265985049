import PropTypes from 'prop-types'

export const SignalProfileShape = PropTypes.shape({
    includes: PropTypes.array,
    instance: PropTypes.shape({
        aggregateTrade: PropTypes.bool,
        isLive: PropTypes.bool,
        isNewData: PropTypes.bool
    }),
    symbols: PropTypes.array,
    samplers: PropTypes.array,
    pricing_models: PropTypes.array,
    variables: PropTypes.array,
    models: PropTypes.array,
    strategies: PropTypes.array
})