import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { FiX } from 'react-icons/fi'
import SearchSelect from '../common/searchSelect/SearchSelect'
import { getSymbolAttributeByName } from '../../util/symbolUtil'
import SaveButton from '../common/saveButton/SaveButton'

class FundingTagEditor extends Component {
    constructor (props) {
        super(props)
        this.state = {
            name: props.defaultName || '',
            coins: props.defaultCoins || []
        }
    }
    
    render () {
        const { name, coins } = this.state
        const { symbolItems, shouldHaveDeleteButton, isSavingTags, onClickConfirmButton, onClickDeleteButton } = this.props
        const allCoins = _.uniq(_.map(symbolItems, symbolItem => getSymbolAttributeByName(symbolItem.symbol_name).base)).sort()
        const coinOptions = _.without(allCoins, ...coins).map(coin => {
            return {
                value: coin,
                name: coin
            }
        })
        
        return (
            <div className='funding-tag-editor' onClick={(e) => { e.stopPropagation() }}>
                <div className='funding-tag-editor--section'>
                    <label>{'NAME'}</label>
                    <input className='funding-tag-editor--name-input' 
                        value={name}
                        autoFocus
                        spellCheck={false}
                        onChange={(e) => { this.setState({ name: e.target.value }) }} />
                </div>
                <div className='funding-tag-editor--section'>
                    <label>{'COINS'}</label>
                    <div className='funding-tag-editor--coins clearfix'>
                        {coins.map(coin => {
                            return (
                                <div className='funding-tag-editor--coin' key={coin}>
                                    <span>{coin.toUpperCase()}</span>
                                    <button className='funding-tag-editor--coin--remove-button'
                                        onClick={() => {
                                            const newCoins = _.without(coins, coin)
                                            this.setState({ coins: newCoins })
                                        }}><FiX /></button>
                                </div>
                            )
                        })}
                    </div>
                    <div className='funding-tag-editor--coins-editor clearfix'>
                        <SearchSelect 
                            className='funding-tag-editor--add-coin-button'
                            options={coinOptions} 
                            value={null}
                            placeholder={'Add Coin'}
                            onChange={(newOption) => {
                                if (!coins.includes(newOption.value)) {
                                    this.setState({ coins: _.concat(coins, newOption.value) })
                                }
                            }} />
                        <button className='funding-tag-editor--clear-coin-button' onClick={() => { 
                            this.setState({ coins: [] })
                        }}>{'Clear'}</button>
                    </div>
                </div>
                <div className='funding-tag-editor--buttons clearfix'>
                    <SaveButton 
                        className='funding-tag-editor--button confirm'
                        disabled={name.trim().length === 0 || _.size(coins) === 0}
                        isSaving={isSavingTags}
                        text={'CONFIRM'}
                        onClick={() => {
                            onClickConfirmButton({
                                name,
                                coins
                            }) 
                        }} />
                    {shouldHaveDeleteButton && <button className='funding-tag-editor--button delete' 
                        onClick={() => { onClickDeleteButton() }}>{'DELETE'}</button>}
                </div>
            </div>
        )
    }
}

FundingTagEditor.propTypes = {
    symbolItems: PropTypes.object.isRequired,

    defaultName: PropTypes.string,
    defaultCoins: PropTypes.array,
    isSavingTags: PropTypes.bool,
    shouldHaveDeleteButton: PropTypes.bool,
    onClickConfirmButton: PropTypes.func.isRequired,
    onClickDeleteButton: PropTypes.func.isRequired
}

FundingTagEditor.defaultProps = {
    defaultName: '',
    defaultCoins: [],
    isSavingTags: false,
    shouldHaveDeleteButton: true,
    onClickConfirmButton: () => {},
    onClickDeleteButton: () => {}
}

function mapStateToProps (state) {
    return {
        symbolItems: state.symbol.items
    }
}

export default connect(mapStateToProps)(FundingTagEditor)