import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { arrayMoveImmutable } from 'array-move'
import dotProp from 'dot-prop-immutable'
import _ from 'lodash'

import { FaPlus, FaTimes } from 'react-icons/fa'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import OptionStrategyItem from './OptionStrategyItem'
import ManualOrderContainer from '../trading/ManualOrderContainer'

import { OptionStrategy } from './optionStrategyStructs'
import { webSocketSendData } from '../webSocket/webSocketAction'
import { isMetSearchStringCriteria } from '../../util/util'

const getStorageStrategies = () => {
    let strategies = []
    try {
        strategies = JSON.parse(localStorage.optionStrategyContainerStrategies) || []
    } catch (error) {
        console.error('OptionStrategyContainer parse storage strategies error: ', error)
    }
    return _.isArray(strategies) ? strategies : []
}

const updateStorageStrategies = (strategies=[]) => {
    localStorage.optionStrategyContainerStrategies = JSON.stringify(strategies)
}

const SortableOptionStrategyItem = SortableElement(({ strategy, searchString='', onClickItem=()=>{}, onClickClose=()=>{} }) => {
    const shouldRender = _.isEmpty(searchString) 
        || (_.has(strategy, 'optionLegs') && isMetSearchStringCriteria(`${_.map(strategy.optionLegs, optionLeg => optionLeg.symbolName).join(' ')}`, searchString))
    return (
        <div className={'option-strategy-container--item' + (!shouldRender ? ' hidden' : '')} onClick={() => { onClickItem() }}>
            <button className='option-strategy-container--item--close-button' onClick={() => { onClickClose() }}><FaTimes /></button>
            <OptionStrategyItem 
                strategy={strategy}
                shouldRenderAsCard
                onClickClose={() => { onClickClose() }} />
        </div>
    )
})

const SortableOptionStrategyList = SortableContainer(({ strategies=[], searchString='', onClickItem=() => {}, onClickClose=()=>{} }) => {
    return (
        <div className='option-strategy-container--items'>
            {strategies.map((strategy, index) => {
                return (
                    <SortableOptionStrategyItem
                        key={index}
                        index={index}
                        strategy={strategy} 
                        searchString={searchString}
                        onClickItem={() => { onClickItem(index) }}
                        onClickClose={() => { onClickClose(index) }} />
                )
            })}
        </div>
    )
})
class OptionStrategyContainer extends Component {
    constructor (props) {
        super(props)
        this.state = {
            strategies: getStorageStrategies(),
            selectedStrategyIndex: null,
            searchString: ''
        }

        this.rightContainerNode = null
        this.itemDetailNode = null
        this.draggableLineNode = null
        this.tradeDetailNode = null
    }

    componentDidMount () {
        const { webSocket } = this.props
        if (_.get(webSocket, `${process.env.REACT_APP_MANUAL_ORDER_PROFILE_HOSTNAME}.readyState`) === WebSocket.OPEN) {
            this._subscribeAllSymbols()
        }
    }

    componentDidUpdate (prevProps, prevState) {
        const { webSocket: prevWebSocket } = prevProps
        const { webSocket } = this.props
        const { strategies: prevStrategies } = prevState
        const { strategies } = this.state
        const prevWebSocketReadyState = _.get(prevWebSocket, `${process.env.REACT_APP_MANUAL_ORDER_PROFILE_HOSTNAME}.readyState`)
        const websocketReadyState = _.get(webSocket, `${process.env.REACT_APP_MANUAL_ORDER_PROFILE_HOSTNAME}.readyState`)
        const prevAllSymbolNames = _.uniq(_.flatMap(prevStrategies, strategy => {
            return _.compact(_.map(strategy.optionLegs, optionLeg => optionLeg.symbolName))
        }))
        const allSymbolNames = _.uniq(_.flatMap(strategies, strategy => {
            return _.compact(_.map(strategy.optionLegs, optionLeg => optionLeg.symbolName))
        }))
        const symbolNamesToSubscribeOrderBook = _.difference(allSymbolNames, prevAllSymbolNames)
        const symbolNamesToUnsubscribeOrderBook = _.difference(prevAllSymbolNames, allSymbolNames)

        if (!_.isEqual(prevWebSocketReadyState, websocketReadyState) && websocketReadyState === WebSocket.OPEN) {
            this._subscribeAllSymbols()
        }
        if (!_.isEmpty(symbolNamesToSubscribeOrderBook)) {
            webSocketSendData(process.env.REACT_APP_MANUAL_ORDER_PROFILE_HOSTNAME, {
                type: 'SUBSCRIBE_ORDER_BOOK',
                symbols: symbolNamesToSubscribeOrderBook
            })
        }
        if (!_.isEmpty(symbolNamesToUnsubscribeOrderBook)) {
            webSocketSendData(process.env.REACT_APP_MANUAL_ORDER_PROFILE_HOSTNAME, {
                type: 'UNSUBSCRIBE_ORDER_BOOK',
                symbols: symbolNamesToUnsubscribeOrderBook
            })
        }
    }

    componentWillUnmount () {
        this._unsubscribeAllSymbols()
    }

    _subscribeAllSymbols () {
        const { strategies } = this.state
        const allSymbolNames = _.uniq(_.flatMap(strategies, strategy => {
            return _.compact(_.map(strategy.optionLegs, optionLeg => optionLeg.symbolName))
        }))
        if (!_.isEmpty(allSymbolNames)) {
            webSocketSendData(process.env.REACT_APP_MANUAL_ORDER_PROFILE_HOSTNAME, {
                type: 'SUBSCRIBE_ORDER_BOOK',
                symbols: allSymbolNames
            })
        }
    }

    _unsubscribeAllSymbols () {
        const { strategies } = this.state
        const allSymbolNames = _.uniq(_.flatMap(strategies, strategy => {
            return _.compact(_.map(strategy.optionLegs, optionLeg => optionLeg.symbolName))
        }))
        if (!_.isEmpty(allSymbolNames)) {
            webSocketSendData(process.env.REACT_APP_MANUAL_ORDER_PROFILE_HOSTNAME, {
                type: 'UNSUBSCRIBE_ORDER_BOOK',
                symbols: allSymbolNames
            })
        }
    }

    handleDragLine (e) {
        let newSplitTop = ((e.clientY - this.rightContainerNode.offsetTop) / (this.rightContainerNode.clientHeight)) * 100
        if (newSplitTop && newSplitTop > 0 && this.itemDetailNode && this.tradeDetailNode && this.draggableLineNode) {
            newSplitTop = _.clamp(newSplitTop, 20, 90) + '%'
            this.itemDetailNode.style.height = newSplitTop
            this.tradeDetailNode.style.top = newSplitTop
            this.draggableLineNode.style.top = newSplitTop
        }
    }

    List () {
        const { strategies, selectedStrategyIndex, searchString } = this.state
        return (
            <div className='option-strategy-container--list'>
                <div className='option-strategy-container--list--header'>
                    <input className='option-strategy-container--list--search-input' 
                        spellCheck={false}
                        placeholder={'Search Symbol'} 
                        value={searchString}
                        onChange={(e) => { this.setState({ searchString: e.target.value }) }} />
                    <button 
                        className='option-strategy-container--add-strategy-button'
                        onClick={() => {
                            const newStrategies = _.concat(OptionStrategy({}), strategies)
                            updateStorageStrategies(newStrategies)
                            this.setState({ 
                                strategies: getStorageStrategies(),
                                selectedStrategyIndex: 0
                            })
                        }}>
                        <FaPlus />
                        <span>{'Add Combination'}</span>
                    </button>
                </div>
                <SortableOptionStrategyList 
                    pressDelay={200}
                    strategies={strategies}
                    searchString={searchString}
                    onClickItem={(index) => { this.setState({ selectedStrategyIndex: index }) }}
                    onClickClose={(index) => {
                        const newStrategies = dotProp.delete(strategies, index)
                        updateStorageStrategies(newStrategies)
                        this.setState({ 
                            strategies: getStorageStrategies(),
                            selectedStrategyIndex: selectedStrategyIndex === index ? null : selectedStrategyIndex
                        })
                    }}
                    onSortEnd={({oldIndex, newIndex}) => {
                        const newStrategies = arrayMoveImmutable(strategies, oldIndex, newIndex)
                        updateStorageStrategies(newStrategies)
                        this.setState({ 
                            strategies: getStorageStrategies(),
                            selectedStrategyIndex: _.isNil(selectedStrategyIndex) ? null
                                : oldIndex === selectedStrategyIndex ? newIndex
                                : oldIndex < selectedStrategyIndex && newIndex >= selectedStrategyIndex ? selectedStrategyIndex - 1
                                : oldIndex > selectedStrategyIndex && newIndex <= selectedStrategyIndex ? selectedStrategyIndex + 1
                                : selectedStrategyIndex
                        })
                    }} />
            </div>
        )

    }

    render () {
        const { strategies, selectedStrategyIndex } = this.state
        const selectedStrategy = strategies[selectedStrategyIndex]
        return (
            <div className='option-strategy-container'>
                <div className='option-strategy-container--left'>
                    {this.List()}
                </div>
                <div className='option-strategy-container--right' ref={(node) => { this.rightContainerNode = node }}>
                    <div className='option-strategy-container--item-detail' ref={(node) => { this.itemDetailNode = node }}>
                        {selectedStrategy && 
                        <OptionStrategyItem 
                            strategy={selectedStrategy} 
                            onChange={(newStrategy) => {
                                const newStrategies = dotProp.set(strategies, selectedStrategyIndex, newStrategy)
                                updateStorageStrategies(newStrategies)
                                this.setState({ strategies: getStorageStrategies() })
                            }} 
                            onClickClose={() => {
                                this.setState({ selectedStrategyIndex: null })
                            }}/>}
                    </div>
                    <div className='option-strategy-container--draggable-line' 
                        ref={(node) => { this.draggableLineNode = node }}
                        draggable
                        onDragOver={(e) => { e.preventDefault() }}
                        onDrag={(e) => { this.handleDragLine(e) }} />
                    <div className='option-strategy-container--trade-detail' ref={(node) => { this.tradeDetailNode = node }}>
                        <ManualOrderContainer 
                            shouldUseTabs 
                            filterOptionPosition={'top'} />
                    </div>
                </div>
            </div>
        )
    }
}

OptionStrategyContainer.propTypes = {
    webSocket: PropTypes.object.isRequired
}

function mapStateToProps (state) {
    return {
        webSocket: state.webSocket
    }
}

export default connect(mapStateToProps)(OptionStrategyContainer)