import React, { Component } from 'react'
import { connect } from 'react-redux'
// import PropTypes from 'prop-types'

import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer'
import ProfileContainer from '../components/profile/ProfileContainer'
import TradingContainer from '../components/trading/TradingContainer'
import { setPageTitleAndDescription } from '../util/util'

// import { fetchProfilesAndConnectWSS } from '../components/profile/profileAction'

class ProfilePage extends Component {
    constructor (props) {
        super(props)
        this.polling = null
    }

    componentDidMount () {
        setPageTitleAndDescription({
            title: 'Profiles - Antelope Technology'
        })
    }

    // componentDidUpdate (prevProps) {
    //     const { auth: prevAuth } = prevProps
    //     const { dispatch, auth } = this.props
    //     if (!prevAuth.isLoggedIn && auth.isLoggedIn) {
    //         dispatch(fetchProfilesAndConnectWSS())
    //     }
    // }

    render () {
        return (
            <div className='profile-page page'>
                <Header />
                <div className='profile-page--body page--body'>
                    <div className='profile-page--profiles'>
                        <ProfileContainer />
                    </div>
                    <div className='profile-page--messages'>
                        <TradingContainer />
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

ProfilePage.propTypes = {
    // dispatch: PropTypes.func.isRequired,
    // auth: PropTypes.object.isRequired
}

function mapStateToProps (state) {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(ProfilePage)