import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import dotProp from 'dot-prop-immutable'
import BigNumber from 'bignumber.js'
import _ from 'lodash'

import { FaPlus, FaTimes } from 'react-icons/fa'
import { BiReset } from 'react-icons/bi'

import Popup from '../common/popup/Popup'
import OrderBook from '../symbol/OrderBook'
import PureOrderBook from '../symbol/PureOrderBook'
import SwapAccountBalanceItem from '../account/SwapAccountBalanceItem'
import SearchSelect from '../common/searchSelect/SearchSelect'
import OptionSymbolGreeks from './OptionSymbolGreeks'
import PositionItem from '../trading/PositionItem'
import OrderEditor from '../trading/OrderEditor'
import Toggle from '../common/toggle/Toggle'

import { getOptionSymbolUnderlying, getPricePrecisionBySymbolItem, getSymbolAttributeByName, INSTRUMENT_TYPES } from '../../util/symbolUtil'
import { OptionStrategyShape, OptionStrategy, OptionLeg } from './optionStrategyStructs'
import { toNumberInputValue } from '../../util/util'
import { getNotional } from '../../util/tradingUtil'

const DIRECTIONS = {
    LONG: 'LONG',
    SHORT: 'SHORT'
}

const OptionOrder = ({ side='BUY', symbolName='', accountName='', price=0, size=0, totalAmount='', avgPrice='' }) => {
    return  {
        side,
        symbolName,
        accountName,
        price,
        size,
        totalAmount,
        avgPrice
    }
}

class OptionStrategyItem extends Component {

    constructor (props) {
        super(props)
        this.state = {
            shouldEnableTrade: false,
            shouldShowOptionLegBalance: true,
            shouldShowOptionLegPosition: true,
            shouldShowOptionLegOrderBook: true,
            shouldShowBulkHitOrdersPopup: false,
            bulkHitOrdersDirection: DIRECTIONS.LONG,
            orderMultiplier: 1,
            positionsByKey: {}
        }
    }

    _getUnderlying () {
        const { strategy } = this.props
        const optionLegWithSymbol = _.find(strategy.optionLegs, optionLeg => !_.isEmpty(optionLeg.symbolName))
        const optionUnderlying = !_.isNil(optionLegWithSymbol) ? getOptionSymbolUnderlying(optionLegWithSymbol.symbolName) : null
        return optionUnderlying
    }

    _getStrategyNameTokens (optionLegs=[OptionLeg({})]) {
        return _.map(_.filter(optionLegs, optionLeg => !_.isEmpty(optionLeg.symbolName) && Number(optionLeg.quantity) !== 0), optionLeg => {
            return `${Number(optionLeg.quantity) > 0 ? '+' : ''}${optionLeg.quantity.toString()} ${optionLeg.symbolName}`
        })
    }

    _everySymbolHasEqualTradingParams (symbolNames=[]) {
        const { symbolItems } = this.props
        let result = false
        if (!_.isEmpty(symbolNames)) {
            const headSymbolItem = symbolItems[symbolNames[0]] || {}
            const { multiplier, trading_in_notional: tradingInNotional } = headSymbolItem
            result = _.every(symbolNames, symbolName => {
                const symbolItem = symbolItems[symbolName] || {}
                return _.get(symbolItem, 'multiplier') === multiplier && _.get(symbolItem, 'trading_in_notional') === tradingInNotional
            })
        }
        return result
    }

    _getAggregatedOrderBook () {
        const { strategy, orderBook, symbolItems } = this.props
        const filteredOptionLegs = _.filter(strategy.optionLegs, optionLeg => {
            return !_.isEmpty(optionLeg.symbolName) && Number(optionLeg.quantity) !== 0
        })
        const filteredSymbolNames = _.map(filteredOptionLegs, optionLeg => optionLeg.symbolName)
        const pricePrecision = Math.max(..._.map(filteredSymbolNames, symbolName => getPricePrecisionBySymbolItem(symbolItems[symbolName])))
        const optionLegOrderBookByIndex = _.map(filteredOptionLegs, optionLeg => {
            return _.has(orderBook, `${optionLeg.symbolName}`) && !_.isEmpty(orderBook[optionLeg.symbolName])
                ? _.cloneDeep(orderBook[optionLeg.symbolName])  
                : {
                    bids: [],
                    asks: []
                }
        }) 
        const aggregatedBooks = {
            bids: [],
            asks: []
        }

        const _getLegSymbolOrderBookSide = (legQuantity=1, aggregatingSide='BID') => {
            return Number(legQuantity) === 0 ? null
                : Number(legQuantity) > 0 ? (aggregatingSide === 'BID' ? 'bids' : 'asks')
                : (aggregatingSide === 'BID' ? 'asks' : 'bids')
        }
        
        const _shouldAggregateOnSide = (aggregatingSide='BID') => {
            return !_.isEmpty(filteredOptionLegs) && _.every(filteredOptionLegs, (optionLeg, index) => {
                const legSymbolOrderBookSide = _getLegSymbolOrderBookSide(optionLeg.quantity, aggregatingSide)
                return BigNumber(_.get(optionLegOrderBookByIndex, `${index}.${legSymbolOrderBookSide}.0.qty`, 0)).gt(0)
            })
        }

        const _aggregateOnSide = (aggregatingSide='BID') => {
            const tradeSize = Math.min(..._.map(filteredOptionLegs, (optionLeg, index) => {
                const { quantity } = optionLeg
                const legSymbolOrderBookSide = _getLegSymbolOrderBookSide(quantity, aggregatingSide)
                const bestLevelQty = _.get(optionLegOrderBookByIndex, `${index}.${legSymbolOrderBookSide}.0.qty`, 0)
                return BigNumber(bestLevelQty).div(Math.abs(quantity)).toNumber()
            }))
            if (tradeSize <= 0) {
                return false
            } else {
                let aggregatedPrice = 0
                _.forEach(filteredOptionLegs, (optionLeg, index) => {
                    const { quantity } = optionLeg
                    const legSymbolOrderBookSide = _getLegSymbolOrderBookSide(quantity, aggregatingSide)
                    aggregatedPrice += quantity * _.get(optionLegOrderBookByIndex, `${index}.${legSymbolOrderBookSide}.0.price`, 0)
                    optionLegOrderBookByIndex[index][legSymbolOrderBookSide][0].qty -= (tradeSize * Math.abs(quantity))
                    if (BigNumber(optionLegOrderBookByIndex[index][legSymbolOrderBookSide][0].qty).lte(0.0001)) {
                        optionLegOrderBookByIndex[index][legSymbolOrderBookSide].shift()
                    }
                })
                aggregatedBooks[aggregatingSide === 'BID' ? 'bids' : 'asks'].push({
                    price: BigNumber(aggregatedPrice).toFixed(pricePrecision),
                    qty: tradeSize
                })
                return true
            }
        }

        if (!_.isEmpty(filteredSymbolNames) && _.every(filteredSymbolNames, symbolName => _.has(orderBook, symbolName))) {
            _.forEach(['BID', 'ASK'], aggregatingSide => {
                while (_shouldAggregateOnSide(aggregatingSide)) {
                    const success = _aggregateOnSide(aggregatingSide)
                    if (!success) {
                        break
                    }
                }
            })
        }
        return aggregatedBooks
    }

    _prepareOrders (direction=DIRECTIONS.LONG) {
        const { strategy, orderBook, symbolItems, BTCUSDIndexLastPrice } = this.props
        const { orderMultiplier } = this.state
        const result = {
            isValid: true,
            message: '',
            items: []
        }
        const filteredOptionLegs = _.filter(strategy.optionLegs, optionLeg => {
            const { symbolName, quantity } = optionLeg
            return !_.isEmpty(symbolName) && Number(quantity) !== 0
        })
        const filteredSymbolNames = _.map(filteredOptionLegs, optionLeg => optionLeg.symbolName)

        if (_.isEmpty(filteredOptionLegs)) {
            result.isValid = false,
            result.message = 'Symbols are empty.'
        } else if (BigNumber(orderMultiplier || 0).lte(0)) {
            result.isValid = false,
            result.message = 'Order Multiplier should be greater than zero.'
        } else if (!this._everySymbolHasEqualTradingParams(filteredSymbolNames)) {
            result.isValid = false,
            result.message = 'Bulk Hit does not support symbols containing unequal trading parameters (multiplier & trading_in_notional). Please mannually send orders instead.'
        } else {
            for (const optionLeg of filteredOptionLegs) {
                const { symbolName, accountName, quantity } = optionLeg
                const symbolItem = symbolItems[symbolName]
                const { quote } = getSymbolAttributeByName(symbolName)
                const pricePrecision = getPricePrecisionBySymbolItem(symbolItem)
                const orderBookSide = direction === DIRECTIONS.LONG 
                    ? (Number(quantity) > 0 ? 'asks' : 'bids')
                    : (Number(quantity) > 0 ? 'bids' : 'asks')
                
                const symbolOrderBookLevels = _.get(orderBook, `${symbolName}.${orderBookSide}`, [])
                const seivedOrderBookLevels = _.sortBy(
                    _.filter(symbolOrderBookLevels, orderBookLevel => Number(orderBookLevel.price) > 0 &&  Number(orderBookLevel.qty) > 0),
                    orderBookLevel => (orderBookSide === 'bids' ? -1 : 1) * Number(orderBookLevel.price)
                )
                const orderTotalSize = BigNumber(Math.abs(quantity)).times(orderMultiplier)
                let orderBookLevelIndex = 0, currentTotalSize = 0, currentLimitPrice=null, notionalSum = 0, quantitySumInAsset = 0
                while (orderBookLevelIndex < _.size(seivedOrderBookLevels) && BigNumber(currentTotalSize).lt(orderTotalSize)) {
                    const orderBookLevel = seivedOrderBookLevels[orderBookLevelIndex]
                    const newTotalSize = BigNumber.min(BigNumber(currentTotalSize).plus(orderBookLevel.qty), orderTotalSize)
                    const quantity = BigNumber(newTotalSize).minus(currentTotalSize).toNumber()
                    currentLimitPrice = Number(orderBookLevel.price)
                    const notional = getNotional({
                        symbolItem,
                        quantity: quantity,
                        price: currentLimitPrice,
                        BTCUSDIndexLastPrice
                    })
                    quantitySumInAsset = BigNumber(quantitySumInAsset).plus(
                        BigNumber(notional).div(currentLimitPrice)
                    )
                    notionalSum = BigNumber(notionalSum).plus(notional)
                    currentTotalSize = newTotalSize
                    orderBookLevelIndex++
                }

                if (_.isEmpty(accountName) || _.isNil(accountName)) {
                    result.isValid = false
                    result.message = `${symbolName} has no trading account.`
                    result.items = []
                    break
                } else if (BigNumber(currentTotalSize).lt(orderTotalSize)) {
                    result.isValid = false
                    result.message = `${symbolName} order book's total size (${BigNumber(currentTotalSize).toFormat(2)}) is less than the expected order size (${BigNumber(orderTotalSize).toFormat()}).`
                    result.items = []
                    break
                }
                
                result.items.push(OptionOrder({
                    side: direction === DIRECTIONS.LONG
                        ? (Number(quantity) > 0 ? 'BUY' : 'SELL')
                        : (Number(quantity) > 0 ? 'SELL' : 'BUY'),
                    symbolName,
                    accountName,
                    price: currentLimitPrice,
                    size: orderTotalSize.toNumber(),
                    totalAmount: `${BigNumber(notionalSum).toFormat(pricePrecision)} ${quote}`,
                    avgPrice: BigNumber(notionalSum).div(quantitySumInAsset).toFormat(pricePrecision)
                }))
            }
        }
        return result
    }

    SendOrdersPopup (direction=DIRECTIONS.LONG) {
        const { orderMultiplier } = this.state
        const orders = this._prepareOrders(direction)
        const { isValid, message, items } = orders
        return (
            <Fragment>
                <div className='option-strategy-item--bulk-orders-popup--directions'>
                    {_.map(DIRECTIONS, d => {
                        return (
                            <button className={`option-strategy-item--bulk-orders-popup--direction ${d}` + (direction === d ? ' selected' : '')}
                                key={d}
                                onClick={() => {
                                    this.setState({ bulkHitOrdersDirection: d })
                                }}>{d}</button>
                        )
                    })}
                </div>
                <div className='option-strategy-item--bulk-orders-popup--order-multiplier'>
                    <label>{'Order Multiplier'}</label>
                    <input 
                        type={'number'}
                        placeholder={'1'}
                        min={0}
                        value={orderMultiplier}
                        autoFocus
                        onChange={(e) => { this.setState({ orderMultiplier: toNumberInputValue(e.target.value) }) }} />
                </div>
                 <div className='option-strategy-item--bulk-orders-popup--main'>
                    {!isValid && <div className='option-strategy-item--bulk-orders-popup--message'>{message}</div>}
                    {isValid && !_.isEmpty(items) && <Fragment>
                        <div className='option-strategy-item--bulk-orders-popup--orders'>
                            {_.map(items, (item, index) => {
                                return (
                                    <div className='option-strategy-item--bulk-orders-popup--order-item' key={index}>
                                        <div className='option-strategy-item--bulk-orders-popup--order-item--main'>
                                            <span className='account'>{item.accountName}</span>
                                            <span className={`side ${item.side}`}>{`LIMIT ${item.side}`}</span>
                                            <span className='size'>{item.size}</span>
                                            <span className='symbol'>{item.symbolName}</span>
                                            <span className='at'>{'@'}</span>
                                            <span className='price'>{item.price}</span>
                                        </div>
                                        <div className='option-strategy-item--bulk-orders-popup--order-item--stats' key={index}>
                                            <div>
                                                <label>{'Total Amount'}</label>
                                                <span>{item.totalAmount}</span>
                                            </div>
                                            <div>
                                                <label>{'Avg Price'}</label>
                                                <span>{item.avgPrice}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <button className='option-strategy-item--bulk-orders-popup--submit-button'>{'Submit Orders'}</button>
                    </Fragment>}
                </div>
            </Fragment>
           
        )
    }

    EmptyData () {
        return (
            <div className='option-strategy-item--empty-data'>{'Empty Data'}</div>
        )
    }

    Card () {
        const { strategy } = this.props
        const { optionLegs } = strategy
        const strategyNameTokens = this._getStrategyNameTokens(optionLegs)
        const { bids, asks } = this._getAggregatedOrderBook()

        const bidPrice = !_.isEmpty(bids) ? Math.max(..._.map(bids, bid => Number(bid.price))) : null
        const askPrice = !_.isEmpty(asks) ? Math.min(..._.map(asks, ask => Number(ask.price))) : null

        return (
            <div className='option-strategy-item--card'>
                <div className='option-strategy-item--card--name'>
                    {_.isEmpty(strategyNameTokens) 
                    ? 'New Pairs Trade'
                    : _.map(strategyNameTokens, (token, index) => {
                        return (
                            <div className='option-strategy-item--card--name--token' key={index}>{token}</div>
                        )
                    })}
                </div>
                <div className='option-strategy-item--card--prices'>
                    <div className='option-strategy-item--card--price BID'>
                        <label>{'Bid'}</label>
                        <span>{!_.isNil(bidPrice) ? BigNumber(bidPrice).toFormat() : 'N/A'}</span>
                    </div>
                    <div className='option-strategy-item--card--price ASK'>
                        <label>{'Ask'}</label>
                        <span>{!_.isNil(askPrice) ? BigNumber(askPrice).toFormat() : 'N/A'}</span>
                    </div>
                </div>
            </div>
        )
    }

    Item () {
        const { symbolItems, accountItems, strategy, swapAccountBalances, positions, onChange, onClickClose } = this.props
        const { shouldEnableTrade, shouldShowOptionLegBalance, shouldShowOptionLegPosition, shouldShowOptionLegOrderBook, shouldShowBulkHitOrdersPopup, bulkHitOrdersDirection } = this.state
        const { optionLegs } = strategy
        const defaultStrategyName = this._getStrategyNameTokens(optionLegs).join(', ')
        const optionUnderlying = this._getUnderlying()
        const filteredSymbolItems = _.filter(symbolItems, symbolItem => symbolItem.trading === '1')
        const allSymbolOptions = _.map(filteredSymbolItems, symbolItem => {
            return {
                value: symbolItem.symbol_name,
                name: symbolItem.symbol_name
            }
        })
        // const filteredSymbolOptions = _.filter(allSymbolOptions, symbolOption => {
        //     return _.isNil(optionUnderlying) || getOptionSymbolUnderlying(symbolOption.value) === optionUnderlying
        // })
        const aggregatedOrderBook = this._getAggregatedOrderBook()

        const cachedAccountOptionsByExchangeName = {}
        const _getAccountOptionsByExchangeName = (exchangeName='') => {
            if (!_.isEmpty(exchangeName) && !_.has(cachedAccountOptionsByExchangeName, exchangeName)) {
                const filteredAccountItems = _.filter(accountItems, accountItem => accountItem.exchange_name === exchangeName)
                cachedAccountOptionsByExchangeName[exchangeName] = _.map(filteredAccountItems, accountItem => {
                    return {
                        value: accountItem.account_name,
                        name: accountItem.account_name
                    }
                })
            }
            return cachedAccountOptionsByExchangeName[exchangeName] || []
        }

        return (
            <div className='option-strategy-item'>
                <div className='option-strategy-item--header'>
                    <div className='option-strategy-item--header--left-content'>
                        <div className='option-strategy-item--name'>{defaultStrategyName || 'New Pairs Trade'}</div>
                    </div>
                    <button className='option-strategy-item--remove-button' onClick={() => { onClickClose() }}><FaTimes /></button>
                </div>
                <div className='option-strategy-item--main'>
                    <table className='option-strategy-item--table'>
                        <thead>
                            <tr>
                                <th>
                                    <div className='option-strategy-item--symbol-header'>
                                        <div className='option-strategy-item--symbol-header--left-content'>
                                            <span>{'Symbols'}</span>
                                            {!shouldEnableTrade && <Fragment>
                                                <button className='option-strategy-item--add-symbol-button'
                                                    onClick={() => {
                                                        const newStrategy = dotProp.merge(strategy, 'optionLegs', OptionLeg({}))
                                                        onChange(newStrategy)
                                                    }}><FaPlus /></button>
                                                <button className='option-strategy-item--reset-symbol-button' 
                                                    onClick={() => {
                                                        const newStrategy = OptionStrategy({})
                                                        onChange(newStrategy)
                                                    }}><BiReset /></button>
                                                <Popup className='option-strategy-item--bulk-orders-popup'
                                                    on={'click'} 
                                                    trigger={<button className='option-strategy-item--bulk-orders-popup--trigger'>{'Bulk Hit'}</button>}
                                                    onOpen={() => { 
                                                        this.setState({ shouldShowBulkHitOrdersPopup: true })
                                                    }}
                                                    onClose={() => {
                                                        this.setState({ 
                                                            shouldShowBulkHitOrdersPopup: false,
                                                            bulkHitOrdersDirection: DIRECTIONS.LONG,
                                                            orderMultiplier: 1
                                                        })
                                                    }}>
                                                    {shouldShowBulkHitOrdersPopup && this.SendOrdersPopup(bulkHitOrdersDirection)}
                                                </Popup>
                                            </Fragment>}
                                        </div>
                                        <div className='option-strategy-item--symbol-header--trade-toggle'>
                                            <label>{'Manual Order'}</label>
                                            <Toggle 
                                                checked={shouldEnableTrade}
                                                trueText={'Enabled'}
                                                falseText={'Disabled'}
                                                onChange={(newChecked) => {
                                                    this.setState({ shouldEnableTrade: newChecked })
                                                }} />
                                        </div>
                                        
                                    </div>
                                </th>
                                <th>
                                    <div className='option-strategy-item--order-book-header'>
                                        <span>{'Order Book'}</span>
                                        <button className='option-strategy-item--order-book-header--toggle-button' 
                                            onClick={() => {
                                                this.setState({ shouldShowOptionLegOrderBook: !shouldShowOptionLegOrderBook })
                                            }}>{shouldShowOptionLegOrderBook ? 'Hide' : 'Show'}</button>
                                    </div>
                                </th>
                                <th>{'Aggregated Book'}</th>
                                <th>
                                    <div className='option-strategy-item--balance-header'>
                                        <span>{'Balance'}</span>
                                        <button className='option-strategy-item--balance-header--toggle-button' 
                                            onClick={() => {
                                                this.setState({ shouldShowOptionLegBalance: !shouldShowOptionLegBalance })
                                            }}>{shouldShowOptionLegBalance ? 'Hide' : 'Show'}</button>
                                    </div>
                                </th>
                                <th>
                                    <div className='option-strategy-item--position-header'>
                                        <span>{'Position'}</span>
                                        <button className='option-strategy-item--position-header--toggle-button' 
                                            onClick={() => {
                                                this.setState({ shouldShowOptionLegPosition: !shouldShowOptionLegPosition })
                                            }}>{shouldShowOptionLegPosition ? 'Hide' : 'Show'}</button>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {_.map(optionLegs, (optionLeg, index) => {
                                const { symbolName, accountName } = optionLeg
                                const swapAccountBalance = swapAccountBalances[`${accountName}--${(optionUnderlying || '').toLowerCase()}`] || swapAccountBalances[`${accountName}--${optionUnderlying}`]
                                const positionItem = positions[`${accountName}--${symbolName}`]
                                let accountOptions = []
                                if (!_.isEmpty(symbolName)) {
                                    const { exchangeName } = getSymbolAttributeByName(symbolName)
                                    accountOptions = _getAccountOptionsByExchangeName(exchangeName)
                                }
                                return (
                                    <tr key={index}>
                                        <td>
                                            <div className='option-strategy-item--inputs'>
                                                {shouldEnableTrade 
                                                ? <div className='option-strategy-item--inputs--order-editor'>
                                                    <OrderEditor 
                                                        shouldHideTitle
                                                        config={{
                                                            symbolName: symbolName || null,
                                                            accountName
                                                        }} />
                                                    {!_.isEmpty(symbolName) 
                                                        && getSymbolAttributeByName(symbolName).instrumentType === INSTRUMENT_TYPES.OPTION 
                                                        && <OptionSymbolGreeks optionSymbolName={symbolName} />}
                                                </div>
                                                : <Fragment>
                                                    <button onClick={() => {
                                                        const newOptionStrategy = dotProp.delete(strategy, `optionLegs.${index}`)
                                                        onChange(newOptionStrategy)
                                                    }}><FaTimes /></button>
                                                    <div className='option-strategy-item--inputs--main'>
                                                        <input type={'number'} 
                                                            placeholder={'0'}
                                                            value={optionLeg.quantity}
                                                            onChange={(e) => { 
                                                                const newOptionStrategy = dotProp.set(strategy, `optionLegs.${index}.quantity`, toNumberInputValue(e.target.value))
                                                                onChange(newOptionStrategy)
                                                            }} />
                                                        <SearchSelect 
                                                            placeholder={'Select Symbol'}
                                                            value={symbolName || null}
                                                            options={allSymbolOptions}
                                                            hasClearButton
                                                            onChange={(newOption) => {
                                                                // const newUnderlying = getOptionSymbolUnderlying(newOption.value)
                                                                let newOptionStrategy = dotProp.set(strategy, `optionLegs.${index}.symbolName`, newOption.value)
                                                                // if (!_.isNil(optionUnderlying) && !_.isEqual(optionUnderlying, newUnderlying)) {
                                                                //     for (let i=1; i < _.size(optionLegs); i++) {
                                                                //         newOptionStrategy = dotProp.set(newOptionStrategy, `optionLegs.${i}`, OptionLeg({}))
                                                                //     }
                                                                // }
                                                                onChange(newOptionStrategy)
                                                            }} 
                                                            onClickClearButton={() => {
                                                                const newOptionStrategy = dotProp.set(strategy, `optionLegs.${index}`, OptionLeg({}))
                                                                onChange(newOptionStrategy)
                                                            }} />
                                                        <SearchSelect 
                                                            placeholder={'Select Account'}
                                                            value={accountName || null}
                                                            options={accountOptions}
                                                            onChange={(newOption) => {
                                                                const newOptionStrategy = dotProp.set(strategy, `optionLegs.${index}.accountName`, newOption.value)
                                                                onChange(newOptionStrategy)
                                                            }} />
                                                        {!_.isEmpty(symbolName) 
                                                        && getSymbolAttributeByName(symbolName).instrumentType === INSTRUMENT_TYPES.OPTION 
                                                        && <OptionSymbolGreeks optionSymbolName={symbolName} />}
                                                    </div>
                                                </Fragment>}
                                            </div>
                                        </td>
                                        <td>
                                            {!_.isEmpty(symbolName) && shouldShowOptionLegOrderBook && _.has(symbolItems, symbolName) &&
                                            <OrderBook
                                                shouldHideTitle 
                                                amountLabel={'Size'}
                                                symbolItem={symbolItems[symbolName]} />}
                                        </td>
                                        {index === 0 && <td className='option-strategy-item--aggregated-book-column' rowSpan={_.size(optionLegs)}>
                                            <PureOrderBook 
                                                amountLabel={'Size'}
                                                orderBook={aggregatedOrderBook} />
                                        </td>}
                                        <td>
                                            {shouldShowOptionLegBalance && 
                                            <Fragment>
                                                {swapAccountBalance 
                                                ? <SwapAccountBalanceItem 
                                                    swapAccountBalance={swapAccountBalance}
                                                    shouldShowAccountTypeTag
                                                    shouldShowAccountName
                                                    shouldShowDetail/>
                                                : this.EmptyData()}    
                                            </Fragment>}
                                        </td>
                                        <td>
                                            {shouldShowOptionLegPosition && 
                                            <Fragment>
                                                {positionItem ?
                                                <PositionItem 
                                                    positionItem={positionItem} 
                                                    shouldShowTag />
                                                : this.EmptyData()}
                                            </Fragment>}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    render () {
        const { shouldRenderAsCard } = this.props
        return shouldRenderAsCard ? this.Card() : this.Item()
    }
}

OptionStrategyItem.propTypes = {
    symbolItems: PropTypes.object.isRequired,
    accountItems: PropTypes.object.isRequired,
    orderBook: PropTypes.object.isRequired,
    swapAccountBalances: PropTypes.object.isRequired,
    positions: PropTypes.object.isRequired,

    strategy: OptionStrategyShape.isRequired,
    shouldRenderAsCard: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onClickClose: PropTypes.func.isRequired,
    BTCUSDIndexLastPrice: PropTypes.string
}

OptionStrategyItem.defaultProps = {
    strategy: OptionStrategy({}),
    onChange: () => {},
    onClickClose: () => {}
}

function mapStateToProps (state) {
    return {
        symbolItems: state.symbol.items,
        accountItems: state.account.items,
        orderBook: state.symbol.orderBook,
        swapAccountBalances: state.account.balance.swap,
        positions: _.keyBy(state.trading.positions, position => `${position.account_name}--${position.product_name}`),
        BTCUSDIndexLastPrice: _.get(state, 'symbol.pricings.btc_usdc_BINANCE_SPT.last')
    }
}

export default connect(mapStateToProps)(OptionStrategyItem)