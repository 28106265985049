import React, { Component, Suspense } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import BigNumber from 'bignumber.js'
import dotProp from 'dot-prop-immutable'
import moment from 'moment'
import _ from 'lodash'

const Plot = React.lazy(() => import('react-plotly.js'))

import { INSTRUMENT_TYPES, getOptionSymbolUnderlying, getSymbolAttributeByName } from '../../util/symbolUtil'
import Toggle from '../common/toggle/Toggle'
import { getPortfolioNames } from '../../util/accountUtil'
import SearchSelect from '../common/searchSelect/SearchSelect'

const getFilteredOptions = ({ optionSymbolAdditionalInfo={}, coin, withoutExpiryDates=[], strikePriceRange=[0, Number.POSITIVE_INFINITY] }) => {
    const now = moment()
    return _.filter(optionSymbolAdditionalInfo, info => {
        const underlying = getOptionSymbolUnderlying(info.symbol)
        const { optionExpiryDate, optionStrikePrice } = getSymbolAttributeByName(info.symbol)
        return underlying === coin && moment(optionExpiryDate).endOf('day').isAfter(now) 
            && Number(optionStrikePrice) >= Number(strikePriceRange[0]) && !withoutExpiryDates.includes(optionExpiryDate)
            && (_.isEmpty(strikePriceRange[1]) || Number(optionStrikePrice) <= Number(strikePriceRange[1]))
    })
}

const getExchangeNamesFromOptions = (options) => {
    const exchangeNames = _.reduce(options, (result, iv) => {
        const { exchangeName } = getSymbolAttributeByName(iv.symbol)
        result[exchangeName] = exchangeName     
        return result 
    }, {})
    return Object.keys(exchangeNames)
}

const ALL = 'ALL'
class OptionImpliedVolatilitySurface extends Component {
    constructor (props) {
        super(props)
        const { optionSymbolAdditionalInfo, coin, withoutExpiryDates, strikePriceRange } = props
        const filteredOptions = getFilteredOptions({ optionSymbolAdditionalInfo, coin, withoutExpiryDates, strikePriceRange })
        const exchangeNames = getExchangeNamesFromOptions(filteredOptions)
        const plotDataXYValues = this.getPlotDataXYValues(filteredOptions)

        const plotLayoutAxis = {
            spikecolor: '#ccc',
            gridcolor: '#113651',
            tickcolor: '#ccc',
            tickfont: {
                color: '#eee',
                size: 13
            }
        }

        this.OPTION_TYPES = {
            CALL: 'CALL',
            PUT: 'PUT'
            // QMOV: 'QMOV'
        }
        this.PRICE_SIDES = {
            BID: 'BID',
            MARK: 'MARK',
            ASK: 'ASK'
        }
        this.state = {
            exchangeNames: !_.isEmpty(exchangeNames) ? [exchangeNames[0]] : [],
            optionTypes: ['CALL'],
            priceSides: [this.PRICE_SIDES.BID, this.PRICE_SIDES.ASK],
            shouldShowPositions: false,
            positionSurfaceOffset: 10,
            positionPortfolio: 'allblack',
            plotLayout: {
                width: null,
                height: null,
                scene: {
                    camera: {
                        // eye: {
                        //     x: -1.85,
                        //     y: -1.85,
                        //     z: 1.2
                        // }
                    },
                    xaxis: Object.assign({}, plotLayoutAxis, {
                        title: {
                            text: 'Expiry Date',
                            font: {
                                color: '#ccc',
                                size: 16
                            }
                        },
                        type: 'category',
                        tickmode: 'array',
                        tickvals: plotDataXYValues.x || []
                    }),
                    yaxis: Object.assign({}, plotLayoutAxis, {
                        title: {
                            text: 'Strike Price',
                            font: {
                                color: '#ccc',
                                size: 16
                            }
                        }
                    }),
                    zaxis: Object.assign({}, plotLayoutAxis, {
                        title: {
                            text: 'Implied Volatility',
                            font: {
                                color: '#ccc',
                                size: 16
                            }
                        }
                    })
                },
                font: {
                    family: 'Source Sans Pro'
                },
                paper_bgcolor: 'transparent',
                margin: {
                    l: 0, 
                    r: 0,
                    t: 0,
                    b: 0
                },
                hoverlabel: {
                    bgcolor: '#113651',
                    font: {
                        family: 'Source Sans Pro',
                        size: 16
                    }
                }
            }
        }
        this.surfaceBodyNode = null
        this.surfaceAutoSizeInterval = null
    }

    static getDerivedStateFromProps (props, state) {
        let newState = null
        if (_.isEmpty(state.exchangeNames)) {
            const { optionSymbolAdditionalInfo, coin, withoutExpiryDates, strikePriceRange } = props
            const filteredOptions = getFilteredOptions({ optionSymbolAdditionalInfo, coin, withoutExpiryDates, strikePriceRange })
            const exchangeNames = getExchangeNamesFromOptions(filteredOptions)
            if (!_.isEmpty(exchangeNames)) {
                newState = {
                    exchangeNames: [_.head(exchangeNames)]
                }
            }
        }
        return newState
    }

    componentDidMount () {
        this.updatePlotLayoutSize()
        this.registerSurfaceAutoSizeInterval()
    }

    componentDidUpdate (prevProps) {
        const { coin, optionSymbolAdditionalInfo, withoutExpiryDates, strikePriceRange } = this.props
        if (!_.isEqual(prevProps.coin, coin) 
            || !_.isEqual(_.size(prevProps.optionSymbolAdditionalInfo), _.size(optionSymbolAdditionalInfo))) {
            const filteredOptions = getFilteredOptions({ optionSymbolAdditionalInfo, coin, withoutExpiryDates, strikePriceRange })
            const plotDataXYValues = this.getPlotDataXYValues(filteredOptions)
            const newPlotLayout = dotProp.set(this.state.plotLayout, `scene.xaxis.tickvals`, plotDataXYValues.x || [])
            this.updatePlotLayout(newPlotLayout)
        }
    }

    componentWillUnmount () {
        if (this.surfaceAutoSizeInterval) {
            window.clearInterval(this.surfaceAutoSizeInterval)
        }
    }

    registerSurfaceAutoSizeInterval () {
        if (this.surfaceAutoSizeInterval) {
            window.clearInterval(this.surfaceAutoSizeInterval)
        }
        this.surfaceAutoSizeInterval = setInterval(() => {
            this.updatePlotLayoutSize()
        }, 1500)
    }

    updatePlotLayoutSize () {
        if (this.surfaceBodyNode) {
            const { offsetWidth, offsetHeight } = this.surfaceBodyNode
            const { width, height } = this.state.plotLayout
            if (offsetWidth !== width || offsetHeight !== height) {
                const newPlotLayout = dotProp.set(this.state.plotLayout, 'width', offsetWidth)
                newPlotLayout.height = offsetHeight
                this.updatePlotLayout(newPlotLayout)
            }
        }
    }

    updatePlotLayout (newPlotLayout) {
        this.setState({
            plotLayout: newPlotLayout
        })
    }

    getPlotDataXYValues (options) {
        const xyValues = _.reduce(options, (result, option) => {
            const { optionExpiryDate, optionStrikePrice } = getSymbolAttributeByName(option.symbol)
            result.x[optionExpiryDate] = optionExpiryDate
            result.y[optionStrikePrice] = optionStrikePrice
            return result
        }, { x: {}, y: {} })
        xyValues.x = _.sortBy(Object.keys(xyValues.x), v => moment(v).valueOf())
        xyValues.y = _.sortBy(Object.keys(xyValues.y), v => Number(v))
        
        return xyValues
    }

    getSurfaceData () {
        const { optionSymbolAdditionalInfo, positions, coin, withoutExpiryDates, strikePriceRange, volatilityRange, pastHours, accountItems } = this.props
        const { exchangeNames, optionTypes, priceSides, shouldShowPositions, positionPortfolio, positionSurfaceOffset } = this.state
        const filteredOptions = getFilteredOptions({ optionSymbolAdditionalInfo, coin, withoutExpiryDates, strikePriceRange })
        const plotDataXYValues = this.getPlotDataXYValues(filteredOptions)
        const data = {}
        const defaultZMatrix = []
        const validMoment = moment().add(-pastHours || 0, 'hours')

        for (let i = 0; i < plotDataXYValues.y.length; i++) {
            defaultZMatrix[i] = Array(plotDataXYValues.x.length).fill(NaN)
        }
        
        exchangeNames.forEach(exchangeName => {
            optionTypes.forEach(optionType => {
                priceSides.forEach(priceSide => {
                    data[`${exchangeName}--${optionType}--${priceSide}`] = {
                        type: 'surface',
                        name: `${exchangeName}-${optionType}-${priceSide}`,
                        showscale: false,
                        connectgaps: true,
                        x: plotDataXYValues.x,
                        y: plotDataXYValues.y,
                        z: _.cloneDeep(defaultZMatrix),
                        colorscale: [[0, '#30a5a1'], [0.5, '#46677e'], [1, '#fd818a']],
                        opacity: 0.8,
                        contours: {
                            x: {
                                show: true,
                                color: '#072539',
                                highlightcolor: 'white'
                            },
                            y: {
                                show: true,
                                color: '#072539',
                                highlightcolor: 'white'
                            },
                            z: {
                                show: false,
                                highlight: false
                            }
                        },
                        hovertemplate:  ` ${exchangeName} <span style="color: ${optionType==='CALL' ? '#90eaea' : '#fd818a'}">${optionType}</span>` + 
                                        ` <span style="color: ${priceSide === 'BID' ? '#90eaea' : priceSide === 'ASK' ? '#fd818a' : '#fff'}">${priceSide}</span> <br>` +
                                        ' Expiry: <b>%{x}</b> <br>' + 
                                        ' Strike Price: <b>%{y}</b> <br>' + 
                                        ' Implied Vol: <b>%{z}</b> <br>' +
                                        '<extra></extra>'
                    }
                })
                data[`${exchangeName}--${optionType}--position`] = {
                    type: 'surface',
                    name: `${exchangeName}-${optionType}-position`,
                    showscale: false,
                    connectgaps: true,
                    x: plotDataXYValues.x,
                    y: plotDataXYValues.y,
                    z: _.cloneDeep(defaultZMatrix),
                    text: _.cloneDeep(defaultZMatrix),
                    hoverinfo: 'text',
                    // colorscale: [[0, '#30a5a1'], [0.5, '#46677e'], [1, '#fd818a']],
                    opacity: 0.8,
                    contours: {
                        x: {
                            show: true,
                            color: '#072539',
                            highlightcolor: 'white'
                        },
                        y: {
                            show: true,
                            color: '#072539',
                            highlightcolor: 'white'
                        },
                        z: {
                            show: false,
                            highlight: false
                        }
                    }
                }
            })
        })

        let _maxIV = BigNumber(0)
        let _minIV = BigNumber(0)

        _.forEach(filteredOptions, option => {
            const { bid_iv: bidImpliedVolatility, ask_iv: askImpliedVolatility, mark_iv: markImpliedVolatility } = option
            const { exchangeName, originalType: optionType, optionExpiryDate, optionStrikePrice } = getSymbolAttributeByName(option.symbol)
            priceSides.forEach(priceSide => {
                const expiryDateIndex = _.findIndex(plotDataXYValues.x, v => v === optionExpiryDate)
                const strikePriceIndex = _.findIndex(plotDataXYValues.y, v => v === optionStrikePrice)
                const value = priceSide === this.PRICE_SIDES.BID ? bidImpliedVolatility
                    : priceSide === this.PRICE_SIDES.ASK ? askImpliedVolatility
                    : markImpliedVolatility
                if (expiryDateIndex > -1 && strikePriceIndex > -1 && _.has(data, `${exchangeName}--${optionType}--${priceSide}`) 
                    && value >= Number(volatilityRange[0]) && value <= Number(volatilityRange[1])
                    && moment(option.timestamp).isAfter(validMoment)
                ) {
                    data[`${exchangeName}--${optionType}--${priceSide}`].z[strikePriceIndex][expiryDateIndex] = value
                    _maxIV = BigNumber.max(_maxIV, value)
                    _minIV = BigNumber.min(_minIV, value)
                }
            })
        })  
        
        if (shouldShowPositions) {
            let _maxPositionSize = BigNumber(0)
            let _minPositionSize = BigNumber(0)
            _.forEach(positions, position => {
                const { product_name, long_position, short_position, account_name } = position
                const { instrumentType, exchangeName: optionExchangeName, originalType, optionExpiryDate, optionStrikePrice } = getSymbolAttributeByName(product_name)
                if (instrumentType === INSTRUMENT_TYPES.OPTION) {
                    const _accountPortfolio = _.get(accountItems, `${account_name}.portfolio_name`)
                    const underlying = getOptionSymbolUnderlying(product_name)
                    const expiryDateIndex = _.findIndex(plotDataXYValues.x, v => v === optionExpiryDate)
                    const strikePriceIndex = _.findIndex(plotDataXYValues.y, v => v === optionStrikePrice)
                    const _key = `${optionExchangeName}--${originalType}--position`
                    if (expiryDateIndex > -1 && strikePriceIndex > -1  && _.has(data, _key)
                        && underlying === coin && exchangeNames.includes(optionExchangeName)
                        && (positionPortfolio === ALL || _accountPortfolio === positionPortfolio)) {
                        const _positionSize = BigNumber(long_position || 0).minus(short_position || 0)
                        const _newPositionSize = BigNumber(data[_key].z[strikePriceIndex][expiryDateIndex] || 0).plus(_positionSize)
                        data[_key].z[strikePriceIndex][expiryDateIndex] = _newPositionSize.toNumber()
                        data[_key].text[strikePriceIndex][expiryDateIndex] = ` ${optionExchangeName} Position <br>` +
                                ` <span style="color: ${originalType==='CALL' ? '#90eaea' : '#fd818a'}">${originalType}</span> <br>` + 
                                ` Expiry: <b>${optionExpiryDate}</b> <br>` + 
                                ` Strike Price: <b>${optionStrikePrice}</b> <br>` + 
                                ` Position Size: <b>${_newPositionSize.toNumber()}</b> <br>`
                        _maxPositionSize = BigNumber.max(_maxPositionSize, _newPositionSize)
                        _minPositionSize = BigNumber.min(_minPositionSize, _newPositionSize)
                    }
                }
            })

            const _ivRange = _maxIV.minus(_minIV)
            const _positionSizeRange = _maxPositionSize.minus(_minPositionSize)
            if (_ivRange.gt(0)) {
                const _scale = _positionSizeRange.div(_ivRange)
                _.forEach(data, (d, key) => {
                    if (_.endsWith(key, '--position')) {
                        _.forEach(d?.z, zValues => {
                            _.forEach(zValues, (v, index) => {
                                if (!_.isNaN(v)) {
                                    zValues[index] = BigNumber(v).div(_scale).toNumber() + Number(positionSurfaceOffset)
                                }
                            })
                        })
                    }
                })
            }
        }

        const filteredData = _.filter(data, item => _.some(item.z, zValues => _.some(zValues, v => !_.isNaN(v))))
        return filteredData
    }

    Header () {
        const { optionSymbolAdditionalInfo, coin, withoutExpiryDates, strikePriceRange } = this.props
        const { exchangeNames, optionTypes, priceSides, shouldShowPositions, positionPortfolio, positionSurfaceOffset } = this.state
        const filteredOptions = getFilteredOptions({ optionSymbolAdditionalInfo, coin, withoutExpiryDates, strikePriceRange })
        const availableExchangeNames = getExchangeNamesFromOptions(filteredOptions)
        const portfolioNames = getPortfolioNames()
        const renderSelectors = ({ className, name, buttonValues=[], activeButtonValues=[], onClickButton=()=>{} }) => {
            return (
                <div className={'option-implied-volatility-surface--header--select' + (className ? ` ${className}` : '')}>
                    <span className='option-implied-volatility-surface--header--select--name'>{name}</span>
                    <div className='option-implied-volatility-surface--header--select--buttons'>
                        {_.map(buttonValues, value => {
                            const isActive = activeButtonValues.includes(value)
                            return (
                                <button className={'option-implied-volatility-surface--header--select--button' + (isActive ? ' active' : '')}
                                    // disabled={isActive && activeButtonValues.length === 1}
                                    key={value}
                                    onClick={() => { onClickButton(value) }}>
                                    {value}
                                </button>
                            )
                        })}
                    </div>
                </div>
            )
        }
        return (
            <div className='option-implied-volatility-surface--header'>
                <div className='option-implied-volatility-surface--header--row'>
                    {renderSelectors({
                        className: 'exchange-names',
                        name: 'Exchanges',
                        buttonValues: availableExchangeNames,
                        activeButtonValues: exchangeNames,
                        onClickButton: (exchangeName) => {
                            this.setState({
                                exchangeNames: exchangeNames.includes(exchangeName) ? _.without(exchangeNames, exchangeName) : exchangeNames.concat([exchangeName])
                            })
                        }
                    })}
                    {renderSelectors({
                        className: 'option-types',
                        name: 'Option Types',
                        buttonValues: Object.keys(this.OPTION_TYPES),
                        activeButtonValues: optionTypes,
                        onClickButton: (optionType) => {
                            this.setState({
                                optionTypes: optionTypes.includes(optionType) ? _.without(optionTypes, optionType) : optionTypes.concat([optionType])
                            })
                        }
                    })}
                    {renderSelectors({
                        className: 'price-sides',
                        name: 'Price Side',
                        buttonValues: Object.keys(this.PRICE_SIDES),
                        activeButtonValues: priceSides,
                        onClickButton: (priceSide) => {
                            this.setState({
                                priceSides: priceSides.includes(priceSide) ? _.without(priceSides, priceSide) : priceSides.concat([priceSide])
                            })
                        }
                    })}
                </div>
                <div className='option-implied-volatility-surface--header--row'>
                    <div className='option-implied-volatility-surface--header--position-toggle'>
                        <label>{'Show Positions'}</label>
                        <Toggle
                            checked={shouldShowPositions}
                            onChange={(newChecked) => { this.setState({ shouldShowPositions: newChecked }) }} />
                    </div>
                    <div className='option-implied-volatility-surface--header--position-portfolio'>
                        <label>{'Potfolio'}</label>
                        <SearchSelect
                            value={positionPortfolio}
                            options={_.map(_.concat([ALL], portfolioNames), _portfolio => {
                                return {
                                    value: _portfolio,
                                    name: _portfolio
                                }
                            })}
                            onChange={(newOption) => {
                                this.setState({ positionPortfolio: newOption.value })
                            }} />
                    </div>
                    <div className='option-implied-volatility-surface--header--position-offset'>
                        <label>{'Y-Offset'}</label>
                        <input
                            type={'number'}
                            value={positionSurfaceOffset}
                            onChange={(e) => { this.setState({ positionSurfaceOffset: e.target.value }) }} />
                    </div>
                </div>
            </div>
        )
    }

    render () {
        const { plotLayout, exchangeNames, optionTypes, priceSides } = this.state 
        const surfaceData = this.getSurfaceData()
        return (
            <div className='option-implied-volatility-surface'>
                {this.Header()}
                <div className='option-implied-volatility-surface--body' ref={(node) => { this.surfaceBodyNode = node }}>
                    {!_.isEmpty(exchangeNames) && !_.isEmpty(optionTypes) && !_.isEmpty(priceSides) && plotLayout.width > 0 && plotLayout.height > 0 && !_.isEmpty(surfaceData) && 
                    <Suspense fallback={<div style={{ margin: '10px', fontWeight: 'bold' }}>{'Loading...'}</div>}>
                        <Plot 
                            className='option-implied-volatility-surface--plot'
                            data={surfaceData} 
                            layout={plotLayout}
                            style={{ width: '100%', height: '100%' }}
                            config={{ displayModeBar: false }}
                            onUpdate={(figure) => { 
                                if (!_.isEqual(plotLayout, figure.layout)) {
                                    this.setState({ plotLayout: figure.layout }) 
                                }
                            }} />
                    </Suspense>}
                </div>
            </div>
        )
    }
}

OptionImpliedVolatilitySurface.propTypes = {
    optionSymbolAdditionalInfo: PropTypes.object.isRequired,
    positions: PropTypes.array.isRequired,
    accountItems: PropTypes.object.isRequired,
    coin: PropTypes.string.isRequired,
    withoutExpiryDates: PropTypes.array,
    strikePriceRange: PropTypes.array,
    volatilityRange: PropTypes.array,
    pastHours: PropTypes.number
}

OptionImpliedVolatilitySurface.defaultProps = {
    coin: 'BTC',
    withoutExpiryDates: [],
    strikePriceRange: [0, Number.POSITIVE_INFINITY],
    volatilityRange: [0, Number.POSITIVE_INFINITY]
}

function mapStateToProps (state) {
    return {
        optionSymbolAdditionalInfo: state.symbol.optionSymbolAdditionalInfo,
        positions: state.trading.positions,
        accountItems: state?.account?.items
    }
}

export default connect(mapStateToProps)(OptionImpliedVolatilitySurface)