import React, { Component } from 'react'
import { Navigate } from 'react-router-dom'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import _ from 'lodash'

import loadingImage from '../../images/loading.svg'
import { authVerifyUser } from './authAction'

class PrivateRoute extends Component {

    constructor (props) {
        super(props)
        const { auth } = props
        const shouldVerify = !auth.isLoggedIn && !_.isEmpty(auth.accessToken)
        this.state = {
            isVerifying: shouldVerify,
            isVerified: auth.isLoggedIn
        }
        this._mounted = false
        if (shouldVerify) {
            this._verifyUser()
        }
    }

    componentDidMount () {
        this._mounted = true
    }

    componentWillUnmount () {
        this._mounted = false
    }

    _verifyUser () {
        const { dispatch } = this.props
        dispatch(authVerifyUser())
        .then((result) => {
            if (this._mounted) {
                this.setState({ isVerified: result === 'success' })
            }
        })
        .finally(() => {
            if (this._mounted) {
                this.setState({ isVerifying: false })
            }
        })
    }

    Verifying () {
        return (
            <div className='verifying'>
                <div className='verifying--body centered'>
                    <img src={loadingImage} alt={'verifying'} />
                    <div>{'Verifying'}</div>
                </div>
            </div>
        )
    }

    render () {
        const { auth, children } = this.props
        const { isVerifying } = this.state
        return auth.isLoggedIn ? children 
            : isVerifying ? this.Verifying()
            : <Navigate to={'/login'} />
    }
}

PrivateRoute.propTypes = {
    dispatch: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.array,
        PropTypes.object
    ]).isRequired
}

function mapStateToProps (state) {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(PrivateRoute)