import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { AutoSizer, List, CellMeasurer, CellMeasurerCache } from 'react-virtualized'

export default class VirtualizedDynamicHeightList extends Component {
    constructor (props) {
        super(props)

        this.cellMeasurerCache = new CellMeasurerCache({
            fixedWidth: true,
            fixedHeight: false
        })
        this.listNode = null
    }

    componentDidUpdate (prevProps) {
        const { scrollTopId: prevScrollTopId } = prevProps
        const { scrollTopId } = this.props
        if (!_.isEqual(prevScrollTopId, scrollTopId) && !_.isNil(scrollTopId) && this.listNode) {
            this.listNode.scrollToPosition(0)
        }
    }

    renderItem (params, item) {
        const { renderItem } = this.props
        const { index, key, parent, style } = params

        return (
            <CellMeasurer
                key={key} 
                rowIndex={index}
                columnIndex={0}
                parent={parent} 
                cache={this.cellMeasurerCache}>
                {({ registerChild }) => {
                    return (
                        <div className='virtualized-dynamic-height-list--item'
                            key={_.has(item, 'id') ? item.id : key}
                            ref={registerChild}
                            style={style}>
                                {renderItem(item)}
                            </div>
                         )
                    }}
            </CellMeasurer>
        )
    }

    render () {
        const { className, items } = this.props
        this.cellMeasurerCache.clearAll()

        return (
            <div className={'virtualized-dynamic-height-list' + (className ? ` ${className}` : '')}>
                <AutoSizer>
                    {({ width, height }) => (
                        <List 
                            ref={(node) => { this.listNode = node }}
                            className='virtualized-dynamic-height-list--body'
                            width={width}
                            height={height} 
                            rowCount={_.size(items)}
                            rowRenderer={params => this.renderItem(params, items[params.index])} 
                            rowHeight={this.cellMeasurerCache.rowHeight} 
                            overscanRowCount={5} 
                            scrollToAlignment={'start'}
                            deferredMeasurementCache={this.cellMeasurerCache} />
                    )}
                </AutoSizer>
            </div>
        )
    }
}

VirtualizedDynamicHeightList.propTypes = {
    className: PropTypes.string,
    items: PropTypes.array.isRequired,
    renderItem: PropTypes.func,
    scrollTopId: PropTypes.string
}

VirtualizedDynamicHeightList.defaultProps = {
    items: [],
    renderItem: () => {}
}