import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import dotProp from 'dot-prop-immutable'
import _ from 'lodash'
import Modal from 'react-modal'
import {MdClose} from 'react-icons/md'
import {FaCheck} from 'react-icons/fa'

import SaveButton from '../common/saveButton/SaveButton'
import {createProfileGroupItem, updateProfileGroupItem, deleteProfileGroup} from './profileAction'

Modal.setAppElement('#root')

class ProfileGroupEditor extends Component {
    constructor (props) {
        super(props)
        this.state = {
            group: props.group,
            searchString: '',
            isSaving: false,
            isSaved: false,
            message: null,
            showDeleteModal: false
        }
        this.initialGroupProfileIds = props.group.profileIds || [] 
        this.searchInput = null
    }

    renderContentBlock ({title, component}) {
        return (
            <div className='profile-group-editor--content-block'>
                <div className='profile-group-editor--content-block-title'>{title}</div>
                <div className='profile-group-editor--content-block-main'>{component}</div>
            </div>
        )
    }

    handleClickSaveButton () {
        const {group} = this.state
        const {dispatch, mode, onCreateSuccess} = this.props
        if (_.isEmpty(group.name.trim())) {
            this.setState({ message: 'You have to have your group a name' })
        } else {
            this.setState({ isSaving: true })
            if (mode === 'CREATE') {
                dispatch(createProfileGroupItem(group)).then((result) => {
                    this.setState({
                        isSaving: false,
                        isSaved: result === 'success'
                    })
                    onCreateSuccess()
                })
            } else {
                dispatch(updateProfileGroupItem(group.id, group))
                this.setState({ 
                    isSaved: true,
                    isSaving: false
                })
            }
        }
    }

    renderProfileSelector () {
        const {group, searchString} = this.state
        const {profileItems} = this.props
        const filteredProfileItems = _.filter(profileItems, 
            profileItem => profileItem.name.toLowerCase().includes(searchString.trim().toLowerCase()) && !_.isEmpty(profileItem.legs))
        return (
            <div className='profile-group-editor--profile-selector'>
                <div className='profile-group-editor--profile-selector--selected-groups clearfix'
                    onClick={() => { this.searchInput.focus() }}>
                    {group.profileIds.map((profileId) => {
                        const profileItem = profileItems[profileId]
                        return profileItem ?(
                            <div className='profile-group-editor--profile-selector--selected-group' key={profileId}>
                                <span>{profileItem.name}</span>
                                <button className='profile-group-editor--profile-selector--remove-button'
                                    onClick={() => {
                                        this.setState({
                                            isSaved: false,
                                            group: dotProp.set(group, 'profileIds', _.without(group.profileIds, profileId))
                                        })
                                    }}><MdClose /></button>
                            </div>
                        ) : null
                    })}
                    <input className='profile-group-editor--profile-selector--search-input' ref={(node) => { this.searchInput = node }}
                        value={searchString}
                        spellCheck={false}
                        placeholder={'Search Profile'} 
                        onChange={(e) => { this.setState({ searchString: e.target.value }) }}/>
                </div>
                <div className='profile-group-editor--profile-selector--profile-list'>
                    {filteredProfileItems.map((profileItem) => {
                        const isSelected = group.profileIds.includes(profileItem.id)
                        return (
                            <div className='profile-group-editor--profile-selector--profile-item' key={profileItem.id}
                                onClick={() => {
                                    const newProfileIds = isSelected ? _.without(group.profileIds, profileItem.id) : group.profileIds.concat([profileItem.id])
                                    this.setState({
                                        isSaved: false,
                                        group: dotProp.set(group, 'profileIds', newProfileIds)
                                    })
                                }}>
                                <span className='profile-group-editor--profile-selector--profile-item-name'>{profileItem.name}</span>
                                <span className='profile-group-editor--profile-selector--profile-item-hostname'>{profileItem.hostname}</span>
                                {isSelected && <FaCheck className='profile-group-editor--profile-selector--profile-item-selected-icon vertical-centered' />}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    renderDeleteModal () {
        const {dispatch, group, onDeleteSuccess} = this.props
        return (
            <Modal overlayClassName='modal-overlay' 
                className='profile-group-editor--delete-modal centered' 
                isOpen>
                <div className='profile-group-editor--delete-modal--message'>{'Are you sure to delete the group '}<strong>{group.name}</strong>{' ?'}</div>
                <div className='profile-group-editor--delete-modal--buttons'>
                    <button className='profile-group-editor--delete-modal--cancel-button' onClick={() => {
                        this.setState({ showDeleteModal: false })
                    }}>{'Never Mind'}</button>
                    <button className='profile-group-editor--delete-modal--delete-button' onClick={() => {
                        dispatch(deleteProfileGroup(group.id)).then((result) => {
                            if (result === 'success') {
                                onDeleteSuccess()
                            }
                        })
                    }}>{'Delete'}</button>
                </div>
            </Modal>
        )
    }

    render () {
        const {group, message, isSaving, isSaved, showDeleteModal} = this.state
        const {mode, onClickClose} = this.props
        return (
            <Fragment>
                <Modal overlayClassName='modal-overlay'
                    className='profile-group-editor horizontal-centered'
                    isOpen>
                    <button className='profile-group-editor--close-button' onClick={() => {
                        if (_.isFunction(onClickClose)) {
                            onClickClose()
                        }
                    }}><MdClose /></button>
                    <div className='profile-group-editor--title'>{mode === 'CREATE' ? 'Create Group' : 'Edit Group'}</div>
                    <div className='profile-group-editor--main'>
                        {this.renderContentBlock({
                            title: 'Name',
                            component: <input className='profile-group-editor--name-input' 
                                autoFocus
                                value={group.name}
                                spellCheck={false}
                                onChange={(e) => { 
                                    this.setState({ 
                                        message: null,
                                        isSaved: false,
                                        group: dotProp.set(group, 'name', e.target.value) 
                                    }) 
                                }}/>
                        })}
                        {this.renderContentBlock({
                            title: 'Select Profiles',
                            component: this.renderProfileSelector()
                        })}
                    </div>
                    {message && <div className='profile-group-editor--message'>{message}</div>}
                    <div className='profile-group-editor--buttons'>
                        {mode === 'EDIT' && <button className='profile-group-editor--delete-button' onClick={() => { 
                            this.setState({
                                showDeleteModal: true
                            })
                        }}>{'Delete'}</button>}
                        <SaveButton className='profile-group-editor--save-button' 
                            isSaving={isSaving} 
                            isSaved={isSaved} 
                            onClick={() => { this.handleClickSaveButton() }} />
                    </div>
                </Modal>
                {showDeleteModal && this.renderDeleteModal() }
            </Fragment>
        )
    }
}

ProfileGroupEditor.propTypes = {
    dispatch: PropTypes.func.isRequired,
    mode: PropTypes.oneOf(['CREATE', 'EDIT']),
    group: PropTypes.object.isRequired,
    profileItems: PropTypes.object.isRequired,
    onClickClose: PropTypes.func.isRequired,
    onCreateSuccess: PropTypes.func,
    onDeleteSuccess: PropTypes.func
}

ProfileGroupEditor.defaultProps = {
    mode: 'CREATE',
    group: {
        id: null,
        name: '',
        profileIds: [],
        editable: true
    },
    onCreateSuccess: () => {},
    onDeleteSuccess: () => {}
}

function mapStateToProps (state) {
    return {
        profileItems: state.profile.items
    }
}

export default connect(mapStateToProps)(ProfileGroupEditor)