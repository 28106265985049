import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import BigNumber from 'bignumber.js'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import _ from 'lodash'

import { AiOutlineSwap } from 'react-icons/ai'

import Popup from '../common/popup/Popup'
import TokenTransferEditor, { TRANSFER_STATES } from './TokenTransferEditor'

import { toNumberWithSmartPrecision } from '../../util/util'
import { getOptionAccountTransferAdvice } from '../../util/tradingUtil'

class OptionAccountBalanceItem extends Component {

    constructor (props) {
        super(props)
        this.state = {
            defaultSingleTransferConfig: null
        }

        this._mounted = null
    }

    TokenTransferPopup () {
        const { defaultSingleTransferConfig } = this.state
        const { optionAccountBalance } = this.props
        const { token, transferItem, validOriginTransferAccountNames } = getOptionAccountTransferAdvice(optionAccountBalance)

        return !_.isNil(token) && !_.isNil(transferItem) ? (
            <Popup
                className='option-account-balance-item--token-transfer-popup'
                trigger={<button className='option-account-balance-item--token-transfer-popup--trigger-button'><AiOutlineSwap /></button>}
                on={'click'}
                align={'horizontal'}
                onOpen={() => {
                    this.setState({
                        defaultSingleTransferConfig: {
                            id: uuidv4(),
                            token,
                            transferItem,
                            amount: 0,
                            amountPercentInput: '',
                            state: TRANSFER_STATES.NULL,
                            message: null
                        }
                    })
                }}>
                <div className='option-account-balance-item--token-transfer-popup--main' onClick={(e) => { e.stopPropagation() }}>
                    <TokenTransferEditor 
                        shouldAutoFocusAmountInput
                        defaultSingleTransferConfig={defaultSingleTransferConfig} 
                        validSingleTransferOriginAccountNames={validOriginTransferAccountNames}/>
                </div>
            </Popup>
        ) : null
    }

    Row ({ name, value, displayValue, hasDirection }) {
        return (
            <div className='option-account-balance-item--row'>
                <div className='option-account-balance-item--row--name'>{name}</div>
                <div className={'option-account-balance-item--row--value' + (value > 0 && hasDirection? ' positive' : value < 0 && hasDirection? ' negative' : '')}>
                    {!_.isNil(displayValue) ? displayValue : value}
                </div>
            </div>
        )
    }

    InlineData ({ name, value, shouldHighlight=false, highlightColor }) {
        return (
            <div className='option-account-balance-item--inline-data'>
                <span className='option-account-balance-item--inline-data--name'>{name}</span>
                <div className={'option-account-balance-item--inline-data--value' + (shouldHighlight ? ' highlight' : '')} style={{ color: shouldHighlight && !_.isEmpty(highlightColor) ? highlightColor : null }}>{value}</div>
            </div>
        )
    }

    render () {
        const { creditLineDetails, optionAccountBalance, shouldShowAccountTypeTag, shouldShowAccountName, shouldShowDetail, tokenTransferEnabled } = this.props
        const { acct_name: accountName, coin } = optionAccountBalance || {}
        const creditLineValue = _.get(creditLineDetails, `${accountName}--${_.toUpper(coin)}.credit_line`)
        return (
            <div className='option-account-balance-item'>
                <div className='option-account-balance-item--header clearfix'>
                    {shouldShowAccountTypeTag && <div className='option-account-balance-item--header--info-bar'>
                        <div className='option-account-balance-item--header--info-bar--account-type option'>{'OPTION'}</div>
                        <div className='option-account-balance-item--header--info-bar--timestamp'>{moment(optionAccountBalance.timestamp).format('HH:mm:ss')}</div>
                        {tokenTransferEnabled && 
                        <div className='option-account-balance-item--header--token-transfer-popup' onClick={(e) => { 
                            e.stopPropagation() 
                            const virtualClickEvent = new Event('click')
                            Object.defineProperty(virtualClickEvent, 'target', { writable: false, value: document.body })
                            window.dispatchEvent(virtualClickEvent)
                        }}>
                            {this.TokenTransferPopup()}
                        </div>}
                    </div>}
                    <div className='option-account-balance-item--coin'>
                        {optionAccountBalance.coin}
                        {shouldShowAccountName ? ` @ ${optionAccountBalance.acct_name}` : ''}
                    </div>
                    {!shouldShowAccountTypeTag && <div className='option-account-balance-item--timestamp'>{moment(optionAccountBalance.timestamp).format('HH:mm:ss')}</div>}
                </div>
                {shouldShowDetail && 
                <div className='option-account-balance-item--rows'>
                    {this.Row({
                        name: 'Locked',
                        value: Number(optionAccountBalance.locked),
                        displayValue: toNumberWithSmartPrecision({ number: optionAccountBalance.locked, shouldReturnLocalString: true, shouldApplyFloorValue: true })
                    })}
                    {this.Row({
                        name: 'Unrealized PnL',
                        value: Number(optionAccountBalance.unrealizedPNL),
                        displayValue: toNumberWithSmartPrecision({ number: optionAccountBalance.unrealizedPNL, shouldReturnLocalString: true, shouldApplyFloorValue: true }),
                        hasDirection: true
                    })}
                    {this.Row({
                        name: 'Available',
                        value: optionAccountBalance.available,
                        displayValue: toNumberWithSmartPrecision({ number: optionAccountBalance.available, shouldReturnLocalString: true, shouldApplyFloorValue: true }),
                        hasDirection: true
                    })}
                    {!_.isNil(creditLineValue) &&
                    this.Row({
                        name: 'Credit Line',
                        value: creditLineValue,
                        displayValue: toNumberWithSmartPrecision({ number: creditLineValue, shouldReturnLocalString: true, shouldApplyFloorValue: true }),
                        hasDirection: false
                    })}
                </div>}
                <div className='option-account-balance-item--summary'>
                    {this.InlineData({
                        name: 'Equity',
                        value: toNumberWithSmartPrecision({ number: optionAccountBalance.equity, shouldReturnLocalString: true, shouldApplyFloorValue: true, defaultPrecision: Math.abs(Number(optionAccountBalance.equity)) >= 1000 ? 0 : 2 })
                    })}
                    {this.InlineData({
                        name: 'Maint. Margin',
                        value: toNumberWithSmartPrecision({ number: optionAccountBalance.maintain_margin, shouldReturnLocalString: true, shouldApplyFloorValue: true, defaultPrecision: Math.abs(Number(optionAccountBalance.maintain_margin)) >= 1000 ? 0 : 2  })
                    })}
                    {this.InlineData({
                        name: 'MMR',
                        value: _.isNil(optionAccountBalance.mmr) ? 'N/A' : `${BigNumber(optionAccountBalance.mmr).times(100).toFixed(0, 1)}%`,
                        shouldHighlight: true,
                        highlightColor: !_.isNil(optionAccountBalance.mmr) && BigNumber(optionAccountBalance.mmr || 2).lte(2) ? '#f78991' : null
                    })}
                </div>
            </div>
        )
    } 
}

OptionAccountBalanceItem.propTypes = {
    creditLineDetails: PropTypes.object.isRequired,

    optionAccountBalance: PropTypes.object.isRequired,
    shouldShowAccountTypeTag: PropTypes.bool,
    shouldShowAccountName: PropTypes.bool,
    shouldShowDetail: PropTypes.bool,
    tokenTransferEnabled: PropTypes.bool
}

function mapStateToProps (state) {
    return {
        creditLineDetails: state.trading.creditLineDetails
    }
}
export default connect(mapStateToProps)(OptionAccountBalanceItem)