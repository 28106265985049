import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import AccountPopup from '../account/AccountPopup'
import Toggle from '../common/toggle/Toggle'
import { switchProfileSymbolReduceOnly } from './profileAction'

class ProfileReduceOnlySwitchItem extends Component {
    constructor (props) {
        super(props)
        this.state = {
            isSwitching: false
        }
    }

    _isSwitchedOn () {
        const { profileRunningState, accountName, symbolName, direction } = this.props
        return _.has(profileRunningState, 'reduceOnlySwitches')
            ? _.some(profileRunningState.reduceOnlySwitches || [], { account: accountName, symbol: symbolName, side: direction, type: 'REDUCE_ONLY_SWITCH_ON' })
            : false
    }

    render () {
        const { dispatch, accountItem, profileItem, accountName, symbolName, direction, shouldShowAcccountName } = this.props
        const { isSwitching } = this.state
        const isSwitchedOn = this._isSwitchedOn()
        return (
            <div className='profile-reduce-only-switch-item'>
                {shouldShowAcccountName && <div className={'profile-reduce-only-switch-item--account-name' + (_.isNil(accountItem) ? ' nil-account' : '')} 
                    title={_.isNil(accountItem) ? 'The account is invalid.' : null}>
                    {!_.isNil(accountItem) 
                    ? <AccountPopup accountItem={accountItem} />
                    : <span>{accountName}</span>}
                </div>}
                <Toggle className='profile-reduce-only-switch-item--toggle' 
                    disabled={!profileItem.started || profileItem.isPausing || profileItem.isStarting}
                    trueText={'ON'}
                    falseText={'OFF'} 
                    changingText={'...'} 
                    checked={isSwitchedOn} 
                    isChanging={isSwitching}
                    onChange={(newChecked) => { 
                        this.setState({ isSwitching: true })
                        dispatch(switchProfileSymbolReduceOnly({
                            profileId: profileItem.id,
                            symbolName,
                            accountName,
                            side: direction,
                            action: newChecked ? 'ON' : 'OFF'
                        }))
                        .finally(() => {
                            this.setState({ isSwitching: false })
                        })
                    }} />
            </div>
        )
    }
}

ProfileReduceOnlySwitchItem.defaultProps = {
    shouldShowAcccountName: false,
    direction: 'BUY'
}

ProfileReduceOnlySwitchItem.propTypes = {
    dispatch: PropTypes.func.isRequired,
    accountItem: PropTypes.object,
    profileRunningState: PropTypes.object,

    shouldShowAcccountName: PropTypes.bool,
    profileItem: PropTypes.object.isRequired,
    accountName: PropTypes.string.isRequired,
    symbolName: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(['BUY', 'SELL']).isRequired
}

function mapStateToProps (state, ownProps) {
    const { id: profileId } = ownProps.profileItem
    return {
        accountItem: state.account.items[ownProps.accountName],
        profileRunningState: _.has(state.profile.runningState, `${profileId}`) ? state.profile.runningState[profileId] : {}
    }
}

export default connect(mapStateToProps)(ProfileReduceOnlySwitchItem)