import React, { memo } from 'react'
import PropTypes from 'prop-types'

import './errorBoundary.scss'

function ErrorBoundaryFallbaclk ({ error={}, resetErrorBoundary=()=>{} }) {
    return (
        <div className='error-boundary-fallback'>
            <div className='error-boundary-fallback--title'>{`Something went wrong`}</div>
            <div className='error-boundary-fallback--message'>{error?.message}</div>
            <button onClick={() => { resetErrorBoundary() }}>{`Retry`}</button>
        </div>
    )
}

ErrorBoundaryFallbaclk.propTypes = {
    error: PropTypes.object,
    resetErrorBoundary: PropTypes.func
}

export default memo(ErrorBoundaryFallbaclk)