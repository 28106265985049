import React, { memo, useEffect, useState } from 'react'
import useEvent from 'react-use-event-hook'
import { useLatest, useMountedState } from 'react-use'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import ReactLoading from 'react-loading'

import { constants, utils } from 'ethers'
import { isAddress } from 'viem'
import _ from 'lodash'

import { FaTimes } from 'react-icons/fa'
import Popup from '../common/popup/Popup'

import { readStorage, sendTransactionWithWallet } from './blockchainThunk'
import { ABI as VAULT_ABI } from './ABIs/Vault'
import { VAULT_CHAIN, VAULT_SLOT } from './blockchainConfig'
import { bytes32ToAddr, getEthersJsonRpcBatchProvider, parseABI } from './blockchainUtil'
import { getDefaultChain } from '../../wagmiConfig'
import { formattedHex, formattedTokenAmount } from '../../util/formatUtil'

const LIST_SENTINEL = `0x${'0'.repeat(39)}1`
const PROVIDER = getEthersJsonRpcBatchProvider({ chainId: VAULT_CHAIN.id })

const _fetchTraders = async (contractAddress) => {
    const traders = []
    let trader = LIST_SENTINEL
    // eslint-disable-next-line no-constant-condition
    while (true) {
        trader = bytes32ToAddr(await readStorage({
            provider: PROVIDER,
            contract: contractAddress,
            path: [VAULT_SLOT.TRADERS, trader],
            pathType: 'OM'
        }))
        if ([constants.AddressZero, LIST_SENTINEL].includes(trader)) {
            break
        }
        traders.push(trader)
    }
    return traders
}


function VaultTraders ({ vaultAddress }) {

    const isMounted = useMountedState()

    const [traders, setTraders] = useState([])
    const [traderBalances, setTraderBalances] = useState({})
    const [isFetchingTraders, setIsFetchingTraders] = useState(false)
    const [newTradersInput, setNewTradersInput] = useState('')
    const [isSendingTx, setIsSendingTx] = useState(false)
    const [txHash, setTxHash] = useState('')
    const [message, setMessage] = useState(false)
    const latestVaultAddress = useLatest(vaultAddress)

    const defaultChain = getDefaultChain()

    const newTraders = _.filter(
        _.map(newTradersInput.split(','), address => (address || '').trim()), 
        address => !_.isEmpty(address)
    )

    const updateTraderBalances = useEvent(async () => {
        if (!_.isEmpty(traders)) {
            const balances = await Promise.all(_.map(traders, traderAddress => PROVIDER.getBalance(traderAddress)))
            if (isMounted()) {
                const newTraderBalances = _.reduce(balances, (result, balance, index) => {
                    result[traders[index]] = utils.formatEther(balance)
                    return result
                }, {})
                setTraderBalances(newTraderBalances)
            }
        }
    })

    const updateTraders = useEvent(async () => {
        if (isAddress(vaultAddress)) {
            setIsFetchingTraders(true)
            const newTraders = await _fetchTraders(vaultAddress)
            if (isMounted() && vaultAddress === latestVaultAddress.current) {
                setTraders(newTraders)
                setIsFetchingTraders(false)
                updateTraderBalances()
            }
        }
    })

    useEffect(() => {
        if (!_.isEmpty(traders)) {
            setTraders([])
        }
        updateTraders()
    }, [vaultAddress])

    useEffect(() => {
        const polling = setInterval(() => {
            updateTraderBalances()
        }, 20000)
        return () => { clearInterval(polling) }
    }, [])

    const handleClickClearAndSetTraders = useEvent(() => {
        if (!isSendingTx && isAddress(vaultAddress)) {
            setIsSendingTx(true)
            sendTransactionWithWallet({
                address: vaultAddress,
                abi: parseABI(VAULT_ABI),
                functionName: 'clearAndSetTraders',
                args: [newTraders]
            })
            .then((receipt) => {
                if (isMounted()) {
                    const { transactionHash } = receipt
                    if (!_.isEmpty(transactionHash)) {
                        setTxHash(transactionHash)
                    }
                    updateTraders()
                }
            })
            .catch(error => {
                if (isMounted()) {
                    setMessage(error.toString())       
                }
            })
            .finally(() => {
                if (isMounted()) {
                    setIsSendingTx(false)       
                }
            })
        }
    })

    return (
        <div className='vault-traders'>
            <div className='vault-traders--header'>
                <label>{'Trader Balances'}</label>
                <div className='vault-traders--header--buttons'>
                    {isFetchingTraders
                    ? <ReactLoading className='vault-traders--header--loading'
                        type={'spin'}
                        color='#fff' />
                    : <button className='vault-traders--fetch-latest-button'
                        onClick={() => {
                            setTraders([])
                            updateTraders()
                        }}>{'Fetch Latest'}</button>}
                    <Popup className='vault-traders--set-traders-popup'
                        on={'click'}
                        trigger={<button className='vault-traders--set-traders-popup--trigger'>{'Clear & Set Traders'}</button>}
                        onOpen={() => { setNewTradersInput(traders.join(', ')) }}>
                        <textarea placeholder='Comma Seperated Addresses'
                            spellCheck={false}
                            autoFocus
                            value={newTradersInput}
                            onChange={(e) => {
                                if (!_.isEmpty(message)) {
                                    setMessage('')
                                }
                                setNewTradersInput(e.target.value) 
                            }} />
                        {txHash &&
                        <div className='vault-traders--set-traders-popup--message'>
                            <p>
                                {`Confirmed: `}
                                <Link to={`${defaultChain?.blockExplorers?.etherscan?.url}/tx/${txHash}`} target='_blank'>{formattedHex(txHash)}</Link>
                            </p>
                            <button onClick={() => { setTxHash('') }}><FaTimes /></button>
                        </div>}
                        {!_.isEmpty(message) &&
                        <div className='vault-traders--set-traders-popup--message'>
                            <p>{message}</p>
                            <button onClick={() => { setMessage('') }}><FaTimes /></button>
                        </div>}
                        <button disabled={_.some(newTraders, trader => !isAddress(trader))}
                            onClick={() => { handleClickClearAndSetTraders() }}>{isSendingTx ? 'Waiting' : 'Confirm'}</button>
                    </Popup>
                </div>
            </div>
            <div className='vault-traders--list'>
                {_.map(traders, trader => {
                    const balance = _.get(traderBalances, trader)
                    return (
                        <div className='vault-traders--list--item' key={trader}>
                            <Link
                                to={`${defaultChain?.blockExplorers?.etherscan?.url}/address/${trader}`}
                                target={'_blank'}
                                title={trader}>
                                {formattedHex(trader)}
                            </Link>
                            {!_.isEmpty(balance) && <span>{`: ${formattedTokenAmount({ amount: balance })}`}</span>}
                        </div>

                    )
                })}
            </div>
        </div>
    )
}

VaultTraders.propTypes = {
    vaultAddress: PropTypes.string.isRequired
}

export default memo(VaultTraders)