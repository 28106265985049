/* eslint-disable react/prop-types */
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'

import { FiAlertOctagon } from 'react-icons/fi'
import Toggle from '../common/toggle/Toggle'
import { toggleTimer } from './timerAction'
// import { updateProfileSearchString } from '../profile/profileAction'
import { timerProfileItemHasSymbolConflict } from '../../util/profileUtil'
import { getSymbolAttributeByName } from '../../util/symbolUtil'
import Popup from '../common/popup/Popup'

class TimerItem extends Component {

    renderHeader () {
        const { timerItem, profileItems } = this.props
        const isLoop = timerItem.loop ? 'loop' : 'one-time'
        const datetimeString = timerItem.datetime.map(time => 
            moment(time, timerItem.loop ? 'HH:mm:ssZ' : '').utcOffset('+08:00').format(timerItem.loop ? 'h:mm:ss A' : 'YYYY-MM-DD hh:mm:ss A')
        ).join(', ')
        const hasSymbolConflicts = _.some(timerItem.profiles, timerProfileItem => profileItems[timerProfileItem.profileId] && timerProfileItemHasSymbolConflict(timerProfileItem, profileItems[timerProfileItem.profileId]))
        return (
            <div className='timer-item--header'>
                {/* {!hideDetail && <div className='timer-item--header--id'>{`ID: ${timerItem.id}`}</div>} */}
                <div className={`timer-item--header--loop-type ${isLoop ? 'loop' : 'one-time'}`}>{isLoop ? 'Loop' : 'One-Time'}</div>
                {hasSymbolConflicts &&
                <Popup className='timer-item--conflict--popup'
                    trigger={<div className='timer-item--conflict--icon'><FiAlertOctagon /></div>}>
                    <span>{`Some original profiles' symbols have been modified.`}</span>
                </Popup>}
                <div className='timer-item--header--datetime'>
                    <span className='timer-item--header--datetime--timezone'>{'HKT'}</span>
                    <span className='timer-item--header--datetime--value'>{datetimeString}</span>
                </div>
                {timerItem.description && <div className='timer-item--header--description'>{timerItem.description}</div>}
            </div>
        )
    }

    renderProfile ({key, timerProfile}) {
        const { dispatch, timerItem, profileItems } = this.props
        const profileItem = profileItems[timerProfile.profileId]
        const enabled = timerProfile.enabled
        const leg1Symbols = _.has(timerProfile, `legs.1.symbols`) ? timerProfile.legs['1'].symbols : []
        const { hideDetail } = this.props

        const ProfileHeader = () => {
            return (
                <div className='timer-item--profile--header clearfix'>
                    <div className='timer-item--profile--header--left'>
                        <div className={'timer-item--profile--name' + (_.isNil(profileItem) ? ' disabled' : '')} title={profileItem ? profileItem.name : timerProfile.profileId}
                            disabled={_.isNil(profileItem)}
                            onClick={(e) => {
                                e.stopPropagation()
                                // if (profileItem) {
                                //     dispatch(updateProfileSearchString(profileItem.name))
                                // }
                            }}>
                            {profileItem ? profileItem.name : timerProfile.profileId}
                        </div>
                        {profileItem && <div className='timer-item--profile--basic-info'>{`${profileItem.user} @ ${profileItem.hostname}`}</div>}
                    </div>
                    <div className='timer-item--profile--toggle' onClick={(e) => { e.stopPropagation() }}>
                        <Toggle 
                            checked={enabled} 
                            trueText={'ON'}
                            falseText={'OFF'} 
                            disabled={_.isNil(profileItem)}
                            onChange={() => { dispatch(toggleTimer(timerItem, timerProfile.profileId)) }} />
                    </div>
                </div>
            )
        }

        const SymbolSwitch = ({name, value}) => {
            const valueString = value === true ? 'ON' : value === false ? 'OFF' : null
            return !_.isNil(value) ? (
                <div className={'timer-item--symbol-switch' + (valueString ? ` ${valueString}` : '')}>
                    {name && <div className='timer-item--symbol-switch--name'>{`${name}: `}</div>}
                    <div className={`timer-item--symbol-switch--value ${valueString}`}>{valueString}</div>
                </div>
            ) : null
        }

        const ProfileSymbolParams = ({ symbol, displaySwitch, displaySpreadThreshold, displayMaxSidePos }) => {
            const { name, params, switches } = symbol
            const symbolAttribute = getSymbolAttributeByName(name)
            const { switchType } = symbolAttribute
            const { QUOTE_SPREAD_THRESHOLD, QUOTE_MAX_SIDE_POS } = params
            return (
                <table className='timer-item--symbol-params'>
                    <thead>
                        <tr>
                            <th />
                            <th>{'BUY'}</th>
                            <th>{'SELL'}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {displaySwitch && <tr>
                            <th>{'Switch'}</th>
                            <td>
                                {switchType === 'BUY_SELL' && SymbolSwitch({ value: switches.BUY })}
                                {switchType === 'BUY_SELL_TO_OPEN_CLOSE' && 
                                <Fragment>
                                    {SymbolSwitch({ value: switches.BUY_OPEN, name: 'Open' })}
                                    {SymbolSwitch({ value: switches.BUY_CLOSE, name: 'Close' })}
                                </Fragment>}
                            </td>
                            <td>
                                {symbolAttribute.switchType === 'BUY_SELL' && SymbolSwitch({ value: switches.SELL })}
                                {symbolAttribute.switchType === 'BUY_SELL_TO_OPEN_CLOSE' && 
                                <Fragment>
                                    {SymbolSwitch({ value: switches.SELL_OPEN, name: 'Open'})}
                                    {SymbolSwitch({ value: switches.SELL_CLOSE, name: 'Close'})}
                                </Fragment>}
                            </td>
                        </tr>}
                        {displaySpreadThreshold &&
                        <tr>
                            <th>
                                {'SPD THR'}
                                <span className={`timer-item--symbol-params--param-mode ${QUOTE_SPREAD_THRESHOLD.mode}`}>{` (${QUOTE_SPREAD_THRESHOLD.mode === 'relative' ? 'Rel' : 'Abs'})`}</span>
                            </th>
                            <td>{QUOTE_SPREAD_THRESHOLD.value[0]}</td>
                            <td>{QUOTE_SPREAD_THRESHOLD.value[1]}</td>
                        </tr>}
                        {displayMaxSidePos &&
                        <tr>
                            <th>{'Max Side Pos'}</th>
                            <td>{QUOTE_MAX_SIDE_POS.value[0]}</td>
                            <td>{QUOTE_MAX_SIDE_POS.value[1]}</td>
                        </tr>}
                    </tbody>
                </table>
            )
        }
        
        return (
            <div className='timer-item--profile' key={key}>
                {ProfileHeader()}
                {!hideDetail && <div className='timer-item--profile--symbols'>
                    {leg1Symbols.map((symbol, index) => {
                        const { params, switches } = symbol
                        const { QUOTE_SPREAD_THRESHOLD, QUOTE_MAX_SIDE_POS } = params
                        const hasSwitch = !Object.values(switches).every(value => value === null)
                        const hasQuoteSpreadThreshold = QUOTE_SPREAD_THRESHOLD && _.isArray(QUOTE_SPREAD_THRESHOLD.value) && !_.isEqual(QUOTE_SPREAD_THRESHOLD.value, [null, null])
                        const hasQuoteMaxSidePos = QUOTE_MAX_SIDE_POS && _.isArray(QUOTE_MAX_SIDE_POS.value) && !_.isEqual(QUOTE_MAX_SIDE_POS.value, [null, null])
                        return (hasSwitch || hasQuoteSpreadThreshold || hasQuoteMaxSidePos) ? (
                            <div className='timer-item--profile--symbol' key={index}>
                                <div className='timer-item--profile--symbol-name'>{symbol.name}</div>
                                {ProfileSymbolParams({
                                    symbol: symbol,
                                    displaySwitch: hasSwitch,
                                    displaySpreadThreshold: hasQuoteSpreadThreshold,
                                    displayMaxSidePos: hasQuoteMaxSidePos
                                })}
                            </div>
                        ) : null
                    })}
                </div>}
            </div>
        )
    }

    render () {
        const { timerItem, pickProfileId, searchString } = this.props
        const trimmedSearchString = searchString.trim().toLowerCase()
        const filteredProfiles = !_.isNil(pickProfileId) 
        ? timerItem.profiles.filter(profile => profile.profileId === pickProfileId)
        : trimmedSearchString.length > 0 
            ? timerItem.profiles.filter(profile => profile.profileId.toLowerCase().includes(trimmedSearchString))
            : timerItem.profiles

        return (
            <div className='timer-item'>
                {this.renderHeader()}
                <div className='timer-item--profiles'>
                    {filteredProfiles.map((profile, index) => {
                        return this.renderProfile({
                            key: index,
                            timerProfile: profile
                        })
                    })}
                </div>
            </div>
        )
    }
}

TimerItem.propTypes = {
    dispatch: PropTypes.func.isRequired,
    timerItem: PropTypes.object.isRequired,
    profileItems: PropTypes.object.isRequired,
    pickProfileId: PropTypes.string, 
    searchString: PropTypes.string,
    hideDetail: PropTypes.bool
}

TimerItem.defaultProps = {
    pickProfileId: null,
    searchString: '',
    hideDetail: false
}

function mapStateToProps (state) {
    return {
        profileItems: state.profile.items
    }
}

export default connect(mapStateToProps)(TimerItem)
