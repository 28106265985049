import React, { Component, Fragment } from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'

import { Howler } from 'howler'

import { AutoSizer, List, CellMeasurer, CellMeasurerCache } from 'react-virtualized'

import { FaSearch, FaTrashAlt } from 'react-icons/fa'
import { FiBellOff } from 'react-icons/fi'
import { ALL_PROFILE_GROUP_ID } from '../../configs/profileConfig'
import { clearNotificationItems } from './tradingAction'
import { updateProfileSearchString } from '../profile/profileAction'
import { isMetSearchStringCriteria } from '../../util/util'

class NotificationContainer extends Component {
    constructor (props) {
        super(props)
        this.filters = {
            ALL: 'All',
            CURRENT_GROUP: 'CURT. Group',
            MY_GROUPS: 'My Groups'
        }
        this.state = {
            filter: Object.keys(this.filters)[0],
            showSearch: false,
            searchString: '',
            audioContextState: _.get(Howler, 'ctx.state')
        }

        this.cellMeasurerCache = new CellMeasurerCache({
            fixedWidth: true,
            fixedHeight: false
        })

        this._mounted = false
        this.listNode = null
        this.prevNotificationIds = null
    }

    componentDidMount () {
        this._mounted = true
        const ctx = _.get(Howler, 'ctx')
        if (!_.isNil(ctx)) {
            Howler.ctx.onstatechange = (e) => {
                if (e.target.state === 'suspended') {
                    Howler.ctx.resume()
                }
                if (this._mounted) {
                    this.setState({ audioContextState: e.target.state })
                }
            }
        }
    }

    componentWillUnmount () {
        this._mounted = false
    }

    renderItem (params, notification) {
        const { dispatch, profileItems } = this.props
        const { index, key, parent, style } = params
        const profileItem = profileItems[notification.profileId]
        return (
            <CellMeasurer
                key={key} 
                rowIndex={index}
                columnIndex={0}
                parent={parent} 
                cache={this.cellMeasurerCache}>
                {({ registerChild }) => {
                    return (
                        <div className='notification-container--item-wrapper'
                            key={notification.id}
                            ref={registerChild}
                            style={style}>
                            <div className='notification-container--item' key={notification.id}>
                                <div className='notification-container--item--header clearfix'>
                                    {(notification.user || notification.hostname) && <div className='notification-container--item--user-server-info'>
                                        <span className='notification-container--item--username'>{notification.user}</span>
                                        {notification.hostname && <span className='notification-container--item--server'>{` @ ${notification.hostname}`}</span>}
                                    </div>}
                                    <div className='notification-container--item--timestamp'>{moment(notification.timestamp).format('YYYY-M-D HH:mm:ss')}</div>
                                    {notification.profileId && 
                                    <div className='notification-container--item--profile-name' 
                                        onClick={() => { 
                                            if (profileItem) {
                                                dispatch(updateProfileSearchString(profileItem.name))
                                            }
                                        }}>
                                        {profileItem ? profileItem.name : `Profile ID: ${notification.profileId}`}
                                    </div>}
                                    <br />
                                    {_.has(notification, 'type') && _.isString(notification.type) && 
                                    <div className={`notification-container--item--type ${notification.type}`}>{notification.type.replace(/_/g, ' ')}</div>}
                                </div>
                                <div className='notification-container--item--message' dangerouslySetInnerHTML={{__html: notification.message}} />
                            </div>
                        </div>
                    )
                }}
            </CellMeasurer>
        )
    }

    render () {
        const { filters } = this
        const { showSearch, searchString, filter, audioContextState } = this.state
        const { dispatch, notifications, profileItems, profileGroup, profileId, shouldHideHeader } = this.props
        let filteredNotificationItems = _.isEmpty(searchString.trim()) ? notifications : _.filter(notifications, notification => {
            const profileItem = profileItems[notification.profileId] || {}
            return isMetSearchStringCriteria(`${notification.username} ${notification.message} ${notification.type} ${profileItem.name || ''}`, searchString)
        })
        if (!_.isEmpty(profileId)) {
            filteredNotificationItems = _.filter(filteredNotificationItems, { profileId })
        } else if (filter === 'CURRENT_GROUP') {
            const focusedGroup = profileGroup.items[profileGroup.focusedId]
            filteredNotificationItems = filteredNotificationItems
                .filter(item => focusedGroup && focusedGroup.profileIds.includes(item.profileId))
        } else if (filter === 'MY_GROUPS') {
            const myGroupProfileIds = Object.values(_.omit(profileGroup.items, [ALL_PROFILE_GROUP_ID])).reduce((ids, profileGroup) => {
                return _.union(ids, profileGroup.profileIds)
            }, [])
            filteredNotificationItems = filteredNotificationItems
                .filter(item => myGroupProfileIds.includes(item.profileId))
        }

        const notificationIds = _.map(filteredNotificationItems, item => item.id)
        
        if (!_.isEqual(this.prevNotificationIds, notificationIds)) {
            this.cellMeasurerCache.clearAll()
            this.prevNotificationIds = notificationIds
        }

        return (
            <div className='notification-container'>
                {!shouldHideHeader && <Fragment>
                    {audioContextState === 'suspended' && <div className='notification-container--audio-suspended'>
                        <FiBellOff />
                    </div>}
                    <div className='notification-container--title'>{'Notifications'}</div>
                    <button className={'notification-container--search-button' + (showSearch ? ' active' : '')} onClick={() => { 
                        this.setState({ 
                            searchString: '',
                            showSearch: !showSearch 
                        }) 
                    }}><FaSearch /></button>
                    <button className='notification-container--clear-button' onClick={() => { dispatch(clearNotificationItems()) }}><FaTrashAlt /></button>
                </Fragment>}
                {(showSearch || shouldHideHeader) && <input className='notification-container--search-input' value={searchString}
                    placeholder={shouldHideHeader ? 'Search Type, User, Message' : 'Search Profile, Type, User'}
                    autoFocus
                    spellCheck={false}
                    onChange={(e) => { 
                        this.setState({ searchString: e.target.value }) 
                        if (this.listNode) {
                            this.listNode.scrollToPosition(0)
                        }
                    }} />}
                {!shouldHideHeader && <div className='notification-container--filters'>
                    {_.map(filters, (name, key) => {
                        return (
                            <button className={'notification-container--filter' + (filter === key ? ' active' : '')} key={key}
                                onClick={() => { 
                                    this.setState({
                                        filter: key
                                    })
                                }}>{name}</button>
                        )
                    })}
                </div>}
                <div className='notification-container--list-wrapper'>
                    <AutoSizer>
                        {({ width, height }) => (
                            <List 
                                ref={(node) => { this.listNode = node }}
                                className='transaction-container--list'
                                width={width}
                                height={height} 
                                rowCount={_.size(filteredNotificationItems)}
                                rowRenderer={params => this.renderItem(params, filteredNotificationItems[params.index])} 
                                rowHeight={this.cellMeasurerCache.rowHeight} 
                                overscanRowCount={5} 
                                scrollToAlignment={'start'}
                                deferredMeasurementCache={this.cellMeasurerCache} />
                        )}
                    </AutoSizer>
                </div>
                {!_.isEmpty(profileId) && _.isEmpty(filteredNotificationItems) && <div className='notification-container--empty-message'>{'Empty Data'}</div>}
            </div>
        )
    }
}

NotificationContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    profileItems: PropTypes.object.isRequired,
    notifications: PropTypes.array.isRequired,
    profileGroup: PropTypes.object.isRequired,

    shouldHideHeader: PropTypes.bool,
    profileId: PropTypes.string
}

function mapStateToProps (state) {
    return {
        profileItems: state.profile.items,
        notifications: state.trading.notifications,
        profileGroup: state.profile.group
    }
}

export default connect(mapStateToProps)(NotificationContainer)