/* eslint-disable react/prop-types */

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { toNumberWithSmartPrecision, toAbbreviateNumber } from '../../util/util'
import moment from 'moment'

class DefiLendingInfoContainer extends Component {
    constructor (props) {
        super(props)
        this.state = {
            searchString: ''
        }
    }

    Item (defiLendingItem) {
        const { symbol, platform, updated_time: updatedTime,
            borrow_rate_apr: borrowRateARP, suply_rate_apr: supplyRateAPR,
            collateral_factor: collateralFactor, collateral_ratio: collateralRatio,
            total_supply: totalSupply, total_borrows: totalBorrows,
            cash, reserves, total_supply_wei: totalSupplyWei, total_borrow_wei: totalBorrowWei,
            supply_index: supplyIndex, borrow_index: borrowIndex } = defiLendingItem

        const Row = (name, value) => {
            return (
                <div className='defi-lending-item--row'>
                    <div className='defi-lending-item--row--name'>{name}</div>
                    <div className='defi-lending-item--row--value'>{value}</div>
                </div>
            )
        }

        const TableRow = ({ name, borrowValue, supplyValue }) => {
            return (
                <tr>
                    <th>{name}</th>
                    <td>{borrowValue}</td>
                    <td>{supplyValue}</td>
                </tr>
            )
        }

        return (
            <div className='defi-lending-item'>
                <div className='defi-lending-item--header clearfix'>
                    <span className='defi-lending-item--header--symbol'>{symbol}</span>
                    <span className={`defi-lending-item--header--platform ${platform}`}>{platform}</span>
                </div>
                <div className='defi-lending-item--rows'>
                    {Row('Timestamp', moment(updatedTime).format('HH:mm:ss'))}
                    {Row('Collateral Factor', toNumberWithSmartPrecision({ number: collateralFactor }))}
                    {Row('Collateral Ratio', toNumberWithSmartPrecision({ number: collateralRatio }))}
                    {cash > 0 && Row('Cash', toNumberWithSmartPrecision({ number: cash, shouldReturnLocalString: true }))}
                    {reserves > 0 && Row('Reserves', toNumberWithSmartPrecision({ number: reserves, shouldReturnLocalString: true }))}
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>{'Side'}</ th>
                            <th>{'BORROW'}</th>
                            <th>{'SUPPLY'}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(borrowIndex>0 || supplyIndex>0) && TableRow({
                            name: 'Index',
                            borrowValue: toNumberWithSmartPrecision({ number: borrowIndex, defaultPrecision: 4 }),
                            supplyValue: toNumberWithSmartPrecision({ number: supplyIndex, defaultPrecision: 4 })
                        })}
                        {(borrowRateARP>0 || supplyRateAPR>0) && TableRow({
                            name: 'APR',
                            borrowValue: toNumberWithSmartPrecision({ number: borrowRateARP * 100 }) + '%',
                            supplyValue: toNumberWithSmartPrecision({ number: supplyRateAPR * 100 }) + '%'
                        })}
                        {(totalBorrows>0 || totalSupply>0) && TableRow({
                            name: 'Total',
                            borrowValue: toAbbreviateNumber(totalBorrows),
                            supplyValue: toAbbreviateNumber(totalSupply)
                        })}
                        {(totalBorrowWei>0 || totalSupplyWei>0) && TableRow({
                            name: 'Total Wei',
                            borrowValue: Number.parseFloat(totalBorrowWei).toExponential(2),
                            supplyValue: Number.parseFloat(totalSupplyWei).toExponential(2)
                        })}
                    </tbody>
                </table>
            </div>
        )
    }

    render () {
        const { searchString } = this.state
        const { defiLendingInfo } = this.props
        const trimmedSearchString = searchString.toLowerCase().trim()
        const filteredDefiLendingItems = trimmedSearchString.length === 0
            ? defiLendingInfo
            : _.filter(defiLendingInfo, item => `${item.symbol} ${item.platform}`.toLocaleLowerCase().includes(trimmedSearchString))
        return (
            <div className='defi-lending-info-container'>
                <div className='defi-lending-info-container--header'>
                    <input className='defi-lending-info-container--search-input' 
                        spellCheck={false}
                        placeholder={'Search Token, Platform'}
                        value={searchString}
                        onChange={(e) => { this.setState({ searchString: e.target.value }) }}/>
                </div>
                <div className='defi-lending-info-container--body'>
                    {filteredDefiLendingItems.map((item, index) => (
                        <Fragment key={index}>
                            {this.Item(item)}
                        </Fragment>
                    ))}
                </div>
            </div>
        )
    }
}

DefiLendingInfoContainer.propTypes = {
    defiLendingInfo: PropTypes.array.isRequired
}

function mapStateToProps (state) {
    return {
        defiLendingInfo: state.symbol.defiLendingInfo || []
    }
}

export default connect(mapStateToProps)(DefiLendingInfoContainer)