import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import BigNumber from 'bignumber.js'
import moment from 'moment'
import _ from 'lodash'

class PortfolioMarginEffRatioVirtualAccountInfo extends Component {

    constructor (props) {
        super(props)
    }

    render () {
        const { portfolioMarginEffRatioVirtualAccount, ratioHighlightThreshold, liquidationRatioAlertLimit } = this.props
        const { acct_name: accountName, eff_ratio: effRatio, equity, maint_margin: maintMargin, adjusted_position: adjustedPosition, timestamp } = portfolioMarginEffRatioVirtualAccount
        const { soft_limit: softLimit, hard_limit: hardLimit } = liquidationRatioAlertLimit || {}

        return !_.isNil(portfolioMarginEffRatioVirtualAccount) && (
            <div className='portfolio-margin-eff-ratio-virtual-account-info'>
                <div className='portfolio-margin-eff-ratio-virtual-account-info--header'>
                    <label className='portfolio-margin-eff-ratio-virtual-account-info--header--tag'>{'PMA Eff. Ratio'}</label>
                    {!_.isEmpty(timestamp) && <span className='portfolio-margin-eff-ratio-virtual-account-info--header--timestamp'>{moment(timestamp).format('HH:mm:ss')}</span>}
                </div>
                <div className='portfolio-margin-eff-ratio-virtual-account-info--name'>{accountName}</div>
                <div className='portfolio-margin-eff-ratio-virtual-account-info--stats'>
                    {<div className='portfolio-margin-eff-ratio-virtual-account-info--stats--item'>
                        <label>{'Equity'}</label>
                        <span>{_.isNil(equity) ? 'N/A' : `$${BigNumber(equity).toFormat(0, 1)}`}</span>
                    </div>}
                    <div className='portfolio-margin-eff-ratio-virtual-account-info--stats--item'>
                        <label>{'Maint. Margin'}</label>
                        <span>{_.isNil(maintMargin) ? 'N/A' : `$${BigNumber(maintMargin).toFormat(0, 1)}`}</span>
                    </div>
                    <div className='portfolio-margin-eff-ratio-virtual-account-info--stats--item'>
                        <label>{'Adj. Position'}</label>
                        <span>{_.isNil(adjustedPosition) ? 'N/A' : `$${BigNumber(adjustedPosition).toFormat(0, 1)}`}</span>
                    </div>
                    {!_.isEmpty(liquidationRatioAlertLimit) &&
                    <div className='portfolio-margin-account-im-info--stats--item'>
                        <label>{'S/H Limit'}</label>
                        <span>{`${softLimit} / ${hardLimit}`}</span>
                    </div>}
                    <div className='portfolio-margin-eff-ratio-virtual-account-info--stats--item'>
                        <label>{'Eff Ratio'}</label>
                        <span className={!_.isNil(effRatio) && BigNumber(effRatio || ratioHighlightThreshold).lte(ratioHighlightThreshold) ? 'warning' : 'highlight'}>{_.isNil(effRatio) ? 'N/A' : `${BigNumber(effRatio).times(100).toFixed(2, 1)}%`}</span>
                    </div>
                </div>
            </div>
        )
    }
}

PortfolioMarginEffRatioVirtualAccountInfo.propTypes = {
    portfolioMarginEffRatioVirtualAccount: PropTypes.object.isRequired,
    ratioHighlightThreshold: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    liquidationRatioAlertLimit: PropTypes.shape({
        acct_name: PropTypes.string,
        symbol: PropTypes.string,
        soft_limit: PropTypes.number,
        hard_limit: PropTypes.number,
        for_cross_ratio: PropTypes.number,
        comment: PropTypes.string
    })
}

function mapStateToProps (state, ownProps) {
    const { acct_name: accountName } = _.get(ownProps, 'portfolioMarginEffRatioVirtualAccount') || {}
    return {
        liquidationRatioAlertLimit: _.get(state.trading?.liquidationRatioAlertLimits, `${accountName}--eff_credit`)
    }
}

export default connect(mapStateToProps)(PortfolioMarginEffRatioVirtualAccountInfo)