import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import _ from 'lodash'

import { AiOutlineSwap } from 'react-icons/ai'

import Popup from '../common/popup/Popup'

import { toNumberWithSmartPrecision, toAbbreviateNumber, toNumberInputValue } from '../../util/util'
import TokenTransferEditor, { ACCOUNT_TYPES, EXCHANGES as tokenTransferExchangeConfigs, TransferAccount, TransferItem, PairName, TRANSFER_STATES } from './TokenTransferEditor'
import { accountBorrowFund, accountRepayFund, fetchAccountMarginBalance } from './accountAction'

const ACTIONS = {
    BORROW: {
        key: 'BORROW',
        name: 'Borrow'
    },
    REPAY: {
        key: 'REPAY',
        name: 'Repay'
    }
}

class MarginAccountBalanceItem extends Component {

    constructor (props) {
        super(props)
        this.state = {
            defaultSingleTransferConfig: null,
            actionKey: null,
            inputAmount: ['', ''],
            isFetching: false,
            message: null
        }

        this._mounted = null
    }

    static getDerivedStateFromProps (props, state) {
        if (props.shouldShowDetail === false && !_.isNil(state.actionKey)) {
            return {
                actionKey: null,
                inputAmount: ['', '']
            }
        } else {
            return null
        }
    }

    componentDidMount () {
        this._mounted = true
    }

    componentWillUnmount () {
        this._mounted = false
    }

    canSumbit () {
        const { actionKey, inputAmount, isFetching } = this.state
        return !isFetching 
            && Object.keys(ACTIONS).includes(actionKey)
            && _.isArray(inputAmount)
            && _.size(inputAmount) === 2
            && ((inputAmount[0] === '' && _.isNumber(inputAmount[1])) || (_.isNumber(inputAmount[0]) && inputAmount[1] === ''))
            && _.find(inputAmount, amount => _.isNumber(amount)) > 0
    }

    handleClickConfirmButton () {
        const { dispatch, marginAccountBalance, onChangeComponentHeight } = this.props
        const { actionKey, inputAmount } = this.state        
        const request = actionKey === ACTIONS.BORROW.key ? accountBorrowFund
            : actionKey === ACTIONS.REPAY.key ? accountRepayFund
            : null

        if (this.canSumbit() && !_.isNil(request)) {
            const { coin1, coin2, acct_name } = marginAccountBalance
            const indexHasAmount = _.findIndex(inputAmount, amount => _.isNumber(amount)) 
            const amount = inputAmount[indexHasAmount]
            const currency = indexHasAmount === 0 ? coin1
                : indexHasAmount === 1 ? coin2
                : null 
            if (!_.isEmpty(acct_name) && amount > 0 && !_.isNil(currency)) {
                this.setState({ isFetching: true })
                dispatch(request({
                    account_name: acct_name,
                    pair: `${coin1}-${coin2}`.toLowerCase(),
                    currency,
                    amount
                }))
                .then(response => {
                    if (this._mounted && response && response.status) {
                        response.text().then(text => {
                            this.setState(prevState => {
                                return {
                                    isFetching: false,
                                    message: (response.status === 200 ? 'Success: ' : 'Fail: ') + text,
                                    inputAmount: response.status === 200 ? ['', ''] : prevState.inputAmount
                                }
                            })
                            onChangeComponentHeight()
                        })
                    }
                })
                .catch(error => {
                    if (this._mounted) {
                        this.setState({
                            isFetching: false,
                            message: error.toString()
                        })
                        onChangeComponentHeight()
                    }
                })
                .finally(() => {
                    dispatch(fetchAccountMarginBalance())
                })
            }
        }
    }

    InlineData ({name, value, shouldHighlight, className}) {
        return (
            <div className={'margin-account-balance-item--inline-data' + (className ? ` ${className}` : '')}>
                <span className='margin-account-balance-item--inline-data--name'>{name}</span>
                <div className={'margin-account-balance-item--inline-data--value' + (shouldHighlight ? ' highlight' : '')}>{value}</div>
            </div>
        )
    }

    TokenTransferPopup () {
        const { defaultSingleTransferConfig } = this.state
        const { marginAccountBalance, accountItems } = this.props
        const { acct_name: accountName, coin1, coin2 } = marginAccountBalance
        const accountItem = accountItems[accountName]
        const accountExchangeName = _.has(accountItem, 'exchange_name') ? accountItem.exchange_name : null
        const okexTokenTransferConfig = tokenTransferExchangeConfigs.OKEX
        const tokenToTransfer = coin1.toUpperCase()

        const originTransferAccount = accountExchangeName === 'OKEX'
            && (accountItem.is_main === '1' || !_.isEmpty(accountItem.main_acct_name))
            ? TransferAccount({
                accountName: accountItem.is_main === '1' ? accountName : accountItem.main_acct_name,
                accountType: _.has(okexTokenTransferConfig, 'getAccountTypesCanTransferToken') 
                    && _.isFunction(okexTokenTransferConfig.getAccountTypesCanTransferToken)
                    && okexTokenTransferConfig.getAccountTypesCanTransferToken().map(accountType => accountType.key).includes(ACCOUNT_TYPES.WALLET.key) 
                    ? ACCOUNT_TYPES.WALLET
                    : null,
                pairName: null
            }) : TransferAccount({})

        const destinationTransferAccount = TransferAccount({
            accountName,
            accountType: ACCOUNT_TYPES.MARGIN,
            pairName: _.has(okexTokenTransferConfig, 'shouldDefinePairName') && _.isFunction(okexTokenTransferConfig.shouldDefinePairName) && okexTokenTransferConfig.shouldDefinePairName({ 
                    tokenToTransfer, 
                    accountTypeKey: ACCOUNT_TYPES.MARGIN.key
                }) ? PairName(coin1, coin2) : null
        })

        const validSingleTransferOriginAccountNames = _.has(okexTokenTransferConfig, 'getCounterTransferAccountNames') && _.isFunction(okexTokenTransferConfig.getCounterTransferAccountNames)
            ? okexTokenTransferConfig.getCounterTransferAccountNames({ tokenToTransfer, transferAccount: destinationTransferAccount, accountItems })
            : []
        return accountExchangeName === 'OKEX' ? (
            <Popup
                className='margin-account-balance-item--token-transfer-popup'
                trigger={<button className='margin-account-balance-item--token-transfer-popup--trigger-button'><AiOutlineSwap /></button>}
                on={'click'}
                align={'horizontal'}
                onOpen={() => {
                    this.setState({
                        defaultSingleTransferConfig: {
                            id: uuidv4(),
                            token: tokenToTransfer,
                            transferItem: TransferItem({
                                originTransferAccount,
                                destinationTransferAccount
                            }),
                            amount: 0,
                            amountPercentInput: '',
                            state: TRANSFER_STATES.NULL,
                            message: null
                        }
                    })
                }}>
                <div className='margin-account-balance-item--token-transfer-popup--main' onClick={(e) => { e.stopPropagation() }}>
                    <TokenTransferEditor 
                        shouldAutoFocusAmountInput
                        defaultSingleTransferConfig={defaultSingleTransferConfig} 
                        validSingleTransferOriginAccountNames={validSingleTransferOriginAccountNames}/>
                </div>
            </Popup>
        ) : null
    }

    render () {
        const { actionKey, inputAmount, isFetching, message } = this.state
        const { accountItems, marginAccountBalance, shouldShowAccountTypeTag, shouldShowAccountName, shouldHideBorrowRepay, 
            shouldShowDetail, pricingItem, tokenTransferEnabled, isAccountNameClickable, 
            onChangeComponentHeight, onClickAccountName } = this.props
        const timestampToDisplay = moment(marginAccountBalance.timestamp).format('HH:mm:ss')

        const { pair, acct_name: accountName, coin1, coin2, borrowed1, borrowed2, 
            max_borrowable1: maxBorrowable1, max_borrowable2: maxBorrowable2,
            lending_fee1: lendingFee1, lending_fee2: lendingFee2, balance1, balance2, transferable1, transferable2,
            liquidation_price: liquidationPrice, risk_rate: riskRate, leverage } = marginAccountBalance
        const lastPrice = _.has(pricingItem, 'last') ? pricingItem.last : null
        const canAccountSupportBorrowRepay = _.has(accountItems, `${accountName}.exchange_name`) && accountItems[accountName].exchange_name === 'OKEX'

        return (
            <div className='margin-account-balance-item'>
                <div className='margin-account-balance-item--header clearfix'>
                    {shouldShowAccountTypeTag && <div className='margin-account-balance-item--header--info-bar'>
                        <div className='margin-account-balance-item--header--info-bar--account-type margin'>{`MARGIN`}</div>
                        <div className='margin-account-balance-item--header--info-bar--account-leverage'>{`${leverage}X`}</div>
                        <div className='margin-account-balance-item--header--info-bar--timestamp'>{timestampToDisplay}</div>
                        {tokenTransferEnabled && 
                        <div className='margin-account-balance-item--header--token-transfer-popup' onClick={(e) => { e.stopPropagation() }}>
                            {this.TokenTransferPopup()}
                        </div>}
                    </div>}
                    <div className='margin-account-balance-item--pair'>
                        {pair.toUpperCase() + (shouldShowAccountName ? ` @ ` : '')}
                        {shouldShowAccountName && 
                        <span className={'margin-account-balance-item--pair--account-name' + (isAccountNameClickable ? ' clickable' : '')}
                            onClick={(e) => {
                            if (isAccountNameClickable) {
                                e.stopPropagation()
                                onClickAccountName(accountName)
                            }
                        }}>{accountName}</span>}
                    </div>
                    {!shouldShowAccountTypeTag && <div className='margin-account-balance-item--timestamp'>{timestampToDisplay}</div>}
                </div>
                {shouldShowDetail && <table className='margin-account-balance-item--pair-table'>
                    <thead>
                        <tr>
                            <th>{'Coin'}</th>
                            <th>{coin1.toUpperCase()}</th>
                            <th>{coin2.toUpperCase()}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>{'Balance'}</th>
                            <td className='right-align'>{toNumberWithSmartPrecision({ number: Number(balance1), shouldReturnLocalString: true })}</td>
                            <td className='right-align'>{toNumberWithSmartPrecision({ number: Number(balance2), shouldReturnLocalString: true })}</td>
                        </tr>
                        {(!_.isEmpty(_.toString(transferable1)) || !_.isEmpty(_.toString(transferable2))) && <tr>
                            <th>{'Transferable'}</th>
                            <td className='right-align'>{toNumberWithSmartPrecision({ number: Number(transferable1), shouldReturnLocalString: true })}</td>
                            <td className='right-align'>{toNumberWithSmartPrecision({ number: Number(transferable2), shouldReturnLocalString: true })}</td>
                        </tr>}
                        <tr>
                            <th>{'Borrowed'}</th>
                            <td className='right-align'>{toNumberWithSmartPrecision({ number: Number(borrowed1), shouldReturnLocalString: true })}</td>
                            <td className='right-align'>{toNumberWithSmartPrecision({ number: Number(borrowed2), shouldReturnLocalString: true })}</td>
                        </tr>
                        <tr>
                            <th>{'Borrowable'}</th>
                            <td className='right-align'>{toNumberWithSmartPrecision({ number: Number(maxBorrowable1), shouldReturnLocalString: true })}</td>
                            <td className='right-align'>{toNumberWithSmartPrecision({ number: Number(maxBorrowable2), shouldReturnLocalString: true })}</td>
                        </tr>
                        {(lendingFee1 > 0 || lendingFee2 > 0) && <tr>
                            <th>{'Lending Fee'}</th>
                            <td className='right-align'>{toNumberWithSmartPrecision({ number: Number(lendingFee1), shouldReturnLocalString: true })}</td>
                            <td className='right-align'>{toNumberWithSmartPrecision({ number: Number(lendingFee2), shouldReturnLocalString: true })}</td>
                        </tr>}
                        {!shouldHideBorrowRepay && canAccountSupportBorrowRepay && <tr>
                            <td colSpan={99}>
                                <div className='margin-account-balance-item--actions clearfix'>
                                    {_.map(ACTIONS, action => {
                                        return (
                                            <button 
                                                className={`margin-account-balance-item--action-button ${action.key}` + (actionKey === action.key ? ' active' : '')}
                                                disabled={isFetching}
                                                key={action.key}
                                                onClick={(e) => { 
                                                    e.stopPropagation()
                                                    this.setState({ 
                                                        actionKey: action.key,
                                                        inputAmount: ['', '']
                                                    }) 
                                                    onChangeComponentHeight()
                                                }}>
                                                {action.name}
                                            </button>
                                        )
                                    })}
                                </div>
                            </td>
                        </tr>}
                        {actionKey && !shouldHideBorrowRepay && canAccountSupportBorrowRepay && <Fragment>
                        <tr onClick={(e) => { e.stopPropagation() }}>
                            <th>{'Amount'}</th>
                            {_.map(inputAmount, (amountValue, index) => {
                                return (
                                    <td key={index}>
                                        <input className='margin-account-balance-item--amount-input'
                                            type={'number'}
                                            disabled={isFetching}
                                            value={amountValue}
                                            autoFocus={index === 0}
                                            placeholder={index === 0 ? coin1.toUpperCase() : coin2.toUpperCase()}
                                            onChange={(e) => {
                                                const newInputAmount = [
                                                    (index === 0 ? toNumberInputValue(e.target.value) : ''),
                                                    (index === 1 ? toNumberInputValue(e.target.value) : '')
                                                ]
                                                this.setState({
                                                    inputAmount: newInputAmount,
                                                    message: null
                                                })
                                                if (!_.isEmpty(message)) {
                                                    onChangeComponentHeight()
                                                }
                                            }} />
                                    </td>
                                )
                            })}
                        </tr>
                        {message && <tr>
                            <td colSpan={99}>
                                <div className='margin-account-balance-item--lending-message'>{message}</div>
                            </td>
                        </tr>}
                        <tr>
                            <td colSpan={99}>
                                <div className='margin-account-balance-item--buttons clearfix'>
                                    <button className='margin-account-balance-item--button cancel' 
                                        disabled={isFetching}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            this.setState({
                                                actionKey: null,
                                                inputAmount: ['', ''],
                                                message: null
                                            })
                                            onChangeComponentHeight()
                                        }}>{'Cancel'}</button>
                                    <button className='margin-account-balance-item--button confirm' 
                                        disabled={!this.canSumbit()}
                                        onClick={(e) => { 
                                            e.stopPropagation()
                                            this.handleClickConfirmButton() 
                                        }}>{!isFetching ? 'Confirm' : 'Waiting'}</button>
                                </div>
                            </td>
                        </tr></Fragment>}
                    </tbody>
                </table>}
                <div className='margin-account-balance-item--pricing-wrapper'>
                    {this.InlineData({
                        name: 'Liq.',
                        value: liquidationPrice ? toNumberWithSmartPrecision({ number: liquidationPrice, shouldReturnLocalString: true }) : 'N/A'
                    })}
                    {this.InlineData({
                        name: 'Last PC.',
                        value: lastPrice ? toNumberWithSmartPrecision({ number: lastPrice, shouldReturnLocalString: true }) : 'N/A'
                    })}
                    {this.InlineData({
                        name: 'Risk Rate',
                        value: riskRate ? toAbbreviateNumber(riskRate, 4)  : 'N/A',
                        shouldHighlight: true
                    })}
                    <Popup className='margin-account-balance-item--ratio-popup' 
                        on={'hover'}
                        trigger={this.InlineData({
                            className: 'ratio',
                            name: 'Ratio',
                            value: lastPrice > 0 && liquidationPrice > 0 ? `${((liquidationPrice - lastPrice) / lastPrice * 100).toFixed(2)}%` : 'N/A',
                            shouldHighlight: true
                        })}>
                        <span>{'Ratio = (Liquidation - Last Price) / Last Price * 100%'}</span>
                    </Popup>
                </div>
            </div>
        )
    }
}

MarginAccountBalanceItem.propTypes = {
    pricingItem: PropTypes.object,
    accountItems: PropTypes.object,

    dispatch: PropTypes.func.isRequired,
    marginAccountBalance: PropTypes.object.isRequired,
    shouldShowAccountTypeTag: PropTypes.bool,
    shouldShowAccountName: PropTypes.bool,
    shouldShowDetail: PropTypes.bool,
    tokenTransferEnabled: PropTypes.bool,
    shouldHideBorrowRepay: PropTypes.bool,
    isAccountNameClickable: PropTypes.bool,
    onClickAccountName: PropTypes.func,
    onChangeComponentHeight: PropTypes.func

}

MarginAccountBalanceItem.defaultProps = {
    tokenTransferEnabled: false,
    shouldHideBorrowRepay: false,
    isAccountNameClickable: false,
    onClickAccountName: () => {},
    onChangeComponentHeight: () => {}
}

function mapStateToProps (state, ownProps) {
    const { account, symbol } = state
    const { coin1, coin2, acct_name } = ownProps.marginAccountBalance
    const accountItem = account.items[acct_name]
    const pricingItem = _.has(accountItem, 'exchange_name') ? symbol.pricings[`${coin1}_${coin2}_${accountItem.exchange_name}_SPT`] : null
    return {
        pricingItem,
        accountItems: state.account.items
    }
}

export default connect(mapStateToProps)(MarginAccountBalanceItem)