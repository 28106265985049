import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import BigNumber from 'bignumber.js'
// import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import _ from 'lodash'

// import { AiOutlineSwap } from 'react-icons/ai'

// import Popup from '../common/popup/Popup'
// import TokenTransferEditor, { TRANSFER_STATES } from './TokenTransferEditor'

import { toNumberWithSmartPrecision } from '../../util/util'
// import { getOptionAccountTransferAdvice } from '../../util/tradingUtil'

class BnbfutaPM2UnifiedAccountBalanceItem extends Component {

    constructor (props) {
        super(props)
        this.state = {
            defaultSingleTransferConfig: null
        }

        this._mounted = null
    }

    // TokenTransferPopup () {
    //     const { defaultSingleTransferConfig } = this.state
    //     const { optionAccountBalance } = this.props
    //     const { token, transferItem, validOriginTransferAccountNames } = getOptionAccountTransferAdvice(optionAccountBalance)

    //     return !_.isNil(token) && !_.isNil(transferItem) ? (
    //         <Popup
    //             className='bnbfuta-pm2-unified-account-balance-item--token-transfer-popup'
    //             trigger={<button className='bnbfuta-pm2-unified-account-balance-item--token-transfer-popup--trigger-button'><AiOutlineSwap /></button>}
    //             on={'click'}
    //             align={'horizontal'}
    //             onOpen={() => {
    //                 this.setState({
    //                     defaultSingleTransferConfig: {
    //                         id: uuidv4(),
    //                         token,
    //                         transferItem,
    //                         amount: 0,
    //                         amountPercentInput: '',
    //                         state: TRANSFER_STATES.NULL,
    //                         message: null
    //                     }
    //                 })
    //             }}>
    //             <div className='bnbfuta-pm2-unified-account-balance-item--token-transfer-popup--main' onClick={(e) => { e.stopPropagation() }}>
    //                 <TokenTransferEditor 
    //                     shouldAutoFocusAmountInput
    //                     defaultSingleTransferConfig={defaultSingleTransferConfig} 
    //                     validSingleTransferOriginAccountNames={validOriginTransferAccountNames}/>
    //             </div>
    //         </Popup>
    //     ) : null
    // }

    Row ({ name, value, displayValue, hasDirection }) {
        return (
            <div className='bnbfuta-pm2-unified-account-balance-item--row'>
                <div className='bnbfuta-pm2-unified-account-balance-item--row--name'>{name}</div>
                <div className={'bnbfuta-pm2-unified-account-balance-item--row--value' + (value > 0 && hasDirection? ' positive' : value < 0 && hasDirection? ' negative' : '')}>
                    {!_.isNil(displayValue) ? displayValue : value}
                </div>
            </div>
        )
    }

    InlineData ({ name, value, shouldHighlight=false, highlightColor }) {
        return (
            <div className='bnbfuta-pm2-unified-account-balance-item--inline-data'>
                <span className='bnbfuta-pm2-unified-account-balance-item--inline-data--name'>{name}</span>
                <div className={'bnbfuta-pm2-unified-account-balance-item--inline-data--value' + (shouldHighlight ? ' highlight' : '')} style={{ color: shouldHighlight && !_.isEmpty(highlightColor) ? highlightColor : null }}>{value}</div>
            </div>
        )
    }

    render () {
        const { bnbfutaPM2UnifiedAccountBalance, shouldShowAccountTypeTag, shouldShowAccountName, shouldShowDetail } = this.props
        const { accountEquity, accountMaintMargin, uniMMR, accountStatus, accountType, actualEquity } =bnbfutaPM2UnifiedAccountBalance || {}

        const _uniMMR = BigNumber(uniMMR || 0)
    
        return (
            <div className='bnbfuta-pm2-unified-account-balance-item'>
                <div className='bnbfuta-pm2-unified-account-balance-item--header clearfix'>
                    {shouldShowAccountTypeTag && <div className='bnbfuta-pm2-unified-account-balance-item--header--info-bar'>
                        <div className='bnbfuta-pm2-unified-account-balance-item--header--info-bar--account-type BNBFUTA_PM2'>{'BNBFUTA PM2'}</div>
                        <div className='bnbfuta-pm2-unified-account-balance-item--header--info-bar--timestamp'>{moment(bnbfutaPM2UnifiedAccountBalance.timestamp).format('HH:mm:ss')}</div>
                        {/* {tokenTransferEnabled && 
                        <div className='bnbfuta-pm2-unified-account-balance-item--header--token-transfer-popup' onClick={(e) => { 
                            e.stopPropagation() 
                            const virtualClickEvent = new Event('click')
                            Object.defineProperty(virtualClickEvent, 'target', { writable: false, value: document.body })
                            window.dispatchEvent(virtualClickEvent)
                        }}>
                            {this.TokenTransferPopup()}
                        </div>} */}
                    </div>}
                    {shouldShowAccountName &&
                    <div className='bnbfuta-pm2-unified-account-balance-item--account-name'>
                        {bnbfutaPM2UnifiedAccountBalance.acct_name}
                    </div>}
                    {!shouldShowAccountTypeTag && <div className='bnbfuta-pm2-unified-account-balance-item--timestamp'>{moment(bnbfutaPM2UnifiedAccountBalance.timestamp).format('HH:mm:ss')}</div>}
                </div>
                {shouldShowDetail && 
                <div className='bnbfuta-pm2-unified-account-balance-item--rows'>
                    {this.Row({
                        name: 'Status',
                        value: 0,
                        displayValue: accountStatus 
                    })}
                    {this.Row({
                        name: 'Type',
                        value: 0,
                        displayValue: accountType 
                    })}
                    {this.Row({
                        name: 'Actual Equity',
                        value: Number(actualEquity),
                        displayValue: toNumberWithSmartPrecision({ number: actualEquity, shouldReturnLocalString: true, shouldApplyFloorValue: true }),
                        hasDirection: true
                    })}
                </div>}
                <div className='bnbfuta-pm2-unified-account-balance-item--summary'>
                    {this.InlineData({
                        name: 'Equity',
                        value: toNumberWithSmartPrecision({ number: accountEquity, shouldReturnLocalString: true, shouldApplyFloorValue: true, defaultPrecision: Math.abs(Number(accountEquity)) >= 1000 ? 0 : 2 })
                    })}
                    {this.InlineData({
                        name: 'Maint. Margin',
                        value: toNumberWithSmartPrecision({ number: accountMaintMargin, shouldReturnLocalString: true, shouldApplyFloorValue: true, defaultPrecision: Math.abs(Number(accountMaintMargin)) >= 1000 ? 0 : 2  })
                    })}
                    {this.InlineData({
                        name: 'uniMMR',
                        value: _.isNil(uniMMR) ? 'N/A' : `${_uniMMR.times(100).toFixed(0, 1)}%`,
                        shouldHighlight: true,
                        highlightColor: !_.isNil(uniMMR) && _uniMMR.lte(2) ? '#f78991' : null
                    })}
                </div>
            </div>
        )
    } 
}

BnbfutaPM2UnifiedAccountBalanceItem.propTypes = {
    bnbfutaPM2UnifiedAccountBalance: PropTypes.object.isRequired,
    shouldShowAccountTypeTag: PropTypes.bool,
    shouldShowAccountName: PropTypes.bool,
    shouldShowDetail: PropTypes.bool
    // tokenTransferEnabled: PropTypes.bool
}

function mapStateToProps () {
    return {}
}
export default connect(mapStateToProps)(BnbfutaPM2UnifiedAccountBalanceItem)