import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { INSTRUMENT_TYPES, getSymbolAttributeByName } from '../../util/symbolUtil'
import { getPositionLiquidationRatio, getMarginTradingLiquidationRatio } from '../../util/tradingUtil'
import { toNumberWithSmartPrecision } from '../../util/util'
import Popup from '../common/popup/Popup'

const AccountLiquidation = ({ accountName='', liquidationPrice='', riskRatio=0 }) => {
    return {
        accountName,
        liquidationPrice,
        riskRatio
    }
}
class ProfileLiquidations extends Component {
    constructor (props) {
        super(props)
    }

    getAccountLiquidations () {
        const { accountNames, positions, symbolName, pricings, marginAccountBalances } = this.props
        const { instrumentType } = getSymbolAttributeByName(symbolName)
        const accountLiquidations = []
        _.forEach(accountNames, accountName => {
            let liquidationPrice, riskRatio
            if ([INSTRUMENT_TYPES.FUTURE, INSTRUMENT_TYPES.SWAP].includes(instrumentType)) {
                const positionItem = _.find(positions, { product_name: symbolName, account_name: accountName })
                if (positionItem) {
                    liquidationPrice = toNumberWithSmartPrecision({ number: positionItem.liquidation_price })
                    riskRatio = getPositionLiquidationRatio(positionItem, pricings[symbolName])
                }
            } else if (instrumentType === INSTRUMENT_TYPES.SPOT) {
                const symbolNameTokens = symbolName.split('_')
                if (symbolNameTokens.length > 1) {
                    const pairName = `${symbolNameTokens[0]}-${symbolNameTokens[1]}`
                    const marginAccountBalance = _.find(marginAccountBalances, {
                        pair: pairName,
                        acct_name: accountName
                    })
                    if (marginAccountBalance && Number(marginAccountBalance.liquidation_price) > 0) {
                        liquidationPrice = toNumberWithSmartPrecision({ number: marginAccountBalance.liquidation_price })
                        riskRatio = getMarginTradingLiquidationRatio(marginAccountBalance, pricings[symbolName])
                    }
                }
            }
            if (liquidationPrice || riskRatio) {
                accountLiquidations.push(AccountLiquidation({
                    accountName,
                    liquidationPrice,
                    riskRatio
                }))
            }
        })
        return accountLiquidations
    }

    Main (accountLiquidations=[]) {
        return (
            <div className='profile-liquidations--main'>
                {accountLiquidations.map((accountLiquidaiton, index) => {
                    const { accountName, liquidationPrice, riskRatio } = accountLiquidaiton
                    return (
                        <div className='profile-liquidations--item' key={index}>
                            <span className='profile-liquidations--item--account-name'>{accountName}</span>
                            <span className='profile-liquidations--item--liquidation-price'>{toNumberWithSmartPrecision({ number: liquidationPrice || '', shouldReturnLocalString: true })}</span>
                            <span className='profile-liquidations--item--risk-ratio'>{'(' + (riskRatio ? `${(riskRatio * 100).toFixed(2)}%` : 'N/A') + ')'}</span>
                        </div>
                    )
                })}
            </div>
        ) 
    }

    render () {
        const accountLiquidations = this.getAccountLiquidations()
        if (!_.isEmpty(accountLiquidations)) {
            return _.size(accountLiquidations) === 1 
                ? this.Main(accountLiquidations)
                : <Popup className='profile-liquidations--popup'
                    on={'click'}
                    trigger={<button className='profile-liquidations--popup--trigger'>{`View Details (${_.size(accountLiquidations)})`}</button>}>
                    <div className='profile-liquidations--popup--main'>
                        {this.Main(accountLiquidations)}
                    </div>
                </Popup>
        } else {
            return null
        }
    }
}

ProfileLiquidations.propTypes = {
    positions: PropTypes.array.isRequired,
    pricings: PropTypes.object.isRequired,
    marginAccountBalances: PropTypes.object.isRequired,

    symbolName: PropTypes.string.isRequired,
    accountNames: PropTypes.array.isRequired
}

function mapStateToProps (state) {
    return {
        positions: state.trading.positions,
        pricings: state.symbol.pricings,
        marginAccountBalances: state.account.balance.margin
    }
}

export default connect(mapStateToProps)(ProfileLiquidations)