import React, { Component, Fragment } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import dotProp from 'dot-prop-immutable'
import $ from 'jquery'

import { FiX } from 'react-icons/fi'
import ProfileParam from './ProfileParam'
import Popup from '../common/popup/Popup'
import SearchSelect from '../common/searchSelect/SearchSelect'
import { refSymbolParameters, createRefSymbolDefaultParameters } from '../../configs/profileConfig'

class ProfileBulkUploadRefSymbolRow extends Component {
    constructor (props) {
        super(props)
        this.state = {
            bulkEditingRefSymbols: [], // [{profileIndex: 0, symbolIndex: 0, refSymbolIndex: 0}, ...]
            bulkEditorParams: null,
            symbolFilterString: ''
        }
        this.refSymbolRowNode = null
        this.tableHeaderNode = null
        this.bulkEditorNode = null
        this.scrollTableEventTimeout = null
        this.handleScrollTableWrapper = this.handleScrollTableWrapper.bind(this)
        this.handleClickWindow = this.handleClickWindow.bind(this)
    }

    componentDidMount () {
        const tableWrapperNode = this._getTableWrapperNode()
        if (tableWrapperNode) {
            tableWrapperNode.addEventListener('scroll', this.handleScrollTableWrapper)
        }
        window.addEventListener('click', this.handleClickWindow)
    }

    componentDidUpdate () {
        this._arrangeBulkEditorPosition()
    }

    componentWillUnmount () {
        const tableWrapperNode = this._getTableWrapperNode()
        if (tableWrapperNode) {
            tableWrapperNode.removeEventListener('scroll', this.handleScrollTableWrapper)
        }
        window.removeEventListener('click', this.handleClickWindow)
    }

    _getTableWrapperNode () {
        const $tableWrapper = $(this.refSymbolRowNode).closest('.profile-bulk-upload-editor--table-wrapper')
        return $tableWrapper ? $tableWrapper[0] : null

    }

    handleScrollTableWrapper () {
        this.scrollTableEventTimeout = setTimeout(() => {
            this._arrangeBulkEditorPosition()
            this.scrollTableEventTimeout = null
        }, 250)
    }

    handleClickWindow (e) {
        const { bulkEditingRefSymbols, symbolFilterString } = this.state
        if (this.refSymbolRowNode && !this.refSymbolRowNode.contains(e.target) && 
            (!_.isEmpty(bulkEditingRefSymbols) || symbolFilterString.length > 0)) {
            this._clearBulkEditingRefSymbols()
        } 
    }

    handleClickTableHeader () {
        const { bulkEditorParams } = this.state
        const { editingProfiles } = this.props
        const newBulkEditingRefSymbols = []
        let newBulkEditorParams = bulkEditorParams
        editingProfiles.forEach((profile, profileIndex) => {
            const quoteLegSymbols = profile.legs['1'].symbols || []
            quoteLegSymbols.forEach((symbol, symbolIndex) => {
                const refSymbols = symbol.refSymbols || []
                refSymbols.forEach((refSymbol, refSymbolIndex) => {
                    if (![null, '', 'INVALID'].includes(refSymbol.name)) {
                        newBulkEditorParams = bulkEditorParams || refSymbol.params
                        newBulkEditingRefSymbols.push({
                            profileIndex,
                            symbolIndex,
                            refSymbolIndex
                        })
                    }
                })
            })
        })
        if (!_.isEmpty(newBulkEditingRefSymbols)) {
            this.setState({
                bulkEditingRefSymbols: newBulkEditingRefSymbols,
                bulkEditorParams: newBulkEditorParams
            })
        }
    }

    _addBulkEditingRefSymbol ({ profileIndex, symbolIndex, refSymbolIndex }) {
        const { bulkEditingRefSymbols, bulkEditorParams } = this.state
        const { editingProfiles } = this.props
        if (this._getBulkEditingRefSymbolIndex({ profileIndex, symbolIndex, refSymbolIndex }) < 0) {
            const refSymbolItem = editingProfiles[profileIndex].legs['1'].symbols[symbolIndex].refSymbols[refSymbolIndex]
            if (![null, '', 'INVALID'].includes(refSymbolItem.name)) {
                this.setState({
                    bulkEditingRefSymbols: bulkEditingRefSymbols.concat([{
                        profileIndex,
                        symbolIndex,
                        refSymbolIndex
                    }]),
                    bulkEditorParams: _.isEmpty(bulkEditorParams) ? refSymbolItem.params : bulkEditorParams
                })
            }
        }
    }

    _getBulkEditingRefSymbolIndex ({ profileIndex, symbolIndex, refSymbolIndex }) {
        const { bulkEditingRefSymbols } = this.state
        return _.findIndex(bulkEditingRefSymbols, {
            profileIndex,
            symbolIndex,
            refSymbolIndex
        })
    }

    _removeBulkEditingRefSymbol ({ profileIndex, symbolIndex, refSymbolIndex }) {
        const { bulkEditingRefSymbols, bulkEditorParams } = this.state
        const index = this._getBulkEditingRefSymbolIndex({ profileIndex, symbolIndex, refSymbolIndex })
        if (index > -1) {
            this.setState({
                bulkEditingRefSymbols: dotProp.delete(bulkEditingRefSymbols, index),
                bulkEditorParams: bulkEditingRefSymbols.length === 1 ? null : bulkEditorParams
            })
        }
    }

    _clearBulkEditingRefSymbols () {
        const { bulkEditingRefSymbols, symbolFilterString } = this.state
        if (!_.isEmpty(bulkEditingRefSymbols) || symbolFilterString.length > 0) {
            this.setState({
                bulkEditingRefSymbols: [],
                bulkEditorParams: null,
                symbolFilterString: ''
            })
        }
    }

    _arrangeBulkEditorPosition () {
        if (this.tableHeaderNode && this.bulkEditorNode) {
            const tableHeaderNodeClientRect = this.tableHeaderNode.getBoundingClientRect()
            const style = {
                left: tableHeaderNodeClientRect.right - this.bulkEditorNode.clientWidth - 5 + 'px',
                top: tableHeaderNodeClientRect.top + 25 + 'px'
            }
            _.forEach(style, (styleValue, styleKey) => this.bulkEditorNode.style[styleKey] = styleValue)
        }
    }

    RowSelectors () {
        const { symbolFilterString } = this.state
        const { editingProfiles } = this.props
        const ProfileRefSymbols = ({ profileIndex, symbolIndex, symbolName, refSymbols }) => {
            return {
                profileIndex,
                symbolIndex,
                symbolName,
                refSymbols
            }
        }

        const profileRefSymbolList = editingProfiles.reduce((result, profileItem, profileIndex) => {
            const profileLeg1Symbols =  _.has(profileItem, `legs.1.symbols`) ? profileItem.legs['1'].symbols : []
            profileLeg1Symbols.forEach((leg1SymbolItem, symbolIndex) => {
                if (_.has(leg1SymbolItem, 'refSymbols')) {
                    result.push(ProfileRefSymbols({
                        profileIndex,
                        symbolIndex,
                        symbolName: leg1SymbolItem.name,
                        refSymbols: _.filter(leg1SymbolItem.refSymbols, refSymbolItem => 
                            _.isString(refSymbolItem.name) && refSymbolItem.name !== 'INVALID' && refSymbolItem.name.trim().length > 0
                        )
                    }))
                }
            })
            return result
        }, [])

        const maxRefSymbolLength = profileRefSymbolList.reduce((result, profileRefSymbols) => {
            return Math.max(result, profileRefSymbols.refSymbols.length)
        }, 0)

        return maxRefSymbolLength > 0 ? (
            <div className='profile-bulk-upload-ref-symbol-row--row-selectors'>
                <div className='profile-bulk-upload-ref-symbol-row--row-selectors--title'>{'Select Single Row'}</div>
                <div className='profile-bulk-upload-ref-symbol-row--row-selectors--list'>
                    {Array(maxRefSymbolLength).fill(null).map((item, index) => (
                        <button className='profile-bulk-upload-ref-symbol-row--row-selectors--item' 
                            key={index}
                            onClick={() => {
                                const trimmedFilterString = symbolFilterString.trim().toLocaleLowerCase()
                                const newBulkEditingRefSymbols = profileRefSymbolList.reduce((result, profileRefSymbols) => {
                                    const { profileIndex, symbolIndex, symbolName, refSymbols } = profileRefSymbols
                                    if (_.has(refSymbols, index) 
                                        && (trimmedFilterString.length === 0 || symbolName.toLowerCase().includes(trimmedFilterString))
                                    ) {
                                        result.push({
                                            profileIndex,
                                            symbolIndex,
                                            refSymbolIndex: index
                                        })
                                    }
                                    return result
                                }, [])
                                this.setState({ bulkEditingRefSymbols: newBulkEditingRefSymbols })
                            }}>
                            {`Row ${index + 1}`}
                        </button>
                    ))}
                </div>
            </div>
        ) : null
    }

    SymbolFilter () {
        const { symbolFilterString } = this.state
        const { editingProfiles } = this.props
        return (
            <div className='profile-bulk-upload-ref-symbol-row--symbol-filter'>
                <input className='profile-bulk-upload-ref-symbol-row--symbol-filter--input' 
                    spellCheck={false}
                    placeholder={'Filter Symbols'}
                    value={symbolFilterString}
                    onChange={(e) => { 
                        const trimmedFilterString = e.target.value.trim().toLowerCase()
                        const newBulkEditingRefSymbols = editingProfiles.reduce((result, profileItem, profileIndex) => {
                            if (_.has(profileItem, 'legs.1.symbols')) {
                                const leg1Symbols = profileItem.legs['1'].symbols || []
                                leg1Symbols.forEach((legSymbolItem, symbolIndex) => {
                                    const { name, refSymbols } = legSymbolItem
                                    if (name.toLowerCase().includes(trimmedFilterString)) {
                                        (refSymbols || []).forEach((refSymbolItem, refSymbolIndex) => {
                                            if (_.isString(refSymbolItem.name) && refSymbolItem.name.length > 0 && refSymbolItem.name !== 'INVALID')
                                            result.push({
                                                profileIndex,
                                                symbolIndex,
                                                refSymbolIndex
                                            })
                                        })
                                    }
                                })
                            }
                            return result
                        }, [])  
                        this.setState({
                            bulkEditingRefSymbols: newBulkEditingRefSymbols, 
                            symbolFilterString: e.target.value 
                        }) 
                    }} />
            </div>
        )
    }

    BulkEditor () {
        const { bulkEditingRefSymbols, bulkEditorParams, symbolFilterString } = this.state
        const { editingProfiles, onChangeEditingProfiles } = this.props

        return ((!_.isEmpty(bulkEditingRefSymbols) && !_.isNil(bulkEditorParams)) || symbolFilterString.length > 0) ? ReactDOM.createPortal(
            <div className='profile-bulk-upload-ref-symbol-row--bulk-editor' ref={(node) => { this.bulkEditorNode = node }}
                onClick={(e) => { e.stopPropagation() }}>
                <div className='profile-bulk-upload-ref-symbol-row--bulk-editor--header clearfix'>
                    <span>{'Edit Selected Ref Symbols'}</span>
                    <button className='profile-bulk-upload-ref-symbol-row--bulk-editor--close-button' 
                        onClick={() => {
                            this._clearBulkEditingRefSymbols()
                        }}><FiX /></button>
                    {this.SymbolFilter()}
                </div>
                <div className='profile-bulk-upload-ref-symbol-row--bulk-editor--params'>
                    {_.map(bulkEditorParams, (paramValue, paramKey) => {
                        const paramConfig = refSymbolParameters[paramKey]
                        return (
                            <div className='profile-bulk-upload-ref-symbol-row--bulk-editor--param' key={paramKey}>
                                <div className='profile-bulk-upload-ref-symbol-row--bulk-editor--param-name'>{paramConfig.name}</div>
                                <ProfileParam
                                    paramConfig={paramConfig}
                                    value={paramValue}
                                    hideArrayLabel
                                    onChange={(newValue) => {
                                        const newProfiles = _.cloneDeep(editingProfiles)
                                        const isArrayParam = _.isArray(paramValue)
                                        let updatedArrayParamIndex = -1
                                        if (isArrayParam) {
                                            updatedArrayParamIndex = paramValue.findIndex((v, index) => { return v !== newValue[index] })
                                        }
                                        bulkEditingRefSymbols.forEach(bulkEditingRefSymbol => {
                                            const { profileIndex, symbolIndex, refSymbolIndex } = bulkEditingRefSymbol
                                            const refSymbolItemParams = newProfiles[profileIndex].legs['1'].symbols[symbolIndex].refSymbols[refSymbolIndex].params
                                            if (isArrayParam && updatedArrayParamIndex > -1) {
                                                refSymbolItemParams[paramKey][updatedArrayParamIndex] = newValue[updatedArrayParamIndex]
                                            } else if (!isArrayParam) {
                                                refSymbolItemParams[paramKey] = newValue
                                            }
                                        })
                                        this.setState({
                                            bulkEditorParams: dotProp.set(bulkEditorParams, `${paramKey}`, newValue)
                                        })
                                        onChangeEditingProfiles(newProfiles)
                                    }} />
                            </div>
                        )
                    })}
                </div>
                {this.RowSelectors()}
            </div>, window.document.body) : null
    }

    render () {
        const { editingProfiles, profileItems, symbolItems, onChangeEditingProfiles } = this.props
        const symbolOptions = Object.keys(symbolItems).map(symbolName => {
            return {
                value: symbolName,
                name: symbolName
            }
        })

        return (
            <tr className='profile-bulk-upload-ref-symbol-row' ref={(node) => { this.refSymbolRowNode = node }}>
                {this.BulkEditor()}
                <th ref={(node) => { this.tableHeaderNode = node }} onClick={() => { this.handleClickTableHeader() }}>{'Ref Symbols'}</th>
                {editingProfiles.map((editingProfileItem, editingProfileItemIndex) => {
                    const quoteLegSymbols = editingProfileItem.legs['1'].symbols || []
                    const width = `${100/(quoteLegSymbols.length || 1)}%`
                    const started = profileItems[editingProfileItem.id].started
                    return (
                        <td className='profile-bulk-upload-ref-symbol-row--profile' key={editingProfileItemIndex}>
                            <div className='profile-bulk-upload-ref-symbol-row--symbol-blocks'>
                                {quoteLegSymbols.map((symbolItem, symbolItemIndex) => {
                                    const refSymbols = symbolItem.refSymbols || []
                                    return (
                                        <div className='profile-bulk-upload-ref-symbol-row--symbol-block' key={symbolItemIndex}
                                            style={{ width: width }}>
                                            <div className='profile-bulk-upload-ref-symbol-row--items'>
                                                {refSymbols.map((refSymbol, refSymbolIndex) => {
                                                    const editingRefSymbol = {
                                                        profileIndex: editingProfileItemIndex,
                                                        symbolIndex: symbolItemIndex,
                                                        refSymbolIndex: refSymbolIndex
                                                    }
                                                    const isBulkEditing = this._getBulkEditingRefSymbolIndex(editingRefSymbol) > -1
                                                    return (
                                                        <div className={'profile-bulk-upload-ref-symbol-row--item' + (isBulkEditing ? ' is-bulk-editing' : '')} key={refSymbolIndex} 
                                                            onClick={(e) => {
                                                                if (e.ctrlKey || e.shiftKey || e.metaKey) {
                                                                    if (isBulkEditing) {
                                                                        this._removeBulkEditingRefSymbol(editingRefSymbol)
                                                                    } else {
                                                                        this._addBulkEditingRefSymbol(editingRefSymbol)
                                                                    }
                                                                }
                                                            }}>
                                                            <div className='profile-bulk-upload-ref-symbol-row--item--header'>
                                                                <Popup className='profile-bulk-upload-ref-symbol-row--item--symbol-name-popup'
                                                                    disabled={started === false}
                                                                    trigger={
                                                                        <div className='profile-bulk-upload-ref-symbol-row--item--symbol-name-search-select-wrapper'>
                                                                            <SearchSelect
                                                                                className='profile-bulk-upload-ref-symbol-row--item--symbol-name' 
                                                                                options={symbolOptions}
                                                                                value={refSymbol.name} 
                                                                                disabled={started !== false} 
                                                                                onChange={(newOption) => {
                                                                                    const newProfiles = dotProp.set(editingProfiles, `${editingProfileItemIndex}.legs.1.symbols.${symbolItemIndex}.refSymbols.${refSymbolIndex}.name`, newOption.value)
                                                                                    onChangeEditingProfiles(newProfiles)
                                                                                }} />
                                                                        </div>
                                                                    }>
                                                                    <Fragment>{'You are allowed to edit Ref Symbols only when the profile is stopped'}</Fragment>
                                                                </Popup>
                                                                {!started && !(refSymbols.length === 1 && [null, '', 'INVALID'].includes(refSymbol.name)) && 
                                                                <button className='profile-bulk-upload-ref-symbol-row--item--remove-ref-symbol-button'
                                                                    onClick={() => {
                                                                        let newProfiles
                                                                        if (refSymbols.length === 1) {
                                                                            newProfiles = dotProp.set(editingProfiles, `${editingProfileItemIndex}.legs.1.symbols.${symbolItemIndex}.refSymbols.0.name`, 'INVALID')
                                                                        } else {
                                                                            newProfiles = dotProp.delete(editingProfiles, `${editingProfileItemIndex}.legs.1.symbols.${symbolItemIndex}.refSymbols.${refSymbolIndex}`)
                                                                        }
                                                                        this._clearBulkEditingRefSymbols()
                                                                        onChangeEditingProfiles(newProfiles)
                                                                    }}>
                                                                    <FiX />
                                                                </button>}
                                                            </div>
                                                            {![null, undefined, '', 'INVALID'].includes(refSymbol.name) ? (
                                                            <div className='profile-bulk-upload-ref-symbol-row--item--params'>
                                                                {_.map(refSymbolParameters, (paramConfig, paramKey) => {
                                                                    const paramValue = refSymbol.params[paramKey]
                                                                    return (
                                                                        <div className='profile-bulk-upload-ref-symbol-row--item-param' key={paramKey}>
                                                                            <div className='profile-bulk-upload-ref-symbol-row--item-param--title'>{paramConfig.name}</div>
                                                                            {!_.isUndefined(paramValue) && <ProfileParam
                                                                                paramConfig={paramConfig}
                                                                                value={paramValue} 
                                                                                hideArrayLabel
                                                                                onChange={(newValue) => {
                                                                                    const newProfiles = dotProp.set(editingProfiles, `${editingProfileItemIndex}.legs.1.symbols.${symbolItemIndex}.refSymbols.${refSymbolIndex}.params.${paramKey}`, newValue)
                                                                                    onChangeEditingProfiles(newProfiles)
                                                                                }} />}
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>)
                                                            : null}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <button className='profile-bulk-upload-ref-symbol-row--add-ref-symbol-button' 
                                                disabled={started !== false}
                                                onClick={() => {
                                                    const newRefSymbolItem = {
                                                        name: 'INVALID',
                                                        params: createRefSymbolDefaultParameters()
                                                    }
                                                    const newProfiles = dotProp.merge(editingProfiles, `${editingProfileItemIndex}.legs.1.symbols.${symbolItemIndex}.refSymbols`, newRefSymbolItem)
                                                    onChangeEditingProfiles(newProfiles)
                                                }}>{'Add'}</button>
                                        </div>
                                    )
                                })}
                            </div>
                        </td>
                    )
                })}
            </tr>
        )
    }
}

ProfileBulkUploadRefSymbolRow.propTypes = {
    editingProfiles: PropTypes.array.isRequired,
    profileItems: PropTypes.object.isRequired,
    symbolItems: PropTypes.object.isRequired,
    onChangeEditingProfiles: PropTypes.func
}

ProfileBulkUploadRefSymbolRow.defaultProps = {
    editingProfiles: [],
    onChangeEditingProfiles: () => {}
}

function mapStateToProps (state) {
    return {
        profileItems: state.profile.items,
        symbolItems: state.symbol.items
    }
}

export default connect(mapStateToProps)(ProfileBulkUploadRefSymbolRow)