import React, { memo, useState } from 'react'
import useEvent from 'react-use-event-hook'
import { useDispatch } from 'react-redux'
import { useMountedState } from 'react-use'

import PropTypes from 'prop-types'
import _ from 'lodash'

import { createProfileSnapshot, updateProfileSnapshot } from './profileAction'


export const MODE = {
    CREATE_SNAPSHOT: 'CREATE_SNAPSHOT',
    UPDATE_SNAPSHOT: 'UPDATE_SNAPSHOT'
}

const ProfileSnapshotEditor = ({ profileItem, snapshotId, mode, defaultTile, defaultComment, onSaved }) => {

    const dispatch = useDispatch()
    const isMounted = useMountedState()

    const [title, setTitle] = useState(defaultTile || `Snapshot - ${_.random(100, 999)}`)
    const [comment, setComment] = useState(defaultComment)

    const handleClickCreate = useEvent(() => {
        dispatch(createProfileSnapshot({
            title,
            comment,
            data: profileItem
        }))
        .then((body) => {
            if (isMounted()) {
                setTitle(`Snapshot - ${_.random(100, 999)}`)
                setComment('')
            }
            onSaved(body)
        })
    })

    const handleClickUpdate = useEvent(() => {
        if (!_.isEmpty(snapshotId)) {
            dispatch(updateProfileSnapshot({
                id: snapshotId,
                title,
                comment,
                data: profileItem
            }))
            .then((body) => { onSaved(body) })
        }
    })

    return (
        <div className='profile-snapshot-editor'>
            <div className='profile-snapshot-editor--inputs'>
                <div className='profile-snapshot-editor--input-wrapper'>
                    <label>{'Title'}</label>
                    <input
                        type={'text'}
                        spellCheck={false}
                        value={title}
                        autoFocus
                        onChange={(e) => { setTitle(e.target.value) }} />
                </div>
                <div className='profile-snapshot-editor--input-wrapper'>
                    <label>{'Comment'}</label>
                    <textarea
                        spellCheck={false}
                        value={comment}
                        onChange={(e) => { setComment(e.target.value) }} />
                </div>
            </div>
            <div className='profile-snapshot-editor--buttons'>
                <button className='profile-snapshot-editor--save-button'
                    disabled={_.isEmpty(_.get(profileItem, 'id')) || (mode !== MODE.CREATE_SNAPSHOT && _.isEmpty(snapshotId))}
                    onClick={() => {
                        if (mode === MODE.CREATE_SNAPSHOT) {
                            handleClickCreate()
                        } else if (mode === MODE.UPDATE_SNAPSHOT) {
                            handleClickUpdate()
                        }
                    }}>
                    {mode === MODE.CREATE_SNAPSHOT ? 'Create' : 'Save'}
                </button>
            </div>
        </div>
    )
}

ProfileSnapshotEditor.propTypes = {
    profileItem: PropTypes.object.isRequired,
    snapshotId: PropTypes.string,
    mode: PropTypes.oneOf(_.values(MODE)).isRequired,
    defaultTile: PropTypes.string,
    defaultComment: PropTypes.string,
    onSaved: PropTypes.func.isRequired
}

ProfileSnapshotEditor.defaultProps = {
    mode: MODE.CREATE_SNAPSHOT,
    defaultTile: '',
    defaultComment: '',
    onSaved: () => {}
}

export default memo(ProfileSnapshotEditor)