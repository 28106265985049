import React, { useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import useEvent from 'react-use-event-hook'
import { useMountedState } from 'react-use'
import PropTypes from 'prop-types'

import _ from 'lodash'

import SearchSelect from '../common/searchSelect/SearchSelect'
import Popup from '../common/popup/Popup'
import MultipleAccountSelector from '../account/MultipleAccountSelector'

import { getSymbolAttributeByName } from '../../util/symbolUtil'
import { updateSymbolLeverage } from './symbolAction'

function SymbolLeverageUpdateForm ({ symbolItems }) {

    const dispatch = useDispatch()
    const isMounted = useMountedState()

    const [symbol, setSymbol] = useState(null)
    const [accounts, setAccounts] = useState([])
    const [leverage, setLeverage] = useState(1)

    const [pendingCount, setPendingCount] = useState(0)
    const [successCount, setSuccessCount] = useState(0)
    const [errorMessagePerAccount, setErrorMessagePerAccount] = useState({})

    const filteredSymbolItems = useMemo(() => {
        return _.filter(symbolItems, _symbolItem => {
            const { exchangeName } = getSymbolAttributeByName(_symbolItem.symbol_name)
            return ['BNBFUTA', 'BYBIT'].includes(exchangeName)
        })
    }, [symbolItems])

    const symbolOptions = _.map(filteredSymbolItems, _symbolItem => ({
        value: _symbolItem.symbol_name,
        name: _symbolItem.symbol_name
    }))

    const { exchangeName } = getSymbolAttributeByName(symbol)
    const canSet = !_.isEmpty(symbol) && !_.isEmpty(accounts) && Number(leverage) > 0

    const handleClickSetLeverage = useEvent(async () => {
        setPendingCount(_.size(accounts))
        setSuccessCount(0)
        setErrorMessagePerAccount({})

        for await (const accountName of accounts) {
            dispatch(updateSymbolLeverage({ accountName, symbol, leverage }))
                .then(async (response) => {
                    if (isMounted()) {
                        if (response?.status === 200) {
                            setSuccessCount(prevCount => prevCount + 1)
                        } else {
                            const errorMessage = await response.text()
                            setErrorMessagePerAccount(prevState => ({
                                ...prevState,
                                [accountName]: errorMessage
                            }))
                        }
                    }
                })
                .catch((error) => {
                    if (isMounted()) {
                        setErrorMessagePerAccount(prevState => ({
                            ...prevState,
                            [accountName]: error.toString()
                        }))
                    }
                })
                .finally(() => {
                    if (isMounted()) {
                        setPendingCount(prevCount => prevCount - 1)
                    }
                })
            await new Promise(resolve => setTimeout(resolve, 100))
        }
    })

    return (
        <div className='symbol-leverage-update-form'>
            <div className='symbol-leverage-update-form--inputs'>
                <div>
                    <label>{'Symbol'}</label>
                    <div onClick={(e) => { e.stopPropagation() }}>
                        <SearchSelect
                            options={symbolOptions}
                            value={symbol}
                            onChange={(_newOption) => {
                                const { exchangeName: _newExchangeName } = getSymbolAttributeByName(_newOption.value)
                                if (_newExchangeName !== exchangeName) {
                                    setAccounts([])   
                                }
                                setSymbol(_newOption.value)
                            }} />
                    </div>
                </div>
                <div>
                    <label>{'Accounts'}</label>
                    <Popup
                        className='symbol-leverage-update-form--accounts-popup'
                        on={'click'}
                        trigger={
                            <div className='symbol-leverage-update-form--accounts-popup--trigger'>
                                <div>{_.isEmpty(accounts) ? 'None' : accounts.join(', ')}</div>
                                <span>{`(${_.size(accounts)})`}</span>
                            </div>
                        }>
                        <div onClick={(e) => { e.stopPropagation() }}>
                            <MultipleAccountSelector
                                filteredExchangeNames={[exchangeName]}
                                accountNames={accounts}
                                onChangeAccountNames={(newAccounts) => { setAccounts(newAccounts) }} />
                        </div>
                    </Popup>
                </div>
                <div>
                    <label>{'Leverage'}</label>
                    <input
                        type={'number'}
                        min={1}
                        value={leverage}
                        onChange={(e) => {
                            const _newLeverage = e.target.value.trim()
                            setLeverage(!_.isEmpty(_newLeverage) ? Number(_newLeverage) : '')
                        }} />
                </div>
            </div>
            <div className='symbol-leverage-update-form--footer'>
                <div className='symbol-leverage-update-form--footer--messages'>
                    {Number(pendingCount) > 0 && <div className='symbol-leverage-update-form--footer--message pending'>{`Pending Request: ${pendingCount}`}</div>}
                    {Number(successCount) > 0 && <div className='symbol-leverage-update-form--footer--message success'>{`Success Count: ${successCount}`}</div>}
                    {!_.isEmpty(errorMessagePerAccount) && <div className='symbol-leverage-update-form--footer--message failed'>
                        {`Failed Account${_.size(errorMessagePerAccount) > 1 ? 's' : ''}: `}<br />
                        {_.map(errorMessagePerAccount, (errorMessage, account) => {
                            return (
                                <div key={account}>{`${account}: ${errorMessage}`}</div>
                            )
                        })}
                    </div>}
                </div>
                <button
                    disabled={!canSet || Number(pendingCount) > 0}
                    onClick={() => { handleClickSetLeverage() }}>{'Set Leverage'}</button>
            </div>
        </div>
    )
}

SymbolLeverageUpdateForm.propTypes = {
    symbolItems: PropTypes.object.isRequired
}

function mapStateToProps(state) {
    return {
        symbolItems: state.symbol.items
    }
}

export default connect(mapStateToProps)(SymbolLeverageUpdateForm)