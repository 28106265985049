import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import { AiFillEdit } from 'react-icons/ai'
import Popup from '../common/popup/Popup'

import FundingTagEditor from './FundingTagEditor'

export const FundingTag = ({ id='', name='', coins=['BTC'] }) => {
    return {
        id,
        name,
        coins
    }
}

export default class FundingTagItem extends Component {

    constructor (props) {
        super(props)
        this.state = {
            popupCloseId: null
        }
        this.isPopupOpen = false
    }

    componentDidUpdate (prevProps) {
        const { isSavingTags: prevIsSavingTags } = prevProps
        const { isSavingTags } = this.props 
        if (prevIsSavingTags && !isSavingTags && this.isPopupOpen) {
            this.updatePopupCloseId()
        }
    }

    updatePopupCloseId () {
        this.setState({ popupCloseId: uuidv4() })
    }

    render () {
        const { popupCloseId } = this.state
        const { fundingTag, isSavingTags, dragHandle, onClickItem, onClickEditorConfirm, onClickEditorDelete } = this.props
        return (
            <div className='funding-tag-item'>
                <div className='funding-tag-item--button' onClick={() => { 
                    onClickItem() 
                }}>{`${fundingTag.name} (${_.size(fundingTag.coins || [])})`}</div>
                {dragHandle}
                <Popup
                    className='funding-tag-item--popup'
                    closeId={popupCloseId}
                    on={'click'}
                    align={'horizontal'}
                    trigger={<div className='funding-tag-item--trigger'><AiFillEdit /></div>}
                    onOpen={() => { this.isPopupOpen = true }}
                    onClose={() => { this.isPopupOpen = false }}>
                    <FundingTagEditor 
                        defaultName={fundingTag.name} 
                        defaultCoins={fundingTag.coins} 
                        shouldHaveDeleteButton
                        isSavingTags={isSavingTags}
                        onClickConfirmButton={({ name, coins }) => { onClickEditorConfirm(name, coins) }} 
                        onClickDeleteButton={() => { onClickEditorDelete() }} />
                </Popup>
            </div>
        )
    }
}

FundingTagItem.propTypes = {
    fundingTag: PropTypes.object.isRequired,
    isSavingTags: PropTypes.bool.isRequired,
    dragHandle: PropTypes.node,
    onClickItem: PropTypes.func.isRequired,
    onClickEditorConfirm: PropTypes.func.isRequired,
    onClickEditorDelete: PropTypes.func.isRequired
}

FundingTagItem.defaultProps = {
    isSavingTags: false,
    onClickItem: () => {},
    onClickEditorConfirm: () => {},
    onClickEditorDelete: () => {}
}