import dotProps from 'dot-prop-immutable'
import { BULK_TRANSFER_MODES, TransferAccount, TransferItem } from '../account/TokenTransferEditor'
import { UPDATE_LAYOUT_BULK_TRANSFER_WINDOW_ID, CLEAR_LAYOUT_BULK_TRANSFER_WINDOW_ID, UPDATE_LAYOUT_BULK_TRANSFER_WINDOW_CONFIG, CLEAR_LAYOUT_BULK_TRANSFER_WINDOW_CONFIG } from './layoutAction'

const initialBulkTransferWindowConfig = {
    updateId: null,
    token: 'BTC',
    bulkTransferMode: BULK_TRANSFER_MODES.ONE_TO_MANY,
    oneToManyOriginTransferAccount: TransferAccount({}),
    manyToOneDestinationTransferAccount: TransferAccount({}),
    transferItems: [TransferItem({})],
    positionFilterSymbolName: null
}

const initialState = {
    bulkTransferWindowId: null,
    bulkTransferWindowConfig: initialBulkTransferWindowConfig
}

export function layoutReducer (state=initialState, action) {
    switch (action.type) {
        case UPDATE_LAYOUT_BULK_TRANSFER_WINDOW_ID:
            return dotProps.set(state, 'bulkTransferWindowId', action.windowId)

        case CLEAR_LAYOUT_BULK_TRANSFER_WINDOW_ID:
            return dotProps.set(state, 'bulkTransferWindowId', null)

        case UPDATE_LAYOUT_BULK_TRANSFER_WINDOW_CONFIG:
            return dotProps.merge(state, 'bulkTransferWindowConfig', action.config)

        case CLEAR_LAYOUT_BULK_TRANSFER_WINDOW_CONFIG:
            return dotProps.set(state, 'bulkTransferWindowConfig', initialBulkTransferWindowConfig)

        default:
            return state
    }
}