import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { FiCopy, FiCheck } from 'react-icons/fi'
import Popup from '../common/popup/Popup'
import SearchSelect from '../common/searchSelect/SearchSelect'
import SaveButton from '../common/saveButton/SaveButton'
import { copyProfile } from './profileAction'

class ProfileCopyPopup extends Component {

    constructor (props) {
        super(props)
        this.state = {
            name: '',
            user: props.profileItem.user,
            message: null,
            isCopying: false,
            copySuccess: false
        }
    }

    handleClickCreateButton () {
        const { name, user } = this.state
        const { dispatch, profileItem } = this.props
        this.setState({ 
            message: null,
            isCopying: true,
            copySuccess: false
        })
        dispatch(copyProfile(profileItem, name, user)).then((response) => {
            const copySuccess = response && response.status === 200
            if (copySuccess) {
                this.setState({
                    isCopying: false,
                    copySuccess: true,
                    message: 'Copy Profile Success'
                })
            } else if (response) {
                response.text().then((text) => {
                    this.setState({
                        isCopying: false,
                        message: text || 'Unknown Error'
                    })
                })
            } else {
                this.setState({
                    isCopying: false,
                    message: 'No Response'
                })
            }
        })
    }

    renderRow (label, component) {
        return (
            <div className='profile-copy-popup--row'>
                <div className='profile-copy-popup--row--label'>{label}</div>
                <div className='profile-copy-popup--row--main'>{component}</div>
            </div>
        )
    }

    renderName () {
        const { name } = this.state
        const component = (
            <input className='profile-copy-popup--name-input' 
                placeholder={'Type Here'} 
                type={'text'}
                spellCheck={false} 
                autoFocus
                value={name}
                onChange={(e) => { 
                    this.setState({
                        name: e.target.value.replace(/\s+/g, ''),
                        message: null
                    })
                }} />
        )

        return this.renderRow('New Profile Name', component)
    }

    renderUser () {
        const { user } = this.state
        const { profileUsers } = this.props
        const options = profileUsers.map(user => {
            return {
                value: user,
                name: user
            }
        })
        const component = (
            <div className='profile-copy-popup--user-wrapper' onClick={(e) => { e.stopPropagation() }}>
                <SearchSelect className='profile-copy-popup--user' 
                    options={options}
                    value={user} 
                    onChange={(newOption) => {
                        this.setState({
                            user: newOption.value,
                            message: null
                        })
                    }} />
            </div>
        )
        return this.renderRow('User', component)
    }

    render () {
        const { name, user, message, isCopying, copySuccess } = this.state
        const { triggerClassName, profileItem } = this.props 
        return (
            <Popup className='profile-copy-popup'
                on={'click'}
                margin={0}
                trigger={
                    <button className={'profile-copy-popup--trigger' + (triggerClassName ? ` ${triggerClassName}` : '')}
                        onDoubleClick={(e) => { e.stopPropagation() }}><FiCopy /></button>
                }
                onOpen={() => {
                    this.setState({
                        name: '',
                        user: profileItem.user,
                        message: null,
                        isCopying: false,
                        copySuccess: false
                    })
                }}>
                <div className='profile-copy-popup--body' onDoubleClick={(e) => { 
                    e.preventDefault()
                    e.stopPropagation() }}>
                    <div className='profile-copy-popup--title'>{'Copy to a New Proifle'}</div>
                    <div className='profile-copy-popup--main'>
                        {this.renderName()}
                        {this.renderUser()}
                    </div>
                    {message && <div className='profile-copy-popup--message'>
                        {copySuccess && <FiCheck />}
                        {message}
                    </div>}
                    <div className='profile-copy-popup--footer'>
                        <SaveButton className='profile-copy-popup--create-button'
                            text={'Create'}
                            isSavingText={'Copying...'}
                            isSavedText={'Success'}
                            isSaving={isCopying}
                            disabled={!_.isString(name) || name.trim().length === 0 || _.isNull(user)}
                            onClick={() => { this.handleClickCreateButton() }} />
                    </div>
                </div>
            </Popup>
        )
    }
}

ProfileCopyPopup.propTypes = {
    dispatch: PropTypes.func.isRequired,
    triggerClassName: PropTypes.string,
    profileItem: PropTypes.object.isRequired,
    profileUsers: PropTypes.array.isRequired
}

function mapStateToProps (state, ownProps) {
    const profileUsers = _.reduce(state.profile.items, (result, item) => {
        if (item.hostname === _.get(ownProps, 'profileItem.hostname')) {
            result.push(item.user)
        }
        return result
    }, [])

    return {
        profileUsers: _.uniq(profileUsers)
    }
}

export default connect(mapStateToProps)(ProfileCopyPopup)