import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import $ from 'jquery'

import { IoIosArrowDown } from 'react-icons/io'
import { MdClose } from 'react-icons/md'

export default class SearchSelect extends Component {

    constructor (props) {
        super(props)
        this.state = {
            focusedOptionIndex: 0,
            inputValue: '',
            showOptions: false
        }
        this.searchSelect = null
        this.renderedOptions = []

        this.handleClickBody = this.handleClickBody.bind(this)
        this.handleKeyDownWindow = this.handleKeyDownWindow.bind(this)
    }

    componentDidMount () {
        const {focusedOptionIndex} = this.state
        const focusedOption = this.renderedOptions[focusedOptionIndex]
        const bodyNode = $(this.searchSelect).closest('body')[0]
        const window = this.getWindow()
        if (focusedOption) {
            focusedOption.node.scrollIntoView(false)
        }
        if (bodyNode) {
            bodyNode.addEventListener('click', this.handleClickBody)
        }
        if (window) {
            window.addEventListener('keydown', this.handleKeyDownWindow)
        }
    }

    componentWillUnmount () {
        const bodyNode = $(this.searchSelect).closest('body')[0]
        const window = this.getWindow()
        if (bodyNode) {
            bodyNode.removeEventListener('click', this.handleClickBody)
        }
        if (window) {
            window.removeEventListener('keydown', this.handleKeyDownWindow)
        }
    }

    getWindow () {
        return this.searchSelect && this.searchSelect.ownerDocument
            ? (this.searchSelect.ownerDocument.defaultView || this.searchSelect.ownerDocument.parentWindow)
            : null  
    }

    handleClickBody (e) {
        const { showOptions } = this.state
        if (showOptions && !_.isNil(this.searchSelect) && !_.isNil(e) && !this.searchSelect.contains(e.target)) {
            this.setState({
                showOptions: false
            })
        }
    }

    handleKeyDownWindow (e) {
        const {focusedOptionIndex, showOptions} = this.state
        const {options} = this.props
        if (showOptions && ['ArrowDown', 'ArrowUp'].includes(e.key)) {
            const newIndex = e.key === 'ArrowDown' ? Math.min(focusedOptionIndex + 1, options.length - 1) : Math.max(focusedOptionIndex - 1, 0)
            const newFocusedOption = this.renderedOptions[newIndex]
            this.setState({
                focusedOptionIndex: newIndex
            })
            if (newFocusedOption && newFocusedOption.node) {
                newFocusedOption.node.scrollIntoView(true)
            }
        } else if (showOptions && e.key === 'Enter') {
            const newOption = this.renderedOptions[focusedOptionIndex]
            if (newOption) {
                this.handleSelectOption(newOption.option)
            }
        }
    }

    handleSelectOption (option, index) {
        const { focusedOption } = this.state
        const { onChange, hideOptionsOnSelect} = this.props
        this.setState({ 
            option: option,
            showOptions: !hideOptionsOnSelect,
            focusedOptionIndex: !_.isNil(index) ? index : focusedOption
        })
        if (_.isFunction(onChange)) {
            onChange(option)
        }
    }

    render () {
        const { className, options, value, optionPosition, optionsMaxHeight, placeholder, 
            hideSearchBar, hasClearButton, currentOptionNamePostfix, disabled, 
            shouldApplyOptionValueToClassName, shouldHighlightInvalidValue, supplementaryComponent, onClickClearButton } = this.props
        const { focusedOptionIndex, showOptions, inputValue } = this.state
        const selectedOption = _.find(options, option => option.value === value)
        const filteredOptions = inputValue.trim().length === 0 ? options : options.filter(option => option.name.toLowerCase().includes(inputValue.toLowerCase()))
        const showClearButton = showOptions && hasClearButton && _.find(options, option => option.value === value)
        this.renderedOptions = []

        return (
            <div className={'search-select' + (className ? ` ${className}` : '')}
                ref={(node) => { this.searchSelect = node }}>
                <div className={'search-select--current-option' + (disabled ? ' not-clickable' : '') + (shouldHighlightInvalidValue && !_.isNil(value) && _.isNil(selectedOption) ? ' invalid-value' : '')}
                    onClick={(e) => { 
                        if (!disabled && !(e.metaKey || e.ctrlKey || e.shiftKey)) {
                            this.setState({ 
                                showOptions: !showOptions,
                                focusedOptionIndex: _.findIndex(filteredOptions, option => option.value === value)
                            })
                        }
                    }}>
                    {!_.isNil(value)
                        ? <span className='search-select--current-option--name'>{(selectedOption ? selectedOption.name : value) + (currentOptionNamePostfix ? currentOptionNamePostfix : '')}</span> 
                        : <span className='search-select--placeholder'>{placeholder || 'Search'}</span>}
                    {!showClearButton && !disabled && <IoIosArrowDown className='search-select--input-icon vertical-centered' />}
                    {showClearButton && <button className='search-select--clear-button vertical-centered' onClick={() => {
                        if (_.isFunction(onClickClearButton)) {
                            onClickClearButton()
                        }
                    }}><MdClose /></button>}
                </div>
                {showOptions && <div className={'search-select--body' + (optionPosition ? ` ${optionPosition}` : '')}>
                    {showOptions &&
                    <div className={'search-select--options-wrapper'}>
                        {!hideSearchBar && <input className='search-select--search-input'
                            placeholder={'Search'}
                            value={inputValue} 
                            autoFocus
                            spellCheck={false}
                            onChange={(e) => { this.setState({ 
                                inputValue: e.target.value,
                                focusedOptionIndex: 0
                            }) }} />}
                        <div className={'search-select--options' + (!hideSearchBar && optionPosition === 'top' ? ' move-up' : '')} style={{
                            maxHeight: optionsMaxHeight ? `${optionsMaxHeight}px` : 'auto'
                        }}>
                            {!_.isEmpty(filteredOptions) ? filteredOptions.map((option, index) => {
                                return (
                                    <div className={'search-select--option' + (option.disabled ? ' disabled' : '') + (focusedOptionIndex === index ? ' focused-option' : '') + (shouldApplyOptionValueToClassName ? ` ${option.value}` : '')} 
                                        key={index}
                                        ref={(node) => { this.renderedOptions[index] = {
                                            option: option,
                                            node: node
                                        }}}
                                        onClick={() => { 
                                            if (!option.disabled) {
                                                this.handleSelectOption(option, index) 
                                            }
                                        }}>{option.name}</div>
                                )
                            }) : <div className='search-select--empty-message'>{'No Matched Results'}</div>}
                        </div>
                    </div>}
                    {!_.isNil(supplementaryComponent) && <div className='search-select--supplement'>{supplementaryComponent}</div>}
                </div>}

            </div>
        )
    }
}

SearchSelect.propTypes = {
    className: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.any, // unique
        name: PropTypes.string,
        disabled: PropTypes.bool
    })).isRequired,
    value: PropTypes.any,
    optionPosition: PropTypes.oneOf(['top', 'bottom']),
    optionsMaxHeight: PropTypes.number,
    placeholder: PropTypes.string,
    hideSearchBar: PropTypes.bool,
    hasClearButton: PropTypes.bool,
    disabled: PropTypes.bool,
    currentOptionNamePostfix: PropTypes.string,
    hideOptionsOnSelect: PropTypes.bool,
    shouldApplyOptionValueToClassName: PropTypes.bool,
    shouldHighlightInvalidValue: PropTypes.bool,
    supplementaryComponent: PropTypes.node,
    onClickClearButton: PropTypes.func,
    onChange: PropTypes.func.isRequired
}

SearchSelect.defaultProps = {
    options: [],
    optionPosition: 'bottom',
    hideSearchBar: false,
    disabled: false,
    hideOptionsOnSelect: true,
    shouldApplyOptionValueToClassName: false,
    shouldHighlightInvalidValue: false,
    onClickClearButton: () => {},
    onChange: () => {}
}
