import { batch } from 'react-redux'
import { multicall, watchAccount } from '@wagmi/core'
import { erc20ABI } from 'wagmi'
import { formatUnits, formatEther, isAddress } from 'viem'
import _ from 'lodash'

import { selectBlockchainToken, selectBlockchainTokens, selectWallet } from './blockchainReducer'
import { getDefaultChainId, publicClient } from '../../wagmiConfig'
import { parseABI } from './blockchainUtil'
import { ABI as SYNTHETIX_PERPS_MARKET_ABI } from './ABIs/PerpsV2MarketConsolidated'
import { SYNTHETIX_PERPS_ADDRESSES } from './blockchainConfig'

export const RESET_BLOCKCHAIN_ACCOUNT_DATA = 'RESET_BLOCKCHAIN_ACCOUNT_DATA'
export const UPDATE_BLOCKCHAIN_WALLET = 'UPDATE_BLOCKCHAIN_WALLET'
export const UPDATE_BLOCKCHAIN_TOKEN = 'UPDATE_BLOCKCHAIN_TOKEN'
export const UPDATE_BLOCKCHAIN_TOKEN_BALANCE = 'UPDATE_BLOCKCHAIN_TOKEN_BALANCE'
export const UPDATE_BLOCKCHAIN_VAULT_SYNTHETIX_PERPS = 'UPDATE_BLOCKCHAIN_VAULT_SYNTHETIX_PERPS'

let _unwatchAccount = null

export function subscribeAccount () {
    return (dispatch, getState) => {
        if (_.isNil(_unwatchAccount)) {
            _unwatchAccount = watchAccount(account => {
                const { address: walletAddress } = selectWallet(getState()) || {}
                const newWalletAddress = account.address
                const isAddressChanged = newWalletAddress !== walletAddress
                if (isAddressChanged && !_.isEmpty(walletAddress)) {
                    dispatch({ type: RESET_BLOCKCHAIN_ACCOUNT_DATA })
                }
                dispatch({
                    type: UPDATE_BLOCKCHAIN_WALLET,
                    payload: {
                        address: account.address,
                        connector: _.pick(account.connector, ['id', 'name', 'ready']),
                        isConnected: account.isConnected,
                        status: account.status
                    }
                })
                if (isAddressChanged && !_.isEmpty(newWalletAddress)) {
                    // TO DO
                }
            })
        }
    }
}

export function unsubscribeAccount () {
    if (_.isFunction(_unwatchAccount)) {
        _unwatchAccount()
        _unwatchAccount = null
    }
}

export function fetchTokensIfNeed (tokens=[]) {
    return async (dispatch, getState) => {
        const blockchainTokens = selectBlockchainTokens(getState())
        const tokensToFetch = _.difference(tokens, blockchainTokens)
        const chainId = getDefaultChainId()

        let results = null
        if (!_.isEmpty(tokensToFetch)) {
            try {
                const contractCalls = []
                _.forEach(tokensToFetch, address => {
                    console.log(address)
                    contractCalls.push({
                        address,
                        abi: erc20ABI,
                        chainId,
                        functionName: 'decimals',
                        args: [],
                        onSuccess: (result) => {
                            dispatch({
                                type: UPDATE_BLOCKCHAIN_TOKEN,
                                address,
                                data: { decimals: result }
                            })
                        }
                    }, {
                        address,
                        abi: erc20ABI,
                        chainId,
                        functionName: 'symbol',
                        args: [],
                        onSuccess: (result) => {
                            dispatch({
                                type: UPDATE_BLOCKCHAIN_TOKEN,
                                address,
                                data: { symbol: result }
                            })
                        }
                    }, {
                        address,
                        abi: erc20ABI,
                        chainId,
                        functionName: 'name',
                        args: [],
                        onSuccess: (result) => {
                            dispatch({
                                type: UPDATE_BLOCKCHAIN_TOKEN,
                                address,
                                data: { name: result }
                            })
                        }
                    })
                })
                
                results = await multicall({
                    contracts: _.map(contractCalls, call => _.pick(call, ['address', 'abi', 'chainId', 'functionName', 'args'])),
                    allowFailure: false
                })
    
                // Parse multicall result and update redux store
                batch(() => {
                    _.forEach(results, (result, index) => {
                        const { onSuccess } = contractCalls[index]
                        if (_.isFunction(onSuccess)) {
                            onSuccess(result)
                        }
                    })
                })
            } catch (error) {
                console.error('fetchTokensIfNeed error: ', error)
            }
        }
        return results
    }
}

export function fetchVaultOnChainDynamicData (vaultAddress='') {
    return async (dispatch) => {
        if (isAddress(vaultAddress)) {
            const chainId = getDefaultChainId()
            try {
                const synthetixPerpsMarketAbi = parseABI(SYNTHETIX_PERPS_MARKET_ABI)
                const contractCalls = []
                _.forEach(_.values(SYNTHETIX_PERPS_ADDRESSES), address => {
                    contractCalls.push({
                        address,
                        abi: synthetixPerpsMarketAbi,
                        chainId,
                        functionName: 'accessibleMargin',
                        args: [vaultAddress],
                        onSuccess: (result) => {
                            dispatch({
                                type: UPDATE_BLOCKCHAIN_VAULT_SYNTHETIX_PERPS,
                                vaultAddress,
                                perpsAddress: address,
                                payload: { accessibleMargin: formatEther(result[0]) }
                            })
                        }
                    }, {
                        address,
                        abi: synthetixPerpsMarketAbi,
                        chainId,
                        functionName: 'remainingMargin',
                        args: [vaultAddress],
                        onSuccess: (result) => {
                            dispatch({
                                type: UPDATE_BLOCKCHAIN_VAULT_SYNTHETIX_PERPS,
                                vaultAddress,
                                perpsAddress: address,
                                payload: { remainingMargin: formatEther(result[0]) }
                            })
                        }
                    })
                })
    
                const results = await multicall({
                    contracts: _.map(contractCalls, call => _.pick(call, ['address', 'abi', 'chainId', 'functionName', 'args']))
                })
    
                // Parse multicall result and update store
                batch(() => {
                    _.forEach(results, (callResult, index) => {
                        const { result, status, error } = callResult
                        const { onSuccess } = contractCalls[index]
                        if (status === 'success' && _.isFunction(onSuccess)) {
                            onSuccess(result)
                        } else if (!_.isNil(error)) {
                            console.error(error)
                        }
                    })
                })
    
                return results
            } catch (error) {
                console.error('fetchVaultOnChainDynamicData error: ', error)
            }
        }
    }
}

export function fetchTokenBalance (tokenAddress, account) {
    return async (dispatch, getState) => {
        const { decimals } = selectBlockchainToken(getState(), tokenAddress) || {}
        if (decimals > 0 && !_.isEmpty(account)) {
            const balance = await publicClient({}).readContract({
                address: tokenAddress,
                abi: erc20ABI,
                functionName: 'balanceOf',
                args: [account]
            })
            dispatch({
                type: 'UPDATE_BLOCKCHAIN_TOKEN_BALANCE',
                token: tokenAddress,
                account,
                balance: formatUnits(balance, decimals)
            })
        }
    }
}