import _ from 'lodash'
import { BigNumber, utils } from 'ethers'
import {  writeContract, waitForTransaction } from '@wagmi/core'
const { defaultAbiCoder, keccak256 } = utils

/**
 * @param {object} param
 * @param {object} param.provider - Provider from ethers
 * @param {string} param.contract - Contract Address 
 * @param {number} param.path - Array of slot position, mapping keys and array offsets
 * @param {('O'|'M'|'A')} param.pathType - Lookup type for each value in `path`
*      * O - offset. This parameter can be omitted if it's a single letter O.
*      * M - mapping key.
*      * A - dynamic array index.  
*/
export const readStorage = async ({ provider, contract='', path=[], pathType='O' }) => {
    let currPos = BigNumber.from(0)
    for (let i = 0; i < path.length; i++) {
        switch (pathType[i]) {
            case 'O': {
                currPos = currPos.add(path[i])
                break
            }
            case 'M': {
                currPos = BigNumber.from(
                    keccak256(defaultAbiCoder.encode(['uint', 'uint'], [path[i], currPos]))
                )
                break
            }
            case 'A': {
                currPos = BigNumber.from(
                    keccak256(defaultAbiCoder.encode(['uint'], [currPos]))
                ).add(path[i])
                break
            }
            default:
                console.error(`Unknown character in pathType`)
        }
    }
    return await provider.getStorageAt(contract, currPos)
}

export const sendTransactionWithWallet = async ({ address, abi, functionName, args=[], ...others }) => {
    try {
        const { hash } = await writeContract({
            address,
            abi,
            functionName,
            args,
            ...others
        })
        if (!_.isEmpty(hash)) {
            const transactionReceipt = await waitForTransaction({ hash })
            return transactionReceipt
        }
    } catch (error) {
        console.error('sendTransactionWithWallet error: ', error)
        const text = _.get(error, 'shortMessage') || _.get(error, 'metaMessage') || _.get(error, 'details') || _.toString(error)
        throw new Error(text)
    }
}