import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import dotProp from 'dot-prop-immutable'
import _ from 'lodash'

import { createExposureAdjustment, deleteExposureAdjustment, fetchExposureAdjustments, fetchExposures, updateExposureAdjustment } from './tradingAction'
import { toNumberInputValue } from '../../util/util'
import moment from 'moment'
import SearchSelect from '../common/searchSelect/SearchSelect'
import { getPortfolioNames } from '../../util/accountUtil'
import { FaPlus } from 'react-icons/fa'

const EDITING_MODE = {
    CREATE: 'CREATE',
    UPDATE: 'UPDATE'
}

const ExposureAdjustmentItem = ({ _id='', coin='', amount=0, comment='', createdBy='', timestamp='', isUpdating=false }) => {
    return {
        _id,
        coin,
        amount,
        comment,
        createdBy,
        timestamp,
        isUpdating
    }
}

class ExposureAdjustmentTable extends Component {
    constructor (props) {
        super(props)
        this.state = {
            isFetching: false,
            editingAdjustment: {
                itemToCreate: null,
                itemsToUpdate: {}
            }
        }
        this._mounted = false
        this.fetchDataInterval = null
    }

    componentDidMount () {
        this._mounted = true
        this._getData()
        this.fetchDataInterval = setInterval(() => {
            if (this._mounted) {
                this._getData()
            }
        }, 30000)
    }

    componentWillUnmount () {
        this._mounted = false
        if (this.fetchDataInterval) {
            window.clearInterval(this.fetchDataInterval)
        }
    }

    _getData () {
        const { dispatch } = this.props
        const { isFetching } = this.state
        if (!isFetching) {
            this.setState({ isFetching: true })
            dispatch(fetchExposureAdjustments())
            .finally(() => {
                if (this._mounted) {
                    this.setState({ isFetching: false })
                }
            })
        }
    }

    Row ({ adjustmentItem, mode, onChange=()=>{}, onClickCreate=()=>{}, onClickUpdate=()=>{}, onClickCancel=()=>{}, onClickEdit=()=>{}, onClickDelete=()=>{} }) {
        const { portfolioName, coin, amount, comment, createdBy, timestamp, isUpdating } = adjustmentItem
        const portfolioNames = _.concat(getPortfolioNames(), 'treasury')
        const portfolioOptions = _.map(portfolioNames, name => {
            return {
                value: name,
                name
            }
        })
        return (
            <tr className='exposure-adjustment-table--row'>
                <td>
                    {mode ? <SearchSelect 
                        hideSearchBar
                        value={portfolioName}
                        options={portfolioOptions} 
                        onChange={(newOption) => { 
                            onChange(dotProp.set(adjustmentItem, 'portfolioName', newOption.value))
                        }} />
                    : portfolioName}          
                </td>
                <td>
                    {mode ? <input type={'string'}
                        spellCheck={false}
                        value={coin}
                        placeholder={'BTC'}
                        onChange={(e) => { 
                            onChange(dotProp.set(adjustmentItem, 'coin', e.target.value.toUpperCase()))
                        }} />
                    : coin}
                </td>
                <td>
                    {mode ? <input type={'number'}
                        disabled={_.isNil(mode)} 
                        value={amount} 
                        onChange={(e) => {
                            onChange(dotProp.set(adjustmentItem, 'amount', toNumberInputValue(e.target.value)))
                        }} />
                    : amount }
                </td>
                <td className='exposure-adjustment-table--row--comment'>
                    {mode ? <textarea
                        disabled={_.isNil(mode)}
                        value={comment}
                        spellCheck={false}
                        onChange={(e) => {
                            onChange(dotProp.set(adjustmentItem, 'comment', e.target.value))
                        }} />
                    : comment}
                </td>
                <td>{createdBy}</td>
                <td>{timestamp ? moment(timestamp).format('YYYY-MM-DD HH:mm:ss') : ''}</td>
                <td className='exposure-adjustment-table--row--buttons'>
                    {mode === EDITING_MODE.CREATE && 
                    <button className='exposure-adjustment-table--row--button create' 
                        disabled={isUpdating || coin.trim().length === 0}
                        onClick={() => { onClickCreate() }}>{'CREATE'}</button>}
                    {mode === EDITING_MODE.UPDATE && 
                    <button className='exposure-adjustment-table--row--button update' 
                        disabled={isUpdating}
                        onClick={() => { onClickUpdate() }}>{'UPDATE'}</button>}
                    {!_.isNil(mode) && <button className='exposure-adjustment-table--row--button cancel' onClick={() => { onClickCancel() }}>{'CANCEL'}</button>}
                    {_.isNil(mode) && <button className='exposure-adjustment-table--row--button edit' onClick={() => { onClickEdit() }}>{'EDIT'}</button>}
                    {_.isNil(mode) && 
                    <button className='exposure-adjustment-table--row--button delete' 
                        disabled={isUpdating}
                        onClick={() => { onClickDelete() }}>{'DELETE'}</button>}
                </td>
            </tr>
        )
    }

    render () {
        const { dispatch, exposureAdjustments, auth } = this.props
        const { editingAdjustment } = this.state
        const sortedExposureAdjustments = _.sortBy(exposureAdjustments, adjustment => -moment(adjustment.timestamp).valueOf())
        return (
            <div className='exposure-adjustment-table'>
                <button className={'exposure-adjustment-table--add-button'} 
                    disabled={!_.isNil(editingAdjustment.itemToCreate)}
                    onClick={() => {
                        this.setState({
                            editingAdjustment: dotProp.set(editingAdjustment, 'itemToCreate', ExposureAdjustmentItem({
                                coin: '',
                                amount: 0,
                                comment: '',
                                createdBy: auth.username  
                            }))
                        })
                    }}>
                    <FaPlus />
                    {'Add Record'}
                </button>
                <table>
                    <thead>
                        <tr>
                            <th>{'PORTFOLIO'}</th>
                            <th>{'COIN'}</th>
                            <th>{'AMOUNT'}</th>
                            <th>{'COMMENT'}</th>
                            <th>{'CREATED BY'}</th>
                            <th>{'LAST UPDATE'}</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {!_.isEmpty(editingAdjustment.itemToCreate) && this.Row({
                            adjustmentItem: editingAdjustment.itemToCreate,
                            mode: EDITING_MODE.CREATE,
                            onChange: (newItem) => { this.setState({ editingAdjustment: dotProp.set(editingAdjustment, 'itemToCreate', newItem) }) },
                            onClickCancel: () => { this.setState({ editingAdjustment: dotProp.set(editingAdjustment, 'itemToCreate', null) }) },
                            onClickCreate: () => { 
                                this.setState({ editingAdjustment: dotProp.set(editingAdjustment, 'itemToCreate.isUpdating', true) })
                                dispatch(createExposureAdjustment(editingAdjustment.itemToCreate))
                                .then(() => {
                                    if (this._mounted) {
                                        this.setState({ editingAdjustment: dotProp.set(editingAdjustment, 'itemToCreate', null) })
                                    }
                                })
                                .catch((error) => {
                                    console.log('ExposureAdjustmentTable createExposureAdjustment Error', error)
                                    if (this._mounted) {
                                        this.setState({ editingAdjustment: dotProp.set(editingAdjustment, 'itemToCreate.isUpdating', false) })
                                    }
                                })
                                .finally(() => { 
                                    if (this._mounted) {
                                        this._getData() 
                                        dispatch(fetchExposures())
                                    }
                                })
                            }
                        })}
                        {_.map(sortedExposureAdjustments, adjustmentItem => {
                            const { _id } = adjustmentItem
                            return (
                                <Fragment key={_id}>
                                    {this.Row({
                                        adjustmentItem: editingAdjustment.itemsToUpdate[_id] || adjustmentItem,
                                        mode: !_.isEmpty(editingAdjustment.itemsToUpdate[_id]) && !editingAdjustment.itemsToUpdate[_id].isUpdating ? EDITING_MODE.UPDATE : null,
                                        onChange: (newItem) => { this.setState({ editingAdjustment: dotProp.set(editingAdjustment, `itemsToUpdate.${_id}`, newItem) }) },
                                        onClickCancel: () => { this.setState({ editingAdjustment: dotProp.delete(editingAdjustment, `itemsToUpdate.${_id}`) }) },
                                        onClickEdit: () => { this.setState({ editingAdjustment: dotProp.set(editingAdjustment, `itemsToUpdate.${_id}`, adjustmentItem) }) },
                                        onClickUpdate: () => {
                                            this.setState({ editingAdjustment: dotProp.set(editingAdjustment, `itemsToUpdate.${_id}.isUpdating`, true) })
                                            dispatch(updateExposureAdjustment(editingAdjustment.itemsToUpdate[_id]))
                                            .then(() => {
                                                if (this._mounted) {
                                                    this.setState({ editingAdjustment: dotProp.delete(editingAdjustment, `itemsToUpdate.${_id}`) })
                                                }
                                            })
                                            .catch((error) => {
                                                console.log('ExposureAdjustmentTable updateExposureAdjustment Error', error)
                                                if (this._mounted) {
                                                    this.setState({ editingAdjustment: dotProp.set(editingAdjustment, `itemsToUpdate.${_id}.isUpdating`, false) })
                                                }
                                            })
                                            .finally(() => { 
                                                if (this._mounted) {
                                                    this._getData()
                                                    dispatch(fetchExposures())
                                                }
                                            })
                                        },
                                        onClickDelete: () => {
                                            this.setState({ editingAdjustment: dotProp.set(editingAdjustment, `itemsToUpdate.${_id}`, { ...adjustmentItem, isUpdating: true }) })
                                            dispatch(deleteExposureAdjustment(_id))
                                            .finally(() => { 
                                                if (this._mounted) {
                                                    this.setState({ editingAdjustment: dotProp.delete(editingAdjustment, `itemsToUpdate.${_id}`) })
                                                    this._getData() 
                                                    dispatch(fetchExposures())
                                                }
                                            })
                                        }
                                    })}
                                </Fragment>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
}

ExposureAdjustmentTable.propTypes = {
    dispatch: PropTypes.func.isRequired,
    exposureAdjustments: PropTypes.array.isRequired,
    auth: PropTypes.object.isRequired
}

function mapStateToProps (state) {
    return {
        exposureAdjustments: state.trading.exposureAdjustments,
        auth: state.auth
    }
}

export default connect(mapStateToProps)(ExposureAdjustmentTable)