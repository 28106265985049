import _ from 'lodash'
import dotProp from 'dot-prop-immutable'
import { UPDATE_EXCHANGE_ITEMS } from './exchangeAction'

const initialState = {
    items: {}
}

export function exchangeReducer (state = initialState, action) {
    switch(action.type) {
        case UPDATE_EXCHANGE_ITEMS:
            return dotProp.set(state, 'items', _.keyBy(action.exchanges, 'id'))

        default:
            return state
    }
}