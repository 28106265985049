import BigNumber from 'bignumber.js'
import _ from 'lodash'
import { ACCOUNT_TYPES } from './TokenTransferEditor'

export const BALANCE_TYPES = {
    CROSS: 'CROSS', // Expected: These are OKEx balance details
    CROSS_MARGIN: 'CROSS_MARGIN', // Expected: These are BINANCE balance details
    FUND: 'FUND', // Expected: These are BYBIT balance details
    FUNDING: 'FUNDING', // Expected: These are BITFINEX balance details
    FUTURE: 'FUTURE',
    SPOT: 'SPOT',
    SWAP: 'SWAP',
    WALLET: 'WALLET'
}

export const CONFIG_PER_EXCHANGE = {
    BITFINEX: {
        alias: null,
        marginPool: {
            balanceType: BALANCE_TYPES.FUNDING,
            isRiskFree: true
        },
        crossMarginGroupAssets: {
            BITFINEX_USD_cross: {
                balanceTypes: [BALANCE_TYPES.SWAP],
                coins: ['USDT', 'BTC']
            }
        },
        portfolioMarginAssets: {
            USD: {
                balanceTypes: [BALANCE_TYPES.SWAP],
                coins: ['USDT', 'BTC']
            }
        },
        credit: {
            accounts: [],
            assets: {
                balanceTypes: [],
                coins: []
            }
        },
        isolatedMarginBalanceType: null,
        sharedCollateralAccounts: ['bitfinex_prop', 'bitfinex_trader001', 'bitfinex_trader002'],
        accountTypePerBalance: {}
    },
    BINANCE: {
        alias: 'BNBFUTA',
        marginPool: {
            balanceType: BALANCE_TYPES.SPOT,
            isRiskFree: true
        },
        crossMarginGroupAssets: {
            BNBFUTA_USD_portfolio: {
                balanceTypes: [BALANCE_TYPES.CROSS_MARGIN, BALANCE_TYPES.FUTURE, BALANCE_TYPES.SWAP],
                coins: ['USDT', 'USDC', 'BTC', 'ETH']
            },
            BNBFUTA_USDM_cross: {
                balanceTypes: [BALANCE_TYPES.SWAP],
                coins: ['USDT', 'USDC']
            },
            BNBFUTA_BTC_cross: {
                balanceTypes: [BALANCE_TYPES.FUTURE],
                coins: ['BTC']
            },
            BNBFUTA_ETH_cross: {
                balanceTypes: [BALANCE_TYPES.FUTURE],
                coins: ['ETH']
            },
            BNBFUTA_SOL_cross: {
                balanceTypes: [BALANCE_TYPES.FUTURE],
                coins: ['SOL']
            }
        },
        portfolioMarginAssets: {
            USD: {
                balanceTypes: [BALANCE_TYPES.CROSS_MARGIN, BALANCE_TYPES.FUTURE, BALANCE_TYPES.SWAP],
                coins: ['USDT', 'USDC', 'BTC', 'ETH']
            }
        },
        credit: {
            accounts: [],
            assets: {
                balanceTypes: [],
                coins: []
            }
        },
        isolatedMarginBalanceType: null,
        sharedCollateralAccounts: [],
        accountTypePerBalance: {
            [BALANCE_TYPES.SPOT]: ACCOUNT_TYPES.SPOT.key,
            [BALANCE_TYPES.CROSS_MARGIN]: ACCOUNT_TYPES.CROSS_MARGIN.key,
            [BALANCE_TYPES.FUTURE]: ACCOUNT_TYPES.BINANCE_COIN_FUTURES.key,
            [BALANCE_TYPES.SWAP]: ACCOUNT_TYPES.BINANCE_USD_FUTURES.key
        }
    },
    BYBIT: {
        alias: null,
        marginPool: {
            balanceType: BALANCE_TYPES.FUND,
            isRiskFree: true
        },
        crossMarginGroupAssets: {
            BYBIT_USD_cross: {
                balanceTypes: [BALANCE_TYPES.SWAP],
                coins: ['USDT', 'BTC', 'ETH']
            },
            BYBIT_BTC_cross: {
                balanceTypes: [BALANCE_TYPES.FUTURE],
                coins: ['BTC']
            },
            BYBIT_ETH_cross: {
                balanceTypes: [BALANCE_TYPES.FUTURE],
                coins: ['ETH']
            }
        },
        portfolioMarginAssets: {
            USD: {
                balanceTypes: [BALANCE_TYPES.SWAP],
                coins: ['USDT', 'BTC', 'ETH']
            }
        },
        credit: {
            accounts: [],
            assets: {
                balanceTypes: [BALANCE_TYPES.SWAP],
                coins: ['USDT', 'BTC', 'ETH']
            }
        },
        isolatedMarginBalanceType: null,
        sharedCollateralAccounts: [],
        accountTypePerBalance: {
            [BALANCE_TYPES.FUND]: ACCOUNT_TYPES.BYBIT_FUNDING_ACCOUNT.key,
            [BALANCE_TYPES.FUTURE]: ACCOUNT_TYPES.BYBIT_CONTRACT_ACCOUNT.key,
            [BALANCE_TYPES.SWAP]: ACCOUNT_TYPES.BYBIT_UNIFIED_ACCOUNT.key
        }
    },
    DERIBIT: {
        alias: null,
        marginPool: {
            balanceType: BALANCE_TYPES.SWAP,
            isRiskFree: false
        },
        crossMarginGroupAssets: {
            DERIBIT_USD_cross: {
                balanceTypes: [BALANCE_TYPES.SWAP],
                coins: ['USDT', 'USDC', 'BTC', 'ETH']
            }
        },
        portfolioMarginAssets: {
            BTC: {
                balanceTypes: [BALANCE_TYPES.SWAP],
                coins: ['BTC']
            },
            ETH: {
                balanceTypes: [BALANCE_TYPES.SWAP],
                coins: ['ETH']
            },
            USD: {
                balanceTypes: [BALANCE_TYPES.SWAP],
                coins: ['USDT', 'USDC', 'BTC', 'ETH']
            }
        },
        credit: {
            accounts: [],
            assets: {
                balanceTypes: [],
                coins: []
            }
        },
        isolatedMarginBalanceType: BALANCE_TYPES.SWAP,
        sharedCollateralAccounts: [],
        accountTypePerBalance: {}
    },
    GATE: {
        alias: null,
        marginPool: {
            balanceType: BALANCE_TYPES.SPOT,
            isRiskFree: false
        },
        crossMarginGroupAssets: {
            GATE_BTC_cross: {
                balanceTypes: [BALANCE_TYPES.SWAP],
                coins: ['USDT', 'BTC']
            },
            GATE_USD_cross: {
                balanceTypes: [BALANCE_TYPES.SPOT],
                coins: ['USDT', 'BTC']
            }
        },
        portfolioMarginAssets:  {
            USD: {
                balanceTypes: [BALANCE_TYPES.SPOT],
                coins: ['USDT', 'BTC']
            }
        },
        credit: {
            accounts: [],
            assets: {
                balanceTypes: [BALANCE_TYPES.SPOT, BALANCE_TYPES.SWAP],
                coins: ['USDT', 'BTC']
            }
        },
        isolatedMarginBalanceType: BALANCE_TYPES.FUTURE,
        sharedCollateralAccounts: [],
        accountTypePerBalance: {}
    },
    KRAKEN: {
        alias: null,
        marginPool: {
            balanceType: BALANCE_TYPES.SWAP,
            isRiskFree: false
        },
        crossMarginGroupAssets: {
            KRAKEN_BTC_cross: {
                balanceTypes: [BALANCE_TYPES.FUTURE],
                coins: ['BTC']
            },
            KRAKEN_USDM_cross: {
                balanceTypes: [BALANCE_TYPES.SWAP],
                coins: ['USD', 'USDT', 'USDC', 'BTC', 'ETH']
            }
        },
        portfolioMarginAssets: {},
        credit: {
            accounts: [],
            assets: {
                balanceTypes: [BALANCE_TYPES.SWAP],
                coins: ['USD', 'USDT', 'USDC', 'BTC', 'ETH']
            }
        },
        isolatedMarginBalanceType: null,
        sharedCollateralAccounts: [],
        accountTypePerBalance: {}
    },
    OKEX: {
        alias: null,
        marginPool: {
            balanceType: BALANCE_TYPES.WALLET,
            isRiskFree: true
        },
        crossMarginGroupAssets: {
            OKEX_USD_cross: {
                balanceTypes: [BALANCE_TYPES.CROSS],
                coins: ['USDT', 'USDC', 'BTC', 'ETH']
            },
            OKEX_USD_portfolio: {
                balanceTypes: [BALANCE_TYPES.CROSS],
                coins: ['USDT', 'USDC', 'BTC', 'ETH']
            }
        },
        portfolioMarginAssets: {
            USD: {
                balanceTypes: [BALANCE_TYPES.CROSS],
                coins: ['USDT', 'USDC', 'BTC', 'ETH']
            }
        },
        credit: {
            accounts: [],
            assets: {
                balanceTypes: [],
                coins: []
            }
        },
        isolatedMarginBalanceType: null,
        sharedCollateralAccounts: [],
        accountTypePerBalance: {
            [BALANCE_TYPES.WALLET]: ACCOUNT_TYPES.OKEX_FUNDING_ACCOUNT.key,
            [BALANCE_TYPES.CROSS]: ACCOUNT_TYPES.OKEX_UNIFIED_ACCOUNT.key
        }
    },
    PARADEX: {
        alias: null,
        marginPool: {
            balanceType: BALANCE_TYPES.SWAP,
            isRiskFree: false
        },
        crossMarginGroupAssets: {},
        portfolioMarginAssets: {
            USD: {
                balanceTypes: [BALANCE_TYPES.SWAP],
                coins: ['USDC']
            }
        },
        credit: {
            accounts: ['paradex_prop'],
            assets: {
                balanceTypes: [BALANCE_TYPES.SWAP],
                coins: ['USDC']
            }
        },
        isolatedMarginBalanceType: null,
        sharedCollateralAccounts: [],
        accountTypePerBalance: {}
    },
    PHEMEX: {
        alias: null,
        marginPool: {
            balanceType: BALANCE_TYPES.SPOT,
            isRiskFree: true
        },
        crossMarginGroupAssets: {},
        assetsPerCrossMarginGroup: {},
        portfolioMarginAssets: {},
        credit: {
            accounts: [],
            assets: {
                balanceTypes: [BALANCE_TYPES.SWAP],
                coins: ['USDT', 'BTC', 'ETH']
            }
        },
        isolatedMarginBalanceType: BALANCE_TYPES.SWAP,
        sharedCollateralAccounts: [],
        accountTypePerBalance: {
            [BALANCE_TYPES.SPOT]: ACCOUNT_TYPES.SPOT.key,
            [BALANCE_TYPES.SWAP]: ACCOUNT_TYPES.PHEMEX_CONTRACT.key
        }
    }
}

export const WITHDRAWABLE_AMOUNT_KEYS_PER_BALANCE_TYPE = {
    [BALANCE_TYPES.CROSS]: ['transferable'],
    [BALANCE_TYPES.CROSS_MARGIN]: ['available'],
    [BALANCE_TYPES.FUND]: ['transferable'],
    [BALANCE_TYPES.FUNDING]: ['available'],
    [BALANCE_TYPES.FUTURE]: ['transferable', 'total_avail_balance'],
    [BALANCE_TYPES.SPOT]: ['available'],
    [BALANCE_TYPES.SWAP]: ['transferable', 'total_avail_balance'],
    [BALANCE_TYPES.WALLET]: ['available']
}

export const RISK_TYPES = {
    RISK_FREE_BALANCE: { key: 'RISK_FREE_BALANCE', name: 'Risk Free' },
    POSITION_EFFECTIVE_RATIO: { key: 'POSITION_EFFECTIVE_RATIO', name: 'X-Margin' },
    ACCOUNT_MMR: { key: 'ACCOUNT_MMR', name: 'PMA' },
    CREDIT: { key: 'CREDIT', name: 'Credit' }
}

export const MARGIN_SIDES = {
    COLLECTION: 'COLLECTION',
    REQUIREMENT: 'REQUIREMENT'
}

export const AssetsStruct = ({ balanceTypes=[], coins=[] }) => {
    return {
        balanceTypes,
        coins
    }
}

export const MarginAdjustementStruct = ({ marginSide=MARGIN_SIDES.COLLECTION, exchangeName, accountName, isMainAccount=false, mainAccountName,
    riskType=RISK_TYPES.POSITION_EFFECTIVE_RATIO.key, riskDetail={}, riskAdjustedMarginDelta='0', portfolioCurrency,
    withdrawableBalances=[], acceptableAssets={} }) => {
    return { marginSide, exchangeName, accountName, isMainAccount, mainAccountName,
        riskType, riskDetail, riskAdjustedMarginDelta, portfolioCurrency,
        withdrawableBalances, acceptableAssets
    }
}

export const WithdrawableBalanceStruct = ({ mainAccountName='', accountName='', balanceType='', balanceDetail={}, coin='', isCreditMargin=false,
    withdrawableAmount='', withdrawnAmount='0', decimalPrecision, price }) => {
    return {
        mainAccountName, accountName, balanceType, balanceDetail, coin, isCreditMargin,
        withdrawableAmount, withdrawnAmount, decimalPrecision, price
    }
}

export const CreditDetailStruct = ({ mmr={}, effRatio={} }) => {
    return { mmr, effRatio }
}

export const CreditMarginInfoStruct = ({ exchange, mainAccountName, creditDetail=CreditDetailStruct({}), withdrawableMargin='', accounts=[], assets=AssetsStruct({}) }) => {
    return {
        exchange,
        mainAccountName,
        creditDetail,
        withdrawableMargin,
        accounts,
        assets
    }
}

export const isCreditMarginBalance = ({ creditMarginInfo=CreditMarginInfoStruct({}), accountName='', balanceType='' }) => {
    const { accounts, assets } = creditMarginInfo || {}
    return !_.isEmpty(assets?.balanceTypes) && assets.balanceTypes.includes(balanceType)
        && (_.isEmpty(accounts) || accounts.includes(accountName))
}

export function calculateWithdrawals ({
    withdrawableBalances=[],
    riskAdjustedMarginDelta='',
    creditWithdrawableMargin='',
    coins=[],
    riskType='',
    maxWithdrawableBalanceRate='1'
}) {
    const withdrawalPerCoin = {}
    let totalWithdrawableMargin = '0'
    let totalCreditMarginUsed = '0'
    let remainingRiskAdjustedMargin = riskAdjustedMarginDelta
    let remainingCreditWithdrawableMargin = creditWithdrawableMargin || '0'

    // Filter and sort withdrawableBalances
    const filteredWithdrawableBalances = _.isEmpty(coins) ? withdrawableBalances : _.filter(withdrawableBalances, _withdrawable => coins.includes(_withdrawable.coin))
    const seivedWithdrawableBalances = _.sortBy(filteredWithdrawableBalances, [
        // Prioritize non-credit margin first
        _withdrawable => _withdrawable.isCreditMargin ? 1 : -1,
        // Prioritize higher withdrawable margin (USD) within the same type
        _withdrawable => {
            const { withdrawableAmount, withdrawnAmount, price } = _withdrawable
            return (BigNumber(withdrawableAmount).minus(withdrawnAmount || 0))
                .times(price)
                .negated()
                .toNumber()
        }
    ])

    for (const balance of seivedWithdrawableBalances) {
        const { withdrawableAmount, withdrawnAmount, coin, price, decimalPrecision, isCreditMargin } = balance;

        // Calculate the maximum withdrawable amount based on riskType
        const _rate = riskType === RISK_TYPES.RISK_FREE_BALANCE.key ? 1 : _.clamp(Number(maxWithdrawableBalanceRate), 0, 1)
        const _maxWithdrawableAmount = BigNumber(withdrawableAmount).times(_rate).minus(withdrawnAmount || 0)

        // Calculate the maximum withdrawable margin (USD)
        const _maxWithdrawMargin = _maxWithdrawableAmount.times(price)

        // Determine how much can be withdrawn based on remaining riskAdjustedMargin and creditWithdrawableMargin
        const _finalWithdrawMargin = BigNumber.max(
            0,
            BigNumber.min(
                _maxWithdrawMargin,
                remainingRiskAdjustedMargin,
                isCreditMargin ? remainingCreditWithdrawableMargin : Infinity
            )
        )

        // Calculate the final withdrawable amount in coins
        const _finalWithdrawAmount = BigNumber.min(
            withdrawableAmount,
            _finalWithdrawMargin.div(price)
        ).dp(decimalPrecision, 1)
  
        // Update the total withdrawableMargin and remaining margins
        totalWithdrawableMargin = BigNumber(totalWithdrawableMargin).plus(_finalWithdrawMargin).toString()
        remainingRiskAdjustedMargin = BigNumber(remainingRiskAdjustedMargin).minus(_finalWithdrawMargin).toString()
  
        if (isCreditMargin) {
            totalCreditMarginUsed = BigNumber(totalCreditMarginUsed).plus(_finalWithdrawMargin).toString()
            remainingCreditWithdrawableMargin = BigNumber(remainingCreditWithdrawableMargin).minus(_finalWithdrawMargin).toString()
        }
  
        // Update results
        if (!withdrawalPerCoin[coin]) {
            withdrawalPerCoin[coin] = { withdrawableAmount: '0', withdrawableMargin: '0' }
        }
        withdrawalPerCoin[coin].withdrawableAmount = BigNumber(withdrawalPerCoin[coin].withdrawableAmount).plus(_finalWithdrawAmount).toString()
        withdrawalPerCoin[coin].withdrawableMargin = BigNumber(withdrawalPerCoin[coin].withdrawableMargin).plus(_finalWithdrawMargin).toString()
  
        // Stop withdrawing if riskAdjustedMargin is fully used
        if (BigNumber(remainingRiskAdjustedMargin).lte(0)) break
    }
  
    return {
        withdrawalPerCoin,
        totalWithdrawableMargin,
        totalCreditMarginUsed,
        remainingRiskAdjustedMargin,
        remainingCreditWithdrawableMargin
    }
}