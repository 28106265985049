import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Popup from '../common/popup/Popup'
import Checkbox from '../common/checkbox/Checkbox'

import { isMetSearchStringCriteria } from '../../util/util'

class PortfolioAccountSelector extends Component {
    constructor (props) {
        super(props)
        this.state = {
            searchString: ''
        }
        this.searchInputNode = null
    }

    render () {
        const { className, accountItems, accountItemOptions, selectedAccountNames, onChangeSelectedAccountNames } = this.props
        const { searchString } = this.state
        const accountNameOptions = _.union(_.map(accountItemOptions, accountItem => accountItem.account_name), selectedAccountNames)
        const filteredAccountNameOptions = _.filter(accountNameOptions, accountName => isMetSearchStringCriteria(accountName || '', searchString))
        const filteredSelectedAccountNames = _.filter(selectedAccountNames, accountName => isMetSearchStringCriteria(accountName || '', searchString))
        const accountNameGroups = _.groupBy(filteredAccountNameOptions, accountName => {
            return _.has(accountItems, `${accountName}.portfolio_name`) ? accountItems[accountName].portfolio_name : 'Unkown Portfolio'
        })

        return (
            <Popup className='portfolio-account-selector'
                on={'click'}
                align={'horizontal'}
                trigger={<button className={'portfolio-account-selector--trigger' + (className ? ` ${className}` : '')}>{`${_.size(selectedAccountNames)}/${_.size(accountItemOptions)} Selected`}</button>}
                onOpen={() => { 
                    this.setState({ searchString: '' }) 
                    if (this.searchInputNode) {
                        this.searchInputNode.focus()
                    }
                }}>
                <div className='portfolio-account-selector--header'>{`Select Accounts (${_.size(selectedAccountNames)}/${_.size(accountItemOptions)})`}</div>
                <div className='portfolio-account-selector--config-row clearfix'>
                    <button className='portfolio-account-selector--toggle-all-button select-all'
                        onClick={() => { onChangeSelectedAccountNames(_.union(selectedAccountNames, filteredAccountNameOptions)) }}>
                        {'Select All'}
                    </button>
                    <button className='portfolio-account-selector--toggle-all-button unselect-all'
                        onClick={() => { onChangeSelectedAccountNames(_.without(selectedAccountNames, ...filteredSelectedAccountNames)) }}>
                        {'Unselect All'}
                    </button>
                    <input className='portfolio-account-selector--search-string'
                        ref={(node) => { this.searchInputNode = node }}
                        type={'text'}
                        placeholder={'Search Accounts'}
                        spellCheck={false}
                        value={searchString}
                        onChange={(e) => { this.setState({ searchString: e.target.value }) }} />
                </div>
                {!_.isEmpty(accountNameGroups) 
                ? <div className='portfolio-account-selector--portfolios' onClick={(e) => { e.stopPropagation() }}>
                    {_.map(accountNameGroups, (portfolioAccountNames, portfolioName) => {
                        return (
                            <div className='portfolio-account-selector--portfolio' key={portfolioName}>
                                <div className='portfolio-account-selector--portfolio--header'>
                                    <label>{portfolioName}</label>
                                    <button className='select-all'
                                        onClick={() => { onChangeSelectedAccountNames(_.union(selectedAccountNames, portfolioAccountNames)) }}>
                                        {'Select All'}
                                    </button>
                                    <button className='unselect-all'
                                        onClick={() => { onChangeSelectedAccountNames(_.without(selectedAccountNames, ...portfolioAccountNames)) }}>
                                        {'Unselect All'}
                                    </button>
                                </div>
                                <div className='portfolio-account-selector--portfolio--main'>
                                    {_.map(portfolioAccountNames.sort(), accountName => {
                                        return (
                                            <div className='portfolio-account-selector--portfolio--item' key={accountName}
                                                title={!_.has(accountItems, accountName) ? 'Account is invalid' : null}>
                                                <Checkbox checked={filteredSelectedAccountNames.includes(accountName)}
                                                    onChange={(newChecked) => {
                                                        if (newChecked) {
                                                            onChangeSelectedAccountNames(_.union(selectedAccountNames, [accountName]))
                                                        } else {
                                                            onChangeSelectedAccountNames(_.without(selectedAccountNames, accountName))
                                                        }
                                                    }} />
                                                <span className={!_.has(accountItems, accountName) ? 'not-exist-warning' : null}>{accountName}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
                : <div className='portfolio-account-selector--empty-result'>{'No Selectable Account'}</div>}
            </Popup>
        )
    }
}

PortfolioAccountSelector.propTypes = {
    accountItems: PropTypes.object.isRequired,
    
    className: PropTypes.string,
    accountItemOptions: PropTypes.object.isRequired,
    selectedAccountNames: PropTypes.array.isRequired,
    onChangeSelectedAccountNames: PropTypes.func.isRequired
}

PortfolioAccountSelector.defaultProps = {
    onChangeSelectedAccountNames: () => {}
}

function mapStateToProps (state) {
    return {
        accountItems: state.account.items
    }
}

export default connect(mapStateToProps)(PortfolioAccountSelector)