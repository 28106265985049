import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import dotProp from 'dot-prop-immutable'
import _ from 'lodash'

import Popup from '../common/popup/Popup'
import SearchSelect from '../common/searchSelect/SearchSelect'
import PortfolioAccountSelector from '../account/PortfolioAccountSelector'
import Toggle from '../common/toggle/Toggle'

import { EXCHANGES_CAN_SUPPORT_SMART_POSITIONS } from '../../configs/tradingConfig'
import { profileParameters } from '../../configs/profileConfig'
import { filterAccountItemsByExchangeName } from '../../util/tradingUtil'
import { toNumberInputValue } from '../../util/util'

const INVALID = 'INVALID'
const SMART_POS_ACCOUNT = 'smart_pos_acct'

class ProfileExchangeAccountEditor extends Component {

    SmartPosAccounts (exchangeName, direction='BID') {
        const { editingProfileItem, accountItems, onChangeProfileItem, onChangeProfileParam } = this.props
        const directionName = direction === 'BID' ? 'BUY' : 'SELL'
        const paramKey = `${exchangeName}_SMART_POS_${direction}_ACCOUNTS`
        const backUpAccountParamKey = `${exchangeName}_SMART_POS_BACKUP_ACCOUNT`
        const accountNames = _.has(editingProfileItem, `params.${paramKey}`) && _.isArray(editingProfileItem.params[paramKey])
            ? (editingProfileItem.params[paramKey].filter(accountName => ![undefined, null, INVALID].includes(accountName)) || []) 
            : []
        const accountItemOptions = filterAccountItemsByExchangeName(accountItems, exchangeName)
        return (
            <div className='profile-exchange-account-editor--smart-pos-accounts'>
                <div className={`profile-exchange-account-editor--smart-pos-accounts--direction ${directionName}`}>{directionName}</div>
                <div className='profile-exchange-account-editor--smart-pos-accounts--list clearfix'>
                    <PortfolioAccountSelector 
                        className='profile-exchange-account-editor--smart-pos-accounts--editor'
                        accountItemOptions={accountItemOptions} 
                        selectedAccountNames={accountNames} 
                        onChangeSelectedAccountNames={(newAccountNames) => {
                            const newValue = !_.isEmpty(newAccountNames) ? newAccountNames : [INVALID]
                            let newProfileItem = dotProp.set(editingProfileItem, `params.${paramKey}`, newValue)
                            onChangeProfileParam(paramKey, newValue)
                            if (_.has(newProfileItem, `params.${backUpAccountParamKey}`) 
                                && newProfileItem.params[backUpAccountParamKey] !== INVALID 
                                && newAccountNames.includes(newProfileItem.params[backUpAccountParamKey])) {
                                newProfileItem = dotProp.set(newProfileItem, `params.${backUpAccountParamKey}`, INVALID)
                                onChangeProfileParam(backUpAccountParamKey, INVALID)
                            }
                            onChangeProfileItem(newProfileItem)
                        }} />
                    <button className={`profile-exchange-account-editor--smart-pos-accounts--copy-button ${direction}`}
                        onClick={() => { 
                            const newSmartPosAccounts = editingProfileItem.params[`${exchangeName}_SMART_POS_${direction==='BID' ? 'ASK' : 'BID'}_ACCOUNTS`]
                            const newProfileItem = dotProp.set(editingProfileItem, `params.${paramKey}`, newSmartPosAccounts)
                            onChangeProfileParam(paramKey, newSmartPosAccounts)
                            onChangeProfileItem(newProfileItem)
                        }}>
                        {`Copy From ${direction === 'BID' ? 'SELL' : 'BUY'}`}
                    </button>
                </div>
            </div>
        )
    }

    render () {
        const { className, editingProfileItem, exchangeName, accountItems, accountOptionsPosition, editable, notEditableMessage, shouldHideSmartAccounts,
            onChangeProfileItem, onChangeProfileAccount, onChangeProfileParam } = this.props
        const backUpAccountParamKey = `${exchangeName}_SMART_POS_BACKUP_ACCOUNT`
        const roundRobinSwitchParamKey = `${exchangeName}_SMART_ACCTS_ROUND_ROBIN_SWITCH`
        const accountName = editingProfileItem.accounts[exchangeName] || INVALID
        const filteredAccountItems = filterAccountItemsByExchangeName(accountItems, exchangeName)
        const accountOptions = filteredAccountItems.map(accountItem => {
            return {
                value: accountItem.account_name,
                name: accountItem.account_name
            }
        })
        if (EXCHANGES_CAN_SUPPORT_SMART_POSITIONS.includes(exchangeName)) {
            accountOptions.unshift({
                value: 'smart_pos_acct',
                name: 'Smart Position Accounts'
            })
        }
        const selectedSmartAccountNames = _.has(editingProfileItem, 'params') ? _.union(editingProfileItem.params[`${exchangeName}_SMART_POS_BID_ACCOUNTS`] || [], editingProfileItem.params[`${exchangeName}_SMART_POS_ASK_ACCOUNTS`] || []) : []
        
        return (
            <div className={'profile-exchange-account-editor' + (className ? ` ${className}` : '')}>
                <Popup
                    className='profile-exchange-account-editor--disabled-popup'
                    disabled={editable}
                    trigger={
                        <SearchSelect 
                            className='profile-exchange-account-editor--account-name-search-select'
                            options={accountOptions}
                            value={accountName}
                            optionPosition={accountOptionsPosition}
                            placeholder={'Select Trading Account'} 
                            hasClearButton={exchangeName.includes('OKEX')}
                            disabled={!editable}
                            shouldApplyOptionValueToClassName
                            onClickClearButton={() => {
                                const newProfileItem = dotProp.set(editingProfileItem, `accounts.${exchangeName}`, INVALID)
                                onChangeProfileAccount(exchangeName, INVALID)
                                onChangeProfileItem(newProfileItem)
                            }}
                            onChange={(newOption) => {
                                const newProfileItem = dotProp.set(editingProfileItem, `accounts.${exchangeName}`, newOption.value)
                                onChangeProfileAccount(exchangeName, newOption.value)
                                onChangeProfileItem(newProfileItem)
                            }} />
                    }>
                    <span>{notEditableMessage}</span>
                </Popup>
                {accountName === SMART_POS_ACCOUNT && !shouldHideSmartAccounts && 
                <div className='profile-exchange-account-editor--smart-pos-switch-interval'>
                    <div className='profile-exchange-account-editor--smart-pos-switch-interval--name'>{'Switch Interval(Seconds)'}</div>
                    <input className='profile-exchange-account-editor--smart-pos-switch-interval--value' 
                        type={'number'}
                        value={editingProfileItem.params[`${exchangeName}_SMART_POS_SWITCH_INTERVAL_IN_SEC`]} 
                        onChange={(e) => {
                            const paramKey = `${exchangeName}_SMART_POS_SWITCH_INTERVAL_IN_SEC`
                            const newValue = toNumberInputValue(e.target.value)
                            const newProfileItem = dotProp.set(editingProfileItem, `params.${paramKey}`, newValue)
                            onChangeProfileParam(paramKey, newValue)
                            onChangeProfileItem(newProfileItem)
                        }} 
                        onWheel={(e) => { e.target.blur() }}
                        onBlur={(e) => { 
                            if (e.target.value.trim().length === 0) {
                                const paramKey = `${exchangeName}_SMART_POS_SWITCH_INTERVAL_IN_SEC`
                                const newValue = profileParameters[`${exchangeName}_SMART_POS_SWITCH_INTERVAL_IN_SEC`].defaultValue
                                const newProfileItem = dotProp.set(editingProfileItem, `params.${paramKey}`, newValue)
                                onChangeProfileParam(paramKey, newValue)
                                onChangeProfileItem(newProfileItem)
                            }
                        }} />
                </div>}
                {accountName === SMART_POS_ACCOUNT && !shouldHideSmartAccounts && 
                <div className='profile-exchange-account-editor--smart-accounts-wrapper'>
                    {this.SmartPosAccounts(exchangeName, 'BID')}
                    {this.SmartPosAccounts(exchangeName, 'ASK')}
                    {_.has(editingProfileItem, `params.${backUpAccountParamKey}`) && 
                    <div className='profile-exchange-account-editor--smart-accounts-wrapper--backup-account'>
                        <label>{'Backup'}</label>
                        <SearchSelect className='profile-exchange-account-editor--smart-accounts-wrapper--backup-account--search-select'
                            options={_.filter(accountOptions, option => !selectedSmartAccountNames.includes(option.value) && option.value !== 'smart_pos_acct')}
                            value={editingProfileItem.params[backUpAccountParamKey]}
                            placeholder={'Select Account'}
                            hasClearButton
                            onClickClearButton={() => {
                                const newProfileItem = dotProp.set(editingProfileItem, `params.${backUpAccountParamKey}`, INVALID)
                                onChangeProfileParam(backUpAccountParamKey, INVALID)
                                onChangeProfileItem(newProfileItem)
                            }}
                            onChange={(option) => {
                                const newProfileItem = dotProp.set(editingProfileItem, `params.${backUpAccountParamKey}`, option.value)
                                onChangeProfileParam(backUpAccountParamKey, option.value)
                                onChangeProfileItem(newProfileItem)
                            }} />
                    </div>}
                    {_.has(editingProfileItem, `params.${roundRobinSwitchParamKey}`) &&
                    <div className='profile-exchange-account-editor--smart-accounts-wrapper--round-robin-switch'>
                        <label>{'Round Robin Switch'}</label>
                        <Toggle
                            checked={editingProfileItem.params[roundRobinSwitchParamKey]}
                            onChange={(checked) => {
                                const newProfileItem = dotProp.set(editingProfileItem, `params.${roundRobinSwitchParamKey}`, checked)
                                onChangeProfileParam(roundRobinSwitchParamKey, checked)
                                onChangeProfileItem(newProfileItem)
                            }} />    
                    </div>}
                </div>}
            </div>
        )
    }
}

ProfileExchangeAccountEditor.propTypes = {
    className: PropTypes.string,
    editingProfileItem: PropTypes.object.isRequired,
    exchangeName: PropTypes.string.isRequired,
    accountOptionsPosition: PropTypes.oneOf(['top', 'bottom']),
    editable: PropTypes.bool,
    notEditableMessage: PropTypes.string,
    shouldHideSmartAccounts: PropTypes.bool,

    accountItems: PropTypes.object.isRequired,

    onChangeProfileItem: PropTypes.func,
    onChangeProfileAccount: PropTypes.func,
    onChangeProfileParam: PropTypes.func
}

ProfileExchangeAccountEditor.defaultProps = {
    accountOptionsPosition: 'bottom',
    editable: false,
    notEditableMessage: 'Account can be updated only when the profile is STOPPED.',
    shouldHideSmartAccounts: false,
    onChangeProfileItem: () => {},
    onChangeProfileAccount: () => {},
    onChangeProfileParam: () => {}
}

function mapStateToProps (state) {
    return {
        accountItems: state.account.items
    }
}

export default connect(mapStateToProps)(ProfileExchangeAccountEditor)