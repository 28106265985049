export const ABI = [
    'event DelayedOrderRemoved(address indexed account, bool isOffchain, uint256 currentRoundId, int256 sizeDelta, uint256 targetRoundId, uint256 commitDeposit, uint256 keeperDeposit, bytes32 trackingCode)',
    'event DelayedOrderSubmitted(address indexed account, bool isOffchain, int256 sizeDelta, uint256 targetRoundId, uint256 intentionTime, uint256 executableAtTime, uint256 commitDeposit, uint256 keeperDeposit, bytes32 trackingCode)',
    'event FundingRecomputed(int256 funding, int256 fundingRate, uint256 index, uint256 timestamp)',
    'event MarginTransferred(address indexed account, int256 marginDelta)',
    'event PerpsTracking(bytes32 indexed trackingCode, bytes32 baseAsset, bytes32 marketKey, int256 sizeDelta, uint256 fee)',
    'event PositionFlagged(uint256 id, address account, address flagger, uint256 price, uint256 timestamp)',
    'event PositionLiquidated(uint256 id, address account, address liquidator, int256 size, uint256 price, uint256 flaggerFee, uint256 liquidatorFee, uint256 stakersFee)',
    'event PositionModified(uint256 indexed id, address indexed account, uint256 margin, int256 size, int256 tradeSize, uint256 lastPrice, uint256 fundingIndex, uint256 fee, int256 skew)',
    'function accessibleMargin(address account) view returns (uint256 marginAccessible, bool invalid)',
    'function accruedFunding(address account) view returns (int256 funding, bool invalid)',
    'function assetPrice() view returns (uint256 price, bool invalid)',
    'function baseAsset() view returns (bytes32 key)',
    'function canLiquidate(address account) view returns (bool)',
    'function cancelDelayedOrder(address account)',
    'function cancelOffchainDelayedOrder(address account)',
    'function closePosition(uint256 desiredFillPrice)',
    'function closePositionWithTracking(uint256 desiredFillPrice, bytes32 trackingCode)',
    'function currentFundingRate() view returns (int256 fundingRate)',
    'function currentFundingVelocity() view returns (int256 fundingVelocity)',
    'function delayedOrders(address account) view returns (tuple(bool isOffchain, int128 sizeDelta, uint128 desiredFillPrice, uint128 targetRoundId, uint128 commitDeposit, uint128 keeperDeposit, uint256 executableAtTime, uint256 intentionTime, bytes32 trackingCode))',
    'function executeDelayedOrder(address account)',
    'function executeOffchainDelayedOrder(address account, bytes[] priceUpdateData) payable',
    'function fillPrice(int256 sizeDelta) view returns (uint256 price, bool invalid)',
    'function flagPosition(address account)',
    'function forceLiquidatePosition(address account)',
    'function fundingLastRecomputed() view returns (uint32 timestamp)',
    'function fundingRateLastRecomputed() view returns (int128 fundingRate)',
    'function fundingSequence(uint256 index) view returns (int128 netFunding)',
    'function fundingSequenceLength() view returns (uint256 length)',
    'function isFlagged(address account) view returns (bool)',
    'function liquidatePosition(address account)',
    'function liquidationFee(address account) view returns (uint256)',
    'function liquidationPrice(address account) view returns (uint256 price, bool invalid)',
    'function marketDebt() view returns (uint256 debt, bool isInvalid)',
    'function marketKey() view returns (bytes32 key)',
    'function marketSize() view returns (uint128 size)',
    'function marketSizes() view returns (uint256 long, uint256 short)',
    'function marketSkew() view returns (int128 skew)',
    'function modifyPosition(int256 sizeDelta, uint256 desiredFillPrice)',
    'function modifyPositionWithTracking(int256 sizeDelta, uint256 desiredFillPrice, bytes32 trackingCode)',
    'function notionalValue(address account) view returns (int256 value, bool invalid)',
    'function orderFee(int256 sizeDelta, uint8 orderType) view returns (uint256 fee, bool invalid)',
    'function positions(address account) view returns (tuple(uint64 id, uint64 lastFundingIndex, uint128 margin, uint128 lastPrice, int128 size))',
    'function postTradeDetails(int256 sizeDelta, uint256 tradePrice, uint8 orderType, address sender) view returns (uint256 margin, int256 size, uint256 price, uint256 liqPrice, uint256 fee, uint8 status)',
    'function profitLoss(address account) view returns (int256 pnl, bool invalid)',
    'function recomputeFunding() returns (uint256 lastIndex)',
    'function remainingMargin(address account) view returns (uint256 marginRemaining, bool invalid)',
    'function submitCloseDelayedOrderWithTracking(uint256 desiredTimeDelta, uint256 desiredFillPrice, bytes32 trackingCode)',
    'function submitCloseOffchainDelayedOrderWithTracking(uint256 desiredFillPrice, bytes32 trackingCode)',
    'function submitDelayedOrder(int256 sizeDelta, uint256 desiredTimeDelta, uint256 desiredFillPrice)',
    'function submitDelayedOrderWithTracking(int256 sizeDelta, uint256 desiredTimeDelta, uint256 desiredFillPrice, bytes32 trackingCode)',
    'function submitOffchainDelayedOrder(int256 sizeDelta, uint256 desiredFillPrice)',
    'function submitOffchainDelayedOrderWithTracking(int256 sizeDelta, uint256 desiredFillPrice, bytes32 trackingCode)',
    'function transferMargin(int256 marginDelta)',
    'function unrecordedFunding() view returns (int256 funding, bool invalid)',
    'function withdrawAllMargin()'
  ]