import React, { memo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { FaXmark } from 'react-icons/fa6'
import SearchSelect from '../common/searchSelect/SearchSelect'

function SignalParamList ({ values=[], options=[], className='', placeholder='', onChange=()=>{} }) {
    return (
        <div className={'signal-param-list' + (!_.isEmpty(className) ? ` ${className}` : '')}>
            <div className='signal-param-list--main'>
                {_.map(values, (_value, _index) => {
                    const _invalid = _.isNil(_.find(options, { value: _value }))
                    return (
                        <div className={'signal-param-list--item' + (_invalid ? ' invalid' : '')} key={_index}>
                            <label>{_value}</label>
                            <button onClick={() => {
                                const _newValues = _.without(values, _value)
                                onChange(_newValues)
                            }}><FaXmark /></button>
                        </div>
                    )
                })}
            </div>
            <SearchSelect
                options={options}
                value={null}
                placeholder={placeholder || 'Search and add'}
                onChange={(newOption) => {
                    const _newValues = _.uniq(_.concat(values, newOption.value))
                    onChange(_newValues)
                }} />
        </div>
    )
}

SignalParamList.propTypes = {
    values: PropTypes.array.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    })),
    className: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func
}

export default memo(SignalParamList)