import BigNumber from 'bignumber.js'
import _ from 'lodash'

export function formattedTokenAmount ({ amount, decimalPlaces=18 }) {
    const amountString = _.toString(amount)
    if (_.isEmpty(amountString) || amountString === 'NaN') {
        return 'N/A'
    } else {
        const _amount = BigNumber(amount || 0).dp(decimalPlaces, 1)
        return _amount.eq(0) ? '0.0'
            : _amount.lt(1) ? BigNumber(_amount.toPrecision(4, 1)).toString()
                : _amount.lt(1000) ? _amount.toPrecision(4, 1)
                    : _amount.toFormat(0, 1)
    }
}

export function formattedHex (address='') {
    return _.size(address) > 10 ? `${address.substring(0, 6)}...${address.substring(_.size(address) - 6)}` : address
}