import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import BigNumber from 'bignumber.js'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import _ from 'lodash'

import { AiOutlineSwap } from 'react-icons/ai'

import TokenTransferEditor, { TRANSFER_STATES } from './TokenTransferEditor'
import Popup from '../common/popup/Popup'

import { getPortfolioMarginAccountTransferAdvice } from '../../util/tradingUtil'
class PortfolioMarginAccountInfo extends Component {

    constructor (props) {
        super(props)
        this.state = {
            defaultSingleTransferConfig: null
        }
    }

    TokenTransferPopup (portfolioMarginAccount) {
        const { defaultSingleTransferConfig } = this.state
        const transferAdvice = getPortfolioMarginAccountTransferAdvice(portfolioMarginAccount)
        const { token, transferItem, validOriginTransferAccountNames } = transferAdvice

        return !_.isNil(token) && !_.isNil(transferItem) ? (
            <Popup 
                className='portfolio-margin-account-im-info--token-transfer-popup'
                trigger={<button className='portfolio-margin-account-im-info--token-transfer-popup--trigger-button'><AiOutlineSwap /></button>}
                on={'click'}
                align={'horizontal'}
                onOpen={() => {
                    this.setState({
                        defaultSingleTransferConfig: {
                            id: uuidv4(),
                            token,
                            transferItem,
                            amount: 0,
                            amountPercentInput: '',
                            state: TRANSFER_STATES.NULL,
                            message: null
                        }
                    })
                }}>
                <div className='portfolio-margin-account-im-info--token-transfer-popup--main' onClick={(e) => { e.stopPropagation() }}>
                    <TokenTransferEditor 
                        shouldAutoFocusAmountInput
                        defaultSingleTransferConfig={defaultSingleTransferConfig} 
                        validSingleTransferOriginAccountNames={validOriginTransferAccountNames} />
                </div>
            </Popup>
        ) : null
    }

    render () {
        const { crossAccountBalances, portfolioMarginAccountIM, ratioHighlightThreshold, tokenTransferEnabled, liquidationRatioAlertLimit } = this.props
        const { acct_name: accountName, coin, status, timestamp, equity, initial_margin, imr } = portfolioMarginAccountIM

        const pickedCrossAccountBalance = _.find(crossAccountBalances, { acct_name: accountName })
        const adjustedEquity = _.get(pickedCrossAccountBalance, 'adjustedEq')
        const { soft_limit: softLimit, hard_limit: hardLimit } = liquidationRatioAlertLimit || {}

        return !_.isNil(portfolioMarginAccountIM) && (
            <div className='portfolio-margin-account-im-info'>
                <div className='portfolio-margin-account-im-info--header'>
                    <label className='portfolio-margin-account-im-info--header--tag'>{'Portfolio Margin Account IM'}</label>
                    {!_.isEmpty(status) && <label className='portfolio-margin-account-im-info--header--status'>{status}</label>}
                    {!_.isEmpty(timestamp) && <span className='portfolio-margin-account-im-info--header--timestamp'>{moment(timestamp).format('HH:mm:ss')}</span>}
                    {tokenTransferEnabled && <div className='portfolio-margin-account-im-info--header--token-transfer-popup' 
                        onClick={(e) => { 
                            e.stopPropagation()
                            const virtualClickEvent = new Event('click')
                            Object.defineProperty(virtualClickEvent, 'target', { writable: false, value: document.body })
                            window.dispatchEvent(virtualClickEvent)
                        }}>
                            {this.TokenTransferPopup(portfolioMarginAccountIM)}
                        </div>}
                </div>
                <div className='portfolio-margin-account-im-info--name'>{(!_.isEmpty(coin) ? `${coin} @ ` : '') + accountName}</div>
                <div className='portfolio-margin-account-im-info--stats'>
                    {!_.isNil(adjustedEquity) ? <div className='portfolio-margin-account-im-info--stats--item'>
                        <label>{'Adj. Equity'}</label>
                        <span>{`$${BigNumber(adjustedEquity).toFormat(0, 1)}`}</span>
                    </div>
                    : <div className='portfolio-margin-account-im-info--stats--item'>
                        <label>{'Equity'}</label>
                        <span>{_.isNil(equity) ? 'N/A' : `${_.isEmpty(coin) ? '$' : ''}${BigNumber(equity).toFormat(0, 1)}`}</span>
                    </div>}
                    <div className='portfolio-margin-account-im-info--stats--item'>
                        <label>{'Initial Margin'}</label>
                        <span>{_.isNil(initial_margin) ? 'N/A' : `${_.isEmpty(coin) ? '$' : ''}${BigNumber(initial_margin).toFormat(0, 1)}`}</span>
                    </div>
                    {!_.isEmpty(liquidationRatioAlertLimit) &&
                    <div className='portfolio-margin-account-im-info--stats--item'>
                        <label>{'Soft/Hard Limit'}</label>
                        <span>{`${softLimit} / ${hardLimit}`}</span>
                    </div>}
                    <div className='portfolio-margin-account-im-info--stats--item'>
                        <label>{'IMR'}</label>
                        <span className={!_.isNil(imr) && BigNumber(imr || ratioHighlightThreshold).lte(ratioHighlightThreshold) ? 'warning' : 'highlight'}>{_.isNil(imr) ? 'N/A' : `${BigNumber(imr).times(100).toFixed(0, 1)}%`}</span>
                    </div>
                </div>
            </div>
        )
    }
}

PortfolioMarginAccountInfo.propTypes = {
    crossAccountBalances: PropTypes.object.isRequired,
    liquidationRatioAlertLimit: PropTypes.shape({
        acct_name: PropTypes.string,
        symbol: PropTypes.string,
        soft_limit: PropTypes.number,
        hard_limit: PropTypes.number,
        for_cross_ratio: PropTypes.number,
        comment: PropTypes.string
    }),
    portfolioMarginAccountIM: PropTypes.object.isRequired,
    ratioHighlightThreshold: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    tokenTransferEnabled: PropTypes.bool
}

PortfolioMarginAccountInfo.defaultProps = {
    ratioHighlightThreshold: 2
}

function mapStateToProps (state, ownProps) {
    const { acct_name: accountName } = _.get(ownProps, 'portfolioMarginAccountIM') || {}
    return {
        crossAccountBalances: _.get(state.account, `balance.cross`),
        liquidationRatioAlertLimit: _.get(state.trading?.liquidationRatioAlertLimits, `${accountName}--imr`)
    }
}

export default connect(mapStateToProps)(PortfolioMarginAccountInfo)