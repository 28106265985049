import _ from 'lodash'
import dotProp from 'dot-prop-immutable'
import { RESET_BLOCKCHAIN_ACCOUNT_DATA, UPDATE_BLOCKCHAIN_TOKEN, UPDATE_BLOCKCHAIN_TOKEN_BALANCE, UPDATE_BLOCKCHAIN_WALLET, UPDATE_BLOCKCHAIN_VAULT_SYNTHETIX_PERPS } from './blockchainAction'

export const SLICE_NAME = 'blockchain'

const initialState = {
    wallet: {
        address: null,
        connector: {
            id: null,
            name: null,
            ready: false
        },
        isConnected: false,
        status: null,
        tokenBalances: {} // token address -> balance 
    },
    tokens: {}, // token address -> Token
    tokenBalances: {}, // token address -> account address -> balance
    synthetixPerpsPerVault: {} // vault address -> perps address -> data{}
}

export function blockchainReducer (state=initialState, action) {
    switch(action.type) {
        case RESET_BLOCKCHAIN_ACCOUNT_DATA: {
            const newState = _.cloneDeep(state)
            newState.wallet.tokenBalances = {}
            return newState
        }

        case UPDATE_BLOCKCHAIN_WALLET:
            return dotProp.merge(state, 'wallet', action.payload)

        case UPDATE_BLOCKCHAIN_TOKEN:
            return dotProp.merge(state, `tokens.${action.address}`, action.data)

        case UPDATE_BLOCKCHAIN_TOKEN_BALANCE: {
            const newTokenBalances = _.cloneDeep(state.tokenBalances)
            _.set(newTokenBalances, `${action.token}.${action.account}`, action.balance)
            return dotProp.set(state, 'tokenBalances', newTokenBalances)
        }

        case UPDATE_BLOCKCHAIN_VAULT_SYNTHETIX_PERPS:
            return dotProp.merge(state, `synthetixPerpsPerVault.${action.vaultAddress}.${action.perpsAddress}`, action.payload)

        default:
            return state
    }
}

export const selectWallet = (state) => _.get(state, `${SLICE_NAME}.wallet`)
export const selectBlockchainTokens = (state) => _.get(state, `${SLICE_NAME}.tokens`)
export const selectBlockchainToken = (state, token) => _.get(state, `${SLICE_NAME}.tokens.${token}`)
export const selectBlockchainTokenBalances = (state) => _.get(state, `${SLICE_NAME}.tokenBalances`)
export const selectBlockchainTokenBalance = (state, token, account) => _.get(state, `${SLICE_NAME}.tokenBalances.${token}.${account}`)
export const selectBlockchainVaultSynthetixPerps = (state, vaultAddress) => _.get(state, `${SLICE_NAME}.synthetixPerpsPerVault.${vaultAddress}`)