import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'

import { DEFAULT_SYMBOL_SINGLE_LIMIT } from '../../configs/tradingConfig'

class SingleLimit extends Component {
    
    DataRow (name, value) {
        return (
            <div className='single-limit--data-row clearfix'>
                <div className='single-limit--data-row--name'>{name}</div>
                <div className='single-limit--data-row--value'>{value}</div>
            </div>
        )
    }

    render () {
        const { singleLimits, symbolName } = this.props
        const singleLimit = !_.isEmpty(singleLimits) ? (singleLimits[symbolName] || DEFAULT_SYMBOL_SINGLE_LIMIT) : null
        return singleLimit ? (
            <div className='single-limit'>
                {this.DataRow('Min Qty', singleLimit.min_qty)}
                {this.DataRow('Max Notional', singleLimit.max_nv)}
            </div>
        ) : null
    }
}

SingleLimit.propTypes = {
    symbolName: PropTypes.string.isRequired,
    singleLimits: PropTypes.object.isRequired
}

function mapStateToProps (state) {
    return {
        singleLimits: state.trading.singleLimits
    }
}

export default connect(mapStateToProps)(SingleLimit)



