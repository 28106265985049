import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Checkbox from '../common/checkbox/Checkbox'
import { isMetSearchStringCriteria } from '../../util/util'

function MultipleAccountSelector ({ accountNames, filteredExchangeNames, accountItems, onChangeAccountNames }) {

    const [searchString, setSearchString] = useState('')
    
    const filteredAccountItems = _.filter(accountItems, _accountItem => {
        return (_.isEmpty(filteredExchangeNames) || filteredExchangeNames.includes(_accountItem?.exchange_name))
            && (_.isEmpty(searchString) || isMetSearchStringCriteria(`${_accountItem.account_name} ${_accountItem.portfolio_name}`, searchString))
    })

    const accountItemsPerPortfolio = _.groupBy(filteredAccountItems, 'portfolio_name')
    const areAllAccountsSelected = _.every(filteredAccountItems, _accountItem => accountNames.includes(_accountItem.account_name))

    return (
        <div className='multiple-account-selector'>
            <div className='multiple-account-selector--header'>
                <input
                    type={'text'}
                    placeholder={'Search Accounts'}
                    spellCheck={false}
                    value={searchString}
                    onChange={(e) => { setSearchString(e.target.value.trim()) }} />
                {areAllAccountsSelected
                    ? <button onClick={() => { onChangeAccountNames([]) }}>{'Unselect All'}</button> 
                    : <button onClick={() => { onChangeAccountNames(_.compact(_.map(filteredAccountItems, _accountItem => _accountItem.account_name))) }}>{'Select All'}</button>}
            </div>
            <div className='multiple-account-selector--main'>
                {_.isEmpty(accountItemsPerPortfolio) ? <div className='multiple-account-selector--empty-message'>{'No Available Accounts'}</div>
                : _.map(accountItemsPerPortfolio, (_accountItems, _portfolio) => {
                    const _accountNames = _.map(_accountItems, _accountItem => _accountItem.account_name)
                    const _areAllAccountsSelected = _.every(_accountNames, _accountName => accountNames.includes(_accountName))
                    return (
                        <div className='multiple-account-selector--portfolio' key={_portfolio}>
                            <div className='multiple-account-selector--portfolio--header'>
                                <label>{_portfolio}</label>
                                {_areAllAccountsSelected
                                    ? <button onClick={() => { onChangeAccountNames(_.without(accountNames, ..._accountNames)) }}>{'Unselect All'}</button>
                                    : <button onClick={() => { onChangeAccountNames(_.compact(_.union(accountNames, _accountNames))) }}>{'Select All'}</button>}
                            </div>
                            <div className='multiple-account-selector--portfolio--accounts'>
                                {_.map(_accountItems, _accountItem => {
                                    const { account_name: accountName } = _accountItem
                                    const checked = accountNames.includes(accountName)
                                    return (
                                        <div key={accountName}
                                            onClick={() => {
                                                if (checked) {
                                                    onChangeAccountNames(_.without(accountNames, accountName))
                                                } else {
                                                    onChangeAccountNames(_.compact(_.union(accountNames, [accountName])))
                                                }
                                            }}>
                                            <Checkbox
                                                checked={checked}
                                                disabled />
                                            <label>{accountName}</label>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

MultipleAccountSelector.propTypes = {
    accountNames: PropTypes.arrayOf(PropTypes.string),
    filteredExchangeNames: PropTypes.arrayOf(PropTypes.string),

    accountItems: PropTypes.object.isRequired,
    onChangeAccountNames: PropTypes.func.isRequired
}

MultipleAccountSelector.defaultProps = {
    accountNames: [],
    filteredExchangeNames: [],
    onChangeAccountNames: () => {}
}

function mapStateToProps (state) {
    return {
        accountItems: state.account.items
    }
}

export default connect(mapStateToProps)(MultipleAccountSelector)