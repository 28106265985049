import dotProp from 'dot-prop-immutable'
import {AUTH_LOGIN_SUCCESS, AUTH_VERIFY_SUCCESS, AUTH_LOGOUT} from './authAction'

const initialState = {
    isLoggedIn: false,
    accessToken: localStorage.accessToken || null,
    username: localStorage.username || null
}

export function authReducer (state = initialState, action) {
    switch (action.type) {
        case AUTH_LOGIN_SUCCESS:
            localStorage.accessToken = action.accessToken
            localStorage.username = action.username
            return Object.assign({}, state, {
                isLoggedIn: true,
                accessToken: action.accessToken,
                username: action.username
            })

        case AUTH_VERIFY_SUCCESS:
            return dotProp.set(state, 'isLoggedIn', true)

        case AUTH_LOGOUT:
            localStorage.removeItem('accessToken')
            localStorage.removeItem('username')
            return Object.assign({
                isLoggedIn: false,
                accessToken: null,
                username: null
            })

        default:
            return state
    }
}