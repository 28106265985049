import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import _ from 'lodash'

import { AutoSizer, Table, Column } from 'react-virtualized'
import SearchSelect from '../common/searchSelect/SearchSelect'
import Popup from '../common/popup/Popup'
import Checkbox from '../common/checkbox/Checkbox'

import { getSymbolAttributeByName, INSTRUMENT_TYPES } from '../../util/symbolUtil'
import { getQueryString, isMetSearchStringCriteria, secureFetch } from '../../util/util'
import { DEFAULT_SERVER } from '../../configs/config'
import moment from 'moment/moment'
import dotProp from 'dot-prop-immutable'
import BigNumber from 'bignumber.js'

const SORT_ORDERS = {
    ASC: 'ASC',
    DESC: 'DESC'
}

const TIME_RANGE_TYPE = {
    H: 'H',
    D: 'D'
}

const SymbolCandles = ({ symbol, lastUpdateTime, data=[] }) => {
    return { symbol, lastUpdateTime, data }
}

const TimeRange = ({ value=1, type=TIME_RANGE_TYPE.H }) => {
    return {
        value,
        type
    }
}

const TIME_RANGES = [
    TimeRange({
        value: '1',
        type: TIME_RANGE_TYPE.H
    }),
    TimeRange({
        value: '4',
        type: TIME_RANGE_TYPE.H
    }),
    TimeRange({
        value: '1',
        type: TIME_RANGE_TYPE.D
    }),
    TimeRange({
        value: '2',
        type: TIME_RANGE_TYPE.D
    }),
    TimeRange({
        value: '3',
        type: TIME_RANGE_TYPE.D
    }),
    TimeRange({
        value: '7',
        type: TIME_RANGE_TYPE.D
    })
]

const cachedWorkspaceComponentStates = {}

class HighLowPrices extends Component {
    constructor (props) {
        super(props)

        this.state = _.get(cachedWorkspaceComponentStates, props.workspaceComponentId, {
            coin: null,
            instrumentTypes: [INSTRUMENT_TYPES.SWAP],
            timeRange: TIME_RANGES[2],
            searchString: '',
            candlesPerSymbol: {},
            sortBy: 'symbol',
            sortOrder: SORT_ORDERS.ASC,
            shouldHideSymbolsWithoutData: false
        })

        this._mounted = false
    }

    componentDidMount () {
        this._mounted = true
    }

    componentDidUpdate () {
        if (this.tableNode) {
            this.tableNode.recomputeRowHeights()
        }
    }

    componentWillUnmount () {
        this._mounted = false
        const { workspaceComponentId } = this.props
        if (!_.isNil(workspaceComponentId)) {
            cachedWorkspaceComponentStates[workspaceComponentId] = _.cloneDeep(this.state)
        }
    }

    _getAllCoins () {
        const { symbolItems } = this.props
        const tokens = _.map(symbolItems, symbolItem => {
            const { base } = getSymbolAttributeByName(symbolItem.symbol_name)
            return base || ''
        })
        return _.uniq(_.compact(tokens)).sort()
    }

    _getFilteredSymbols () {
        const { symbolItems } = this.props
        const { coin, instrumentTypes, searchString } = this.state
        const symbols = []
        _.forEach(symbolItems, symbolItem => {
            const { symbol_name: symbolName } = symbolItem
            const { base, instrumentType } = getSymbolAttributeByName(symbolName)
            if (base === coin && instrumentTypes.includes(instrumentType) && (_.isEmpty(searchString) || isMetSearchStringCriteria(symbolName, searchString))) {
                symbols.push(symbolName)
            }
        })
        return symbols
    }

    fetchCandles (shouldSkipCachedData=false) {
        const { dispatch } = this.props
        const { candlesPerSymbol } = this.state 
        const symbols = this._getFilteredSymbols()

        _.map(symbols, symbol => {
            if (!shouldSkipCachedData || !_.has(candlesPerSymbol, symbol)) {
                const params = {
                    startTime: moment().add(-7, 'days').valueOf(),
                    endTime: moment().valueOf(),
                    interval: '1h',
                    symbol
                }
                dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/get_klines?${getQueryString(params)}`))
                .then(response => {
                    if (response.status === 200) {
                        return response.json()
                    }
                })
                .then(body => {
                    if (this._mounted && _.isArray(_.get(body, 'data'))) {
                        const unifiedData = _.map(
                            _.get(body, 'data'),
                            item => {
                                return Object.assign({}, item, { start_time: moment(item.start_time).toISOString() })
                            }
                        )
                        const sortedData = _.sortBy(unifiedData, item => {
                            return -moment(item.start_time).valueOf()
                        })
                        this.setState((prevState) => {
                            return {
                                candlesPerSymbol: dotProp.set(prevState.candlesPerSymbol, symbol, SymbolCandles({
                                    symbol,
                                    lastUpdateTime: moment().toISOString(),
                                    data: sortedData
                                }))
                            }
                        })
                    }
                })
            }
        })
    }

    _getTableRows () {
        const { candlesPerSymbol, timeRange, shouldHideSymbolsWithoutData, sortBy, sortOrder } = this.state
        const { value: timeRangeValue, type: timeRangeType } = timeRange
        const filteredSymbol = this._getFilteredSymbols()

        const tableRows = []
        _.forEach(filteredSymbol, symbol => {
            const candles = _.get(candlesPerSymbol, symbol)
            const filteredData = _.take(_.get(candles, 'data') || [], timeRangeValue * (timeRangeType === TIME_RANGE_TYPE.D ? 24 : 1))

            if (!_.isEmpty(filteredData) || !shouldHideSymbolsWithoutData) {
                const lastData = _.last(filteredData)
                let HH, LL, volumeSum
                _.forEach(filteredData, data => {
                    HH = Math.max(HH || 0, Number(data.high))
                    LL = Math.min(LL || Number.MAX_SAFE_INTEGER, Number(data.low)),
                    volumeSum = Number(volumeSum || 0) + Number(data.volume)
                })
                tableRows.push({
                    symbol,
                    lastUpdateTime: _.get(candles, 'lastUpdateTime'),
                    startTime: _.get(lastData, 'start_time'),
                    HH,
                    LL,
                    volumeSum,
                    volumeUnit: _.get(lastData, 'unit')
                }) 
            }
        })

        const sortedTableRows = _.sortBy(tableRows, tableRow => {
            return sortBy === 'lastUpdateTime' ? moment(tableRow.lastUpdateTime).valueOf()
                : sortBy === 'start_time' ? moment(tableRow.startTime).valueOf()
                : sortBy === 'HH' ? Number(tableRow.HH)
                : sortBy === 'LL' ? Number(tableRow.LL)
                : sortBy === 'volumeSum' ? Number(tableRow.volumeSum)
                : tableRow.symbol
        })

        if (sortOrder === SORT_ORDERS.DESC) {
            _.reverse(sortedTableRows)
        }

        const [tableRowsWithData, tableRowsWithoutData] = _.partition(sortedTableRows, tableRow => !_.isNil(tableRow.lastUpdateTime))
        
        return _.concat(tableRowsWithData, tableRowsWithoutData)
    }

    TimeRanges () {
        const { timeRange } = this.state
        return (
            <div className='high-low-prices--time-ranges'>
                <label>{'Time Range'}</label>
                <div className='high-low-prices--time-ranges--list'>
                    {_.map(TIME_RANGES, (timeRangeItem, index) => {
                        const { value, type } = timeRangeItem
                        return (
                            <button 
                                className={'high-low-prices--time-ranges--item' + (_.isEqual(timeRange, timeRangeItem) ? ' selected' : '')} 
                                key={index}
                                onClick={() => { this.setState({ timeRange: timeRangeItem }) }}>
                                {`${value}${type}`}
                            </button>
                        )
                    })}
                </div>
            </div>
        )
    }

    render () {
        const { coin, instrumentTypes, searchString, sortBy, sortOrder, shouldHideSymbolsWithoutData } = this.state
        const allCoins = this._getAllCoins()
        const coinOptions = _.map(allCoins, coin => {
            return {
                value: coin,
                name: coin
            }
        })
        const tableRows = this._getTableRows()
        return (
            <div className='high-low-prices'>
                <div className='high-low-prices--header'>
                    <div className='high-low-prices--header--row-1'>
                        <div className='high-low-prices--filters'>
                            <div className='high-low-prices--filters--item'>
                                <label>{'Coin'}</label>
                                <SearchSelect 
                                    placeholder={'Select Coin'}
                                    value={coin}
                                    options={coinOptions} 
                                    onChange={(newOption) => {
                                        this.setState({ coin: newOption.value })
                                    }} />
                            </div>
                            <div className='high-low-prices--filters--item'>
                                <label>{'Instruments'}</label>
                                <Popup 
                                    className={'high-low-prices--instruments-popup'}
                                    on={'click'}
                                    trigger={
                                        <div className='high-low-prices--instruments-popup--trigger'>{_.isEmpty(instrumentTypes) ? 'EMPTY' : (instrumentTypes).join(', ')}</div>
                                    }>
                                    <div className='high-low-prices--instruments-popup--main'>
                                        {_.map([INSTRUMENT_TYPES.SPOT, INSTRUMENT_TYPES.FUTURE, INSTRUMENT_TYPES.SWAP], instrumentType => {
                                            const isSelected = instrumentTypes.includes(instrumentType)
                                            return (
                                                <div className='high-low-prices--instruments-popup--item' key={instrumentType}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        this.setState({ instrumentTypes: isSelected ? _.without(instrumentTypes, instrumentType) : _.concat(instrumentTypes, instrumentType) })
                                                    }}>
                                                    <Checkbox checked={isSelected} />
                                                    <label>{instrumentType}</label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </Popup>
                            </div>
                        </div>
                        <button className='high-low-prices--fetch-data-button' 
                            disabled={_.isNil(coin) || _.isEmpty(instrumentTypes)}
                            onClick={() => { this.fetchCandles(true) }}>{'Fetch Missing Data'}</button>
                        <button className='high-low-prices--fetch-data-button' 
                            disabled={_.isNil(coin) || _.isEmpty(instrumentTypes)}
                            onClick={() => { this.fetchCandles(false) }}>{'Refresh All Data'}</button>
                    </div>
                    <div className='high-low-prices--header--row-2'>
                        {this.TimeRanges()}
                        <div className='high-low-prices--should-hide-symbols-without-data'>
                            <Checkbox checked={shouldHideSymbolsWithoutData} onChange={(newChecked) => { this.setState({ shouldHideSymbolsWithoutData: newChecked }) }}/>
                            <label>{'Hide Symbols Without Data'}</label>
                        </div>
                        <input className='high-low-prices--search-input' 
                            placeholder='Filter Symbol'
                            spellCheck={false}
                            value={searchString}
                            onChange={(e) => { this.setState({ searchString: e.target.value }) }} />
                    </div>
                </div>
                <div className='high-low-prices--body'>
                    <AutoSizer>
                        {({ width, height }) => (
                            <Table
                                ref={(node) => { this.tableNode = node }}
                                className='high-low-prices--table'
                                headerClassName={'high-low-prices--table--header'}
                                headerHeight={27}
                                width={Math.max(width, 500)}
                                height={height}
                                rowCount={tableRows.length}
                                rowGetter={({ index }) => tableRows[index]}
                                rowClassName={({ index }) => { 
                                    return `high-low-prices--table--row ${index % 2 === 1 ? 'odd-row' : 'even-row'}`
                                }}
                                rowHeight={45}
                                overscanRowCount={5}
                                noRowsRenderer={() => (<div className='high-low-prices--table--no-content'>{'There is no matched symbols.'}</div>)}
                                sort={({ sortBy: newSortBy }) => {
                                    const newSortOrder = _.isEqual(sortBy, newSortBy)
                                        ? (sortOrder === SORT_ORDERS.ASC ? SORT_ORDERS.DESC : SORT_ORDERS.ASC)
                                        : sortOrder
                                    this.setState({
                                        sortBy: newSortBy,
                                        sortOrder: newSortOrder
                                    })
                                }}>
                                <Column dataKey={'symbol'}
                                    label={'SYMBOL'}
                                    headerClassName={'sortable' + (sortBy === 'symbol' ? ' sorted' : '')}
                                    width={160}
                                    flexGrow={1}
                                    flexShrink={0} />
                                <Column dataKey={'lastUpdateTime'}
                                    label={'LAST UPDATE'}
                                    headerClassName={'sortable' + (sortBy === 'lastUpdateTime' ? ' sorted' : '')}
                                    width={80}
                                    flexGrow={1}
                                    flexShrink={0} 
                                    cellRenderer={({ rowData }) => {
                                        const { lastUpdateTime } = rowData
                                        return _.isNil(lastUpdateTime) ? '' : moment(lastUpdateTime).format('HH:mm:ss')
                                    }} />
                                <Column dataKey={'startTime'}
                                    label={'SINCE'}
                                    headerClassName={'sortable' + (sortBy === 'startTime' ? ' sorted' : '')}
                                    width={100}
                                    flexGrow={1}
                                    flexShrink={0} 
                                    cellRenderer={({ rowData }) => {
                                        const { startTime } = rowData
                                        return _.isNil(startTime) ? '' : moment(startTime).format('MM-DD HH:mm:ss')
                                    }} />
                                <Column dataKey={'HH'}
                                    label={'HIGH'}
                                    headerClassName={'sortable' + (sortBy === 'HH' ? ' sorted' : '')}
                                    width={60}
                                    flexGrow={1}
                                    flexShrink={0} />
                                <Column dataKey={'LL'}
                                    label={'LOW'}
                                    headerClassName={'sortable' + (sortBy === 'LL' ? ' sorted' : '')}
                                    width={60}
                                    flexGrow={1}
                                    flexShrink={0} />
                                <Column dataKey={'volumeSum'}
                                    label={'VOLUME'}
                                    headerClassName={'sortable' + (sortBy === 'volumeSum' ? ' sorted' : '')}
                                    width={100}
                                    flexGrow={1}
                                    flexShrink={0} 
                                    cellRenderer={({ rowData }) => {
                                        const { symbol, volumeSum, volumeUnit } = rowData
                                        const { base, quote } = getSymbolAttributeByName(symbol)
                                        const volumeUnitDisplayName = volumeUnit === 'CONTRACT_COUNT' ? 'Cont' 
                                            : volumeUnit === 'USD' ? 'USD'
                                            : volumeUnit === 'BASE_CURRENCY' ? base
                                            : volumeUnit === 'QUOTE_CURRENCY' ? quote
                                            : volumeUnit
                                        return _.isNil(volumeSum) ? '' : `${BigNumber(volumeSum).toFormat(0, 1)} (${volumeUnitDisplayName})`
                                    }} />
                            </Table>
                        )}
                    </AutoSizer>
                </div>
            </div>
        )
    }
}

HighLowPrices.propTypes = {
    dispatch: PropTypes.func.isRequired,
    symbolItems: PropTypes.object.isRequired,

    workspaceComponentId: PropTypes.string
}

function mapStateToProps (state) {
    return {
        symbolItems: state.symbol.items
    }
}

export default connect(mapStateToProps)(HighLowPrices)