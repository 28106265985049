import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import BigNumber from 'bignumber.js'
import moment from 'moment'
import _ from 'lodash'

import { FaXmark } from 'react-icons/fa6'
import Popup from '../common/popup/Popup'
import SearchSelect from '../common/searchSelect/SearchSelect'
import Checkbox from '../common/checkbox/Checkbox'

import { fetchProfileFillStatistisNew } from './profileAction'
import { getSymbolAttributeByName, getTokenPriceInUSD, INSTRUMENT_TYPES } from '../../util/symbolUtil'
import { areAllValuesNonEmpty, isMetSearchStringCriteria, toNumberWithSmartPrecision } from '../../util/util'
import { getPortfolioNames } from '../../util/accountUtil'

const ALL = 'ALL'
const MAX_PROFILE_SIZE = 10

class ProfileFillStatistics extends Component {
    constructor (props) {
        super(props)

        const profileIds = []
        if (!_.isEmpty(props.profileId)) {
            const { profileId, profileItems } = props
            profileIds.push(profileId)
            const { RUNNING_MODE, PEER_PROFILE_NAME, PEER_PROFILE_SERVER } = _.get(profileItems, `${profileId}.params`) || {}
            if (['QUOTE', 'HEDGE'].includes(RUNNING_MODE) && areAllValuesNonEmpty([PEER_PROFILE_NAME, PEER_PROFILE_SERVER])) {
                profileIds.push(`${PEER_PROFILE_NAME}_${PEER_PROFILE_SERVER}`)
            }
        }

        this.state = {
            fromTimestamp: moment().add(-1, 'day').toISOString(),
            toTimestamp: moment().toISOString(),
            isFetching: false,
            profileIds,
            fillStatistics: {
                trades: [],
                fees: []
            },
            searchString: '',
            profileSearchInput: '',
            portfolio: ALL,
            instrumentType: ALL
        }

        this.datetimeAPIFormat = 'YYYY-MM-DD HH:mm:ss'
        this.datetimeInputFormat = 'YYYY-MM-DDTHH:mm:ss'
    }

    componentDidMount () {
        this._getFillStatistics()
    }

    async _getFillStatistics () {
        const { profileIds, fromTimestamp, toTimestamp, portfolio, isFetching } = this.state
        const { dispatch, profileItems, shouldIgnoreProfileHostname } = this.props
        if (!_.isEmpty(profileIds) && !isFetching 
            && moment(fromTimestamp).isValid()
            && moment(toTimestamp).isValid()
            && moment(fromTimestamp).isBefore(toTimestamp)) {

            this.setState({ 
                isFetching: true,
                fillStatistics: {
                    trades: [],
                    fees: []
                }
            })

            let profileHostnamePairs = _.reduce(profileIds, (_result, _profileId) => {
                const _profileItem = profileItems[_profileId]
                if (!_.isNil(_profileItem)) {
                    _result.push([_profileItem.name, !shouldIgnoreProfileHostname ? _profileItem.hostname : null])
                }
                return _result
            }, [])

            profileHostnamePairs = _.uniqBy(profileHostnamePairs, _pair => `${_pair[0]}--${_pair[1]}`)

            dispatch(fetchProfileFillStatistisNew({
                profileHostnamePairs,
                fromTimestamp: moment(fromTimestamp).format(this.datetimeAPIFormat),
                toTimestamp: moment(toTimestamp).format(this.datetimeAPIFormat),
                portfolio: portfolio !== ALL ? portfolio : null
            }))
            .then(result => {
                if (_.isArray(result?.trades) && _.isArray(result?.fees)) {
                    const tradeGroups = _.groupBy(result.trades, _trade => `${_trade.buy_sell}--${_trade.prod_name}`)
                    const mergedTrades = _.map(tradeGroups, _trades => {
                        const { buy_sell, prod_name } = _trades[0]
                        return {
                            buy_sell,
                            prod_name,
                            coin: _.sumBy(_trades, 'coin'),
                            quote: _.sumBy(_trades, 'quote')
                        }
                    })

                    const feeGroups = _.groupBy(result.fees, _fee => `${_fee.prod_name}--${_fee.fee_currency}`)
                    const mergedFees = _.map(feeGroups, _fees => {
                        const { prod_name, fee_currency } = _fees[0]
                        return {
                            prod_name,
                            fee_currency,
                            total_fee: _.sumBy(_fees, 'total_fee')
                        }
                    })

                    this.setState({
                        fillStatistics: {
                            trades: mergedTrades,
                            fees: mergedFees
                        }
                    })
                }
            })
            .finally(() => {
                this.setState({ isFetching: false })
            })
        }
    }

    _getFillStatsBaseAndQuoteTokens (tradeStats=[]) {
        const result = {
            bases: [],
            quotes: []
        }
        _.forEach(tradeStats || [], tradeStatItem => {
            const { prod_name: symbol } = tradeStatItem
            if (!_.isEmpty(symbol)) {
                const { base, quote } = getSymbolAttributeByName(symbol)
                result.bases.push(base)
                result.quotes.push(quote)
            }
        })
        result.bases = _.uniq(result.bases)
        result.quotes = _.uniq(result.quotes)
        return result
    }

    DateTimeTabs () {
        const { isFetching, profileIds } = this.state
        const _shouldDisable = isFetching || _.isEmpty(profileIds) || _.size(profileIds) > MAX_PROFILE_SIZE
        return (
            <div className='profile-fill-statistics--date-time-tabs'>
                <button className='profile-fill-statistics--date-time-tab' 
                    disabled={_shouldDisable}
                    onClick={() => {
                        this.setState({
                            fromTimestamp: moment().add(-1, 'hour').toISOString(),
                            toTimestamp: moment().toISOString()
                        }, () => { this._getFillStatistics() })
                    }}>{'1 Hour'}</button>
                <button className='profile-fill-statistics--date-time-tab' 
                    disabled={_shouldDisable}
                    onClick={() => {
                        this.setState({
                            fromTimestamp: moment().add(-4, 'hour').toISOString(),
                            toTimestamp: moment().toISOString()
                        }, () => { this._getFillStatistics() })
                    }}>{'4 Hours'}</button>
                <button className='profile-fill-statistics--date-time-tab' 
                    disabled={_shouldDisable}
                    onClick={() => {
                        this.setState({
                            fromTimestamp: moment().add(-1, 'day').toISOString(),
                            toTimestamp: moment().toISOString()
                        }, () => { this._getFillStatistics() })
                    }}>{'1 Day'}</button>
                <button className='profile-fill-statistics--date-time-tab' 
                    disabled={_shouldDisable}
                    onClick={() => {
                        this.setState({
                            fromTimestamp: moment().add(-3, 'days').toISOString(),
                            toTimestamp: moment().toISOString()
                        }, () => { this._getFillStatistics() })
                }}>{'3 Days'}</button>
                <button className='profile-fill-statistics--date-time-tab' 
                    disabled={_shouldDisable}
                    onClick={() => {
                        this.setState({
                            fromTimestamp: moment().add(-7, 'days').toISOString(),
                            toTimestamp: moment().toISOString()
                        }, () => { this._getFillStatistics() })
                    }}>{'7 Days'}</button>
                <button className='profile-fill-statistics--date-time-tab' 
                    disabled={_shouldDisable}
                    onClick={() => {
                        this.setState({
                            fromTimestamp: moment().add(-14, 'days').toISOString(),
                            toTimestamp: moment().toISOString()
                        }, () => { this._getFillStatistics() })
                    }}>{'14 Days'}</button>
                <button className='profile-fill-statistics--date-time-tab' 
                    disabled={_shouldDisable}
                    onClick={() => {
                        this.setState({
                            fromTimestamp: moment().add(-1, 'month').toISOString(),
                            toTimestamp: moment().toISOString()
                        }, () => { this._getFillStatistics() })
                    }}>{'1 Month'}</button>
            </div>
        )
    }

    DateTimeInput (side='from') {
        const { fromTimestamp, toTimestamp, isFetching } = this.state
        return (
            <div className='profile-fill-statistics--date-time-input'>
                <label>{side === 'to' ? 'To' : 'From'}</label>
                <input type={'datetime-local'}
                    value={moment(side === 'to' ? toTimestamp : fromTimestamp).format(this.datetimeInputFormat)} 
                    disabled={isFetching}
                    onChange={(e) => {
                        const newMoment = moment(e.target.value)
                        if (newMoment.isValid()) {
                            this.setState({
                                [side === 'to' ? 'toTimestamp' : 'fromTimestamp']: moment(e.target.value).toISOString()
                            })
                        }
                    }} />
            </div>
        )
    }

    AggregatedStats (tradeStats=[], feeStats=[]) {
        const { symbolItems, pricings } = this.props
        const baseAndQuoteTokens = this._getFillStatsBaseAndQuoteTokens(tradeStats)
        const usdTokens = ['USD', 'USDT', 'BUSD', 'USDC', 'TUSD']
        const shouldHaveAggregatedStats = _.size(baseAndQuoteTokens.bases) === 1 && (
            _.size(baseAndQuoteTokens.quotes) === 1
            || _.every(baseAndQuoteTokens.quotes, quote => usdTokens.includes(quote))
        )
        
        if (shouldHaveAggregatedStats) {
            const aggregatedStats = {
                BUY: {
                    coinSum: '0',
                    notionalSum: '0'
                },
                SELL: {
                    coinSum: '0',
                    notionalSum: '0'
                }
            }
            _.forEach(tradeStats, tradeStatItem => {
                const { coin, quote, buy_sell: side } = tradeStatItem
                if (_.has(aggregatedStats, side)) {
                    aggregatedStats[side].coinSum = BigNumber(aggregatedStats[side].coinSum).plus(coin || 0)
                    aggregatedStats[side].notionalSum = BigNumber(aggregatedStats[side].notionalSum).plus(quote || 0)
                }
            })


            const feeStatItems = _.cloneDeep(feeStats)
            const quoteTokenPrice = getTokenPriceInUSD(baseAndQuoteTokens.quotes[0], pricings)
            const notionalSumValue = (BigNumber(aggregatedStats.BUY.notionalSum).plus(aggregatedStats.SELL.notionalSum))
                .times(quoteTokenPrice)
        
            const totalFeePerCurrency = {}
            let totalFeeValue = 0

            _.forEach(feeStatItems, feeStatItem => {
                const { prod_name, total_fee } = feeStatItem
                const { base, quote } = getSymbolAttributeByName(prod_name)
                feeStatItem.fee_currency = feeStatItem.fee_currency || (_.get(symbolItems, `${prod_name}.trading_in_notional`) === '1' ? base : quote)
                totalFeePerCurrency[feeStatItem.fee_currency] = BigNumber(totalFeePerCurrency[feeStatItem.fee_currency] || 0).plus(total_fee)
                const feeCurrencyPrice = getTokenPriceInUSD(feeStatItem.fee_currency, pricings)
                if (!_.isNil(feeCurrencyPrice)) {
                    totalFeeValue = BigNumber(totalFeeValue)
                        .plus(BigNumber(total_fee).times(feeCurrencyPrice))
                        .toString()
                }
            })

            const _avgBuyPrice = BigNumber(aggregatedStats.BUY.notionalSum).div(aggregatedStats.BUY.coinSum)
            const _avgSellPrice = BigNumber(aggregatedStats.SELL.notionalSum).div(aggregatedStats.SELL.coinSum)

            return (
                <div className='profile-fill-statistics--aggregated-stats'>
                    <div className='profile-fill-statistics--aggregated-stats--title'>{'Aggregated Stats'}</div>
                    <div className='profile-fill-statistics--aggregated-stats--main'>
                        <table>
                            <thead>
                                <tr>
                                    <th>{'Side'}</th>
                                    <th className='buy'>{'BUY'}</th>
                                    <th className='sell'>{'SELL'}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>{`Notional Sum (${baseAndQuoteTokens.quotes.join(' | ')})`}</th>
                                    <td className='buy'>{BigNumber(aggregatedStats.BUY.notionalSum || 0).abs().gte(1000) ? BigNumber(aggregatedStats.BUY.notionalSum).toFormat(2, 1) : BigNumber(aggregatedStats.BUY.notionalSum || 0).toPrecision(6, 1)}</td>
                                    <td className='sell'>{BigNumber(aggregatedStats.SELL.notionalSum || 0).abs().gte(1000) ? BigNumber(aggregatedStats.SELL.notionalSum).toFormat(2, 1) : BigNumber(aggregatedStats.SELL.notionalSum || 0).toPrecision(6, 1)}</td>
                                </tr>
                                <tr>
                                    <th>{`Coin Sum (${baseAndQuoteTokens.bases[0]})`}</th>
                                    <td className='buy'>{BigNumber(aggregatedStats.BUY.coinSum || 0).gte(1000) ? BigNumber(aggregatedStats.BUY.coinSum).toFormat(2, 1) : BigNumber(aggregatedStats.BUY.coinSum || 0).toPrecision(6, 1)}</td>
                                    <td className='sell'>{BigNumber(aggregatedStats.SELL.coinSum || 0).gte(1000) ? BigNumber(aggregatedStats.SELL.coinSum).toFormat(2, 1) : BigNumber(aggregatedStats.SELL.coinSum || 0).toPrecision(6, 1)}</td>
                                </tr>
                                <tr>
                                    <th>{`Avg Price`}</th>
                                    <td className='buy'>{_avgBuyPrice.lt(1) ? _avgBuyPrice.toPrecision(5, 1) : _avgBuyPrice.toFormat(5, 1)}</td>
                                    <td className='sell'>{_avgSellPrice.lt(1) ? _avgSellPrice.toPrecision(5, 1) : _avgSellPrice.toFormat(5, 1)}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='profile-fill-statistics--aggregated-stats--avg-price-ratio' title={`= Avg Sell Price / Avg Buy Price - 1`}>
                            <label>{`Avg Price Ratio`}</label>
                            <div>{`${(_avgSellPrice.div(_avgBuyPrice).minus(1)).times(10000).toFixed(2, 1)}%%`}</div>
                        </div>
                        {!_.isEmpty(feeStatItems) &&
                        <div className='profile-fill-statistics--fee'>
                            <label>{'Total Fee'}</label>
                            <Popup
                                className='profile-fill-statistics--fee--popup'
                                trigger={
                                    <div className='profile-fill-statistics--fee--popup--trigger'>
                                        <span className='total-value'>{`USD ${BigNumber(totalFeeValue).toFormat(2, 1)}`}</span>
                                        <span className='ratio' title={'= $(Total Fee) / $(Notional Sum)'}>{`(${BigNumber(totalFeeValue).div(notionalSumValue).times(10000).toFixed(2, 1)}%%)`}</span>
                                    </div>
                                }>
                                {_.map(totalFeePerCurrency, (totalFee, currency) => {
                                    return (
                                        <div className='' key={currency}>{`${_.toUpper(currency || '')} ${BigNumber(totalFee).toFormat(4, 1)}`}</div>
                                    )
                                })}
                            </Popup>
                        </div>}
                    </div>
                </div>
            )
        } else {
            return (
                <div className='profile-fill-statistics--aggregated-stats'>
                    <div className='profile-fill-statistics--aggregated-stats--error-message'>{'Filled symbols are not in the same pair.'}</div>
                </div>
            )
        }
    }

    Result () {
        const { symbolItems, pricings } = this.props
        const { fillStatistics, isFetching, searchString, instrumentType } = this.state
        const filteredTradeStats = _.filter(fillStatistics.trades, tradeStatItem => {
            return (_.isEmpty(searchString) || isMetSearchStringCriteria(tradeStatItem.prod_name, searchString))
                && (instrumentType === ALL || getSymbolAttributeByName(tradeStatItem.prod_name).instrumentType === instrumentType)
        })
        const filteredFeeStats = _.filter(fillStatistics.fees, feeStatItem => {
            return (_.isEmpty(searchString) || isMetSearchStringCriteria(feeStatItem.prod_name, searchString))
                && (instrumentType === ALL || getSymbolAttributeByName(feeStatItem.prod_name).instrumentType === instrumentType)
        })
        const tradeStatsPerSymbol = _.groupBy(filteredTradeStats, 'prod_name')
        const feeStatsPerSymbol = _.groupBy(filteredFeeStats, 'prod_name')

        return (
            <div className='profile-fill-statistics--result'>
                {!isFetching && _.isEmpty(tradeStatsPerSymbol) && <div className='profile-fill-statistics--result--empty-data'>{'Empty Data'}</div>}
                {!_.isEmpty(filteredTradeStats) && this.AggregatedStats(filteredTradeStats, filteredFeeStats)}
                {_.map(tradeStatsPerSymbol, (tradeStatsGroup, symbol) => {
                    const { base, quote } = getSymbolAttributeByName(symbol)
                    const buyTradeStats = _.find(tradeStatsGroup, { buy_sell: 'BUY' })
                    const sellTradeStats = _.find(tradeStatsGroup, { buy_sell: 'SELL' })
                    const hasNoData = BigNumber(_.get(buyTradeStats, 'coin') || 0).eq(0) && BigNumber(_.get(sellTradeStats, 'coin') || 0).eq(0)

                    const feeStatItems = _.cloneDeep(_.get(feeStatsPerSymbol, symbol, []))
                    const quoteTokenPrice = getTokenPriceInUSD(quote, pricings)
                    const notionalSumValue = (BigNumber(_.get(buyTradeStats, 'quote', 0)).plus(_.get(sellTradeStats, 'quote', 0)))
                        .times(quoteTokenPrice)
                
                    let totalFee = 0
                    _.forEach(feeStatItems, feeStatItem => {
                        const { prod_name, total_fee } = feeStatItem
                        feeStatItem.fee_currency = feeStatItem.fee_currency || (_.get(symbolItems, `${prod_name}.trading_in_notional`) === '1' ? base : quote)
                        const feeCurrencyPrice = getTokenPriceInUSD(feeStatItem.fee_currency, pricings)
                        if (!_.isNil(feeCurrencyPrice)) {
                            totalFee = BigNumber(totalFee)
                                .plus(BigNumber(total_fee).times(feeCurrencyPrice))
                                .toString()
                        }
                    })

                    const _avgBuyPrice = buyTradeStats ? BigNumber(buyTradeStats.quote).div(buyTradeStats.coin) : null
                    const _avgSellPrice = sellTradeStats ? BigNumber(sellTradeStats.quote).div(sellTradeStats.coin) : null
                    
                    return (
                        <div className={'profile-fill-statistics--result--item' + (hasNoData ? ' has-no-data' : '')} key={symbol}>
                            <div className='profile-fill-statistics--result--item--title'>
                                <span>{symbol}</span>
                                {hasNoData && <span className='warning'>{'Empty Data'}</span>}
                            </div>
                            <div className='profile-fill-statistics--result--item--main'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>{'Side'}</th>
                                            <th className='buy'>{'BUY'}</th>
                                            <th className='sell'>{'SELL'}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>{`Notional (${quote})`}</th>
                                            <td className='buy'>{buyTradeStats ? toNumberWithSmartPrecision({ number: buyTradeStats.quote, shouldApplyFloorValue: true, shouldReturnLocalString: true }) : 'N/A'}</td>
                                            <td className='sell'>{sellTradeStats ? toNumberWithSmartPrecision({ number: sellTradeStats.quote, shouldApplyFloorValue: true, shouldReturnLocalString: true }) : 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <th>{`Coin (${base})`}</th>
                                            <td className='buy'>{buyTradeStats ? toNumberWithSmartPrecision({ number: buyTradeStats.coin, shouldApplyFloorValue: true, shouldReturnLocalString: true, defaultPrecision: 6 }) : 'N/A'}</td>
                                            <td className='sell'>{sellTradeStats ? toNumberWithSmartPrecision({ number: sellTradeStats.coin, shouldApplyFloorValue: true, shouldReturnLocalString: true, defaultPrecision: 6 }) : 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <th>{`Avg Price`}</th>
                                            <td className='buy'>{!_.isNil(_avgBuyPrice) ? (_avgBuyPrice.lt(1) ? _avgBuyPrice.toPrecision(5, 1) : _avgBuyPrice.toFormat(5, 1)) : 'N/A'}</td>
                                            <td className='sell'>{!_.isNil(_avgSellPrice) ? (_avgSellPrice.lt(1) ? _avgSellPrice.toPrecision(5, 1) : _avgSellPrice.toFormat(5, 1)) : 'N/A'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                {!_.isEmpty(feeStatItems) &&
                                <div className='profile-fill-statistics--fee'>
                                    <label>{'Total Fee'}</label>
                                    <Popup
                                        className='profile-fill-statistics--fee--popup'
                                        trigger={
                                            <div className='profile-fill-statistics--fee--popup--trigger'>
                                                <span className='total-value'>{`USD ${BigNumber(totalFee).toFormat(2, 1)}`}</span>
                                                <span className='ratio' title={'= $(Total Fee) / $(Notional Sum)'}>{`(${BigNumber(totalFee).div(notionalSumValue).times(10000).toFixed(2, 1)}%%)`}</span>
                                            </div>
                                        }>
                                        {_.map(feeStatItems, feeStatItem => {
                                            const { total_fee, fee_currency } = feeStatItem
                                            return (
                                                <div className='' key={fee_currency}>{`${_.toUpper(fee_currency || '')} ${BigNumber(total_fee).toFormat(4, 1)}`}</div>
                                            )
                                        })}
                                    </Popup>
                                </div>}
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    ProfileSelector () {
        const { profileIds, profileSearchInput } = this.state
        const { profileItems } = this.props
        const _filteredProfileItems = _.isEmpty((profileSearchInput || '').trim()) ? profileItems
            : _.filter(profileItems, _profileItem => isMetSearchStringCriteria(`${_profileItem.name} ${_profileItem.hostname}`, profileSearchInput))

        const _handleToggleSelectingProfile = (_profileId) => {
            const _profileItem = _.get(profileItems, _profileId)
            const _isSelected = profileIds.includes(_profileId)
            const { RUNNING_MODE, PEER_PROFILE_NAME, PEER_PROFILE_SERVER } = _.get(_profileItem, 'params') || {}
            let _newProfileIds = _isSelected ? _.without(profileIds, _profileId) : _.concat(profileIds, _profileId)
            if (['QUOTE', 'HEDGE'].includes(RUNNING_MODE) && areAllValuesNonEmpty([PEER_PROFILE_NAME, PEER_PROFILE_SERVER])) {
                const _peerProfileId = `${PEER_PROFILE_NAME}_${PEER_PROFILE_SERVER}`
                if (_.has(profileItems, _peerProfileId)) {
                    _newProfileIds = _isSelected ? _.without(_newProfileIds, _peerProfileId) : _.concat(_newProfileIds, _peerProfileId)
                }
            }
            this.setState({ profileIds: _.uniq(_newProfileIds) })
        }

        return (
            <div className='profile-fill-statistics--profile-selector'>
                <div className='profile-fill-statistics--profile-selector--buttons'>
                    <Popup className='profile-fill-statistics--profile-selector--popup'
                        on={'click'}
                        trigger={<button className='profile-fill-statistics--profile-selector--trigger'>{`Select Profiles (${_.size(profileIds)})`}</button>}>
                        <div className='profile-fill-statistics--profile-selector--header'>
                            <input className='profile-fill-statistics--profile-selector--search-input'
                                type={'text'}
                                value={profileSearchInput}
                                placeholder='Search Profiles'
                                spellCheck={false}
                                onChange={(e) => { this.setState({ profileSearchInput: e.target.value }) }} />
                            <button
                                disabled={_.size(_filteredProfileItems) > MAX_PROFILE_SIZE}
                                onClick={() => {
                                    const _newProfileIds = _.cloneDeep(profileIds)
                                    _.forEach(_filteredProfileItems, _profileItem => {
                                        _newProfileIds.push(_profileItem.id)
                                        const { RUNNING_MODE, PEER_PROFILE_NAME, PEER_PROFILE_SERVER } = _.get(_profileItem, 'params') || {}
                                        if (['QUOTE', 'HEDGE'].includes(RUNNING_MODE) && areAllValuesNonEmpty([PEER_PROFILE_NAME, PEER_PROFILE_SERVER])) {
                                            const _peerProfileId = `${PEER_PROFILE_NAME}_${PEER_PROFILE_SERVER}`
                                            if (_.has(profileItems, _peerProfileId)) {
                                                _newProfileIds.push(_peerProfileId)
                                            }
                                        }
                                    })
                                    this.setState({ profileIds: _.uniq(_newProfileIds) })
                                }}>{`Select All`}</button>
                        </div>
                        <div className='profile-fill-statistics--profile-selector--list'>
                            {_.map(_filteredProfileItems, _profileItem => {
                                const _id = `${_profileItem.name}_${_profileItem.hostname}`
                                const _isSelected = profileIds.includes(_id)
                                return (
                                    <div className='profile-fill-statistics--profile-selector--item' key={_id}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            _handleToggleSelectingProfile(_id)
                                        }}>
                                        <Checkbox checked={_isSelected} />
                                        <label>{_profileItem.name}</label>
                                        <span>{_profileItem.hostname}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </Popup>
                    <button className='profile-fill-statistics--profile-selector--clear-all-button'
                        onClick={() => {
                            this.setState({
                                profileIds: [],
                                fillStatistics: {
                                    trades: [],
                                    fees: []
                                }
                            }) 
                        }}>{'Clear All'}</button>
                </div>
                {_.size(profileIds) > MAX_PROFILE_SIZE && <div className='profile-fill-statistics--profile-selector--profile-size-warning'>{`The number of profiles selected cannot exceed ${MAX_PROFILE_SIZE}`}</div>}
                <div className='profile-fill-statistics--profile-selector--selected-list'>
                    {_.map(profileIds, _profileId => {
                        const { name, hostname } = _.get(profileItems, _profileId) || {}
                        return (
                            <div className='profile-fill-statistics--profile-selector--selected-item' key={_profileId}>
                                <label>{name}</label>
                                <span>{hostname}</span>
                                <button onClick={() => { _handleToggleSelectingProfile(_profileId) }}><FaXmark /></button>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    render () {
        const { isFetching, profileIds, searchString, portfolio, instrumentType } = this.state
        const { profileItems, profileId, shouldHaveSearchInput, shouldHaveProfileSelector } = this.props
    
        const defaultProfileItem = profileItems[profileId] || {}
        const { RUNNING_MODE, PEER_PROFILE_NAME, PEER_PROFILE_SERVER } = _.get(defaultProfileItem, 'params') || {}
        const defaultProfileDisplayName = ['QUOTE', 'HEDGE'].includes(RUNNING_MODE) && _.every([PEER_PROFILE_NAME, PEER_PROFILE_SERVER], v => !_.isEmpty(v)) 
            ? (RUNNING_MODE === 'QUOTE' ? `${defaultProfileItem.name} & ${PEER_PROFILE_NAME}` : `${PEER_PROFILE_NAME} & ${defaultProfileItem.name}`)
            : defaultProfileItem.name
    
        const portfolioNames = getPortfolioNames()
        const portfolioOptions = _.concat([{
            value: ALL,
            name: ALL
        }], _.map(portfolioNames, portfolioName => {
            return {
                value: portfolioName,
                name: portfolioName
            }
        }))

        const instrumentTypeOptions = _.concat({
            value: ALL,
            name: ALL
        }, _.map(_.omit(INSTRUMENT_TYPES, ['INDEX', 'REPO', 'UNKOWN']), instrument => {
            return {
                value: instrument,
                name: instrument
            }
        }))

        return (
            <div className='profile-fill-statistics'>
                {!shouldHaveProfileSelector && !_.isEmpty(defaultProfileItem) ?
                <div className='profile-fill-statistics--header'>
                    <label>{'Fill Statistics'}</label>
                    {isFetching && <span className='profile-fill-statistics--header--is-fetching'>{'Fetching...'}</span>}
                    <span className='profile-fill-statistics--header--profile-name'>{defaultProfileDisplayName}</span>
                </div>
                : this.ProfileSelector()}
                {this.DateTimeTabs()}
                <div className='profile-fill-statistics--date-time-inputs'>
                    {this.DateTimeInput('from')}
                    <span>{' - '}</span>
                    {this.DateTimeInput('to')}
                    <button className='profile-fill-statistics--date-time-inputs--go-button' 
                        disabled={isFetching || _.isEmpty(profileIds) || _.size(profileIds) > MAX_PROFILE_SIZE}
                        onClick={() => {
                            this._getFillStatistics()
                        }}>{'Go'}</button>
                </div>
                {shouldHaveSearchInput && 
                <div className='profile-fill-statistics--search-input-wrapper'>
                    <div className='profile-fill-statistics--portfolio' onClick={(e) => { e.stopPropagation() }}>
                        <span>{'Portfolio'}</span>
                        <SearchSelect 
                            hasClearButton
                            value={portfolio}
                            options={portfolioOptions} 
                            optionPosition={'bottom'}
                            onChange={(newOption) => { 
                                this.setState({ portfolio: newOption.value }, () => { this._getFillStatistics() }) 
                            }} 
                            onClickClearButton={() => { 
                                this.setState({ portfolio: ALL }, () => { this._getFillStatistics() }) 
                            }} />
                    </div>
                    <div className='profile-fill-statistics--instrument' onClick={(e) => { e.stopPropagation() }}>
                        <span>{'Instrument'}</span>
                        <SearchSelect 
                            hasClearButton
                            value={instrumentType}
                            options={instrumentTypeOptions} 
                            optionPosition={'bottom'}
                            onChange={(newOption) => { 
                                this.setState({ instrumentType: newOption.value }) 
                            }} 
                            onClickClearButton={() => { 
                                this.setState({ instrumentType: ALL }) 
                            }} />
                    </div>
                    <input className='profile-fill-statistics--search-input' 
                        value={searchString}
                        autoFocus
                        spellCheck={false}
                        placeholder={'Search Symbols'} 
                        onChange={(e) => { this.setState({ searchString: e.target.value }) }} />
                </div>}
                {this.Result()}
            </div>
        )
    }
}

ProfileFillStatistics.propTypes = {
    dispatch: PropTypes.func.isRequired,
    profileItems: PropTypes.object.isRequired,
    symbolItems: PropTypes.object.isRequired,
    pricings: PropTypes.object.isRequired,

    profileId: PropTypes.string,
    shouldIgnoreProfileHostname: PropTypes.bool,
    shouldHaveSearchInput: PropTypes.bool,
    shouldHaveProfileSelector: PropTypes.bool
}

function mapStateToProps (state) {
    return {
        profileItems: state.profile.items,
        symbolItems: state.symbol.items,
        pricings: state.symbol.pricings
    }
}

export default connect(mapStateToProps)(ProfileFillStatistics)