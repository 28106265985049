import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import BigNumber from 'bignumber.js'
import moment from 'moment'
import _ from 'lodash'

import { FiChevronsDown, FiChevronsUp } from 'react-icons/fi'
import { BiTargetLock } from 'react-icons/bi'
import { getSymbolAttributeByName, INSTRUMENT_TYPES, isDynamicFundingRateSymbol } from '../../util/symbolUtil'
import { AutoSizer, Table, Column } from 'react-virtualized'
import { isMetSearchStringCriteria } from '../../util/util'

import Checkbox from '../common/checkbox/Checkbox'
import dotProp from 'dot-prop-immutable'

const SORT_ORDERS = {
    ASC: 'ASC',
    DESC: 'DESC'
}

const TABLE_ROW_TYPES = {
    PAIR_GROUP: 'PAIR_GROUP',
    SYMBOL: 'SYMBOL'
}

const TableRow = ({ type=TABLE_ROW_TYPES.SYMBOL, data={} }) => {
    return {
        type,
        data
    }
}

const Spread = ({ value='', premium='' }) => {
    return {
        value,
        premium
    }
}

const cachedWorkspaceComponentStates = {}

class SpreadAnalyzer extends Component {
    constructor (props) {
        super(props)
        this.state = _.get(cachedWorkspaceComponentStates, props.workspaceComponentId, {
            shouldUseBidAskPrice: true,
            shouldPickUSDQuoteOnly: true,
            globalSearchString: '',
            searchStringPerPair: {},
            quoteSymbolPerPair: {},
            pairNamesShouldShowItems: [],
            sortBy: null,
            sortOrder: SORT_ORDERS.DESC
        })
        this.tableWrapperNode = null
    }

    componentWillUnmount () {
        const { workspaceComponentId } = this.props
        if (!_.isEmpty(workspaceComponentId)) {
            cachedWorkspaceComponentStates[workspaceComponentId] = _.cloneDeep(this.state)
        }
    }

    _getSymbolExpiry (symbolName) {
        const { symbolItems } = this.props
        const { instrumentType } = getSymbolAttributeByName(symbolName)
        return instrumentType === INSTRUMENT_TYPES.FUTURE ? _.get(symbolItems, `${symbolName}.expiry`) : null
    }

    _getTableRows () {
        const { shouldUseBidAskPrice, globalSearchString, searchStringPerPair, quoteSymbolPerPair, shouldPickUSDQuoteOnly, pairNamesShouldShowItems, sortBy, sortOrder } = this.state
        const { pricings, fundingRates } = this.props
        const now = moment()

        const filteredPricings = _.filter(pricings, pricing => {
            const { instrumentType, quote } = getSymbolAttributeByName(pricing.symbolName)
            return [INSTRUMENT_TYPES.INDEX, INSTRUMENT_TYPES.SPOT, INSTRUMENT_TYPES.SWAP, INSTRUMENT_TYPES.FUTURE].includes(instrumentType) 
                && isMetSearchStringCriteria(`${pricing.symbolName} ${this._getSymbolExpiry(pricing.symbolName)}`, globalSearchString)
                && (!shouldPickUSDQuoteOnly || ['BUSD', 'USDT', 'USDC', 'USD'].includes(quote))
        })
        const pairGroups = _.groupBy(filteredPricings, pricing => {
            const { base, quote } = getSymbolAttributeByName(pricing.symbolName)
            return `${base}/${['BUSD', 'USDT', 'USDC'].includes(quote) ? 'USD' : quote}`
        })
        const tableRows = []
        _.forEach(pairGroups, (groupPricings, pair) => {
            const pairSearchString = _.get(searchStringPerPair, pair, '')
            const filteredGroupPricings = _.filter(groupPricings, pricingItem => isMetSearchStringCriteria(`${pricingItem.symbolName} ${this._getSymbolExpiry(pricingItem.symbolName)}`, pairSearchString))
            const pricingPerSymbol = _.keyBy(filteredGroupPricings, 'symbolName')
            const quoteSymbolPricing = pricingPerSymbol[_.get(quoteSymbolPerPair, pair)] 
                || _.find(pricingPerSymbol, pricing => pricing.symbolName.includes('SPT'))
                || filteredGroupPricings[0]
                || {}
            
            const _quoteSymbolBid = BigNumber(quoteSymbolPricing.bid || 0)
            const _quoteSymbolAsk = BigNumber(quoteSymbolPricing.ask || 0)
            const _quoteSymbolLast = BigNumber(quoteSymbolPricing.last || 0)
            const quoteSymbolExpiry = this._getSymbolExpiry(quoteSymbolPricing.symbolName)
            const quoteExpiryInSeconds = _.isNil(quoteSymbolExpiry) ? null : moment(quoteSymbolExpiry).diff(now, 'seconds')

            tableRows.push(TableRow({
                type: TABLE_ROW_TYPES.PAIR_GROUP,
                data: {
                    pair,
                    size: _.size(filteredGroupPricings)
                }
            }))

            if (pairNamesShouldShowItems.includes(pair)) {
                let rows = []
                _.forEach(filteredGroupPricings, pricingItem => {
                    const { symbolName, bid, ask, last, timestamp, bidAskUpdateTimestamp } = pricingItem
                    const { base, quote, instrumentType, exchangeName } = getSymbolAttributeByName(symbolName)
                    const expiry = this._getSymbolExpiry(symbolName)
                    const spreads = []
                    const annualizedSpreads = []
    
                    if (!shouldUseBidAskPrice) {
                        if (_quoteSymbolLast.gt(0) && !_.isNil(last)) {
                            const _buyCurrentAndSellQuoteSpreadValue = _quoteSymbolLast.minus(last)
                            spreads.push(
                                Spread({
                                    value: _buyCurrentAndSellQuoteSpreadValue.toString(),
                                    premium: _buyCurrentAndSellQuoteSpreadValue.div(_quoteSymbolLast).toString()
                                }),
                                Spread({
                                    value: _buyCurrentAndSellQuoteSpreadValue.negated().toString(),
                                    premium: _buyCurrentAndSellQuoteSpreadValue.negated().div(_quoteSymbolBid).toString()
                                })
                            )
                        }
                    } else {
                        const _bid = BigNumber(bid || 0)
                        const _ask = BigNumber(ask || 0)
                        if (_bid.gt(0) && _ask.gt(0)) {
                            if (_quoteSymbolBid.gt(0) && _quoteSymbolAsk.gt(0)) {
                                const _buyCurrentAndSellQuoteSpreadValue = _quoteSymbolBid.minus(_ask)
                                const _buyQuoteAndSellCurrentSpreadValue = _bid.minus(_quoteSymbolAsk) 
                                spreads.push(
                                    Spread({
                                        value: _buyCurrentAndSellQuoteSpreadValue.toString(),
                                        premium: _buyCurrentAndSellQuoteSpreadValue.div(_quoteSymbolBid).toString()
                                    }),
                                    Spread({
                                        value: _buyQuoteAndSellCurrentSpreadValue.toString(),
                                        premium: _buyQuoteAndSellCurrentSpreadValue.div(_quoteSymbolAsk).toString()
                                    })
                                )
                            } else if (_quoteSymbolLast.gt(0)) {
                                const _buyCurrentAndSellQuoteSpreadValue = _quoteSymbolLast.minus(_ask)
                                const _buyQuoteAndSellCurrentSpreadValue = _bid.minus(_quoteSymbolLast)
                                spreads.push(
                                    Spread({
                                        value: _buyCurrentAndSellQuoteSpreadValue.toString(),
                                        premium: _buyCurrentAndSellQuoteSpreadValue.div(_quoteSymbolLast).toString()
                                    }),
                                    Spread({
                                        value: _buyQuoteAndSellCurrentSpreadValue.toString(),
                                        premium: _buyQuoteAndSellCurrentSpreadValue.div(_quoteSymbolLast).toString()
                                    })
                                )
                            }
                        }
                    }
    
                    if (_.size(spreads) === 2) {
                        const expiryInSeconds = _.isNil(expiry) ? null : moment(expiry).diff(now, 'seconds')
                        let expiryForAnnualizedSpreadsCalculation
                        if (Number(quoteExpiryInSeconds) > 0 || Number(expiryInSeconds) > 0) {
                            expiryForAnnualizedSpreadsCalculation = Math.abs(Number(quoteExpiryInSeconds) - Number(expiryInSeconds))
                        }
                        if (Number(expiryForAnnualizedSpreadsCalculation) > 0) {
                            annualizedSpreads.push(
                                BigNumber(spreads[0].premium).div(expiryForAnnualizedSpreadsCalculation).times(31536000).toString(),
                                BigNumber(spreads[1].premium).div(expiryForAnnualizedSpreadsCalculation).times(31536000).toString()
                            )
                        }
                    }

                    rows.push(TableRow({
                        type: TABLE_ROW_TYPES.SYMBOL,
                        data: {
                            pair,
                            symbolName,
                            exchangeName,
                            base, 
                            quote, 
                            instrumentType,
                            bid, 
                            ask, 
                            last, 
                            timestamp,
                            bidAskUpdateTimestamp,
                            expiry,
                            fundingRateItem: _.get(fundingRates, symbolName),
                            spreads,
                            annualizedSpreads,
                            isQuoteSymbol: _.get(quoteSymbolPricing, 'symbolName') === symbolName
                        }
                    }))
                })

                if (!_.isEmpty(rows)) {
                    if (!_.isNil(sortBy)) {
                        rows = _.sortBy(rows, row => {
                            const { spreads, annualizedSpreads, fundingRateItem, instrumentType, exchangeName } = row.data
                            return sortBy === 'symbolName' ? row.data.symbolName
                                : sortBy === 'spreads' ? (
                                    _.size(annualizedSpreads) === 2 ? BigNumber.max(annualizedSpreads[0], annualizedSpreads[1]).toNumber()
                                    : _.size(spreads) === 2 ? BigNumber.max(spreads[0].premium, spreads[1].premium).toNumber()
                                    : -Number.MAX_SAFE_INTEGER
                                )
                                : sortBy === 'funding_rates' ? (
                                    !_.isNil(fundingRateItem) ? Number(_.get(fundingRateItem, 'current_funding_rate', 0)) * ((['FTX', 'DYDX'].includes(exchangeName) || instrumentType === INSTRUMENT_TYPES.SPOT ? 8 : 1)) : null
                                )
                                : row.data.expiry
                        })
                        if (sortOrder === SORT_ORDERS.DESC || sortBy === 'spreads') {
                            _.reverse(rows)
                        }
                    }
                    tableRows.push(...rows)
                }
            }
        })

        return tableRows
    }

    Header () {
        const { pairNamesShouldShowItems, globalSearchString, shouldPickUSDQuoteOnly, shouldUseBidAskPrice } = this.state
        return (
            <div className='spread-analyzer--header'>
                <div className='spread-analyzer--header--main'>
                    <div className='spread-analyzer--header--title'>
                        <label>{'Spread Analyzer'}</label>
                    </div>
                    <button className='spread-analyzer--header--toggle-group-button' onClick={() => {
                        if (!_.isEmpty(pairNamesShouldShowItems)) {
                            this.setState({ pairNamesShouldShowItems: [] })
                        } else {
                            const tableRows = this._getTableRows()
                            const allPairNames = _.filter(tableRows, tableRow => tableRow.type === TABLE_ROW_TYPES.PAIR_GROUP).map(tableRow => tableRow.data.pair)
                            this.setState({ pairNamesShouldShowItems: allPairNames })
                        }
                    }}>
                        {!_.isEmpty(pairNamesShouldShowItems) ? 'Collapse All' : 'Expand All'}
                        {!_.isEmpty(pairNamesShouldShowItems) ? <FiChevronsUp /> : <FiChevronsDown />}
                    </button>
                    {/* <button className='spread-analyzer--header--reset-button' onClick={() => {
                        this.setState({
                            globalSearchString: '',
                            pairNamesShouldShowItems: []
                        })
                    }}>{'RESET'}</button> */}
                    <input className='spread-analyzer--header--search-input'
                        type={'text'}
                        spellCheck={false}
                        placeholder={'Search Coin, Symbol'} 
                        value={globalSearchString}
                        onChange={(e) => { this.setState({ globalSearchString: e.target.value }) }} />
                </div>
                <div className='spread-analyzer--header--check-boxes'>
                    <div className='spread-analyzer--header--check-boxes--item'>
                        <label>{'USD Quote Only'}</label>
                        <Checkbox 
                            checked={shouldPickUSDQuoteOnly}
                            onChange={(newChecked) => {
                                this.setState({ shouldPickUSDQuoteOnly: newChecked })
                            }} />
                    </div>
                    <div className='spread-analyzer--header--check-boxes--item'>
                        <label>{'Use Bid/Ask'}</label>
                        <Checkbox 
                            checked={shouldUseBidAskPrice}
                            onChange={(newChecked) => {
                                this.setState({ shouldUseBidAskPrice: newChecked })
                            }} />
                    </div>
                </div>
            </div>
        )
    }

    GroupRow (groupData={}) {
        const { searchStringPerPair } = this.state
        const { pair, size } = groupData
        return (
            <div className='spread-analyzer--group-row'>
                <div className='spread-analyzer--group-row--name'>{pair}</div>
                <div className='spread-analyzer--group-row--size'>{`(${size})`}</div>
                <input className='spread-analyzer--group-row--search-input' 
                    type={'text'}
                    spellCheck={false}
                    placeholder={'Filter Symbol'} 
                    value={_.get(searchStringPerPair, pair, '')}
                    onClick={(e) => { e.stopPropagation() }}
                    onChange={(e) => { 
                        this.setState({ searchStringPerPair: dotProp.set(searchStringPerPair, pair, e.target.value) })
                    }}/>
            </div>
        )
    }

    _getExpiryDiffDisplayValue (expiryMoment, now) {
        const diffInSeconds = expiryMoment.diff(now, 'seconds')
        let value = '', unit = ''
        if (diffInSeconds >= 86400) {
            value = Math.floor(diffInSeconds/86400)
            unit = `Day${value > 1 ? 's' : ''}`
        } else if (diffInSeconds >= 3600) {
            value = Math.floor(diffInSeconds/3600)
            unit = `Hour${value > 1 ? 's' : ''}`
        } else if (diffInSeconds >= 60) {
            value = Math.floor(diffInSeconds/60)
            unit = `Minute${value > 1 ? 's' : ''}`
        } else if (diffInSeconds > 0) {
            value = Math.floor(diffInSeconds)
            unit = `Second${value > 1 ? 's' : ''}`
        }
        return `${value} ${unit}`
    }

    renderSpreadValue (spreadValue) {
        const _value = BigNumber(spreadValue || 0)
        return BigNumber(_value.toPrecision(5)).toString()
    }

    render () {
        const { quoteSymbolPerPair, pairNamesShouldShowItems, sortBy, sortOrder, shouldUseBidAskPrice } = this.state
        const tableRows = this._getTableRows()
        const now = moment()

        return (
            <div className='spread-analyzer'>
                {this.Header()}
                <div className='spread-analyzer--table-wrapper' ref={(node) => { this.tableWrapperNode = node }}>
                    <AutoSizer>
                        {({ width, height }) => (
                            <Table
                                ref={(node) => { this.tableNode = node }}
                                className='spread-analyzer--table'
                                headerClassName={'spread-analyzer--table--header'}
                                headerHeight={27}
                                width={Math.max(width, 880)}
                                height={height}
                                rowCount={_.size(tableRows)}
                                rowGetter={({ index }) => tableRows[index]}
                                rowClassName={({ index }) => { 
                                    const tableRow = tableRows[index]
                                    let className = 'spread-analyzer--table--row'
                                    if (tableRow) {
                                        className += ` ${tableRow.type}${index % 2 === 1 ? ' odd-row' : ' even-row'}`
                                    } 
                                    return className
                                }}
                                rowHeight={({ index }) => { 
                                    const tableRow = tableRows[index]
                                    return tableRow.type === TABLE_ROW_TYPES.PAIR_GROUP ? 35 : 40
                                }}
                                overscanRowCount={5}
                                noRowsRenderer={() => (<div className='spread-analyzer--table--no-content'>{'No matched records.'}</div> )}
                                sort={({ sortBy: newTableSortBy }) => {
                                    this.setState({
                                        sortBy: newTableSortBy,
                                        sortOrder: newTableSortBy === sortBy ? (sortOrder === SORT_ORDERS.ASC ? SORT_ORDERS.DESC : SORT_ORDERS.ASC) : sortOrder
                                    })
                                }}
                                // onScroll={() => { this.handleScrollTableMain() }}
                                onRowClick={({ rowData }) => {
                                    if (rowData.type === TABLE_ROW_TYPES.PAIR_GROUP) {
                                        const { pair } = rowData.data
                                        const newPairNamesShouldShowItems = pairNamesShouldShowItems.includes(pair)
                                            ? _.without(pairNamesShouldShowItems, pair)
                                            : _.concat(pairNamesShouldShowItems, pair)
                                        this.setState({ pairNamesShouldShowItems: newPairNamesShouldShowItems })
                                    }
                                }}>
                                <Column dataKey={'is_quote'}
                                    label={'Selected Quote'}
                                    width={100}
                                    flexGrow={0}
                                    flexShrink={0}
                                    disableSort
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.PAIR_GROUP ? this.GroupRow(data)
                                            : (
                                                <button className={'spread-analyzer--quote-symbol-selection' + (_.get(data, 'isQuoteSymbol') ? ' quote-symbol' : '')}
                                                    onClick={() => {
                                                        this.setState({
                                                            quoteSymbolPerPair: dotProp.set(quoteSymbolPerPair, _.get(data, 'pair'), _.get(data, 'symbolName'))
                                                        })
                                                    }}>
                                                    {_.get(data, 'isQuoteSymbol') ? <BiTargetLock /> : null}
                                                </button>
                                            ) 
                                    }} />
                                <Column dataKey={'symbolName'}
                                    label={'Symbol'}
                                    headerClassName={'sortable' + (sortBy === 'symbolName' ? ' sorted' : '')}
                                    width={150}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.SYMBOL 
                                            ? (<div title={`Base: ${data.base}, Quote: ${data.quote}`}>{_.get(data, 'symbolName')}</div>)
                                            : null
                                    }} />
                                <Column dataKey={'timestamp'}
                                    label={'Timestamp'}
                                    // headerClassName={'sortable' + (sortBy === 'timestamp' ? ' sorted' : '')}
                                    disableSort
                                    width={100}
                                    flexGrow={0}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        if (type === TABLE_ROW_TYPES.SYMBOL) {
                                            const _bid = BigNumber(data.bid || 0)
                                            const _ask = BigNumber(data.ask || 0) 
                                            const _last = BigNumber(data.last || 0)
                                            if (shouldUseBidAskPrice && _bid.gt(0) && _ask.gt(0)) {
                                                return moment(data.bidAskUpdateTimestamp || data.timestamp).format('HH:mm:ss')
                                            } else if (_last.gt(0)) {
                                                return moment(data.timestamp).format('HH:mm:ss')
                                            }
                                        }
                                    }} />
                                <Column dataKey={'price'}
                                    label={'Price'}
                                    width={80}
                                    flexGrow={1}
                                    flexShrink={0}
                                    disableSort
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        if (type === TABLE_ROW_TYPES.SYMBOL) {
                                            const _bid = BigNumber(data.bid || 0)
                                            const _ask = BigNumber(data.ask || 0) 
                                            const _last = BigNumber(data.last || 0)
                                            if (shouldUseBidAskPrice && _bid.gt(0) && _ask.gt(0)) {
                                                return (
                                                    <div className='spread-analyzer--prices'>
                                                        <div className='spread-analyzer--price ask-price negative'>
                                                            <label>{'Ask'}</label>
                                                            <span>{_ask.toString()}</span>
                                                        </div>
                                                        <div className='spread-analyzer--price bid-price positive'>
                                                            <label>{'Bid'}</label>
                                                            <span>{_bid.toString()}</span>
                                                        </div>
                                                    </div>
                                                )
                                            } else if (_last.gt(0)) {
                                                return (
                                                    <div className='spread-analyzer--price last-price'>
                                                        <label>{`Last`}</label>
                                                        <span>{_last.toString()}</span>
                                                    </div>
                                                )
                                            }
                                        }
                                    }} />
                                <Column dataKey={'funding_rates'}
                                    label={'Funding % / Annualized %'}
                                    headerClassName={'sortable' + (sortBy === 'funding_rates' ? ' sorted' : '')}
                                    width={200}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        const fundingRateItem = _.get(data, 'fundingRateItem')
                                        if (type === TABLE_ROW_TYPES.SYMBOL && !_.isNil(fundingRateItem)) {
                                            const { instrumentType } = data
                                            const { current_funding_rate: currentFundingRate, timestamp: currentFundingRateTimestamp, indicative_funding_rate: nextFundingRate, next_period: nextPeriod } = fundingRateItem
                                            const _currentFundingRate = BigNumber(currentFundingRate || 0)
                                            const _nextFundingRate = BigNumber(nextFundingRate || 0)
                                            const _currentFundingRateClassName = _currentFundingRate.gt(0) ? 'positive' : _currentFundingRate.lt(0) ? 'negative' : null
                                            const _nextFundingRateClassName = _nextFundingRate.gt(0) ? 'positive' : _nextFundingRate.lt(0) ? 'negative' : null
       
                                            const isDynamicFundingRate = isDynamicFundingRateSymbol(data.symbolName)
                                            return (
                                                <div className='spread-analyzer--funding-rates'>
                                                    {!_.isEmpty(currentFundingRate) && (isDynamicFundingRate || moment(currentFundingRateTimestamp).isAfter(now)) &&
                                                    <div className='spread-analyzer--funding-rates--item'>
                                                        <label>
                                                            {!isDynamicFundingRate 
                                                            ? `${moment(currentFundingRateTimestamp).format('HH:mm')} (${this._getExpiryDiffDisplayValue(moment(currentFundingRateTimestamp), now)})`
                                                            : 'Current'}
                                                        </label>
                                                        <span className={_currentFundingRateClassName}>{`${_currentFundingRate.times(10000).toFixed(3)}%%`}</span>
                                                        <Fragment>
                                                            <span>{' / '}</span>
                                                            <span className={_currentFundingRateClassName}>{`${_currentFundingRate.times(['FTX', 'DYDX'].includes(_.get(data, 'exchangeName')) || instrumentType === INSTRUMENT_TYPES.SPOT ? 24 : 3).times(36500).toFixed(2)}%`}</span>
                                                        </Fragment>
                                                    </div>}
                                                    {!_.isEmpty(nextFundingRate) && (isDynamicFundingRate || moment(nextPeriod).isAfter(now)) &&
                                                    <div className='spread-analyzer--funding-rates--item'>
                                                        <label>
                                                            {!isDynamicFundingRate 
                                                            ? `${moment(nextPeriod).format('HH:mm')} (${this._getExpiryDiffDisplayValue(moment(nextPeriod), now)})`
                                                            : 'Next'}
                                                        </label>
                                                        <span className={_nextFundingRateClassName}>{`${_nextFundingRate.times(10000).toFixed(3)}%%`}</span>
                                                        {instrumentType === INSTRUMENT_TYPES.SWAP &&<Fragment>
                                                            <span>{' / '}</span>
                                                            <span className={_nextFundingRateClassName}>{`${_nextFundingRate.times(['FTX', 'DYDX'].includes(_.get(data, 'exchangeName')) || instrumentType === INSTRUMENT_TYPES.SPOT  ? 24 : 3).times(36500).toFixed(2)}%`}</span>
                                                        </Fragment>}
                                                    </div>}
                                                </div>
                                            )
                                        }
                                    }} />
                                <Column dataKey={'spreads'}
                                    label={'Spreads (Spd % / Annualized %)'}
                                    headerClassName={'sortable' + (sortBy === 'spreads' ? ' sorted' : '')}
                                    width={150}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        const spreads = _.get(data, 'spreads', [])
                                        const annualizedSpreads = _.get(data, 'annualizedSpreads', [])
                                        if (type === TABLE_ROW_TYPES.SYMBOL && !_.isEmpty(spreads) && !_.get(data, 'isQuoteSymbol')) {
                                            if (_.size(spreads) === 2) {
                                                return (
                                                    <div className='spread-analyzer--spreads'>
                                                        <div className={'spread-analyzer--spreads--item buy' + (BigNumber(_.get(spreads, '0.value') || 0).gt(0) ? ' profitable' : ' non-profitable')}>
                                                            <label>{'Buy'}</label>
                                                            <span className='spread-value'>{this.renderSpreadValue(_.get(spreads, '0.value'))}</span>
                                                            {'('}
                                                            <span>{`${BigNumber(_.get(spreads, '0.premium')).times(10000).toFixed(2)}%%`}</span>
                                                            {_.size(annualizedSpreads) === 2 && 
                                                            <span>{` / ${BigNumber(_.get(annualizedSpreads, '0')).times(100).toFixed(2)}%`}</span>}
                                                            {')'}
                                                        </div>
                                                        <div className={'spread-analyzer--spreads--item sell' + (BigNumber(_.get(spreads, '1.value') || 0).gt(0) ? ' profitable' : ' non-profitable')}>
                                                            <label>{'Sell'}</label>
                                                            <span className='spread-value'>{this.renderSpreadValue(_.get(spreads, '1.value'))}</span>
                                                            {'('}
                                                            <span>{`${BigNumber(_.get(spreads, '1.premium')).times(10000).toFixed(2)}%%`}</span>
                                                            {_.size(annualizedSpreads) === 2 && 
                                                            <span>{` / ${BigNumber(_.get(annualizedSpreads, '1')).times(100).toFixed(2)}%`}</span>}
                                                            {')'}
                                                        </div>
                                                    </div>
                                                )
                                            } else if (_.size(spreads) === 1) {
                                                return (
                                                    <div className={'spread-analyzer--spreads--item' + (BigNumber(_.get(spreads, '0.value') || 0).gt(0) ? ' profitable' : ' non-profitable')}>
                                                        <span className='spread-value'>{BigNumber(_.get(spreads, '0.value')).toPrecision(5)}</span>
                                                        {_.size(annualizedSpreads) === 1 && <span>{` / ${BigNumber(_.get(annualizedSpreads, '0')).times(100).toFixed(0)}%`}</span>}
                                                    </div>
                                                )
                                            }
                                        }
                                    }} />
                                <Column dataKey={'expiry'}
                                    label={'Expiry'}
                                    headerClassName={'sortable' + (sortBy === 'expiry' ? ' sorted' : '')}
                                    width={90}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        if (type === TABLE_ROW_TYPES.SYMBOL && !_.isNil(data.expiry)) {
                                            const expiryMoment = moment(data.expiry)
                                            const diffInSeconds = expiryMoment.diff(now, 'seconds')
                                            if (diffInSeconds > 0) {
                                                return (
                                                    <div className='spread-analyzer--expiry'>
                                                        <div className='spread-analyzer--expiry--date'>{expiryMoment.format('YYYY-MM-DD HH:mm')}</div>
                                                        <div className='spread-analyzer--expiry--diff'>
                                                            {this._getExpiryDiffDisplayValue(expiryMoment, now)}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        }
                                    }} />
                                {/* <Column dataKey={'annualized_spreads_percentage'}
                                    label={'Annualized Spreads%'}
                                    headerClassName={'sortable' + (sortBy === 'annualized_spreads_percentage' ? ' sorted' : '')}
                                    width={150}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        const annualizedSpreads = _.get(data, 'annualizedSpreads', [])
                                        if (type === TABLE_ROW_TYPES.SYMBOL && !_.isNil(data.expiry) && !_.isEmpty(annualizedSpreads) && !_.get(data, 'isQuoteSymbol')) {
                                            if (_.size(annualizedSpreads) === 2) {
                                                return (
                                                    <div className='spread-analyzer--spreads'>
                                                        <div className={'spread-analyzer--spreads--item' + (BigNumber(_.get(annualizedSpreads, '0') || 0).gt(0) ? ' profitable' : ' non-profitable')}>
                                                            <label>{'Buy'}</label>
                                                            <span>{`(${BigNumber(_.get(annualizedSpreads, '0')).times(10000).toFixed(0)}%%)`}</span>
                                                        </div>
                                                        <div className={'spread-analyzer--spreads--item' + (BigNumber(_.get(annualizedSpreads, '1') || 0).gt(0) ? ' profitable' : ' non-profitable')}>
                                                            <label>{'Sell'}</label>
                                                            <span>{`(${BigNumber(_.get(annualizedSpreads, '1')).times(10000).toFixed(0)}%%)`}</span>
                                                        </div>
                                                    </div>
                                                )
                                            } else if (_.size(annualizedSpreads) === 1) {
                                                return (
                                                    <div className={'spread-analyzer--spreads--item' + (BigNumber(_.get(annualizedSpreads, '0') || 0).gt(0) ? ' profitable' : ' non-profitable')}>
                                                        <span>{`(${BigNumber(_.get(annualizedSpreads, '0')).times(10000).toFixed(0)}%%)`}</span>
                                                    </div>
                                                )
                                            }
                                        }
                                    }} /> */}
                            </Table>            
                        )}
                    </AutoSizer>
                </div>
            </div>
        )
    }
}

SpreadAnalyzer.propTypes = {
    symbolItems: PropTypes.object.isRequired,
    pricings: PropTypes.object.isRequired,
    fundingRates: PropTypes.object.isRequired,

    workspaceComponentId: PropTypes.string
}

function mapStateToProps (state) {
    return {
        symbolItems: state.symbol.items,
        pricings: state.symbol.pricings,
        fundingRates: state.symbol.fundingRates
    }
}

export default connect(mapStateToProps)(SpreadAnalyzer)