import React, { memo } from 'react'

import PropTypes from 'prop-types'
import dotProp from 'dot-prop-immutable'

import Checkbox from '../common/checkbox/Checkbox'

export const ProfileViewStruct = ({ shouldShowSpreadDiffData=false, shouldShowOpenSize=true }) => {
    return {
        shouldShowSpreadDiffData,
        shouldShowOpenSize
    }
}

const ProfileViewShape = PropTypes.shape({
    shouldShowSpreadDiffData: PropTypes.bool,
    shouldShowOpenSize: PropTypes.bool
})

function ProfileViewConfiguration ({ profileView, onChange }) {
    return (
        <div className='profile-view-configuration'>
            <div className='profile-view-configuration--header'>{'Profile View Configuration'}</div>
            <div className='profile-view-configuration--main'>
                <div onClick={(e) => { e.stopPropagation() }}>
                    <label>{'Show Spread Diff'}</label>
                    <Checkbox
                        checked={profileView?.shouldShowSpreadDiffData}
                        onChange={(_newChecked) => { onChange(dotProp.set(profileView, 'shouldShowSpreadDiffData', _newChecked)) }} />
                </div>
                <div onClick={(e) => { e.stopPropagation() }}>
                    <label>{'Show Open Size'}</label>
                    <Checkbox
                        checked={profileView?.shouldShowOpenSize}
                        onChange={(_newChecked) => { onChange(dotProp.set(profileView, 'shouldShowOpenSize', _newChecked)) }} />
                </div>
            </div>
        </div>
    )
}

ProfileViewConfiguration.propTypes = {
    profileView: ProfileViewShape,
    onChange: PropTypes.func.isRequired
}

ProfileViewConfiguration.defaultProps = {
    onChange: () => {}
}

export default memo(ProfileViewConfiguration)