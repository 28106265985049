import React, { memo, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import useEvent from 'react-use-event-hook'
import { useMountedState } from 'react-use'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import dotProp from 'dot-prop-immutable'
import { utils } from 'ethers'
import _ from 'lodash'

import Popup from '../common/popup/Popup'

import { sendTransactionWithWallet } from './blockchainThunk'
import { ABI as VAULT_ABI } from './ABIs/Vault'
import { parseABI } from './blockchainUtil'

import { getDefaultChain } from '../../wagmiConfig'
import { formattedHex } from '../../util/formatUtil'
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector'
import { selectWallet } from './blockchainReducer'
import { fetchVaultOnChainDynamicData } from './blockchainAction'
import { FaTimes } from 'react-icons/fa'
import { isAddress } from 'viem'

const InputValueStruct = ({ value='', decimals=null }) => {
    return {
        value,
        decimals
    }
}

function VaultExecutePopup ({ className, triggerClassName, triggerLabel, targetContract, vaultAddress, abi, functionSigHashOrName, onSuccess }) {

    const isMounted = useMountedState()
    const dispatch = useDispatch()

    const { address: walletAddress } = useShallowEqualSelector(state => selectWallet(state))
    const [inputValues, setInputValues] = useState([])
    const [isSendingTx, setIsSendingTx] = useState(false)
    const [message, setMessage] = useState('')
    const [txHash, setTxHash] = useState('')

    const defaultChain = getDefaultChain()

    const iface = useMemo(() => {
        return new utils.Interface(abi)
    }, [abi])

    const fragment = useMemo(() => {
        let _fragment
        try {
            _fragment = iface.getFunction(functionSigHashOrName)
        } catch {
            _fragment = null
        }
        return _fragment
    }, [iface, functionSigHashOrName])

    const { name: functionName, inputs } = fragment || {}

    const resetMessageAndTxHash = useEvent(() => {
        if (!_.isEmpty(message)) {
            setMessage('')
        }
        if (!_.isEmpty(txHash)) {
            setTxHash('')
        }
    })

    const handleOpenPopup = useEvent(() => {
        const initialInputValues = []
        _.forEach(inputs, input => {
            const { type } = input
            initialInputValues.push(InputValueStruct({
                value: '',
                decimals: (type || '').includes('int') ? 18 : null
            }))
        })
        setInputValues(initialInputValues) 
    })

    const handleClickConfirm = useEvent(() => {
        if (!isSendingTx && !_.isEmpty(targetContract) && isAddress(vaultAddress)) {
            setIsSendingTx(true)
            const values = _.map(inputValues, inputValue => {
                const { value, decimals } = inputValue
                return Number(decimals) > 0 ? utils.parseUnits(value, Number(decimals)) : value
            })
            const callData = iface.encodeFunctionData(functionName, values)
            sendTransactionWithWallet({
                address: vaultAddress,
                abi: parseABI(VAULT_ABI),
                functionName: 'exec',
                args: [targetContract, utils.parseEther('0'), callData]
            })
            .then((receitp) => {
                const { transactionHash } = receitp || {}
                if (isMounted() && !_.isEmpty(transactionHash)) {
                    setTxHash(transactionHash)
                }
                dispatch(fetchVaultOnChainDynamicData(vaultAddress))
                onSuccess(receitp)
            })
            .catch(error => {
                console.error('VaultExecutePopup handleClickConfirm error: ', error)
                if (isMounted()) {
                    setMessage(error.toString())
                }
            })
            .finally(() => {
                if (isMounted()) {
                    setIsSendingTx(false)
                }
            })
        }
    })

    return (
        <Popup
            className={'vault-execute-popup' + (!_.isEmpty(className) ? ` ${className}` : '')}
            on={'click'}
            trigger={<button className={'vault-execute-popup--trigger' + (!_.isEmpty(triggerClassName) ? ` ${triggerClassName}` : '')}>{triggerLabel || functionName || 'Execute'}</button>}
            onOpen={() => { handleOpenPopup() }}>
            <div className='vault-execute-popup--name'>
                {functionName}
            </div>
            {!_.isEmpty(inputs) && <div className='vault-execute-popup--inputs'>
                {_.map(inputs, (input, index) => {
                    const { name: inputName, type } = input
                    return (
                        <div className='vault-execute-popup--inputs--item' key={index}>
                            <span className='vault-execute-popup--inputs--item--type'>{type}</span>
                            <label>{inputName}</label>
                            <div className='vault-execute-popup--inputs--item--input-wrapper'>
                                {(type || '').includes('int') &&
                                <div className='vault-execute-popup--inputs--item--decimals-input'>
                                    <label>{'Decimals'}</label>
                                    <input
                                        type={'number'}
                                        min={0} 
                                        value={_.get(inputValues, `${index}.decimals`) ?? ''} 
                                        onChange={(e) => {
                                            resetMessageAndTxHash()
                                            setInputValues(dotProp.set(inputValues, `${index}.decimals`, e.target.value)) 
                                        }} />
                                </div>}
                                <input
                                    spellCheck={false}
                                    autoFocus={index === 0}
                                    value={_.get(inputValues, `${index}.value`) ?? ''}
                                    onChange={(e) => {
                                        resetMessageAndTxHash()
                                        setInputValues(dotProp.set(inputValues, `${index}.value`, e.target.value)) 
                                    }} />
                            </div>
                        </div>
                    )
                })}    
            </div>}
            {!_.isEmpty(message) &&
            <div className='vault-execute-popup--message'>
                <div>{message}</div>
                <button onClick={() => { setMessage('') }}><FaTimes /></button>
            </div>}
            {!_.isEmpty(txHash) &&
            <div className='vault-execute-popup--message'>
                <div>
                    {`Confirmed: `}
                    <Link to={`${defaultChain?.blockExplorers?.etherscan?.url}/tx/${txHash}`} target='_blank'>{formattedHex(txHash)}</Link>
                </div>
                <button onClick={() => { setTxHash('') }}><FaTimes /></button>
            </div>}
            <button
                disabled={_.some(inputValues, inputVaule => _.isEmpty(inputVaule.value)) || isSendingTx || _.isEmpty(walletAddress)}
                onClick={() => {
                    resetMessageAndTxHash()
                    handleClickConfirm() 
                }}>
                {isSendingTx ? 'Waiting' : 'Confirm'}
            </button>
        </Popup>
    )
}

VaultExecutePopup.propTypes = {
    className: PropTypes.string,
    triggerClassName: PropTypes.string,
    triggerLabel: PropTypes.string,
    targetContract: PropTypes.string.isRequired,
    vaultAddress: PropTypes.string.isRequired,
    abi: PropTypes.array.isRequired,
    functionSigHashOrName: PropTypes.string,
    onSuccess: PropTypes.func
}

VaultExecutePopup.defaultProps = {
    onSuccess: () => {}
}

export default memo(VaultExecutePopup)

