import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux' 
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'

import SearchSelect from '../common/searchSelect/SearchSelect'
import { getOptionSymbolUnderlying, getSymbolAttributeByName } from '../../util/symbolUtil'
import { toNumberWithSmartPrecision } from '../../util/util'

class OptionImpliedVolatilityMatrix extends Component {

    constructor (props) {
        super(props)
        this.groupBys = {
            EXCHANGE_NAME: 'Exchange',
            TYPE: 'Option Type'
        }
        this.state = {
            groupBy: Object.keys(this.groupBys)[0]
        }
    }

    Header () {
        const { groupBy } = this.state
        return (
            <div className='option-implied-volatility-matrix--header'>
                <div className='option-implied-volatility-matrix--header--group-by-select'>
                    <span>{'Group By'}</span>
                    <SearchSelect
                        value={groupBy} 
                        hideSearchBar
                        options={_.map(this.groupBys, (name, value) => {
                            return { value, name }
                        })} 
                        onChange={(newOption) => { this.setState({ groupBy: newOption.value }) }} />
                </div>
            </div>
        )
    }

    Table (subGroupImpliedVolatilities, priceSide='BID') {
        const { volatilityRange } = this.props
        let strikePrices = []
        let expiryDates = []
        let values = {}
        let middleValue, range
        subGroupImpliedVolatilities.forEach(iv => {
            const { optionStrikePrice, optionExpiryDate } = getSymbolAttributeByName(iv.symbol)
            strikePrices.push(optionStrikePrice)
            expiryDates.push(optionExpiryDate)
            const value = _.get(iv, _.toLower(`${priceSide}_iv`)) 
            if (value >= Number(volatilityRange[0]) && value <= Number(volatilityRange[1])) {
                values[`${optionStrikePrice}--${optionExpiryDate}`] = value
            } 
        })
        strikePrices = _.sortBy(_.uniq(strikePrices), price => Number(price)).reverse()
        expiryDates = _.uniq(expiryDates).sort()

        const impliedVolatilityValues = Object.values(values)
        const minValue = _.min(impliedVolatilityValues)
        const maxValue = _.max(impliedVolatilityValues)
        if (_.isNumber(minValue) && _.isNumber(maxValue)) {
            middleValue = (minValue + maxValue) / 2
            range = maxValue - minValue
        }
        return (
            <table className='option-implied-volatility-matrix--table'>
                <tbody>
                    {strikePrices.map(strikePrice => {
                        return (
                            <tr key={strikePrice}>
                                <th>{strikePrice}</th>
                                {expiryDates.map(expiryDate => {
                                    const value = values[`${strikePrice}--${expiryDate}`]
                                    const opacity = Math.abs(value - middleValue) / (range / 2)
                                    return (
                                        <td key={expiryDate}
                                            style={{
                                                background: _.isNil(value) ? 'transparent' 
                                                    : value < middleValue ? `rgba(48, 165, 161, ${opacity})` 
                                                    : `rgba(230, 113, 124, ${opacity})`
                                            }}>
                                            {!_.isNil(value) ? toNumberWithSmartPrecision({ number: value, defaultPrecision: 4 }) : ''}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <td />
                        {expiryDates.map(expiryDate => {
                            return (
                                <td key={expiryDate}>{moment(expiryDate).format('MM-DD')}</td>
                            )
                        })}
                    </tr>
                </tfoot>
            </table>
        )
    }

    GroupMatrix (singleGroupOptions) {
        const { groupBy } = this.state
        const impliedVolatilitySubGroups = _.groupBy(singleGroupOptions, option => {
            const { exchangeName, originalType } = getSymbolAttributeByName(option.symbol)
            return groupBy === 'EXCHANGE_NAME' ? originalType : exchangeName
        })
        return (
            <div className='option-implied-volatility-matrix--group-matrix'>
                <div className='option-implied-volatility-matrix--group-matrix--header'>
                    <div className='option-implied-volatility-matrix--group-matrix--header--data' />
                    <div className='option-implied-volatility-matrix--group-matrix--header--data BID'>{'BID'}</div>
                    <div className='option-implied-volatility-matrix--group-matrix--header--data MAKR'>{'MARK'}</div>
                    <div className='option-implied-volatility-matrix--group-matrix--header--data ASK'>{'ASK'}</div>
                </div>
                <Fragment>
                    {_.map(_.keys(impliedVolatilitySubGroups).sort(), groupName => {
                        const impliedVolatilities = impliedVolatilitySubGroups[groupName]
                        return (
                            <div className='option-implied-volatility-matrix--group-matrix--row' key={groupName}>
                                <div className={`option-implied-volatility-matrix--group-matrix--row--data name ${groupName}`}>{groupName}</div>
                                <div className='option-implied-volatility-matrix--group-matrix--row--data bid'>{this.Table(impliedVolatilities, 'BID')}</div>
                                <div className='option-implied-volatility-matrix--group-matrix--row--data mark'>{this.Table(impliedVolatilities, 'MARK')}</div>
                                <div className='option-implied-volatility-matrix--group-matrix--row--data ask'>{this.Table(impliedVolatilities, 'ASK')}</div>
                            </div>
                        )
                    })}
                </Fragment>
            </div>
        )
    }

    render () {
        const { groupBy } = this.state
        const { optionSymbolAdditionalInfo, coin, withoutExpiryDates, strikePriceRange, pastHours } = this.props
        const now = moment()
        const validDataTimestamp = moment().add(-pastHours || 0, 'hours')
        const filteredOptions = _.filter(optionSymbolAdditionalInfo, iv => {
            const { symbol, timestamp } = iv
            const underlying = getOptionSymbolUnderlying(symbol)
            const { optionExpiryDate, optionStrikePrice } = getSymbolAttributeByName(symbol)
            return underlying === coin && moment(optionExpiryDate).endOf('day').isAfter(now)
                && optionStrikePrice >= Number(strikePriceRange[0]) && !withoutExpiryDates.includes(optionExpiryDate)
                && (_.isEmpty(strikePriceRange[1]) || optionStrikePrice <= Number(strikePriceRange[1]))
                && moment(timestamp).isAfter(validDataTimestamp)
        })
        const optionGroups = _.groupBy(filteredOptions, iv => {
            const { exchangeName, originalType } = getSymbolAttributeByName(iv.symbol)
            return groupBy === 'EXCHANGE_NAME' ? exchangeName : originalType
        })

        return (
            <div className='option-implied-volatility-matrix'>
                {this.Header()}
                <div className='option-implied-volatility-matrix--main'>
                    {_.map(optionGroups, (_options, _groupName) => {
                        return (
                            <div className='option-implied-volatility-matrix--group' key={_groupName}>
                                <div className={`option-implied-volatility-matrix--group--name ${_groupName}`}>{_groupName}</div>
                                <div className='option-implied-volatility-matrix--group--body'>{this.GroupMatrix(_options)}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}

OptionImpliedVolatilityMatrix.propTypes = {
    optionSymbolAdditionalInfo: PropTypes.object.isRequired,
    coin: PropTypes.string.isRequired,
    withoutExpiryDates: PropTypes.array,
    strikePriceRange: PropTypes.array,
    volatilityRange: PropTypes.array,
    pastHours: PropTypes.number
}

OptionImpliedVolatilityMatrix.defaultProps = {
    coin: 'BTC',
    withoutExpiryDates: [],
    strikePriceRange: [0, Number.POSITIVE_INFINITY],
    volatilityRange: [0, Number.POSITIVE_INFINITY]
}

function mapStateToProps (state) {
    return {
        optionSymbolAdditionalInfo: state.symbol.optionSymbolAdditionalInfo
    }
}

export default connect(mapStateToProps)(OptionImpliedVolatilityMatrix)
