import { v4 as uuidv4 } from 'uuid'

export const UPDATE_LAYOUT_BULK_TRANSFER_WINDOW_ID = 'UPDATE_LAYOUT_BULK_TRANSFER_WINDOW_ID'
export const CLEAR_LAYOUT_BULK_TRANSFER_WINDOW_ID = 'CLEAR_LAYOUT_BULK_TRANSFER_WINDOW_ID'
export const UPDATE_LAYOUT_BULK_TRANSFER_WINDOW_CONFIG = 'UPDATE_LAYOUT_BULK_TRANSFER_WINDOW_CONFIG'
export const CLEAR_LAYOUT_BULK_TRANSFER_WINDOW_CONFIG = 'CLEAR_LAYOUT_BULK_TRANSFER_WINDOW_CONFIG'

export function updateLayoutBulkTransferWindowId () {
    return (dispatch) => {
        dispatch({
            type: UPDATE_LAYOUT_BULK_TRANSFER_WINDOW_ID,
            windowId: uuidv4()
        })
    }
}

export function clearLayoutBulkTransferWindowId () {
    return (dispatch) => {
        dispatch({
            type: CLEAR_LAYOUT_BULK_TRANSFER_WINDOW_ID
        })
    }
}

export function updateLayoutBulkTransferWindowConfig (config) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_LAYOUT_BULK_TRANSFER_WINDOW_CONFIG,
            config
        })
    }
}

export function clearLayoutBulkTransferWindowConfig () {
    return (dispatch) => {
        dispatch({
            type: CLEAR_LAYOUT_BULK_TRANSFER_WINDOW_CONFIG
        })
    }
}