import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import dotProp from 'dot-prop-immutable'

import LegEditor from './LegEditor'
import Popup from '../common/popup/Popup'
import SaveButton from '../common/saveButton/SaveButton'

import { updateProfileParams } from './profileAction'

class LegPopup extends Component {
    constructor (props) {
        super(props)
        this.state = {
            legItem: this._getLegItem(),
            isSaving: false,
            isSaved: false,
            message: null
        }
    }

    componentDidUpdate (prevProps, prevState) {
        const { legItem } = this.state
        const { profile } = this.props
        if (!_.isEqual(prevProps.profile, profile)) {
            this._updateLegItem(this._getLegItem())
        }
        if (_.has(prevState, 'legItem.strategy.type') && !_.isEqual(prevState.legItem.strategy.type, legItem.strategy.type)) {
            window.dispatchEvent(new Event('resize'))
        }
    }

    _getLegItem () {
        const {profile, legNumber} = this.props
        return profile.legs[legNumber]
    }

    _updateLegItem (legItem) {
        this.setState({
            legItem
        })
    }

    handleClickSaveButton () {
        const { legItem } = this.state
        const { dispatch, profile, legNumber } = this.props
        const newProfile = dotProp.set(profile, `legs.${legNumber}`, legItem)
        this.setState({ isSaving: true })
        dispatch(updateProfileParams(profile.id, _.pick(newProfile, ['accounts', 'legs', 'params']))).then((response) => {
            this.setState({
                isSaving: false,
                isSaved: response && !_.isError(response) && response.status === 200,
                message: _.isError(response) ? response.toString() 
                    : (response && response.status === 403) ? `You are forbidden to update this profile's params`
                    : (response && response.status === 503) ? 'The server is under maintenance'
                    : null
            })
        })
    }


    render () {
        const { legItem, isSaving, isSaved, message } = this.state
        const { profile, legNumber } = this.props
        const strategyType = profile.legs[legNumber].strategy.type
        return (
            <Popup className='leg-popup'
                on={'click'}
                align={'horizontal'}
                trigger={<span className='leg-popup--trigger'>
                    <span className='leg-popup--trigger--title'>{legNumber === 1 ? 'Quote Symbol' : 'Hedge Symbol'}</span>
                    <span className={'leg-popup--strategy-type ' + strategyType}>{strategyType}</span>
                </span>}
                onOpen={() => {
                    this.setState({
                        legItem: this._getLegItem(),
                        isSaved: false,
                        message: null
                    })
                }}>
                    <div className='leg-popup--title'>{legNumber === 1 ? 'Quote Settings' : 'Hedge Settings'}</div>
                    <div className='leg-popup--editor'>
                        <LegEditor
                            profileId={profile.id}
                            legNumber={legNumber}
                            legItem={legItem}
                            disableSymbolModification 
                            isProfileStarted={profile.started}
                            symbolParamKeyFilter={(paramKey) => !_.startsWith(paramKey, 'QUOTE_ENET')}
                            onChangeLeg={(newLegItem) => {
                                this.setState({
                                    legItem: newLegItem,
                                    isSaved: false,
                                    message: null
                                })
                            }} />
                    </div>
                    {message && <div className='leg-popup--message horizontal-centered'>{message}</div>}
                    <SaveButton className='leg-popup--save-button horizontal-centered'
                        isSaving={isSaving}
                        isSaved={isSaved}
                        onClick={() => { this.handleClickSaveButton() }} />
            </Popup>
        )
    }
}

LegPopup.propTypes = {
    dispatch: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    legNumber: PropTypes.oneOf([1, 2]).isRequired
}

export default connect()(LegPopup)




