import React, { memo, useEffect, useState } from 'react'
import useEvent from 'react-use-event-hook'
import { useMountedState, useLocalStorage, useSessionStorage, useLatest } from 'react-use'
import { useDispatch } from 'react-redux'

import { useConnect, useNetwork, useSwitchNetwork } from 'wagmi'
import { createPublicClient, http, isAddress } from 'viem'
import { optimism } from 'viem/chains'
import _ from 'lodash'

import { BsCaretDownFill } from 'react-icons/bs'
import { FaTimes } from 'react-icons/fa'

import Popup from '../common/popup/Popup'
import VaultSynthetixPerps from './VaultSynthetixPerps'
import VaultTraders from './VaultTraders'
import VaultActions from './VaultActions'
import VaultTokens from './VaultTokens'

import { fetchVaultOnChainDynamicData, subscribeAccount, unsubscribeAccount } from './blockchainAction'
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector'
import { selectWallet } from './blockchainReducer'
import { formattedHex } from '../../util/formatUtil'
import { getDefaultChain } from '../../wagmiConfig'
import { VAULT_CHAIN } from './blockchainConfig'
import { readStorage } from './blockchainThunk'
import { bytes32ToAddr, getEthersJsonRpcProvider } from './blockchainUtil'

const DEFAULT_VAULT_ADDRESS = process.env.REACT_APP_DEFAULT_VAULT_ADDRESS

const SLOT = {
    GIT_VERSION: 0,
    OWNER: 1,
    PAUSER: 2,
    TRADERS: 3,
    ACTION_SET: 4
}

const provider = getEthersJsonRpcProvider({ chainId: VAULT_CHAIN.id })
const publicClient = createPublicClient({
    batch: {
        multicall: {
            wait: 3000
        }
    },
    chain: optimism,
    name: 'Public Client',
    transport: http('https://quaint-late-crater.optimism.quiknode.pro/703cf95e4c0a99fc2fb4a5359a4d854c1fbf4b44')
})

const _fetchGitVersion = async (contractAddress) => {
    return (await readStorage({
        provider,
        contract: contractAddress,
        path: [SLOT.GIT_VERSION]
    })).slice(-40)
}

const _fetchOnwer = async (contractAddress) => {
    return bytes32ToAddr(await readStorage({
        provider,
        contract: contractAddress,
        path: [SLOT.OWNER]
    }))
}

const _fetchPauser = async (contractAddress) => {
    return bytes32ToAddr(await readStorage({
        provider,
        contract: contractAddress,
        path: [SLOT.PAUSER]
    }))
}


function VaultContainer () {

    const dispatch = useDispatch()
    const isMounted = useMountedState()
    const { address: walletAddress } = useShallowEqualSelector(state => selectWallet(state))

    const { connect: connectWallet, connectors, pendingConnector, isLoading } = useConnect()
    const { chain } = useNetwork()
    const { switchNetwork } = useSwitchNetwork()

    const defaultChain = getDefaultChain()
    const isValidNetwork = chain?.id === defaultChain?.id

    const connector = _.get(connectors, '0')
    const isConnecting = isLoading && _.get(connector, 'id') === _.get(pendingConnector, 'id')

    const [blockNumber, setBlockNumber] = useState(0)
    const [gitVersion, setGitVersion] = useState('')
    const [owner, setOnwer] = useState('')
    const [pauser, setPauser] = useState('')
    const [errorMessages, setErrorMessages] = useState([])
    const [vaultAddresses, setVaultAddresses] = useLocalStorage('vault-addresses', [])
    const [selectedVaultAddress, setSelectedVaultAddress] = useSessionStorage('selected-vault-address', DEFAULT_VAULT_ADDRESS)
    const [vaultAddressInput, setVaultAddressInput] = useState('')
    const [contractsPopupId, setContractsPopupId] = useState(0)
    const latestVaultAddress = useLatest(selectedVaultAddress)

    const updateGitVersion = useEvent(async () => {
        if (isAddress(selectedVaultAddress)) {
            try {
                const newGitVersion = await _fetchGitVersion(selectedVaultAddress)
                if (isMounted() && selectedVaultAddress === latestVaultAddress.current) {
                    setGitVersion(newGitVersion)
                }
            } catch (error) {
                console.error('updateGitVersion error: ', error)
                if (isMounted()) {
                    setErrorMessages(_.concat('Update Git Version error', errorMessages))
                }
            }
        }
    })

    const updateOnwer = useEvent(async () => {
        if (isAddress(selectedVaultAddress)) {
            try {
                const newOwner = await _fetchOnwer(selectedVaultAddress)
                if (isMounted() && selectedVaultAddress === latestVaultAddress.current) {
                    setOnwer(newOwner)
                }
            } catch (error) {
                console.error('updateOnwer error: ', error)
                if (isMounted()) {
                    setErrorMessages(_.concat('Update Owner error', errorMessages))
                }
            }
        }
    })

    const updatePauser = useEvent(async () => {
        if (isAddress(selectedVaultAddress)) {
            try {
                const newPauser = await _fetchPauser(selectedVaultAddress)
                if (isMounted() && selectedVaultAddress === latestVaultAddress.current) {
                    setPauser(newPauser)
                }
            } catch (error) {
                console.error('updatePauser error: ', error)
                if (isMounted()) {
                    setErrorMessages(_.concat('Update Pauser error', errorMessages))
                }
            }
        }
    })

    const updateData = useEvent(() => {
        updateGitVersion()
        updateOnwer()
        updatePauser()
    })

    useEffect(() => {
        dispatch(subscribeAccount())
        const unwatch = publicClient.watchBlockNumber({
            emitOnBegin: true,
            onBlockNumber: newBlockNumber => setBlockNumber(Number(newBlockNumber)),
            pollingInterval: 5000
        })
        return () => {
            unsubscribeAccount()
            unwatch()
        }
    }, [])

    useEffect(() => {
        if (!_.isEmpty(gitVersion)) {
            setGitVersion('')
        }
        if (!_.isEmpty(owner)) {
            setOnwer('')
        }
        if (!_.isEmpty(pauser)) {
            setPauser('')
        }
        updateData()
        dispatch(fetchVaultOnChainDynamicData(selectedVaultAddress))

        const polling = setInterval(() => {
            dispatch(fetchVaultOnChainDynamicData(selectedVaultAddress))
        }, 10000)
        
        return () => {
            clearInterval(polling)
        }
    }, [selectedVaultAddress])

    function Contracts () {
        const contracts = _.compact(_.uniq(_.concat(DEFAULT_VAULT_ADDRESS, vaultAddresses)))
        return (
            <div className='vault-container--contracts'>
                <label>{'Contract'}</label>
                <Popup className='vault-container--contracts--popup'
                    on={'click'}
                    closeId={contractsPopupId}
                    trigger={
                        <button className='vault-container--contracts--popup--trigger'>
                            {selectedVaultAddress}
                            <BsCaretDownFill />
                        </button>}>
                    <div className='vault-container--contracts--list'>
                        {_.map(contracts, contract => {
                            return (
                                <div className='vault-container--contracts--item'
                                    key={contract}>
                                    <button className='vault-container--contracts--item--select-button' 
                                        onClick={() => {
                                        setSelectedVaultAddress(contract)
                                        setContractsPopupId(contractsPopupId + 1)
                                    }}>{contract}</button>
                                    {contract !== DEFAULT_VAULT_ADDRESS &&
                                    <button className='vault-container--contracts--item--remove-button'
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setVaultAddresses(_.without(contracts, contract)) 
                                        }}>
                                        <FaTimes />
                                    </button>}
                                </div>
                            )
                        })}
                    </div>
                    <div className='vault-container--contracts--add'>
                        <input type='text'
                            spellCheck={false}
                            placeholder='Address'
                            value={vaultAddressInput}
                            onChange={(e) => { setVaultAddressInput(e.target.value) }} />
                        <button disabled={!isAddress(vaultAddressInput) && !contracts.includes(vaultAddressInput)}
                            onClick={() => {
                                setVaultAddresses(_.concat(contracts, [vaultAddressInput]))
                                setSelectedVaultAddress(vaultAddressInput)
                                setVaultAddressInput('')
                            }}>{'Add'}</button>
                    </div>
                </Popup>
            </div>
        )
    }

    return (
        <div className='vault-container'>
            <div className='vault-container--header'>
                <div className='vault-container--block-number'>
                    <label>{'Block #'}</label>
                    <div>{blockNumber}</div>
                </div>
                <div className='vault-container--wallet'>
                    {!_.isEmpty(walletAddress) ? 
                    <>
                        <div className='vault-container--wallet--network'>
                            <label>{'Network'}</label>
                            <div>
                                {_.get(chain, 'name')}
                            </div>
                            {!isValidNetwork && <button onClick={() => { switchNetwork(defaultChain?.id) }}>{`Switch to ${defaultChain?.name}`}</button>}
                        </div>
                        <div className='vault-container--wallet--account'>
                            <label>{'Account'}</label>
                            <div>{formattedHex(walletAddress)}</div>
                        </div>
                    </>
                    : <button disabled={_.isNil(connector) || isConnecting} onClick={() => { connectWallet({ connector }) }}>{isConnecting ? 'Connecting' : 'Connect Wallet'}</button>}
                </div>
            </div>

            <div className='vault-container--overview'>
                <div className='vault-container--basic-info'>
                    {Contracts()}
                    <div>
                        <label>{'Git Version'}</label>
                        <div>{gitVersion}</div>
                    </div>
                    <div>
                        <label>{'Owner'}</label>
                        <div>{owner}</div>
                    </div>
                    <div>
                        <label>{'Pauser'}</label>
                        <div>{pauser}</div>
                    </div>
                </div>
                <div className='vault-container--traders'>
                    <VaultTraders vaultAddress={selectedVaultAddress} />
                </div>
            </div>
            <VaultTokens vaultAddress={selectedVaultAddress} />
            <VaultSynthetixPerps vaultAddress={selectedVaultAddress} />
            <VaultActions vaultAddress={selectedVaultAddress} owner={owner} />
        </div>
    )
}

export default memo(VaultContainer)