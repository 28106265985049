import React from 'react'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { FaAngleDown } from 'react-icons/fa'
import { RxReset } from 'react-icons/rx'
import Popup from '../common/popup/Popup'

import { LOG_LEVELS, getProfileLogLevels } from '../../configs/profileConfig'
import { disableProfileLogLevel, resetProfileLogLevel, updateProfileLogLevel } from './profileAction'

function ProfileLogLevel ({ profileId, profileItem }) {
    const dispatch = useDispatch()

    const selectedLogLevels = getProfileLogLevels(profileItem?.logLevelBits)

    return (
        <div className='profile-log-level'
            key={profileId}>
            <label>{'LOG'}</label>
            <Popup
                className='profile-log-level--popup'
                on={'click'}
                trigger={
                    <button>
                        {_.size(selectedLogLevels) === 0 ? 'Empty' : _.size(selectedLogLevels) === 1 ? selectedLogLevels[0] : `${_.size(selectedLogLevels)} selected levels`}
                        <FaAngleDown />
                    </button>
                }>
                <div className='profile-log-level--main'>
                    <div className='profile-log-level--title'>{'Log Levels'}</div>
                    <div className='profile-log-level--list'>
                        {_.map(LOG_LEVELS, _logLevel => {
                            const _selected = selectedLogLevels.includes(_logLevel.value)
                            const _shouldShow = _selected || _logLevel.disabled !== true
                            return _shouldShow && (
                                <button className={'profile-log-level--list--item' + (_selected ? ' selected' : '')}
                                    key={_logLevel.value}
                                    disabled={_logLevel.disabled || (_logLevel.value === LOG_LEVELS.INFO.value && _selected)}
                                    onClick={() => { 
                                        if (_selected) {
                                            dispatch(disableProfileLogLevel(profileId, _logLevel.value))
                                        } else {
                                            dispatch(updateProfileLogLevel(profileId, _logLevel.value))
                                        }
                                    }}>
                                    {_logLevel.value}
                                </button>
                            )
                        })}
                    </div>
                    <button className='profile-log-level--reset-button' onClick={() => { dispatch(resetProfileLogLevel(profileId)) }}>
                        <RxReset />
                        <span>{'Reset'}</span>
                    </button>
                </div>
            </Popup>
        </div>
    )
}

ProfileLogLevel.propTypes = {
    profileId: PropTypes.string.isRequired,
    profileItem: PropTypes.object.isRequired
}

function mapStateToProps (state, ownProps) {
    return {
        profileItem: _.get(state, `profile.items.${ownProps.profileId}`)
    }
}

export default connect(mapStateToProps)(ProfileLogLevel)