import _ from 'lodash'
import dotProp from 'dot-prop-immutable'
import { SERVERS } from '../../configs/config'
import { UPDATE_WEB_SOCKET, UPDATE_WEB_SOCKET_DELAY_MILLISECONDS } from './webSocketAction'

const initialState = _.filter(SERVERS, { enabled: true }).reduce((result, server) => {
    return dotProp.set(result, server.hostname, {
        hostname: server.hostname,
        readyState: WebSocket.CLOSED,
        reconnectTimeout: null,
        delayMilliseconds: null,
        isFetchingTicket: false
    })
}, {})

export function webSocketReducer (state = initialState, action) {
    let newState
    switch (action.type) {

        case UPDATE_WEB_SOCKET:
            return dotProp.merge(state, action.hostname, action.params)
    
        case UPDATE_WEB_SOCKET_DELAY_MILLISECONDS:
            newState = _.cloneDeep(state)
            _.forEach(action.socketDelayMilliseconds || {}, (delay, hostname) => {
                if (_.has(newState, hostname)) {
                    newState[hostname].delayMilliseconds = delay
                }
            })
            return newState

        default:
            return state
    }
}