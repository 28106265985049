import React, {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'

import { FiLock, FiUnlock } from 'react-icons/fi'
import { AutoSizer, List, CellMeasurer, CellMeasurerCache } from 'react-virtualized'

import AccountPopup from '../account/AccountPopup' 
import SymbolPopup from '../symbol/NewSymbolPopup'
import Popup from '../common/popup/Popup'

import { updateProfileSearchString } from '../profile/profileAction'
import { SYMBOLS_WITH_MUTLIPLIER_IN_BTC } from '../../configs/tradingConfig'
import { isMetSearchStringCriteria, toFixedNumber, toNumberWithSmartPrecision } from '../../util/util'
import { getNotional } from '../../util/tradingUtil'
import { getSymbolAttributeByName } from '../../util/symbolUtil'

class TransactionContainer extends Component {
    constructor (props) {
        super(props)
        this.state = {
            searchString: '',
            shouldLock: false
        }

        this.cellMeasurerCache = new CellMeasurerCache({
            fixedWidth: true,
            fixedHeight: false
        })

        this.prevTransactionIds = null
        this.listNode = null
        this.lockedTransactions = null
    }

    TransactionData = (name, value, hasDirectionColor) => {
        return (
            <div className='transaction-container--item--data'>
                <div className='transaction-container--item--data-name'>{name}</div>
                <div className={'transaction-container--item--data-value' + (hasDirectionColor ? (value > 0 ? ' positive' : ' negative') : '')}>{value}</div>
            </div>
        )
    }

    _getTransactionId (transaction={}) {
        return transaction.id || `${transaction.profileId}--${transaction.prod_name}--${transaction.fill_ts}`
    }

    renderItem (params, transaction) {
        const { dispatch, profileItems, accountItems, symbolItems, pricings } = this.props
        const { index, key, parent, style } = params
        const { profileId, acct_name, prod_name, last_qty, side, avg_fill_price, tag_name, accu_cog_cancel_cnt, fee, fee_currency } = transaction
        const profileItem = profileItems[profileId]
        const accountItem = accountItems[acct_name]
        const symbolItem = symbolItems[prod_name]
        const { quote } = getSymbolAttributeByName(prod_name)
        const notional = getNotional({
            symbolItem: symbolItem,
            quantity: last_qty,
            price: avg_fill_price,
            BTCUSDIndexLastPrice: SYMBOLS_WITH_MUTLIPLIER_IN_BTC.includes(prod_name) ? _.get(pricings, 'btc_usd_OKEX_INDEX.last') : null
        })

        return (
            <CellMeasurer
                key={key} 
                rowIndex={index}
                columnIndex={0}
                parent={parent} 
                cache={this.cellMeasurerCache}>
                {({ registerChild }) => {
                    return (
                        <div className='transaction-container--item--wrapper'
                            key={this._getTransactionId(transaction)}
                            ref={registerChild}
                            style={style}>
                            <div className='transaction-container--item'>
                                <div className={`transaction-container--item--tag ${(tag_name || '').includes('HEDGE') ? 'HEDGE' : 'QUOTE'}`}>{tag_name}</div>
                                <div className='transaction-container--item--symbol-name'>
                                    {symbolItem 
                                    ? <SymbolPopup 
                                        symbolName={symbolItem.symbol_name}
                                        profileId={profileId} />
                                    : prod_name}
                                </div>
                                {accountItem && <div className='transaction-container--item--account'>
                                    <AccountPopup className='transaction-container--account-popup' accountItem={accountItem} />
                                    <span style={{ fontWeight: 'noraml' }}>{' @ '}</span>
                                    <span>{profileItem ? profileItem.hostname : 'unknown host'}</span>
                                </div>}
                                <div className='transaction-container--item--profile-name'
                                    onClick={() => { 
                                        if (profileItem) {
                                            dispatch(updateProfileSearchString(profileItem.name))
                                        }
                                    }}>{profileItem ? profileItem.name : `Profile ID: ${transaction.profileId}`}</div>
                                <div className='transaction-container--item--main'>
                                    {this.TransactionData('Datetime', moment(transaction.fill_ts).format('MM-DD HH:mm:ss'))}
                                    {Number(accu_cog_cancel_cnt) >= 0 && this.TransactionData('COG Cancel Count', accu_cog_cancel_cnt)}
                                    {this.TransactionData('Avg Fill Price', toNumberWithSmartPrecision({ number: avg_fill_price, shouldReturnLocalString: true }) )}
                                    {this.TransactionData('Quantity', toFixedNumber(Number(last_qty) * (_.toLower(side || '').includes('sell') ? -1 : 1), 5), true)}
                                    {this.TransactionData('Notional', notional ? (toNumberWithSmartPrecision({ number: Math.abs(notional), shouldReturnLocalString: true} ) + ` ${quote}`) : 'N/A')}
                                    {Number(fee) > 0 && this.TransactionData('Fee', `${fee} ${_.toUpper(fee_currency || '')}`)}
                                </div>
                            </div>
                        </div>
                    )
                }}
            </CellMeasurer>
        )
    }

    render () {
        const { searchString, shouldLock } = this.state
        const transactions = shouldLock ? this.lockedTransactions : this.props.transactions
        const filteredTransactions = _.isEmpty(searchString.trim()) ? transactions : _.filter(transactions, transaction => isMetSearchStringCriteria(`${transaction.prod_name} ${transaction.profileId} ${transaction.tag_name} ${transaction.acct_name}`, searchString))
        const transactionIds = _.map(filteredTransactions, transaction => this._getTransactionId(transaction))
        
        if (!_.isEqual(this.prevTransactionIds, transactionIds)) {
            this.cellMeasurerCache.clearAll()
            this.prevTransactionIds = transactionIds
        }

        return (
            <div className='transaction-container'>
                <div className='transaction-container--header'>
                    <input className='transaction-container--search-input' value={searchString}
                        placeholder={'Search Symbol, Account, Profile'}
                        autoFocus
                        spellCheck={false}
                        onChange={(e) => { 
                            this.setState({ searchString: e.target.value }) 
                            if (this.listNode) {
                                this.listNode.scrollToPosition(0)
                            }
                        }} />
                    <Popup 
                        className={'transaction-container--locker--popup'}
                        trigger={
                            <button className={'transaction-container--locker' + (shouldLock ? ' locked' : '')} 
                                onClick={() => { 
                                    const newShouldLock = !shouldLock
                                    if (newShouldLock) {
                                        this.lockedTransactions = transactions
                                    }
                                    this.setState({ shouldLock: newShouldLock }) 
                                }}>
                                {shouldLock ? <FiLock /> : <FiUnlock />}
                            </button>}>
                        {'Lock fill records'}
                    </Popup>
                </div>
                <div className='transaction-container--list-wrapper'>
                    <AutoSizer>
                        {({ width, height }) => (
                            <List 
                                ref={(node) => { this.listNode = node }}
                                className='transaction-container--list'
                                width={width}
                                height={height} 
                                rowCount={_.size(filteredTransactions)}
                                rowRenderer={params => this.renderItem(params, filteredTransactions[params.index])} 
                                rowHeight={this.cellMeasurerCache.rowHeight} 
                                overscanRowCount={5} 
                                scrollToAlignment={'start'}
                                deferredMeasurementCache={this.cellMeasurerCache} />
                        )}
                    </AutoSizer>
                </div>
            </div>
        )
    }
}

TransactionContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    profileItems: PropTypes.object.isRequired,
    transactions: PropTypes.array.isRequired,
    accountItems: PropTypes.object.isRequired,
    symbolItems: PropTypes.object.isRequired,
    pricings: PropTypes.object.isRequired
}

function mapStateToProps (state) {
    return {
        profileItems: state.profile.items,
        transactions: state.trading.transactions,
        accountItems: state.account.items,
        symbolItems: state.symbol.items,
        pricings: state.symbol.pricings
    }
}

export default connect(mapStateToProps)(TransactionContainer)