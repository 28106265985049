import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import WaitingSVG from '../../images/rolling.svg'

import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { MdDone } from 'react-icons/md'
import { fetchProfilesByHostname } from './profileAction'
import { SERVERS } from '../../configs/config'

const shouldShowComponent  = (hostnameProfileState) => {
    return !_.isEmpty(hostnameProfileState) && !_.every(hostnameProfileState, (item, hostname) => item.isFetched || SERVERS[hostname].profileDisabled)
}

class HostnameProfileState extends Component {
    constructor (props) {
        super(props)
        this.state = {
            shouldShow: shouldShowComponent(props.hostnameProfileState),
            isMinimized: false,
            isFadingOut: false
        }
        this._mounted = false
        this.fadingOutTimeout = null
    }

    static getDerivedStateFromProps (props, state) {
        if (!state.shouldShow && shouldShowComponent(props.hostnameProfileState)) {
            return { shouldShow: true }
        } else {
            return null
        }
    }

    componentDidMount () {
        this._mounted = true
    }

    componentDidUpdate () {
        const { hostnameProfileState } = this.props
        const { shouldShow, isFadingOut } = this.state

        if (!shouldShowComponent(hostnameProfileState) && shouldShow && !isFadingOut && _.isNil(this.fadingOutTimeout)) {
            this.fadingOutTimeout = setTimeout(() => {
                if (this._mounted) {
                    this.setState({ isFadingOut: true })
                }
            }, 3000)
            setTimeout(() => {
                if (this._mounted) {
                    this.setState({ shouldShow: false })
                }
            }, 4000)
        }
    }

    componentWillUnmount () {
        this._mounted = false
    }

    render () {
        const { dispatch, hostnameProfileState } = this.props
        const { shouldShow, isMinimized, isFadingOut } = this.state

        return shouldShow ? (
            <div className={'hostname-profile-state' + (isFadingOut ? ' fading-out' : '')}>
                <div className='hostname-profile-state--header clearfix'>
                    <div className='hostname-profile-state--header--title'>{'Hostname Profiles'}</div>
                    <button className='hostname-profile-state--header--toggle-button' 
                        onClick={() => { this.setState({ isMinimized: !isMinimized }) }}>
                        {isMinimized ? <FiChevronUp /> : <FiChevronDown />}
                    </button>
                </div>
                {!isMinimized && <div className='hostname-profile-state--main'>
                    <table>
                        <tbody>
                            {_.map(hostnameProfileState, (item, hostname) => {
                                const { isFetching, isFetched, errorMessage } = item
                                const server = SERVERS[hostname]
                                return !server.profileDisabled ? (
                                    <tr className='hostname-profile-state--item' key={hostname}>
                                        <td className='hostname-profile-state--item--name'>{hostname}</td>
                                        <td className='hostname-profile-state--item--state'>
                                            {isFetching && <Fragment>
                                                <img src={WaitingSVG} />
                                                <span className='pending'>{'PENDING'}</span>
                                            </Fragment>}
                                            {isFetched && <Fragment>
                                                <MdDone />
                                                <span className='complete'>{'COMPLETE'}</span>
                                            </Fragment>}
                                            {errorMessage && <div className='hostname-profile-state--error-message'>{errorMessage}</div>}
                                        </td>
                                        <td className='hostname-profile-state--item--button'>
                                            {!isFetched && 
                                            <button className={'hostname-profile-state--fetch-button' + (isFetching ? ' is-fetching' : '')}
                                                onClick={() => { dispatch(fetchProfilesByHostname(hostname)) }}>
                                                {'Fetch Again'}
                                            </button>}
                                        </td>
                                    </tr>
                                ) : null
                            })}
                        </tbody>
                    </table>
                </div>}
            </div>
        ) : null
    }
}

HostnameProfileState.propTypes = {
    dispatch: PropTypes.func.isRequired,
    hostnameProfileState: PropTypes.object.isRequired
}

function mapStateToProps (state) {
    return {
        hostnameProfileState: state.profile.hostnameProfileState
    }
}

export default connect(mapStateToProps)(HostnameProfileState)