import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import { toNumberWithSmartPrecision } from '../../util/util'

export default class AccountAssetItem extends Component {
    render () {
        const { accountAssetItem, tokensToFilter } = this.props
        return (
            <div className='account-asset-item'>
                <div className='account-asset-item--header clearfix'>
                    <div className='account-asset-item--account-name'>{accountAssetItem.acct_name}</div>
                    <div className='account-asset-item--timestamp'>{moment(accountAssetItem.timestamp).format('HH:mm:ss')}</div>
                </div>
                <div className='account-asset-item--asset-values'>
                    {_.map(_.omit(accountAssetItem, ['timestamp', 'acct_name']), (assetValue, assetName) => {
                        return tokensToFilter.includes(assetName.toUpperCase()) || (_.isEmpty(tokensToFilter) && Number(assetValue) !== 0) ? (
                            <div className='account-asset-item--asset' key={assetName}>
                                <div className='account-asset-item--asset-name'>{assetName.toUpperCase()}</div>
                                <div className='account-asset-item--asset-value'>{toNumberWithSmartPrecision({ number: assetValue, shouldReturnLocalString: true })}</div>
                            </div>
                        ) : null
                    })}
                </div>
            </div>
        )
    }
}

AccountAssetItem.propTypes = {
    accountAssetItem: PropTypes.object.isRequired,
    tokensToFilter: PropTypes.array
}

AccountAssetItem.defaultProps = {
    tokensToFilter: []
}

