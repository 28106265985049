import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import _ from 'lodash'
import moment from 'moment'
import dotProp from 'dot-prop-immutable'

import Modal from 'react-modal'
import { MdClose } from 'react-icons/md'
import { FiPlus } from 'react-icons/fi'

import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { arrayMoveImmutable } from 'array-move'

import Checkbox from '../common/checkbox/Checkbox'
import SaveButton from '../common/saveButton/SaveButton'
import TimerProfileItem from './TimerProfileItem'

import { getSymbolAttributeByName } from '../../util/symbolUtil'
import { createTimer, updateTimer, deleteTimer } from './timerAction'
import { verfiyProfilesPermission } from '../profile/profileAction'
import { timerProfileItemHasSymbolConflict, resolveTimerProfileItemSymbolConflict } from '../../util/profileUtil'

Modal.setAppElement('#root')

const SortableProfileItem = SortableElement(({ profileItem, isDuplicated, shouldDisableModification, onChangeId, onChangeParams, onClickRemoveButton }) => {
    return (
        <div className='timer-editor--profile-item'>
            <TimerProfileItem
                timerProfileItem={profileItem}
                isDuplicated={isDuplicated}
                shouldDisableModification={shouldDisableModification}
                onChangeProfileId={(newProfileId) => { onChangeId(newProfileId) }}
                onChangeProfileParams={(newProfileItem) => { onChangeParams(newProfileItem) }}
                onClickRemoveButton={() => { onClickRemoveButton() }} />
        </div>
    )
})

const SortableProfileList = SortableContainer(({ profileItems, shouldDisableModification, onChangeProfileId, onChangeProfileParams, onClickRemoveButton }) => {
    return (
        <div className='timer-editor--profile-list'>
            {profileItems.map((profileItem, index) => {
                return (
                    <SortableProfileItem
                        key={index}
                        index={index}
                        profileItem={profileItem} 
                        isDuplicated={profileItem.profileId && _.findIndex(profileItems, { profileId: profileItem.profileId }) !== index} 
                        shouldDisableModification={shouldDisableModification}
                        onChangeId={(newProfileId) => { onChangeProfileId(index, newProfileId) }} 
                        onChangeParams={(newProfileItem) => { onChangeProfileParams(index, newProfileItem) }}
                        onClickRemoveButton={() => { onClickRemoveButton(index) }} />
                )
            })}
        </div>
    )
})

const DEFAULT_BULK_UPDATE_EDITOR_TIMER_PROFILE_ITEM = {
    enabled: false,
    profileId: 'BULK_UPDATE_EDITOR',
    user: 'BULK_UPDATE_EDITOR',
    legs: {
        1: {
            symbols: [{
                name: 'SYMBOL',
                params: {
                    QUOTE_MAX_SIDE_POS: {
                        mode: null,
                        value: [null, null]
                    },
                    QUOTE_SPREAD_THRESHOLD: {
                        mode: null,
                        value: [null, null]
                    }
                },
                switches: {}
            }]
        }
    }
}

class TimerEditor extends Component {
    constructor (props) {
        super(props)
        
        this.state = {
            timer: this.props.defaultTimer,
            message: null,
            bulkUpdateEditor: {
                enabled: false,
                timerProfileItem: {},
                profileSearchString: '',
                symbolSearchString: ''
            },
            isVerifyingPermission: false,
            isPermissionVerified: this.props.mode === 'EDIT' ? false : true,
            showDeleteModal: false,
            isSaving: false,
            isSaved: false,
            isDeleting: false,
            isDeleted: false,
            isDeleteForbidden: false
        }
    }

    componentDidMount () {
        const { timer } = this.state
        const { mode } = this.props
        if (mode === 'EDIT' && !_.isEmpty(timer.profiles) ) {
            this.verifyPermission()
        }
    }

    verifyPermission () {
        const { dispatch } = this.props
        const { timer } = this.state
        this.setState({ 
            isVerifyingPermission: true,
            isPermissionVerified: false
        })
        const profilesToVerify = _.filter(timer.profiles, timerProfileItem => timerProfileItem.profileId && timerProfileItem.user)
            .map(timerProfileItem => {
                const { profileId, user } = timerProfileItem
                const hostname = _.last(profileId.split('_'))
                const profileName = profileId.substring(0, profileId.length - hostname.length - 1)
                return {
                    name: profileName,
                    hostname,
                    user
                }
            })
        dispatch(verfiyProfilesPermission(profilesToVerify))
        .then(verifyResult => {
            this.setState({
                isVerifyingPermission: false,
                isPermissionVerified: verifyResult
            })
        })
    }

    _createTimerProfile (profileItem) {
        return {
            profileId: profileItem.id,
            user: profileItem.user,
            enabled: true,
            legs: {
                1: {
                    symbols: profileItem.legs['1'].symbols.map((symbol) => {
                        const symbolAttribute = getSymbolAttributeByName(symbol.name)
                        return {
                            name: symbol.name,
                            params: {
                                QUOTE_SPREAD_THRESHOLD: {
                                    mode: 'absolute',
                                    value: [null, null]
                                },
                                QUOTE_MAX_SIDE_POS: {
                                    mode: 'absolute',
                                    value: [null, null]
                                }
                            },
                            switches: symbolAttribute.switchType === 'BUY_SELL' ? {
                                BUY: null,
                                SELL: null
                            } : symbolAttribute.switchType === 'BUY_SELL_TO_OPEN_CLOSE' ? {
                                BUY_OPEN: null,
                                BUY_CLOSE: null,
                                SELL_OPEN: null,
                                SELL_CLOSE: null
                            } : {}
                        }
                    })
                }
            }
        }
    }

    _isEmptyTimerProfile (timerProfile) {
        return timerProfile.legs['1'].symbols.reduce((isEmpty, symbol) => {
            const { switches, params } = symbol
            return isEmpty 
                && Object.values(switches).every(value => value === null) 
                && _.isEqual(params['QUOTE_SPREAD_THRESHOLD'].value, [null, null])
                && _.isEqual(params['QUOTE_MAX_SIDE_POS'].value, [null, null])
        }, true)
    }

    handleClickSaveButton () {
        const { timer } = this.state
        const { dispatch, profileItems, mode, closeOnSaveSuccess, onClickClose } = this.props
        const timerToSend = _.cloneDeep(timer)
        timerToSend.profiles = timer.profiles.filter(profile => !_.isEmpty(profile))
        const emptyTimerProfile = _.find(timerToSend.profiles, profile => this._isEmptyTimerProfile(profile))
        const hasDuplicateProfiles = _.uniqBy(timerToSend.profiles, 'profileId').length < timerToSend.profiles.length

        if (!timerToSend.loop && moment(timerToSend.datetime[0]).isBefore(moment())) {
            this.setState({
                message: 'The datetime is not valid'
            })
        } else if (_.isEmpty(timerToSend.profiles)) {
            this.setState({
                message: 'You have to create at least one profile.'
            })
        } else if (emptyTimerProfile) {
            const profileName = profileItems[emptyTimerProfile.profileId].name
            this.setState({
                message: `It is not allowed to have a profile modifying no parameters: ${profileName}`
            })
        } else if (hasDuplicateProfiles) {
            this.setState({
                message: 'You are not allowed to create duplicated profiles.'
            })
        } else {
            const action = mode === 'CREATE' ? createTimer : updateTimer
            this.setState({
                isSaving: true,
                isSaved: false
            })

            dispatch(action(timerToSend))
            .then((responses) => {
                const isSavedSuccess = responses.every(response => response.status === 200)
                const isForbidden = _.some(responses, response => response.status === 403)
                let isPartiallySaved, message = ''
                if (!isSavedSuccess) {
                    isPartiallySaved = _.some(responses, response => response.status === 200)
                    if (isPartiallySaved) {
                        message += 'This timer seems not saved correctly. Only part of profiles are saved. '
                    } else {
                        message += 'The timer is failed to save. '
                    }
                }
                if (isForbidden) {
                    message += 'You are forbidden to commit the action.'
                }
                if (isSavedSuccess && closeOnSaveSuccess) {
                    onClickClose()
                } else {
                    this.setState({
                        isSaving: false,
                        isSaved: isSavedSuccess || isPartiallySaved,
                        message: message
                    })
                }
            })
        }
    }

    renderLoop () {
        const { timer, isPermissionVerified } = this.state
        return (
            <div className='timer-editor--loop'>
                <div className='timer-editor--loop--label'>{'Loop'}</div>
                <Checkbox 
                    disabled={!isPermissionVerified}
                    checked={timer.loop} 
                    onChange={(newChecked) => { 
                        this.setState({
                            timer: Object.assign({}, timer, {
                                loop: newChecked,
                                datetime: newChecked 
                                ? ['00:00:00Z']
                                : [moment().utc().add(4, 'hour').format()]
                            }),
                            isSaved: false
                        })
                    }} />
            </div>
        )
    }

    renderDateTime () {
        const { timer, isPermissionVerified } = this.state
        const { loop } = timer

        const renderTimeInput = ({key, defaultValue, onBlur}) => {
            return (
                <div className='timer-editor--datetime--input-wrapper' key={`${key}_${defaultValue}`}>
                    <input className='timer-editor--datetime--input'
                        type={loop ? 'time' : 'datetime-local'}
                        defaultValue={defaultValue}
                        min={!loop ? moment().utcOffset('+08:00').format('YYYY-MM-DDTHH:mm:ss') : null}
                        step={1}
                        disabled={!isPermissionVerified}
                        onBlur={(e) => { onBlur(e.target.value) }} />
                    <span className='timer-editor--datetime--timezone-label'>{'HKT'}</span>
                </div>
            )
        }
        
        return (
            <div className='timer-editor--datetime'>
                <div className='timer-editor--datetime--label'>{'Datetime'}</div>
                <div className='timer-editor--datetime--inputs'>
                    {loop ? timer.datetime.map((time, index) => {
                        return (
                            <div className='timer-editor--datetime--loop-input' key={index}>
                                {renderTimeInput({
                                    key: index,
                                    defaultValue: moment(time.substr(-9), 'HH:mm:ssZ').utcOffset('+08:00').format('HH:mm:ss'),
                                    onBlur: (newValue) => {
                                        this.setState({
                                            timer: dotProp.set(timer, `datetime.${index}`, moment(`${newValue.length === 5 ? (newValue + ':00') : newValue}+08:00`, 'HH:mm:ssZ').utc().format('HH:mm:ss') + 'Z'),
                                            isSaved: false,
                                            message: null
                                        })
                                    }
                                })}
                                {isPermissionVerified && <Fragment>
                                    {index === 0 
                                    ? <button className='timer-editor--datetime--add-time-button' 
                                        disabled={!isPermissionVerified}
                                        onClick={() => {
                                            this.setState({
                                                timer: dotProp.merge(timer, 'datetime', moment(_.last(timer.datetime), 'HH:mm:ssZ').utc().add(8, 'hour').format('HH:mm:ss') + 'Z'),
                                                isSaved: false
                                            })
                                        }}><FiPlus /></button>
                                    : <button className='timer-editor--datetime--remove-time-button' 
                                        disabled={!isPermissionVerified}
                                        onClick={() => {
                                            this.setState({
                                                timer: dotProp.delete(timer, `datetime.${index}`),
                                                isSaved: false
                                            })
                                        }}><MdClose /></button>}
                                </Fragment>}               
                            </div>
                        )
                    }) : renderTimeInput({
                        defaultValue: moment(timer.datetime[0]).utcOffset('+08:00').format('YYYY-MM-DDTHH:mm:ss'),
                        onBlur: (newValue) => {
                            this.setState({
                                timer: dotProp.set(timer, 'datetime.0', moment(`${newValue}+08:00`).utc().format()),
                                isSaved: false,
                                message: null
                            })
                        }
                    })}
                </div>
            </div>
        )
    }

    renderDescription () {
        const { timer, isPermissionVerified } = this.state
        return (
            <div className='timer-editor--description'>
                <div className='timer-editor--description--label'>{'Description'}</div>
                <textarea className='timer-editor--description--input'
                    spellCheck={false}
                    value={timer.description}
                    disabled={!isPermissionVerified}
                    onChange={(e) => { 
                        this.setState({
                            timer: dotProp.set(timer, 'description', e.target.value),
                            isSaved: false
                        })
                    }} />
            </div>
        )
    }

    getCriteriaResult (profileSearchString='', symbolSearchString='') {
        const { timer } = this.state
        const trimmedProfileSearchString = profileSearchString.toLowerCase().trim()
        const trimmedSymbolSearchString = symbolSearchString.toLowerCase().trim()

        const filteredProfileIds = [], filteredSymbolNames = []
        let profileSymbolSize = 0
        timer.profiles.forEach(timerProfile => {
            const isFilteredProfile = trimmedProfileSearchString.length === 0
                || _.some(trimmedProfileSearchString.split(/\|{2}|\s+/), v => {
                    const tags = timerProfile.tags || []
                    return v.trim().length > 0 && `${tags.join()}`.toLowerCase().includes(v.trim())
                })
            if (isFilteredProfile) {
                filteredProfileIds.push(timerProfile.profileId)
                if (_.has(timerProfile, 'legs.1.symbols') && _.isArray(timerProfile.legs['1'].symbols)) {
                    const timerProfileSymbols = timerProfile.legs['1'].symbols
                    timerProfileSymbols.forEach(profileSymbol => {
                        const isFilteredSymbol = trimmedSymbolSearchString.length === 0 
                            || _.some(trimmedSymbolSearchString.split(/\|{2}|\s+/), v => {
                                return v.trim().length > 0 && profileSymbol.name.toLowerCase().includes(v.trim())
                            })
                        if (isFilteredSymbol) {
                            filteredSymbolNames.push(profileSymbol.name)
                            profileSymbolSize++
                        }
                    })
                }
            }
        })
        return {
            filteredProfileIds,
            filteredSymbolNames: _.uniq(filteredSymbolNames),
            profileSymbolSize
        }
    }

    BulkUpdateEditor () {
        const { timer, bulkUpdateEditor } = this.state
        const { profileSearchString, symbolSearchString } = bulkUpdateEditor
        const { filteredProfileIds, filteredSymbolNames } = this.getCriteriaResult(profileSearchString, symbolSearchString)

        return (
            <div className='timer-editor--bulk-update-editor'>
                <div className='timer-editor--bulk-update-editor--title'>{'Bulk Update'}</div>
                <div className='timer-editor--bulk-update-editor--criteria'>
                    <div className='timer-editor--bulk-update-editor--criteria--profile'>
                        <input className='timer-editor--bulk-update-editor--criteria--profile--input'
                            placeholder={'Filter Profile Tags'}
                            type={'text'}
                            spellCheck={false}
                            value={bulkUpdateEditor.profileSearchString}
                            onChange={(e) => {
                                const newProfileSearchString = e.target.value
                                const newBulkUpdateEditor = dotProp.set(bulkUpdateEditor, 'profileSearchString', newProfileSearchString)
                                const { profileSymbolSize: newProfileSymbolSize } = this.getCriteriaResult(newProfileSearchString, symbolSearchString)
                                const newBulkUpdateEditorTimerProfileItem =  dotProp.set(DEFAULT_BULK_UPDATE_EDITOR_TIMER_PROFILE_ITEM, 'legs.1.symbols.0.name', `${newProfileSymbolSize} Symbol${newProfileSymbolSize > 1 ? 's' : ''}`)
                                newBulkUpdateEditor.timerProfileItem = newBulkUpdateEditorTimerProfileItem
                                this.setState({ bulkUpdateEditor: newBulkUpdateEditor })
                            }} />
                        {profileSearchString.trim().length > 0 && <div className='timer-editor--bulk-update-editor--criteria--profile--results clearfix'>
                            {filteredProfileIds.length > 0 
                            ? filteredProfileIds.map((profileId, index) => {
                                return (
                                    <div className='timer-editor--bulk-update-editor--criteria--profile--results--item' key={index}>
                                        {profileId}
                                    </div>
                                )
                            })
                            : <div className='timer-editor--bulk-update-editor--criteria--profile--results--empty'>{'No Matched Profile'}</div>}
                        </div>}
                    </div>
                    <div className='timer-editor--bulk-update-editor--criteria--symbol'>
                        <input className='timer-editor--bulk-update-editor--criteria--symbol--input'
                            placeholder={'Filter Symbols'}
                            type={'text'}
                            spellCheck={false}
                            value={bulkUpdateEditor.symbolSearchString}
                            onChange={(e) => {
                                const newSymbolSearchString = e.target.value
                                const newBulkUpdateEditor = dotProp.set(bulkUpdateEditor, 'symbolSearchString', newSymbolSearchString)
                                const { profileSymbolSize: newProfileSymbolSize } = this.getCriteriaResult(profileSearchString, newSymbolSearchString)
                                const newBulkUpdateEditorTimerProfileItem =  dotProp.set(DEFAULT_BULK_UPDATE_EDITOR_TIMER_PROFILE_ITEM, 'legs.1.symbols.0.name', `${newProfileSymbolSize} Symbol${newProfileSymbolSize > 1 ? 's' : ''}`)
                                newBulkUpdateEditor.timerProfileItem = newBulkUpdateEditorTimerProfileItem
                                this.setState({ bulkUpdateEditor: newBulkUpdateEditor })
                            }} />
                        {symbolSearchString.trim().length > 0 && <div className='timer-editor--bulk-update-editor--criteria--symbol--results clearfix'>
                            {filteredSymbolNames.length > 0 
                            ? filteredSymbolNames.map((symbolName, index) => {
                                return (
                                    <div className='timer-editor--bulk-update-editor--criteria--symbol--results--item' key={index}>
                                        {symbolName}
                                    </div>
                                )
                            })
                            : <div className='timer-editor--bulk-update-editor--criteria--profile--results--empty'>{'No Matched Symbol'}</div>}
                        </div>}
                    </div>
                </div>
                <div className='timer-editor--bulk-update-editor--main'>
                    <TimerProfileItem
                        timerProfileItem={bulkUpdateEditor.timerProfileItem}
                        isDuplicated={false}
                        shouldHideProfileName
                        shouldHideTags
                        shouldHideSwitches
                        shouldDisableParamMode
                        onChangeProfileParams={(newProfileItem) => {
                            const { filteredProfileIds, filteredSymbolNames } = this.getCriteriaResult(profileSearchString, symbolSearchString)
                            const newTimer = _.cloneDeep(timer)
                            newTimer.profiles.forEach(timerProfileItem => {
                                if (filteredProfileIds.includes(timerProfileItem.profileId)) {
                                    const symbols = _.has(timerProfileItem, `legs.1.symbols`) ? timerProfileItem.legs['1'].symbols : []
                                    symbols.forEach(symbol => {
                                        if (filteredSymbolNames.includes(symbol.name)) {
                                            _.forEach(symbol.params, (symbolParam, symbolParamKey) => {
                                                const bulkUpdateSymbolParam = _.has(newProfileItem, `legs.1.symbols.0.params.${symbolParamKey}`)
                                                    ? newProfileItem.legs['1'].symbols[0].params[symbolParamKey]
                                                    : null
                                                const prevBulkUpdateSymbolParam = bulkUpdateSymbolParam ? bulkUpdateEditor.timerProfileItem.legs['1'].symbols[0].params[symbolParamKey] : null
                            
                                                if (_.isObject(bulkUpdateSymbolParam) && _.isArray(symbolParam.value)) {
                                                    symbolParam.value.forEach((symbolParamValue, symbolParamValueIndex) => {
                                                        let newValue = symbolParamValue
                                                        if (_.has(bulkUpdateSymbolParam, `value.${symbolParamValueIndex}`)) {
                                                            if (!_.isNull(bulkUpdateSymbolParam.value[symbolParamValueIndex])) {
                                                                newValue = bulkUpdateSymbolParam.value[symbolParamValueIndex]
                                                            } else if (_.has(prevBulkUpdateSymbolParam, `value.${symbolParamValueIndex}`) && !_.isNull(prevBulkUpdateSymbolParam.value[symbolParamValueIndex])) {
                                                                newValue = null
                                                            }
                                                        }
                                                        symbolParam.value[symbolParamValueIndex] = newValue
                                                    })
                                                }
                                            })
                                        }
                                    })  
                                }
                            })
                            this.setState({
                                timer: newTimer,
                                bulkUpdateEditor: dotProp.set(bulkUpdateEditor, 'timerProfileItem', newProfileItem),
                                isSaved: false
                            })
                        }} />
                </div>
            </div>
        )
    }

    renderProfiles () {
        const { timer, bulkUpdateEditor, isPermissionVerified } = this.state
        const { profileItems } = this.props
        const shouldShowBulkUpdateEditor = bulkUpdateEditor.enabled
        const hasSymbolConflicts = _.some(timer.profiles, timerProfileItem => {
            const profileItem = profileItems[timerProfileItem.profileId]
            return profileItem && timerProfileItemHasSymbolConflict(timerProfileItem, profileItem)
        })
        return (
            <div className='timer-editor--profiles'>
                <SortableProfileList 
                    pressDelay={200}
                    profileItems={timer.profiles}
                    shouldDisableModification={!isPermissionVerified}
                    onChangeProfileId={(index, newProfileId) => {
                        const profileItem = profileItems[newProfileId]
                        this.setState({
                            timer: dotProp.set(timer, `profiles.${index}`, this._createTimerProfile(profileItem)),
                            isSaved: false
                        })
                    }} 
                    onChangeProfileParams={(index, newProfileItem) => {
                        this.setState({
                            timer: dotProp.set(timer, `profiles.${index}`, newProfileItem),
                            isSaved: false
                        })
                    }} 
                    onClickRemoveButton={(index) => {
                        this.setState({
                            timer: dotProp.delete(timer, `profiles.${index}`),
                            bulkUpdateEditor: dotProp.set(bulkUpdateEditor, 'enabled', false),
                            isSaved: false
                        })
                    }} 
                    onSortEnd={({oldIndex, newIndex}) => {
                        this.setState({
                            timer: dotProp.set(timer, 'profiles', arrayMoveImmutable(timer.profiles, oldIndex, newIndex)),
                            isSaved: false
                        })
                    }} />
                {isPermissionVerified && <div className='timer-editor--profiles--buttons'>
                    <button className='timer-editor--profiles--add-button' onClick={() => {
                        this.setState({
                            timer: dotProp.merge(timer, 'profiles', {}),
                            bulkUpdateEditor: dotProp.set(bulkUpdateEditor, 'enabled', false),
                            message: null,
                            isSaved: false
                        })
                    }}>{'Add Profile'}</button>
                    {timer.profiles.length > 0 && <button className={'timer-editor--profiles--bulk-update-button' + (shouldShowBulkUpdateEditor ? ' enabled' : ' disabled')} onClick={() => {
                        this.setState(prevState => {
                            const newBulkUpdateEditor = dotProp.set(bulkUpdateEditor, 'enabled', !prevState.bulkUpdateEditor.enabled)
                            newBulkUpdateEditor.profileSearchString = ''
                            newBulkUpdateEditor.symbolSearchString = ''
                            const { profileSymbolSize } = this.getCriteriaResult(bulkUpdateEditor.profileSearchString, bulkUpdateEditor.symbolSearchString)
                            newBulkUpdateEditor.timerProfileItem = dotProp.set(DEFAULT_BULK_UPDATE_EDITOR_TIMER_PROFILE_ITEM, 'legs.1.symbols.0.name', `${profileSymbolSize} Symbol${profileSymbolSize > 1 ? 's' : ''}`)
                            return {
                                bulkUpdateEditor: newBulkUpdateEditor
                            }
                        })
                    }}>{shouldShowBulkUpdateEditor ? 'Hide Bulk Update' : 'Enable Bulk Update'}</button>}
                    {hasSymbolConflicts && <button className='timer-editor--profiles--resolve-all-conflict-button' onClick={() => {
                        const newTimer = _.cloneDeep(timer)
                        newTimer.profiles.forEach((timerProfileItem, index) => {
                            newTimer.profiles[index] = resolveTimerProfileItemSymbolConflict(timerProfileItem, profileItems[timerProfileItem.profileId])
                        })
                        this.setState({ 
                            bulkUpdateEditor: dotProp.set(bulkUpdateEditor, 'enabled', false),
                            timer: newTimer 
                        })
                    }}>{'Resolve All Conflict'}</button>}
                </div>}

            </div>
        )
    }

    renderDeleteModal () {
        const { timer, isDeleting, isDeleted, isDeleteForbidden } = this.state
        const { dispatch, closeOnDeleteSuccess, onClickClose } = this.props
        return (
            <Modal overlayClassName='modal-overlay' 
                className='timer-editor--delete-modal centered' 
                isOpen>
                <div className='timer-editor--delete-modal--message'>{'Are you sure to delete the timer ?'}</div>
                <div className='timer-editor--delete-modal--buttons'>
                    <button className='timer-editor--delete-modal--cancel-button' onClick={() => {
                        this.setState({ showDeleteModal: false })
                    }}>{'Never Mind'}</button>
                    <SaveButton className='timer-editor--delete-modal--delete-button' 
                        isSaving={isDeleting} 
                        isSaved={isDeleted} 
                        disabled={isDeleteForbidden}
                        text={isDeleteForbidden ? 'Fobidden': 'Delete'}
                        isSavingText={'Deleting...'}
                        isSavedText={'Deleted'} 
                        onClick={() => { 
                            this.setState({
                                isDeleting: true,
                                isDeleted: false,
                                isDeleteForbidden: false
                            })
                            dispatch(deleteTimer(timer.id)).then((responses) => {
                                const isDeletedSuccess = responses.every(response => response.status === 200)
                                if (isDeletedSuccess && closeOnDeleteSuccess) {
                                    onClickClose()
                                } else {
                                    this.setState({
                                        isDeleting: false,
                                        isDeleted: isDeletedSuccess,
                                        isDeleteForbidden: _.some(responses, response => response.status === 403)
                                    })
                                }
                            })
                        }} />
                </div>
            </Modal>
        )
    }

    render () {
        const { timer, message, bulkUpdateEditor, showDeleteModal, isSaving, isSaved, isVerifyingPermission, isPermissionVerified } = this.state
        const { defaultTimer, mode, onClickClose } = this.props
        return (
            <Modal 
                overlayClassName='modal-overlay'
                className='timer-editor horizontal-centered'
                isOpen>
                <div className='timer-editor--header'>
                    <div className='timer-editor--title'>{'Timer Setting'}</div>
                    {!isPermissionVerified && <span className={'timer-editor--verify-message' + (!isVerifyingPermission ? ' forbidden-message' : '')}>{isVerifyingPermission ? 'Verifying Permission' : 'You are forbidden to modify this timer'}</span>}
                    <button className='timer-editor--close-button' onClick={() => {
                        onClickClose()
                    }}><MdClose /></button>
                </div>
                <div className='timer-editor--main' >
                    {this.renderLoop()}
                    {this.renderDateTime()}
                    {this.renderDescription()}
                    {this.renderProfiles()}
                    {bulkUpdateEditor.enabled && this.BulkUpdateEditor()}
                </div>
                {isPermissionVerified && <div className='timer-editor--footer'>
                    {message && <div className='timer-editor--message'>{message}</div>}
                    {mode === 'EDIT' && 
                    <button className='timer-editor--reset-button'
                        onClick={() => { 
                            this.setState({ 
                                timer: defaultTimer,
                                bulkUpdateEditor: dotProp.set(bulkUpdateEditor, 'enabled', false),
                                isSaved: false
                            }) 
                        }}>
                        {'RESET'}
                    </button>}
                    {mode === 'EDIT' && <button className='timer-editor--delete-button' 
                        onClick={() => { 
                            this.setState({ 
                                showDeleteModal: true,
                                isDeleted: false,
                                isDeleteForbidden: false
                            }) }}>{'Delete'}</button>}
                    {!_.isEmpty(timer.profiles) && <SaveButton 
                        className='timer-editor--save-button' 
                        isSaving={isSaving}
                        isSaved={isSaved}
                        onClick={() => { this.handleClickSaveButton() }}/>}
                </div>}
                {showDeleteModal && this.renderDeleteModal()}
            </Modal>
        )
    }
}

TimerEditor.propTypes = {
    dispatch: PropTypes.func.isRequired,
    mode: PropTypes.oneOf(['CREATE', 'EDIT']).isRequired,
    defaultTimer: PropTypes.object,
    profileItems: PropTypes.object.isRequired,
    closeOnSaveSuccess: PropTypes.bool,
    closeOnDeleteSuccess: PropTypes.bool,
    onClickClose: PropTypes.func
}

TimerEditor.defaultProps = {
    mode: 'CREATE',
    defaultTimer: {
        loop: true,
        datetime: ['00:00:00Z'],
        profiles: [{}]
    },
    closeOnSaveSuccess: true,
    closeOnDeleteSuccess: true,
    onClickClose: () => {}
}

function mapStateToProps (state) {
    return {
        profileItems: state.profile.items
    }
}

export default connect(mapStateToProps)(TimerEditor)

