import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import dotProp from 'dot-prop-immutable'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import _ from 'lodash'

import { MdSpeakerPhone } from 'react-icons/md'
import { BsPersonBadge, BsPersonBoundingBox } from 'react-icons/bs'
import { HiCode } from 'react-icons/hi'
import { FaBomb } from 'react-icons/fa6'

import Popup from '../common/popup/Popup'
import { getPortfolioNames } from '../../util/accountUtil'
import { muteAlertServer } from '../setting/settingAction'
import { fetchPortfolioDetails, postAlertAllTraders, postAlertUser } from './supportAction'
import { DEVELOPERS, TRADERS } from '../../configs/config'

const DEFAULT_MUTE_DURATION_IN_MINUTES = 15
const PORTFOLIO_PUSHOVER_STATE = {
    NORMAL: 'NORMAL',
    MUTED: 'MUTED',
    UNKOWN: 'UNKOWN'
}

class AlertManagement extends Component {

    constructor (props) {
        super(props)
        this.state = {
            isFetchingPortfolioDetails: false,
            portfolioDetails: {},
            portfolioMuteDurationInputs: {},
            isAlertSuccess: false,
            isAlertFailed: false,
            messageInput: '',
            shouldShowUsersPopup: false
        }
        this._mounted = false
        this.polling = null
        this.alertId = null
        this.handleClickWindow = this.handleClickWindow.bind(this)
    }

    componentDidMount () {
        this._mounted = true
        this._getPortfolioDetails()
        this.polling = setInterval(() => {
            this._getPortfolioDetails()
        }, 10000)

        window.addEventListener('click', this.handleClickWindow)
    }

    componentWillUnmount () {
        this._mounted = false
        if (this.polling) {
            window.clearInterval(this.polling)
        }

        window.removeEventListener('click', this.handleClickWindow)
    }

    handleClickWindow () {
        const { shouldShowUsersPopup } = this.state
        if (shouldShowUsersPopup) {
            this.setState({ shouldShowUsersPopup: false })
        }
    }

    _getPortfolioDetails () {
        const { dispatch } = this.props
        const { isFetchingPortfolioDetails } = this.state
        if (!isFetchingPortfolioDetails && this._mounted) {
            this.setState({ isFetchingPortfolioDetails: true })
            dispatch(fetchPortfolioDetails())
            .then(details => {
                if (this._mounted) {
                    this.setState({ portfolioDetails: _.keyBy(details, 'name') })
                }
            })
            .finally(() => {
                if (this._mounted) {
                    this.setState({ isFetchingPortfolioDetails: false })
                }
            })
        }
    }

    handleClickMuteButton (portfolio='', duration=300000) {
        const { dispatch } = this.props
        if (!_.isEmpty(portfolio) && duration > 0) {
            dispatch(muteAlertServer(portfolio, duration))
            .finally(() => {
                this._getPortfolioDetails()
            })
        }
    }

    handleClickSendPushoverMessageButton (targetUser='') {
        const { dispatch } = this.props
        const { messageInput } = this.state
        if (!_.isEmpty(targetUser)) {
            const postId = uuidv4()
            this.alertId = postId
            this.setState({
                isAlertSuccess: false,
                isAlertFailed: false
            })
            dispatch(postAlertUser(targetUser, messageInput.trim()))
            .then(() => {
                if (this._mounted && this.alertId === postId) {
                    this.setState({ 
                        isAlertSuccess: true,
                        isAlertFailed: false
                    })
                    setTimeout(() => {
                        if (this._mounted && this.alertId === postId) {
                            this.setState({
                                isAlertSuccess: false,
                                isAlertFailed: false
                            })
                        }
                    }, 5000)
                }
            })
            .catch(() => {
                if (this._mounted && this.alertId === postId) {
                    this.setState({ 
                        isAlertSuccess: false,
                        isAlertFailed: true
                    })
                    setTimeout(() => {
                        if (this._mounted && this.alertId === postId) {
                            this.setState({
                                isAlertSuccess: false,
                                isAlertFailed: false
                            })
                        }
                    }, 5000)
                }
            })
        }
    }

    handleClickNotifyAllButton () {
        const { dispatch } = this.props
        const { messageInput } = this.state
        const postId = uuidv4()
        this.alertId = postId
        this.setState({
            isAlertSuccess: false,
            isAlertFailed: false
        })
        dispatch(postAlertAllTraders(messageInput.trim()))
        .then(() => {
            if (this._mounted && this.alertId === postId) {
                this.setState({ 
                    isAlertSuccess: true,
                    isAlertFailed: false
                })
                setTimeout(() => {
                    if (this._mounted && this.alertId === postId) {
                        this.setState({
                            isAlertSuccess: false,
                            isAlertFailed: false
                        })
                    }
                }, 5000)
            }
        })
        .catch(() => {
            if (this._mounted && this.alertId === postId) {
                this.setState({ 
                    isAlertSuccess: false,
                    isAlertFailed: true
                })
                setTimeout(() => {
                    if (this._mounted && this.alertId === postId) {
                        this.setState({
                            isAlertSuccess: false,
                            isAlertFailed: false
                        })
                    }
                }, 5000)
            }
        })
    }

    render () {
        const { portfolioMuteDurationInputs, portfolioDetails, isFetchingPortfolioDetails, isAlertSuccess, isAlertFailed, messageInput, shouldShowUsersPopup } = this.state
        const portfolioNames = getPortfolioNames().sort()
        const users = Object.assign({}, TRADERS, DEVELOPERS)
        const usernames = _.values(users).sort()

        return (
            <div className='alert-management--main'>
                <section className='alert-management--section'>
                    <div className='alert-management--section--title clearfix'>
                        {'Portfolio Mutes'}
                        <span className='alert-management--is-fetching-details'>{isFetchingPortfolioDetails ? 'fetching...' : ''}</span>
                    </div>
                    <div className='alert-management--section--main'>
                        <div className='alert-management--portfolio-table'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>{'Portfolio'}</th>
                                        <th>{'State'}</th>
                                        <th>{'Muted Until'}</th>
                                        <th>{'Update By'}</th>
                                        <th>{'Mute Duration (Mins)'}</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {_.map(portfolioNames, portfolioName => {
                                        const muteDurationInput = _.has(portfolioMuteDurationInputs, portfolioName) ? portfolioMuteDurationInputs[portfolioName] : DEFAULT_MUTE_DURATION_IN_MINUTES
                                        const portfolioDetailItem = portfolioDetails[portfolioName] || {}
                                        const { pushover_mute_ts, mute_operation_user } = portfolioDetailItem
                                        const now = moment()
                                        const portfolioPushoverState = _.isNil(pushover_mute_ts) ? PORTFOLIO_PUSHOVER_STATE.UNKOWN
                                            : moment(pushover_mute_ts).isAfter(now) ? PORTFOLIO_PUSHOVER_STATE.MUTED
                                            : PORTFOLIO_PUSHOVER_STATE.NORMAL
                                        return portfolioName !== 'dp' && (
                                            <tr key={portfolioName}>
                                                <td>{portfolioName}</td>
                                                <td>
                                                    <div className='alert-management--state'>
                                                        <span className={portfolioPushoverState === PORTFOLIO_PUSHOVER_STATE.UNKOWN ? 'unkown' : portfolioPushoverState === PORTFOLIO_PUSHOVER_STATE.MUTED ? 'muted' : null}/>
                                                        <label>{_.capitalize(_.toLower(portfolioPushoverState))}</label>
                                                    </div>
                                                </td>
                                                <td>{portfolioPushoverState === PORTFOLIO_PUSHOVER_STATE.MUTED ? moment(pushover_mute_ts).format('MM-DD HH:mm:ss') : 'N/A'}</td>
                                                <td>{mute_operation_user || ''}</td>
                                                <td>
                                                    <input className='alert-management--duration-input' 
                                                        type={'number'}
                                                        placeholder={'15 Mins'}
                                                        value={muteDurationInput}
                                                        min={1}
                                                        max={45}
                                                        onKeyPress={(e) => { ['-', 'e', '+', '.'].includes(e.key) && e.preventDefault() }}
                                                        onChange={(e) => {
                                                            const newValue = e.target.value.trim().length > 0 ? _.clamp(e.target.value, 1, 45) : ''
                                                            this.setState({ portfolioMuteDurationInputs: dotProp.set(portfolioMuteDurationInputs, portfolioName, newValue) })
                                                        }} />
                                                </td>
                                                <td>
                                                    <button className='alert-management--mute-button'
                                                        disabled={Number(muteDurationInput) <= 0 || Number(muteDurationInput) > 45}
                                                        onClick={() => {
                                                            this.handleClickMuteButton(portfolioName, muteDurationInput * 60 * 1000)
                                                        }}>{'Ack & Mute'}</button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
                <section className='alert-management--section'>
                    <div className='alert-management--section--title'>{'Send Pushover Message'}</div>
                    <div className='alert-management--section--main notify-traders'>
                        <textarea 
                            placeholder={'Write a message...'} 
                            spellCheck={false} 
                            autoFocus
                            value={messageInput}
                            onChange={(e) => { 
                                this.setState({ 
                                    messageInput: e.target.value,
                                    isAlertSuccess: false,
                                    isAlertFailed: false
                                }) 
                            }} />
                        <br />
                        <div className='alert-management--notify-buttons'>
                            <Popup className='alert-management--notify-all-popup'
                                on={'click'}
                                trigger={
                                    <button className='alert-management--notify-traders-button' >
                                        <MdSpeakerPhone />
                                        {'Notify All Traders'}
                                    </button>}>
                                <div className='alert-management--notify-all-popup--description'>
                                    <FaBomb />
                                    <div>
                                        {'This action will alert everyone! '}<br />
                                        {'Are you sure you want to proceed?'}
                                    </div>
                                </div>
                                <button className='alert-management--notify-all-popup--confirm-button'
                                    onClick={() => { this.handleClickSendPushoverMessageButton('trader_whole_group') }}>
                                    {'Confirm'}
                                </button>
                            </Popup>
                            <button className='alert-management--notify-traders-button trader-on-duty' 
                                onClick={() => {
                                    this.handleClickSendPushoverMessageButton('trader_on_duty')
                                }}>
                                <BsPersonBoundingBox />
                                {'Notify the on-duty trader'}
                            </button>
                            <button className='alert-management--notify-traders-button it-support' 
                                onClick={() => {
                                    this.handleClickSendPushoverMessageButton('dev')
                                }}>
                                <HiCode />
                                {'Request IT support'}
                            </button>
                            <div className='alert-management--users-popup' onClick={(e) => { e.stopPropagation() }}>
                                <button className='alert-management--users-popup--trigger alert-management--notify-traders-button specific-member' 
                                    onClick={() => { this.setState({ shouldShowUsersPopup: !shouldShowUsersPopup }) }}>
                                    <BsPersonBadge />
                                    {`Notify Specific Member`}
                                </button>
                                {shouldShowUsersPopup && 
                                <div className='alert-management--users-popup--main'>
                                    {_.map(usernames, username => {
                                        return (
                                            <button className='alert-management--users-popup--user' 
                                                key={username}
                                                onClick={() => {
                                                    this.handleClickSendPushoverMessageButton(username)
                                                    this.setState({ shouldShowUsersPopup: false })
                                                }}>{username}</button>
                                        )
                                    })}
                                </div>}
                            </div>
                        </div>
                        {(isAlertSuccess || isAlertFailed) && <div className='alert-management--notify-traders-message'>
                            {isAlertSuccess ? 'OK! Pushover message is submitted.' : 'Oops, request is failed.'}
                        </div>}
                    </div>
                </section>
                {/* <section className='alert-management--section'>
                    <div className='alert-management--section--title'>{'Request IT Support'}</div>
                    <div className='alert-management--section--main request-support'>
                        <SupportRequestEditor />
                    </div>
                </section> */}
            </div>
        )
    }
}

AlertManagement.propTypes = {
    dispatch: PropTypes.func.isRequired
}

function mapStateToProps (state) {
    return {
        accountItems: state.account.items
    }
}

export default connect(mapStateToProps)(AlertManagement)