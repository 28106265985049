import PropTypes from 'prop-types'

export const OptionLegShape = PropTypes.shape({
    symbolName: PropTypes.string,
    quantity: PropTypes.number,
    accountName: PropTypes.string
})

export const OptionLeg = ({ symbolName='', quantity=0, accountName='' }) => {
    return {
        symbolName,
        quantity,
        accountName
    }
}

export const OptionStrategyShape = PropTypes.shape({
    name: PropTypes.string,
    optionLegs: PropTypes.arrayOf(OptionLegShape)
})

export const OptionStrategy = ({ name='', optionLegs=[OptionLeg({}), OptionLeg({})] }) => {
    return {
        name,
        optionLegs
    }
}

export const AggregatedBookItem = ({ price='', tradeSize=0 }) => {
    return {
        price,
        tradeSize
    }
}