import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { v4 as uuidv4 } from 'uuid'
import { FaTimes } from 'react-icons/fa'
import _ from 'lodash'

import Popup from '../common/popup/Popup'

export default class WorkspaceTab extends Component {
    constructor (props) {
        super(props)
        this.state = {
            isEditing: false,
            nameInput: '',
            removePopupCloseId: null
        }
        this.tabNode = null
        this.handleClickWindow = this.handleClickWindow.bind(this)
    }

    componentDidMount () {
        window.addEventListener('click', this.handleClickWindow)
    }

    componentWillUnmount () {
        window.removeEventListener('click', this.handleClickWindow)
    }

    _changeName () {
        const { onChangeName } = this.props
        const { nameInput } = this.state
        this.setState({ isEditing: false })
        if (!_.isEmpty(nameInput.trim())) {
            onChangeName(nameInput.trim())
        }
    }

    handleClickWindow () {
        const { isEditing } = this.state
        if (isEditing) {
            this._changeName()
        }
    }

    render () {
        const { workspace, shouldHighlight, onClick, onRemove } = this.props
        const { isEditing, nameInput, removePopupCloseId } = this.state
        return (
            <div className={'workspace-tab' + (shouldHighlight ? ' highlight' : '')}
                ref={node => this.tabNode = node}
                onClick={(e) => { 
                    e.stopPropagation()
                    onClick()
                }}
                onDoubleClick={() => {
                    this.setState({ 
                        isEditing: true,
                        nameInput: workspace.name
                    })
                }}>
                {isEditing ? <input spellCheck={false}
                    value={nameInput} 
                    onChange={(e) => { 
                        this.setState({ nameInput: e.target.value })
                    }} 
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            this._changeName()
                        }
                    }} />
                : <label>{workspace.name}</label>}
                <Popup className='workspace-tab--remove-popup' 
                    on={'click'}
                    closeId={removePopupCloseId}
                    trigger={<button className='workspace-tab--remove-button'><FaTimes /></button>}>
                    <div className='workspace-tab--remove-popup--body' onClick={(e) => { 
                        e.stopPropagation()
                    }}>
                        <div className='workspace-tab--remove-popup--message'>{'Remove the workspace?'}</div>
                        <div className='workspace-tab--remove-popup--buttons'>
                            <button className='remove' onClick={() => { onRemove() }}>{'Remove'}</button>
                            <button className='cancel' onClick={() => { this.setState({ removePopupCloseId: uuidv4() }) }}>{'Cancel'}</button>
                        </div>
                    </div>
                </Popup>
            </div>    
        )
    }
}

WorkspaceTab.propTypes = {
    workspace: PropTypes.object.isRequired,
    shouldHighlight: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    onChangeName: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired
}

WorkspaceTab.defaultProps = {
    onClick: () => {},
    onChangeName: () => {},
    onRemove: () => {}
}