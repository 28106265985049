import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import ProfileSwitchItem from './ProfileSwitchItem'
import { SYMBOL_SWITCH_TYPES, getSymbolSwitchType } from '../../util/symbolUtil'
import { getProfileAccountNameBySymbol, getProfileExchangeNameBySymbol, getProfileSmartPositionAccountsByExchangeName, getProfileTradingAccountNamesBySymbol, isProfileSymbolSmartPosAccountNil } from '../../util/profileUtil'
import Popup from '../common/popup/Popup'

class ProfileSwitches extends Component {

    SmartPosAccountSummary (smartPosAccountNames=[]) {
        const { profileItem, symbolName, direction, profileRunningState, accountItems } = this.props
        const filteredSwitchOffItems = _.filter((_.has(profileRunningState, 'switchOffs') ? profileRunningState.switchOffs : []), switchOffItem => {
            const { symbol, account, side } = switchOffItem
            const switchType = getSymbolSwitchType(symbol, account)
            return symbol === symbolName 
                && smartPosAccountNames.includes(account) 
                && (
                    (switchType === SYMBOL_SWITCH_TYPES.BUY_SELL && side === direction) 
                    || (switchType === SYMBOL_SWITCH_TYPES.BUY_SELL_TO_OPEN_CLOSE && [`${direction}_OPEN`, `${direction}_CLOSE`].includes(side))
                )
        })
        const isSmartPosAccountNil = isProfileSymbolSmartPosAccountNil({ 
            profileItem,
            profileRunningState,
            symbolName,
            direction
        })
        const invalidAccountNames = _.filter(smartPosAccountNames, accountName => !_.has(accountItems, accountName))
        return (
            <div className='profile-switches--smart-pos-account-summary'>
                <div className='profile-switches--smart-pos-account-summary--accounts'>
                    <span>{smartPosAccountNames.length}</span>
                    {'Account' + (smartPosAccountNames.length > 1 ? 's' : '')}
                </div>
                {_.size(invalidAccountNames) > 0 && <div className='profile-switches--smart-pos-account-summary--invalid-size'>
                    <span>{_.size(invalidAccountNames)}</span>
                    {'Invalid'}    
                </div>}
                <div className={'profile-switches--smart-pos-account-summary--switch-offs' + (filteredSwitchOffItems.length > 0 ? ' has-switch-off' : '')}>
                    <span>{filteredSwitchOffItems.length}</span>
                    {'Switch Off' + (filteredSwitchOffItems.length > 1 ? 's' : '')}
                </div>
                {isSmartPosAccountNil && <div className='profile-switches--smart-pos-account-summary--nil-message'>{'Nil Selcted Acct'}</div>}
            </div>
        )
    }

    render () {
        const { profileItem, symbolName, direction, profileRunningState } = this.props
        const profileSmartPosAccounts = _.has(profileRunningState, 'smartPosAccounts') ? profileRunningState.smartPosAccounts : []
        const profileSmartPosAccountExceptions = _.has(profileRunningState, 'smartPosAccountExceptions') ? profileRunningState.smartPosAccountExceptions : []
        const profileSmartPosAccountException = _.find(profileSmartPosAccountExceptions, { symbol: symbolName, side: direction })
        const symbolAccountName = getProfileAccountNameBySymbol(profileItem, symbolName)[direction]
        const isSmartPositionAccounts = symbolAccountName === 'smart_pos_acct'
        const symbolTradingAccountNames = []
        let smartPosAccountBackupName = null
    
        if (isSmartPositionAccounts) {
            const profileExchangeName = getProfileExchangeNameBySymbol(profileItem, symbolName)
            const profileSmartPositionAccounts = getProfileSmartPositionAccountsByExchangeName(profileItem, profileExchangeName[direction])
            symbolTradingAccountNames.push(...profileSmartPositionAccounts[direction])
            if (!_.isEmpty(profileSmartPositionAccounts.BACKUP)) {
                smartPosAccountBackupName = profileSmartPositionAccounts.BACKUP
                symbolTradingAccountNames.push(smartPosAccountBackupName)
            }
        } else {
            symbolTradingAccountNames.push(...getProfileTradingAccountNamesBySymbol(profileItem, symbolName)[direction])
        }

        return (
            <div className='profile-switches'>
                {isSmartPositionAccounts ? <Popup className='profile-switches--smart-accounts-popup'
                    disabled={_.size(symbolTradingAccountNames) === 0}
                    on={'click'}
                    trigger={this.SmartPosAccountSummary(symbolTradingAccountNames)}>
                    <div className='profile-switches--smart-accounts-popup--main' onClick={(e) => { e.stopPropagation() }}>
                        {profileSmartPosAccountException && <div className='profile-switches--smart-pos-account-exception'>{profileSmartPosAccountException.reason || 'Unkown Reason'}</div>}
                        <div className='profile-switches--smart-accounts-popup--accounts'>
                            {symbolTradingAccountNames.map((accountName, index) => {
                                const isSelectedSmartPosAccount = _.some(profileSmartPosAccounts, profileSmartPosAccount => {
                                    return profileSmartPosAccount.account === accountName 
                                        && profileSmartPosAccount.side === direction
                                        && profileSmartPosAccount.symbol === symbolName
                                })
                                return (
                                    <Fragment key={index}>
                                        <ProfileSwitchItem 
                                            shouldShowAcccountName
                                            shouldHighlightAcccountName={isSelectedSmartPosAccount} 
                                            profileItem={profileItem}
                                            accountName={accountName} 
                                            symbolName={symbolName} 
                                            direction={direction} 
                                            postfix={accountName === smartPosAccountBackupName ? '(Backup)' : null} />
                                    </Fragment>
                                )
                            })}
                        </div>
                    </div>

                </Popup>
                : _.size(symbolTradingAccountNames) === 1 ? <ProfileSwitchItem 
                    shouldShowAcccountName
                    profileItem={profileItem}
                    accountName={symbolTradingAccountNames[0]} 
                    symbolName={symbolName} 
                    direction={direction} />
                : null}
            </div>
        )
    }
}

ProfileSwitches.propTypes = {
    profileItem: PropTypes.object.isRequired,
    symbolName: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(['BUY', 'SELL']).isRequired,

    profileRunningState: PropTypes.object,
    accountItems: PropTypes.object.isRequired
}

function mapStateToProps (state, ownProps) {
    return {
        profileRunningState: _.has(state.profile.runningState, `${ownProps.profileItem.id}`) ? state.profile.runningState[ownProps.profileItem.id] : null,
        accountItems: state.account.items
    }
}

export default connect(mapStateToProps)(ProfileSwitches)